const _forEach = require('lodash/forEach');
const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);


    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'episode') {
        citationTitle = shared.getStringValue(refData.episodeTitle);
    }
    else{
        citationTitle = shared.getStringValue(refData.title);   
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    //if there is a secondary title, fill this out here
    let type = shared.getStringValue(refData.type);

    //filter out our contribs to only custom 
    let customContribs = [];
    customContribs = shared.getContributorsByType('custom', refData.contributors);

    //make a copy of refdata to pass off to the shared code
    let fakeRefDate = new Object();
    fakeRefDate.title = refData.title;
    fakeRefDate.contributors = customContribs;

    if (type == 'episode') {
        titleProperty = 'episodeTitle';
        fakeRefDate.episodeTitle = refData.episodeTitle;
        isSecondaryTitle = true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefDate, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryCustomContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = '';

    //title will not be used in this section if this is a book chapter
    if (type == 'episode') {
        title = shared.getStringValue(refData.episodeTitle);
        
        if (title.length > 0) {
            
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.doubleQuote();
            }
        }
    }
    else{
        title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.italicize();
            }
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let publisherString = shared.getPublisherString(refData.publishers);

    if (type == 'series') {
        //Secondary Contribs
        let secondaryContributor = getSecondaryContributorString(refData.contributors);
        container1 = container1.appendData(secondaryContributor, ', ');

        //Publishers
        container1 = container1.appendData(publisherString, ', ');

        //publicationDate
        let publicationDate = shared.getFullDate(refData.publicationDate);
        container1 = container1.appendData(publicationDate, ', ');
    }

    if (type == 'episode') {
        //Secondary Contribs
        let secondaryContributor = getEpisodeContainerOneContribs(refData.contributors);
        container1 = container1.appendData(secondaryContributor, ', ');
    }

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type == 'episode') {
        // title, executive directors & executive producers, season, episode, publishers, publicationDate.

        //title
        let title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.italicize();
            }
            
            container2 = container2.appendData(title, '');
        }

        //exec directors and producers
        let secondaryContributor = getEpisodeContainerTwoContribs(refData.contributors);
        container2 = container2.appendData(secondaryContributor, ', ');

        //season
        let season = shared.getStringValue(refData.seasonNumber);
        container2 = container2.appendData(season, ', ', 'season');

        //episode
        let episode = shared.getStringValue(refData.episodeNumber);
        container2 = container2.appendData(episode, ', ', 'episode');

        //Publishers
        let publisherString = shared.getPublisherString(refData.publishers);
        container2 = container2.appendData(publisherString, ', ');

        //publicationDate
        let publicationDate = shared.getFullDate(refData.publicationDate);
        container2 = container2.appendData(publicationDate, ', ');
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //platform
    let platform = shared.getStringValue(refData.platform);
    
    if (platform.length > 0) {
        platform = platform.italicize();
        secondSupplemental = secondSupplemental.appendData(platform, ', ');
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//this is so one off, we are just going to use these locally
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get all non custom
        let secondaryContributors = []; 
		_forEach(contributors, (contrib) => {
            if (contrib.type != 'custom') {
                secondaryContributors.push(contrib);
            }
        });

        if (secondaryContributors.length > 0) {

            //take one pass and pull each unique contrib types we have
            let contribTypes = [];
            let typeStrings = [];

			_forEach(secondaryContributors, (contrib) => {
                if (contrib.type.length > 0) {
                    
                    let alreadyInList = false;

                    contribTypes.forEach(contribType => {
                        if (contrib.type == contribType) {
                            alreadyInList = true;
                        }
                    });

                    if (!alreadyInList) {
                        contribTypes.push(contrib.type);
                    }
                }
            });

            //add each type into it's own list
            contribTypes.forEach(contribType => {
                let contribsByType = [];

                _forEach(secondaryContributors, (contrib) => {
                    if (contrib.type == contribType) {
                        contribsByType.push(contrib);
                    }
                });

                let typeString = shared.getSecondaryContributorTypeString(contribsByType, contribType);
                typeStrings.push(typeString);
            });

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}

function getEpisodeContainerOneContribs(contributors){
    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //directors, writers, writers & directors.
        let directors = shared.getContributorsByType('director', contributors);
        let writers = shared.getContributorsByType('writer', contributors);
        let writerDirectors = shared.getContributorsByType('writerDirector', contributors);

        if (directors.length > 0 || writers.length > 0 || writerDirectors.length > 0) {
            
            //see if we need to "flatten" writer and directors
            if (writerDirectors.length > 0) {
                if (directors.length > 0 || writers.length > 0) {
               
                    //we need to get order they were entered correctly, so let's recreate these arrays as listed
                    directors = [];
                    writers = [];
                    writerDirectors = [];

					_forEach(contributors, (contributor) => {
                        switch (contributor.type) {
                            case 'director':
                                directors.push(contributor);
                                break;
                            case 'writer':
                                writers.push(contributor);
                                break;
                            case 'writerDirector':
                                directors.push(contributor);
                                writers.push(contributor);
                            break;
                        }
                    });
                }
            }

            let typeStrings = [];

            //directors
            if (directors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(directors, 'director'));
            }

            //writers
            if (writers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writers, 'writer'));
            }

            //writerDirectors
            if (writerDirectors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writerDirectors, 'writerDirector'));
            }

            //now build out our strings
            if (typeStrings.length > 0) {
                
                let counter = 0;
                typeStrings.forEach(item => {

                    if (counter == 0) {
                        contributorstring = item;
                    }
                    else{
                        contributorstring = contributorstring + ', ' + item;
                    }
                    
                    counter++;
                });
            }
            
        }

    }

    return contributorstring;
}

function getEpisodeContainerTwoContribs(contributors){
    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {

        let executiveDirectors = shared.getContributorsByType('executiveDirector', contributors);
        let executiveProducers = shared.getContributorsByType('executiveProducer', contributors);
        let executiveDirectorProducers = shared.getContributorsByType('executiveDirectorProducer', contributors);

        if (executiveDirectors.length > 0 || executiveProducers.length > 0 || executiveDirectorProducers.length > 0) {
            let typeStrings = [];

            if (executiveDirectors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(executiveDirectors, 'executiveDirector'));
            }

            if (executiveProducers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(executiveProducers, 'executiveProducer'));
            }

            if (executiveDirectorProducers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(executiveDirectorProducers, 'executiveDirectorProducer'));
            }

            //now build out our strings
            if (typeStrings.length > 0) {
                
                let counter = 0;
                typeStrings.forEach(item => {

                    if (counter == 0) {
                        contributorstring = item;
                    }
                    else{
                        contributorstring = contributorstring + ', ' + item;
                    }
                    
                    counter++;
                });
            }
        }
    }

    return contributorstring;
}