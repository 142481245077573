const _forEach = require('lodash/forEach');
const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    let authors = shared.getContributorsByType('author', refData.contributors);
    let fakeRefData = new Object();
    fakeRefData.contributors = authors;
    fakeRefData.title = refData.title;

    let kind = shared.getStringValue(refData.kind);
    if (kind == 'song' || kind == 'tvEpisode') {
        isSecondaryTitle =  true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    //for this type, only authors are primary, so filter those out
    let authors = shared.getContributorsByType('author', refData.contributors);
    let fakeRefData = new Object();
    fakeRefData.contributors = authors;

    let primaryContributor = shared.getPrimaryContributorString(fakeRefData);
    primaryPart = primaryContributor;

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.doubleQuote();
        }

        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    
    if (reviewedTitle.length > 0) {
        if (reviewedTitle.startsWith('[')) {
            reviewedTitle = shared.removeDescriptionFromTitle(reviewedTitle);
        }
        else{
            reviewedTitle = reviewedTitle.replaceAmpersand();
            reviewedTitle = reviewedTitle.toTitleCase();

            if (!reviewedTitle.endsWith('"')) {
                if (kind == 'song' || kind == 'tvEpisode') {
                    reviewedTitle = reviewedTitle.doubleQuote();
                }
                else {
                    reviewedTitle = reviewedTitle.italicize();
                }
            }
        }

        firstSupplemental = firstSupplemental.appendData(reviewedTitle, ', ');
    }

    //Secondary Contribs
    let secondaryContributor = getSecondaryContributorString(refData.contributors);
    firstSupplemental = firstSupplemental.appendData(secondaryContributor, ', ');

    if (firstSupplemental.length > 0) {
        firstSupplemental = 'Review of ' + firstSupplemental;
    }

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let publicationDate = shared.getFullDate(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    switch (type) {
        case 'periodical':
            //<em>[periodical]</em>, vol. [volume], no. [issue], [publicationDate], [pageNumbers], [url/doi].
            let periodical = shared.getStringValue(refData.periodical);

            if (periodical.length > 0) {
                periodical = periodical.replaceAmpersand();
                periodical = periodical.toTitleCase();
                periodical = periodical.italicize();

                container1 = container1.appendData(periodical, ', ');
            }

            //volume
            let volume = shared.getStringValue(refData.volume);
            container1 = container1.appendData(volume, ', ', 'vol.', '');

            //issue
            let issue = shared.getStringValue(refData.issue);
            container1 = container1.appendData(issue, ', ', 'no.', '');

            //publicationDate
            container1 = container1.appendData(publicationDate, ', ');

            //pageNumbers
            let pageNumbers = shared.getStringValue(refData.pageNumbers);
            pageNumbers = shared.formatPageNumbers(pageNumbers);
            container1 = container1.appendData(pageNumbers, ', ');

            // doi/url
            let doi = shared.getStringValue(refData.doi);

            if (doi.length > 0) {
                doi = shared.appendDOIURL(doi);
                container1 = container1.appendData(doi, ', ');
            }
            else{
                if (url.length > 0) {
                    url = shared.formatURL(url);
                    container1 = container1.appendData(url, ', ');
                }
            }

            break;
        case "website":
            //<em>[website]</em>, [publicationDate], [url].
            let website = shared.getStringValue(refData.website);

            if (website.length > 0) {
                website = website.replaceAmpersand();
                website = website.toTitleCase();
                website = website.italicize();

                container1 = container1.appendData(website, ', ');
            }

            //publicationDate
            container1 = container1.appendData(publicationDate, ', ');

            if (url.length > 0) {
                url = shared.formatURL(url);
                container1 = container1.appendData(url, ', ');
            }

            break;
        case "blog":
            //<em>[blog]</em>, [publicationDate], [url].
            let blog = shared.getStringValue(refData.blog);

            if (blog.length > 0) {
                blog = blog.replaceAmpersand();
                blog = blog.toTitleCase();
                blog = blog.italicize();

                container1 = container1.appendData(blog, ', ');
            }

            //publicationDate
            container1 = container1.appendData(publicationDate, ', ');

            if (url.length > 0) {
                url = shared.formatURL(url);
                container1 = container1.appendData(url, ', ');
            }

            break;
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - which is editors and tranlators
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let reviewAuthors = shared.getContributorsByType('reviewAuthor', secondaryContributors);
            let reviewEditors = shared.getContributorsByType('reviewEditor', secondaryContributors);
            let directors = shared.getContributorsByType('reviewDirector', contributors);
            let writers = shared.getContributorsByType('reviewWriter', contributors);
            let writerDirectors = shared.getContributorsByType('reviewWriterDirector', contributors);
            let artists = shared.getContributorsByType('reviewArtist', contributors);
            let reviewComposers = shared.getContributorsByType('reviewComposer', contributors);
            let reviewGroupArtists = shared.getContributorsByType('reviewGroupArtist', contributors);

            if (writerDirectors.length > 0) {
                if (directors.length > 0 || writers.length > 0) {
               
                    //we need to get order they were entered correctly, so let's recreate these arrays as listed
                    directors = [];
                    writers = [];
                    writerDirectors = [];

					_forEach(contributors, (contributor) => {
                        switch (contributor.type) {
                            case 'reviewDirector':
                                directors.push(contributor);
                                break;
                            case 'reviewWriter':
                                writers.push(contributor);
                                break;
                            case 'reviewWriterDirector':
                                directors.push(contributor);
                                writers.push(contributor);
                            break;
                        }
                    });
                }
            }

            if (reviewAuthors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewAuthors, 'reviewAuthor'));
            }

            if (reviewEditors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewEditors, 'editor'));
            }

            //directors
            if (directors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(directors, 'director'));
            }

            //writers
            if (writers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writers, 'writer'));
            }

            //writerDirectors
            if (writerDirectors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writerDirectors, 'writerDirector'));
            }

            //artists
            if (artists.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(artists, 'reviewArtist'));
            }

            //reviewGroupArtists
            if (reviewGroupArtists.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewGroupArtists, 'reviewGroupArtist'));
            }

            //reviewComposers
            if (reviewComposers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewComposers, 'reviewComposer'));
            }

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}