/*
*****Statutes*****
 
FORM: https://docs.google.com/spreadsheets/d/1UNwDJj93PUey3aT7efImjaEZhTddn4KkatKLSyIocrY/edit#gid=2071428744
MOCKUP: Mockup

TYPES & KINDS
    - federal: Federal statutes
        - usc: Statutes found in the United States Code
        - pl: Statutes found by Public Law Number
    - state: State statutes

FEDERAL USC
    - [title], [volume] U.S.C. § [section] ([publicationDate]). [url]
    *** publicationDate may include more than just YYYY ("2018 & rev. 2019").
    *** section is a bit of a booger.  If [section] includes a dash, hyphen, en dash, or em dash, break at it.  Only show the first part.  Replace the rest with "<em>et seq.</em>" as seen in the tests. If there aren't any dashes, then just enter whatever they put.
    *** Citations use [shortTitle]

FEDERAL PL
    - [title], Pub. L. No. [number], [volume] Stat. [pageNumber] ([publicationDate]). [url]
    *** same rules for publicationDate 
    *** Citations use [shortTitle]

STATE
    - [title], [sourceTitle] [sourceLocation] ([publicationDate]). [url]
    *** same rules for publicationDate
    *** Citations use [shortTitle]

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0) {
        return 'Type and Kind required.';
    }

    switch (type) {
        case 'federal':

            if (kind == 'usc') {
                referenceValue = getFederalUSCReference(refData);
            }

            if (kind == 'pl') {
                referenceValue = getFederalPLReference(refData);
            }

            break;
        case 'state':
            referenceValue = getStateReference(refData);
            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let title = shared.getStringValue(refData.title);
    let shortTitle = shared.getStringValue(refData.shortTitle);
    let year = shared.getYear(refData.publicationDate);
    let revisedDate = shared.getStringValue(refData.revisedDate);

    if (shortTitle.length > 0) {
        title = shortTitle;
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (revisedDate.length > 0) {
        year = year + '/' + revisedDate;
    }

    let citation = shared.getCitations(citationData, title, year, []);

    return citation;
}

function getFederalUSCReference(refData){
    //- [title], [volume] U.S.C. § [section] ([publicationDate]). [url]
    let referenceValue = '';

    let title = shared.getStringValue(refData.title);
    let volume = shared.getStringValue(refData.volume);
    let section = shared.getStringValue(refData.section);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let revisedDate = shared.getStringValue(refData.revisedDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        referenceValue = title;
    }

    if (volume.length > 0) {
        if (title.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + volume;
    }

    if (referenceValue.length > 0) {
        referenceValue = referenceValue + ' ';
    }

    referenceValue = referenceValue + 'U.S.C.';

    if (section.length > 0) {
        section = formatSectionString(section);
        referenceValue = referenceValue + ' § ' + section;
    }

    if (publicationDate.length > 0 || revisedDate.length > 0) {
        referenceValue = referenceValue + ' (';

        if (publicationDate.length > 0) {
            referenceValue = referenceValue + publicationDate;
        }

        if (revisedDate.length > 0) {
            referenceValue = referenceValue + ' & rev. ' + revisedDate;
        }

        referenceValue = referenceValue + ')';
    }

    if (!referenceValue.endsWith('.')) {
        referenceValue = referenceValue + '.';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        referenceValue = referenceValue + ' ' + url;
    }

    return referenceValue;
}

function getFederalPLReference(refData){
    //- [title], Pub. L. No. [number], [volume] Stat. [pageNumber] ([publicationDate]). [url]
    let referenceValue = '';

    let title = shared.getStringValue(refData.title);
    let number = shared.getStringValue(refData.number);
    let volume = shared.getStringValue(refData.volume);
    let pageNumber = shared.getStringValue(refData.pageNumber);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let revisedDate = shared.getStringValue(refData.revisedDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        referenceValue = title;
    }

    if (number.length > 0) {
        if (title.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + 'Pub. L. No. ' + number;
    }

    if (volume.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + volume;
    }

    if (pageNumber.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + 'Stat. ' + pageNumber;
    }

    if (publicationDate.length > 0 || revisedDate.length > 0) {
        referenceValue = referenceValue + ' (';

        if (publicationDate.length > 0) {
            referenceValue = referenceValue + publicationDate;
        }

        if (revisedDate.length > 0) {
            referenceValue = referenceValue + ' & rev. ' + revisedDate;
        }

        referenceValue = referenceValue + ')';
    }

    if (!referenceValue.endsWith('.')) {
        referenceValue = referenceValue + '.';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        referenceValue = referenceValue + ' ' + url;
    }

    return referenceValue;
}

function getStateReference(refData){
    // - [title], [sourceTitle] [sourceLocation] ([publicationDate]). [url]
    let referenceValue = '';

    let title = shared.getStringValue(refData.title);
    let sourceTitle = shared.getStringValue(refData.sourceTitle);
    let sourceLocation = shared.getStringValue(refData.sourceLocation);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let revisedDate = shared.getStringValue(refData.revisedDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        referenceValue = title;
    }

    if (sourceTitle.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + sourceTitle;
    }

    if (sourceLocation.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + sourceLocation;
    }

    if (publicationDate.length > 0 || revisedDate.length > 0) {
        referenceValue = referenceValue + ' (';

        if (publicationDate.length > 0) {
            referenceValue = referenceValue + publicationDate;
        }

        if (revisedDate.length > 0) {
            referenceValue = referenceValue + ' & rev. ' + revisedDate;
        }

        referenceValue = referenceValue + ')';
    }

    if (!referenceValue.endsWith('.')) {
        referenceValue = referenceValue + '.';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        referenceValue = referenceValue + ' ' + url;
    }

    return referenceValue;
}

function formatSectionString(section){
    let suffix = ' <em>et seq.</em>';
    let array = [];

    if (section.includes('-')) {
        array = section.split("-");

        return array[0] + suffix;
    }

    if (section.includes('–')) {
        array = section.split("–");

        return array[0] + suffix;
    }

    if (section.includes('—')) {
        array = section.split("—");

        return array[0] + suffix;
    }
    
    return section;
}