const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getAuthorPart(refData){
    let authorPart = '';

    switch (refData.kind) {
        case 'senate':
        case 'senateJoint':
            authorPart = 'US Congress, Senate.';
            break;
        case 'house':
        case 'houseJoint':
            authorPart = 'US Congress, House.';
            break;
    }

    return authorPart;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    let shortTitle = shared.getStringValue(refData.shortTitle);

    if (shortTitle.length > 0) {
        title = shortTitle;
    }

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    //H. Res. [billNumber]. [session] Cong. [sessionPart] sess.
    switch (refData.kind) {
        case 'senate':
            publicationPart = publicationPart.appendData('S. Res.');
            break;
        case 'senateJoint':
            publicationPart = publicationPart.appendData('S. Joint Res.');
            break;
        case 'house':
            publicationPart = publicationPart.appendData('H. Res.');
            break;
        case 'houseJoint':
            publicationPart = publicationPart.appendData('H. Joint Res.');
            break;
    }

    let billNumber = shared.getStringValue(refData.billNumber);
    publicationPart = publicationPart.appendData(billNumber);
    publicationPart = publicationPart.addClosure();

    let session = shared.getStringValue(refData.session);

    if (session.length > 0) {
        session = shared.getOrdinalValue(session);
        publicationPart = publicationPart.appendData(session);
        publicationPart = publicationPart.appendData('Cong.');
    }

    let sessionPart = shared.getStringValue(refData.sessionPart);

    if (sessionPart.length > 0) {
        publicationPart = publicationPart.appendData(sessionPart);
        publicationPart = publicationPart.appendData('sess.');   
    }

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //some of this is technically publication, but it keeps the logic simpler
    //<em>Congressional Record</em> [volume] ([publicationDate]): [pageNumber], [url].

    locationPart = locationPart.appendData('<em>Congressional Record</em>');

    let volume = shared.getStringValue(refData.volume);
    locationPart = locationPart.appendData(volume);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
    }
    else{
        publicationDate = 'n.d.';
    }

    publicationDate = publicationDate.parenthesis();   
    locationPart = locationPart.appendData(publicationDate);

    let pageNumber = shared.getStringValue(refData.pageNumber);

    if (pageNumber.length > 0) {
        locationPart = locationPart + ':'
        locationPart = locationPart.appendData( pageNumber);
    }

    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url)
        locationPart = locationPart.appendData(url, ', ');
    }

    locationPart = locationPart.addClosure();

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();
    let shortTitle = shared.getStringValue(refData.shortTitle).replaceAmpersand();

    if (shortTitle.length > 0) {
        citationTitle = shortTitle;
    }

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    // [title], S. Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], H. Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], S. Joint Res. [billNumber], [session] Cong. [sessionPart] sess.,, <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], H. Joint Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], S. Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], H. Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], S. Joint Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].
    // [title], H. Joint Res. [billNumber], [session] Cong., [sessionPart] sess., <em>Congressional Record</em> [volume], p. [pageNumber] ([publicationDate]), [location], [url].

    let title = shared.getStringValue(refData.title);
    let shortTitle = shared.getStringValue(refData.shortTitle);

    if (shortTitle.length > 0) {
        title = shortTitle;
    }

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    firstInfo = firstInfo.appendData(title);

    switch (refData.kind) {
        case 'senate':
            firstInfo = firstInfo.appendData('S. Res.', ", ");
            break;
        case 'senateJoint':
            firstInfo = firstInfo.appendData('S. Joint Res.', ", ");
            break;
        case 'house':
            firstInfo = firstInfo.appendData('H. Res.', ", ");
            break;
        case 'houseJoint':
            firstInfo = firstInfo.appendData('H. Joint Res.', ", ");
            break;
    }

    let billNumber = shared.getStringValue(refData.billNumber);
    firstInfo = firstInfo.appendData(billNumber);

    let session = shared.getStringValue(refData.session);

    if (session.length > 0) {
        session = shared.getOrdinalValue(session);
        firstInfo = firstInfo.appendData(session, ', ');
        firstInfo = firstInfo.appendData('Cong.');
    }

    let sessionPart = shared.getStringValue(refData.sessionPart);

    if (sessionPart.length > 0) {
        firstInfo = firstInfo.appendData(sessionPart, ', ');
        firstInfo = firstInfo.appendData('sess.');   
    }

    firstInfo = firstInfo.appendData('<em>Congressional Record</em>', ', ');

    let volume = shared.getStringValue(refData.volume);
    firstInfo = firstInfo.appendData(volume);

    let pageNumber = shared.getStringValue(refData.pageNumber);

    if (pageNumber.length > 0) {
        firstInfo = firstInfo.appendData('p. ' + pageNumber, ', ');
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
    }
    else{
        publicationDate = 'n.d.';
    }

    publicationDate = publicationDate.parenthesis();   
    firstInfo = firstInfo.appendData(publicationDate);

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //[shortenedTitle], [location].
    shortTitle = shared.getStringValue(refData.shortTitle);

    if (shortTitle.length > 0) {
        subsequentInfo = subsequentInfo.appendData(shortTitle.replaceAmpersand().toTitleCase(), ', ');
    }
    else{
        title = shared.getStringValue(refData.title);
        title = title.replaceAmpersand().toTitleCase();

        subsequentInfo = subsequentInfo.appendData(title, ', ');
    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    if (subsequentInfo.length == 0) {
        let billNumber = shared.getStringValue(refData.billNumber);

        if (billNumber.length > 0) {
            switch (refData.kind) {
                case 'senate':
                    subsequentInfo = 'S. Res. ' + billNumber;
                    break;
                case 'senateJoint':
                    subsequentInfo = 'S. Joint Res. ' + billNumber;
                    break;
                case 'house':
                    subsequentInfo = 'H. Res. ' + billNumber;
                    break;
                case 'houseJoint':
                    subsequentInfo = 'H. Joint Res. ' + billNumber;
                    break;
            }
        } 
    }

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}