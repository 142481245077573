const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = 'United States';

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    let type = shared.getStringValue(refData.type);
    let primaryContributor = 'United States';

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    let type = shared.getStringValue(refData.type);

    switch (type) {
        case 'supreme':
            primaryPart = 'United States, Supreme Court.';
            break;
        case 'circuit':
            let circuit = shared.getStringValue(refData.circuit);

            if (circuit.length > 0) {
                let circuitConverted = shared.ordinalToWord(circuit);
                primaryPart = 'United States, Court of Appeals for ' + circuitConverted + ' Circuit.';
            }
            else{
                primaryPart = 'United States, Court of Appeals for Circuit.';
            }

            break;
        case 'district':
            let district = shared.getStringValue(refData.district);

            if (district.length > 0) {
                let districtConverted = districtAbbreviationToFull(district);
                primaryPart = 'United States, ' +  districtConverted + '.';
            }
            else{
                primaryPart = 'United States, District Court.';
            }

            break;
        case 'appealed':

            let appealCircuit = shared.getStringValue(refData.appealCircuit);

            if (appealCircuit.length > 0) {
                let circuitConverted = shared.ordinalToWord(appealCircuit);
                primaryPart = 'United States, Court of Appeals for ' + circuitConverted + ' Circuit.';
            }
            else{
                primaryPart = 'United States, Court of Appeals for Circuit.';
            }

            break;
    }

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.italicize();
        }

        primaryPart = primaryPart.appendData(title, ' ');
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let label = '';
    let series = shared.getStringValue(refData.series);

    switch (type) {
        case 'supreme':
            label = '<em>United States Reports</em>';
            break;
        case 'circuit':
            if (series.length > 0) {
                series = series.upperFirstLetter();
                label = '<em>Federal Reporter, ' + series + ' Series</em>';
            }
            else{
                label = '<em>Federal Reporter</em>';
            }
            break;
        case 'district':

            if (series.length > 0) {
                series = series.upperFirstLetter();
                label = '<em>Federal Supplement, ' + series + ' Series</em>';
            }
            else{
                label = '<em>Federal Supplement</em>';
            }
            break;
        case 'appealed':

            let appealSeries = shared.getStringValue(refData.appealSeries);

            if (appealSeries.length > 0) {
                appealSeries = appealSeries.upperFirstLetter();
                label = '<em>Federal Reporter, ' + appealSeries + ' Series</em>';
            }
            else{
                label = '<em>Federal Reporter</em>';
            }

            break;
    }

    container1 = container1.appendData(label, ', ');

    if (type == 'appealed') {
        //volume
        let appealVolume = shared.getStringValue(refData.appealVolume);
        container1 = container1.appendData(appealVolume, ', ', 'vol.', '');

        //publicationDate
        let appealDate = shared.getMonthYear(refData.appealDate);
        container1 = container1.appendData(appealDate, ', ');

        //pageNumber
        let appealPageNumber = shared.getStringValue(refData.appealPageNumber);
        appealPageNumber = shared.formatPageNumbers(appealPageNumber);
        container1 = container1.appendData(appealPageNumber, ', ');    
    }
    else{
        //volume
        let volume = shared.getStringValue(refData.volume);
        container1 = container1.appendData(volume, ', ', 'vol.', '');

        //publicationDate
        let publicationDate = shared.getMonthYear(refData.publicationDate);
        container1 = container1.appendData(publicationDate, ', ');

        //pageNumber
        let pageNumber = shared.getStringValue(refData.pageNumber);
        pageNumber = shared.formatPageNumbers(pageNumber);
        container1 = container1.appendData(pageNumber, ', ');
    }

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//District Court Abbreviations
//https://abbreviations.yourdictionary.com/articles/federal-and-district-court-abbreviations.html
function districtAbbreviationToFull(abbreviation){
    //APA is entered in abbreviations...if they entered and abbreviation...convert it

    let word = '';

    var conversions = [
        ['D. Conn.', 'District of Connecticut'],
        ['D. Mass.', 'District of Massachusetts'],
        ['D. Me.', 'District of Maine'],
        ['D.N.J.', 'District of New Jersey'],
        ['D.N.H.', 'District of New Hampshire'],
        ['E.D.N.Y.', 'Eastern District of New York'],
        ['N.D.N.Y.', 'Northern District of New York'],
        ['S.D.N.Y.', 'Southern District of New York'],
        ['W.D.N.Y.', 'Western District of New York'],
        ['E.D. Pa.', 'Eastern District of Pennsylvania'],
        ['M.D. Pa.', 'Middle District of Pennsylvania'],
        ['W.D. Pa', ' Western District of Pennsylvania'],
        ['D.R.I.', 'District of Rhode Island'],
        ['D. Vt.', 'District of Vermont'],
        ['C.D. Ill.', 'Central District of Illinois'],
        ['N.D. Ill', 'Northern District of Illinois'],
        ['N.D. Ind', 'Northern District of Indiana'],
        ['S.D. Ind.', 'Southern District of Indiana'],
        ['N.D. Iowa', 'Northern District of Iowa'],
        ['S.D. Iowa ', 'Southern District of Iowa'],
        ['D. Kan.', 'District of Kansas'],
        ['E.D. Mich.', 'Eastern District of Michigan'],
        ['W.D. Mich.', 'Western District of Michigan'],
        ['D. Minn.', 'District of Minnesota'],
        ['E.D. Mo', 'Eastern District of Missouri'],
        ['W.D. Mo.', 'Western District of Missouri'],
        ['D. Neb.', 'District of Nebraska'],
        ['D.N.D.', 'District of North Dakota'],
        ['N.D. Ohio', 'Northern District of Ohio'],
        ['S.D. Ohio', 'Southern District of Ohio'],
        ['D.S.D.', 'District of South Dakota'],
        ['E.D. Wis', 'Eastern District of Wisconsin'],
        ['W.D. Wis.', 'Western District of Wisconsin'],
        ['M.D. Ala.', 'Middle District of Alabama'],
        ['N.D. Ala.', 'Northern District of Alabama'],
        ['S.D. Ala.', 'Southern District of Alabama'],
        ['E.D. Ark.', 'Eastern District of Arkansas'],
        ['W.D. Ark.', 'Western District of Arkansas'],
        ['D. Del.', 'District of Delaware'],
        ['D.D.C.', 'District of D.C.'],
        ['M.D. Fla.', 'Middle District of Florida'],
        ['N.D. Fla.', 'Northern District of Florida'],
        ['S.D. Fla.', 'Southern District of Florida'],
        ['M.D. Ga.', 'Middle District of Georgia'],
        ['N.D. Ga.', 'Northern District of Georgia'],
        ['S.D. Ga.', 'Southern District of Georgia'],
        ['E.D. Ky.', 'Eastern District of Kentucky'],
        ['W.D. Ky.', 'Western District of Kentucky'],
        ['E.D. La.', 'Eastern District of Louisiana'],
        ['M.D. La.', 'Middle District of Louisiana'],
        ['W.D. La.', 'Western District of Louisiana'],
        ['D. Md.', 'District of Maryland'],
        ['N.D. Miss.', 'Northern District of Mississippi'],
        ['S.D. Miss.', 'Southern District of Mississippi'],
        ['E.D.N.C.', 'Eastern District of North Carolina'],
        ['M.D.N.C.', 'Middle District of North Carolina'],
        ['W.D.N.C.', 'Western District of North Carolina'],
        ['E.D. Okla.', 'Eastern District of Oklahoma'],
        ['N.D. Okla.', 'Northern District of Oklahoma'],
        ['W.D. Okla.', 'Western District of Oklahoma'],
        ['D.S.C.', 'District of South Carolina'],
        ['E.D. Tenn.', 'Eastern District of Tennessee'],
        ['M.D. Tenn.', 'Middle District of Tennessee'],
        ['W.D. Tenn.', 'Western District of Tennessee'],
        ['E.D. Tex.', 'Eastern District of Texas'],
        ['N.D. Tex.', 'Northern District of Texas'],
        ['S.D. Tex.', 'Southern District of Texas'],
        ['W.D. Tex.', 'Western District of Texas'],
        ['E.D. Va.', 'Eastern District of Virginia'],
        ['W.D. Va.', 'Western District of Virginia'],
        ['N.D.W. Va.', 'Northern District of West Virginia'],
        ['S.D.W. Va.', 'Southern District of West Virginia'],
        ['D. Alaska', 'District of Alaska'],
        ['D. Ariz.', 'District of Arizona'],
        ['C.D. Cal.', 'Central District of California'],
        ['E.D. Cal.', 'Eastern District of California'],
        ['N.D. Cal.', 'Northern District of California'],
        ['S.D. Cal.', 'Southern District of California'],
        ['D. Colo.', 'District of Colorado'],
        ['D. Guam', 'District of Guam'],
        ['D. Haw.', 'District of Hawaii'],
        ['D. Idaho', 'District of Idaho'],
        ['D. Mont.', 'District of Montana'],
        ['D. Nev.', 'District of Nevada'],
        ['D.N.M.', 'District of New Mexico'],
        ['D.N. Mar. Is.', 'District of the Northern Mariana Islands'],
        ['D. Or.', 'District of Oregon'],
        ['D.P.R.', 'District of Puerto Rico'],
        ['D. Utah', 'District of Utah'],
        ['D.V.I.', 'District of the Virgin Islands'],
        ['E.D. Wash.', 'Eastern District of Washington'],
        ['W.D. Wash.', 'Western District of Washington'],
        ['D. Wyo.', 'District of Wyoming'],
        ['D. Tenn.', 'District Court for Tennessee']
    ];


    for (let index = 0; index < conversions.length; index++) {
        let thisConversion = conversions[index][0];

        if (thisConversion.toLowerCase() == abbreviation.toLowerCase()) {
            word = conversions[index][1];
        }
    }    

    if (word == '') {
        //if we havent converted yet, just leave it as is
        word = abbreviation;
    }

    return word;
}