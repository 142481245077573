/*
*****ExecutiveOrders*****
 
Documentation: https://docs.google.com/spreadsheets/d/1LbxrNtaVJ9qquMV-FvS0ej4C-_9Bcf0CpyoDPDkyP0U/edit#gid=1084144617

Mockup: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-executive-orders

Type & Kind Questions
- Types & Kinds: 
    There are no types or kinds

PARTS
    - orderNumber: the number of the order.  It should use commas appropriately for long numbers (e.g. 12,345)
    - pageNumber: the starting page number for the record
    - publicationDate: the year the executive order was given
    - url: url for the bill/resolution

Example
    Exec. Order No. [orderNumber], 3. C.F.R. [pageNumber] ([publicationDate]). [url]
*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';

    //Exec. Order No. 12,345. 3 C.F.R. 345 (2018). https://www.perrla.com/executiveorder

    let orderNumber = shared.getStringValue(refData.orderNumber);
    let pageNumber = shared.getStringValue(refData.pageNumber);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    if (orderNumber.length > 0) {
        referenceValue = 'Exec. Order No. ' + shared.addCommasToNumber(orderNumber) + ',';
    }

    if (pageNumber.length > 0) {

        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + '3 C.F.R. ' + pageNumber;
    }

    if (referenceValue.length > 0) {
        referenceValue = referenceValue + ' ';
    }
    
    if (publicationDate.length > 0) {
        let year = shared.getYear(publicationDate);
        referenceValue = referenceValue + '(' + year + ')';
    }
    else{
        referenceValue = referenceValue + '(n.d.)';
    }

    referenceValue = referenceValue + '.';

    if (url.length) {
        url = shared.formatURL(url);
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + url;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let authorString = '';
    let orderNumber = shared.getStringValue(refData.orderNumber);
    let year = shared.getYear(refData.publicationDate);

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (orderNumber.length > 0) {
        authorString = 'Exec. Order No. ' + shared.addCommasToNumber(orderNumber);
    }

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}