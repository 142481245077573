const _cloneDeep = require('lodash/cloneDeep');
const shared = require('../MLA9/MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    specialRules
};

function specialRules(references){
	let _references = [];

    //iterarate each reference to update it's value
    references.forEach((thisReference) => {
		// clone thisReference so i can manipulate it
		let _thisReference = _cloneDeep(thisReference);

        let typeName = getTypeShortName(_thisReference.referenceTypeID);
        
        let typeEngine = getTypeEngine(typeName);
        let engineRef;
        let refDataObject; 
        
        if (typeof _thisReference.data === 'string') {
          refDataObject = JSON.parse((_thisReference.data));
        }
        else{
          refDataObject = _thisReference.data;
        }

        engineRef = typeEngine.getReference(refDataObject);
        
        _thisReference.authorPart = engineRef.authorPart;
        _thisReference.authorPartNoLabel = engineRef.authorPartNoLabel;
        _thisReference.authorPartSort = engineRef.authorPartSort;
        _thisReference.sameAuthorPartID = 0;
        _thisReference.sameAuthorPartEtAlID = 0;
        _thisReference.citationTitle = engineRef.citationTitle;
        _thisReference.citationShortTitle = engineRef.citationTitle;

        if (_thisReference.citationShortTitle.length > 0) {
          _thisReference.citationShortTitle = _thisReference.citationShortTitle.shortenName();
        }

        //_thisReference.newDatePart = '';
        _thisReference.displayValue = engineRef.value;
        _thisReference.orderByValue = shared.getOrderByValue(_thisReference);
        //_thisReference.citationEtAlOverwrite = '';

        let citationData = new Object;
        citationData.namePart = true;
        citationData.datePart = true;
        citationData.label = '';
        citationData.value = '';
        citationData.type = 'notdirect';
        
        _thisReference.indirectCitation = typeEngine.getCitation(refDataObject, citationData, references);

        //update the citations for this reference
        if (_thisReference.citations != undefined) {

          //see if this is a simgle object, or an array
          if (Array.isArray(_thisReference.citations)) {

            let counter = 1;

            _thisReference.citations.forEach(thisCitation => {

              thisCitation.mla9 = typeEngine.getCitation(refDataObject, thisCitation.citationData, references);

              // if (counter > 1 && thisCitation.apa7.subsequent != '') {
              //   thisCitation.displayValue = '(' + thisCitation.apa7.subsequent + ')';
              // }
              // else{
                thisCitation.displayValue = '(' + thisCitation.mla9.first + ')';
              // }
    
              counter++;
            });
          }
          else{
            if (typeof _thisReference.citations === 'object' && _thisReference.citations !== null) {
              _thisReference.citations.mla9 = typeEngine.getCitation(refDataObject, _thisReference.citations.citationData, references);
              _thisReference.citations.displayValue = '(' + _thisReference.citations.mla9.first + ')'; 
            }
          }
        }
		_references.push(_thisReference);

    });//e:forEach

    //initial sort
    orderRefs(_references);

    //Same author parts
    sameAuthorParts(_references);
    
    //Grouped Citations
    groupedCitations(_references);

    return _references;
}

function getTypeShortName(id){
    //swap the ID to the short name from types.json
    var typeData = require('../types.json');
    let typeShortName = '';

    if (shared.isInteger(id)) {
      id = id.toString();
    }
  
    typeData.refTypes.forEach(thisType => {
      if (thisType.id == id) {
        typeShortName = thisType.shortname;
      }
    });
  
    return typeShortName;
  }

function getTypeEngine(type){
  var typeEngine = null;

    typeEngine = require('./MLA9-' + type +'.js');

    return typeEngine;
}

  function groupedCitations(references){

    //iterate and see if we have any grouped citations in the paper
    let groupCitationIds = [];

    references.forEach(thisRef => {

      if (thisRef.citations != undefined) {

        if (Array.isArray(thisRef.citations)) {
          for (var i = 0; i < thisRef.citations.length; i++) {
            let thisCitation = thisRef.citations[i];
  
            if (thisCitation.groupUniqueID != undefined) {
              if (thisCitation.groupUniqueID.length > 0) {
  
                //make sure this group id is not already in the list
                let found = false;
                groupCitationIds.forEach(thisGroupCitationID => {
                  if (thisGroupCitationID.toUpperCase() == thisCitation.groupUniqueID.toUpperCase()) {
                    found = true;
                  }
                });
  
                if (!found) {
                  groupCitationIds.push(thisCitation.groupUniqueID.toUpperCase());
                }
  
              }
            }
          }//e:for:citations
        }
        else{
          if (thisRef.citations.groupUniqueID != undefined) {
              if (thisRef.citations.groupUniqueID.length > 0) {
                //make sure this group id is not already in the list
                let found = false;
                groupCitationIds.forEach(thisGroupCitationID => {
                  if (thisGroupCitationID.toUpperCase() == thisRef.citations.groupUniqueID.toUpperCase()) {
                    found = true;
                  }
                });
  
                if (!found) {
                  groupCitationIds.push(thisRef.citations.groupUniqueID.toUpperCase());
                }
              }
          }
        }
      }

    });//e:for:references

    //process each group ID now
    groupCitationIds.forEach(thisGroupID => {

      let groupCitations = [];

      references.forEach(thisRef => {
        if (thisRef.citations != undefined) {

          if (Array.isArray(thisRef.citations)) {
            thisRef.citations.forEach(thisCitation =>{

              if (thisCitation.groupUniqueID != undefined) {
                if (thisCitation.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                  
                  //append some ref data that we may need in the next step building the output
                  thisCitation.refAuthorPart = thisRef.authorPart;
  
                  groupCitations.push(thisCitation);
                }
              }
  
            });//e:for:citations
          }
          else{
            if (thisRef.citations.groupUniqueID != undefined) {
              if (thisRef.citations.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                
                //append some ref data that we may need in the next step building the output
                thisRef.citations.refAuthorPart = thisRef.authorPart;

                groupCitations.push(thisRef.citations);
              }
            }
          }
        }
      });//e:for:references

      //make a pass through now to get our new value
      let groupValue = '';
      let lastRefAuthorPart = '';
      let lastCitationUsed = ''

      groupCitations.forEach(thisCitation => {

        let thisDisplay = thisCitation.displayValue;

        if (thisDisplay.startsWith('(')) {
          thisDisplay = thisDisplay.substring(1, thisDisplay.length);
        }

        if (thisDisplay.endsWith(')')) {
          thisDisplay = thisDisplay.substring(0, thisDisplay.length -1);
        }

        let displayToUse = thisDisplay;

        if (lastRefAuthorPart.length > 0) {
          if (thisCitation.refAuthorPart == lastRefAuthorPart) {

            //loop through until we find the part of this citation that start to differ from the last one we used
            let found = false;
            for (let i = 0; i < displayToUse.length; i++) {

              if (!found) {

                let thisChar = displayToUse.charAt(i);

                if (shared.isInteger(thisChar)) {
                  displayToUse = displayToUse.substring(i, displayToUse.length);
                  found = true;
                }
                else{
                  if (displayToUse.charAt(i) != lastCitationUsed.charAt(i)) {
                    displayToUse = displayToUse.substring(i, displayToUse.length);
                    found = true;
                  } 
                } 
              }
            }
          } 
        }

        lastCitationUsed = thisDisplay;
        lastRefAuthorPart = thisCitation.refAuthorPart; 
        
        if (displayToUse.length > 0) {
          if (groupValue.length > 0) {
            groupValue = groupValue + '; ';
          }

          groupValue = groupValue + displayToUse;
        }

      });//e:for:group citations

      if (groupValue.length > 0) {
        groupValue = '(' + groupValue + ')';
      }

      //now go back and set the display value back
      groupCitations.forEach(thisCitation => {
        thisCitation.displayValue = groupValue;
      });//e:for:group citations

    });

  }

  function orderRefs(references){
    //order the results
    references.sort((a, b) => {
      let textA = '';
      let textB = '';

      if (a.orderByValue !== null) {
          textA = a.orderByValue;
      }

      if (b.orderByValue !== null) {
        textB = b.orderByValue;
      }

      if (textA.length > 0) {
        textA = textA.toUpperCase();
      }

      if (textB.length > 0) {
        textB = textB.toUpperCase();
      }

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });//e:sort
  }

  function sameAuthorParts(references){
    //see which references have the same author parts
    let sameAuthorPartID = 1; 
    let sameAuthorPartIDs = [];

    let sameAuthorPartEtAlID = 1; 
    let sameAuthorPartEtAlIDs = [];
  
    references.forEach(thisReference => {
  
      if (thisReference.authorPart != '' && 
          thisReference.sameAuthorPartID == 0) {
          
        //inner loop for this reference
          let foundMatches = false;
          let foundEtAlMatches = false;

          references.forEach(innerReference => {
            if (innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
                innerReference.authorPartSort == thisReference.authorPartSort
                ) 
            {
              //we found a matching author and date part
              foundMatches = true;
              thisReference.sameAuthorPartID = sameAuthorPartID;
              innerReference.sameAuthorPartID = sameAuthorPartID;
            }
            
            if (thisReference.displayValue.includes('et al.') &&
                  innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
                  innerReference.authorPartNoLabel == thisReference.authorPartNoLabel) {
                
                  if (thisReference.sameAuthorPartEtAlID == 0) {
                    thisReference.sameAuthorPartEtAlID = sameAuthorPartEtAlID;  
                    foundEtAlMatches = true;
                  }
                  
                  if (innerReference.sameAuthorPartEtAlID == 0) {
                    innerReference.sameAuthorPartEtAlID = sameAuthorPartEtAlID;  
                  }
            }
          });
  
          //if we found a match, increment our id
          if (foundMatches) {
            sameAuthorPartIDs.push(sameAuthorPartID);
            sameAuthorPartID++;
          }

          if (foundEtAlMatches) {
            sameAuthorPartEtAlIDs.push(sameAuthorPartEtAlID);
            sameAuthorPartEtAlID++;
          }
      }
        
    });
  
    //change any authors after the initial entry to only be ---
    sameAuthorPartIDs.forEach(thisSameID =>{
      let matchingRefs = [];
  
          references.forEach(thisReference => {
            if (thisReference.sameAuthorPartID == thisSameID) {
              matchingRefs.push(thisReference);
            }
          });
  
          let counter = 0;
          matchingRefs.forEach(thisReference => {
            
            if (counter > 0) {
  
              let dashReplacement = '———';

              // if (thisReference.authorPart == thisReference.authorPartNoLabel) {
              //     dashReplacement = dashReplacement + '.';
              // }

              if (thisReference.authorPartNoLabel.endsWith('.')) {
                thisReference.authorPartNoLabel = thisReference.authorPartNoLabel.substring(0, thisReference.authorPartNoLabel.length - 1);
              }
  
              thisReference.displayValue = thisReference.displayValue.replace(thisReference.authorPartNoLabel, dashReplacement);
            }
            
            counter++;
          });
    });
  
    //now we need to make sure that any citations do not match and have enough to identify
    //start with the matching et als
    sameAuthorPartEtAlIDs.forEach(thisSameID =>{
      let matchingRefs = [];
 
      references.forEach(thisReference => {
        if (thisReference.sameAuthorPartEtAlID == thisSameID) {
          matchingRefs.push(thisReference);
        }
      });

      //now go back thru each of the references and expand their citation for more information
      matchingRefs.forEach(thisReference => {

        let hasDuplicateTitlesAlso = false;

        matchingRefs.forEach(innerReference => {
          if (innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
              innerReference.citationTitle == thisReference.citationTitle) {
                hasDuplicateTitlesAlso = true;
          }
        });

        ///go back thru and see if any other references in this list have the exact same title too
        //if so we'll use date instead of title\
        if (thisReference.citations != undefined) {
          if (thisReference.citations.length > 0) {

            if (hasDuplicateTitlesAlso) {
              thisReference.citations.forEach(thisCitation => {
                if (thisCitation.mla9.withDate.length > 0) {
                  thisCitation.displayValue = "(" + thisCitation.mla9.withDate + ")";
                }
              });
            }
            else{
              thisReference.citations.forEach(thisCitation => {
                if (thisCitation.mla9.withTitle.length > 0) {
                  thisCitation.displayValue = "(" + thisCitation.mla9.withTitle + ")";
                }
              });
            }
          }
      }
        
      });
    
    });

    sameAuthorPartIDs.forEach(thisSameID =>{
      let matchingRefs = [];
 
      references.forEach(thisReference => {
        // if (thisReference.sameAuthorPartID == thisSameID) {
        if (thisReference.sameAuthorPartID == thisSameID && thisReference.sameAuthorPartEtAlID == 0) {
          matchingRefs.push(thisReference);
        }
      });

      //now go back thru each of the references and expand their citation for more information
      matchingRefs.forEach(thisReference => {

        let hasDuplicateTitlesAlso = false;

        matchingRefs.forEach(innerReference => {
          if (innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
              innerReference.citationTitle == thisReference.citationTitle) {
                hasDuplicateTitlesAlso = true;
          }
        });

        ///go back thru and see if any other references in this list have the exact same title too
        //if so we'll use date instead of title
        if (thisReference.citations != undefined) {
          if (thisReference.citations.length > 0) {

            if (hasDuplicateTitlesAlso) {
              thisReference.citations.forEach(thisCitation => {
                if (thisCitation.mla9.withDate.length > 0) {
                  thisCitation.displayValue = "(" + thisCitation.mla9.withDate + ")";
                }
              });
            }
            else{
              thisReference.citations.forEach(thisCitation => {
                if (thisCitation.mla9.withTitle.length > 0) {
                  thisCitation.displayValue = "(" + thisCitation.mla9.withTitle + ")";
                }
              });
            }
          }
        }
        
      });
    });
  
  }