const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

//Breakdown Notes
/*
Type Question
Kind Quesstion

-Type: (report) Reports: Annual, Research, Technical, etc.
    - (whole) All or part of a report
    (AuthorPart) (DatePart) (TitlePart) (SourcePart)
    - (series) A report in a series
    (AuthorPart) (DatePart) (SeriesTitlePart) (SourcePart)

-Type: (brief) Briefs & Evaluations
    - (whole) All or part of a brief/evaluation
    (AuthorPart) (DatePart) (TitlePart) (SourcePart)
    - (series) A brief/evaluation in a series
    (AuthorPart) (DatePart) (SeriesTitlePart) (SourcePart)

-Type: (codes) Codes & Guidelines
    - (whole) All or part of a set of codes/guidelines
    (AuthorPart) (DatePart) (TitlePart) (SourcePart)
    - (series) A set of codes/guidelines in a series
    (AuthorPart) (DatePart) (SeriesTitlePart) (SourcePart)


AuthorPart
    - Authors, Group Authors, Editors w/o Authors
    - Inverted names

DatePart
    - Publication Date (Year)

TitlePart
    - Title of the work (italics), Editors, format

SeriesTitlePart
    - Title/Description of the work (italics), Series Title, Volume/Issue Number, Editors, format

SourcePart
    - Publisher, DOI, URL

*/

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    // let kind = shared.getStringValue(refData.kind);

    // if (type.length == 0 || kind.length == 0) {
    //     return 'Type and Kind required.';
    // }

    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);

    switch (type) {
        case 'whole':
            //(AuthorPart) (DatePart) (TitlePart) (SourcePart)
            let titlePart = getTitlePart(refData);

            if (authorPart.length == 0) {
                //no contributors
                referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
            }
            else{
                referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
            }

            break;

        case 'series':
            //(AuthorPart) (DatePart) (SeriesTitlePart) (SourcePart)
            let seriesTitlePart = getSeriesTitlePart(refData);

            if (authorPart.length == 0) {
                //no contributors
                referenceValue = seriesTitlePart + ' ' + datePart + ' ' + sourcePart;  
            }
            else{
                referenceValue = authorPart + ' ' + datePart + ' ' + seriesTitlePart + ' ' + sourcePart;
            }
            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let workTitle = shared.getStringValue(refData.workTitle);

            if (workTitle.length > 0) {
                //remove our subtitle for the citation
                if (workTitle.includes(':')) {
                    var array = workTitle.split(":");
                    workTitle = array[0];
                }

                authorString = workTitle.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0 && (refData.type == 'reprinted')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/'+ year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    //Title of the work (italics), Editors, format
    let titlePart = '';

    let title = shared.getStringValue(refData.workTitle);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = title.italicize();    
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    let authorArray = shared.getAuthors(refData.contributors);
    let description = shared.getStringValue(refData.description);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
            description.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (description.length > 0 && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (description.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            titlePart = titlePart + description;
        }

        titlePart = titlePart + ')';
    }

    //format
    let format = shared.getStringValue(refData.format);
    
    if (format.length > 0) {
        titlePart = titlePart + ' [' + format + ']';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getSeriesTitlePart(refData){
    //- Title/Description of the work (italics), Series Title, Volume/Issue Number, Editors, format
    //em>Work title: Subtitle of the work</em> (A. A. Editora, B. B. Editorb Jr., & C. C. Editorc, Eds.; Series Title, Publication No. 10-12345) [Format].
    let titlePart = '';

    let title = shared.getStringValue(refData.workTitle);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = title.italicize();    
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);
    let authorArray = shared.getAuthors(refData.contributors);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    let description = shared.getStringValue(refData.description)
    // let custom = shared.getStringValue(refData.custom);
    // let issue = shared.getStringValue(refData.issue);
    // let volume = shared.getStringValue(refData.volume);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
        seriesTitle.length > 0 ||
        description.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if ((seriesTitle.length > 0 || 
            description.length > 0) 
                && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (seriesTitle.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
            }

            isFirstNames = false;
            titlePart = titlePart + seriesTitle;
        }

        if (description.length > 0) {
            if (!isFirstNames) {
                if (seriesTitle.length > 0) {
                    titlePart = titlePart + ', ';
                }
                else{
                    titlePart = titlePart + ' ';
                }
            }

            isFirstNames = false;

            titlePart = titlePart + description;
        }

        titlePart = titlePart + ')';
    }

    //format
    let format = shared.getStringValue(refData.format);
    
    if (format.length > 0) {
        titlePart = titlePart + ' [' + format + ']';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Publisher, DOI, URL
    let sourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}