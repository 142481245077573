/*
*****USFederalCourtCases*****
 
Documentation: 
FORM: https://docs.google.com/spreadsheets/d/1c49E2DLcu6thGJDIfUBg9ysD1ToSv5XzgrjEQYir9Z4/edit#gid=1084144617
MOCKUP: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-federal-court-cases

Type & Kind Questions
    Types:
        - supreme
        - circuit
        - district
        - appealed

    Kinds:
        - affirmed: This is only used for the "appealed" type.
        - overturned: This is only used for the "appealed" type.

Supreme
    [title], [volume] U.S. [pageNumber] ([publicationDate]). [url]

    PageNumber: If there isn't a page number passed, put three underscores "___". 

Circuit
    [title], [volume] F.[series] [pageNumber] ([circuit] Cir. [publicationDate]). [url]

    Series: This is a dropdown that will pass "first", "second", or "third".  First will show as "F.".  Second will be "F.2d".  Third is "F.3d".

District
    [title], [volume] F. Supp.[series] [pageNumber] ([district] [publicationDate]). [url]

    Series: This is a dropdown that will pass "first", "second", or "third".  First will show as "F. Supp.".  Second will be "F. Supp. 2d".  Third is "F. Supp. 3d".

Appealed Affirmed
    [title], [volume] F. Supp.[series] [pageNumber] ([district] [publicationDate]), <em>aff'd</em> [appealVolume] F.[appealSeries] [appealPageNumber] ([circuit] Cir. [appealPublicationDate]). [url]

    Series follows the pattern for District.  AppealSeries follows the pattern for Circuit.

Appealed Overturned
    [title], [volume] F. Supp.[series] [pageNumber] ([district] [publicationDate]), <em>rev'd</em> [appealVolume] F.[appealSeries] [appealPageNumber] ([circuit] Cir. [appealPublicationDate]). [url]

    Series follows the pattern for District.  AppealSeries follows the pattern for Circuit.

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0) {
        return 'Type required.';
    }

    let title = shared.getStringValue(refData.title);
    let volume = shared.getStringValue(refData.volume);
    let pageNumber = shared.getStringValue(refData.pageNumber);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);
    let series = shared.getStringValue(refData.series);
    let circuit = shared.getStringValue(refData.circuit);
    let district = shared.getStringValue(refData.district);

    let label = '';

    switch (type) {
        case 'supreme':
            label = 'U.S.';
            break;
        case 'circuit':
            label = 'F.';

            if (series.length > 0) {
                let seriesAbbreviation = getSeriesAbbreviation(series);

                if(seriesAbbreviation.length > 0){
                    label = label + seriesAbbreviation;
                }
            }

            break;
        case 'district':
        case 'appealed':
            label = 'F. Supp.';

            if (series.length > 0) {
                let seriesAbbreviation = getSeriesAbbreviation(series);

                if(seriesAbbreviation.length > 0){
                    label = label + ' ' + seriesAbbreviation;
                }
            }
            break;
        
    }

    //if there are no pagenumbers, set this to ___
    if (pageNumber.length == 0) {
        pageNumber = '___';
    }

    if (publicationDate.length == 0) {
        publicationDate = 'n.d.';
    }

    if (title.length > 0) {
        referenceValue = title;
    }

    if (volume.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', '
        }

        referenceValue = referenceValue + volume;
    }

    if (label.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' '
        }

        referenceValue = referenceValue + label;
    }

    if (pageNumber.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' '
        }

        referenceValue = referenceValue + pageNumber;
    }

    if (publicationDate.length > 0 || circuit.length > 0 || district.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' '
        }

        referenceValue = referenceValue + '(';

        if (district.length > 0) {
            referenceValue = referenceValue + district;

            if (publicationDate.length > 0) {
                referenceValue = referenceValue + ' ';
            }
        }

        if (circuit.length > 0) {
            referenceValue = referenceValue + circuit + ' Cir.';

            if (publicationDate.length > 0) {
                referenceValue = referenceValue + ' ';
            }
        }

        if (publicationDate.length > 0) {
            referenceValue = referenceValue + publicationDate;
        }

        referenceValue = referenceValue + ')';
    }

    if (type == 'appealed') {
        referenceValue = referenceValue + ', ';
        //<em>aff'd</em>, 321 F.2d 654 (9th Cir. 2019).
        let appealedLabel = '';

        if (kind == 'affirmed') {
            appealedLabel = '<em>aff\'d</em>';
        }

        if (kind == 'overturned') {
            appealedLabel = '<em>rev\'d</em>';
        }

        referenceValue = referenceValue + appealedLabel;

        let appealVolume = shared.getStringValue(refData.appealVolume);
        let appealSeries = shared.getStringValue(refData.appealSeries);
        let appealPageNumber = shared.getStringValue(refData.appealPageNumber);
        let appealDate = shared.getStringValue(refData.appealDate);
        let appealCircuit = shared.getStringValue(refData.appealCircuit);

        if (appealPageNumber.length == 0) {
            appealPageNumber = '___';
        }

        if (appealVolume.length > 0 || appealSeries.length > 0 || appealPageNumber.length > 0 || appealDate.length > 0 || appealCircuit.length > 0) {
            referenceValue = referenceValue + ','

            if (appealVolume.length > 0) {
                referenceValue = referenceValue + ' ' + appealVolume;
            }

            if (appealSeries.length > 0) {
                referenceValue = referenceValue + ' F.' + getSeriesAbbreviation(appealSeries);
            }

            if (appealPageNumber.length > 0) {
                referenceValue = referenceValue + ' ' + appealPageNumber;
            }

            if (appealCircuit.length > 0 || appealDate.length > 0) {
                referenceValue = referenceValue + ' (';

                if (appealCircuit.length > 0) {
                    referenceValue = referenceValue + appealCircuit + ' Cir.';

                    if (appealDate.length) {
                        referenceValue = referenceValue + ' ';
                    }
                }

                if (appealDate.length) {
                    referenceValue = referenceValue + appealDate;
                }

                referenceValue = referenceValue + ')'
            }

            referenceValue = referenceValue + '.'
        }
        else{
            referenceValue = referenceValue + '.'
        }

    }
    else{
        referenceValue = referenceValue + '.';
    }
    
    if (url.length) {
        url = shared.formatURL(url);
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + url;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let authorString = '';
    let title = shared.getStringValue(refData.title);
    let year = shared.getYear(refData.publicationDate);
    let appealDate = shared.getYear(refData.appealDate);

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (appealDate.length > 0) {
        year = year + '/' + appealDate;
    }

    if (title.length > 0) {
        authorString = title.italicize();
    }

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}

function getSeriesAbbreviation(series){
    let abbreviation = '';

    switch (series) {
        case 'second':
            abbreviation = '2d'
            break;
        case 'third':
            abbreviation = '3d'
            break;
        case 'fourth':
            abbreviation = '4th'
            break;
    }

    return abbreviation;
}