/*
*****Speech*****
 
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-speech-recorded-audio
FORM: https://docs.google.com/spreadsheets/d/1FfXVmRtXa2XIeZX5RJAvDVhil7_Pgr-rJgHSiMNsH9w/edit#gid=2071428744

TYPE & KIND
    - There are no types/kinds.

BREAKDOWN
    [contributors]. ([publicationDate]). <em>[title]</em> [Speech audio recording]. [publisher]. [url]

AuthorPart
    contributor
        - Acts normally

DatePart
    publicationDate
        - Acts normally as YYYY, MM DD

TitlePart
    title
        - Normal title in sentence case
    "[Speech audio recording]."
        - this is added text to every reference after the title

SourcePart
    publisher
        - Normal publisher
    url
        - Normal url

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let authorPart = getAuthorPart(refData);
    let datePart = getDatePart(refData);
    let title = shared.getStringValue(refData.title);
    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);

    if (authorPart.length > 0) {
        referenceValue = authorPart;

        referenceValue = referenceValue + ' ' + datePart;

        if (title.length > 0) {
            if (title.startsWith('[')) {
                referenceValue = referenceValue + ' ' + title + '.';  
            }
            else{
                referenceValue = referenceValue + ' ' + title.italicize() + ' [Speech audio recording].';  
            }
        } 
    }
    else{
        if (title.length > 0) {
            if (title.startsWith('[')) {
                referenceValue = title + '.';  
            }
            else{
                referenceValue = title.italicize() + ' [Speech audio recording].';  
            }
        } 

        referenceValue = referenceValue + ' ' + datePart;
    }

    if (publisherString.length > 0) {
        referenceValue = referenceValue + ' ' + publisherString;

        if (!referenceValue.endsWith('.')) {
            referenceValue = referenceValue + '.';
        }
    }
    
    if (url.length > 0) {
        url = shared.formatURL(url);
        referenceValue = referenceValue + ' ' + url;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }
    
    return authorPart;
}

function getDatePart(refData){
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}