const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';

    let type = shared.getStringValue(refData.type);
    
    let authorPart = shared.getAuthorPart(refData);
    let publicationPart = getPublicationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);

    if (type == 'archive') {
        let editorString = getInterviewEditorString(refData.contributors);

        if (editorString.length == 0) {
            editorString = 'author';
        }

        referenceValue = referenceValue.appendData('Interview by ' + editorString);
        referenceValue = referenceValue.addClosure();
    }
    else{
        referenceValue = referenceValue.appendData('Interview by author.');
    }
    
    referenceValue = referenceValue.appendData(publicationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getPublicationPart(refData){
    let publicationPart = '';
 
    let number = shared.getStringValue(refData.number);
    publicationPart = publicationPart.appendData(number);
    publicationPart = publicationPart.addClosure();

    let type = shared.getStringValue(refData.type);

    let publisherString;
    if(type === 'personal'){
        publisherString = shared.getPublisherString(refData.publishers, 'reference', false, true);
    } else {
        publisherString = shared.getPublisherStringInverted(refData.publishers, false, true);
    }

    if(publisherString !== 'N.p.'){
        publicationPart = publicationPart.appendData(publisherString);
        publicationPart = publicationPart.addClosure();
    }
    

    let publicationDate = shared.getStringValue(refData.publicationDate);
    if(publicationDate === ''){
        publicationPart = publicationPart.appendData('N.d.');
        
    } else {
        publicationDate = shared.getFullDate(publicationDate);   
        publicationPart = publicationPart.appendData(publicationDate);
        publicationPart = publicationPart.addClosure();

    }

    return publicationPart;
}



function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle);

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    if (type == 'archive') {
        let editorString = getInterviewEditorString(refData.contributors);

        if (editorString.length == 0) {
            editorString = 'author';
        }

        firstInfo = firstInfo.appendData('interview by ' + editorString, ', ');
    }
    else{
        firstInfo = firstInfo.appendData('interview by author', ', ');
    }

    if (authorPart.length == 0 && firstInfo.length > 0) {
        firstInfo = firstInfo.upperFirstLetter();
    }

    // let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    // firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let number = shared.getStringValue(refData.number);
    number = number.lowerFirstLetter();
    firstInfo = firstInfo.appendData(number, ', ');

    let publisherPart = '';

    let publisherLocationString;
    if(type === 'personal'){
        publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, true);
    } else {
        publisherLocationString = shared.getPublisherStringInverted(refData.publishers, true, true);
    }

    if(publisherLocationString !== 'n.p.'){
        publisherPart = publisherPart.appendData(publisherLocationString);
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    citationLocation = shared.parseTimeStampString(citationLocation);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let kind = shared.getStringValue(refData.kind);

    if (kind != 'anonymous') {
        subsequentInfo = subsequentInfo.appendData('interview', ', ');
    }
    else{
        //if they don't have an author and it's anonymous, throw in something here to avoid empty
        if (subsequentAuthorPart.length == 0) {
            subsequentInfo = subsequentInfo.appendData('interview');
        }
    }

    if (subsequentAuthorPart.length == 0 && subsequentInfo.length > 0 ) {
        subsequentInfo = subsequentInfo.upperFirstLetter();
    }

    workTitle = shared.getStringValue(refData.workTitle);
    workTitle = workTitle.shortenName().toTitleCase().italicize();

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function getInterviewEditorString(contributors) {

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof isCitation === 'undefined') {
        isSubsequent = false;
    }

    let editorArray = shared.getContributorsByType('editor', contributors);
    let editorString = '';

    //1
    if (editorArray.length == 1) {
            editorString = shared.getCitationName(editorArray[0]);
    }    


    //2
    if (editorArray.length == 2) {
        editorString = shared.getCitationName(editorArray[0]) + ' and ' + shared.getCitationName(editorArray[1]);
    }   
    
    //3 or more
    if (editorArray.length > 2) {

        let contribStringArray = [];

        //throw the contrib strings in an array before we build
        let counter = 1;
        _forEach(editorArray, (contrib) => {
            let contribString = '';
            
            contribString = shared.getCitationName(contrib);

            contribStringArray.push(contribString);
            counter++;
        });

            counter = 1;
            contribStringArray.forEach(contrib => {
            if (counter > 1) {

                if (counter == contribStringArray.length) {
                    editorString = editorString + ', and '
                }
                else{
                    editorString = editorString + ', ';
                }
            }

            editorString = editorString + contrib;
            counter++;
        });
    }

    return editorString;
}