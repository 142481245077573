const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let isTrans = isTranslated(refData);

    switch (refData.kind) {
        case 'article':
        case 'advance':
        case 'inPress':
        case 'foreign':
        case 'editorial':
        case 'retracted':
        
            //"Title of the article" [Translated article title]. Edited by Albert A. Editora. Translated by Albert A. Translatora.
            let articleTitle = shared.getStringValue(refData.articleTitle);

            if (articleTitle.startsWith('[')) {
                articleTitle = articleTitle.removeBrackets();
                titlePart = titlePart.appendData(articleTitle);
            }
            else{
                if (!isTrans) {
                    articleTitle = articleTitle.replaceAmpersand();
                    articleTitle = articleTitle.toTitleCase();
                }
    
                articleTitle = articleTitle.doubleQuote();
                titlePart = titlePart.appendData(articleTitle);
            }
            
            let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);

            if (translatedArticleTitle.length > 0 && articleTitle.length == 0) {
                translatedArticleTitle = translatedArticleTitle.doubleQuote();
            }
            else{
                translatedArticleTitle = translatedArticleTitle.bracket();
            }

            titlePart = titlePart.appendData(translatedArticleTitle);

            break;
    
        case 'section':
            
            let sectionTitle = shared.getStringValue(refData.sectionTitle);


            if (sectionTitle.startsWith('[')) {
                sectionTitle = shared.removeDescriptionFromTitle(sectionTitle);
            } else{
                if (!isTrans) {
                    sectionTitle = sectionTitle.replaceAmpersand();
                    sectionTitle = sectionTitle.toTitleCase();
                }
    
                sectionTitle = sectionTitle.doubleQuote();    
            }

            titlePart = titlePart.appendData(sectionTitle);

            let translatedSectionTitle = shared.getStringValue(refData.translatedSectionTitle);
            translatedSectionTitle = translatedSectionTitle.bracket();
            titlePart = titlePart.appendData(translatedSectionTitle);

            break;

        case 'issue':
        
            let issueTitle = shared.getStringValue(refData.issueTitle);


            if (issueTitle.startsWith('[')) {
                issueTitle = shared.removeDescriptionFromTitle(issueTitle);
            } else{
                if (!isTrans) {
                    issueTitle = issueTitle.replaceAmpersand();
                    issueTitle = issueTitle.toTitleCase();
                }
    
                issueTitle = issueTitle.doubleQuote();    
            }

            titlePart = titlePart.appendData(issueTitle);

            let translatedIssueTitle = shared.getStringValue(refData.translatedIssueTitle);
            translatedIssueTitle = translatedIssueTitle.bracket();
            titlePart = titlePart.appendData(translatedIssueTitle);

            break;
    }

    if (refData.kind == 'retracted') {
        titlePart = titlePart.appendData('(retracted)');
    }

    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);

    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    //<em>Journal Title</em> 3, no. 2 (2019): 13–21, 27.
    //<em>journalTitle</em> volume, no. issue (publicationDate.year): referencePages.

    if (refData.kind == 'issue') {
        publicationPart = publicationPart.appendData('Special issue,');
    }

    if (refData.kind == 'editorial') {
        publicationPart = publicationPart.appendData('Editorial,');
    }

    let journalTitle = shared.getStringValue(refData.journalTitle);

    if (journalTitle.startsWith('[')) {
        journalTitle = shared.removeDescriptionFromTitle(journalTitle);
    } else{
        journalTitle = journalTitle.replaceAmpersand(); 
        journalTitle = journalTitle.italicize();    
    }

    publicationPart = publicationPart.appendData(journalTitle);

    let volume = shared.getStringValue(refData.volume);
    publicationPart = publicationPart.appendData(volume);

    let issue = shared.getStringValue(refData.issue);
    publicationPart = publicationPart.appendData(issue, ', ', 'no.');

    let publicationDate = shared.getFullDate(refData.publicationDate);

    switch (refData.kind) {
        case 'inPress':
            publicationPart = publicationPart.appendData('(forthcoming)');    
            break;
    
        case 'advance':
            // publicationPart = publicationPart.appendData('Published ahead of print, n.d.');    

            if (publicationDate.length > 0) {
                publicationPart = publicationPart.appendData(publicationDate, '. ', 'Published ahead of print,');  
            }
            else{
                publicationPart = publicationPart.appendData('Published ahead of print, n.d.', '. ');
            }

            break;

        default:

            if (publicationDate.length > 0) {
                publicationPart = publicationPart.appendData(publicationDate.parenthesis());
            }

            break;
    }

    let articleNumber = shared.getStringValue(refData.articleNumber);

    if (articleNumber.length > 0) {
        publicationPart = publicationPart.appendData(articleNumber, ': ');
    }
    else{
        let referencePages = shared.getStringValue(refData.referencePages);
        referencePages = shared.formatPageNumbers(referencePages, false);
        publicationPart = publicationPart.appendData(referencePages, ': ');
    }

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //<a href="https://doi.org/10.123456789">https://doi.org/10.123456789</a>
    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let databaseTitle = shared.getStringValue(refData.databaseTitle);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (databaseTitle.length > 0) {
            locationPart = locationPart.appendData(databaseTitle);
            locationPart = locationPart.addClosure();
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url).addClosure();
                locationPart = locationPart.appendData(url);
            }
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    switch (refData.kind) {
        case 'article':
        case 'advance':
        case 'inPress':
        case 'foreign':
        case 'editorial':
        case 'retracted':
            
            citationTitle = shared.getStringValue(refData.articleTitle);

            if (citationTitle.startsWith('[')) {
                citationTitle = citationTitle.removeBrackets();
            }
            
            if (citationTitle.length == 0) {
                citationTitle = shared.getStringValue(refData.translatedArticleTitle);
            }

            break;
    
        case 'section':
            
            citationTitle = shared.getStringValue(refData.sectionTitle);

            if (citationTitle.startsWith('[')) {
                citationTitle = shared.removeDescriptionFromTitle(citationTitle);
            }

            break;

        case 'issue':
            
            citationTitle = shared.getStringValue(refData.issueTitle);

            if (citationTitle.startsWith('[')) {
                citationTitle = shared.removeDescriptionFromTitle(citationTitle);
            }

            break;
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    //Albert A. Authora et al., "Title of the article" [Translated article title], ed. Albert A Editora, trans. Albert A Translatora, <em>Journal Title</em> 3, no. 2 (2019): 4, <a href="https://doi.org/10.123456789">https://doi.org/10.123456789</a>.
    //Contributors, "articleTitle" [translatedArticleTitle]. SecondaryContribs, <em>journalTitle</em> volume, no. issue (publicationDate.year): citationLocation, doi/url.
    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let isTrans = isTranslated(refData);

    switch (refData.kind) {
        case 'article':
        case 'advance':
        case 'inPress':
        case 'foreign':
        case 'editorial':
        case 'retracted':
            
            //"Title of the article" [Translated article title]. Edited by Albert A. Editora. Translated by Albert A. Translatora.
            let articleTitle = shared.getStringValue(refData.articleTitle);

            if (articleTitle.startsWith('[')) {
                articleTitle = articleTitle.removeBrackets();
                firstInfo = firstInfo.appendData(articleTitle, ', ');
            }
            else{
                if (!isTrans) {
                    articleTitle = articleTitle.replaceAmpersand();
                    articleTitle = articleTitle.toTitleCase();
                }
    
                articleTitle = articleTitle.doubleQuote();
                firstInfo = firstInfo.appendData(articleTitle, ', ');
            }

            let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);

            if (translatedArticleTitle.length > 0 && articleTitle.length == 0) {
                translatedArticleTitle = translatedArticleTitle.doubleQuote();
                firstInfo = firstInfo.appendData(translatedArticleTitle, ', ');
            }
            else{
                translatedArticleTitle = translatedArticleTitle.bracket();
                firstInfo = firstInfo.appendData(translatedArticleTitle);
            }

            break;
    
        case 'section':
            
            let sectionTitle = shared.getStringValue(refData.sectionTitle);

            if (sectionTitle.startsWith('[')) {
                sectionTitle = shared.removeDescriptionFromTitle(sectionTitle);
            } else{
                if (!isTrans) {
                    sectionTitle = sectionTitle.replaceAmpersand();
                    sectionTitle = sectionTitle.toTitleCase();
                }
    
                sectionTitle = sectionTitle.doubleQuote();    
            }

            firstInfo = firstInfo.appendData(sectionTitle, ', ');

            let translatedSectionTitle = shared.getStringValue(refData.translatedSectionTitle);
            translatedSectionTitle = translatedSectionTitle.bracket();
            firstInfo = firstInfo.appendData(translatedSectionTitle);

            break;

        case 'issue':
        
            let issueTitle = shared.getStringValue(refData.issueTitle);

            if (issueTitle.startsWith('[')) {
                issueTitle = shared.removeDescriptionFromTitle(issueTitle);
            } else{
                if (!isTrans) {
                    issueTitle = issueTitle.replaceAmpersand();
                    issueTitle = issueTitle.toTitleCase();
                }
    
                issueTitle = issueTitle.doubleQuote();    
            }

            firstInfo = firstInfo.appendData(issueTitle, ', ');

            let translatedIssueTitle = shared.getStringValue(refData.translatedIssueTitle);
            translatedIssueTitle = translatedIssueTitle.bracket();
            firstInfo = firstInfo.appendData(translatedIssueTitle);

            break;
    }

    if (refData.kind == 'retracted') {
        firstInfo = firstInfo.appendData('(retracted)');
    }

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    if (refData.kind == 'issue') {
        firstInfo = firstInfo.appendData('special issue', ', ');
    }

    if (refData.kind == 'editorial') {
        firstInfo = firstInfo.appendData('editorial', ', ');
    }

    let journalTitle = shared.getStringValue(refData.journalTitle);

    if (journalTitle.startsWith('[')) {
        journalTitle = shared.removeDescriptionFromTitle(journalTitle);
    } else{
        journalTitle = journalTitle.replaceAmpersand(); 
        journalTitle = journalTitle.italicize();    
    }

    firstInfo = firstInfo.appendData(journalTitle, ", ", '', '', true);

    let volume = shared.getStringValue(refData.volume);
    firstInfo = firstInfo.appendData(volume);

    let issue = shared.getStringValue(refData.issue);
    firstInfo = firstInfo.appendData(issue, ', ', 'no.');

    if (refData.kind == 'inPress') {
        firstInfo = firstInfo.appendData('(forthcoming):');
    }
    else{
        let publicationDate = shared.getFullDate(refData.publicationDate);

        if (refData.kind == 'advance') {
            if (publicationDate.length > 0) {
                firstInfo = firstInfo.appendData(publicationDate, ', ', 'published ahead of print,');
                // firstInfo = firstInfo + ',';
            }
            else{
                firstInfo = firstInfo.appendData('published ahead of print, n.d.', ', ');
                // firstInfo = firstInfo + ',';
            }
        }
        else{
            if (publicationDate.length > 0) {
                firstInfo = firstInfo.appendData(publicationDate.parenthesis());
                firstInfo = firstInfo + ':';
            }
        }
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    
    let seperator = ', ';

    if (firstInfo.endsWith(':')) {
        seperator = ' ';
    }
    
    firstInfo = firstInfo.appendData(citationLocation, seperator);

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let databaseTitle = shared.getStringValue(refData.databaseTitle);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (databaseTitle.length > 0) {
            firstInfo = firstInfo.appendData(databaseTitle, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url).addClosure();
                firstInfo = firstInfo.appendData(url, ', ');
            }
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    switch (refData.kind) {
        case 'article':
        case 'advance':
        case 'inPress':
        case 'foreign':
        case 'editorial':
        case 'retracted':
            
            articleTitle = shared.getStringValue(refData.articleTitle);

            if (articleTitle.startsWith('[')) {
                articleTitle = articleTitle.removeBrackets().shortenName(isTrans);
            }
            else{
                if (!isTrans) {
                    articleTitle = articleTitle.replaceAmpersand();
                    articleTitle = articleTitle.toTitleCase();
                }
                
                articleTitle = articleTitle.shortenName(isTrans).doubleQuote();
            }
            
            if (articleTitle.length == 0) {
                translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);
                translatedArticleTitle = translatedArticleTitle.shortenName().doubleQuote();

                subsequentInfo = subsequentInfo.appendData(translatedArticleTitle, ', ');
            }
            else{
                subsequentInfo = subsequentInfo.appendData(articleTitle, ', ');
            }

            break;
    
        case 'section':
            
            sectionTitle = shared.getStringValue(refData.sectionTitle);

            if (sectionTitle.startsWith('[')) {
                sectionTitle = shared.removeDescriptionFromTitle(sectionTitle);
            } else{
                if (!isTrans) {
                    sectionTitle = sectionTitle.replaceAmpersand();
                    sectionTitle = sectionTitle.toTitleCase();
                }
    
                sectionTitle = sectionTitle.shortenName(isTrans).doubleQuote();    
            }

            subsequentInfo = subsequentInfo.appendData(sectionTitle, ', ');

            break;

        case 'issue':
            
            issueTitle = shared.getStringValue(refData.issueTitle);

            if (issueTitle.startsWith('[')) {
                issueTitle = shared.removeDescriptionFromTitle(issueTitle);
            } else{
                if (!isTrans) {
                    issueTitle = issueTitle.replaceAmpersand();
                    issueTitle = issueTitle.toTitleCase();
                }
    
                issueTitle = issueTitle.shortenName(isTrans).doubleQuote();    
            }

            subsequentInfo = subsequentInfo.appendData(issueTitle, ', ');

            break;
    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function isTranslated(refData){

    let isTrans = false;

    if (refData.kind == 'foreign') {
        return true;
    }

    let contributors = shared.removeEmptyContributors(refData.contributors);

    let translators = shared.getContributorsByType('translator', contributors);
    let chapterTranslators = shared.getContributorsByType('chapterTranslator', contributors);

    if (translators.length > 0 || chapterTranslators.length > 0) {
        isTrans = true;
    }

    return isTrans;
}