const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';

    //for this type, no contributors are primary..editors are always secondary
    let isOnlyEditorsInEntryValue = isOnlyEditorsInEntry(refData);
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    if (!isOnlyEditorsInEntryValue) {
        referenceValue = referenceValue.appendData(authorPart);
    }
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    // any authors (any contributors that are not editors?)
    let isAnyAuthorsValue = isAnyAuthors(refData);
   
    // does this have any contributors?
    if(isAnyAuthorsValue){
        // yes
        let workTitle = shared.getStringValue(refData.entryTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
        }

        titlePart = titlePart.appendData(workTitle);
        titlePart = titlePart.addClosure();    
    } else {
        // no

    }

    let referenceTitle = shared.getStringValue(refData.referenceTitle);
    if(type === 'entry'){
        if(kind === 'wikipedia'){
            titlePart = titlePart.appendData('Wikipedia');
        }

        if (referenceTitle.startsWith('[')) {
            referenceTitle = shared.removeDescriptionFromTitle(referenceTitle);
        } else{
            referenceTitle = referenceTitle.replaceAmpersand().toTitleCase().italicize();   
        }

        if(isAnyAuthorsValue){
            titlePart = titlePart.appendData(referenceTitle, ' ', 'In');
        } else {
            titlePart = titlePart.appendData(referenceTitle);
        }
        
        titlePart = titlePart.addClosure();
    } else if(type === 'whole'){
        if (referenceTitle.startsWith('[')) {
            referenceTitle = shared.removeDescriptionFromTitle(referenceTitle);
        } else{
            referenceTitle = referenceTitle.replaceAmpersand().toTitleCase().italicize();   
        }

        titlePart = titlePart.appendData(referenceTitle);
        titlePart = titlePart.addClosure();
    }

    //for this type, no contributors are primary..editors are always secondary
    let isOnlyEditorsInEntryValue = isOnlyEditorsInEntry(refData);
    let secondaryContributors = '';

    if (isOnlyEditorsInEntryValue) {
        secondaryContributors = shared.getSecondaryContributorTypeString(refData.contributors, 'editor');
        titlePart = titlePart.appendData(secondaryContributors);
        titlePart = titlePart.addClosure();
    }
    else{
        secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
        titlePart = titlePart.appendData(secondaryContributors);
        titlePart = titlePart.addClosure();
    }

    let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
    titlePart = titlePart.appendData(edition);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let publicationPart = '';
    let publisherString = shared.getPublisherString(refData.publishers);

    if (shared.isSelfPublished(refData)) {
        publicationPart = publicationPart.appendData('Self-published');
    } else{
        // console.log('publisherString');
        // console.log(publisherString);
        if(publisherString === 'N.p.'){
            if(type === 'entry' && kind === 'wikipedia'){
                // publicationPart = publicationPart.appendData(publisherString);
            } else {
                publicationPart = publicationPart.appendData(publisherString);
            }
            
        } else {
            publicationPart = publicationPart.appendData(publisherString);
        }
    }

    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    retrievalDate = shared.getFullDate(retrievalDate);
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    
    if(publicationDate === 'n.d.'){
        
        if(retrievalDate === 'n.d.'){
            if(publisherString === 'N.p.'){
                if(type === 'entry' && kind === 'wikipedia'){
                    // publicationPart = publicationPart.appendData(publicationDate, ': ');
                } else {
                    publicationPart = publicationPart.appendData(publicationDate, ', ');
                }
            } else {
                publicationPart = publicationPart.appendData(publicationDate, ', ');
            }
        } else {
            if (retrievalDate.length > 0) {
                publicationPart = publicationPart.addClosure();
                publicationPart = publicationPart.appendData(retrievalDate, ' ', 'Accessed');

            } else{
                publicationPart = publicationPart.appendData(retrievalDate, ', ');

            }
        }
        
    } else {
        if(type === 'entry'){
            if(kind === 'wikipedia'){
                publicationPart = publicationPart.appendData(publicationDate, ', ', 'Last modified');
            } else {
                publicationPart = publicationPart.appendData(publicationDate, ', ');
            }
        } else {
            publicationPart = publicationPart.appendData(publicationDate, ', ');
        }
    }


    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.entryTitle);

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'entryTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //for this type, no contributors are primary..editors are always secondary
    let isOnlyEditorsInEntryValue = isOnlyEditorsInEntry(refData);
    
    // any authors (any contributors that are not editors?)
    let isAnyAuthorsValue = isAnyAuthors(refData);
    
    if(!isOnlyEditorsInEntryValue){
        let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
        firstInfo = firstInfo.appendData(authorPart);
    }
    
    let workTitle = shared.getStringValue(refData.entryTitle);
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
    }

    if(isAnyAuthorsValue){
        firstInfo = firstInfo.appendData(workTitle, ', ');
    }

    if(type === 'entry'){
        if(kind === 'wikipedia'){
            firstInfo = firstInfo.appendData('Wikipedia', ', ');
        }
    }

    
    let referenceTitle = shared.getStringValue(refData.referenceTitle);
    if(type === 'entry'){

        if (referenceTitle.startsWith('[')) {
            referenceTitle = shared.removeDescriptionFromTitle(referenceTitle);
        } else{
            referenceTitle = referenceTitle.replaceAmpersand().toTitleCase().italicize();    
        }

        if(isAnyAuthorsValue){
            firstInfo = firstInfo.appendData(referenceTitle, ', ', 'in');
        } else {
            firstInfo = firstInfo.appendData(referenceTitle, ', ');
            firstInfo = firstInfo.appendData(workTitle, ', ', 's.v.');
        }

    } else if(type === 'whole'){
        if (referenceTitle.startsWith('[')) {
            referenceTitle = shared.removeDescriptionFromTitle(referenceTitle);
        } else{
            referenceTitle = referenceTitle.replaceAmpersand().toTitleCase().italicize();    
        }

        firstInfo = firstInfo.appendData(referenceTitle, ', ');
    }
    
    let secondaryContributors = '';
    if (isOnlyEditorsInEntryValue) {
        secondaryContributors = shared.getSecondaryContributorCitationTypeString(refData.contributors, 'editor');
        firstInfo = firstInfo.appendData(secondaryContributors, ", ");
    }
    else{
        secondaryContributors = shared.getSecondaryContributorCitationString(refData);
        firstInfo = firstInfo.appendData(secondaryContributors, ", ");
    }
    
    let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
    firstInfo = firstInfo.appendData(edition, ', ');
    
    let publisherPart = '';
    
    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);

    if (shared.isSelfPublished(refData)) {
        publisherPart = publisherPart.appendData('self-pub.');
    } else{
        // if(publisherLocationString !== 'n.p.'){
            publisherPart = publisherPart.appendData(publisherLocationString, ', ');
        // }
    }

    let datePart = '';

   
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);  
    
    let retrievalDate = shared.getStringValue(refData.retrievalDate);

    if(type === 'entry' && kind === 'wikipedia'){
        if(publicationDate !== 'n.d.'){
            datePart = datePart.appendData(publicationDate, ', ', 'last modified');
            publisherPart = publisherPart.appendData(datePart, ', ');
            firstInfo = firstInfo.appendData(publisherPart, ', ');
        }
    } else {
        datePart = datePart.appendData(publicationDate, ', ');
        if(publicationDate === 'n.d.' && publisherLocationString !== 'n.p.'){
            // if(publicationDate === 'n.d.' && !publisherLocationString.startsWith('n.p.')){
            // publisherPart = publisherPart.appendData(datePart, ': ');
            if (retrievalDate.length == 0) {
                publisherPart = publisherPart.appendData(datePart, ', ');
            }
        } else {
            
            publisherPart = publisherPart.appendData(datePart, ', ');
        }

        publisherPart = publisherPart.parenthesis();

        firstInfo = firstInfo.appendData(publisherPart, ', ');

    }

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');
    
    if(publicationDate === 'n.d.'){
        retrievalDate = shared.getFullDate(retrievalDate);
        if(retrievalDate !== 'n.d.'){
            firstInfo = firstInfo.appendData(retrievalDate, ', ', 'accessed');
        }
    }

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    if(!isOnlyEditorsInEntryValue){
        let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
        subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    }
    
    let referenceTitleSubsequent = shared.getStringValue(refData.referenceTitle);
    let workTitleSubsequent = shared.getStringValue(refData.entryTitle);

    if(type === 'entry'){
        
        if (referenceTitleSubsequent.startsWith('[')) {
            referenceTitleSubsequent = shared.removeDescriptionFromTitle(referenceTitleSubsequent);
        } else{
            referenceTitleSubsequent = referenceTitleSubsequent.replaceAmpersand().toTitleCase().italicize();    
        }

        if (workTitleSubsequent.startsWith('[')) {
            workTitleSubsequent = shared.removeDescriptionFromTitle(workTitleSubsequent);
        } else{
            workTitleSubsequent = workTitleSubsequent.replaceAmpersand().shortenName().toTitleCase().doubleQuote();    
        }

        if(isAnyAuthorsValue){
            subsequentInfo = subsequentInfo.appendData(workTitleSubsequent, ', ');
        } else {
            subsequentInfo = subsequentInfo.appendData(referenceTitleSubsequent, ', ');
            subsequentInfo = subsequentInfo.appendData(workTitleSubsequent, ', ', 's.v.');
        }
        
        
    } else if(type === 'whole'){
        if (referenceTitleSubsequent.startsWith('[')) {
            referenceTitleSubsequent = shared.removeDescriptionFromTitle(referenceTitleSubsequent);
        } else{
            referenceTitleSubsequent = referenceTitleSubsequent.replaceAmpersand().toTitleCase().italicize();    
        }
        
        workTitleSubsequent = workTitleSubsequent.appendData(referenceTitleSubsequent, ', ');
        subsequentInfo = subsequentInfo.appendData(workTitleSubsequent, ', ');
    }

    
    

    

    // if(type === 'entry'){
    //     referenceTitleSubsequent = referenceTitleSubsequent.toTitleCase().italicize();
    //     workTitleSubsequent = workTitleSubsequent.appendData(referenceTitleSubsequent, ', ');
    //     if(isAnyAuthorsValue){
    //     //     firstInfo = firstInfo.appendData(referenceTitle, ', ', 'in');
    //     } else {
    //         console.log('car')
    //     //     firstInfo = firstInfo.appendData(referenceTitle, ', ');
    //     //     firstInfo = firstInfo.appendData(workTitle, ', ', 's.v.');
    //     }

    // } else if(type === 'whole'){
        
    //     referenceTitleSubsequent = referenceTitleSubsequent.toTitleCase().italicize();
    //     workTitleSubsequent = workTitleSubsequent.appendData(referenceTitleSubsequent, ', ');
    // }
    
    // console.log('referenceTitle');
    // console.log(referenceTitle);

    // subsequentInfo = subsequentInfo.appendData(workTitleSubsequent, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}


function isOnlyEditorsInEntry(refData){
    let type = shared.getStringValue(refData.type);

    let returnValue = false;

    if (typeof refData.contributors == 'undefined' || type !== 'entry'){
        return returnValue;
    }

    if (refData.contributors.length > 0) {
        
        if (refData.contributors[0].type == 'editor') {
            
            returnValue = true;
            
			_forEach(refData.contributors, (contrib) => {
                if (contrib.type != 'editor') {
                    returnValue = true;
                }
            });
        }
    }

    return returnValue;
}//e:isOnlyEditorsInEntry


function isAnyAuthors(refData){
    let returnValue = false;

    let kind = shared.getStringValue(refData.kind);
    
    if (typeof refData.contributors == 'undefined'){
        return returnValue;
    }
    
    // this flag can be ignored if it's wikipedia without contributors is ok
    if (kind === 'wikipedia'){
        return true;
    }

    if (refData.contributors.length > 0) {
        _forEach(refData.contributors, (contrib) => {
            if (
                contrib.type === 'author' ||
                contrib.type === 'groupAuthor' ||
                contrib.type === 'username' 
            ) {
                returnValue = true;
            }
        });
    }


    return returnValue;
}//e:isAnyAuthors

function hasEditorsOnly(contributors){
    let editorOnly = false;

    if (typeof contributors == 'undefined'){
        return editorOnly;
    }

    if (contributors.length > 0) {
        
        if (contributors[0].type == 'editor') {
            
            editorOnly = true;
            
			_forEach(contributors, (contrib) => {
                if (contrib.type != 'editor') {
                    editorOnly = true;
                }
            });
        }
    }

    return editorOnly;
}