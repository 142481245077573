const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}


function getCitationTitle(refData){

    let citationTitle = 'United States';

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    let type = shared.getStringValue(refData.type);
    let primaryContributor = 'United States';

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (kind) {
        case 'house':
        case 'houseJoint':
            primaryPart = 'United States, Congress, House';
            break;
        case 'senate':
        case 'senateJoint':
            primaryPart = 'United States, Congress, Senate';
        break;
        case '':
            primaryPart = 'United States, Congress';
        break;
    }

    primaryPart = shared.fixClosure(primaryPart);

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            // title = title.italicize();
        }

        primaryPart = primaryPart.appendData(title, ' ');
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type == 'enactedFed') {
        let volume = shared.getStringValue(refData.volume);
        let pageNumber = shared.getStringValue(refData.pageNumber);

        if (volume.length > 0 || pageNumber.length > 0) {
            container1 = container1.appendData('Congressional Record', ', ');
        
            //volume
            container1 = container1.appendData(volume, ' ', '', '');

            //pageNumber
            pageNumber = shared.formatPageNumbers(pageNumber);
            container1 = container1.appendData(pageNumber, ', ');
        }
    }

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let session = shared.getStringValue(refData.session);

    if (session.length > 0) {
        session = shared.getOrdinalValue(session);
        container2 = container2.appendData(session, ', ', '', 'Congress');
    }

    let label = '';

    switch (kind) {
        case 'senate':
            container2 = container2.appendData('Senate Resolution', ', ');
            break;
        case 'house':
            container2 = container2.appendData('House Resolution', ', ');
            break;
        case 'senateJoint':
            container2 = container2.appendData('Senate Joint Resolution', ', ');
            break;
        case 'houseJoint':
            container2 = container2.appendData('House Joint Resolution', ', ');
            break;
    }

    let billNumber = shared.getStringValue(refData.billNumber);
    container2 = container2.appendData(billNumber, ' ', '', '');

    //publicationDate
    let pubDateLabel = 'passed';

    if (type == 'unenactedFed') {
        pubDateLabel = 'considered'
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    container2 = container2.appendData(publicationDate, ', ', pubDateLabel);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}