/*
- ApiS_MakeCall
-- Makes an API call to the server
-- 2023.11.27
*/

import _has from 'lodash/has';
import _merge from 'lodash/merge';
import AS_CheckToken from '@/services/app/checkToken';
import config from '@/config';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';

export default ($opts) => {
	return new Promise((resolve, reject) => {
	
		let isRejectWithMessage = false;
		
		// structure this api call as a promise so if it fails for specific reasons i can correct them and try again
		let makeTheApiCall = () =>{
			return new Promise((resolve, reject) => {
				// console.log('ApiS_MakeCall');
				
				isRejectWithMessage = false;
				
				let _contentType = _has($opts, 'contentType') ? $opts.contentType : 'application/json';
				let _ignoreAuth = _has($opts, 'ignoreAuth') ? $opts.ignoreAuth : false;
				let _outsideSpa = _has($opts, 'outsideSpa') ? $opts.outsideSpa : false;
				let _responseType = _has($opts, 'responseType') ? $opts.responseType : 'none';
				
				let requestParams = {
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					method: $opts.method,
				};

				let sourceAppOutput = '';
				let wordVersionOutput = '';
				if(config.platformId === config.enums.Platform.ADD_IN){
					sourceAppOutput = 'WordAddIn'
					wordVersionOutput = Office.context.diagnostics.version;
				} else if(config.platformId === config.enums.Platform.ONLINE){
					sourceAppOutput = 'Online'
				}

				// build headers
				let headerObject = {
					'Browser': store.state.api.browserName,
					'BrowserVersion': store.state.api.browserVersion,
					'Cache-Control': 'no-store, max-age=0',
					'Content-Type': _contentType,
					'CustomerID': store.state.api.customerId,
					'IsImpersonating': store.state.customer.isImpersonating,
					'organizationExpirationDate': store.state.customer.organizationExpirationDate,
					'organizationID': store.state.customer.organizationId,
					'organizationStatusID': store.state.customer.organizationStatusId,
					'OS': store.state.api.osName,
					'OSVersion': store.state.api.osVersion,
					'SessionID': store.state.api.sessionId,
					'SourceApp': sourceAppOutput,
					'SourceAppVersion': VERSION,
					'WordVersion': wordVersionOutput
				}

				// add url 
				let _url;

				// a hard url gets a full url from the opts instead of getting built based on the app
				if($opts.isHardUrl){
					_url = new URL($opts.url);
				} else {
					if (_outsideSpa) {
						// legacy items won't have an api, so route directly in to the MVC project
						_url = new URL(config.appUrl);
						_url.href += $opts.url;
					} else {
						if(config.isLive){
							_url = new URL('https://api.perrla.com');
						} else {
							_url = new URL('https://testapi.perrla.com');
						}
						
						_url.href += $opts.url;
					}
				}
				
				if(!_ignoreAuth){
					_merge(headerObject, {
						'Authorization': "Bearer " + store.state.api.token
					});
				}

				// add url params
				if($opts.params){
					_url.search = new URLSearchParams($opts.params);
				}

				requestParams.headers = new Headers(headerObject);

				// add body
				if($opts.body){
					if(typeof $opts.body === 'string'){
						requestParams.body = $opts.body;
					} else {
						requestParams.body = JSON.stringify($opts.body);
					}
				}

				// use Fetch API
				fetch(new Request(_url, requestParams)).then((response) => {
					// this first then() inside fetch just parses the data and returned it to the next then()
					if (response.ok) {
						// Success
						if(_responseType === 'arraybuffer'){
							return response.arrayBuffer();
						} else if(_responseType === 'json'){
							return response.json();
						} else if(_responseType === 'text'){
							return response.text();
						} else {
							// an api call that doesn't have or care about the result
							return response;
						}
					} else {
						// Fail
						if(response.status == 400){
							// bad request
							return reject('Bad Request');
							
						} else if(response.status == 401){
							// user is unauthorized
							return reject('Token Expired');
						
						} else if(response.status == 404){
							Swal.fire({
								buttonsStyling: false,
								text: '404 - There was an error processing this request',
								icon: 'error',
								confirmButtonText: 'Ok',
								showCloseButton: false,
								showConfirmButton: true,
								customClass:{
									confirmButton: 'btn btn-danger me-2',
								},
							}).then((result) => {
								if (result.value) {
									// reject(responseData[0].message);
									return reject('404');
								}
							});

						} else if(response.status == 500){
							// server error - return a text result
							isRejectWithMessage = true;
							return response.json();

						} else {
							// Other
							// generic error - usually a 404 at this point
							let responseError = '';
							if(response.status){
								responseError += response.status + ' ';
							}
							if(response.statusText){
								responseError += response.statusText;
							}

							return reject(responseError);

						}//e:else

					}//e:if:response.ok

				}).then((responseData) => {
					// data is processed earlier, this then() resolves this makeTheApiCall promise 
					if(isRejectWithMessage){
						// 500 errors return a reason why, pass the first one off to show here
						let handleErrorAtSource = false;
						let messageOutput = '';
						
						if(config.platformId === config.enums.Platform.ADD_IN){
							// 500 errors return a reason why, pass the first one off to show here
							if($opts.url === 'v4/PaymentMethod' && $opts.method === 'GET'){
								switch(responseData[0].code){
									case "NO_VALID_METHODS":
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}
								
							} else if($opts.url === 'v4/PaymentMethod' && $opts.method === 'POST'){
								switch(responseData[0].code){
									case 'AUTH_PROFILE':
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}

							} else if($opts.url === 'v4/Purchase' && $opts.method === 'POST'){
								switch(responseData[0].code){
									case 'AUTH_PROFILE':
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}

							} else {
								switch(responseData[0].code){
									case "ALREADY_CUSTOMER":
										messageOutput = 'This email is already being used for a PERRLA Account.';
										break;
									case 'CUSTOMER_NOT_IN_TRIAL':
										// v4/FreeTrial/Discount
										handleErrorAtSource = true;
										break;
									case 'DISCOUNT_EXISTS':
										// v4/FreeTrial/Discount
										handleErrorAtSource = true;
										break;
									case "INVALID_CODE":
										messageOutput = 'Invalid code: Try again or contact our Support Team if you continue to have trouble (support@perrla.com).';
										break;
									case 'TRIAL_EXPIRED':
										// v4/FreeTrial/Discount
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}

							}

						} else if(config.platformId === config.enums.Platform.ONLINE){
							// Online has checks for the url a user is trying to access
							if($opts.url === 'v4/CohortUser/Activate' && $opts.method === 'GET'){
								switch(responseData[0].code){
									case "EXPIRED_ORG":
									case "INVALID_ID":
									case "INVALID_ORG":
									case "ORG_CAPACITY_REACHED":
									case "USER_ALREADY_CREATED":
									case "USER_DELETED":
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}
							} else if($opts.url === 'v4/FreeTrial'){
								switch(responseData[0].code){
									case 'PENDING_ORG_INVITE':
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}
							} else if($opts.url === 'v4/Purchase' && $opts.method === 'POST'){		
								switch(responseData[0].code){
									case 'AUTH_PROFILE':
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}
							} else if($opts.url === 'v4/Purchase/User' && $opts.method === 'POST'){
								switch(responseData[0].code){
									case 'ALREADY_CUSTOMER_INVALID':
									case 'ALREADY_CUSTOMER_VALID':
									case 'PENDING_INVITATION':
										handleErrorAtSource = true;
										break;
									default:
										console.log(responseData[0]);
										messageOutput = responseData[0].message;
								}
								
							} else if($opts.url === 'v4/PaymentMethod' && $opts.method === 'GET'){
								switch(responseData[0].code){
									case "NO_VALID_METHODS":
										handleErrorAtSource = true;
										break;
									default:
										messageOutput = responseData[0].message;
								}

							} else {
								messageOutput = responseData[0].message;

							}

						}//e:else:online

						if(handleErrorAtSource){
							// reject to source and handle the error code there
							reject(responseData[0]);

						} else {
							if(config.platformId === config.enums.Platform.ADD_IN){
								window.$vm.emitter.emit('toasterOpen', {
									body: messageOutput,
									isSticky: true,
									title: 'Error',
									type: 'danger'
								});
								reject();

							} else if(config.platformId === config.enums.Platform.ONLINE){
								
								Swal.fire({
									buttonsStyling: false,
									text: responseData[0].message,
									icon: 'error',
									confirmButtonText: 'Ok',
									showCloseButton: false,
									showConfirmButton: true,
									customClass:{
										confirmButton: 'btn btn-danger me-2',
									},
								}).then((result) => {
									if (result.value) {
										reject(responseData[0].message);
									}
								});
							
							}//e:else:online
						}//e:else:handleErrorAtSource

					} else {
						return resolve(responseData);
					}

				}).catch((error) => {
					// fetch call failed for any reason
					if(config.platformId === config.enums.Platform.ADD_IN){
						reject(error.message);

					} else if(config.platformId === config.enums.Platform.ONLINE){
						let failReasonDisplay = error.message;
						if(failReasonDisplay === 'Failed to fetch'){
							failReasonDisplay = 'There was an error with this request. Please try again.'
						}

						Swal.fire({
							allowOutsideClick: false,
							buttonsStyling: false,
							text: failReasonDisplay,
							icon: 'error',
							confirmButtonText: 'OK',
							showCloseButton: false,
							showConfirmButton: true,
							customClass:{
								confirmButton: 'btn btn-danger me-2',
							},
						}).then((result) => {
							// if (result.value) {
							// 	reject(failReasonDisplay);
							// }
						});
						return reject(failReasonDisplay);
					}//e:else:online
					
				});//fetch.catch

			});//e:Promise
		};//e:makeTheApiCall

		if(navigator.onLine) {
			if($opts.ignoreAuth){
				// ignoreAuth - just make the call
				makeTheApiCall($opts).then((responseData)=>{
					// first call success, resolve back to the original makeApiCall call
					return resolve(responseData);
	
				}).catch((failReason)=>{
					console.log('failReason 1');
					console.log(failReason);

					return reject(failReason);
				});//e:makeTheApiCall.then

			} else {
				// use Auth - check account
				AS_CheckToken().then((apiToken)=>{
					store.commit('api/SET_TOKEN', apiToken);

					makeTheApiCall($opts).then((responseData)=>{
						// first call success, resolve back to the original makeApiCall call
						return resolve(responseData);
		
					}).catch((failReason)=>{
						// first call failed
						if(failReason === 'Token Expired'){
							// get a new token and make this call again

							// blank out tokens and session ids
							store.commit('api/SET_SESSION_ID', '');
							store.commit('api/SET_TOKEN', '');

							AS_CheckToken().then((apiToken)=>{
								// clone the original options since i need to make this call one more time
								let clonedOpts = {
									method: $opts.method,
									outsideSpa: $opts.outsideSpa,
									params: $opts.params,
									responseType: $opts.responseType,
									url: $opts.url,
								}
								if(_has($opts, 'body')){
									clonedOpts['body'] = $opts.body;
								}
								if(_has($opts, 'contentType')){
									clonedOpts['contentType'] = $opts.contentType;
								}
						
								makeTheApiCall(clonedOpts).then((responseData)=>{
									// second call success, resolve back to the original makeApiCall call
									resolve(responseData);
									
								}).catch(()=>{
									// getting a new token didn't fix the problem, have the user log out
									if(config.platformId === config.enums.Platform.ADD_IN){
										// automatically
										store.commit('api/SET_TOKEN', '');
										store.commit('USER_EMAIL', '');
										window.location = '/Account/LogOff'
										return reject();

									} else if(config.platformId === config.enums.Platform.ONLINE){
										// with an alert
										
										Swal.fire({
											buttonsStyling: false,
											text: 'Token has expired.',
											icon: 'error',
											confirmButtonText: 'Log Out',
											showCloseButton: false,
											showConfirmButton: true,
											customClass:{
												confirmButton: 'btn btn-danger',
											},
										}).then((result) => {
											if (result.value) {
												store.commit('api/SET_TOKEN', '');
												store.commit('USER_EMAIL', '');
												window.location = '/Account/LogOff'
												return reject();
											}
										});

									}//e:else:online

								});//e:catch

							});//e:AS_CheckToken

						} else if(failReason === 'Bad Request'){
							return reject();

						} else if(failReason === 'expired'){
							// account suscription expired
							router.push({
								name: 'Settings',
							}).catch(()=>{});
							
							return reject();
							
						} else {
							// many reasons it failed
							if(config.platformId === config.enums.Platform.ADD_IN){
								// general reject()
								return reject(failReason);

							} else if(config.platformId === config.enums.Platform.ONLINE){
								if(!isRejectWithMessage){
									store.commit('loaders/REMOVE_ID', 'App');
									// 2023.06.21 - commented this out, so it doesn't fire the Swal twice
									// if(failReason === 'Failed to fetch'){
									// 	failReason = 'There was an error with this request. Please try again.'
									// }

									// Swal.fire({
									// 	buttonsStyling: false,
									// 	text: failReason,
									// 	icon: 'error',
									// 	confirmButtonText: 'Ok',
									// 	showCloseButton: false,
									// 	showConfirmButton: true,
									// 	customClass:{
									// 		confirmButton: 'btn btn-danger me-2',
									// 	},
									// });
								}//e:if:!isRejectWithMessage

								return reject(failReason);

							}//e:else:online

						}//e:else:failReason

					});//e:makeTheApiCall.then
				});

			}//e:else:$opts.ignoreAuth

		} else {
			// no connection
			let errorText = '';

			if(config.platformId === config.enums.Platform.ADD_IN){
				errorText = 'Your computer may have lost its connection to the internet. Please reconnect to the internet and try again.';
				store.commit('loaders/REMOVE_ID', 'App');

			} else if(config.platformId === config.enums.Platform.ONLINE){
				// change error text based on route (am i in a paper or not)
				
				if(router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
					errorText = 'Your computer may have lost its connection to the internet and we cannot save the latest changes to your paper. Please reconnect to the internet and try to save your paper again.';
					store.dispatch('paperEdit/localVersion/checkIn');
				} else {
					errorText = 'Your computer may have lost its connection to the internet. Please reconnect to the internet and try again.';
				}

				Swal.fire({
					allowOutsideClick: false,
					buttonsStyling: false,
					title: 'Internet connection failed',
					text: errorText,
					icon: 'error',
					confirmButtonText: 'OK',
					showCloseButton: false,
					showConfirmButton: true,
					customClass:{
						confirmButton: 'btn btn-danger me-2',
					},
				}).then((result) => {
					if (result.value) {
						store.commit('loaders/REMOVE_ID', 'App');
					}
				});
				
			}//e:else:online

		}//e:if:else:navigator.onLine

	});//e:Promise
}
