const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';
    
    if (isSelfPublished(refData)) {
        publicationPart = publicationPart.appendData('Self-published');
    } else{
        let publisherString = shared.getPublisherString(refData.institution, 'reference', false, true);
        publicationPart = publicationPart.appendData(publisherString);
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    publicationPart = publicationPart.addClosure();

    publicationPart = publicationPart.appendData('Grant.');

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';


    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        workTitle = workTitle.toLowerCase();
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }
    firstInfo = firstInfo.appendData(workTitle, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.customLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');
    
    let publisherLocationString = shared.getPublisherString(refData.institution, 'reference', true);
    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';

    if (isSelfPublished(refData)) {
        publisherPart = publisherPart.appendData('self-pub.');
    }
    else{
        publisherPart = publisherPart.appendData(publisherLocationString);
    }
    
    publisherPart = publisherPart.appendData(publicationDate, ', ');
    publisherPart = publisherPart.parenthesis();
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    firstInfo = firstInfo.appendData('grant', ', ');    

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    
    let subsequentWorkTitle = shared.getStringValue(refData.title);
    if (subsequentWorkTitle.startsWith('[')) {
        subsequentWorkTitle = shared.removeDescriptionFromTitle(subsequentWorkTitle);
        subsequentWorkTitle = subsequentWorkTitle.shortenName().toLowerCase();
    } else{
        subsequentWorkTitle = shared.getStringValue(refData.title);
        subsequentWorkTitle = subsequentWorkTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
    }

    

    subsequentInfo = subsequentInfo.appendData(subsequentWorkTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

//using a copy instead of shared....this type doesnt have publishers, but institutions
function isSelfPublished(refData){
    let isSelf = false;

    let primaryType = shared.getPrimaryContributorType(refData);
    let primaryContributors = shared.getContributorsByType(primaryType, refData.contributors);

    if (typeof refData.institution === 'undefined') {
        return false;
    }

    if (primaryContributors.length == 1 && refData.institution.length == 1) {
        if (primaryContributors[0].type == 'groupAuthor') {
            if (primaryContributors[0].groupName == refData.institution[0].name) {
                isSelf = true;
            }
        }
    }

    return isSelf;
}