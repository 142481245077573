/*
- Class Modules
-- ex: this.$store.state.classes.x
*/

export default {
	namespaced: true,

	state: {
		// non local storage states
		list: [],			// array of objects sets in /Sync
		openObject:	{},	// the class open that is being edited
	},//e:state

	mutations: {
		SET_OPEN_OBJECT(state, value){
			state.openObject = value;
		},
		SET_LIST(state, value) {
			state.list = value;
		},
	},//e:mutations
}
