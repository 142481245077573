const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);

    if (primaryContributor.length == 0) {
        primaryContributor = "Advertisement";
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let isSecondaryTitle = true;

    let fakeRefData = new Object();
    fakeRefData.contributors = [];
    fakeRefData.title = refData.title;
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);

    if (primaryContributor.length == 0) {
        primaryContributor = "Advertisement";
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let type = shared.getStringValue(refData.type);

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.doubleQuote();
        }

        primaryPart = primaryPart.appendData(title, ', ');
    }
    else {
        let sponsor = shared.getStringValue(refData.sponsor);

        if (sponsor.length > 0) {
            primaryPart = primaryPart.appendData(sponsor, ', ', 'Advertisement for');
        }
        else {
            primaryPart = primaryPart.appendData('Advertisement', ', ');
        }

    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let sponsor = shared.getStringValue(refData.sponsor);
    let title = shared.getStringValue(refData.title);

    if (sponsor.length > 0 && title.length > 0) {
        sponsor = sponsor.toTitleCase();
        sponsor = sponsor.italicize();

        container1 = container1.appendData(sponsor, ', ');
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    //location
    let location = shared.getStringValue(refData.location);
    container1 = container1.appendData(location, ', ', '', '');

    //url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
        container1 = container1.appendData(url, ', ');
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //format
    let format = shared.getStringValue(refData.format);

    if (format.length > 0) {
        secondSupplemental = secondSupplemental.appendData(format, ', ', '', '');

        if (type == 'digital') {
            secondSupplemental = secondSupplemental.appendData('advertisement', ' ');
        }
    }
    else {
        let sponsor = shared.getStringValue(refData.sponsor);
        let title = shared.getStringValue(refData.title);

        if (sponsor.length == 0 && title.length > 0) {
            secondSupplemental = secondSupplemental.appendData('Advertisement', ', ');
        }
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}