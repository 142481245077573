const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    
    let authorPart = '';
    
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    // start by getting the different contibutor types (host | executiveProducer | interview)
    // i need to use them in different ways later based on type and kind
    let hosts = getContriutorByType(refData.contributors, 'host');
    let hostsString = '';
    let executiveProducers = getContriutorByType(refData.contributors, 'executiveProducer');
    let executiveProducersString = '';
    
    let isDescriptionUsedForTitle = false;

    let workTitle = shared.getStringValue(refData.title);
    if (workTitle.startsWith('[')) {
        isDescriptionUsedForTitle = true;
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }
    
    workTitle = workTitle.addClosure();

    // do i need to convert Hosts into a 'Hosted by' string
    if(
        type === 'series' ||
        (type === 'episode' && kind === 'radio')
    ){
        if (hosts.length > 0) {
            hostsString = shared.getSecondaryContributorTypeString(hosts, 'host');
            hostsString = hostsString.addClosure();
        }     
    }

    // do i need to convert Executive Producers into a 'Produced by' string
    if(type !== 'interview'){
        if (executiveProducers.length > 0) {
            executiveProducersString = shared.getSecondaryContributorTypeString(executiveProducers, 'executiveProducer');
            executiveProducersString = executiveProducersString.addClosure();
            
        }     
    }

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    
    if(publicationDate !== 'n.d.'){
        publicationDate = publicationDate.addClosure();
    }

    let length = shared.parseTimeStampString(refData.length);

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
    }
    
    switch(type){
        case 'series':
            referenceValue = referenceValue.appendData(workTitle);
            referenceValue = referenceValue.appendData(hostsString);
            referenceValue = referenceValue.appendData(executiveProducersString);
            referenceValue = referenceValue.appendData(publisherString, ' ', 'On');

            if(publicationDate !== 'n.d.'){
                if(publisherString === ''){
                    referenceValue = referenceValue.appendData(publicationDate);
                } else {
                    referenceValue = referenceValue.appendData(publicationDate, ', ');
                }
            }

            switch(kind){
                case 'audio':
                    referenceValue = referenceValue.appendData('Podcast.');
                    break;
                case 'radio':
                    referenceValue = referenceValue.appendData('Radio series.');
                    break;
                case 'video':
                    referenceValue = referenceValue.appendData('Video podcast.');
                    break;
            }

            referenceValue = referenceValue.appendData(url);
            break;

        case 'episode':
            let episodeTitle = shared.getStringValue(refData.episodeTitle);
            if (episodeTitle.startsWith('[')) {
                episodeTitle = shared.removeDescriptionFromTitle(episodeTitle);
            } else{
                episodeTitle = episodeTitle.replaceAmpersand().toTitleCase().doubleQuote();
            }
            episodeTitle = episodeTitle.addClosure();

            switch(kind){
                case 'audio':
                    authorPart = shared.getAuthorPart(refData);
                    referenceValue = referenceValue.appendData(authorPart);
                    referenceValue = referenceValue.appendData(episodeTitle);
                    referenceValue = referenceValue.appendData(executiveProducersString);
                    if(publicationDate !== 'n.d.'){
                        referenceValue = referenceValue.appendData(publicationDate);
                    }
                    if(isDescriptionUsedForTitle){
                        workTitle = workTitle.toLowerCase();
                    }
                    referenceValue = referenceValue.appendData(workTitle, ' ', 'In');
                    referenceValue = referenceValue.appendData('Podcast');
                    referenceValue = referenceValue.appendData(length, ', ');
                    break;
                case 'radio':
                    referenceValue = referenceValue.appendData(episodeTitle);
                    referenceValue = referenceValue.appendData(hostsString);
                    referenceValue = referenceValue.appendData(workTitle);
                    referenceValue = referenceValue.appendData(publisherString, ' ', 'On');
                    referenceValue = referenceValue.addClosure();
                    if(publicationDate !== 'n.d.'){
                        referenceValue = referenceValue.appendData(publicationDate);
                    }
                    break;
                case 'video':
                    authorPart = shared.getAuthorPart(refData);
                    referenceValue = referenceValue.appendData(authorPart);
                    referenceValue = referenceValue.appendData(episodeTitle);
                    referenceValue = referenceValue.appendData(executiveProducersString);
                    if(publicationDate !== 'n.d.'){
                        referenceValue = referenceValue.appendData(publicationDate);
                    }
                    referenceValue = referenceValue.appendData(workTitle, ' ', 'In');
                    referenceValue = referenceValue.appendData('Video');
                    referenceValue = referenceValue.appendData(length, ', ');
                    break;
            }
            referenceValue = referenceValue.addClosure();
            referenceValue = referenceValue.appendData(url);
            break;
        case 'interview':
            let workTitleInterview = shared.getStringValue(refData.title);

            if (workTitle.startsWith('[')) {
                workTitleInterview = shared.removeDescriptionFromTitle(workTitleInterview);
            } else{
                workTitleInterview = workTitleInterview.replaceAmpersand().toTitleCase().doubleQuote();    
            }

            workTitleInterview = workTitleInterview.addClosure();

            //the author part here is the interview contribs
            authorPart = getInterviewerString(refData.contributors);
            authorPart = authorPart.addClosure();
            referenceValue = referenceValue.appendData(authorPart);

            referenceValue = referenceValue.appendData(workTitleInterview);

            // Interview by [contributor.host].
            let hostString = getHostString(hosts);
            if(hostString){
                referenceValue = referenceValue.appendData(hostString, ' ', 'Interview by');
                referenceValue = referenceValue.addClosure();
            }

            referenceValue = referenceValue.appendData(publisherString);
            
            let archiveName = shared.getStringValue(refData.archiveName);
            archiveName = archiveName.italicize();
            archiveName = archiveName.addClosure();
            referenceValue = referenceValue.appendData(archiveName, ', ');
            
            referenceValue = referenceValue.appendData(publicationDate);
            referenceValue = referenceValue.addClosure();
            referenceValue = referenceValue.appendData(url);

            break;
    }

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    } else{
        citationTitle = citationTitle.replaceAmpersand().toTitleCase();    
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let citationLocation = shared.getCitationLocationString(citationData, false);
    citationLocation = shared.parseTimeStampString(citationLocation);
    
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    // start by getting the different contibutor types (host | executiveProducer | interview)
    // i need to use them in different ways later based on type and kind
    let hosts = getContriutorByType(refData.contributors, 'host');
    let hostsString = '';
    let executiveProducers = getContriutorByType(refData.contributors, 'executiveProducer');
    let executiveProducersString = '';
    
    // do i need to convert Hosts into a 'hosted by' string
    if(
        type === 'series' ||
        (type === 'episode' && kind === 'radio')
    ){
        if (hosts.length > 0) {
            hostsString = shared.getSecondaryContributorTypeString(hosts, 'host');
            hostsString = hostsString.lowerFirstLetter();
        }     
    }

    // do i need to convert Executive Producers into a 'produced by' string
    if(type !== 'interview'){
        if (executiveProducers.length > 0) {
            executiveProducersString = shared.getSecondaryContributorTypeString(executiveProducers, 'executiveProducer');
            executiveProducersString = executiveProducersString.lowerFirstLetter();
        }     
    }
    
    let workTitle = shared.getStringValue(refData.title);
    
    let shortWorkTitle;
    
    let isDescriptionUsedForTitle = false;

    if (workTitle.startsWith('[')) {
        isDescriptionUsedForTitle = true;
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        shortWorkTitle = workTitle;
    } else{
        shortWorkTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
    }

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);

    switch(type){
        case 'series':
            firstInfo = firstInfo.appendData(workTitle);
            firstInfo = firstInfo.appendData(hostsString, ', ');
            firstInfo = firstInfo.appendData(executiveProducersString, ', ');
            firstInfo = firstInfo.appendData(publisherString, ', ', 'on');

            if(publicationDate !== 'n.d.'){
                if(publisherString === ''){
                    firstInfo = firstInfo.appendData(publicationDate, ', ');
                } else {
                    firstInfo = firstInfo.appendData(publicationDate, ', ');
                }
            }
            switch(kind){
                case 'audio':
                    firstInfo = firstInfo.appendData('podcast', ', ');
                    break;
                case 'radio':
                    firstInfo = firstInfo.appendData('radio series', ', ');
                    break;
                case 'video':
                    firstInfo = firstInfo.appendData('video podcast', ', ');
                    break;
            }

            firstInfo = firstInfo.appendData(citationLocation, ', ');
            firstInfo = firstInfo.appendData(url, ', ');
            
            subsequentInfo = subsequentInfo.appendData(shortWorkTitle);

            break;

        case 'episode':
            let episodeTitle = shared.getStringValue(refData.episodeTitle);
            
            let shortEpisodeTitle;
            shortEpisodeTitle = episodeTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();

            if (episodeTitle.startsWith('[')) {
                episodeTitle = shared.removeDescriptionFromTitle(episodeTitle);
            } else{
                episodeTitle = episodeTitle.replaceAmpersand().toTitleCase().doubleQuote();
            }

            switch(kind){
                case 'audio':
                    authorPart = shared.getCitationAuthorPart(refData, 'episodeTitle');
                    firstInfo = firstInfo.appendData(authorPart);
                    firstInfo = firstInfo.appendData(episodeTitle, ', ');
                    firstInfo = firstInfo.appendData(executiveProducersString, ', ');
                    if(publicationDate !== 'n.d.'){
                        firstInfo = firstInfo.appendData(publicationDate, ', ');
                    }
                    if(isDescriptionUsedForTitle){
                        workTitle = workTitle.toLowerCase();
                    }
                    firstInfo = firstInfo.appendData(workTitle, ', ', 'in');
                    firstInfo = firstInfo.appendData('podcast', ', ');
                    
                    firstInfo = firstInfo.appendData(url, ', ');

                    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
                    if(subsequentAuthorPart.length === 0){
                        subsequentInfo = subsequentInfo.appendData(episodeTitle);
                    }

                    break;
                case 'radio':
                    firstInfo = firstInfo.appendData(episodeTitle);
                    firstInfo = firstInfo.appendData(hostsString, ', ');
                    firstInfo = firstInfo.appendData(workTitle, ', ');
                    firstInfo = firstInfo.appendData(publisherString, ', ', 'on');
                    if(publicationDate !== 'n.d.'){
                        firstInfo = firstInfo.appendData(publicationDate, ', ');
                    }
                    firstInfo = firstInfo.appendData(url, ', ');

                    subsequentInfo = subsequentInfo.appendData(shortEpisodeTitle);
                    break;
                case 'video':
                    authorPart = shared.getCitationAuthorPart(refData, 'episodeTitle');
                    firstInfo = firstInfo.appendData(authorPart);
                    firstInfo = firstInfo.appendData(episodeTitle, ', ');
                    firstInfo = firstInfo.appendData(executiveProducersString, ', ');
                    if(publicationDate !== 'n.d.'){
                        firstInfo = firstInfo.appendData(publicationDate, ', ');
                    }
                    firstInfo = firstInfo.appendData(workTitle, ', ', 'in');
                    firstInfo = firstInfo.appendData('video', ', ');
                    firstInfo = firstInfo.appendData(url, ', ');
                    
                    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
                    break;
            }
            break;
        case 'interview':
            authorPart = getInterviewerString(refData.contributors, true);
            firstInfo = firstInfo.appendData(authorPart);

            let workTitleInterview = shared.getStringValue(refData.title);

            if (workTitleInterview.startsWith('[')) {
                workTitleInterview = shared.removeDescriptionFromTitle(workTitleInterview);
            } else{
                workTitleInterview = workTitleInterview.replaceAmpersand().toTitleCase().doubleQuote();    
            }

            firstInfo = firstInfo.appendData(workTitleInterview, ', ');

            // Interview by [contributor.host].
            let hostString = getHostString(hosts);
            if(hostString){
                firstInfo = firstInfo.appendData(hostString, ', ', 'interview by');
                // firstInfo = firstInfo.addClosure();
            }

            firstInfo = firstInfo.appendData(publisherString, ', ');
            
            let archiveName = shared.getStringValue(refData.archiveName);
            archiveName = archiveName.italicize();
            firstInfo = firstInfo.appendData(archiveName, ', ');
            
            firstInfo = firstInfo.appendData(publicationDate, ', ');
            
            firstInfo = firstInfo.appendData(url, ', ');

            subsequentAuthorPart = getInterviewerString(refData.contributors, true, true);
            
            subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

            break;
    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');







    // let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    // firstInfo = firstInfo.appendData(authorPart);

    // let workTitle = shared.getStringValue(refData.title);
    // workTitle = workTitle.toTitleCase().italicize();
    // firstInfo = firstInfo.appendData(workTitle, ", ");

    // let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    // firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    // let description = shared.getStringValue(refData.description);
    // description = shared.replaceWithAbbreviations(description);
    // description = description.toLowerCase();
    // firstInfo = firstInfo.appendData(description, ', ');

    // let seriesTitle = shared.getStringValue(refData.seriesTitle);
    // workTitle = workTitle.toTitleCase();
    // firstInfo = firstInfo.appendData(seriesTitle, ", ");

    // let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    // let publicationDate = shared.getFullDate(refData.publicationDate);
    // let publisherPart = '';

    // if (shared.isSelfPublished(refData)) {
    //     publisherPart = publisherPart.appendData('self-pub.');
    // }
    // else{
    //     publisherPart = publisherPart.appendData(publisherLocationString);
    // }

    // publisherPart = publisherPart.appendData(publicationDate, ', ');

    // publisherPart = publisherPart.parenthesis();
    // firstInfo = firstInfo.appendData(publisherPart, ', ');

    // let format = shared.getStringValue(refData.format);
    // format = shared.fixFormatStringForCitation(format);
    // firstInfo = firstInfo.appendData(format, ', ');

    // let citationLocation = shared.getCitationLocationString(citationData, false);
    // firstInfo = firstInfo.appendData(citationLocation, ', ');

    // let articleNumber = shared.getStringValue(refData.articleNumber);
    // firstInfo = firstInfo.appendData(articleNumber, ', ');

    // if (firstInfo.endsWith(':')) {
    //     firstInfo = firstInfo.removeLastCharacter();
    // }

    // let url = shared.getStringValue(refData.url);
    // let doi = shared.getStringValue(refData.doi);

    // if (doi.length > 0) {
    //     doi = shared.appendDOIURL(doi).addClosure();
    //     firstInfo= firstInfo.appendData(doi, ', ');
    // }
    // else{
    //     if (url.length > 0) {
    //         url = shared.formatURL(url).addClosure();
    //         firstInfo = firstInfo.appendData(url, ', ');
    //     }
    // }

    // //Authora et al., \"Title,\" 4.
    // //Contributors, "articleTitle", citationLocation
    // let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    // subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    // workTitle = shared.getStringValue(refData.title);
    // workTitle = workTitle.shortenName().toTitleCase().italicize();

    // subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    // subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}



function getContriutorByType(contributors, typeToGet){
    let returnContributors = [];

    _forEach(contributors, (item) => {
        if (item.type == typeToGet) {
            returnContributors.push(item);
        }
    
    });

    return returnContributors;
}

function getHostString(hosts){
    let returnHostString = '';

    _forEach(hosts, (item) => {
        if(item.firstName !== ''){
            returnHostString += item.firstName + ' ';
        }
        if(item.middleName !== ''){
            returnHostString += item.middleName + '. ';
        }
        if(item.lastName !== ''){
            returnHostString += item.lastName + ' ';
        }
    });//e:forEach

    return returnHostString.trim();

}//e:getHostString

function getInterviewerString(contributors, isCitation, isSubsequent) {

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof isCitation === 'undefined') {
        isSubsequent = false;
    }

    let interviewArray = shared.getContributorsByType('interview', contributors);
    let interviewString = '';

    //1 - Last, First Middle Suffix.
    if (interviewArray.length == 1) {

        if (isCitation) {
            interviewString = shared.getCitationName(interviewArray[0], isSubsequent);
        }
        else{
            interviewString = shared.getReferenceName(interviewArray[0]);
        }
    }    


    //2 - Last, First Middle Suffix., and First Middle Last Suffix.
    if (interviewArray.length == 2) {
        if (isCitation) {
            interviewString = shared.getCitationName(interviewArray[0], isSubsequent) + ' and ' + shared.getCitationName(interviewArray[1], isSubsequent);
        }
        else{
            interviewString = shared.getReferenceName(interviewArray[0]) + ', and ' + shared.getSecondaryReferenceName(interviewArray[1]);
        }
    }   
    
    //3 or more - Last, First Middle Suffix - list them all
    if (interviewArray.length > 2) {

        let contribStringArray = [];

        //throw the contrib strings in an array before we build
        let counter = 1;
        _forEach(interviewArray, (contrib) => {
            let contribString = '';
            
            if (counter == 1) {

                if (isCitation) {
                    contribString = shared.getCitationName(contrib, isSubsequent);
                }
                else{
                    contribString = shared.getReferenceName(contrib);
                }
            }
            else{

                if (isCitation) {
                    contribString = shared.getCitationName(contrib, isSubsequent);
                }
                else{
                    contribString = shared.getSecondaryReferenceName(contrib);
                }
            }

            contribStringArray.push(contribString);
            counter++;
        });

            counter = 1;
            contribStringArray.forEach(contrib => {
            if (counter > 1) {

                if (counter == contribStringArray.length) {
                    interviewString = interviewString + ', and '
                }
                else{
                    interviewString = interviewString + ', ';
                }
            }

            interviewString = interviewString + contrib;
            counter++;
        });
    }

    return interviewString;
}
