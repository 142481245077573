const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

	let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
		authorPart: primaryContributor,
		authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
		authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'entry') {
        citationTitle = shared.getStringValue(refData.entryTitle);
    }
    else{
        citationTitle = shared.getStringValue(refData.anthologyTitle);   
    }

	citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.originalPublicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
	let titleProperty = 'anthologyTitle';
	let isSecondaryTitle = false;

	let type = shared.getStringValue(refData.type);	//anthology|entry
	
	if(type === 'entry'){
		titleProperty = 'entryTitle';
		isSecondaryTitle = true;
	}

	let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle,);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);	//anthology
	let kind = shared.getStringValue(refData.kind);	//whole

    let title = '';

	if(type === 'anthology'){
		title = shared.getStringValue(refData.anthologyTitle);
	} else if(type === 'entry'){
		title = shared.getStringValue(refData.entryTitle);
	}

	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		} else{
			title = title.replaceAmpersand();
			title = title.toTitleCase();
			
			if(type === 'anthology'){
				title = title.italicize();
			} else if(type === 'entry'){
				title = title.doubleQuote();
			}
		}
	}

    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

	if(type === 'anthology'){
		//translatedAnthologyTitle
		let translatedTitle = shared.getStringValue(refData.translatedAnthologyTitle);

		if (translatedTitle.length > 0) {
			translatedTitle = translatedTitle.replaceAmpersand();
			translatedTitle = translatedTitle.toTitleCase();
			translatedTitle = translatedTitle.italicize();

			primaryPart = primaryPart.appendData('[' + translatedTitle + ']', ' ', '', '');  
		}
	} else if(type === 'entry'){
		//translatedEntryTitle
		let translatedEntryTitle = shared.getStringValue(refData.translatedEntryTitle);

		if (translatedEntryTitle.length > 0) {
			translatedEntryTitle = translatedEntryTitle.replaceAmpersand();
			translatedEntryTitle = translatedEntryTitle.toTitleCase();
			translatedEntryTitle = translatedEntryTitle.doubleQuote();

			primaryPart = primaryPart.appendData('[' + translatedEntryTitle + ']', ' ', '', '');  
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if(type === 'anthology'){
		//originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');
	} else if(type === 'entry'){
		//originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');
	}

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	// Grab data, then assemble it differently based on type

	//Secondary Contributors
	let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);

	//edition
	let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);

	//Publishers
	let publisherString = shared.getPublisherString(refData.publishers);

	//publicationDate
	let publicationDate = shared.getStringValue(refData.publicationDate);
	publicationDate = shared.getFullDate(publicationDate);

	// doi/url
	let url = shared.getStringValue(refData.url);
	let doi = shared.getStringValue(refData.doi);
	let urlDoiOutput = '';

	if (doi.length > 0) {
		urlDoiOutput = shared.appendDOIURL(doi);
	} else {
		if (url.length > 0) {
			urlDoiOutput = shared.formatURL(url);
		}
	}


    if(type === 'anthology'){
		//Secondary Contributors
		container1 = container1.appendData(secondaryContributor, ', ');

		//edition
		container1 = container1.appendData(edition, ', ');

		//publishers
		container1 = container1.appendData(publisherString, ', ');

		//publicationDate
		container1 = container1.appendData(publicationDate, ', ');

		// doi/url
		container1 = container1.appendData(urlDoiOutput, ', ');
		

	} else if(type === 'entry'){
		//anthologyTitle
		let anthologyTitle = '';
		anthologyTitle = shared.getStringValue(refData.anthologyTitle);
		if(anthologyTitle.length > 0){
			anthologyTitle = anthologyTitle.toTitleCase();
			anthologyTitle = anthologyTitle.italicize();
			container1 = container1.appendData(anthologyTitle, ', ');
		}
		
		//translatedAnthologyTitle
		let translatedAnthologyTitle = '';
		translatedAnthologyTitle = shared.getStringValue(refData.translatedAnthologyTitle);
		if(translatedAnthologyTitle.length > 0){
			translatedAnthologyTitle = translatedAnthologyTitle.toTitleCase();
			translatedAnthologyTitle = '[' + translatedAnthologyTitle.italicize() + ']';
			container1 = container1.appendData(translatedAnthologyTitle, ' ');
		}

		//Secondary Contributors
		container1 = container1.appendData(secondaryContributor, ', ');

		//edition
		container1 = container1.appendData(edition, ', ');

		//publishers
		container1 = container1.appendData(publisherString, ', ');

		//publicationDate
		container1 = container1.appendData(publicationDate, ', ');

		//pageNumbers
		let pageNumbers = shared.getStringValue(refData.pageNumbers);
		pageNumbers = shared.formatPageNumbers(pageNumbers);
		container1 = container1.appendData(pageNumbers, ', ');

		// doi/url
		container1 = container1.appendData(urlDoiOutput, ', ');
	}

	//do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //TODO: add any container data needed

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}