const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (type) {
        case 'federal':
            
            switch (kind) {
                case 'usc':
                    citationTitle = 'United States Code';
                    break;
            
                case 'pl':
                    citationTitle = 'United States';
                    break;
            }
            
            break;
    
        case 'state':

            let state = shared.getStringValue(refData.state);

            if (state.length > 0) {
                state = state + ' ';
            }

            state = state + 'State';            
            citationTitle = state;
            
            break;
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryContributor = '';

    switch (type) {
        case 'federal':
            
            switch (kind) {
                case 'usc':
                        primaryContributor = 'United States Code';
                    break;
            
                case 'pl':
                        primaryContributor = 'United States';
                    break;
            }
            
            break;
    
        case 'state':

            let state = shared.getStringValue(refData.state);

            if (state.length > 0) {
                state = state + ' ';
            }

            state = state + 'State';            
            primaryContributor = state;
            
            break;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (type) {
        case 'federal':
            
            switch (kind) {
                case 'usc':
                    primaryPart = 'United States Code';
                    break;
            
                case 'pl':
                    
                    primaryPart = 'United States, Congress';
                    
                    let number = shared.getStringValue(refData.number);
                    primaryPart = primaryPart.appendData(number, '. ', 'Public Law');
                    
                    break;
            }
            
            break;
    
        case 'state':

            let state = shared.getStringValue(refData.state);

            if (state.length > 0) {
                state = state + ' ';
            }

            state = state + 'State, Legislature';            
            primaryPart = state;
            
            break;
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let volume = shared.getStringValue(refData.volume);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    switch (type) {
        case 'federal':
            
            switch (kind) {
                case 'usc':
                    //Title [volume], section [section], U.S. Government Publishing Office, [publicationDate], url.
                    container1 = container1.appendData(volume, ', ', 'Title');

                    let section = shared.getStringValue(refData.section);
                    container1 = container1.appendData(section, ', ', 'section');

                    container1 = container1.appendData('U.S. Government Publishing Office', ', ');

                    publicationDate = shared.getFullDate(publicationDate);
                    container1 = container1.appendData(publicationDate, ', ');

                    // doi/url
                    if (doi.length > 0) {
                        doi = shared.appendDOIURL(doi);
                        container1 = container1.appendData(doi, ', ');
                    }
                    else{
                        if (url.length > 0) {
                            url = shared.formatURL(url);
                            container1 = container1.appendData(url, ', ');
                        }
                    }

                    break;
            
                case 'pl':
                    //<em>United States Statutes at Large</em>, vol. [volume], [publicationDate], p. [pageNumber].
                    container1 = container1.appendData('<em>United States Statutes at Large</em>', ', ');

                    container1 = container1.appendData(volume, ', ', 'vol.', '');

                    publicationDate = shared.getFullDate(publicationDate);
                    container1 = container1.appendData(publicationDate, ', ');

                    let pageNumber = shared.getStringValue(refData.pageNumber);
                    pageNumber = shared.formatPageNumbers(pageNumber);
                    container1 = container1.appendData(pageNumber, ', ');
                    
                    break;
            }
            
            break;
    
        case 'state':
            
            let sourceTitle = shared.getStringValue(refData.sourceTitle);
            container1 = container1.appendData(sourceTitle, ', ', '', '');

            let sourceLocation = shared.getStringValue(refData.sourceLocation);
            sourceLocation = sourceLocation.replace('§', 'section');
            container1 = container1.appendData(sourceLocation, ', ', '', '');
            
            publicationDate = shared.getFullDate(publicationDate);
            container1 = container1.appendData(publicationDate, ', ');

            // doi/url
            if (doi.length > 0) {
                doi = shared.appendDOIURL(doi);
                container1 = container1.appendData(doi, ', ');
            }
            else{
                if (url.length > 0) {
                    url = shared.formatURL(url);
                    container1 = container1.appendData(url, ', ');
                }
            }

            break;
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (kind == 'pl') {
        container2 = container2.appendData('<em>U.S. Government Publishing Office</em>', ', ');
    
        // doi/url
        let url = shared.getStringValue(refData.url);
        let doi = shared.getStringValue(refData.doi);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi);
            container2 = container2.appendData(doi, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url);
                container2 = container2.appendData(url, ', ');
            }
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    let revisedDate = shared.getStringValue(refData.revisedDate);
    revisedDate = shared.getFullDate(revisedDate);
    secondSupplemental = secondSupplemental.appendData(revisedDate, ', ', 'Revised');

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}
