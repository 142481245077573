String.prototype.bracket = function() {
    if (this.length == 0) {
        return this;
    }

    return '[' + this + ']';
}

String.prototype.removeBrackets = function() {
    if (this.length == 0) {
        return this;
    }

    let text = this.trim();

    if (text.startsWith('[')) {
        text = text.slice(1);

        //remove the ending bracket too
        if (text.endsWith(']')) {
            text = text.slice(0, -1);
        }
    }

    return text;
}

String.prototype.parenthesis = function() {
    if (this.length == 0) {
        return this;
    }

    return '(' + this + ')';
}

String.prototype.trimSubtitle = function() {
    //pull out any odd characters
    let trimmed = this.trim();

    //if this is a subtitle, out an extra spacing before or after
    if (trimmed.includes(':')) {
        let subtitleArray = trimmed.split(':');

        if (subtitleArray.length == 2) {
            trimmed = subtitleArray[0].trim() + ': ' + subtitleArray[1].trimStart();
        }
    }

    return trimmed;
}

String.prototype.italicize = function() {
    if (this.length == 0) {
        return '';
    }

    //helper to just wrap the string in italics
    return '<em>' + this + '</em>';
}

String.prototype.getInitial = function() {
    //Contributor: If a first or midddle name is hyphenated and the name after the hyphen is capitalized, 
    //keep the hyphenation and place a period after each capital letter
    
    //Ai-Jun Xu = Jun, A.-J.
    if (this.includes('-')) {
        var nameArray = this.split("-");
        var secondPart = nameArray[1].trim();

        if (secondPart.charAt(0) == secondPart.charAt(0).toUpperCase()) {
            return nameArray[0].charAt(0) + '.-' + secondPart.charAt(0).toUpperCase() + '.';
        }
    }

    //if you get here, just return what was passed
    return this.trim().charAt(0).toUpperCase() + '.';
}

String.prototype.appendData = function(data, seperator, prefix, suffix, ignorePunctuation) {

    if (typeof prefix === 'undefined') {
        prefix = '';
    }

    if (typeof suffix === 'undefined') {
        suffix = '';
    }

    if (typeof seperator === 'undefined') {
        seperator = ' ';
    }

    if (typeof data === 'undefined') {
        data = '';
    }

    if (typeof ignorePunctuation === 'undefined') {
        ignorePunctuation = false;
    }

    if (prefix.length > 0) {
        prefix = prefix + ' ';
    }

    if (suffix.length > 0) {
        suffix = ' ' + suffix;
    }

    if (data.startsWith('(')) {
        seperator = ' ';
    }

    if (data.length == 0) {
        return this;
    }
    else{
        data = prefix + data + suffix;
    }

    if (this.length == 0) {
        return data;
    }

    //if the last character in the string is a double quote, move the comma inside
    if (seperator == ", ") {
        if (ignorePunctuation && this.endsWith('\'"')) {
            return this.slice(0, -2) + ',\'" ' + data;
        }
    
        if (ignorePunctuation && this.endsWith('"')) {
            return this.slice(0, -1) + '," ' + data;
        }    
    }
    
    if (seperator == ", " && !ignorePunctuation) {
        
        if (this.endsWith('"')) {

            if (!this.endsWith('?"') && !this.endsWith('!"')) {
                return this.slice(0, -1) + '," ' + data;
            }
            else{
                return this + ' ' + data;
            }
        }

        //if this has a punctuation, then a comma is not needed
        if (this.endsWith('!') || this.endsWith('!</em>') ||
            this.endsWith('?') || this.endsWith('?</em>')
            ){
            return this + ' ' + data;
        }
    }

    return this + seperator + data;
}

String.prototype.doubleQuote = function() {
    if (this.length == 0) {
        return this;
    }

    let newText = this;

    if (newText.includes('"')) {
        newText = newText.replaceAll('"', "'")
    }

    return '"' + newText + '"';
}

String.prototype.addClosure = function (){

    let text = this;
    
    if (text.length > 0) {
        if (!text.endsWith('.') && !text.endsWith('?') && !text.endsWith('!')
            && !text.endsWith('.</em>') && !text.endsWith('?</em>') && !text.endsWith('!</em>')) {
            text = text + ".";
        }  

        //if text ends with the a title in double quotes, followed by a period, we need to move the period inside the quote
        if (text.endsWith('\'".')) {

            let title = text.slice(0, -3);
            let punctuation = '.\'"';

            text = title + punctuation;
        }
        
        if (text.endsWith('".')) {

            let title = text.slice(0, -2);
            let punctuation = '."';

            if (title.endsWith('!') || title.endsWith('?') || title.endsWith('.')) {
                //if text is an ellipsis, it still needs an extra period
                if (!title.endsWith('...')) {
                    punctuation = '"';
                }
            }

            text = title + punctuation;
        }
    }

    return text;
}

String.prototype.toTitleCase = function() {

    //pull out any odd characters
    let trimmed = this.trim();

    if (trimmed == 0) {
        return trimmed;
    }

    //if this is a subtitle, out an extra spacing before or after
    if (trimmed.includes(':')) {
        let subtitleArray = trimmed.split(':');

        if (subtitleArray.length == 2) {
            trimmed = subtitleArray[0].trim() + ': ' + subtitleArray[1].trimStart();
        }
    }

    var sentence = trimmed.split(" ");

    let lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'At', 'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With', 'Against', 'As', 'Between', 'Yet', 'So', 'According', 'De', 'En', 'Entre', 'Por', 'Para', 'Con', 'Ante', 'Bajo', 'Contra', 'Segùn', 'Lo'];

    let uppers = ['Id', 'Tv'];

      for(var i = 0; i< sentence.length; i++){

        let previousWord = '';

        if (i  > 0) {
            previousWord = sentence[i - 1];
        }

        let thisWord = sentence[i];
        let thisWordToCheck = thisWord;
        let thisWordPrefix = '';

        //this is to handle title like this: Depression (Major Depressive Disorder)
        if (thisWord.startsWith('(') ||
            thisWord.startsWith('[') ||
            thisWord.startsWith('"') ||
            thisWord.startsWith('\'')) {
            
            thisWordPrefix = thisWord.substring(0,1);
            thisWordToCheck = thisWord.substring(1, thisWord.length);
        }

        if (i > 0) {
            //leave the lower case entries alone, unless they start a sentence
            let isLower = false;
            for (let index = 0; index < lowers.length; index++) {
                const element = lowers[index];
                
                //also ignore the lowers if this is the start of the subtitle
                if (element.toLowerCase() == thisWordToCheck.toLowerCase() 
                        && !previousWord.endsWith(':')) {
                    sentence[i] = thisWordPrefix + thisWordToCheck[0].toLowerCase() + thisWordToCheck.slice(1);
                    isLower = true;
                }
            }

            //if it's in the upper array, 
            let isUpper = false;
            for (let index = 0; index < uppers.length; index++) {
                const element = uppers[index];
                
                if (element.toLowerCase() == thisWordToCheck.toLowerCase()) {
                    sentence[i] = thisWordPrefix + thisWordToCheck.toUpperCase();
                    isUpper = true;
                }
            }

            // //see if this is an abbreviation
            let isAbbreviation = false;
            // if (thisWordToCheck.includes(".")) {
            //     sentence[i] = thisWordPrefix + thisWordToCheck.toUpperCase();
            //     isAbbreviation = true;
            // }

            //see if the word contains a -
            let isHyphenated = false;
            if (thisWordToCheck.includes("-")) {
                let hyphenArray = thisWordToCheck.split('-');

                sentence[i] = '';

                let thisWord = '';
                for (let index = 0; index < hyphenArray.length; index++) {
                    if (index > 0) {
                        sentence[i] = sentence[i] + '-';
                    }

                    thisWord = hyphenArray[index];

                    //only upper case this if the word is not in the lowers array
                    let leaveLower = false;
                    for (let index = 0; index < lowers.length; index++) {
                        const element = lowers[index];
                        
                        if (element.toLowerCase() == thisWord.toLowerCase()) {
                            leaveLower = true;
                        }
                    }

                    //make sure to not lower case the first word either
                    if (leaveLower && index != 0) {

                        if (typeof hyphenArray[index][0] !== 'undefined') {
                            if (hyphenArray[index][0].length > 0) {
                                thisWord = hyphenArray[index][0].toLowerCase();
                            }
                        }
                    }
                    else{
                        if (typeof hyphenArray[index][0] !== 'undefined') {
                            if (hyphenArray[index][0].length > 0) {
                                thisWord = hyphenArray[index][0].toUpperCase();
                            }
                        }
                    }

                    sentence[i] = sentence[i] + thisWord + hyphenArray[index].slice(1);
                }

                sentence[i] = thisWordPrefix + sentence[i];

                isHyphenated = true;
            }

            if (!isLower && !isUpper && !isAbbreviation && !isHyphenated) {
                if (thisWordToCheck.length > 0) {
                    sentence[i] = thisWordPrefix + thisWordToCheck[0].toUpperCase() + thisWordToCheck.slice(1);    
                }
            }

        }
        else{

            if (thisWordToCheck.length > 0) {
                sentence[i] = thisWordPrefix + thisWordToCheck[0].toUpperCase() + thisWordToCheck.slice(1);
            }
        }
      }

    return sentence.join(" ");
}

String.prototype.upperFirstLetter = function() {
    if (this.length == 0) {
        return this;
    }

    if (this.length == 1) {
        return this.toUpperCase();
    }

    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.lowerFirstLetter = function() {
    if (this.length == 0) {
        return this;
    }

    if (this.length == 1) {
        return this.toLowerCase();
    }

    return this.charAt(0).toLowerCase() + this.slice(1);
}

String.prototype.removeLastCharacter = function() {
    if (this.length == 0) {
        return this;
    }

    return this.slice(0, -1);
}

String.prototype.ordinalToNumber = function() {
    if (this.length == 0) {
        return this;
    }

    //converts something like 4th to 4
    let string = parseInt(this);

    if (isNaN(string)) {
        //if the passed something like "Evidence" as the section - a name will convert to null
        return this;
    }
    else{
        return string;
    }
}

String.prototype.shortenName = function(isForeign){

    if (typeof isForeign === 'undefined') {
        isForeign = false;
    }

    if (this.length == 0) {
        return this;
    }

    let name = String(this);

    //add an addtional check to see if this is a url, and shorten to the domain only
    if (name.split(" ").length == 1 && name.toLowerCase().startsWith('http')) {
        //hack to ignore //
        let domain = name.replace('//', 'PERRLA_SHORTEN');

        let parts = domain.split("/");

        domain = parts[0].replace('PERRLA_SHORTEN', '//');

        return domain;
    }

    //if we contain preposition, cut before it
    //otherwise, 3 words...break

    let prepositions = ['aboard'
                            ,'about'
                            ,'above'
                            ,'across'
                            ,'after'
                            ,'against'
                            ,'along'
                            ,'amid'
                            ,'among'
                            ,'anti'
                            ,'around'
                            ,'as'
                            ,'at'
                            ,'before'
                            ,'behind'
                            ,'below'
                            ,'beneath'
                            ,'beside'
                            ,'besides'
                            ,'between'
                            ,'beyond'
                            ,'but'
                            ,'by'
                            ,'concerning'
                            ,'considering'
                            ,'despite'
                            ,'down'
                            ,'during'
                            ,'except'
                            ,'excepting'
                            ,'excluding'
                            ,'following'
                            ,'for'
                            ,'from'
                            ,'in'
                            ,'inside'
                            ,'into'
                            ,'like'
                            ,'minus'
                            ,'near'
                            ,'of'
                            ,'off'
                            ,'on'
                            ,'onto'
                            ,'opposite'
                            ,'outside'
                            ,'over'
                            ,'past'
                            ,'per'
                            ,'plus'
                            ,'regarding'
                            ,'round'
                            ,'save'
                            ,'since'
                            ,'than'
                            ,'through'
                            ,'to'
                            ,'toward'
                            ,'towards'
                            ,'under'
                            ,'underneath'
                            ,'unlike'
                            ,'until'
                            ,'up'
                            ,'upon'
                            ,'versus'
                            ,'via'
                            ,'with'
                            ,'within'
                            ,'without','and', 'or', 'but', '&', 'how', 'do', 'that', 'is'
                        ];

    if (isForeign) {
        prepositions.push('con');
        prepositions.push('de');
        prepositions.push('la');
    }

    //if this is a subtitle, only take the first entry before the :
    if (name.includes(':')) {
        let subtitleArray = name.split(':');
        name = subtitleArray[0].trim();
    }

    //same with: Depression (Major Depressive Disorder)
    if (name.includes('(')) {
        let subtitleArray = name.split('(');
        name = subtitleArray[0].trim();
    }
    
    let words = name.split(" ");

    let shortName = '';
    let foundPrep = false;
    let foundNonPrep = false;
    let counter = 0;
    let startsWithDQuote = false;
    let endsWithDQuote = false;
    let startsWithSQuote = false;
    let endsWithSQuote = false;

    //do not stop once we 
    words.forEach(word => {
        let isPrep = prepositions.includes(word.toLowerCase());

        //also treat any 2 letter or less words as preps
        if (!isPrep) {
            if (word.length < 3 && !word.isInteger()) {
                isPrep = true;
            }
        }

        // let isAbbreviated = isAbbreviation(word);
        let isAbbreviated = false;

        if (foundPrep) {
            if (!foundNonPrep) {
                //keep adding until we find a non-prep
                if (word.endsWith('"')) {
                    endsWithDQuote = true;
                }

                if (word.endsWith("'")) {
                    endsWithSQuote = true;
                }

                if (word.startsWith('"')) {
                    startsWithDQuote = true;

                    if (!word.endsWith('"')) {
                        endsWithDQuote = false;
                    }
                }

                if (word.startsWith("'")) {
                    startsWithSQuote = true;

                    if (!word.endsWith("'")) {
                        endsWithSQuote = false;
                    }
                }

                shortName = shortName.appendData(word, ' ');
            }
        }
        else{
            //have not found a prep and we are still less than 3, add it
            if (!foundNonPrep) {
                if (word.endsWith('"')) {
                    endsWithDQuote = true;
                }

                if (word.endsWith("'")) {
                    endsWithSQuote = true;
                }

                if (word.startsWith('"')) {
                    startsWithDQuote = true;

                    if (!word.endsWith('"')) {
                        endsWithDQuote = false;
                    }
                }

                if (word.startsWith("'")) {
                    startsWithSQuote = true;

                    if (!word.endsWith("'")) {
                        endsWithSQuote = false;
                    }
                }

                shortName = shortName.appendData(word, ' ');
            }
            else{
                if (counter < 3 && !isPrep) {

                    if (word.endsWith('"')) {
                        endsWithDQuote = true;
                    }

                    if (word.endsWith("'")) {
                        endsWithSQuote = true;
                    }
    
                    if (word.startsWith('"')) {
                        startsWithDQuote = true;
    
                        if (!word.endsWith('"')) {
                            endsWithDQuote = false;
                        }
                    }

                    if (word.startsWith("'")) {
                        startsWithSQuote = true;
    
                        if (!word.endsWith("'")) {
                            endsWithSQuote = false;
                        }
                    }

                    shortName = shortName.appendData(word, ' ');
                }
            }
        }

        if (isPrep || isAbbreviated) {
            foundPrep = true;
        }
        else{
            foundNonPrep = true;
        }

        counter++;
    });

    shortName = shortName.trim();

    //if the shortened name ends in a comma, remove it
    if (shortName.endsWith(',')) {
        shortName = shortName.slice(0, -1);
    }

    //if we opened a quote, but didn't close it...we need to close it
    if (startsWithDQuote && !endsWithDQuote) {
        shortName = shortName + '"';
    }

    if (startsWithSQuote && !endsWithSQuote) {
        shortName = shortName + "'";
    }

    return shortName;
}

String.prototype.getOrdinalValue = function(){

    if (this.length == 0) {
        return this;
    }

    //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    if (this.isInteger()) {
        var j = this % 10,
        k = this % 100;
        if (j == 1 && k != 11) {
            return this + "st";
        }
        if (j == 2 && k != 12) {
            return this + "nd";
        }
        if (j == 3 && k != 13) {
            return this + "rd";
        }
        return this + "th";
    }

    return this;
}

String.prototype.isInteger = function() {
    return !isNaN(this) && 
        parseInt(Number(this)) == this && 
        !isNaN(parseInt(this, 10));
}

String.prototype.getYear = function(){

    if (typeof this == 'undefined'){
        return '';
    }

    if (this.length == 0) {
        return this;
    }

    let date = this;
    var year = '';

    date = fixPageDash(date);

    //2018-2020 need to be abbrivated as 2018-20
    if (date.includes('–')) {
        var array = date.split("–");

        if (array.length == 2) {

            //trim both entries
            array[0] = array[0].trim();
            array[1] = array[1].trim();

            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }
    }

    //see if the format is either 2019 or 2/2/2019 or 2018/2020
    if (date.includes("/")) {
        //split into an array and grab the last entry in that array
        var array = date.split("/");

        if (array.length == 2) {
            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }

        year = array[array.length-1]
    }
    else{
        if (date.length > 4) {

            //2018-2020
            if (date.includes("-")) {
                year = date;
            }
            else {
                try {
                    year = dateHelper.format(date, "yyyy");
                }
                catch(err) {
                    console.log('Failed to convert year of ' + date);
                    console.log(err);
                    year = date;
                }
            }
        }
        else {
            //assume this is only a year
            year = date;
        }
    }

    return year;
}

String.prototype.customLowerCase = function(){

    //this is a customized version of lower case that will only lower case words that are not upper cased as entered
    let text = String(this);

    let words = text.split(" ");
    let lowerText = "";

    words.forEach(word => {
    
        let isUpperCase = word === word.toUpperCase();

        if (isUpperCase) {
            lowerText = lowerText.appendData(word); 
        }
        else{
            lowerText = lowerText.appendData(word.toLowerCase());
        }
    });

    return lowerText;
}

//https://stackoverflow.com/questions/7313395/case-insensitive-replace-all
String.prototype.replaceAll = function(strReplace, strWith) {
    // See http://stackoverflow.com/a/3561711/556609
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};

String.prototype.replaceAmpersand = function() {
    if (this.length == 0) {
        return '';
    }

    return this.replaceAll('&', 'and');
}

function fixPageDash(pages){
    
    let cleanPages = ''

    cleanPages = pages.replace('-', '–');
    cleanPages = cleanPages.replace(' - ', '–');
    cleanPages = cleanPages.replace(' – ', '–');

    return cleanPages;
}