/*
*****SocialMedia*****
 
FORM: https://docs.google.com/spreadsheets/d/1ONLiQXCgZTGA9WWCIVTYKKW-0UESPrHl0ls6G-beJT4/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-social-media

TYPES:
    post
        - single posts to a platform (tweet, highlight, etc.)
    page
        - A page on a platform (profile, about, etc.)
    forum
        - Citing a post in an online forum

post: [contributors]. ([publicationDate]). <em>[content]</em>. [[attachment]] [[format]]. [platform]. Retrieved [retrievalDate] from, [url]
page: [contributors]. ([publicationDate]). <em>[title]</em> [[description]]. [platform]. Retrieved [retrievalDate] from, [url]
forum: [contributors]. ([pubicationDate]). <em>[content]</em> [Online forum post]. [platform]. [url]

AuthorPart
    contributors
        - username: Same as before
        - Group Author (with username): this is a new addition to Group Authors
    content or title
        - if there's no username or Group Authors, then the content or title goes at the beginning (this shouldn't happen in real life)

DatePart
    publicationDate
        - This is month, date, and year but include whatever is available
        - put "n.d." for no date

TitlePart
    content
        - this is the first twenty words of the content that is being cited.  Users are going to put this in themselves.
    attachment
        - optionally included to describe any attached audiovisual media with the post
    title
        - title of the page being referenced
    description
        - description of the kind of page being referenced
    format
        - name of the posts (tweet, highlight, etc.)
    platform
        - name of the social media platform

SourcePart
    retrievalDate
        - optional
    url
        - A url

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);

    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = "";

    switch (type) {
        case 'post':
            titlePart = getPostTitlePart(refData);
            break;
        case 'page':
            titlePart = getPageTitlePart(refData);
            break;
        case 'forum':
            titlePart = getForumTitlePart(refData);
            break;
    }

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }


    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.content);

            if (title.length > 0) {
                //trim this to the first five words
                let titleArray = title.split(' ');
                if(titleArray.length > 0){
                    let i = 0;

                    while (i < 5) {
                        if (authorString.length > 0) {
                            authorString = authorString + ' ';
                        }

                        authorString = authorString + titleArray[i];
                        i++;
                      }

                      authorString = authorString.italicize();
                }
                else{
                    authorString = title.italicize();
                }     
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getPostTitlePart(refData){
    let titlePart = '';
    //<em>[content]</em>. [[attachment]] [[format]]. [platform].
    let content = shared.getStringValue(refData.content);
    let attachment = shared.getStringValue(refData.attachment);
    let format = shared.getStringValue(refData.format);

    if (content.length > 0) {
        content = shared.limitWordsInString(content, 20);
        titlePart = content.italicize();
    }

    if (attachment.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + attachment + ']';
    }

    if (format.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + format + '].';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getPageTitlePart(refData){
    let titlePart = '';
    //<em>[title]</em> [[description]]. [platform].
    let title = shared.getStringValue(refData.title);
    let description = shared.getStringValue(refData.description);

    if (title.length > 0) {
        titlePart = title.italicize();
    }

    if (description.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + description + '].';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getForumTitlePart(refData){
    let titlePart = '';
    // <em>[content]</em> [Online forum post]. [platform].
    let content = shared.getStringValue(refData.content);
    let attachment = shared.getStringValue(refData.attachment);
    

    if (content.length > 0) {
        content = shared.limitWordsInString(content, 20);
        titlePart = content.italicize();
    }

    if (attachment.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + attachment + '].';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    let sourcePart = '';
    let platform = shared.getStringValue(refData.platform);
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let url = shared.getStringValue(refData.url);

    if (platform.length > 0) {
        sourcePart = platform + '.';
    }

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);
        sourcePart = sourcePart + ', from';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}