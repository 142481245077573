const shared = require('./Turabian9-Shared.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(titlePart);
    // referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);
    
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();
    
    let model = shared.getStringValue(refData.model);
    titlePart = titlePart.appendData(model);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let authorPartRepeat = shared.getAuthorPart(refData);

    if(authorPartRepeat.length > 0 && authorPartRepeat.endsWith('.')){
        authorPartRepeat = authorPartRepeat.substring(0, authorPartRepeat.length - 1);
        
    }
    publicationPart = publicationPart.appendData(authorPartRepeat);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);

    if(publicationDate === 'n.d.' && publicationPart.length == 0){
        publicationDate = publicationDate.upperFirstLetter();
    }
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    let type = shared.getStringValue(refData.type);

    if(type === 'apparatus'){
        locationPart = locationPart.appendData('Apparatus.');

    } else if(type === 'combo') {
        locationPart = locationPart.appendData('Apparatus and software.');
        
    }

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }
    
    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    }
    else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }
    
    firstInfo = firstInfo.appendData(workTitle, ', ');

    let model = shared.getStringValue(refData.model);
    firstInfo = firstInfo.appendData(model, ', ');
    
    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart, ', ');

    let publicationDate = shared.getFullDate(refData.publicationDate);
    firstInfo = firstInfo.appendData(publicationDate, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let type = shared.getStringValue(refData.type);

    if(type === 'apparatus'){
        firstInfo = firstInfo.appendData('apparatus', ', ');

    } else if(type === 'combo') {
        firstInfo = firstInfo.appendData('apparatus and software', ', ');
        
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    // let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    // subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);
    workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}