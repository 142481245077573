const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

	let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
		authorPart: primaryContributor,
		authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
		authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

	citationTitle = shared.getStringValue(refData.articleTitle);

	let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);

	if(kind === 'section'){
		citationTitle = shared.getStringValue(refData.sectionTitle);
	}

	if (kind == 'issue') {
		citationTitle = shared.getStringValue(refData.issueTitle);
	}

	citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
	let titleProperty = 'title';
	let isSecondaryTitle = false;
	let primaryContributor;

	let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);

	if(refData.contributors.length === 0){
		// no contributors exisit
		if(type === 'magazine' || type === 'reprinted'){
			titleProperty = 'articleTitle';
		} else {
			if(kind === 'section'){
				titleProperty = 'sectionTitle';
			}

			if (kind == 'issue') {
				titleProperty = 'issueTitle';
			}
			
		}
		isSecondaryTitle = true;

		primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
	} else {
		primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
	}
    
	let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
	let shortenPageNumber = true;
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate, shortenPageNumber);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);

    let title = '';
	if(type === 'special'){
		if(kind === 'section'){
			title = shared.getStringValue(refData.sectionTitle);	
			if (title.length > 0) {
				title = title.replaceAmpersand();
				title = title.toTitleCase();
				title = title.doubleQuote();
			}
		} else if(kind === 'issue'){
			title = shared.getStringValue(refData.issueTitle);	
			if (title.length > 0) {
				title = title.replaceAmpersand();
				title = title.toTitleCase();
				title = title.italicize();
			}
		}
	} else {
		title = shared.getStringValue(refData.articleTitle);
		if (title.length > 0) {
			title = title.replaceAmpersand();
			title = title.toTitleCase();
			title = title.doubleQuote();
		}
	}

    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

	if(type === 'magazine' || type === 'reprinted'){
		//translatedArticleTitle
		let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);
		if(translatedArticleTitle.length > 0){
			translatedArticleTitle = translatedArticleTitle.replaceAmpersand();
			translatedArticleTitle = translatedArticleTitle.toTitleCase();
			translatedArticleTitle = translatedArticleTitle.doubleQuote();
			primaryPart = primaryPart.appendData('[' + translatedArticleTitle + ']', ' ', '', '');        
		}
	} else {
		if(kind === 'section'){
			//translatedSectionTitle
			let translatedSectionTitle = shared.getStringValue(refData.translatedSectionTitle);
			if(translatedSectionTitle.length > 0){
				translatedSectionTitle = translatedSectionTitle.replaceAmpersand();
				translatedSectionTitle = translatedSectionTitle.toTitleCase();
				translatedSectionTitle = translatedSectionTitle.doubleQuote();
				primaryPart = primaryPart.appendData('[' + translatedSectionTitle + ']', ' ', '', '');        
			}
		} else if(kind === 'issue'){
			//translatedIssueTitle
			let translatedIssueTitle = shared.getStringValue(refData.translatedIssueTitle);
			if(translatedIssueTitle.length > 0){
				translatedIssueTitle = translatedIssueTitle.replaceAmpersand();
				translatedIssueTitle = translatedIssueTitle.toTitleCase();
				translatedIssueTitle = translatedIssueTitle.italicize();
				primaryPart = primaryPart.appendData('[' + translatedIssueTitle + ']', ' ', '', '');        
			}
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if(type === 'magazine' && kind !== 'retracted'){
		// originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');
	}
    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
	
	//magazineTitle
	let magazineTitle = shared.getStringValue(refData.magazineTitle);
    if (magazineTitle.length > 0) {
		magazineTitle = magazineTitle.replaceAmpersand();
		magazineTitle = magazineTitle.toTitleCase();
		magazineTitle = magazineTitle.italicize();
		if(type === 'special'){
			if(kind === 'section'){
				container1 = container1.appendData(magazineTitle, ', ', 'Special section of');
			} else if(kind === 'issue'){
				container1 = container1.appendData(magazineTitle, ', ', 'Special issue of');
			}
		} else {
			container1 = container1.appendData(magazineTitle, ', ');
		}
		
	}

	//Secondary Contribs
    let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    container1 = container1.appendData(secondaryContributor, ', ');

	//volume
    let volume = shared.getStringValue(refData.volume);
    container1 = container1.appendData(volume, ', ', 'vol.', '');

    //issue
    let issue = shared.getStringValue(refData.issue);
    container1 = container1.appendData(issue, ', ', 'no.', '');

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	//referencePages
	let referencePages = shared.getStringValue(refData.referencePages);
	referencePages = shared.formatPageNumbers(referencePages);
    container1 = container1.appendData(referencePages, ', ');

	// doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }
	
    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if(type === 'magazine' && kind === 'retracted'){
		container2 = 'Retracted in';

		//retractionVolume
		let retractionVolume = shared.getStringValue(refData.retractionVolume);
		container2 = container2.appendData(retractionVolume, ' ', 'vol.');
	
		//retractionIssue
		let retractionIssue = shared.getStringValue(refData.retractionIssue);
		if(retractionVolume.length > 0){
			container2 = container2.appendData(retractionIssue, ', ', 'no.');
		} else {
			// there isn't a volume, so dont have a comma 
			container2 = container2.appendData(retractionIssue, ' ', 'no.');
		}

		// retractionDate
		let retractionDate = shared.getStringValue(refData.retractionDate);
		retractionDate = shared.getFullDate(retractionDate);
		if(retractionVolume.length > 0 || retractionIssue.length > 0){
			container2 = container2.appendData(retractionDate, ', ');
		} else {
			// there isn't a volume or an issue, so dont have a comma
			container2 = container2.appendData(retractionDate, ' ');
		}
		
		
		//retractionPages
		let retractionPages = shared.getStringValue(refData.retractionPages);
		retractionPages = shared.formatPageNumbers(retractionPages);
		container2 = container2.appendData(retractionPages, ', ');

		// retractionUrl/retractionDoi
		let retractionUrl = shared.getStringValue(refData.retractionURL);
		let retractionDoi = shared.getStringValue(refData.retractionDOI);

		if (retractionDoi.length > 0) {
			retractionDoi = shared.appendDOIURL(retractionDoi);
			container2 = container2.appendData(retractionDoi, ', ');
		} else if (retractionUrl.length > 0) {
			retractionUrl = shared.formatURL(retractionUrl);
			container2 = container2.appendData(retractionUrl, ', ');
		}
	}

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);
	
	if(type === 'reprinted'){
		//originalArticleTitle
		let originalArticleTitle = shared.getStringValue(refData.originalArticleTitle);
		if (originalArticleTitle.length > 0) {
			originalArticleTitle = originalArticleTitle.replaceAmpersand();
			originalArticleTitle = originalArticleTitle.toTitleCase();
			originalArticleTitle = originalArticleTitle.doubleQuote();
			secondSupplemental = secondSupplemental.appendData(originalArticleTitle, ', ', 'Originally published as');
		}

		//originalMagazineTitle
		let originalMagazineTitle = shared.getStringValue(refData.originalMagazineTitle);
		if (originalMagazineTitle.length > 0) {
			originalMagazineTitle = originalMagazineTitle.replaceAmpersand();
			originalMagazineTitle = originalMagazineTitle.toTitleCase();
			originalMagazineTitle = originalMagazineTitle.italicize();
			secondSupplemental = secondSupplemental.appendData(originalMagazineTitle, ', ',);
		}

		//originalVolume
		let originalVolume = shared.getStringValue(refData.originalVolume);
		secondSupplemental = secondSupplemental.appendData(originalVolume, ', ', 'vol.', '');
	
		//originalIssue
		let originalIssue = shared.getStringValue(refData.originalIssue);
		secondSupplemental = secondSupplemental.appendData(originalIssue, ', ', 'no.', '');
		
			
		// originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		secondSupplemental = secondSupplemental.appendData(originalPublicationDate, ', ');
		
		//originalPages
		let originalPages = shared.getStringValue(refData.originalPages);
		originalPages = shared.formatPageNumbers(originalPages);
		secondSupplemental = secondSupplemental.appendData(originalPages, ', ');

		// originalDoi/originalUrl
		let originalUrl = shared.getStringValue(refData.originalUrl);
		let originalDoi = shared.getStringValue(refData.originalDoi);

		if (originalDoi.length > 0) {
			originalDoi = shared.appendDOIURL(originalDoi);
			secondSupplemental = secondSupplemental.appendData(originalDoi, ', ');
		} else if (originalUrl.length > 0) {
			originalUrl = shared.formatURL(originalUrl);
			secondSupplemental = secondSupplemental.appendData(originalUrl, ', ');
		}
	}

    if(kind === 'editorial'){
		secondSupplemental = 'Editorial';
	}



    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}