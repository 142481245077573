/*
*****Films*****

Type & Kind Questions
There aren't any type/kind questions for film/movies

Document the parts and their fields here
AuthorPart
    - Director (contributor.author) + "(Director)", Custom Role (contributor.custom) + "([contributor.custom.name])"

DatePart
    - Publication Date

TitlePart
    - title, "[Film]", format (if included, inside brackets with "Film" but separated by semi-colon)

SourcePart
    - publisher.reference, url

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'custom' || item.type == 'director') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {
        let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.italicize();   
            }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //pad minutes and seconds
    if (citationData.type == 'time') {
        if (citationData.value.length > 0) {
            citationData.value = shared.fixTimeCitationData(citationData.value);
            // let dataArray = citationData.value.split('/');

            // switch (dataArray.length) {
            //     case 2:
            //         if (dataArray[1].length == 1) {
            //             dataArray[1] = '0' + dataArray[1]
            //         }
            //         break;
            
            //     case 3:
            //         if (dataArray[1].length == 1) {
            //             dataArray[1] = '0' + dataArray[1]
            //         }

            //         if (dataArray[2].length == 1) {
            //             dataArray[2] = '0' + dataArray[2]
            //         }
            //         break;
            // }

            // citationData.value = dataArray.join('/');
        }
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    //since time is treated differently here than from other places, replace / with :
    if (citationData.type == 'time') {
        citation.first = citation.first.split("/").join(":");
        citation.first = citation.first.replace('<:em>', '</em>');

        citation.subsequent = citation.subsequent.split("/").join(":");
    }

    return citation;
}

function getTitlePart(refData){
    //- title, "[Film]", format (if included, inside brackets with "Film" but separated by semi-colon)
    //<em>Title of the film: Subtitle of the film</em> [Film; Educational DVD].
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    let translatedTitle = shared.getStringValue(refData.translatedTitle);
    let format = shared.getStringValue(refData.format);

    if (title.length > 0) {

        if (title.startsWith('[')) {
            titlePart = title;  
        }
        else{
            titlePart = title.italicize();  
        }

        if (refData.type == 'foreign') {
            if (translatedTitle.length > 0) {
                titlePart = titlePart + ' [' + translatedTitle + ']';    
            }    
        }

        titlePart = titlePart + ' [Film';

        if (format.length > 0) {
            titlePart = titlePart + '; ' + format;
        }

        titlePart = titlePart + ']';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    //- Director (contributor.author) + "(Director)", Custom Role (contributor.custom) + "([contributor.custom.name])"
    let authorPart = '';

    //this gets really customized based off what data is entered.
    //if they are all "authors", then it's just director, directors
    //otherwise, we have to do each one indivisually

    if (typeof refData.contributors === 'undefined') {
        return '';
    }

    if (refData.contributors.length == 0) {
        return '';
    }

    let authorArray = shared.getAuthors(refData.contributors);

    if (authorArray.length == refData.contributors.length) {
        //all directors
        let authorString = shared.getReferenceNameString(authorArray, false);

        if (authorArray.length == 1) {
            authorPart = authorString + ' (Director)';
        }
        else{
            authorPart = authorString + ' (Directors)';
        }
    }
    else{
        //customized here
        authorPart = getFilmReferenceNameString(refData.contributors)
    }

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //- publisher.reference, url
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);

    if (publisherString != '.') {
        sourcePart = publisherString;     
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}

function getFilmReferenceNameString(contributors){
    var contributorstring = '';

    if (contributors.length > 0) {

        //Single
        if (contributors.length == 1) {
            contributorstring = shared.getReferenceName(contributors[0]);

            let label = getCustomNameLabel(contributors[0]);

            if (label.length > 0) {
                contributorstring = contributorstring + ' ' + label;
            }
        }

        //2-20
        if (contributors.length > 1 && contributors.length < 21) {
            var counter = 0;

            _forEach(contributors, (item) => {

                if (counter > 0) {

                    if (counter == contributors.length - 1) {
                            contributorstring = contributorstring + ', & '
                    }
                    else{
                        contributorstring = contributorstring + ', '
                    }
                }

                contributorstring = contributorstring + shared.getReferenceName(item);

                let label = getCustomNameLabel(contributors[counter]);

                if (label.length > 0) {
                    contributorstring = contributorstring + ' ' + label;
                }

                counter++;
            });
        }

        //More Than Twenty
        if (contributors.length > 20) {
            var counter = 0;

            _forEach(contributors, (item) => {

                let label = getCustomNameLabel(item);

                if (counter < 19) {
                    if (counter > 0) {
                        contributorstring = contributorstring + ', '    
                    }
                    contributorstring = contributorstring + shared.getReferenceName(item);
                }
                else{
                    if (counter == contributors.length - 1) {
                        contributorstring = contributorstring + ',...'
                        contributorstring = contributorstring + shared.getReferenceName(item);
                    }
                }

                if (label.length > 0) {
                    contributorstring = contributorstring + ' ' + label;
                }

                counter++;
            });
        }
    }
  
    return contributorstring;
}

function getCustomNameLabel(contributor){
    let label = '';

    if (contributor.type == 'author') {
        label = '(Director)';
    }
    else{
        //custom
        let customLabel = shared.getStringValue(contributor.name);

        if (customLabel.length > 0) {
            label = '(' + customLabel + ')';
        }
    }

    return label;
}