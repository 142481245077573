const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');
const { first } = require('lodash');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);

    if (titlePart.endsWith('."')) {
        if (publicationPart.startsWith('n.d.')) {
            publicationPart = publicationPart.upperFirstLetter();
        }
    }
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let content = shared.getStringValue(refData.content);
    content = content.doubleQuote();

    if(type === 'post'){
        titlePart = titlePart.appendData(content);
        titlePart = titlePart.addClosure();
    }
    else{

        let title = shared.getStringValue(refData.title);
        title = title.replaceAmpersand().toTitleCase().doubleQuote();

        if (type == 'forum') {

            if (title.length > 0) {
                titlePart = titlePart.appendData('Reply to');
            }
            else{
                if (content.length > 0) {
                    titlePart = titlePart.appendData(content);
                }
            }
        }

        titlePart = titlePart.appendData(title);
        titlePart = titlePart.addClosure();
    }

    // let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    // titlePart = titlePart.appendData(secondaryContributors);
    // titlePart = titlePart.addClosure();

    // let seriesTitle = shared.getStringValue(refData.seriesTitle);
    // seriesTitle = seriesTitle.toTitleCase();
    // titlePart = titlePart.appendData(seriesTitle);
    // titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    // if (shared.isSelfPublished(refData)) {
    //     publicationPart = publicationPart.appendData('Self-published');
    // }
    // else{
    //     let publisherString = shared.getPublisherString(refData.publishers);
    //     publicationPart = publicationPart.appendData(publisherString);
    // }
    let title = shared.getStringValue(refData.title);
    let type = shared.getStringValue(refData.type);
    let platform = shared.getStringValue(refData.platform);

    if (title.length == 0 && type == 'forum' && platform.length > 0) {
        publicationPart = publicationPart.appendData('Reply on');
    }

    platform = platform.replaceAmpersand().toTitleCase();
    publicationPart = publicationPart.appendData(platform);

    let format = shared.getStringValue(refData.format);

    if (format.length == 0) {
        let attachment = shared.getStringValue(refData.attachment);
        attachment = attachment.toLowerCase();

        publicationPart = publicationPart.appendData(attachment, ', ');
    }

    publicationPart = publicationPart.appendData(format);

    let description = shared.getStringValue(refData.description);
    publicationPart = publicationPart.appendData(description);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);  

    publicationPart = publicationPart.appendData(publicationDate, ', ');
    
    let time = shared.getStringValue(refData.time);
    publicationPart = publicationPart.appendData(time, ', ');

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // //format
    // let format = shared.getStringValue(refData.format);
    // format = shared.fixFormatString(format);
    // locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';
    let type = shared.getStringValue(refData.type);

    if(type === 'post'){
        citationTitle = shared.getStringValue(refData.content).replaceAmpersand();
    }
    else{
        citationTitle = shared.getStringValue(refData.title).replaceAmpersand();
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let type = shared.getStringValue(refData.type);
    let titleProperty = 'title';
    
    if(type === 'post'){
        titleProperty = 'content';
    }
    
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let content = shared.getStringValue(refData.content);
    content = content.doubleQuote();

    let title = shared.getStringValue(refData.title);

    if(type === 'post'){    
        firstInfo = firstInfo.appendData(content, ", ");
    }
    else{
        
        let seperator = ", ";
        if(type === 'forum'){
            
            if (title.length > 0) {
                firstInfo = firstInfo.appendData('reply to', ", ");
                seperator = ' ';

                if (firstInfo.startsWith('reply to')) {
                    firstInfo = firstInfo.upperFirstLetter();
                }
            }
            else{
                if (content.length > 0) {
                    firstInfo = firstInfo.appendData(content, ", ");
                }
            }
        }

        
        title = title.replaceAmpersand().toTitleCase().doubleQuote();
        firstInfo = firstInfo.appendData(title, seperator);
    }

    let platform = shared.getStringValue(refData.platform);

    if (title.length == 0 && type == 'forum' && platform.length > 0) {
        firstInfo = firstInfo.appendData('reply on', ', ');
        firstInfo = firstInfo.appendData(platform);
    }
    else{
        firstInfo = firstInfo.appendData(platform, ', ');
    }

    let format = shared.getStringValue(refData.format);

    if (format.length == 0) {
        let attachment = shared.getStringValue(refData.attachment);
        attachment = attachment.toLowerCase();
        firstInfo = firstInfo.appendData(attachment, ', ');
    }
    else{

        if (platform.length > 0) {
            firstInfo = firstInfo.appendData(format);
        }
        else{
            firstInfo = firstInfo.appendData(format, ', ');
        }
    }

    let description = shared.getStringValue(refData.description);
    firstInfo = firstInfo.appendData(description);

    let publicationDate = shared.getFullDate(refData.publicationDate);
    firstInfo = firstInfo.appendData(publicationDate, ', ');

    let time = shared.getStringValue(refData.time);
    firstInfo = firstInfo.appendData(time, ', ');

    // let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    // firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    // let description = shared.getStringValue(refData.description);
    // description = shared.replaceWithAbbreviations(description);
    // description = description.toLowerCase();
    // firstInfo = firstInfo.appendData(description, ', ');

    // let seriesTitle = shared.getStringValue(refData.seriesTitle);
    // workTitle = workTitle.toTitleCase();
    // firstInfo = firstInfo.appendData(seriesTitle, ", ");

    // let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    // let publicationDate = shared.getFullDate(refData.publicationDate);
    // let publisherPart = '';

    // if (shared.isSelfPublished(refData)) {
    //     publisherPart = publisherPart.appendData('self-pub.');
    // }
    // else{
    //     publisherPart = publisherPart.appendData(publisherLocationString);
    // }

    // publisherPart = publisherPart.appendData(publicationDate, ', ');

    // publisherPart = publisherPart.parenthesis();
    // firstInfo = firstInfo.appendData(publisherPart, ', ');

    // let format = shared.getStringValue(refData.format);
    // format = shared.fixFormatStringForCitation(format);
    

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    // let articleNumber = shared.getStringValue(refData.articleNumber);
    // firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    // workTitle = shared.getStringValue(refData.workTitle);
    // workTitle = workTitle.shortenName().toTitleCase().italicize();

    // subsequentInfo = subsequentInfo.appendData(workTitle, ', ');

    title = shared.getStringValue(refData.title);
    title = title.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
    content = shared.getStringValue(refData.content);

    if(type === 'post'){
        content = content.shortenName().doubleQuote();
        subsequentInfo = subsequentInfo.appendData(content, ", ");
    }
    else{

        let seperator = ', ';

        if(type === 'forum'){

            if (title.length > 0) {
                subsequentInfo = subsequentInfo.appendData('reply to', ", ");
                seperator = " ";

                if (subsequentInfo.startsWith('reply to')) {
                    subsequentInfo = subsequentInfo.upperFirstLetter();
                }
            }
            else{
                if (content.length > 0) {

                    if (subsequentInfo.length > 0) {
                        subsequentInfo = subsequentInfo.appendData("reply to", ', ');
                        content = content.shortenName().doubleQuote();
                        subsequentInfo = subsequentInfo.appendData(content)
                    }
                    else{
                        content = content.shortenName().doubleQuote();
                        subsequentInfo = subsequentInfo.appendData(content, ", ")
                    }
                }
            }
        }

        if (title.length > 0) {
            subsequentInfo = subsequentInfo.appendData(title, seperator);
        }
    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}