/*
*****InformalWorks*****
 
Documentation: https://docs.google.com/spreadsheets/d/14BXHp7D26CAiknsujdJHoyESUtMOAVc0BRwLMZcqiT0/edit#gid=2071428744

Type & Kind Questions
    - There aren't type/kind questions for informal works
    [Author Part] [Date Part] [Title Part] [Source Part]

AuthorPart
    - Authors, Group Authors, Editors without authors

DatePart
    - Year or n.d.

TitlePart
    - Work title, Description in parentheses, Editors, description if no title

SourcePart
    - Publisher/database/archive, doi, url

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{

        if (titlePart.length > 0) {
            referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
        }
        else{
            referenceValue = authorPart + ' ' + datePart + ' ' + sourcePart;
        }
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.workTitle);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    // - Work title, Description in parentheses, Editors, description if no title
    //<em>Work title: Subtitle</em> (A. A. Editora, B. B. Editorb Jr., & C. C. Editorc, Eds.; Description 12345).
    let titlePart = '';

    let title = shared.getStringValue(refData.workTitle);
    let authorArray = shared.getAuthors(refData.contributors);
    let editorArray = shared.getEditors(refData.contributors);
    
    //entry title has aleady been used if there aren't any authors
    if (title.length > 0 && (authorArray.length != 0 || editorArray.length != 0)) {
        if (title.startsWith('[')) {
            titlePart = title;  
        }
        else{
            titlePart = title.italicize();  
        }    
    }

    //editors
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    let description = shared.getStringValue(refData.description);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
            description.length > 0) {
        
        var isFirstNames = true;

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(';

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (description.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + description;    
            }
            else{
                titlePart = titlePart + '; ' + description; 
            }
            isFirstNames = false;
        }

        titlePart = titlePart + ')';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0) {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = shared.getStringValue(refData.workTitle);

        if (title.length > 0) {

            if (title.startsWith('[')) {
                authorPart = title;
            }
            else{
                authorPart = title.italicize();   
            }
        }
    }

    if (!authorPart.endsWith('.')) {
        authorPart = authorPart + '.';
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    // - Publisher/database/archive, doi, url
    //Publisher Name; Publisher Name 2; Publisher Name 3. https://doi.org/10.123456789
    let sourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}