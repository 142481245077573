const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = 'United States';

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryContributor = 'United States';

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let primaryPart = '';

    switch (type) {
        case 'report':

            if (kind == 'senate') {
                primaryPart = 'United States, Congress, Senate';
            }

            if (kind == 'house') {
                primaryPart = 'United States, Congress, House';
            }
            
            break;
        case 'regulation':
            primaryPart = 'United States';
            break;
    }

    let groupAuthor = shared.getPrimaryContributorString(refData);
    primaryPart = primaryPart.appendData(groupAuthor, ', ');
    

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        title = title.replaceAmpersand();
        
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.toTitleCase();

            switch (type) {
                case 'report':
                    title = title.italicize();
                    break;
                case 'regulation':
                    title = title.doubleQuote();
                    break;
            }
        }

        primaryPart = shared.fixClosure(primaryPart);
        primaryPart = primaryPart.appendData(title, ' ');
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    switch (type) {
        case 'report':

            publicationDate = shared.getFullDate(publicationDate);
            container1 = container1.appendData(publicationDate, ', ');

            break;
        case 'regulation':
            
            switch (kind) {
                case 'codified':
                    container1 = container1.appendData('<em>Code of Federal Regulations</em>', ', ');

                    //volume
                    let volume = shared.getStringValue(refData.volume);
                    container1 = container1.appendData(volume, ', ', 'vol.', '');

                    container1 = container1.appendData('Government Publishing Office', ', ');

                    publicationDate = shared.getFullDate(publicationDate);
                    container1 = container1.appendData(publicationDate, ', ');

                    let section = shared.getStringValue(refData.section);
                    container1 = container1.appendData(section, ', ', 'sec.', );

                    break;
                case 'uncodified':
                    container1 = container1.appendData('<em>Office of the Federal Register</em>', ', ');

                    let proposedVolume = shared.getStringValue(refData.proposedVolume);
                    container1 = container1.appendData(proposedVolume, ', ', 'vol.', '');

                    container1 = container1.appendData('Government Publishing Office', ', ');

                    //proposedDate
                    let proposedDate = shared.getStringValue(refData.proposedDate);
                    proposedDate = shared.getFullDate(proposedDate);
                    container1 = container1.appendData(proposedDate, ', ');

                    //pageNumber
                    let pageNumber = shared.getStringValue(refData.pageNumber);
                    pageNumber = shared.formatPageNumbers(pageNumber);
                    container1 = container1.appendData(pageNumber, ', ');

                    break;
            }
            
            break;
    }

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let session = shared.getStringValue(refData.session);

    if (session.length > 0) {
        session = shared.getOrdinalValue(session);
        container2 = container2.appendData(session, ', ', '', 'Congress');
    }

    switch (kind) {
        case 'senate':
            container2 = container2.appendData('Senate Report', ', ');
            break;
        case 'house':
            container2 = container2.appendData('House Report', ', ');
            break;
    }

    let reportNumber = shared.getStringValue(refData.reportNumber);
    container2 = container2.appendData(reportNumber, ' ', '', '');

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);


    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}