const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(locationPart);

    
    let citationShortTitle = shared.getStringValue(refData.shortTitle);

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let workTitle = shared.getStringValue(refData.title).replaceAmpersand();
    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let volume = shared.getStringValue(refData.volume);
    let publicationDate = shared.getStringValue(refData.publicationDate);

    if(type === 'federal'){
        if(kind === 'usc'){
            titlePart = titlePart.appendData(('US Code').italicize());
            titlePart = titlePart.appendData(volume).addClosure();
            titlePart = titlePart.appendData(publicationDate).addClosure();

            let sectionOutput = formatSectionString(refData);
            titlePart = titlePart.appendData(sectionOutput).addClosure();

        } else if(kind === 'pl'){
            let number = shared.getStringValue(refData.number);
            titlePart = titlePart.appendData(number, ' ', 'Public Law').addClosure();
            titlePart = titlePart.appendData(('US Statutes at Large').italicize());
            titlePart = titlePart.appendData(volume).addClosure();
            titlePart = titlePart.appendData(publicationDate).addClosure();
        }

    } else if(type === 'state'){
        let state = shared.getStringValue(refData.state);
        titlePart = titlePart.appendData(state);

        let sourceTitle = shared.getStringValue(refData.sourceTitle);
        titlePart = titlePart.appendData(sourceTitle);
        titlePart = titlePart.appendData(publicationDate).addClosure();

        let sourceLocation = shared.getStringValue(refData.sourceLocation);
        titlePart = titlePart.appendData(sourceLocation).addClosure();
        

    }//e:if:else:type
    
    
    // let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    // titlePart = titlePart.appendData(secondaryContributors);
    // titlePart = titlePart.addClosure();

    return titlePart;
}

function getLocationPart(refData){
    let locationPart = '';

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}


function formatSectionString(refData){
    let section = shared.getStringValue(refData.section);
    let sectionStringOutput = '';
    
    let suffix = 'et seq.';
    let array = [];

    // split section based on various dash options
    if (section.includes('-')) {
        array = section.split("-");

    } else if (section.includes('–')) {
        array = section.split("–");

    } else if (section.includes('—')) {
        array = section.split("—");

    }
    
    if(array.length > 0){
        // there was dash manipulation
        sectionStringOutput = sectionStringOutput.appendData(array[0], '', '§§', suffix);
    } else {
        // there was no dash (array should be empty)
        sectionStringOutput = sectionStringOutput.appendData(section, '', '§');
    }
    
    return sectionStringOutput;
}

function getCitationTitle(refData){
    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title).replaceAmpersand();
    titlePart = titlePart.appendData(workTitle);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let volume = shared.getStringValue(refData.volume);
    let publicationDate = shared.getStringValue(refData.publicationDate);
        
    if(type === 'federal'){
        if(kind === 'usc'){
            titlePart = titlePart.appendData(('US Code').italicize(), ', ');
            
            titlePart = titlePart.appendData(volume);
            titlePart = titlePart.appendData(publicationDate.parenthesis());

            let sectionOutput = formatSectionString(refData);
            titlePart = titlePart.appendData(sectionOutput, ', ');

        } else if(kind === 'pl'){

            let number = shared.getStringValue(refData.number);
            titlePart = titlePart.appendData(number, ', ', 'Public Law');
            titlePart = titlePart.appendData(('US Statutes at Large').italicize(), ', ');
            titlePart = titlePart.appendData(volume);
            titlePart = titlePart.appendData(publicationDate.parenthesis());

        }

    } else if(type === 'state'){
        let state = shared.getStringValue(refData.state);
        titlePart = titlePart.appendData(state, ', ');

        let sourceTitle = shared.getStringValue(refData.sourceTitle);
        titlePart = titlePart.appendData(sourceTitle);
        titlePart = titlePart.appendData(publicationDate, ', ');

        let sourceLocation = shared.getStringValue(refData.sourceLocation);
        titlePart = titlePart.appendData(sourceLocation, ', ');

    }//e:if:else:type
    
    
    
    firstInfo = firstInfo.appendData(titlePart);   

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    url = shared.formatURL(url).addClosure();
    
    if(type === 'federal' && kind === 'pl'){
        firstInfo = firstInfo.appendData(url, ': ');
    } else {
        firstInfo = firstInfo.appendData(url, ', ');
    }
    

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title).replaceAmpersand();
    workTitle = shared.getStringValue(refData.shortTitle).replaceAmpersand();

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}