// referenceRules
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import Engine from '@/services/reference/Engine';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import router from '@/router';
import { v4 as uuidv4 } from 'uuid';

const publishersDefault = {
	'original': [
		{
			city : '',
			id: uuidv4().toUpperCase(),
			name : '',
			state : '',
		},
	],
	'reference': [
		{
			city : '',
			id: uuidv4().toUpperCase(),
			name : '',
			state : '',
		},
	],
}

const toggleSelectDefault = {
	doiUrl: {
		isCommonUse: true,
		label: 'DOI / URL',
		selectedOption: 'doi',
		options: {
			doi: {
				display: 'DOI',
				helpText: "Only include the DOI number (10.###...).",
				value: '',
			},
			url: {
				display: 'URL',
				helpText: "Type or paste the entire URL",
				value: '',
			},
		},
	},
	originalDoiUrl: {
		isCommonUse: true,
		label: 'Original DOI / URL',
		selectedOption: 'originalDoi',
		options: {
			originalDoi: {
				display: 'DOI',
				helpText: "Only include the DOI number (10.###...).",
				value: '',
			},
			originalUrl: {
				display: 'URL',
				helpText: "Type or paste the entire URL",
				value: '',
			},
		},
	},
	originalPagesArticle: {
		isCommonUse: true,
		label: 'Page Numbers / Article Number',
		selectedOption: 'originalReferencePages',
		options: {
			originalReferencePages: {
				display: 'Pages',
				helpText: "Give the page numbers that include the work (e.g., 45-78). Discontinuous pages are separated with a comma and a space (e.g., 45-78, 93).",
				value: '',
			},
			originalArticleNumber: {
				display: 'Article',
				helpText: "Do not include the prefix &ldquo;Article&rdquo; for the Article number.",
				value: '',
			},
		},
	},
	pagesArticle: {
		isCommonUse: true,
		label: 'Page Numbers / Article Number',
		selectedOption: 'referencePages',
		options: {
			referencePages: {
				display: 'Pages',
				helpText: "Give the page numbers that include the work (e.g., 45-78). Discontinuous pages are separated with a comma and a space (e.g., 45-78, 93).",
				value: '',
			},
			articleNumber: {
				display: 'Article',
				helpText: "Do not include the prefix &ldquo;Article&rdquo; for the Article number.",
				value: '',
			},
		},
	},
	retractionDoiUrl: {
		isCommonUse: true,
		label: 'Retracted Original DOI / URL',
		selectedOption: 'retractionDoi',
		options: {
			retractionDoi: {
				display: 'DOI',
				helpText: "Only include the DOI number (10.###...).",
				value: '',
			},
			retractionUrl: {
				display: 'URL',
				helpText: "Type or paste the entire URL",
				value: '',
			},
		},
	},
	retractionPagesArticle: {
		isCommonUse: false,
		label: 'Retracted Page Numbers / Article Number',
		selectedOption: 'retractionReferencePages',
		options: {
			retractionReferencePages: {
				display: 'Pages',
				helpText: "Give the page numbers that include the work (e.g., 45-78). Discontinuous pages are separated with a comma and a space (e.g., 45-78, 93).",
				value: '',
			},
			retractionArticleNumber: {
				display: 'Article',
				helpText: "Do not include the prefix &ldquo;Article&rdquo; for the Article number.",
				value: '',
			},
		},
	},
}//e:toggleSelectDefault

const formDataDefault = {
	albumTitle: '',
	amendment: '',
	anthologyTitle: '',
	appealCircuit: '',
	appealDate: '',
	appealPageNumber: '',
	appealSeries: '',
	appealVolume: '',
	archiveName: '',
	article: '',
	articleNumber: '',
	articleTitle: '',
	attachment: '',
	billNumber: '',
	blog: '',
	blogTitle: '',
	bookTitle: '',
	channel: '',
	chapterTitle: '',
	charterArticle: '',
	circuit: '',
	citation: [
		// placeholder data when creating a new reference (US Court Case is the only type that uses this)
		{
			id: uuidv4().toUpperCase(),
			pageNumber : '',
			series : '',
			volume : '',
		},
	],
	city: '',
	committeeName: '',
	conference: '',
	content: '',
	contributors: [],
	country: '',
	court: '',
	database: '',
	databaseTitle: '',
	description: '',
	district: '',
	doi: '',
	edition: '',
	episodeNumber: '',
	entry: '',
	entryTitle: '',
	episodeTitle: '',
	filedDate: '',
	format: '',
	institution: '',
	issue: '',
	issueTitle: '',
	journalTitle: '',
	kind: '',
	location: '',
	magazineTitle: '',
	medium: '',
	model: '',
	newspaperTitle: '',
	number: '',
	orderNumber: '',
	originalArticleNumber: '',
	originalArticleTitle: '',
	originalBookTitle: '',
	originalDoi: '',
	originalEdition: '',
	originalIssue: '',
	originalJournalTitle: '',
	originalMagazineTitle: '',
	originalNewspaperTitle: '',
	originalPublicationDate: '',
	originalReferencePages: '',
	originalVolume: '',
	pageNumber: '',
	pageNumbers: '',
	pageTitle: '',
	paragraph: '',
	parties: '',
	periodical: '',
	platform: '',
	proposedVolume: '',
	publicationDate: '',
	publishers: publishersDefault,	// this is just the data holder, the format gets converted when it's sent to the engine
	record: '',
	referencePages: '',
	referenceTitle: '',
	repealDate: '',
	reportNumber: '',
	retractionIssue: '',
	retractionDate: '',
	retractionDoi: '',
	retractionPages: '',
	retractionUrl: '',
	retractionVolume: '',
	retrievalDate: '',
	reviewedTitle: '',
	revisedDate: '',
	runtime: '',
	seasonNumber: '',
	section: '',
	sectionTitle: '',
	series: '',
	seriesTitle: '',
	session: '',
	sessionPart: '',
	shortTitle: '',
	source: '',
	sourceLocation: '',
	sourceTitle: '',
	sponsor: '',
	state: '',
	symposiumTitle: '',
	toggleSelect: toggleSelectDefault,
	title: '',
	translatedAnthologyTitle: '',
	translatedArticleTitle: '',
	translatedChapterTitle: '',
	translatedEntryTitle: '',
	translatedIssueTitle: '',
	translatedNewspaperTitle: '',
	translatedTitle: '',
	translatedVolumeTitle: '',
	type: '',
	url: '',
	version: '',
	volume: '',
	volumeTitle: '',
	website: '',
	websiteTitle: '',
	workTitle: '',
	workType: '',
}

export default {
	namespaced: true,

	state: {
		// non local storage states
		isCreateNextEnabled: false,	// when creating a reference the next button can be turned on and off from other components
		isTranslator: false,
		formData: _cloneDeep(formDataDefault),
		referenceTypeId: 0,			// selected ref type
		referenceTypeName: '',		// selected ref name
		rulesObjectData: [],	// to replace the reference rules 'data' property
		rulesObjectValues: {},	// to replace the reference rules 'values' property
	},

	mutations: {
		PUSH_TO_CONTRIBUTORS(state, value){
			state.formData.contributors.push(value);
		},
		PUSH_TO_CITATION(state, $opts){
			state.formData.citation.push({
				id: $opts.id,
				pageNumber: $opts.pageNumber,
				series: $opts.series,
				volume: $opts.volume,
			});
		},
		PUSH_TO_PUBLISHERS(state, $opts){
			state.formData.publishers[$opts.fieldType].push({
				city: $opts.city,
				id: $opts.id,
				name: $opts.name,
				state: $opts.state,
			});
		},
		REMOVE_CONTRIBUTOR_AT_INDEX(state, $opts){
			state.formData.contributors.splice($opts.contributorIndex, 1);
		},
		REMOVE_CONTRIBUTOR_BY_ID(state, value){
			let findThisContributorIndex = state.formData.contributors.findIndex((contributorData)=>{
				return contributorData.id === value;
			});
			if(findThisContributorIndex != -1){
				state.formData.contributors.splice(findThisContributorIndex, 1); 
			}
		},
		REMOVE_CITATION_AT_INDEX(state, $opts){
			state.formData.citation.splice($opts.citationIndex, 1);
		},
		REMOVE_PUBLISHER_AT_INDEX(state, $opts){
			state.formData.publishers[$opts.fieldType].splice($opts.publisherIndex, 1);
		},
		RESET_FORM_DATA(state){
			state.formData = _cloneDeep(formDataDefault);
		},
		SET_IS_CREATE_NEXT_ENABLED(state, value){
			state.isCreateNextEnabled = value;
		},
		SET_IS_TRANSLATOR(state, value){
			state.isTranslator = value;
		},
		SET_REFERENCE_TYPE_ID(state, value){
			state.referenceTypeId = parseInt(value);
		},
		SET_REFERENCE_TYPE_NAME(state, referenceTypeName) {
			state.referenceTypeName = referenceTypeName;
		},
		SET_RULES_OBJECT_DATA(state, value){
			state.rulesObjectData = _cloneDeep(value);
		},
		SET_RULES_OBJECT_VALUES(state, value){
			state.rulesObjectValues = value;
		},
		SORT_CITATION(state, $opts){
			state.formData.citation.splice($opts.citationIndex, 0, state.formData.citation.splice($opts.oldIndex, 1)[0]);
		},
		SORT_CONTRIBUTORS(state, $opts){
			state.formData.contributors.splice($opts.contributorIndex, 0, state.formData.contributors.splice($opts.oldIndex, 1)[0]);
		},
		SORT_PUBLISHERS(state, $opts){
			state.formData.publishers[$opts.fieldType].splice($opts.publisherIndex, 0, state.formData.publishers[$opts.fieldType].splice($opts.oldIndex, 1)[0]);
		},
		UPDATE_CONTRIBUTOR_AT_INDEX(state, $opts){
			state.formData.contributors[$opts.contributorIndex][$opts.key] = $opts.value;
		},
		UPDATE_FORM_DATA(state, $opts){
			state.formData[$opts.key] = $opts.value;
		},
		
		UPDATE_CITATION_AT_INDEX(state, $opts){
			state.formData.citation[$opts.citationIndex][$opts.key] = $opts.value;
		},
		UPDATE_PUBLISHER_AT_INDEX(state, $opts){
			state.formData.publishers[$opts.fieldType][$opts.publisherIndex][$opts.key] = $opts.value;
		},
		UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION(state, $opts){
			state.formData.toggleSelect[$opts.groupName].options[$opts.unselectedOptionName].value = '';
		},
		UPDATE_TOGGLE_SELECT_AT_GROUP(state, $opts){
			state.formData.toggleSelect[$opts.groupName].options[state.formData.toggleSelect[$opts.groupName].selectedOption].value = $opts.value;
		},
		UPDATE_TOGGLE_SELECT_SELECTED_OPTION(state, $opts){
			state.formData.toggleSelect[$opts.groupName].selectedOption = $opts.selectedOption;
		},
	},
	actions: {
		deepClear({commit}){
			return new Promise((resolve)=>{
				// console.log('referenceRules.js > action  > deepClear');

				commit('RESET_FORM_DATA');
				commit('SET_REFERENCE_TYPE_ID', 0);
				commit('SET_RULES_OBJECT_DATA', []);
				commit('SET_RULES_OBJECT_VALUES', {});
				
				resolve();
			});
		},//e:deepClear

		// preview is generated in another action, this one is called when user clicks a next or save button
		generateReference({commit, state}){
			return new Promise((resolve)=>{
				// console.log('state.rulesObjectData');
				// console.log(state.rulesObjectData);

				// console.log('state.rulesObjectValues');
				// console.log(state.rulesObjectValues);

				// console.log(router.currentRoute.name);

				switch(router.currentRoute.name){
					
					case 'CreateGenesisReferenceStep2':
						router.push({
							name:'CreateGenesisReferenceStep3',
							params:{
								openReferenceTab: router.currentRoute.params.openReferenceTab,
								referenceTypeSlug: router.currentRoute.params.referenceTypeSlug
							},
						}).catch(()=>{});
						break;
					case 'CreateStep3ThirdParty':
						router.push({
							name:'CreateStep4ThirdParty'
						}).catch(()=>{});
						break;

					case 'ConvertGenesisStep1':
						router.push({
							name:'ConvertGenesisStep2',
						}).catch(()=>{});
						break;
				}
				
				return resolve();
			});//e:Promise
		},//e:generateReference

		generateReferencePreview({commit, state}){
			return new Promise((resolve)=>{
				// console.log('vuex store - generateReferencePreview');

				// i need to figure out which type of reference format to reutn to the preview
				let _referenceFormatTypeId = HelperGetReferenceFormatId();

				commit('SET_IS_CREATE_NEXT_ENABLED', false);
			
				// start a new blank object 
				let _collectedRefData = {};
				
				// console.log('state.formData');
				// console.log(state.formData);

				// loop through formData, find data entered for one field (ignore type and kind they will always have something)
				_forEach(state.formData, (value, key)=>{
					if(key !== 'contributors' && key !== 'type' && key !== 'kind' && key !== 'multifield'){
						if(value && value.length > 0){
							commit('SET_IS_CREATE_NEXT_ENABLED', true);
							return false;// only need to catch on
						}
					}
				});
				
				// collect each ref value and key
				_forEach(state.formData, (value, key)=>{
					// console.log(key);
					// console.log(value);
					// console.log('-');
					let multiTextArray = [];
					let cleanValue = value;

					switch(key){
						case 'articleNumber':
							if(_referenceFormatTypeId == config.enums.Format.MLA9 || (state.formData.kind !== 'advance' && state.formData.kind !== 'inPress' && state.formData.kind !== 'issue' && state.formData.type != 'database')){
								_collectedRefData['articleNumber'] = state.formData.toggleSelect.pagesArticle.options.articleNumber.value;
							} else {
								_collectedRefData['articleNumber'] = '';
							}
							break;
						case 'contributors':
							// console.log('contributors value');
							// console.log(value);

							_collectedRefData[key] = value;
							break;
						case 'originalArticleNumber':
							if(state.formData.type === 'reprinted'){
								_collectedRefData['originalArticleNumber'] = state.formData.toggleSelect.originalPagesArticle.options.originalArticleNumber.value;
							} else {
								_collectedRefData['originalArticleNumber'] = '';
							}
							break;
						case 'originalReferencePages':
							if(state.formData.type === 'reprinted'){
								_collectedRefData['originalReferencePages'] = state.formData.toggleSelect.originalPagesArticle.options.originalReferencePages.value;
							} else {
								_collectedRefData['originalReferencePages'] = '';
							}
							break;
						case 'publishers':
							// Assemble publishers v2

							// console.log('publishers');
							// console.log(value);
							_forEach(value, (arrayOfPublishers, publisherType)=>{
								// console.log('arrayOfPublishers');
								// console.log(arrayOfPublishers);

								_forEach(arrayOfPublishers, (publisherData)=>{
									multiTextArray.push({
										city: publisherData.city,
										name: publisherData.name,
										state: publisherData.state,
										type: publisherType,
									});	
								});
							});
							if(state.referenceTypeId === 21){
								// Grants
								_collectedRefData['institution'] = multiTextArray;
							} else {
								_collectedRefData['publishers'] = multiTextArray;
							}
							// console.log(_collectedRefData['publishers']);

							break;
						case 'referencePages': // value has different types of content based on it's reference type
							if(state.referenceTypeId === 2 || state.referenceTypeId === 29){
								// Book || Newspaper
								_collectedRefData['referencePages'] = value;
							} else {
								if(_referenceFormatTypeId == config.enums.Format.MLA9 || (state.formData.kind !== 'advance' && state.formData.kind !== 'inPress' && state.formData.kind !== 'issue' && state.formData.type != 'database')){
									_collectedRefData['referencePages'] = state.formData.toggleSelect.pagesArticle.options.referencePages.value;
								} else {
									_collectedRefData['referencePages'] = '';
								}
							}
							break;
						case 'retractionDoi':
							// console.log(value);
							break;
						case 'toggleSelect':
							// break out the toggle select options
							_forEach(value, (toggleSelectData)=>{
								_forEach(toggleSelectData.options, (optionValue, optionKey)=>{
									if(optionKey !== 'pageNumbers' && state.referenceTypeId === 38) {
										// Reviews
										// referencePages is set as a text field for these two types, skip over it =
									} else if(optionKey === 'referencePages' && (state.referenceTypeId === 2 || state.referenceTypeId === 29)){
										// Book || Newspaper
										// referencePages is set as a text field for these two types, skip over it =
									} else {
										if(state.referenceTypeId === 26){
											// Magazine - passes the data with a different key
											if(optionKey === 'retractionDoi'){
												_collectedRefData['retractionDOI'] = optionValue.value;
											} else if(optionKey === 'retractionUrl'){
												_collectedRefData['retractionURL'] = optionValue.value;
											} else {
												_collectedRefData[optionKey] = optionValue.value;	
											}
										} else {
											_collectedRefData[optionKey] = optionValue.value;
										}

									}
								});
							});
							break;
						case 'doi':
						case 'url':
							// some reference data has url as a string instead of a toggleSelect value
							if(
								state.referenceTypeId === 3 || // Website
								state.referenceTypeId === 5 || // Advertisement
								state.referenceTypeId === 8 ||	// Apparatus
								state.referenceTypeId === 9 ||	// Artwork
								state.referenceTypeId === 10 ||	// Bills & Resolutions
								state.referenceTypeId === 11 ||	// Blog
								state.referenceTypeId === 12 ||	// Charts, Infographics, & Maps
								state.referenceTypeId === 16 || // Dissertations & Theses
								state.referenceTypeId === 17 ||	// Executive Orders
								state.referenceTypeId === 18 ||	// Federal Testimony
								state.referenceTypeId === 19 || // Film & Movies
								state.referenceTypeId === 20 || // Government Reports
								state.referenceTypeId === 24 ||	// International Conventions & Treaties
								state.referenceTypeId === 25 ||	// Lectures
								state.referenceTypeId === 28 ||	// Music
								state.referenceTypeId === 30 ||	// Online Comment
								state.referenceTypeId === 32 ||	// Patent
								state.referenceTypeId === 34 ||	// Podcasts Radio
								state.referenceTypeId === 38 ||	// Reviews
								state.referenceTypeId === 39 ||	// SocialMedia
								state.referenceTypeId === 40 ||	// Software
								state.referenceTypeId === 41 ||	// Speech
								state.referenceTypeId === 42 ||	// Statutes
								state.referenceTypeId === 44 ||	// Tests & Scales
								state.referenceTypeId === 45 ||	// US Federal Court
								state.referenceTypeId === 46 ||	// State Court
								state.referenceTypeId === 47 	// Videos
							){
								_collectedRefData[key] = value;
							}
							break;
						default:
							// console.log(key);
							// console.log(value);
							// replace any fancy quotes with normal ones, this will change what goes into the Engine, not the data field for this reference
							// https://stackoverflow.com/questions/9401312/how-to-replace-curly-quotation-marks-in-a-string-using-javascript
							if(typeof cleanValue === 'string'){
								_collectedRefData[key] = cleanValue.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
							} else {
								_collectedRefData[key] = cleanValue;
							}
							
					}
				});//e:forEach
				
				// console.log('_collectedRefData');
				// console.log(_collectedRefData);
				
				let modeLevel = 'available';	// start with available as the default
				if(config.isDev){
					modeLevel = 'dev';

				} else if(config.isTest){
					modeLevel = 'test';
					
				}

				let _values = Engine.getReference(state.referenceTypeId, _collectedRefData, modeLevel);

				commit('SET_RULES_OBJECT_DATA', _collectedRefData);
				commit('SET_RULES_OBJECT_VALUES', _values);

				resolve();
			});//e:Promise
		},//e:generateReferencePreview
		
		// called when leaving an active form, like when create ref starts, this should always be blank
		resetFormData({commit}){
			return new Promise((resolve)=>{
				// console.log('action > resetFormData');

				commit('SET_IS_TRANSLATOR', false);
				commit('RESET_FORM_DATA');

				resolve();
			});//e:Promise
		},//e:resetFormData

		roleDropdownChange({commit, dispatch, state}, $opts){
			return new Promise((resolve)=>{
				commit('SET_IS_TRANSLATOR', false);
	
				// clear all 3plu error states
				// _forEach(state.formData.contributors, (contributorData)=>{
				// 	contributorData.is3pluErrorState = false;
				// });
				
				// check for any 3plu errors
				_forEach(state.formData.contributors, (contributorData)=>{
					contributorData.is3pluErrorState = false;
					if(contributorData.type === 'chapterTranslator' || contributorData.type === 'translator'){
						commit('SET_IS_TRANSLATOR', true);
						return false;	// exit, we just need 1 true
					}
				});
				
				dispatch('generateReferencePreview').then(()=>{
					resolve();
				});
			
			});//e:Promise
		},
	},

}