const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let type = shared.getStringValue(refData.type);
    let titleProperty = 'title';
    let isSecondaryTitle = true;

    let title = shared.getStringValue(refData.title);
    let fakeTitle = '';

    if (title.length > 0) {
        fakeTitle = title;
    }
    else{
        let description = shared.getStringValue(refData.description);

        if (description.length > 0) {
            fakeTitle = description.bracket();
        }
    }

    let fakeRefData = new Object();
    fakeRefData.title = fakeTitle;

    if(type === 'webinar'){
        fakeRefData.contributors = refData.contributors;
    }
    else{
        fakeRefData.contributors = []; //contribs arent listed in the citation here
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    
	let type = shared.getStringValue(refData.type);
	let title;

	if(type === 'webinar'){
		let primaryContributor = shared.getPrimaryContributorString(refData);
		primaryPart = primaryContributor;
	}

	title = shared.getStringValue(refData.title);

	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		} else{
            title = title.replaceAmpersand();
			title = title.toTitleCase();
			if(type === 'streaming'){
				title = title.doubleQuote();
			}
		}
	}

    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        } else{
            primaryPart = title;
        }
    }
    else{
        let description = shared.getStringValue(refData.description);

        primaryPart = description;
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

	let type = shared.getStringValue(refData.type);

	//Publishers
    let pubsItalics = true;

    if(type === 'webinar'){
        pubsItalics = false;
    }

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', pubsItalics);
	if(publisherString.length > 0){
		container1 = container1.appendData(publisherString, ', ');
	}

	//contributors
	if(type === 'streaming'){
		let primaryContributor = getSecondaryContributorString(refData.contributors);
		if(primaryContributor.length > 0){

            //do not add this if it is the same as the publisher
            let unformattedPublishers = shared.getPublisherString(refData.publishers);
           
            if (primaryContributor != 'by ' + unformattedPublishers) {
                container1 = container1.appendData(primaryContributor, ', ', 'uploaded');
            }
		}
	}

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	//url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
		url = shared.formatURL(url);
		container1 = container1.appendData(url, ', ');
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //TODO: add any container data needed

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let type = shared.getStringValue(refData.type);
    
	if(type === 'webinar'){
		// webinar doesn't get a 2ndSup if the title is used as the description
		let title = shared.getStringValue(refData.title);
		if (title.length > 0 && !title.startsWith('[')) {
			secondSupplemental = 'Webinar';		
		}
	}


    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - authors (which includes group authors and user names)
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let authors = shared.getContributorsByType('author', secondaryContributors);

            if (authors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(authors, 'author'));
            }

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}