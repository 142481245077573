/*
*****Dissertations*****
 
FORM: https://docs.google.com/spreadsheets/d/14m_yBa82BBDm7gG6z8KB4rmh3aqvxfjXvhMseYgN9kI/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-dissertations-theses

TYPES
    - published: Published dissertations or theses
    - unpublished: Unpublished dissertations or theses
KINDS
    - doctorate: Doctoral level work.  This is an option for both types.
    - masters: Master's level work.  This is an option for both types. 

published doctorate
    - [contributor.author]. ([publicationDate]). <em>[title]</em> ([record]) [Doctoral dissertation, [institution]]. [database]. [url]
published masters
    - [contributor.author]. ([publicationDate]). <em>[title]</em> ([record]) [Master's thesis, [institution]]. [database]. [url]
unpublished doctorate
    - [contributor.author]. ([publicationDate]). <em>[title]</em> [Unpublished doctoral dissertation]. [institution]. [url]
unpublished masters
    - [contributor.author]. ([publicationDate]). <em>[title]</em> [Unpublished master's thesis]. [institution]. [url]

AuthorPart
    contributor.author
        - normal
    Ends with period

DatePart
    publicationDate
        - normal, YYYY
    Ends with period

TitlePart
    title
        - italicize
    record
        - placed in parentheses after the title
        - omit parentheses if missing
    institution
        - if published, placed in brackets after the title & institution
    Ends with a period

SourcePart
    institution
        - first part of SourcePart if unpublished
        - period afterwards
    url
        - normal

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let title = shared.getStringValue(refData.title);
    let institution = shared.getStringValue(refData.institution);
    let record = shared.getStringValue(refData.record);
    let label = '';

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{
            titlePart = title.italicize();
        }
    }

    if (record.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(' + record + ')';
    }

    if (type == 'published' && kind == 'doctorate') {
        label = 'Doctoral dissertation';
    }

    if (type == 'published' && kind == 'masters') {
        label = "Master's thesis";
    }

    if (type == 'unpublished' && kind == 'doctorate') {
        label = 'Unpublished doctoral dissertation';
    }

    if (type == 'unpublished' && kind == 'masters') {
        label = "Unpublished master's thesis";
    }

    //if published, include the institution in the label
    if (type == 'published' && institution.length > 0) {
        if (label.length > 0) {
            label = label + ', '
        }

        label = label + institution;
    }

    if (label.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + label + ']';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';

    let type = shared.getStringValue(refData.type);
    let institution = shared.getStringValue(refData.institution);
    let database = shared.getStringValue(refData.database);
    let url = shared.getStringValue(refData.url);
    
    if (type == 'unpublished' && institution.length > 0) {
        sourcePart = institution;

        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (database.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + database;

        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }
    
    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}