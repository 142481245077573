/*
*****Artwork*****
 
Documentation:

Type & Kind Questions
- "museum" : Art found in a museum or collection
- "outside" : Art viewed outside a museuem

AuthorPart
    - Artist Name (Author), Group Artist Name (Group Author)

DatePart
    - Year the work was produced (e.g., 1915–1918)

TitlePart
    - Title of the work in italics, format/medium of the work (e.g., Oil painting)

SourcePart
    - Museum/Collection name (uses Publisher), Publisher/Venue Name (uses Publisher), City, State, Country, URL

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    let format = shared.getStringValue(refData.format);

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;  
        }
        else{
            titlePart = title.italicize();  
        }
    }

    if (format.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + format.upperFirstLetter() + ']';        
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = shared.getStringValue(refData.entryTitle);

        if (title.length > 0) {

            if (!title.endsWith('.')) {
                title = title + '.';
            }
    
            authorPart = title;    
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + shared.fixPageDash(formattedDate) + ').';

    return datePart;
}

function getSourcePart(refData){
    // Museum Name, Washington, D.C., United States.
    let sourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let city = shared.getStringValue(refData.city);
    let state = shared.getStringValue(refData.state);
    let country = shared.getStringValue(refData.country);
    let url = shared.getStringValue(refData.url);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            //remove our period for this one....we'll add it back later if needed
            sourcePart = publisherString.substring(0, publisherString.length -1);    
        }        
    }

    if (city.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + city;
    }

    if (state.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + state;
    }

    if (country.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + country;
    }

    if (sourcePart.length > 0) {
        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' '
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}