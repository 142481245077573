/*
*****Patent*****
 
FORM: https://docs.google.com/spreadsheets/d/12c6R7-SaPK8oB43iUDUGdr8vLDghHsiPnltMDo5fhHU/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-patent

TYPE & KIND
    - None

[contributors]. ([publicationDate]). <em>[title]</em> (U.S. Patent No. [number]). U.S. Patent and Trademark Office. <a href=\"[url\">[url]</a>

AuthorPart
    contributor.author
    contributor.groupAuthor
        - Normal
    title
        - if there aren't contributors

DatePart
    publicationDate
        - YYYY

TitlePart
    title
        - Italicize title
    number
        - follows title
        - always includes "(U.S. Patent No. " before the number and ")." after it
        - Place number exactly as it is typed by the user

SourcePart
    Preset text
        - Always includes "U.S. Patent and Trademark Office"
    url
        - Normal

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        // referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;
        referenceValue = titlePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getYear(publicationDate);

    if (formattedDate.length == 0) {
        formattedDate = 'n.d.';
    }

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    let number = shared.getStringValue(refData.number);

    let authorPart = getAuthorPart(refData);

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{
            titlePart = title.italicize();
        }
    }

    //if we don't have an author, put the date here
    if (authorPart.length == 0) {
        let datePart = getDatePart(refData);

        if (datePart.length > 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + '. ';
            }

            titlePart = titlePart + datePart;
        }
    }

    if (number.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(U.S. Patent No. ' + number + ')';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let url = shared.getStringValue(refData.url);

    let sourcePart = 'U.S. Patent and Trademark Office.';

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}