/*
*****BLOG*****

There is no type/kind question for blogs.

[Author Part] [Date Part] [Title Part] [Source Part]

Author Part
    - Author, Username, Group Author
    - inverted names

Date Part
    - As specific a date as available: YYYY, MM DD
    - No date = n.d.

Title Part
    - Entry title or description, Blog title

Source Part
    - Retrieval Date
    - URL

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    // if (authorPart.length == 0) {
    //     //no contributors
    //     referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    // }
    // else{
        // referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
        referenceValue = referenceValue.appendData(authorPart);
        referenceValue = referenceValue.appendData(datePart);
        referenceValue = referenceValue.appendData(titlePart);
        referenceValue = referenceValue.appendData(sourcePart);
    // }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let entryTitle = shared.getStringValue(refData.entryTitle);

            authorString = '"' + entryTitle.toTitleCase() + '," ';
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0 && refData.type == 'entry') {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    //- Entry title or description, Blog title
    //Blog entry title. <em>Blog Name</em>.
    let titlePart = '';

    let title = shared.getStringValue(refData.entryTitle);
    let blogTitle = shared.getStringValue(refData.blogTitle);
    let authorArray = shared.getAuthors(refData.contributors);
    
    //entry title has aleady been used if there aren't any authors
    if (title.length > 0 && authorArray.length != 0) {

        if (!title.endsWith('.') && !title.endsWith('?')) {
            title = title + '.';
        }

        titlePart = title;    
    }

    if (blogTitle.length > 0) {

        //if the blog title matches our author part (group author name), then don't add it
        if (authorArray.length > 0) {
            let authorPart = getAuthorPart(refData);

            if (blogTitle + '.' != authorPart) {
                if (titlePart.length > 0) {
                    titlePart = titlePart + ' ';
                }
        
                titlePart = titlePart + blogTitle.italicize();
            }

            if (refData.type == 'blog') {
                titlePart = titlePart.appendData('[Blog series]');
            }
        }
        else{
            if (titlePart.length > 0) {
                titlePart = titlePart + ' ';
            }
    
            titlePart = titlePart + blogTitle.italicize();

            if (refData.type == 'blog') {
                titlePart = titlePart.appendData('[Blog series]');
            }
        }   
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = shared.getStringValue(refData.entryTitle);

        if (title.length > 0) {

            if (!title.endsWith('.')) {
                title = title + '.';
            }
    
            authorPart = title;    
        }
    }

    return authorPart;
}

function getDatePart(refData){

    if(refData.type == 'entry'){
        //default the pub date to n.d. if we don't have one
        let publicationDate = shared.getStringValue(refData.publicationDate);
        let formattedDate = shared.getFullDate(publicationDate);

        let datePart = '(' + formattedDate + ').';

        return datePart;
    }
    else{
        return '';
    }
}

function getSourcePart(refData){
    // - Retrieval Date
    // - URL
    let sourcePart = '';
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let url = shared.getStringValue(refData.url);

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);
        sourcePart = sourcePart + ', from';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}