/*
*****TestsScales*****
 
FORM: https://docs.google.com/spreadsheets/d/1u-C3PKXFayjzdGHcyaaj1PJS6sENHjQ4SGB_-YfUcDw/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-tests-scales-inventories

TYPES:
    manual
        - Citing a manual for a test, inventory, or scale
        [contributors]. ([publicationDate]). <em>[title]</em> ([contributors.editor]). [publisher.reference]. [url]
    direct
        - Citing a test, inventory, or scale directly (when the manual isn't available)
        [contributors.groupAuthor]. [publicationDate]. <em>[title]</em>. [publisher.reference]. [url]
    record
        - Citing a database record for a test
        [contributors]. ([publicationDate]). <em>[title]</em> ([number]) [Database record]. [publisher.reference]. [url]



AuthorPart
    - List primary contributors (Authors & Group Authors like normal)

DatePart
    - Date is listed as YYYY

TitlePart
    - Title is in Title Case (not sentence case).  This is the same for citations without contributors and with a title.
    - "record" also includes [number] in parentheses, followed by "[Database record]". 

SourcePart
    - Publishers are listed like normal.
    - URLs are also listed normally.

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
        //for authors, we need to limit this to Authors and usernames
        let authorArray = [];

        _forEach(refData.contributors, (item) => {
            if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
                authorArray.push(item);
            }
        });
    
        let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
        let year = shared.getYear(refData.publicationDate);
        let type = shared.getStringValue(refData.type);
        
        //if there is not author string, we need to use the title
        if (authorString.length == 0) {
    
            let editorArray = shared.getEditors(refData.contributors);
    
            if (editorArray.length > 0) {
                authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
            }
            else{
                let title = shared.getStringValue(refData.title);
    
                if (title.startsWith('[')) {
                    authorString = title;
                }
                else{
                    authorString = title.italicize();   
                }
            }
        }
    
        //if there is no year, use n.d.
        if (year.length == 0) {
            year = 'n.d.';
        }
        else{
            year = shared.fixPageDash(year);
        }
    
        let citation = shared.getCitations(citationData, authorString, year, authorArray);
    
        return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';
    let type = shared.getStringValue(refData.type);
    let title = shared.getStringValue(refData.title);
    let number = shared.getStringValue(refData.number);
    
    if (title.length > 0) {

        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{
            titlePart = title.italicize();   
        }
    }

    if (number.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';    
        }

        titlePart = titlePart + '(' + number + ')';
    }

    if (type == 'record') {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';    
        }

        titlePart = titlePart + '[Database record]';
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    if (editorString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';    
        }
        titlePart = titlePart + '(' + editorString + ')';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';
    let url = shared.getStringValue(refData.url);
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}