/*
- HelperGetReferenceFormatId
-- 2024.08.05
*/
import config from '@/config';
import router from '@/router';
import store from '@/store';

export default () => {
    // console.log('HelperGetReferenceFormatId');

    if(config.platformId === config.enums.Platform.ONLINE){
        if(router.currentRoute.meta.module === config.enums.Module.REFERENCE_LIST){
            return store.state.references.displayFormatVersionId;

        } else if(router.currentRoute.meta.module === config.enums.Module.REFERENCE_EDIT){
            return store.state.references.displayFormatVersionId;

        } else if(router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
            return store.state.paperEdit.meta.PaperFormatVersionID;
    
        }

    } else if(config.platformId === config.enums.Platform.ADD_IN){
        return store.state.formatVersionID;

    } else if(config.platformId === config.enums.Platform.CHROME_EXT){
        return store.state.formatVersionID;

    } else {
        console.log('HelperGetReferenceFormatId missing a platform match')
        return config.enums.Format.APA7;

    }
    
    
    return '0';

}
