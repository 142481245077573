const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'episode') {
        citationTitle = shared.getStringValue(refData.episodeTitle);

        if (citationTitle.length == 0) {
            citationTitle = shared.getStringValue(refData.title); 
        }
    }
    else{
        citationTitle = shared.getStringValue(refData.title);   
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let type = shared.getStringValue(refData.type);
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    if (type == 'episode') {
        titleProperty = 'episodeTitle';
        isSecondaryTitle = true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let type = shared.getStringValue(refData.type);
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);
    primaryPart = primaryContributor;

    let title = '';

    if (type == 'episode') {
        title = shared.getStringValue(refData.episodeTitle);
        
        if (title.length > 0) {
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.doubleQuote();
            }
        }
    }
    else{
        title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();

                if (type == "interview") {
                    title = title.doubleQuote();
                }
                else{
                    title = title.italicize();
                }
            }
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    
    switch (type) {
        case 'series':
            //Secondary Contribs
            container1 = container1.appendData(secondaryContributor, ', ');

            break;
        case 'episode':
            let title = shared.getStringValue(refData.title);

            if (title.length > 0) {
                //if this is a description, remove the brackets and don't italicize
                if (title.startsWith('[')) {
                    title = shared.removeDescriptionFromTitle(title);
                } else{
                    title = title.replaceAmpersand();
                    title = title.toTitleCase();
                    title = title.italicize();
                }

                container1 = container1.appendData(title, ', ');
            }

            //Secondary Contribs
            container1 = container1.appendData(secondaryContributor, ', ');

            //episodeNumber
            let episodeNumber = shared.getStringValue(refData.episodeNumber);

            if (episodeNumber.length > 0) {
                //this could be an integer, or it could be "Season 4, Episode 15"
                //each needs to be handled differently

                if (shared.isInteger(episodeNumber)) {
                    container1 = container1.appendData(episodeNumber, ', ', 'no.', '');
                }
                else{
                    episodeNumber = episodeNumber.toLowerCase();
                    container1 = container1.appendData(episodeNumber, ', ', '', '');
                }
            }

            break;
        case 'interview':
            //"Container 1: archiveName, publishers, publicationDate, url.",
            //archiveName
            let archiveName = shared.getStringValue(refData.archiveName);

            if (archiveName.length > 0) {
                archiveName = archiveName.italicize();
                container1 = container1.appendData(archiveName, ', ', '', '');
            }

            break;
    }

    //Publishers
    let publisherString = shared.getPublisherString(refData.publishers);
    publisherString = publisherString.split('University').join('U');

    if(publisherString.length > 0){
        container1 = container1.appendData(publisherString, ', ');
    }

    //publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    //url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
        container1 = container1.appendData(url, ', ');
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type != 'interview') {
        //platform
        let platform = shared.getStringValue(refData.platform);
        if (platform.length > 0) {
            platform = platform.italicize();
            container2 = container2.appendData(platform, ', ');
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    let label = '';

    let title = shared.getStringValue(refData.title);
    let isDescription = false;

    if (title.length > 0) {
        if (title.startsWith('[')) {
            isDescription = true;
        }
    } 

    if (type == 'series' && kind == 'audio') {
        label = 'Audio podcast';
    }

    if (type == 'series' && kind == 'video') {
        label = 'Video podcast';
    }

    if (type == 'series' && kind == 'radio') {
        label = 'Radio series';
    }

    if (type == 'episode' && kind == 'audio') {
        label = 'Audio podcast episode';
    }

    if (type == 'episode' && kind == 'video') {
        label = 'Video podcast episode';
    }

    if (type == 'episode' && kind == 'radio') {
        label = 'Radio series episode';
    }

    if (type == 'interview') {
        label = 'Interview';
    }

    //if they entered a desciption in the title, do not add the label
    if (label.length > 0 && !isDescription) {
        secondSupplemental = secondSupplemental.appendData(label, ', ');
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}