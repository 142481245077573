const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'album') {
        citationTitle = shared.getStringValue(refData.albumTitle);
    }else if (refData.type == 'song') {
        citationTitle = shared.getStringValue(refData.songTitle);
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = '';
    let isSecondaryTitle = false;

	let type = shared.getStringValue(refData.type);
	
	// console.log('type ' + type);
	
	if(type === 'album'){
		titleProperty = 'albumTitle';
	} else if(type === 'song'){
		titleProperty = 'songTitle';
	}

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

	if(type === 'album'){
		//albumTitle
		let albumTitle = shared.getStringValue(refData.albumTitle);
		if(albumTitle.length > 0){
			albumTitle = albumTitle.replaceAmpersand();
            albumTitle = albumTitle.toTitleCase();
			albumTitle = albumTitle.italicize();
			primaryPart = primaryPart.appendData(albumTitle, ' ');
		}
	} else if(type === 'song'){
		//songTitle
		let songTitle = shared.getStringValue(refData.songTitle);
		
		if(songTitle.length > 0){
			//if this is a description, remove the brackets and don't italicize
			if (songTitle.startsWith('[')) {
				songTitle = shared.removeDescriptionFromTitle(songTitle);
			} else {
                songTitle = songTitle.replaceAmpersand();
				songTitle = songTitle.toTitleCase();
				songTitle = songTitle.doubleQuote();
			}
			primaryPart = primaryPart.appendData(songTitle, ' ');
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let kind = shared.getStringValue(refData.kind);

    if(kind === 'cover'){
		//originalPublicationDate
		let originalPublicationDate = shared.getYear(refData.originalPublicationDate);
        firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');    
	}

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if(type === 'song'){
		let albumTitle = shared.getStringValue(refData.albumTitle);
		if(albumTitle.length > 0){
			//if this is a description, remove the brackets and don't italicize
			if (albumTitle.startsWith('[')) {
				albumTitle = shared.removeDescriptionFromTitle(albumTitle);
			} else {
                albumTitle = albumTitle.replaceAmpersand();
				albumTitle = albumTitle.toTitleCase();
				albumTitle = albumTitle.italicize();
			}

			container1 = container1.appendData(albumTitle, ' ');
		}
	}

	if(kind === 'cover'){
		//Secondary Contribs
		let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
		container1 = container1.appendData(secondaryContributor, ', ');
	}

    //format
    let format = shared.getStringValue(refData.format);
	container1 = container1.appendData(format, ', ');

	//publishers
    let publisherString = shared.getPublisherString(refData.publishers);
    container1 = container1.appendData(publisherString, ', ');

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	//url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
        container1 = container1.appendData(url, ', ');
    }

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //medium
    let medium = shared.getStringValue(refData.medium);
	container2 = container2.appendData(medium, ', ');

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //TODO: add any supplemental data needed


    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}