const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let titlePart = getTitlePart(refData);
    let contributorPart = getContributorPart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(contributorPart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(titlePart),
        authorPartNoLabel: String(titlePart),
        authorPartSort: String(titlePart),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase().italicize();    
    }

    titlePart = titlePart.appendData(title);

    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.startsWith('[')) {
        translatedTitle = shared.removeDescriptionFromTitle(translatedTitle);
    } else{
        translatedTitle = translatedTitle.replaceAmpersand().toTitleCase().bracket();    
    }

    titlePart = titlePart.appendData(translatedTitle);

    titlePart = titlePart.addClosure();

    return titlePart;
}

function getContributorPart(refData){
    let contributorPart = '';

    let contributors = getCustomContributorString(refData);
    contributorPart = contributorPart.appendData(contributors);
    contributorPart = contributorPart.addClosure();

    return contributorPart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);

    if (publisherString.length == 0) {
        publisherString = 'N.p.'
    }

    publicationPart = publicationPart.appendData(publisherString);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //runtime
    let runtime = shared.getStringValue(refData.runtime);
    let formattedRunTime = formatRunTime(runtime);
    locationPart = locationPart.appendData(formattedRunTime).addClosure();

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle);

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    } else{
        citationTitle = citationTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let firstInfo = '';
    let subsequentInfo = '';

    let workTitle = shared.getStringValue(refData.title);
    
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {

        if (translatedTitle.startsWith('[')) {
            translatedTitle = shared.removeDescriptionFromTitle(translatedTitle);
        } else{
            translatedTitle = translatedTitle.replaceAmpersand().toTitleCase().bracket();    
        }

        workTitle = workTitle.appendData(translatedTitle);
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    let contributors = getCustomContributorString(refData, true);
    contributors = contributors.lowerFirstLetter();
    firstInfo = firstInfo.appendData(contributors, ", ");

    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false);

    if (publisherLocationString.length == 0) {
        publisherLocationString = 'N.p.';
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';

    publisherPart = publisherPart.appendData(publisherLocationString);
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    publisherPart = publisherPart.parenthesis();
    
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
    } else{
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();    
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function getCustomContributorString(refData, isCitation){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    let seperator = '. ';

    if (isCitation) {
        seperator = ', '
    }

    //https://docs.google.com/document/d/1i8JTgpxic3-mJjC2CDK1G0yJHBhS5clHyp8eVNi8OGY/edit#heading=h.2qotezq9birt
    
    let contributors = refData.contributors;

    contributors = shared.removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        //start by getting the directors
        let directors = getDirectors(refData.contributors);
        let customs = shared.getContributorsByType('custom', refData.contributors, false);

        if (directors.length > 0) {
            let directorsString = shared.getSecondaryContributorTypeString(directors, 'director');
            contributorstring = contributorstring.appendData(directorsString);
        }

        if (customs.length > 0) {
            let actorContribs = getCustomContributorByName(customs, ['Actor', 'Actress']);
            let producerContribs = getCustomContributorByName(customs, ['Producer', 'Executive Producer']);
            let writerContribs = getCustomContributorByName(customs, ['Writer']);
            let hostContribs = getCustomContributorByName(customs, ['Host']);
            let narratorContribs = getCustomContributorByName(customs, ['Narrator']);
            let otherContribs = getCustomContributorByName(customs, []);

            if (actorContribs.length > 0) {
                let actorsString = shared.getSecondaryContributorTypeString(actorContribs, 'actor');
                contributorstring = contributorstring.appendData(actorsString, seperator);
            }

            if (producerContribs.length > 0) {
                let producerString = shared.getSecondaryContributorTypeString(producerContribs, 'producer', '.');
                contributorstring = contributorstring.appendData(producerString, seperator);
            }

            if (writerContribs.length > 0) {
                let writerString = shared.getSecondaryContributorTypeString(writerContribs, 'writer');
                contributorstring = contributorstring.appendData(writerString, seperator);
            }

            if (hostContribs.length > 0) {
                let hostString = shared.getSecondaryContributorTypeString(hostContribs, 'host');
                contributorstring = contributorstring.appendData(hostString, seperator);
            }

            if (narratorContribs.length > 0) {
                let narratorString = shared.getSecondaryContributorTypeString(narratorContribs, 'narrator');
                contributorstring = contributorstring.appendData(narratorString, seperator);
            }

            if (otherContribs.length > 0) {
                _forEach(otherContribs, (item) => {
                    let customString = shared.getSecondaryContributorTypeString([item], 'custom');
                    contributorstring = contributorstring.appendData(customString, seperator);
                });
            }
        }
    }

    return contributorstring;
}

function formatRunTime(runtime){
    let formattedRunTime = '';

    if (runtime.length > 0) {
        if(runtime.includes(':')){
            let timeArray = runtime.split(':');

            // the time array can be different lengths based on data we get at this point
            switch(timeArray.length){
                case 1: // MM (assumed)
                    return timeArray[0] + ' min.';
    
                case 2: // HH:MM (assumed)
                    return parseInt(timeArray[0]) + ' hr., ' + timeArray[1]+ ' min.';
            }
        } else {
            return runtime;
        }
    }

    return formattedRunTime;
}

function getCustomContributorByName(contributors, names) {

    let namedContribs = [];

    if (names.length == 0) {
        //if an empty array is returned, only return those that are not in the known list
        _forEach(contributors, (item) => {
            if (item.name.toLowerCase() !== 'actor' &&
                item.name.toLowerCase() !== 'actress' &&
                item.name.toLowerCase() !== 'producer' &&
                item.name.toLowerCase() !== 'executive producer' &&
                item.name.toLowerCase() !== 'writer' &&
                item.name.toLowerCase() !== 'host' &&
                item.name.toLowerCase() !== 'narrator' &&
                item.name.toLowerCase() !== 'associate director'
                ) {
                    namedContribs.push(item);
            }
        });
    }
    else{
        _forEach(contributors, (item) => {

            _forEach(names, (name) => {
                if (item.name.toLowerCase() == name.toLowerCase()) {
                    namedContribs.push(item);
                }
            });
        });
    }

    return namedContribs;
}

function getDirectors(contributors){
    let directors = [];

    //this can be an Author, Director, or 
    //Custom with Associate Director

    _forEach(contributors, (item) => {
    
        if (item.type == 'author') {
            directors.push(item);
        }

        if (item.type == 'director') {
            directors.push(item);
        }

        if (item.type == 'custom') {

            if (typeof item.name !== 'undefined') {
                if (item.name.toLowerCase() == 'associate director') {
                    directors.push(item);
                }
            }
        }
    
    })

    return directors;
}
