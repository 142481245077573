const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: false
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let isPublisherAndAuthorSame = shared.isPublisherAndAuthorSame(refData);

    let publicationPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);

    if (refData.contributors.length === 0 && publisherString.length == 0) {
        publisherString = publisherString + 'N.p.';
    }

    if(!isPublisherAndAuthorSame){
        publicationPart = publicationPart.appendData(publisherString);
    }

    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    retrievalDate = shared.getFullDate(retrievalDate);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    if(publicationDate === 'n.d.'){
        
        if (retrievalDate.length > 0 && retrievalDate != 'n.d.') {
            publicationPart = publicationPart.appendData(retrievalDate, ', ', 'Accessed');
        }
        else{
            publicationPart = publicationPart.appendData(retrievalDate, ', ');
        }
        
    } else {
        publicationPart = publicationPart.appendData(publicationDate, ', ');
    }
   
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let isPublisherAndAuthorSame = shared.isPublisherAndAuthorSame(refData);

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);
    
    let isWorkTitleBlank = false;
    let workTitle = shared.getStringValue(refData.title);
    if(workTitle === ''){
        isWorkTitleBlank = true;
    }

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    if(!isWorkTitleBlank){
        description = description.toLowerCase();
    }

    firstInfo = firstInfo.appendData(description, ', ');

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");

    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false);

    if (refData.contributors.length === 0 && publisherLocationString.length == 0) {
        publisherLocationString = 'n.p.';
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    
    let publisherPart = '';

    if(!isPublisherAndAuthorSame){
        publisherPart = publisherPart.appendData(publisherLocationString);
    }

    if(publicationDate === 'n.d.'){
        // there isn't a publication date, check for a retrevial date
        let retrievalDate = shared.getStringValue(refData.retrievalDate);
        retrievalDate = shared.getFullDate(retrievalDate);

        if (retrievalDate.length > 0 && retrievalDate != 'n.d.') {
            publisherPart = publisherPart.appendData(retrievalDate, ', ', 'accessed');
        }
        else{
            publisherPart = publisherPart.appendData(retrievalDate, ', ');
        }
        
    } else {
        // publicationPart = publicationPart.appendData(publicationDate, ', ');
        
        publisherPart = publisherPart.appendData(publicationDate, ', ');
    }

    // publisherPart = publisherPart.parenthesis();
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let subsequentWorkTitle = '';

    if(isWorkTitleBlank){
        subsequentWorkTitle = shared.getStringValue(refData.description);
        subsequentWorkTitle = subsequentWorkTitle.shortenName();
    } else {
        subsequentWorkTitle = shared.getStringValue(refData.title);

        if (subsequentWorkTitle.startsWith('[')) {
            subsequentWorkTitle = shared.removeDescriptionFromTitle(subsequentWorkTitle).shortenName();
        } else{
            subsequentWorkTitle = subsequentWorkTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();   
        }
        
    }

    subsequentInfo = subsequentInfo.appendData(subsequentWorkTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}