const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

//BREAKDOWN NOTES
/*

-There isn't a type/kind for Religious Texts
[TitlePart] [DatePart] [SourcePart]

-TitlePart
    - Title of the work, Editors, Translators, Edition
- DatePart
    - Either a year or n.d.
- SourcePart
    - Publishers, DOI/URL, Original Publication Date
*/


function getReference (refData){

    let referenceValue = '';
    let titlePart = getTitlePart(refData);
    let sourcePart = getSourcePart(refData);
    let datePart = getDatePart(refData);

    referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  

    let reference = {
        value : referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: titlePart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs){
    let firstCitation = ''

    // (Author, YYYY, location)
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    let location = shared.getCitationLocationString(citationData);

    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        //Graysen is verifying if this is needed or not
        // let editorArray = shared.getEditors(refData.contributors);

        // if (editorArray.length > 0) {
        //     authorString = shared.getCitationAuthorString(editorArray, false);
        // }
        // else{
            let titlePart = '';

            let title = shared.getStringValue(refData.bookTitle);
            let version = shared.getStringValue(refData.version);
            let translatedTitle = shared.getStringValue(refData.translatedTitle);

            if(version === ''){
                if (translatedTitle == '') {
                    // no translated title
                    title = title.appendData(version, ': ');
        
                    if (title.length > 0) {
                        if (title.startsWith('[')) {
                            titlePart = title;  
                        } else{
                            titlePart = title.italicize();
                        }  
                    }
                    
                } else {
                    if (!translatedTitle.startsWith('[')) {
                        translatedTitle = '[' + translatedTitle + ']';
                    }
                    titlePart = translatedTitle;
                }

            } else {
                // console.log('version has content');
                title = title.appendData(version, ': ');

                if (title.length > 0) {
                    if (title.startsWith('[')) {
                        titlePart = title;  
                    } else{
                        titlePart = title.toTitleCase().italicize();
                    }  
                }
            }//e:if:else

            // console.log();
            firstCitation = firstCitation.appendData(titlePart);
        // }
    }

    if (authorString.length > 0) {
        firstCitation = authorString;
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    firstCitation = firstCitation.appendData(publicationDateOriginal, ', ');

    let publicationDateInput = shared.getStringValue(refData.publicationDate);  // what the user actually typed in

    if(publicationDateInput === ''){
        // no date entered
        firstCitation = firstCitation.appendData('n.d.', ', ');

    } else {
        // user entered data for publicationDate - look at it closer
        let publicationYearOnly = '';
        publicationYearOnly = shared.getYear(refData.publicationDate);          // the extracted YEAR (a user could type something like ca. 500 B.C.E which should be an empty string here)

        // are there any NON numeric character in this?
        if(publicationYearOnly.match(/[^0-9]/gmi)){
            // Yes there are, assume "ca. 500 B.C.E"
            firstCitation = firstCitation.appendData(publicationDateInput, ', ');
        } else {
            // No it's just numbers, assume "2000"
            if(publicationDateOriginal === ''){
                firstCitation = firstCitation.appendData(publicationYearOnly, ', ');
            } else {
                firstCitation = firstCitation.appendData(publicationYearOnly, '/');
            }
        }//e:if:else

        
    }//e:if:else
    
    firstCitation = firstCitation.appendData(location, ', ');

  
   
    var subsequentCitation = '';

    //if there is a group author, and it's in the first two contributors....and that group auther has an abbreviation, we have a different subsequent
    var contributorCounter = 0;
    var hasSubsequent = false;

    authorArray.forEach(item => {
        if (item.type == 'groupAuthor') {
            //authorArray.push(item);
            if (contributorCounter < 2) {
                var abbreviation = shared.getStringValue(item.abbreviation);

            if (abbreviation.length > 0) {
                hasSubsequent = true;
            }
            }
        }

        contributorCounter++;
    });

    if (hasSubsequent) {
        let subsequentAuthorString = shared.getCitationAuthorString(authorArray, true, paperRefs);
        subsequentCitation = subsequentAuthorString + ', ' + year; 

        if (location.length > 0) {
            subsequentCitation = subsequentCitation + ', ' + location;
        }
    }

    let citation = {
        first : String(firstCitation),
        subsequent : String(subsequentCitation)
    }

    return citation;
}

function getTitlePart(refData){
    //<em>The Work: Work subtitle</em> [Translated title] (A. A. Editora, B. B. Editorb Jr., & C. C. Editorc, Eds.; A. A. Translatora, Trans.; 2nd ed.).
    let titlePart = '';

    let edition = shared.getStringValue(refData.edition);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length >0) {
        editorString = '';
        translatorString = '';
    }

    let title = shared.getStringValue(refData.bookTitle);
    let version = shared.getStringValue(refData.version);
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if(version === ''){
        // no version, is there a translated title?
        if (translatedTitle == '') {
            // no translated title
            title = title.appendData(version, ': ');

            if (title.length > 0) {
                if (title.startsWith('[')) {
                    titlePart = title;  
                } else{
                    titlePart = title.italicize();
                }  
            }
            
        } else {
            if (!translatedTitle.startsWith('[')) {
                translatedTitle = '[' + translatedTitle + ']';
            }
            titlePart = translatedTitle;
        }

    } else {
        title = title.appendData(version, ': ');

        if (title.length > 0) {
            if (title.startsWith('[')) {
                titlePart = title;  
            } else{
                titlePart = title.italicize();
            }  
        }

    }//e:if:else
   
    //build our secondary contributors portion
    if ((editorString.length > 0) ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0||
        edition.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorTranslatorString.length > 0) {
            titlePart = titlePart + editorTranslatorString;
            isFirstNames = false;
        }

        if (editorString.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + translatorString;    
            }
            else{
                titlePart = titlePart + '; ' + translatorString; 
            }
            isFirstNames = false;
        }

        if ((edition.length > 0) && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (edition.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            edition = shared.fixEditionString(edition);

            titlePart = titlePart + edition;
        }

        titlePart = titlePart + ')';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Publisher Name; Publisher Name 2; Publisher Name 3. https://doi.org/10.123456789/ (Original work published ca. 1720)
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);    
    }
    
    if (doi.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    //publication Date Original
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);

    if (publicationDateOriginal.length > 0) {
        sourcePart = sourcePart + ' (Original work published ' + publicationDateOriginal + ')';
    }

    return sourcePart;
}