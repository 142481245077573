/*
*****Datasets*****
 
FORM: https://docs.google.com/spreadsheets/d/1X1numWbp1h96m3QvtQWrU40WD6ahWlARAeY5qZryazM/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-datasets

TYPES:
    published: published data sets
        [contributors]. ([publicationDate]). <em>[title]</em> ([version]) [Data set]. [publishers]. [doi/url]
    raw: unpublished 
        [contributors]. ([publicationDate]). <em>[title]</em> ([version]) [Unpublished raw data]. [publishers]. Retrieved [retrievalDate] from, [doi/url]

AuthorPart
    contributors
        - Author or Group Author
        - Include Title if there are no contributors

DatePart
    publicationDate
        - Normal Date in YYYY

TitlePart
    title
        - italicized
        - If the title is a description (including brackets), do not include "[Data set]" or "[Unpublished raw data]" in the title part.
    version
        - place in parentheses after the title

SourcePart
    publishers
        - Normal for publisher.reference
    retrievalDate
        - optional for unpublished raw data
    url/doi
        - normal url/doi

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            let description = shared.getStringValue(refData.description);

            if (title.length > 0) {
                authorString = title.toTitleCase().italicize();
            }
            else{
                authorString = '[' + description + ']';
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let title = shared.getStringValue(refData.title);
    let version = shared.getStringValue(refData.version);

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{
            titlePart = title.italicize();   
        }
    }

    if (version.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(' + version + ')';
    }

    let label = '';
    switch (type) {
        case 'published':
            label = '[Data set]';
            break;
        case 'raw':
            label = '[Unpublished raw data]';
            break;
    }

    if (label.length > 0 && !titlePart.startsWith('[')) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + label;
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);
        sourcePart = sourcePart + ', from';
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}