const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    // let locationPart = getLocationPart(refData);
    let publicationPart = getPublicationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    // referenceValue = referenceValue.appendData(locationPart);
    referenceValue = referenceValue.appendData(publicationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';
    
    let type = shared.getStringValue(refData.type);
    
    let sponsor = shared.getStringValue(refData.sponsor);
    sponsor = sponsor.replaceAmpersand().toTitleCase();    
    titlePart = titlePart.appendData(sponsor);
    titlePart = titlePart.addClosure();

    let title = shared.getStringValue(refData.title);
    title = title.doubleQuote();    
    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    let format = shared.getStringValue(refData.format);
    if(format.length > 0){
        format = format.upperFirstLetter();
        titlePart = titlePart.appendData(format, ' ', '', 'advertisement');
    } else {
        titlePart = titlePart.appendData('Advertisement');
    }
    titlePart = titlePart.addClosure();
    
    if(type === 'physical'){
        let location = shared.getStringValue(refData.location);
        titlePart = titlePart.appendData(location);
        titlePart = titlePart.addClosure();
    }


    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);

    if(publicationDate !== 'n.d.'){
        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.addClosure();
    }
    
    publicationPart = publicationPart.addClosure();

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        publicationPart = publicationPart.appendData(url);
    }

    return publicationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.sponsor);

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let sponsor = shared.getStringValue(refData.sponsor);

    sponsor = sponsor.replaceAmpersand().toTitleCase();    
    firstInfo = firstInfo.appendData(sponsor, ', ');

    let title = shared.getStringValue(refData.title);
    title = title.doubleQuote();    
    firstInfo = firstInfo.appendData(title, ', ');

    let format = shared.getStringValue(refData.format);

    if(format.length > 0){
        format = shared.fixFormatStringForCitation(format);
        if(title.length !== 0 || sponsor.length !== 0){
            format = format.lowerFirstLetter();
        }
        firstInfo = firstInfo.appendData(format, ', ', '', 'advertisement', true);

    } else {
        firstInfo = firstInfo.appendData('advertisement', ', ', '', '', true);

    }
    

    let location = '';

    if(type === 'physical'){
        location = shared.getStringValue(refData.location);
        firstInfo = firstInfo.appendData(location, ', ');
    }
    
    let publicationDate = shared.getFullDate(refData.publicationDate);
    if(publicationDate !== 'n.d.'){
        firstInfo = firstInfo.appendData(publicationDate, ', ');
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    citationLocation = shared.parseTimeStampString(citationLocation);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    firstInfo = firstInfo.upperFirstLetter();

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    // let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    // subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let subsequentSponsor = shared.getStringValue(refData.sponsor);
    subsequentSponsor = subsequentSponsor.replaceAmpersand().toTitleCase();

    let subsequentTitle = shared.getStringValue(refData.title);
    // console.log('subsequentTitle.length');
    // console.log(subsequentTitle.length);
    // console.log('location.length');
    // console.log(location.length);

    if(subsequentTitle.length === 0 && location.length === 0){
        // console.log('missing title and location');
        if(subsequentSponsor.length === 0){
            // also missing sponsor
            subsequentInfo = subsequentInfo.appendData('Advertisement');

        } else {
            subsequentInfo = subsequentInfo.appendData(subsequentSponsor);

        }

    } else if(subsequentTitle.length === 0 && subsequentSponsor.length === 0){
        // console.log('missing title and sponsor');
        if(type === 'physical'){
            format = format.upperFirstLetter();
            subsequentInfo = subsequentInfo.appendData(format, ', ', '', 'advertisement');
        }
    } else if(subsequentTitle.length === 0 && publicationDate === 'n.d.'){
        // console.log('missing title and date')
        subsequentInfo = subsequentInfo.appendData(subsequentSponsor);
    } else if(subsequentTitle.length === 0 && format.length === 0){
        // console.log('missing title and format')
        subsequentInfo = subsequentInfo.appendData(subsequentSponsor);
    } else {
        // console.log('missing nothing');
        subsequentInfo = subsequentInfo.appendData(subsequentSponsor, ', ');

        subsequentTitle = subsequentTitle.shortenName().doubleQuote();
        subsequentInfo = subsequentInfo.appendData(subsequentTitle, ', ');

    }//e:if:missing.parts
    
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');
    
    subsequentInfo = subsequentInfo.upperFirstLetter();

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}