const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let primaryContributor = 'United States';

    let committeeName = shared.getStringValue(refData.committeeName);
    let title = shared.getStringValue(refData.title);

    if (kind == '' && committeeName == '' && title.length > 0) {
        let isSecondaryTitle = true;
        let titleProperty = 'title';

        let fakeRefData = new Object();
        fakeRefData.title = refData.title;
        fakeRefData.contributors = [];
        
        primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (kind) {
        case 'house':
            primaryPart = 'United States, Congress, House';
            break;
        case 'senate':
            primaryPart = 'United States, Congress, Senate';
        break;
        case '':
            primaryPart = 'United States, Congress';
        break;
    }

    let committeeName = shared.getStringValue(refData.committeeName);
    committeeName = committeeName.replaceAmpersand();
    primaryPart = primaryPart.appendData(committeeName, ', ', '', '');

    primaryPart = shared.fixClosure(primaryPart);

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.italicize();
        }

        primaryPart = primaryPart.appendData(title, ' ');
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (type) {
        case 'testimony':
        
            let session = shared.getStringValue(refData.session);

            if (session.length > 0) {
                session = shared.getOrdinalValue(session);
                container1 = container1.appendData(session, ', ', '', 'Congress');
            }

            //Secondary Contribs
            let secondaryContributor = getSecondaryContributorString(refData.contributors);
            container1 = container1.appendData(secondaryContributor, ', ');

            break;
        case 'hearing':
        
            container1 = container1.appendData('U.S. Government Publishing Office', ', ');
            
            break;
    }

    //publicationDate
    let publicationDate = shared.getFullDate(refData.publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - authors (which includes group authors and user names)
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let testimonies = shared.getContributorsByType('testimony', secondaryContributors);

            if (testimonies.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(testimonies, 'testimony'));
            }

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}