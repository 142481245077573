/*
*****PodcastsRadio*****
 
Documentation: https://docs.google.com/spreadsheets/d/16xG-DW-rxA7KBPQGk0fTFwXr3O5B72oiK4F-fcCwroQ/edit#gid=2071428744

Type & Kind Questions
- TYPE: 
    - episode: A podcast or radio episode
    - series: An entire podcast or radio series
    - interview: A radio interview in a digital archive (no Kind options)

- KIND:
    - audio: An audio podcast
    - video: A video podcast
    - radio: A radio broadcast

AuthorPart
    - Contributors
        - Host(s)
        - Executive Producer(s)
        - Interviewee(s)
    - Last, F. M. (role)

DatePart
    - Series 
        - ([publicationDate]).
        - A year or range of years
        - The second year can be "present"
        - Years should be separated by a – (en dash) without spaces
    - Episode
        - ([publicationDate]).
        - This is a specific date with YYYY, MM DD
TitlePart
    - Episode
        - [episodeTitle] ([episodeNumber]). [format]
        - Episode title not italicized
        - Followed by the episode number in parentheses
        - That is followed by the format in brackets (this is set by the type/kind)
        - Ends with a period
    - Series
        - [title] [format]
        - Series title is italicized
        - Followed by the format in brackets (this is set by the type/kind)
        - Ends with a period
    - Interview
        - [title] [format]
        - Interview title is italicized
        - Followed by the format in brackets (this is set by the type/kind)
        - Ends with a period

SourcePart
    - Episode
        - "In " [contributors.producer], <em>[title]</em>. [publishers.reference]. [url]
    - Series
        - [publishers.reference]. [url]
    - Interview
        - [archiveName]; [publishers.reference]. [url]

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0) {
        return 'Type required.';
    }

    let datePart = getDatePart(refData);
    let sourcePart = '';
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (refData.type == 'series' || refData.type == 'interview') {
        sourcePart = getSourcePart(refData);
    }

    if (refData.type == 'episode') {
        sourcePart = getEpisodeSourcePart(refData);
    }

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {

        if (refData.type == 'series') {
            if (item.type == 'host' || item.type == 'executiveProducer' || item.type == 'interview') {
                authorArray.push(item);
            }    
        }

        if (refData.type == 'episode') {
            if (item.type == 'host') {
                authorArray.push(item);
            }    
        }

        if (refData.type == 'interview') {
            if (item.type == 'interview') {
                authorArray.push(item);
            }    
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {
        let title = shared.getStringValue(refData.title);
        let episodeTitle = shared.getStringValue(refData.episodeTitle);
        let usingEpisodeTitle = false;

        if (refData.type == 'episode' && episodeTitle.length > 0) {
            title = episodeTitle; 
            usingEpisodeTitle = true;
        }

        title = shared.stripSubtitle(title);

        if (title.startsWith('[')) {
            authorString = title;
        }
        else{
            if (usingEpisodeTitle) {
                authorString = '"' + title.toTitleCase() + '," '
            }else{
                authorString = title.toTitleCase().italicize(); 
            }  
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (citationData.type == 'time') {
        citationData.value = shared.fixTimeCitationData(citationData.value);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    //since time is treated differently here than from other places, replace / with :
    if (citationData.type == 'time') {
        citation.first = citation.first.split("/").join(":");
        citation.subsequent = citation.subsequent.split("/").join(":");
    }

    return citation;
}

function getTitlePart(refData){
    //<em>Series title</em> [Audio podcast].
    let titlePart = '';
    let title = '';

    if (refData.type == 'series' || refData.type == 'interview') {
        title = shared.getStringValue(refData.title);
    }

    if (refData.type == 'episode') {
        title = shared.getStringValue(refData.episodeTitle);
    }

    let format = '';
    let episodeNumber = shared.getStringValue(refData.episodeNumber);

    switch (refData.kind) {
        case 'audio':

            if (refData.type == 'series') {
                format = '[Audio podcast]';
            }

            if (refData.type == 'episode') {
                format = '[Audio podcast episode]';
            }

            break;
        case 'video':

            if (refData.type == 'series') {
                format = '[Video podcast]';
            }

            if (refData.type == 'episode') {
                format = '[Video podcast episode]';
            }

            break;
        case 'radio':

            if (refData.type == 'series') {
                format = '[Radio series]';
            }

            if (refData.type == 'episode') {
                format = '[Radio series episode]';
            }

            break;
    }

    if (refData.type == 'interview') {
        format = '[Interview]';
    }

    if (title.length > 0) {

        if (title.startsWith('[')) {
            titlePart = title;  
        }
        else{

            if (refData.type == 'series' || refData.type == 'interview') {
                title = title.italicize()
            }

            titlePart = title;  
        }

        if (episodeNumber.length > 0) {
            titlePart = titlePart + ' ';

            if(shared.isInteger(episodeNumber)){
                titlePart = titlePart + '(No. ' + episodeNumber + ')';
            }
            else{
                titlePart = titlePart + '(' + episodeNumber + ')';
            }
        }

        if (format.length > 0) {

            if (title.length > 0) {
                titlePart = titlePart + ' ';
            }

            titlePart = titlePart + format;
        }
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    //Hosta, A. A. (Host), & Producera, A. A. (Executive Producer).
    let authorPart = '';

    if (typeof refData.contributors === 'undefined') {
        return '';
    }

    if (refData.contributors.length == 0) {
        return '';
    }

    let filteredContribs = [];
    let typeAdded = false;
    let typeCount = 0;

    if (refData.type == 'series') {
        
        _forEach(refData.contributors, (item) => {
            if (item.type == 'host') {
                filteredContribs.push(item);

                if (!typeAdded) {
                    typeAdded = true;
                    typeCount++;
                }
            }
        });
        typeAdded = false;

        _forEach(refData.contributors, (item) => {
            if (item.type == 'executiveProducer') {
                filteredContribs.push(item);

                if (!typeAdded) {
                    typeAdded = true;
                    typeCount++;
                }
            }
        });
        typeAdded = false;
    }

    if (refData.type == 'episode') {
        
        _forEach(refData.contributors, (item) => {
            if (item.type == 'host') {
                filteredContribs.push(item);

                if (!typeAdded) {
                    typeAdded = true;
                    typeCount++;
                }
            }
        });
        typeAdded = false;
    }

    if (refData.type == 'interview') {
        
        _forEach(refData.contributors, (item) => {
            if (item.type == 'interview') {
                filteredContribs.push(item);

                if (!typeAdded) {
                    typeAdded = true;
                    typeCount++;
                }
            }
        });
        typeAdded = false;
    }

    authorPart = getPodcastReferenceNameString(filteredContribs, typeCount, false);

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    return authorPart;
}

function getDatePart(refData){

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let cleanedDate = '';

    if (refData.type == 'series') {
        //(2018–present).
        if (publicationDate.length == 0) {
            cleanedDate = 'n.d.';
        }
        else{
            cleanedDate = shared.fixPageDash(publicationDate);
            cleanedDate = cleanedDate.split(' ').join('');
        }        
    }

    if (refData.type == 'episode' || refData.type == 'interview') {
        //(2018, April 1)
        cleanedDate = shared.getFullDate(publicationDate);
    }

    let datePart = '(' + cleanedDate + ').';

    return datePart;
}

function getEpisodeSourcePart(refData){
    //Episode
    //In A. A. Producera (Executive Producer), <em>Series title</em>. 
    ///  rest is pulled from  the  source part Production Company 1; Production Company 2. https://www.perrla.com/podcast-and-radio
    let episodeSourcePart = '';

    let filteredContribs = [];

    if (typeof refData.contributors != 'undefined') {
        _forEach(refData.contributors, (item) => {
            if (item.type == 'executiveProducer') {
                filteredContribs.push(item);
            }
        });
    }

    if (filteredContribs.length > 0) {
        let secondaryContribString = getPodcastReferenceNameString(filteredContribs, 1, true);

        episodeSourcePart = 'In ' + secondaryContribString;
    }

    let seriesTitle = shared.getStringValue(refData.title);

    if (seriesTitle.length > 0) {
        if (episodeSourcePart.length == 0) {
            episodeSourcePart = 'In ';
        }
        else{
            episodeSourcePart = episodeSourcePart + ', ';
        }

        if (seriesTitle.startsWith('[')) {
            episodeSourcePart = episodeSourcePart + seriesTitle;  
        }
        else{
            episodeSourcePart = episodeSourcePart + seriesTitle.italicize();
        }        
    }

    //make sure we end this with a period
    if (episodeSourcePart.length > 0) {
        if (!episodeSourcePart.endsWith('.')) {
            episodeSourcePart = episodeSourcePart + '.';
        }    
    }

    let sourcePart = getSourcePart(refData);

    if (sourcePart.length > 0) {
        episodeSourcePart = episodeSourcePart + ' ' + sourcePart;
    }

    return episodeSourcePart;
}

function getSourcePart(refData){
    //Series
    //Production Company 1; Production Company 2. https://www.perrla.com/podcast-and-radio
    let sourcePart = '';
    let archiveName = shared.getStringValue(refData.archiveName);
    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);

    if (archiveName.length > 0) {
        sourcePart = archiveName;
    }

    if (publisherString != '.') {
        if (archiveName.length > 0) {
            sourcePart = sourcePart + '; ' + publisherString;
        }
        else{
            sourcePart = publisherString;
        }     
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}

function getPodcastReferenceNameString(contributors, typeCount, isSecondary){
    var contributorstring = '';

    if (contributors.length > 0) {

        //Single
        if (contributors.length == 1) {
            if (isSecondary) {
                contributorstring = shared.getReverseCitationName(contributors[0]);
            }
            else{
                contributorstring = shared.getReferenceName(contributors[0]);
            }

            let label = getNameLabel(contributors[0], false);

            if (label.length > 0) {
                contributorstring = contributorstring + ' ' + label;
            }
        }

        //2-20
        if (contributors.length > 1 && contributors.length < 21) {
            var counter = 0;

            _forEach(contributors, (item) => {

                if (counter > 0) {

                    if (counter == contributors.length - 1) {

                        if (isSecondary && contributors.length == 2) {
                            contributorstring = contributorstring + ' & '   
                        }
                        else{
                            contributorstring = contributorstring + ', & '
                        }
                    }
                    else{
                        contributorstring = contributorstring + ', '
                    }
                }

                if (isSecondary) {
                    contributorstring = contributorstring + shared.getReverseCitationName(item);
                }
                else{
                    contributorstring = contributorstring + shared.getReferenceName(item);
                }

                if (typeCount > 1) {
                    let label = getNameLabel(contributors[counter], false);

                    if (label.length > 0) {
                        contributorstring = contributorstring + ' ' + label;
                    }
                }

                counter++;
            });

            if (typeCount == 1) {
                let label = getNameLabel(contributors[0], true);

                if (label.length > 0) {
                    contributorstring = contributorstring + ' ' + label;
                }
            }
        }

        //More Than Twenty
        if (contributors.length > 20) {
            var counter = 0;

            _forEach(contributors, (item) => {

                let label = getNameLabel(item);

                if (counter < 19) {
                    if (counter > 0) {
                        contributorstring = contributorstring + ', '    
                    }

                    if (isSecondary) {
                        contributorstring = contributorstring + shared.getReverseCitationName(item);
                    }
                    else{
                        contributorstring = contributorstring + shared.getReferenceName(item);
                    }
                }
                else{
                    if (counter == contributors.length - 1) {
                        contributorstring = contributorstring + ',...'

                        if (isSecondary) {
                            contributorstring = contributorstring + shared.getReverseCitationName(item);
                        }
                        else{
                            contributorstring = contributorstring + shared.getReferenceName(item);
                        }
                    }
                }

                if (label.length > 0 && typeCount > 1) {
                    contributorstring = contributorstring + ' ' + label;
                }

                counter++;
            });
        }
    }
  
    return contributorstring;
}

function getNameLabel(contributor, plural){
    let label = '';

    switch (contributor.type) {
        case 'host':
            label = '(Host)';

            if (plural) {
                label = '(Hosts)';
            }

            break;
        case 'executiveProducer':
            label = '(Executive Producer)';

            if (plural) {
                label = '(Executive Producers)';
            }

            break;
    }

    return label;
}