/*
*****USStateCourtCases*****
 
Documentation:
FORM: https://docs.google.com/spreadsheets/d/1FAxGIq3vCU6zLwMZ1NYFD-JrWdegcBPhgnyn6wP2VHs/edit
MOCKUP: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-state-court-case

Type & Kind Questions
    There are no types or kinds.

TODO:
    [title], [citation.volume] [citation.series] [citation.pageNumber] ([court] [publicationDate]). [url]

    title: This is the case name. Name v. Name
    citation:
        volume: The volume number of the series.
        series: The abbreviated name of the series where the case was published.
        pageNumber: The starting page number of the case.
    court: The abbreviated name of the court where the case was held (if applicable).
    publicationDate: The date the verdic was rendered.
    url: The url if applicable.

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let title = shared.getStringValue(refData.title);
    let citations = getCitationString(refData.citation);
    let court = shared.getStringValue(refData.court);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    if (publicationDate.length == 0) {
        publicationDate = 'n.d.';
    }

    if (title.length > 0) {
        referenceValue = title;
    }

    if (citations.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + citations;
    }
    else{
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ',';
        }
    }

    if (court.length > 0 || publicationDate.length > 0) {

        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + '(';

        if (court.length > 0) {
            referenceValue = referenceValue + court;

            if (publicationDate.length > 0) {
                referenceValue = referenceValue + ' ';
            }
        }

        if (publicationDate.length > 0) {
            referenceValue = referenceValue + publicationDate;
        }

        referenceValue = referenceValue + ')';
    }

    if (referenceValue.length > 0) {
        referenceValue = referenceValue + '.';
    }

    if (url.length) {
        url = shared.formatURL(url);
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' ';
        }

        referenceValue = referenceValue + url;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let authorString = '';
    let title = shared.getStringValue(refData.title);
    let year = shared.getYear(refData.publicationDate);
    let appealDate = shared.getYear(refData.appealDate);

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (appealDate.length > 0) {
        year = year + '/' + appealDate;
    }

    if (title.length > 0) {
        authorString = title.italicize();
    }

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}

function getCitationString(citations){
    var citationString = '';

    if (typeof citations === 'undefined') {
        return citationString;
    }

    if (citations.length > 0) {

        citations.forEach(item => {
            
    
            let volume = shared.getStringValue(item.volume);
            let series = shared.getStringValue(item.series);
            let pageNumber = shared.getStringValue(item.pageNumber);
            let thisCitation = '';

            //if there are no pagenumbers, set this to ___
            if (pageNumber.length == 0) {
                pageNumber = '___';
            }

            if (volume.length > 0) {
                thisCitation = volume;
            }

            if (series.length > 0) {
                if (thisCitation.length > 0) {
                    thisCitation = thisCitation + ' ';
                }

                thisCitation = thisCitation + series;
            }

            if (pageNumber.length > 0) {
                if (thisCitation.length > 0) {
                    thisCitation = thisCitation + ' ';
                }

                thisCitation = thisCitation + pageNumber;
            }

            if (citationString.length > 0 && thisCitation.length > 0) {
                citationString = citationString + ', ';
            }

            if (thisCitation.length > 0) {
                citationString = citationString + thisCitation;
            }
        });
    }

    return citationString;
}