const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);

    //werid fix for empty date issue
    if (titlePart.endsWith('.') && locationPart.startsWith('(')) {
        titlePart = titlePart.removeLastCharacter();
    }

    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);
    workTitle = workTitle.replaceAmpersand().italicize();    
    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let citations = getCitationString(refData.citation);
    titlePart = titlePart.appendData(citations);

    return titlePart;
}


function getLocationPart(refData){
    let locationPart = '';

    let courtPublicationDatePart = '';

    let court = shared.getStringValue(refData.court);
    courtPublicationDatePart = courtPublicationDatePart.appendData(court);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    // if(publicationDate === 'n.d.'){
    //     courtPublicationDatePart = courtPublicationDatePart.appendData('N.d.');
    // } else {
        courtPublicationDatePart = courtPublicationDatePart.appendData(publicationDate);
    // }
    
    courtPublicationDatePart = courtPublicationDatePart.parenthesis();

    locationPart = locationPart.appendData(courtPublicationDatePart);

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
        locationPart = locationPart.appendData(url, ', ');
    }
    locationPart = locationPart.addClosure();
    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);
    workTitle = workTitle.replaceAmpersand().italicize();    
    firstInfo = firstInfo.appendData(workTitle, ', ');

    let citations = getCitationString(refData.citation);
    firstInfo = firstInfo.appendData(citations, ', ');

    let courtPublicationDatePart = '';

    let court = shared.getStringValue(refData.court);
    courtPublicationDatePart = courtPublicationDatePart.appendData(court);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    courtPublicationDatePart = courtPublicationDatePart.appendData(publicationDate);
    courtPublicationDatePart = courtPublicationDatePart.parenthesis();
    firstInfo = firstInfo.appendData(courtPublicationDatePart, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');


    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
        firstInfo = firstInfo.appendData(url, ', ');
    }
    

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);
    workTitle = workTitle.replaceAmpersand().italicize();

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}


function getCitationString(citations){
    let citationString = '';

    if (typeof citations === 'undefined') {
        return citationString;
    }
    
    _forEach(citations, (citationData, index)=>{
        let volume = shared.getStringValue(citationData.volume);
        if (volume.length > 0) {
            citationString = citationString.appendData(volume);
        }

        let series = shared.getStringValue(citationData.series);
        if (series.length > 0) {
            // series = seriesAbbreviationToFull(series);
            citationString = citationString.appendData(series);
        }

        let pageNumber = shared.getStringValue(citationData.pageNumber);
        if (pageNumber.length > 0) {
            pageNumber = shared.formatPageNumbers(pageNumber, false);
            citationString = citationString.appendData(pageNumber);
        }

        // add a comma between multiple citations
        if(citations.length > 1 && index !== citations.length - 1){
            citationString = citationString + ',';
        }
        
    });//e:forEach

    return citationString;
}//e:getCitationString


// function seriesAbbreviationToFull(abbreviation){
//     //APA is entered in abbreviations...if they entered and abbreviation...convert it

//     let word = '';

//     var conversions = [
//         ['A.', 'Atlantic Reporter'],
//         ['A.2d', 'Atlantic Reporter, Second Series'],
//         ['Cal. Rptr.', 'California Reporter'],
//         ['Cal. Rptr. 2d', 'California Reporter, Second Series'],
//         ['N.Y.S.', 'New York Supplement'],
//         ['N.Y.S.2d', 'New York Supplement, Second Series'],
//         ['N.E.', 'North Eastern Reporter'],
//         ['N.E.2d', 'North Eastern Reporter, Second Series'],
//         ['N.W.', 'North Western Reporter'],
//         ['N.W.2d', 'North Western Reporter, Second Series'],
//         ['P.', 'Pacific Reporter'],
//         ['P.2d', 'Pacific Reporter, Second Series'],
//         ['P.3d', 'Pacific Reporter, Third Series'],
//         ['S.E.', 'South Eastern Reporter'],
//         ['S.E.2d', 'South Eastern Reporter, Second Series'],
//         ['So.', 'Southern Reporter'],
//         ['So.2d', 'Southern Reporter, Second Series'],
//         ['S.W.', 'South Western Reporter'],
//         ['S.W.2d', 'South Western Reporter, Second Series'],
//         ['S.W. 3d', 'South Western Reporter, Third Series']
//     ];

//     for (let index = 0; index < conversions.length; index++) {
//         let thisConversion = conversions[index][0];

//         if (thisConversion.toLowerCase() == abbreviation.toLowerCase()) {
//             word = conversions[index][1];
//         }
//     }    

//     if (word == '') {
//         //if we havent converted yet, just leave it as is
//         word = abbreviation;
//     }

//     return word;
// } //e:seriesAbbreviationToFull