const _forEach = require('lodash/forEach');
const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'whole') {
        citationTitle = shared.getStringValue(refData.referenceTitle);
    }
    else{
        citationTitle = shared.getStringValue(refData.entryTitle);   
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'entryTitle';
    let isSecondaryTitle = true;

    let type = shared.getStringValue(refData.type);

    if (type == 'whole') {
        titleProperty = 'referenceTitle';
        isSecondaryTitle = false;
    }

    let primaryContributor;
    let editorsOnly = hasEditorsOnly(refData.contributors);

    if (editorsOnly && type == 'entry') {
        let fakeRefData = new Object();
		fakeRefData.entryTitle = refData.entryTitle;
        fakeRefData.referenceTitle = refData.referenceTitle;
		fakeRefData.contributors = []; //contribs arent listed in the citation here
        
        primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
    }
    else{
        primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
   
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //for this type, no contributors are primary..editors are always secondary
    let editorsOnly = hasEditorsOnly(refData.contributors);

    if (editorsOnly && type == 'entry') {
        primaryContributor = '';
    }
    else{
        primaryContributor = shared.getPrimaryContributorString(refData);
    }

    primaryPart = primaryContributor;

    let title = '';

    if (type == 'whole') {
        title = shared.getStringValue(refData.referenceTitle);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.italicize();
            }
        }
    }
    else{
        title = shared.getStringValue(refData.entryTitle);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.doubleQuote();
            }
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let referenceTitle = shared.getStringValue(refData.referenceTitle);

    if (referenceTitle.length > 0) {
        referenceTitle = referenceTitle.replaceAmpersand();
        referenceTitle = referenceTitle.toTitleCase();
        referenceTitle = referenceTitle.italicize();
    }

    let secondaryContributor;

    if (type == 'entry') {
        secondaryContributor = getSecondaryContributorString(refData.contributors);        
    }
    else{
        secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    }

    let publishers = shared.getPublisherString(refData.publishers);

    let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    publicationDate = shared.formatOriginalPublicationDate(publicationDate);

    let pageNumbers = shared.getStringValue(refData.pageNumber);
    pageNumbers = shared.formatPageNumbers(pageNumbers);

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
        }
    }

    switch (type) {
        case 'entry':

            switch (kind) {
                case 'encyclopedia':
                    //referenceTitle, secondary contributors, edition, publishers, publicationDate, pageNumber, url / doi.
                    container1 = container1.appendData(referenceTitle, '');
                    container1 = container1.appendData(secondaryContributor, ', ');
                    container1 = container1.appendData(edition, ', ');
                    container1 = container1.appendData(publishers, ', ');
                    container1 = container1.appendData(publicationDate, ', ');
                    container1 = container1.appendData(pageNumbers, ', ');

                    if (doi.length > 0) {
                        container1 = container1.appendData(doi, ', ');
                    }
                    else{
                        if (url.length > 0) {
                            container1 = container1.appendData(url, ', ');
                        }
                    }

                    break;
                case 'wikipedia':
                    //'Wikipedia,', secondary contributors, publicationDate, url.
                    container1 = container1.appendData('<em>Wikipedia</em>', '');
                    container1 = container1.appendData(secondaryContributor, ', ');
                    container1 = container1.appendData(publicationDate, ', ');
                    container1 = container1.appendData(url, ', ');
                    break;
            }
            
        break;

        case 'whole':
            //Secondary contributors, edition, publishers, publicationDate, url / doi.  
            container1 = container1.appendData(secondaryContributor, '');
            container1 = container1.appendData(edition, ', ');
            container1 = container1.appendData(publishers, ', ');
            container1 = container1.appendData(publicationDate, ', ');

            if (doi.length > 0) {
                container1 = container1.appendData(doi, ', ');
            }
            else{
                if (url.length > 0) {
                    container1 = container1.appendData(url, ', ');
                }
            }

            break;
    }

    //do not upper the the first letter if we start with a publisher
    if (publishers == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publishers)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //retrievalDate
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    retrievalDate = shared.getFullDate(retrievalDate);
    secondSupplemental = secondSupplemental.appendData(retrievalDate, ', ', 'accessed', '');


    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - which is editors and tranlators
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let editors = shared.getContributorsByType('editor', secondaryContributors);
            let translators = shared.getContributorsByType('translator', secondaryContributors);

            if (editors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(editors, 'editor'));
            }

            if (translators.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(translators, 'translator'));
            }


            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}

function hasEditorsOnly(contributors){
    let editorOnly = false;

    if (typeof contributors == 'undefined'){
        return editorOnly;
    }

    if (contributors.length > 0) {
        
        if (contributors[0].type == 'editor') {
            
            editorOnly = true;
            
			_forEach(contributors, (contrib) => {
                if (contrib.type != 'editor') {
                    editorOnly = true;
                }
            });
        }
    }

    return editorOnly;
}