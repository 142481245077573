const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(datePart);
    referenceValue = referenceValue.appendData(sourcePart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}


function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    return titlePart;
}


function getDatePart(refData){
    let datePart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getYear(publicationDate);

    if (publicationDate == 'n.d.') {
        publicationDate = publicationDate.upperFirstLetter();
    }

    datePart = datePart.appendData(publicationDate).addClosure();

    //format (ex: oil painting)
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format).upperFirstLetter();
    datePart = datePart.appendData(format).addClosure();

    return datePart;
}

function getSourcePart(refData){
    // Museum Name, Washington, D.C.
    // ('United States' not to be included)
    let sourcePart = '';

    let publisherString = shared.getPublisherStringInverted(refData.publishers);

    if(publisherString !== 'N.p.'){
        sourcePart = sourcePart.appendData(publisherString);
    }

    let city = shared.getStringValue(refData.city);
    if (city.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + city;
    }

    let state = shared.getStringValue(refData.state);
    if (state.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + state;
    }

    let country = shared.getStringValue(refData.country);
    if (country.length > 0 && country !== 'United States') {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + country;
    }

    sourcePart = sourcePart.addClosure();

    if (refData.type != 'museum') {
        // url
        let url = shared.getStringValue(refData.url);
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            sourcePart = sourcePart.appendData(url);
        }
    }
    return sourcePart;
}


function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle)//;.toLowerCase();
        if(authorPart !== ''){
            workTitle = workTitle.toLowerCase();
        }
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    // let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    // firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let publicationDate = shared.getStringValue(refData.publicationDate);
    // publicationDate = shared.getFullDate(publicationDate);
    publicationDate = shared.getYear(publicationDate);
    firstInfo = firstInfo.appendData(publicationDate, ', ');

    //format (ex: oil painting)
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format).toLowerCase();
    firstInfo = firstInfo.appendData(format, ", ");

    // source part (all the publishers/city/state/etc) - add it after the string is completed
    let sourcePart = '';

    let publisherString = shared.getPublisherStringInverted(refData.publishers);

    if(publisherString !== 'N.p.'){
        sourcePart = sourcePart.appendData(publisherString);
    }

    let city = shared.getStringValue(refData.city);
    if (city.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + city;
    }

    let state = shared.getStringValue(refData.state);
    if (state.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + state;
    }

    let country = shared.getStringValue(refData.country);
    if (country.length > 0 && country !== 'United States') {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + country;
    }

    firstInfo = firstInfo.appendData(sourcePart, ", ");


    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }
    

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        
        if(authorPart !== ''){
            workTitle = workTitle.toLowerCase();
        }

        workTitle = workTitle.addClosure();
    } else{
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
    }
    
    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}