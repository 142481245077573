const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

let useDescriptionForTitle = false; // set as a flag here so i can not include the descritpion later

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    // if this is a description, remove the brackets
    if (workTitle.startsWith('[')) {
        useDescriptionForTitle = true;
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        useDescriptionForTitle = false;
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    seriesTitle = seriesTitle.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(seriesTitle);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';
    
    // let publisherString = shared.getPublisherString(refData.publishers);
    // publicationPart = publicationPart.appendData(publisherString);

    let kind = shared.getStringValue(refData.kind);

    
    if(kind === 'doctorate'){
        publicationPart = publicationPart.appendData('PhD diss.');
    } else {
        publicationPart = publicationPart.appendData('Master\'s thesis');
    }

    let institution = shared.getStringValue(refData.institution);
    publicationPart = publicationPart.appendData(institution, ', ');

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate).upperFirstLetter();   
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';
    
    //record
    let record = shared.getStringValue(refData.record);
    record = shared.fixFormatString(record);
    locationPart = locationPart.appendData(record).addClosure();

    //database
    let database = shared.getStringValue(refData.database);
    database = shared.fixFormatString(database);
    locationPart = locationPart.appendData(database).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);

    // if this is a description, remove the brackets
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        workTitle = workTitle.addClosure();
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.toLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");

    let publisherPart = '';

    let kind = shared.getStringValue(refData.kind);
    if(kind === 'doctorate'){
        publisherPart = publisherPart.appendData('PhD diss.');
    } else {
        publisherPart = publisherPart.appendData('master\'s thesis');
    }
    
    let institution = shared.getStringValue(refData.institution);
    publisherPart = publisherPart.appendData(institution, ', ');
    
    // let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    
    // publisherPart = publisherPart.appendData(publisherLocationString);
    let publicationDate = shared.getFullDate(refData.publicationDate);
    publicationDate = publicationDate.upperFirstLetter();
    publisherPart = publisherPart.appendData(publicationDate, ', ');
    
    if(useDescriptionForTitle){
        firstInfo = firstInfo.appendData(publisherPart);
    } else {
        publisherPart = publisherPart.parenthesis();
        firstInfo = firstInfo.appendData(publisherPart, ', ');        
    }

    // record
    let record = shared.getStringValue(refData.record);
    record = shared.fixFormatString(record);
    firstInfo = firstInfo.appendData(record, ', ');

    // database
    let database = shared.getStringValue(refData.database);
    database = shared.fixFormatString(database);
    firstInfo = firstInfo.appendData(database, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        workTitle = workTitle.addClosure();
    } else{
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
        
    }


    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}