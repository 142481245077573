const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.bookTitle);

    if (citationTitle.length == 0) {
        citationTitle = shared.getStringValue(refData.translatedTitle);
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.originalPublicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'bookTitle';
    let isSecondaryTitle = false;

    let title = shared.getStringValue(refData.bookTitle);

    if (title.length == 0) {
        titleProperty = 'translatedTitle';
        isSecondaryTitle = false;
    }

    //make a copy of refdata to pass off to the shared code
    let fakeRefDate = new Object();
    fakeRefDate.bookTitle = refData.bookTitle;
    fakeRefDate.translatedTitle = refData.translatedTitle;
    fakeRefDate.contributors = []; //contribs arent listed in the citation here

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefDate, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let type = shared.getStringValue(refData.type);

    let title = shared.getStringValue(refData.bookTitle);

    
    
    

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.italicize();
        }

    } else {
        // title is blank, try to grab the translatedTitle to use in it's place
        title = shared.getStringValue(refData.translatedTitle);
        if(title){
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            } else{

                // title = title.toTitleCase();
                title = title.italicize();
            }
        }
       
    }

    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        } else{
            primaryPart = title;
        }
    }
    
    primaryPart = shared.fixClosure(primaryPart);


    let version = shared.getStringValue(refData.version);
    primaryPart = primaryPart.appendData(version);

    
    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //originalPublicationDate 
	let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
    originalPublicationDate = shared.getFullDate(originalPublicationDate);
    originalPublicationDate = shared.formatOriginalPublicationDate(originalPublicationDate);
	firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //All Contribs
    let allContribs = getSecondaryContributorString(refData.contributors);
    container1 = container1.appendData(allContribs, ', ');

    //edition
	let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
	container1 = container1.appendData(edition, ', ');
    
	//Publishers
	let publisherString = shared.getPublisherString(refData.publishers);
	container1 = container1.appendData(publisherString, ', ');

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    publicationDate = shared.formatOriginalPublicationDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	// doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }
    
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - which is editors and tranlators
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let editors = shared.getContributorsByType('editor', secondaryContributors);
            let translators = shared.getContributorsByType('translator', secondaryContributors);

            if (editors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(editors, 'editor'));
            }

            if (translators.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(translators, 'translator'));
            }


            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}