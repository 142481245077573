/*
*****ConferenceMaterials*****
 
FORM: https://docs.google.com/spreadsheets/d/1LuvOrew1KaBXoz30pgNYep2l4cT9g-sUcaq5p36rvQw/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-conference-symposium

TYPES:
    conference
        - A conference session or presentation
        [contributor.author]. ([publicationDate combined with endDate]). <em>[title]</em> [[description]]. [conference], [city], [state], [country]. <a href="[url or doi]">[url or doi]</a>"
    symposium
        - Material presented at a symposium
        [contributor.author]. ([publicationDate combined with endDate]). [title]. In [contributor.chair] (Chair *or* Chairs), <em>[symposiumTitle]</em>. [conference], [city], [state], [country].

AuthorPart
    contributor.author
        - Normal author behavior

DatePart
    publicationDate & endDate
        - if there is only publicationDate, then format as YYYY, MM DD
        - If there is a publicationDate and an endDate, then it should be YYYY, MM DD–DD (e.g., 2020, April 1–3 or 2020, March 30–April 1)
        - always uses an en-dash (Option & -)
        - No date = n.d.

TitlePart
    title
        - italicize in conference, but don't in symposium
    description
        - Happens in conference, inside brackets after the title
        - in symposium, the description is always the same & input automatically "[Symposium]"

SourcePart
    contributor.chair
        - for symposium, we have secondary contributors (similar to Editors)
        - Follows the title
        - Always preceeded by "In "
        - If there is only one, follow with "[Chair}"
        - If there are multiple, follow with "[Chairs]"
        - Names follow normal mutli-contributor rules (1, 2+, or 20+)
    conference
        - The name of the conferece
        - Italicize for symposium
    city
        - name of the city, nothing special
    state
        - Two letter abbreviation for the state
        - if they do more, whatever
    country
        - Name of the country
    url/doi
        - typical URL & DOI

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            
            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let endDate = shared.getStringValue(refData.endDate);
    let formattedDate = '';
    let datePart = '';

    if (endDate.length > 0) {
        formattedDate = shared.getFullDateRange(publicationDate, endDate);
    }
    else{
        formattedDate = shared.getFullDate(publicationDate);
    }

    datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let title = shared.getStringValue(refData.title);
    let description = shared.getStringValue(refData.description);

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{

            if (type == 'conference') {
                titlePart = title.italicize();    
            }   
            else{
                titlePart = title;
            }
        }
    }

    if (description.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + description + ']';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';

    //chair
    let chairArray = shared.getChairs(refData.contributors);
    let chairString = shared.getReferenceNameString(chairArray, true);

    if (chairArray.length == 1) {
        chairString = chairString + ' (Chair)';
    }
    else{
        if (chairArray.length > 1) {
            chairString = chairString + ' (Chairs)';
        }
    }

    let symposiumTitle = shared.getStringValue(refData.symposiumTitle);
    let conference = shared.getStringValue(refData.conference);
    let city = shared.getStringValue(refData.city);
    let state = shared.getStringValue(refData.state);
    let country = shared.getStringValue(refData.country);
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let type = shared.getStringValue(refData.type);

    if (chairString.length > 0) {
        sourcePart = chairString;
    }

    if (symposiumTitle.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + symposiumTitle.italicize() + ' [Symposium].'; 
    }

    if (conference.length > 0) {
        if (sourcePart.length > 0) {

            if (sourcePart.endsWith('.')) {
                sourcePart = sourcePart + ' ';
            }
            else{
                sourcePart = sourcePart + '. ';
            }
        }

        sourcePart = sourcePart + conference;
    }

    if (city.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + city;
    }

    if (state.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + state;
    }

    if (country.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + country;
    }

    if (sourcePart.length > 0) {
        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    if (sourcePart.length > 0 && type == 'symposium') {
        sourcePart = 'In ' + sourcePart;
    }

    return sourcePart;
}