const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
	let secondSupplemental = getSecondSupplemental(refData);
    let containerTwo = getContainerTwo(refData);

    let referenceValue = primaryInfo;

	let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }
	
	// this type and kind has 2nd supplemental inbetween the containers
	if(type === 'reprinted' && kind === 'editorial'){
		if (secondSupplemental.length > 0) {
			referenceValue = referenceValue + ' ' + secondSupplemental;
		}
		if (containerTwo.length > 0) {
			referenceValue = referenceValue + ' ' + containerTwo;
		}
	} else {
		// all other types and kind have the 2nd supplemental at the end like normal
		if (containerTwo.length > 0) {
			referenceValue = referenceValue + ' ' + containerTwo;
		}
		if (secondSupplemental.length > 0) {
			referenceValue = referenceValue + ' ' + secondSupplemental;
		}
	}

	let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
		authorPart: primaryContributor,
		authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
		authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

	citationTitle = shared.getStringValue(refData.articleTitle);

    if (refData.kind == 'issue') {
        citationTitle = shared.getStringValue(refData.issueTitle);
    }

	if (refData.kind == 'section') {
        citationTitle = shared.getStringValue(refData.sectionTitle);
    }

	citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'articleTitle';
    let isSecondaryTitle = true;

	let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if (kind == 'issue') {
		titleProperty = 'issueTitle';
		isSecondaryTitle = false;
	}

	if (kind == 'section') {
		titleProperty = 'sectionTitle';
		isSecondaryTitle = true;
	}

	let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
	let shortenPageNumber = true;
    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate, shortenPageNumber);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

	let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	let title = '';
	
	if(type === 'special'){
		if(kind ==='section'){
			title = shared.getStringValue(refData.sectionTitle);
		} else if(kind === 'issue'){
			title = shared.getStringValue(refData.issueTitle);
		}
	} else {
		title = shared.getStringValue(refData.articleTitle);
	}
     
	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		}
		else{
			title = title.replaceAmpersand();
			title = title.toTitleCase();
		}
	}

	if(type === 'special'){
		if(kind ==='section'){
			title = title.doubleQuote();	
		} else if(kind === 'issue'){
			title = title.italicize();
		}
	} else {
		title = title.doubleQuote();
	}


    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }
	
	if(type === 'special'){
		if(kind ==='section'){
			//translatedSectionTitle
			let translatedSectionTitle = shared.getStringValue(refData.translatedSectionTitle);
			if(translatedSectionTitle.length > 0){
				translatedSectionTitle = translatedSectionTitle.replaceAmpersand();
				translatedSectionTitle = translatedSectionTitle.toTitleCase();
				translatedSectionTitle = translatedSectionTitle.doubleQuote();
				primaryPart = primaryPart.appendData('[' + translatedSectionTitle + ']', ' ', '', '');        
			}
		} else if(kind ==='issue'){
			//translatedIssueTitle
			let translatedIssueTitle = shared.getStringValue(refData.translatedIssueTitle);
			if(translatedIssueTitle.length > 0){
				translatedIssueTitle = translatedIssueTitle.replaceAmpersand();
				translatedIssueTitle = translatedIssueTitle.toTitleCase();
				translatedIssueTitle = translatedIssueTitle.italicize();
				primaryPart = primaryPart.appendData('[' + translatedIssueTitle + ']', ' ', '', '');        
			}
		}
	} else {
		//translatedArticleTitle
		let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);
		if(translatedArticleTitle.length > 0){
			translatedArticleTitle = translatedArticleTitle.replaceAmpersand();
			translatedArticleTitle = translatedArticleTitle.toTitleCase();
			translatedArticleTitle = translatedArticleTitle.doubleQuote();
			primaryPart = primaryPart.appendData('[' + translatedArticleTitle + ']', ' ', '', '');        
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

	let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

	if(type === 'newspaper' && (kind === 'article' || kind ===  'editorial' || kind === 'foreign')){
		// originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');
	}

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';  
	
	let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);

    // newspaperTitle
	let newspaperTitle = shared.getStringValue(refData.newspaperTitle);
	if (newspaperTitle.length > 0) {
		newspaperTitle = newspaperTitle.replaceAmpersand();
		newspaperTitle = newspaperTitle.toTitleCase();
		newspaperTitle = newspaperTitle.italicize();
		
		if(type === 'special'){
			if(kind ==='section'){
				container1 = container1.appendData(newspaperTitle, ', ', 'Special section of');
			} else if(kind ==='issue'){
				container1 = container1.appendData(newspaperTitle, ', ', 'Special issue of');
			}
		} else {
			container1 = container1.appendData(newspaperTitle, ', ');
		}

	}

	//Secondary Contribs
    let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    container1 = container1.appendData(secondaryContributor, ', ');

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	//referencePages
	let referencePages = shared.getStringValue(refData.referencePages);
	referencePages = shared.formatPageNumbers(referencePages, true);
    container1 = container1.appendData(referencePages, ', ');

	// doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }
	

	container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

// 2nd supplemental goes inbetween the containers (for newspaper / editorial)
function getSecondSupplemental(refData){
    let secondSupplemental = '';
	
	let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    
	if(kind === 'editorial'){
		secondSupplemental = 'Editorial';
	}

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

function getContainerTwo(refData){
    let container2 = '';
    
	let type = shared.getStringValue(refData.type);
	let kind = shared.getStringValue(refData.kind);

	if(type === 'reprinted'){
		//originalArticleTitle
		let originalArticleTitle = shared.getStringValue(refData.originalArticleTitle);
		if (originalArticleTitle.length > 0) {
			originalArticleTitle = originalArticleTitle.replaceAmpersand();
			originalArticleTitle = originalArticleTitle.toTitleCase();
			originalArticleTitle = originalArticleTitle.doubleQuote();
			container2 = container2.appendData(originalArticleTitle, ', ', 'Originally published as');
		}

		//originalNewspaperTitle
		let originalNewspaperTitle = shared.getStringValue(refData.originalNewspaperTitle);
		if (originalNewspaperTitle.length > 0) {
			originalNewspaperTitle = originalNewspaperTitle.replaceAmpersand();
			originalNewspaperTitle = originalNewspaperTitle.toTitleCase();
			originalNewspaperTitle = originalNewspaperTitle.italicize();
			container2 = container2.appendData(originalNewspaperTitle, ', ',);
		}
			
		// originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
		container2 = container2.appendData(originalPublicationDate, ', ');
		
		//originalPages
		let originalPages = shared.getStringValue(refData.originalPages);
		originalPages = shared.formatPageNumbers(originalPages);
		container2 = container2.appendData(originalPages, ', ');

		// originalDoi/originalUrl
		let originalUrl = shared.getStringValue(refData.originalUrl);
		let originalDoi = shared.getStringValue(refData.originalDoi);
	
		if (originalDoi.length > 0) {
			originalDoi = shared.appendDOIURL(originalDoi);
			container2 = container2.appendData(originalDoi, ', ');
		} else if (originalUrl.length > 0) {
			originalUrl = shared.formatURL(originalUrl);
			container2 = container2.appendData(originalUrl, ', ');
		}

	} else if(kind === 'retracted'){
		//retractionDate
		let retractionDate = shared.getStringValue(refData.retractionDate);
	    retractionDate = shared.getFullDate(retractionDate);

		let prefix = 'Retracted in';
		if (retractionDate.length > 4) {
			prefix = 'Retracted on';
		}

		container2 = container2.appendData(retractionDate, ', ', prefix);

		//retractionPages
		let retractionPages = shared.getStringValue(refData.retractionPages);
		retractionPages = shared.formatPageNumbers(retractionPages);
		container2 = container2.appendData(retractionPages, ', ');

		// retractionDOI/retractionURL
		let retractionURL = shared.getStringValue(refData.retractionURL);
		let retractionDOI = shared.getStringValue(refData.retractionDOI);
	
		if (retractionDOI.length > 0) {
			retractionDOI = shared.appendDOIURL(retractionDOI);
			container2 = container2.appendData(retractionDOI, ', ');
		} else if (retractionURL.length > 0) {
			retractionURL = shared.formatURL(retractionURL);
			container2 = container2.appendData(retractionURL, ', ');
		}

		
	}//e:if:reprinted



    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}
