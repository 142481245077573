const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    
    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch(type){
        case 'us':
            titlePart = titlePart.appendData('U.S. Constitution.');
            break;
        case 'state':
            let state = shared.getStringValue(refData.state);
            titlePart = titlePart.appendData(state, '', '', 'Constitution.');
            break;
        case 'charter':
            let title = shared.getStringValue(refData.title);
            titlePart = titlePart.appendData(title);
            titlePart = titlePart.addClosure();

            // charter doesn't have a kind so it will skip the below step
            let charterArticle = shared.getStringValue(refData.charterArticle);
            charterArticle = convertRomanNumerals(charterArticle);
            titlePart = titlePart.appendData(charterArticle, ' ', 'art.');

            let paragraph = shared.getStringValue(refData.paragraph);
            paragraph = convertRomanNumerals(paragraph);
            titlePart = titlePart.appendData(paragraph, ', ', 'para.');

            break;
    }

    if(type === 'us' || type === 'state'){
        // grab some data that will get injected in different orders based on kind
        let amendment = shared.getStringValue(refData.amendment);
        if(amendment.length > 0){
            amendment = convertRomanNumerals(amendment);
        }
        let section = shared.getStringValue(refData.section);
        if(section.length > 0){
            section = convertRomanNumerals(section);
        }

        switch(kind){
            case 'article':
                let article = shared.getStringValue(refData.article);
                if(article.length > 0){
                    article = convertRomanNumerals(article);
                    titlePart = titlePart.appendData(article, ' ', 'art.');
                }

                if(section.length > 0){
                    titlePart = titlePart.appendData(section, ', ', 'sec.');
                }
                break;
            case 'amendment':
                if(amendment.length > 0){
                    titlePart = titlePart.appendData(amendment, ' ', 'amend.');
                }
                if(section.length > 0){
                   titlePart = titlePart.appendData(section, ', ', 'sec.');
                }
                break;
            case 'repealed':
                if(amendment.length > 0){
                    titlePart = titlePart.appendData(amendment, ' ', 'amend.');
                }
                titlePart = titlePart.appendData(section, ', ', 'sec.');

                let repealDate = shared.getStringValue(refData.repealDate);
                repealDate = shared.getFullDate(repealDate);
                let parenthesisPart = '';
                parenthesisPart = parenthesisPart.appendData(repealDate, ' ', 'repealed');
                parenthesisPart = parenthesisPart.parenthesis();
                titlePart = titlePart.appendData(parenthesisPart);
                break;
        }//e:switch
    }//e:if

    titlePart = titlePart.addClosure();

    return titlePart;
}

function getCitationTitle(refData){
    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch(type){
        case 'us':
            firstInfo = firstInfo.appendData('U.S. Constitution.');
            break;
        case 'state':
            let state = shared.getStringValue(refData.state);
            firstInfo = firstInfo.appendData(state, '', '', 'Constitution.');
            break;
        case 'charter':
            let title = shared.getStringValue(refData.title);
            firstInfo = firstInfo.appendData(title);
            firstInfo = firstInfo.addClosure();

            // charter doesn't have a kind so it will skip the below step
            let charterArticle = shared.getStringValue(refData.charterArticle);
            charterArticle = convertRomanNumerals(charterArticle);
            firstInfo = firstInfo.appendData(charterArticle, ' ', 'art.');

            let paragraph = shared.getStringValue(refData.paragraph);
            paragraph = convertRomanNumerals(paragraph);
            firstInfo = firstInfo.appendData(paragraph, ', ', 'para.');

            break;
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    if(type === 'us' || type === 'state'){
        // grab some data that will get injected in different orders based on kind
        let amendment = shared.getStringValue(refData.amendment);
        if(amendment.length > 0){
            amendment = convertRomanNumerals(amendment);
        }
        let section = shared.getStringValue(refData.section);
        if(section.length > 0){
            section = convertRomanNumerals(section);
        }

        switch(kind){
            case 'article':
                let article = shared.getStringValue(refData.article);
                if(article.length > 0){
                    article = convertRomanNumerals(article);
                    firstInfo = firstInfo.appendData(article, ' ', 'art.');
                }
                if(section.length > 0){
                    firstInfo = firstInfo.appendData(section, ', ', 'sec.');
                }
                break;
            case 'amendment':
                if(amendment.length > 0){
                    firstInfo = firstInfo.appendData(amendment, ' ', 'amend.');
                }
                if(section.length > 0){
                    firstInfo = firstInfo.appendData(section, ', ', 'sec.');
                }
                break;
            case 'repealed':
                if(amendment.length > 0){
                    firstInfo = firstInfo.appendData(amendment, ' ', 'amend.');
                }
                if(section.length > 0){
                    firstInfo = firstInfo.appendData(section, ', ', 'sec.');
                }

                let repealDate = shared.getStringValue(refData.repealDate);
                repealDate = shared.getFullDate(repealDate);
                let parenthesisPart = '';
                parenthesisPart = parenthesisPart.appendData(repealDate, ' ', 'repealed');
                parenthesisPart = parenthesisPart.parenthesis();
                firstInfo = firstInfo.appendData(parenthesisPart);
                break;
        }
    }
    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    // let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    // subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.workTitle);
    workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();

    // subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    // subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');
    
    subsequentInfo = firstInfo;

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

// https://medium.com/swlh/how-to-build-a-roman-numeral-to-integer-function-in-javascript-8298657a26f7
function convertRomanNumerals(input){
    // input should be a range like II–V, start by converting that into an array
    let fixedInput = shared.fixPageDash(input);
    let rangeArray = fixedInput.split('–');

    let outputString = '';

    const romanHash = {
        I: 1,
        V: 5,
        X: 10,
        L: 50,
        C: 100,
        D: 500,
        M: 1000,
    };
    
    // loop through each rangeArray to build an output string
    _forEach(rangeArray, (singleRange, index)=>{
        // check this to see if it's a real number like 3, or roman numberals like III
        if(isNaN(parseFloat(singleRange))){
            // this is a roman number like III

            let accumulator = 0;

            // loop through each digit in this range (if it was IV, this would be a single I first, then a single V in the second loop)
            for (let i = 0; i < singleRange.length; i++) {
                if (singleRange[i] === "I" && singleRange[i + 1] === "V") {
                    accumulator += 4;
                    i++;
                } else if (singleRange[i] === "I" && singleRange[i + 1] === "X") {
                    accumulator += 9;
                    i++;
                } else if (singleRange[i] === "X" && singleRange[i + 1] === "L") {
                    accumulator += 40;
                    i++;
                } else if (singleRange[i] === "X" && singleRange[i + 1] === "C") {
                    accumulator += 90;
                    i++;
                } else if (singleRange[i] === "C" && singleRange[i + 1] === "D") {
                    accumulator += 400;
                    i++;
                } else if (singleRange[i] === "C" && singleRange[i + 1] === "M") {
                    accumulator += 900;
                    i++;
                } else {
                    accumulator += romanHash[singleRange[i]];

                }
            } // e:for

            outputString += accumulator.toString();

        } else {
            // this a real number like 3
            outputString += singleRange;

        } //e:if
        
        // not the last item - add a dash
        if(rangeArray.length >= 2 && index < rangeArray.length - 1){
            outputString += '–';
        }
        
    });//e:forEach
    
    return outputString;
      
}//e:convertRomanNumerals