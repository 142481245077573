const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation,
    getAuthorPart,
    getDatePart
}

//Notes from breakdown with Graysen
/*
Type Question
Kind Quesstion

-Type: (book) Book, EBook, Audiobook
    - Kind: (whole) All or Part
    - Kind: (translated) Foreign or Translated
    - Kind: (republished) Republished
    - Kind: (multivolume) Volume in Series
[AuthorPart][DatePart][TitlePart][SourcePart]


- Type: Chapter in Edited Book
    - Kind: All or Part Chapter
    - Kind: Foreign or Translated Chapter 
    - Kind: Republished chapter from a book
    - Kind: Chapter from a volume in a series
[ChapterAuthorPart][DatePart][ChapterTitlePart][ChapterSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalBookSourcePart]]]
[ChapterTitlePart][DatePart][ChapterSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalBookSourcePart]] - (APA Example 10.44)
    - Kind: Republished from journal
[ChapterAuthorPart][DatePart][ChapterTitlePart][ChapterJournalSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalJournalSourcePart]] - (APA Example 10.43)

- AuthorPart
    - Authors, Group Authors, Editors
    - This is done: Book.getAuthorPart
- DatePart
    - Either a year, year-month-day, nd, specific for kind or type
    - This is done: Book.getDatePart
- TitlePart
    - Title of Book (SeriesTitle, VolumeTitle, Volume), Translated Title, Editors, Translators, Narrators, Edition, (Volume) Format
    - This is done: Book.getTitlePart
- SourcePart
    - Publishers, DOI/URL, Original Publication Date
    - This is done: Book.getSourcePart
- ChapterAuthorPart
    - ChapterAuthors, ChapterGroupAuthors
    - If there is nothing, re-order with title ahead of date
    - This is done: Book.getChapterAuthorPart
- ChapterTitlePart
    - ChapterTitle, TranslatedChapterTitle, Translators
    - This is done: Book.getChapterTitlePart   


- ChapterSourcePart
    - "In "
    - BookEditorPart
        - Editors + " Eds."
    - BookTitlePart
        - Title of Book, Translated Book Title, Narrators, edition, volume, page numbers, format
    - BookSourcePart
        - Publishers, DOI/URL
    - OriginalBookSourcePart
        - "Reprinted from "
        - Original Title, Original Pages, Original Contributors, Original Publication Date, Original Publishers

- ChapterJournalSourcePart
    - "In "
    - Same BookEditorPart
    - Same BookTitlePart
    - Same BookSourcePart
    - OriginalJournalSourcePart
        - "Reprinted from "
        - Original Article Title, Original Publication Date, Original Journal Title, Original Volume Issue Number, Original Pages, Original DOI URL
*/


function getReference (refData){

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let refAuthorPart = '';
    let refDatePart = '';

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let referenceValue = '';
    let datePart = getDatePart(refData);
    refDatePart = datePart;

    switch (type) {
        case 'book':
        case 'series':
            //[AuthorPart][DatePart][TitlePart][SourcePart]
            let authorPart = getAuthorPart(refData);
            let titlePart = getTitlePart(refData);
            let sourcePart = getSourcePart(refData);

            refAuthorPart = authorPart;

            if (authorPart.length == 0) {
                //no contributors
                referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
            }
            else{
                referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
            }

            break;
        case 'bookChapter':
        
            let chapterAuthorPart = getChapterAuthorPart(refData);
            let chapterTitlePart = getChapterTitlePart(refData);

            refAuthorPart = chapterAuthorPart;

            // [ChapterAuthorPart][DatePart][ChapterTitlePart][ChapterSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalBookSourcePart]]]
            // [ChapterTitlePart][DatePart][ChapterSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalBookSourcePart]] - (APA Example 10.44)
            if (kind == 'whole' || kind == 'translated' || kind == 'multivolume' || kind == 'book') {
                let chapterSourcePart = getChapterSourcePart(refData);

                if (chapterAuthorPart.length == 0) {
                    //no contributors
                    referenceValue = chapterTitlePart + ' ' + datePart + ' ' + chapterSourcePart;  
                }
                else{
                    referenceValue = chapterAuthorPart + ' ' + datePart + ' ' + chapterTitlePart + ' ' + chapterSourcePart;
                }
            }

            //[ChapterAuthorPart][DatePart][ChapterTitlePart][ChapterJournalSourcePart [BookEditorPart][BookTitlePart][BookSourcePart][OriginalJournalSourcePart]] - (APA Example 10.43)
            if (kind == 'journal') {
                let chapterJournalSourcePart = getChapterJournalSourcePart(refData);

                if (chapterAuthorPart.length == 0) {
                    //no contributors
                    referenceValue = chapterTitlePart + ' ' + datePart + ' ' + chapterJournalSourcePart;  
                }
                else{
                    referenceValue = chapterAuthorPart + ' ' + datePart + ' ' + chapterTitlePart + ' ' + chapterJournalSourcePart;
                }
            }

            break;
    }

    let reference = {
        value : referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: refAuthorPart,
        datePart: refDatePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs){
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

	// i'm using lodash here since the chrome ext creates contributors as an object 
	_forEach(refData.contributors, (item) => {
    	if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor') {
            authorArray.push(item);
        }
	});

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);

    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            //use chapter intead of book for book chapters
            if (refData.type == 'bookChapter') {
                authorString = '"' + refData.chapterTitle.toTitleCase() + '," ';
            }
            else{

                if (refData.bookTitle.trim().length == 0) {
                    authorString = '';
                }
                else
                {
                    authorString = refData.bookTitle.trimSubtitle().toTitleCase().italicize();
                }                
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0 && (refData.kind == 'book' || refData.kind == 'journal' || refData.kind == 'republished')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/'+ year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors
        if (editorArray.length > 0) {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getChapterAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (publicationDate.length == 0) {
        publicationDate = 'n.d.';
    }
    else{
        if (refData.publicationDate.includes("/")) {
            var array = refData.publicationDate.split("/");

            if (array.length > 2) {
                publicationDate = array[2];    
            }
            else {
                publicationDate = shared.getYear(publicationDate);
            }
        }
        else{
            publicationDate = refData.publicationDate;
        }
    }

    publicationDate = shared.fixPageDash(publicationDate);

    let datePart = '(' + publicationDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let title = shared.getStringValue(refData.bookTitle);
    let titlePart = '';
    
    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    let volumeTitle = shared.getStringValue(refData.volumeTitle);
    let volume = shared.getStringValue(refData.volume);

    if (seriesTitle.length > 0 || volumeTitle.length > 0) {

        if (seriesTitle.length > 0) {
            title = seriesTitle;
            
            if (volume.length > 0) {
                
                if (volumeTitle.length > 0) {

                    title = title + ': Vol. ' + volume;

                    //clear the volume to be ignored later
                    volume = '';

                    title = title + '. ' + volumeTitle;                
                }
                // else{
                //     title = title + ' Vol. ' + volume;
                // }
            }
            else{
                if (volumeTitle.length > 0) {
                    title = title + ': ' + volumeTitle;
                } 
            }
        }
        else{
            title = volumeTitle;   
        }
    }

    if (title.length > 0) {
        if (title.startsWith('[')) {
            titlePart = title;    
        }
        else{
            titlePart = title.trimSubtitle().italicize();
        }    
    }
    
    //translated title
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[' + translatedTitle + ']';
    }

    //editors
    let editorArray = [];
    if (refData.kind == 'volume') {
        editorArray = getVolumeEditors(refData.contributors);
    }else{
        editorArray = shared.getEditors(refData.contributors);
    }

    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length >0) {
        editorString = '';
        translatorString = '';
    }

    //illustrators
    let illustratorString = '';

    illustratorString = shared.getIllustratorString(refData.contributors);

    //narrators
    let narratorArray = [];

    if (typeof refData.contributors != 'undefined') {
		_forEach(refData.contributors, (item) => {
			if (item.type == 'narrator') {
                if(item.lastName.length > 0 ||
                    item.firstName.length > 0 ||
                    item.middleName.length > 0 ||
                    item.suffix.length > 0){
                        narratorArray.push(item);
                }
            }
		});
    }

    let narratorString = '';

    if (narratorArray.length > 0) {
        narratorString = shared.getReferenceNameString(narratorArray, true);

        if (narratorArray.length == 1) {
            narratorString = narratorString + ', Narr.';    
        }
        else{
            narratorString = narratorString + ', Narrs.';  
        }
        
    }

    //edition
    let edition = shared.getStringValue(refData.edition);

    let authorArray = shared.getAuthors(refData.contributors);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0||
        narratorString.length > 0 ||
        illustratorString.length > 0 ||
        edition.length > 0 ||
        volume.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorTranslatorString.length > 0) {
            titlePart = titlePart + editorTranslatorString;
            isFirstNames = false;
        }

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + translatorString;    
            }
            else{
                titlePart = titlePart + '; ' + translatorString; 
            }
            isFirstNames = false;
        }

        if (illustratorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + illustratorString;    
            }
            else{
                titlePart = titlePart + '; ' + illustratorString; 
            }
            isFirstNames = false;
        }

        if (narratorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + narratorString;    
            }
            else{
                titlePart = titlePart + '; ' + narratorString; 
            }
            isFirstNames = false;
        }

        if ((edition.length > 0 || volume.length > 0) && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (edition.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            edition = shared.fixEditionString(edition);

            titlePart = titlePart + edition;
        }

        if (volume.length > 0) {
            if (edition.length > 0) {
                titlePart = titlePart + ',';
            }

            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }
            
            if (refData.type == 'series' && refData.kind == 'series') {
                titlePart = titlePart + volume + ' vols.';
            }
            else{
                titlePart = titlePart + 'Vol. ' + volume;
            }
        }

        titlePart = titlePart + ')';
    }

    //format
    let format = shared.getStringValue(refData.format);
    
    if (format.length > 0) {
        titlePart = titlePart + ' [' + format + ']';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getChapterTitlePart(refData){
    let chapterTitle = shared.getStringValue(refData.chapterTitle);

    //translatedChapterTitle
    let translatedChapterTitle = shared.getStringValue(refData.translatedChapterTitle);

    // let translatorString  = '';
    // let editorTranslatorString = '';
    // let narratorString = '';

    // if (refData.kind != 'journal') {
    //     //translators
    //     translatorString = shared.getTranslatorString(refData.contributors);

    //     //editor tranlators
    //     //if we have this here, we need to ignore the translators...it will get taken care of in Chapter Source
    //     editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    //     if (editorTranslatorString.length > 0) {
    //         translatorString = '';
    //     }

    //     //narrators
    //     narratorString = shared.getNarratorString(refData.contributors);
    // }
    
    //now build the title part
    let titlePart = chapterTitle;
    
    if (translatedChapterTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedChapterTitle + ']';
    }

    // if (translatorString.length > 0 || narratorString.length > 0) {
    //     titlePart = titlePart + ' (';

    //     titlePart = titlePart + translatorString;

    //     if (translatorString.length > 0 && narratorString.length > 0) {
    //         titlePart = titlePart + '; ';
    //     }

    //     titlePart = titlePart + narratorString;

    //     titlePart = titlePart + ')';
    // }

    //check for chapterTranslators
    let translatorString  = '';
    translatorString = shared.getChapterTranslatorString(refData.contributors);

    if (translatorString.length > 0) {
        titlePart = titlePart + ' (' + translatorString + ')';
    }
    
    //titlePart = shared.fixTitlePartClosure(titlePart);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getSourcePart(refData){
    //Crypto Press; Bigfoot Times; Nessie & Co. https://doi.org/10.1109/5.771073
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        doi = shared.appendDOIURL(doi);

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    //publication Date Original
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);

    if (publicationDateOriginal.length > 0) {
        sourcePart = sourcePart + ' (Original work published ' + publicationDateOriginal + ')';
    }

    return sourcePart;
}

function getChapterSourcePart(refData){
    let chapterSourcePart = 'In';

    let bookEditorPart = getBookEditorPart(refData);
    let bookTitlePart = getBookTitlePart(refData);
    let bookSourcePart = getBookSourcePart(refData);
    let originalBookSourcePart = getOriginalBookSourcePart(refData);

    if (bookEditorPart.length > 0) {
        chapterSourcePart = chapterSourcePart + ' ' + bookEditorPart;
    }

    if (bookTitlePart.length > 0) {

        if (chapterSourcePart != 'In') {
            chapterSourcePart = chapterSourcePart + ',';
        }

        chapterSourcePart = chapterSourcePart + ' ' + bookTitlePart;
    }

    if (bookSourcePart.length > 0) {
        chapterSourcePart = chapterSourcePart + ' ' + bookSourcePart;
    }

    if (originalBookSourcePart.length > 0) {
        chapterSourcePart = chapterSourcePart + ' ' + originalBookSourcePart;
    }

    return chapterSourcePart;
}

function getBookEditorPart(refData){

    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length > 0) {

        //this format needs brackets IE: (Ed. & Trans.)
        editorTranslatorString = editorTranslatorString.replace(', Ed. & Trans.', ' (Ed. & Trans.)')

        return editorTranslatorString;
    }

    let editorString = '';
    let editorArray = shared.getEditors(refData.contributors);
    let authorArray = shared.getAuthors(refData.contributors);
    let translatorArray = shared.getTranslators(refData.contributors);
    let chapterTranslatorArray = shared.getChapterTranslators(refData.contributors);

    if (editorArray.length > 0) {
        if (authorArray.length == 0 && translatorArray.length == 0 && chapterTranslatorArray.length == 0) {
            editorString = shared.getEditorsAtStartofRefString(editorArray);
        }
        else{
            editorString = shared.getReferenceNameString(editorArray, true);
        }    
    }
    
    if (editorArray.length == 1) {
        editorString = editorString + ' (Ed.)';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ' (Eds.)';
        }
    }

    return editorString;
}

function getBookTitlePart(refData){
    let bookTitlePart = '';

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    let volumeTitle = shared.getStringValue(refData.volumeTitle);
    let volume = shared.getStringValue(refData.volume);
    let seriesVolumeTitle = '';

    if (seriesTitle.length > 0 || volumeTitle.length > 0) {

        if (seriesTitle.length > 0) {
            seriesVolumeTitle = seriesTitle;
            
            if (volume.length > 0) {
                seriesVolumeTitle = seriesVolumeTitle + ': Vol. ' + volume;

                //clear the volume to be ignored later
                volume = '';

                if (volumeTitle.length > 0) {
                    seriesVolumeTitle = seriesVolumeTitle + '. ' + volumeTitle;                
                }
            }
            else{
                if (volumeTitle.length > 0) {
                    seriesVolumeTitle = seriesVolumeTitle + ': ' + volumeTitle;
                } 
            }
        }
        else{
            seriesVolumeTitle = volumeTitle;   
        }
    }

    if (seriesVolumeTitle.length > 0) {
        seriesVolumeTitle = seriesVolumeTitle.italicize();    
    }

    //book title
    let bookTitle = shared.getStringValue(refData.bookTitle);

    if (bookTitle.length > 0) {
        bookTitle = bookTitle.italicize();
    }

    //translated title
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        translatedTitle = translatedTitle.italicize();    
    }

    let translatorString  = '';
    let editorTranslatorString = '';
    let narratorString = '';

    if (refData.kind != 'journal') {
        //translators
        translatorString = shared.getTranslatorString(refData.contributors);

        //editor tranlators
        //if we have this here, we need to ignore the translators...it will get taken care of in Chapter Source
        editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

        if (editorTranslatorString.length > 0) {
            translatorString = '';
        }
    }

    //narrators
    narratorString = shared.getNarratorString(refData.contributors);

    //edition
    let edition = shared.getStringValue(refData.edition);

    if (edition.length > 0) {
        edition = shared.fixEditionString(edition);
    }

    //pages
    let referencePages = shared.getStringValue(refData.referencePages);

    if (referencePages.length > 0) {
        var label = 'p.';
        if (referencePages.includes('-') || referencePages.includes('–') || referencePages.includes(':')) {
            label = 'pp.';
        }

        referencePages = label + ' ' + shared.fixPageDash(referencePages);
    }

    //format
    let format = shared.getStringValue(refData.format);
    
    if (format.length > 0) {
        format = '[' + format + ']';
    }

    //Title of Book, Translated Book Title, Narrators, edition, volume, page numbers, format
    if (translatedTitle.length > 0) {
        bookTitlePart = bookTitlePart + translatedTitle;
    }
    else{
        bookTitlePart = bookTitlePart + bookTitle;
    }

    if (seriesVolumeTitle.length > 0) {
        bookTitlePart = bookTitlePart + seriesVolumeTitle
    }

    if (seriesTitle.length > 0) {
        //clear out the volume var here, so we don't use it in the next step
        volume = '';
    }
    
    var editionPart = '';

    if (edition.length > 0 || referencePages.length > 0 || volume.length > 0 || translatorString.length > 0 || narratorString.length > 0) {
        editionPart = editionPart + ' (';

        if (translatorString.length > 0) {
            editionPart = editionPart + translatorString;
            editionPart = editionPart + '; ';
        }

        if (narratorString.length > 0) {
            editionPart = editionPart + narratorString;
            editionPart = editionPart + '; ';
        }

        if (edition.length > 0) {
            editionPart = editionPart + edition;            
        }

        if (volume.length > 0 && refData.kind != 'journal') {
            if (edition.length > 0) {
                editionPart = editionPart + ', ';
            }

            editionPart = editionPart + 'Vol. ' + volume;
        }

        if (referencePages.length > 0) {
            if (editionPart.length > 0 && !editionPart.endsWith('(') && !editionPart.endsWith(', ') && !editionPart.endsWith('; ')) {
                editionPart = editionPart + ', ';
            }
        
            editionPart = editionPart + referencePages;
        }

        if (editionPart.endsWith('; ')) {
            editionPart = editionPart.substring(0, editionPart.length - 2);
        }
        
        editionPart = editionPart + ')';
    }

    bookTitlePart = bookTitlePart + editionPart;
    
    if (format.length > 0) {
        bookTitlePart = bookTitlePart + ' ' + format;
    }

    bookTitlePart = shared.fixTitlePartClosure(bookTitlePart);

    return bookTitlePart;
}

function getBookSourcePart(refData){
    //Publishers, DOI/URL
    let bookSourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != authorString && (publisherString != authorString + '.') && publisherString != '.') {
        bookSourcePart = publisherString;    
    }
    
    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    //publication Date Original
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);

    if (doi.length > 0) {
        if (bookSourcePart.length > 0) {
            bookSourcePart = bookSourcePart + ' ';
        }

        doi = shared.appendDOIURL(doi);

        bookSourcePart = bookSourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (bookSourcePart.length > 0) {
                bookSourcePart = bookSourcePart + ' ';
            }
    
            bookSourcePart = bookSourcePart + url;
        }
    }

    if (publicationDateOriginal.length > 0 && refData.kind != 'book' && refData.kind != 'journal') {
        bookSourcePart = bookSourcePart + ' (Original work published ' + publicationDateOriginal + ')';
    }

    return bookSourcePart;
}

function getOriginalBookSourcePart(refData){
    //- Original Title, Original Pages, Original Contributors, Original Publication Date, Original Publishers
    //(Reprinted from <em>Searching for Sasquatch</em>, pp. 21–31, by B. B. Editorb Jr., Ed., 1987, Bigfoot Times)
    let originalBookSourcePart = ''; 

    if (refData.kind == 'book') {
        let bookTitleOriginal = shared.getStringValue(refData.originalBookTitle);
        let originalPages = shared.getStringValue(refData.originalPages);

        if (originalPages.length > 0) {
            var label = 'p.';
            if (originalPages.includes('-') || originalPages.includes('–') || originalPages.includes(':')) {
                label = 'pp.';
            }
    
            originalPages = label + ' ' + originalPages;
        }
        
        let originalEditorString = shared.getOriginalEditorString(refData.contributors);
        let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
        let originalPublisherString = shared.getOriginalPublisherString(refData.publishers);
        
        //build the part
        originalBookSourcePart = '(Reprinted from ';

        if (bookTitleOriginal.length > 0) {
            originalBookSourcePart = originalBookSourcePart + bookTitleOriginal.italicize();
            originalBookSourcePart = originalBookSourcePart + ', ';    
        }
        
        if (originalPages.length > 0) {
            originalBookSourcePart = originalBookSourcePart + originalPages;
            originalBookSourcePart = originalBookSourcePart + ', '; 
        }

        if (originalEditorString.length > 0) {
            originalBookSourcePart = originalBookSourcePart + 'by ' + originalEditorString;
            originalBookSourcePart = originalBookSourcePart + ', '; 
        }

        if (publicationDateOriginal.length > 0) {
            originalBookSourcePart = originalBookSourcePart + publicationDateOriginal;
            originalBookSourcePart = originalBookSourcePart + ', '; 
        }
        else{
            originalBookSourcePart = originalBookSourcePart + 'n.d., '; 
        }

        if (originalPublisherString.length > 0) {
            originalBookSourcePart = originalBookSourcePart + originalPublisherString;
        }

        if (originalBookSourcePart.endsWith(', ')) {
            originalBookSourcePart = originalBookSourcePart.substring(0, originalBookSourcePart.length - 2)
        }

        originalBookSourcePart = originalBookSourcePart + ')';
    }

    return originalBookSourcePart;
}

function getChapterJournalSourcePart(refData){
    let chapterJournalSourcePart = 'In'; 

    //[BookEditorPart][BookTitlePart][BookSourcePart][OriginalJournalSourcePart]
    let bookEditorPart = getBookEditorPart(refData);
    let bookTitlePart = getBookTitlePart(refData);
    let bookSourcePart = getBookSourcePart(refData);
    let originalJournalSourcePart = getOriginalJournalSourcePart(refData);

    if (bookEditorPart.length > 0) {
        chapterJournalSourcePart = chapterJournalSourcePart + ' ' + bookEditorPart;
    }

    if (bookTitlePart.length > 0) {

        if (chapterJournalSourcePart.length > 0 && bookEditorPart.length > 0) {
            chapterJournalSourcePart = chapterJournalSourcePart + ',';
        }

        chapterJournalSourcePart = chapterJournalSourcePart + ' ' + bookTitlePart;
    }

    if (bookSourcePart.length > 0) {
        chapterJournalSourcePart = chapterJournalSourcePart + ' ' + bookSourcePart;
    }

    if (originalJournalSourcePart.length > 0) {

        let url = shared.getStringValue(refData.url);
        let doi = shared.getStringValue(refData.doi);

        if (chapterJournalSourcePart.length > 0 && !chapterJournalSourcePart.endsWith('.') && url.length == 0 && doi.length == 0) {
            chapterJournalSourcePart = chapterJournalSourcePart + '.';
        }

        chapterJournalSourcePart = chapterJournalSourcePart + ' ' + originalJournalSourcePart;
    }

    return chapterJournalSourcePart;
}

function getOriginalJournalSourcePart(refData){
    let originalJournalSourcePart = '';
    //(Reprinted from "articleTitle" publicationDateOriginal, <em>journalTitle</em>, volume[issue], originalPages, doi)
    //(Reprinted from  \"Searching for Bigfoot,\" 1945, <em>Cryptozoology Today</em>, 2[3], 21–31, https://doi.org/10.1109/5.771073)

    let articleTitle = shared.getStringValue(refData.articleTitle);
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);
    let journalTitle = shared.getStringValue(refData.journalTitle);
    let volume = shared.getStringValue(refData.volume);
    let issue = shared.getStringValue(refData.issue);
    let originalPages = shared.getStringValue(refData.originalPages);
    let originalDoi = shared.getStringValue(refData.originalDoi);
    let originalUrl = shared.getStringValue(refData.originalUrl);

    originalJournalSourcePart = '(Reprinted from ';

    if (articleTitle.length > 0) {
        originalJournalSourcePart = originalJournalSourcePart + '"' + articleTitle + ',"';
    }

    if (publicationDateOriginal.length > 0) {
        if (!originalJournalSourcePart.endsWith(' ')) {
            originalJournalSourcePart = originalJournalSourcePart + ' ';
        }
        originalJournalSourcePart = originalJournalSourcePart + publicationDateOriginal;
    }
    else{
        if (!originalJournalSourcePart.endsWith(' ')) {
            originalJournalSourcePart = originalJournalSourcePart + ' ';
        }

        originalJournalSourcePart = originalJournalSourcePart + 'n.d.'; 
    }

    if (journalTitle.length > 0) {
        if (!originalJournalSourcePart.endsWith(',') || !originalJournalSourcePart.endsWith(',"')) {
            originalJournalSourcePart = originalJournalSourcePart + ', ';
        }

        originalJournalSourcePart = originalJournalSourcePart + journalTitle.italicize();
    }

    //if there is no volume and there is an issue, replace it
    if (volume.length == 0 && issue.length > 0) {
        volume = issue;
        issue = '';
    }

    if (volume.length > 0) {
        if (!originalJournalSourcePart.endsWith(',') || !originalJournalSourcePart.endsWith(',"')) {

            if (originalJournalSourcePart.endsWith('!</em>') 
                || originalJournalSourcePart.endsWith('?</em>') 
                || originalJournalSourcePart.endsWith('.</em>')) {
                    originalJournalSourcePart = originalJournalSourcePart + ' ';
            }
            else{
                originalJournalSourcePart = originalJournalSourcePart + ', ';
            }
        }
        
        originalJournalSourcePart = originalJournalSourcePart + volume.italicize();

        if (issue.length > 0) {
            originalJournalSourcePart = originalJournalSourcePart + '[' + issue + ']';
        }
    }

    if (originalPages.length > 0) {
        if (!originalJournalSourcePart.endsWith(',') || !originalJournalSourcePart.endsWith(',"')) {
            originalJournalSourcePart = originalJournalSourcePart + ', ';
        }

        originalJournalSourcePart = originalJournalSourcePart + shared.fixPageDash(originalPages);
    }

    if (originalDoi.length > 0) {
        if (!originalJournalSourcePart.endsWith(',') || !originalJournalSourcePart.endsWith(',"')) {
            originalJournalSourcePart = originalJournalSourcePart + ', ';
        }

        originalJournalSourcePart = originalJournalSourcePart + shared.appendDOIURL(originalDoi);
    }
    else{
        if (originalUrl.length > 0) {
            if (!originalJournalSourcePart.endsWith(',') || !originalJournalSourcePart.endsWith(',"')) {
                originalJournalSourcePart = originalJournalSourcePart + ', ';
            }
    
            originalUrl = shared.formatURL(originalUrl);
            originalJournalSourcePart = originalJournalSourcePart + originalUrl;
        }
    }
    
    originalJournalSourcePart = originalJournalSourcePart + ')';

    return originalJournalSourcePart;
}

function getVolumeEditors(contributors){
    let editorArray = [];

    contributors = shared.removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'volumeEditor') {
            editorArray.push(item);
        }
    });

    return editorArray;
}