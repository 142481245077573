/*
*****OnlineComment*****
 
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-online-comment
FORM: https://docs.google.com/spreadsheets/d/1dsP2E0humU09xEJDU0X08JyBovcf5oKuya-CZmmYJKI/edit#gid=1084144617

TYPES:
    forum
        - This is an exact copy of Forum from Social Media
        [contributors]. ([pubicationDate]). <em>[content]</em> [Online forum post]. [platform]. [url]
    comment
        - A comment on an article or webpage
        article: [contributors]. ([publicationDate]). [content] [Comment on the article "[title]"]. <em>[source]</em>. [url]  ***the brackets around "Comment on the..." aren't fields like [title]
        page: [contributors]. ([publicationDate]). [content] [Comment on the webpage "[title]"]. <em>[source]</em>. [url]

KINDS:
    comment has two kinds: article & page
        - the only difference between them is the inserted text
            "[Comment on the article...]" vs. "[Comment on the webpage...]"

AuthorPart
    contributors
        - username: Same as normal
        - groupAuthors: These now include a username field
    content
        - if there are no contributors, start with [content]

DatePart
    publicationDate
        - Normal.  Uses MM/DD/YY.  Leave out any missing parts.
        - Uses n.d. when empty.

TitlePart
    content
        - this is the first 20 words of the comment
    platform
        - forum type only
        - the name of the platform where the content was made
    title
        - comment type only
        - title of the page where the comment was made

SourcePart
    source
        - comment type only
        - name of the source of the page where the comment was made (pseudo publisher, but doesn't act like it)
    url
        - the url for the page where the comment was made

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }


    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    let type = shared.getStringValue(refData.type);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.content);

            if (title.length > 0) {
                //trim this to the first five words
                let titleArray = title.split(' ');
                if(titleArray.length > 0){
                    let i = 0;

                    while (i < 5) {
                        if (authorString.length > 0) {
                            authorString = authorString + ' ';
                        }

                        authorString = authorString + titleArray[i];
                        i++;
                      }

                      authorString = authorString;
                }
                else{
                    authorString = title;
                }
                
                if (type == 'forum') {
                    authorString = authorString.italicize();
                }
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    let titlePart = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let content = shared.getStringValue(refData.content);
    let title = shared.getStringValue(refData.title);

    if (content.length > 0) {

        content = shared.limitWordsInString(content, 20);

        if (type == 'forum') {
            titlePart = content.italicize();    
        }
        else{
            titlePart = content;
        }
    }

    let label = '';

    switch (type) {
        case 'forum':
            label = '[Online forum post]';
            break;
        case 'comment':

            if (title.length > 0) {
                if (kind == 'article') {
                    label = "[Comment on the article '" + title + "']";
                }
    
                if (kind == 'webpage') {
                    label = "[Comment on the webpage '" + title + "']";
                }        
            }
        
            break;
    }

    if (label.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + label;
    }

    

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    let sourcePart = '';
    let platform = shared.getStringValue(refData.platform);
    let source = shared.getStringValue(refData.source);
    let url = shared.getStringValue(refData.url);

    if (platform.length > 0) {
        sourcePart = platform + '.';
    }

    if (source.length > 0) {
        sourcePart = source.italicize() + '.';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}