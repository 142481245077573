/*
*****Constitutions*****
 
FORM: https://docs.google.com/spreadsheets/d/1v3lHaILY_D_os-6fRGmlnGLnYJSmMp-r1lk5yO9gjKY/edit#gid=2071428744
MOCKUP: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-constitutions-charters

Type & Kind Questions
    - us: United States Constitution
        - article: Article(s) of the US Constitution
        - amendment: Amendment(s) to the US Constitution
        - repealed: Repealed amendment to the US Constitution
    - state: State Constitution
        - article: Article(s) of a state Constitution
        - amendment: Amendment(s) to a state Constitution
        - repealed: Repealed amendment to a state Constitution
    - charter: an organizational charter

Parts
    - us article: U.S. Const. art. [article], § [section].
    - us amendment: U.S. Const. amend. [amendment].
    - us repealed: U.S. Const. amend. [amendment]. (repealed [repealDate]).

    - state article: [state] Const. art. [article], § [secrtion].
    - state amendment: [state] Const. amend. [amendment].
    - state repealed: [state] Const. amend. [amendment]. (repealed [repealDate]).
    
    - charter: [title] art. [charterArticle], para. [paragraph].

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let article = shared.getStringValue(refData.article);
    let section = shared.getStringValue(refData.section);
    let amendment = shared.getStringValue(refData.amendment);
    let repealDate = shared.getStringValue(refData.repealDate);
    let state = shared.getStringValue(refData.state);
    let title = shared.getStringValue(refData.title);
    let charterArticle = shared.getStringValue(refData.charterArticle);
    let paragraph = shared.getStringValue(refData.paragraph);
    let label = '';

    section = formatSectionString(section);
    paragraph = formatPargraphString(paragraph);

    switch (type) {
        case 'us':
            referenceValue = 'U.S. Const.';

            switch (kind) {
                case 'article':
                    label = 'art.';
                    break;
                case 'amendment':
                case 'repealed':
                    label = 'amend.';
                    break;
            }

            referenceValue = referenceValue + ' ' + label;

            if (kind == 'article') {
                if (article.length > 0) {
                    referenceValue = referenceValue + ' ' + article;

                    if (section.length > 0) {
                        referenceValue = referenceValue + ',';
                    }
                }

                if (section.length > 0) {
                    referenceValue = referenceValue + ' ' + section;
                }

                referenceValue = referenceValue + '.';
            }
            else{
                if (amendment.length > 0) {
                    referenceValue = referenceValue + ' ' + amendment;
                }

                if (repealDate.length > 0) {
                    referenceValue = referenceValue + ' (repealed ' + repealDate +').';
                }
            }

            if (!referenceValue.endsWith('.')) {
                referenceValue = referenceValue + '.';
            }

            break;
        case 'state':

            if (state.length > 0) {
                referenceValue = state;
            }

            switch (kind) {
                case 'article':
                    label = 'Const. art.';
                    break;
                case 'amendment':
                case 'repealed':
                    label = 'Const. amend.';
                    break;
            }

            referenceValue = referenceValue + ' ' + label;

            if (kind == 'article') {
                if (article.length > 0) {
                    referenceValue = referenceValue + ' ' + article;

                    if (section.length > 0) {
                        referenceValue = referenceValue + ',';
                    }
                }

                if (section.length > 0) {
                    referenceValue = referenceValue + ' ' + section;
                }

                referenceValue = referenceValue + '.';
            }
            else{
                if (amendment.length > 0) {
                    referenceValue = referenceValue + ' ' + amendment;
                }

                if (repealDate.length > 0) {
                    referenceValue = referenceValue + ' (repealed ' + repealDate +').';
                }
            }

            if (!referenceValue.endsWith('.')) {
                referenceValue = referenceValue + '.';
            }

            break;
        case 'charter':
            if (title.length > 0) {
                referenceValue = title;
            }

            referenceValue = referenceValue + ' art.';

            if (charterArticle.length > 0) {
                referenceValue = referenceValue + ' ' + charterArticle;
            }            

            if (paragraph.length > 0) {
                if (charterArticle.length > 0) {
                    referenceValue = referenceValue + ',';
                } 

                referenceValue = referenceValue + ' ' + paragraph;
            }

            if (!referenceValue.endsWith('.')) {
                referenceValue = referenceValue + '.';
            }

            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);
    let authorString = '';

    let article = shared.getStringValue(refData.article);
    let section = shared.getStringValue(refData.section);
    let amendment = shared.getStringValue(refData.amendment);
    let repealDate = shared.getStringValue(refData.repealDate);
    let state = shared.getStringValue(refData.state);
    let title = shared.getStringValue(refData.title);
    let charterArticle = shared.getStringValue(refData.charterArticle);
    let paragraph = shared.getStringValue(refData.paragraph);
    let label = '';

    section = formatSectionString(section);
    paragraph = formatPargraphString(paragraph);

    switch (type) {
        case 'us':
            authorString = 'U.S. Const.';

            switch (kind) {
                case 'article':
                    label = 'art.';
                    break;
                case 'amendment':
                case 'repealed':
                    label = 'amend.';
                    break;
            }

            authorString = authorString + ' ' + label;

            if (kind == 'article') {
                if (article.length > 0) {
                    authorString = authorString + ' ' + article;

                    if (section.length > 0) {
                        authorString = authorString + ',';
                    }
                }

                if (section.length > 0) {
                    authorString = authorString + ' ' + section;
                }
            }
            else{
                if (amendment.length > 0) {
                    authorString = authorString + ' ' + amendment;
                }

                if (repealDate.length > 0) {
                    authorString = authorString + ', repealed ' + repealDate;
                }
            }

            break;
        case 'state':
            if (state.length > 0) {
                authorString = state;
            }

            switch (kind) {
                case 'article':
                    label = 'Const. art.';
                    break;
                case 'amendment':
                case 'repealed':
                    label = 'Const. amend.';
                    break;
            }

            authorString = authorString + ' ' + label;

            if (kind == 'article') {
                if (article.length > 0) {
                    authorString = authorString + ' ' + article;

                    if (section.length > 0) {
                        authorString = authorString + ',';
                    }
                }

                if (section.length > 0) {
                    authorString = authorString + ' ' + section;
                }
            }
            else{
                if (amendment.length > 0) {
                    authorString = authorString + ' ' + amendment;
                }

                if (repealDate.length > 0) {
                    authorString = authorString + ' (repealed ' + repealDate + ')';
                }
            }

            break;
        case 'charter':

            if (title.length > 0) {
                authorString = title;
            }

            authorString = authorString + ' art.';

            if (charterArticle.length > 0) {
                authorString = authorString + ' ' + charterArticle;
            }            

            if (paragraph.length > 0) {
                if (charterArticle.length > 0) {
                    authorString = authorString + ',';
                } 

                authorString = authorString + ' ' + paragraph;
            }

            break;
    }

    let year = '';

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}

function formatSectionString(section){
    let prefix = '§';

    if (section.length == 0) {
        return '';
    }

    if (section.includes('-') || section.includes('–') || section.includes('—')) {
        prefix = '§§';
    }

    section = prefix + ' ' + section; 
    
    return section;
}

function formatPargraphString(paragraph){
    let prefix = 'para.';

    if (paragraph.length == 0) {
        return '';
    }

    if (paragraph.includes('-') || paragraph.includes('–') || paragraph.includes('—')) {
        prefix = 'paras.';
    }

    paragraph = prefix + ' ' + paragraph; 
    
    return paragraph;
}