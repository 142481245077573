const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: false
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    seriesTitle = seriesTitle.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(seriesTitle);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    // let publisherString = shared.getPublisherString(refData.publishers);
    // publicationPart = publicationPart.appendData(publisherString);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    
    publicationPart = publicationPart.upperFirstLetter();

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let workTitle = shared.getStringValue(refData.description);
    workTitle = shared.replaceWithAbbreviations(workTitle);
    workTitle = workTitle.lowerFirstLetter();
    if(authorPart === ''){
        workTitle = workTitle.upperFirstLetter();
    }
    firstInfo = firstInfo.appendData(workTitle, ", ");

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");

    // let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    let publicationDate = shared.getFullDate(refData.publicationDate);

    let publisherPart = '';
    // publisherPart = publisherPart.appendData(publisherLocationString);
    if(authorPart === '' && workTitle === ''){
        publicationDate = publicationDate.upperFirstLetter();
    }
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.description);

    if(subsequentAuthorPart === '' && workTitle === '' ){
        // test 9 - nothing: no work title or authors so this should only return N.d. (the publication date)
        subsequentInfo = subsequentInfo.appendData(publicationDate, ', ');
    } else {
        workTitle = workTitle.shortenName().lowerFirstLetter();
        if(subsequentAuthorPart === ''){
            workTitle = workTitle.upperFirstLetter();
        }
        subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    }
    
    

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}