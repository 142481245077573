const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

let useDescriptionForTitle = false; // set as a flag here so i can not include the descritpion later

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';
    
    // use description if there isn't a title
    let workTitle = shared.getStringValue(refData.title);

    if (workTitle) {
        // work title has content
        useDescriptionForTitle = false;
        
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
        }
        
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            useDescriptionForTitle = true;
            workTitle = workTitle.addClosure();
        } else {
            useDescriptionForTitle = false;
        }
    }

    titlePart = titlePart.appendData(workTitle);  // %Rh - i can't test 19 - "How Do Geckos Walk on Water?," to turn into "How Do Geckos Walk on Water?"
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();


    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    seriesTitle = seriesTitle.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(seriesTitle);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let isPublisherAndAuthorSame = shared.isPublisherAndAuthorSame(refData);

    let publicationPart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);

    if (refData.type === 'webinar') {

        let preText = '';
        if (publicationDate != 'n.d.') {
            preText = 'Recorded';
        }

        publicationPart = publicationPart.appendData(publicationDate, ' ', preText);

        if(!isPublisherAndAuthorSame){
            publicationPart = publicationPart.addClosure();

            if (preText.length > 0) {
                publicationPart = publicationPart.appendData('webinar', ' ', publisherString);
            }
            else{
                publicationPart = publicationPart.appendData('Webinar', ' ', publisherString);
            }
            
        }
    } else {
        if (refData.kind === 'hosted' && !isPublisherAndAuthorSame) {
            publicationPart = publicationPart.appendData(publisherString);
            publicationPart = publicationPart.addClosure();
        }

        publicationPart = publicationPart.appendData(publicationDate);
        publicationPart = publicationPart.addClosure();
    }

    let workTitle = shared.getStringValue(refData.title);

    if(!useDescriptionForTitle && !workTitle.startsWith('[')){
        // the description was not used for the title, so you can include it here
        let description = shared.getStringValue(refData.description);
        description = shared.replaceWithAbbreviations(description);
        description = description.upperFirstLetter();
        publicationPart = publicationPart.appendData(description);
    }

    let runtime = shared.parseTimeStampString(refData.runtime);
    
    if (publicationPart.endsWith('.')) {
        publicationPart = publicationPart.appendData(runtime);
    }
    else{
        publicationPart = publicationPart.appendData(runtime, ', ');
    }

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    // use description if there isn't a title
    let workTitle = shared.getStringValue(refData.title);
    let descriptionAsTitle = false;

    if (workTitle) {
        // work title has content
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
            // workTitle = workTitle.addClosure();
            descriptionAsTitle = true;
        } else{
            // workTitle = workTitle.toTitleCase() + ',';
            workTitle = workTitle.replaceAmpersand().toTitleCase();
            workTitle = workTitle.doubleQuote();
            
        }
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            // workTitle = workTitle.addClosure();
            descriptionAsTitle = true;
        }
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");
   
    let publicationDate = shared.getFullDate(refData.publicationDate);

    let isPublisherAndAuthorSame = shared.isPublisherAndAuthorSame(refData);

    let publisherPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);

    if (refData.type === 'webinar') {

        let preText = '';
        if (publicationDate != 'n.d.') {
            preText = 'recorded';
        }

        publisherPart = publisherPart.appendData(publicationDate, '', preText);
        
        if(!isPublisherAndAuthorSame && publisherString.length > 0){
            publisherPart = publisherPart.appendData(publisherString, ', ', '', 'webinar');
        }
        else{
            publisherPart = publisherPart.appendData('webinar', ', ');
        }
    } else {
        if (refData.kind === 'hosted' && !isPublisherAndAuthorSame) {
            let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false);
            publisherPart = publisherPart.appendData(publisherLocationString);
        }
        publisherPart = publisherPart.appendData(publicationDate, ', ');
    }
    
    firstInfo = firstInfo.appendData(publisherPart, ", ", '', '', true);
    
    
    if(!useDescriptionForTitle && !descriptionAsTitle){
        // the description was not used for the title, so you can include it here
        let description = shared.getStringValue(refData.description);
        description = shared.replaceWithAbbreviations(description);
        description = description.toLowerCase();
        firstInfo = firstInfo.appendData(description, ', ');
    }

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    citationLocation = shared.parseTimeStampString(citationLocation);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    
    // use description if there isn't a title    
    workTitle = shared.getStringValue(refData.title);

    if (workTitle) {
        // work title has content
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
            workTitle = workTitle.addClosure();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
            
        }
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            workTitle = workTitle.shortenName().addClosure();
        }
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}