/*
- Third Party Lookup
-- accessed from ReferenceList > Create Modals / Reference Edit / Paper Edit > Create Drawers
-- ex: this.$store.state.thirdPartyLookup.x
*/

export default {
	namespaced: true,
	
	state:{
		referenceResults: [],	// array of results from 3PLU
		searchTerm: '',		// String: term user searched for 3PLU
	},//e:state

	mutations: {
		REFERENCE_RESULTS(state, value) {
			state.referenceResults = value;
		},
		SEARCH_TERM(state, value) {
			state.searchTerm = value;
		},
	},//e:mutations
}
