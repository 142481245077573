/*
*****Music*****
 
Documentation: TODO: Include a link to the Google Document for this type here

Type & Kind Questions
- Types:
    - "album" : A whole album or stand-alone piece
    - "song" : A song or track on an album

TODO: Document the parts and their fields here
AuthorPart
    - Author, Group Author, Album title (if no artist & album), Song title (if no artist & song)

DatePart
    - publicationDate

TitlePart
    - Album name (if album), Song name (if song), "[Album]" (if album), "[Song]" (if song), "; recorded by" (if a cover, added into Format brackets)

SourcePart
    "On " Album Name (if a song), Label Names, "(Original work published ####)" (if a cover)

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        //referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;
        //for this type, our data part is split into the middle title part
        referenceValue = titlePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = '';
            let songTitle = shared.getStringValue(refData.songTitle);
            let albumTitle = shared.getStringValue(refData.albumTitle);

            if (songTitle.length > 0) {
                title = songTitle;
            }
            else{
                if (albumTitle.length > 0) {
                    title = albumTitle;
                }
            }

            if (title.length > 0) {
                title = shared.stripSubtitle(title);

                if (title.startsWith('[')) {
                    authorString = title;
                }
                else{
                    if (songTitle.length > 0) {
                        authorString = title; 
                    }
                    else{
                        authorString = title.toTitleCase().italicize(); 
                    }  
                }    
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0) {
        publicationDateOriginal = 'n.d.';

        if (refData.kind == 'cover') {
            year = publicationDateOriginal + '/'+ year;    
        }
    }
    else{
        year = publicationDateOriginal + '/'+ year;
    }

    if (citationData.type == 'time') {
        citationData.value = shared.fixTimeCitationData(citationData.value);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    let titlePart = '';

    let albumTitle = shared.getStringValue(refData.albumTitle);
    let songTitle = shared.getStringValue(refData.songTitle);
    let format = shared.getStringValue(refData.format);
    let groupPerformerString = shared.getGroupPerformerString(refData.contributors);
    let performerArray = shared.getPerformers(refData.contributors);
    let performerString = shared.getReferenceNameString(performerArray, true);
    let authorPart = getAuthorPart(refData);

    if (refData.type == 'album') {
        //<em>Album name</em> [Album recorded by Group Performer Name; Special version].
        if (albumTitle.length > 0) {
            titlePart = albumTitle.italicize() + ' ';

            titlePart = titlePart + '[Album';

            if (groupPerformerString.length > 0) {
                titlePart = titlePart + ' recorded by ' + groupPerformerString;
            }

            if (format.length > 0) {
                titlePart = titlePart + '; ' + format;
            }

            titlePart = titlePart + ']';
        }
        else{
            if (songTitle.length > 0) {
                titlePart = songTitle + ' ';

                titlePart = titlePart + '[Song';

                if (performerString.length > 0) {
                    titlePart = titlePart + ' recorded by ' + performerString;
                }

                if (groupPerformerString.length > 0) {
                    titlePart = titlePart + ' recorded by ' + groupPerformerString;
                }

                if (format.length > 0) {
                    titlePart = titlePart + '; ' + format;
                }

                titlePart = titlePart + ']';
            }
        }

        //if we don't have an author part, we need to throw the date part in here instead
        if (authorPart.length == 0) {
            let datePart = getDatePart(refData);
            datePart = datePart.substring(0, datePart.length - 1);

            titlePart = titlePart + '. ' + datePart;
        }
    }

    if (refData.type == 'song') {
        //Song title [Song; Special version]. On <em>Album name</em>.
        if (songTitle.length > 0) {
            titlePart = songTitle + ' ';

            titlePart = titlePart + '[Song';

            if (performerString.length > 0) {
                titlePart = titlePart + ' recorded by ' + performerString;
            }

            if (groupPerformerString.length > 0) {
                titlePart = titlePart + ' recorded by ' + groupPerformerString;
            }

            if (format.length > 0) {
                titlePart = titlePart + '; ' + format;
            }

            titlePart = titlePart + ']';

            //if we don't have an author part, we need to throw the date part in here instead
            if (authorPart.length == 0) {
                let datePart = getDatePart(refData);
                datePart = datePart.substring(0, datePart.length - 1);

                titlePart = titlePart + '. ' + datePart;
            }

            if (albumTitle.length > 0) {
                titlePart = titlePart + '. On '

                if (!albumTitle.startsWith('[')) {
                    albumTitle = albumTitle.italicize();
                }

                titlePart = titlePart + albumTitle;
            }
        }
        else{
            //this is weird, but if they selected a song, but didnt enter the song and only entered the album
            if (albumTitle.length > 0) {
                titlePart = albumTitle.italicize() + ' ';
            }
    
            titlePart = titlePart + '[Album';
    
            if (groupPerformerString.length > 0) {
                titlePart = titlePart + ' recorded by ' + groupPerformerString;
            }
    
            if (format.length > 0) {
                titlePart = titlePart + '; ' + format;
            }
    
            titlePart = titlePart + ']';

            titlePart = shared.fixTitlePartClosure(titlePart);

            //we'd need to append the date part here, odd odd case
            let datePart = getDatePart(refData);

            titlePart = titlePart + ' ' + datePart;
        }
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = shared.getStringValue(refData.entryTitle);

        if (title.length > 0) {

            if (!title.endsWith('.')) {
                title = title + '.';
            }
    
            authorPart = title;    
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + shared.fixPageDash(formattedDate) + ').';

    return datePart;
}

function getSourcePart(refData){
    // Museum Name, Washington, D.C., United States.
    let sourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let city = shared.getStringValue(refData.city);
    let state = shared.getStringValue(refData.state);
    let country = shared.getStringValue(refData.country);
    let url = shared.getStringValue(refData.url);
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            //remove our period for this one....we'll add it back later if needed
            sourcePart = publisherString.substring(0, publisherString.length -1);    
        }        
    }

    if (city.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + city;
    }

    if (state.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + state;
    }

    if (country.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', '
        }

        sourcePart = sourcePart + country;
    }

    if (sourcePart.length > 0) {
        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' '
        }

        sourcePart = sourcePart + url;
    }

    if (publicationDateOriginal.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' '
        }

        sourcePart = sourcePart + '(Original work published ' + publicationDateOriginal + ')';
    }
    else{
        //f this is a cover and they didn't enter the date....n.d.
        if (refData.kind == 'cover') {
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' '
            }
    
            sourcePart = sourcePart + '(Original work published n.d.)';
        }
    }

    return sourcePart;
}