const shared = require('./Turabian9-Shared.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);

    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    if(refData.type === 'raw') {
        let retrievalDatePart = '';

        let retrievalDate = shared.getStringValue(refData.retrievalDate);
        if (retrievalDate.length > 0) {
            retrievalDatePart = shared.getFullDate(retrievalDate);
            retrievalDatePart = retrievalDatePart.addClosure();
            referenceValue = referenceValue.appendData(retrievalDatePart, ' ', 'Accessed');
        }
	}

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    if (title.length > 0) {
        // if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        } else{
            title = title.replaceAmpersand().toTitleCase().italicize();
               
        }
    }
    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    let version = shared.getStringValue(refData.version);
    titlePart = titlePart.appendData(version);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

   // puting Label here passes test 9 but breaks test 1

    if (shared.isSelfPublished(refData)) {
        publicationPart = publicationPart.appendData('Self-published');
    }
    else{
        let publisherString = shared.getPublisherString(refData.publishers);
        publicationPart = publicationPart.appendData(publisherString);
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (publicationDate.length > 0 && publicationDate != 'n.d.') {
        publicationDate = shared.getFullDate(publicationDate);   
        publicationPart = publicationPart.appendData(publicationDate, ', ');
    }

    publicationPart = publicationPart.addClosure();

    // puting Label here passes test 1 but breaks test 9
    let label = '';
    switch (refData.type) {
        case 'published':
            label = 'Dataset';
            break;
        case 'raw':
            label = 'Unpublished raw data';
            break;
    }
    publicationPart = publicationPart.appendData(label);
    publicationPart = publicationPart.addClosure();


    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);
    if (workTitle.length > 0) {
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
        }
    }
    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.toLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");

    let version = shared.getStringValue(refData.version);
    firstInfo = firstInfo.appendData(version, ", ");
    
    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';

    if (shared.isSelfPublished(refData)) {
        publisherPart = publisherPart.appendData('self-published');
    } else{
        publisherPart = publisherPart.appendData(publisherLocationString);
    }

    if (publicationDate.length > 0 && publicationDate != 'n.d.') {
        publisherPart = publisherPart.appendData(publicationDate, ', ');
        publisherPart = publisherPart.parenthesis();
    }

    firstInfo = firstInfo.appendData(publisherPart, ', ');


    // label
    switch (refData.type) {
        case 'published':
            firstInfo = firstInfo.appendData('dataset', ', ');
            break;
        case 'raw':
            firstInfo = firstInfo.appendData('unpublished raw data', ', ');
            break;
    }


    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        firstInfo= firstInfo.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            // url = shared.formatURL(url).addClosure();
            url = shared.formatURL(url);
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    let retrievalDatePart = '';
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    if (retrievalDate.length > 0) {
        retrievalDatePart = shared.getFullDate(retrievalDate);
        firstInfo = firstInfo.appendData(retrievalDatePart, ', ', 'accessed');
	}

    firstInfo = firstInfo.addClosure();

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);
    if (workTitle.length > 0) {
        // if this is a description, remove the brackets and don't italicize
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
               
        }
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}