const apa7engine = require('./APA7/APA7-Engine.js');
const apa7specialrules = require('./APA7/APA7-SpecialRules.js');

const mla9engine = require('./MLA9/MLA9-Engine.js');
const mla9specialrules = require('./MLA9/MLA9-SpecialRules.js');

const turabian9engine = require('./Turabian9/Turabian9-Engine.js');
const turabian9specialrules = require('./Turabian9/Turabian9-SpecialRules.js');

const _cloneDeep = require('lodash/cloneDeep');

module.exports = {
  getReference,
  getCitation,
  specialRules
};

function getReference(type, refData, mode){

    //swap the ID to the short name from types.json
    let typeShortName = getTypeShortName(type);

    if (typeShortName.length == 0) {
      throw 'Invalid Reference Type';
    }

    let runAPA7 = canExecuteFormat('APA7', type, mode);
    let runMLA9 = canExecuteFormat('MLA9', type, mode);
    let runTurabian9 = canExecuteFormat('Turabian9', type, mode);

    let reference = new Object();
    //pass this off to the reference factories

    if (runAPA7) {
      reference.apa7 = apa7engine.getReference(typeShortName, refData); 
    }

    if (runMLA9) {
      reference.mla9 = mla9engine.getReference(typeShortName, refData); 
    }

    if (runTurabian9) {
      reference.turabian9 = turabian9engine.getReference(typeShortName, refData); 
    }

    return reference;
}

function getCitation(type, refData, citationData, mode){

  //swap the ID to the short name from types.json
  let typeShortName = getTypeShortName(type);

  if (typeShortName.length == 0) {
    throw 'Invalid Reference Type';
  }

  let runAPA7 = canExecuteFormat('APA7', type, mode);
  let runMLA9 = canExecuteFormat('MLA9', type, mode);
  let runTurabian9 = canExecuteFormat('Turabian9', type, mode);

  let citation = new Object();

  if (runAPA7) {
    citation.apa7 = apa7engine.getCitation(typeShortName, refData, citationData);
  }

  if (runMLA9) {
    citation.mla9 = mla9engine.getCitation(typeShortName, refData, citationData);
  }

  if (runTurabian9) {
    citation.turabian9 = turabian9engine.getCitation(typeShortName, refData, citationData); 
  }

  //pass this off to the APA 7 factory
  // let apa7Citation = apa7engine.getCitation(typeShortName, refData, citationData);

  // let citation = {
  //   apa7 : apa7Citation
  // }

  return citation;
}

function getTypeShortName(id){
  //swap the ID to the short name from types.json
  var typeData = require('./types.json');
  let typeShortName = '';

  typeData.refTypes.forEach(thisType => {
    if (thisType.id == id) {
      typeShortName = thisType.shortname;
    }
  });

  return typeShortName;
}

function specialRules(format, references){
	let returnReferences = [];

	let _references = _cloneDeep(references);
	
	// check the format of the reference data
	_references.forEach((referenceObject)=>{
		try {
			// let parsedRefData = JSON.parse(referenceObject.data);

      let parsedRefData;
      if (typeof referenceObject.data === 'string') {
        parsedRefData = JSON.parse((referenceObject.data));
      }
      else{
        parsedRefData = referenceObject.data;
      }

			if(!Array.isArray(parsedRefData.contributors)){
				const contributorPropertyNames = Object.values(parsedRefData.contributors);
				// console.log('parsedRefData needs contributor converted to array');
				// console.log('contributorPropertyNames');
				// console.log(contributorPropertyNames);
				parsedRefData.contributors = contributorPropertyNames;
				referenceObject.data = JSON.stringify(parsedRefData);
			}
		} catch(error){
			console.log(error);
		}
	});//forEach

	switch (format) {
		case 7: //APA 7
			returnReferences = apa7specialrules.specialRules(_references);
			break;
		case 8: //MLA 9
			returnReferences = mla9specialrules.specialRules(_references);
			break;
		case 9: //Turabian 9
			returnReferences = turabian9specialrules.specialRules(_references);
			break;
	}
	return returnReferences;
}

function canExecuteFormat(format, type, executingMode){
  
  //if mode is not passed in, then only generate the available types
  if (typeof executingMode === 'undefined') {
    executingMode = 'available';
  }

  //only run this if our executing mode is 
  let modeLevels = ['unavailable', 'dev', 'test', 'available'];

  let executingLevel = 0;
  let typeLevel = 0;

  type = parseInt(type);

  //get the executing level of our process
  for (i = 0; i < modeLevels.length; i++) {
    if (modeLevels[i] === executingMode) {
      executingLevel = i;
      break;
    }
  }

  //get the level for this type
  var typeData = require('./types.json');
  let typeMode = '';

  typeData.refTypes.forEach(thisType => {
    if (thisType.id === type) {
      typeMode =thisType.formats[format];
    }
  });

  if (typeMode.length > 0) {
    for (i = 0; i < modeLevels.length; i++) {
      if (modeLevels[i] === typeMode) {
        typeLevel = i;
        break;
      }
    }  
  }

  //only tests that are not unavailable
  //and are at this executing level or higher

  let canExecuteFormat = false;

  if (typeLevel > 0) {
    if (typeLevel >= executingLevel) {
      canExecuteFormat = true;
    }
  }

  return canExecuteFormat;
}