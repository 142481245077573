const _forEach = require('lodash/forEach');
const dateHelper = require('../dateHelper');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getOrderByValue,
    getStringValue,
    getPrimaryContributorString,
    getPrimaryContributorSort,
    getPrimaryCustomContributorString,
    getPrimaryContributorCitationString,
    getSecondaryContributorString,
    getFirstSupplementalContributorString,
    getContributorsByType,
    getSecondaryContributorTypeString,
    fixClosure,
    appendDOIURL,
    getYear,
    getMonthYear,
    getFullDate,
    getFullDateRange,
    formatOriginalPublicationDate,
    formatURL,
    getPublisherString,
    getCitations,
    fixEditionString,
    fixFormatString,
    isInteger,
    removeDescriptionFromTitle,
    formatPageNumbers,
    abbreviate,
    ordinalToWord,
    getOrdinalValue,
    getSecondaryReferenceName,
    usePluralLabel
}

function getOrderByValue(reference){

    let referenceValue = reference.displayValue;

    if (referenceValue == '') {
        return '';
    }

    let orderByValue = referenceValue;
    
    if (typeof reference.authorPartSort !== 'undefined') {
        let authorPartSort = reference.authorPartSort;

        orderByValue = orderByValue.replace(reference.authorPart, authorPartSort);
    }

    //before we remove any tags, replace anything within italics and is a number with text
    //<em>22 searches for Sasquatch</em>
    //<em>twenty two searches for Sasquatch</em>

    var words = orderByValue.split(" ");
    let inItalics = false;
    for (var i = 0; i < words.length; i += 1) {
        if (words[i].startsWith('<em>')) {
            inItalics = true;
            words[i] = words[i].replace('<em>', '');
        }

        if (inItalics) {
            words[i] = words[i].replace(':', '');
        }

        if (words[i].endsWith('</em>') || words[i].endsWith('</em>.')) {
            inItalics = false;
            words[i] = words[i].replace('</em>.', '');
            words[i] = words[i].replace('</em>', '');
        }

        if (words[i].endsWith('.')) {
            words[i] = words[i].removeLastCharacter();
        }

        if (words[i].endsWith('."')) {
            words[i] = words[i].removeLastCharacter();
            words[i] = words[i].removeLastCharacter();
        }

        words[i] = convertNumberToText(words[i]);
    }

    orderByValue = words.join(' ');

    //now split back out and remove anything in brackets (like user names)
    words = orderByValue.split(" ");
    let bracketlessArray = [];
    let inBrackets = false;

    for (var i = 0; i < words.length; i += 1) {

        if (i > 0) {
            if (words[i].startsWith('[')) {
                inBrackets = true;
            }    
        }
        
        if (inBrackets === false) {
            bracketlessArray.push(words[i]);
        }

        if (i > 0) {
            if (words[i].endsWith(']') || words[i].endsWith('].')) {
                inBrackets = false;
            }
        }
    }

    orderByValue = bracketlessArray.join(' ');

    orderByValue = removeTags(orderByValue);

    //we need to parse the date from the value and convert it to sortable
    //IE: 2020, March 15 to 2020 03 15
    var array = orderByValue.split(".");

    for (i = 0; i < array.length; i++) {

        if (array[i].startsWith(' (') && array[i].endsWith(')')) {
            array[i] = array[i].split("(").join("");
            array[i] = array[i].split(")").join("");

            //if the array is just a year, then append 00 00
            if (array[i].length == 5) {
                if (isInteger(array[i])) {
                    array[i] = array[i] + ' 00 00';
                }
            }

            //if this section has  a month only, we need to add a 00 for the sort after ut
            if (array[i].endsWith('January') || array[i].endsWith('February') || array[i].endsWith('March') ||
                array[i].endsWith('April') || array[i].endsWith('May') || array[i].endsWith('June') || 
                array[i].endsWith('July') || array[i].endsWith('August') || array[i].endsWith('September') || 
                array[i].endsWith('October') || array[i].endsWith('November') || array[i].endsWith('December')) {
                    array[i] = array[i] + ' 00';
            }

            //replace any months with digits for sorting
            array[i] = array[i].replace('January', '01');
            array[i] = array[i].replace('February', '02');
            array[i] = array[i].replace('March', '03');
            array[i] = array[i].replace('April', '04');
            array[i] = array[i].replace('May', '05');
            array[i] = array[i].replace('June', '06');
            array[i] = array[i].replace('July', '07');
            array[i] = array[i].replace('August', '08');
            array[i] = array[i].replace('September', '09');
            array[i] = array[i].replace('October', '10');
            array[i] = array[i].replace('November', '11');
            array[i] = array[i].replace('December', '12');
        }

    }

    orderByValue = array.join('.');
    orderByValue = orderByValue.split(". ").join(" ");

    orderByValue = orderByValue.trim();

    if (orderByValue.endsWith('.')) {
        orderByValue = orderByValue.substring(0, orderByValue.length - 1);
    }

    if (orderByValue.endsWith(' point')) {
        orderByValue = orderByValue.substring(0, orderByValue.length - 6);
    }

    orderByValue = orderByValue.split("(enacted)").join("");
    orderByValue = orderByValue.split("(Reprinted from").join("");
    orderByValue = orderByValue.split("(Director)").join("");
    orderByValue = orderByValue.split("(Original work published ").join("");
    orderByValue = orderByValue.split("(Retraction published ").join("");
    orderByValue = orderByValue.split("(Host)").join("");
    orderByValue = orderByValue.split("(Hosts)").join("");
    orderByValue = orderByValue.split("(Executive Producer)").join("");
    orderByValue = orderByValue.split("(Executive Producers)").join("");
    orderByValue = orderByValue.split("(Ed.)").join("");
    orderByValue = orderByValue.split("(Eds.)").join("");
    orderByValue = orderByValue.split("Ed. & Trans.").join("");
    orderByValue = orderByValue.split(", Trans.").join(" ");

    orderByValue = orderByValue.split(".,").join("");
    orderByValue = orderByValue.split(",").join("");
    orderByValue = orderByValue.split("[").join("");
    orderByValue = orderByValue.split("]").join("");
    orderByValue = orderByValue.split("(").join(" ");
    orderByValue = orderByValue.split(")").join(" ");
    orderByValue = orderByValue.split(String.fromCharCode(34)).join(""); //double quotes
    orderByValue = orderByValue.split("“").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split(": ").join(" ");
    orderByValue = orderByValue.split(" n.d. ").join(" 0000 ");
    orderByValue = orderByValue.split(" n.d.-").join(" 0000");
    orderByValue = orderByValue.split(" in press ").join(" 9999 ");
    orderByValue = orderByValue.split(" in press-").join(" 9999");
    orderByValue = orderByValue.split("  ").join(" ");
    orderByValue = orderByValue.split(". & ").join(" ");
    orderByValue = orderByValue.split(" & ").join(" ");
    orderByValue = orderByValue.split(" + ").join(" plus ");
    orderByValue = orderByValue.split(" - ").join(" minus ");
    orderByValue = orderByValue.split(" = ").join(" equals ");

    //replace special characters with their equivalent values
    orderByValue = orderByValue.split("é").join("e");
    orderByValue = orderByValue.split("á").join("a");
    orderByValue = orderByValue.split("ú").join("u");
    orderByValue = orderByValue.split("í").join("i");
    orderByValue = orderByValue.split("ó").join("o");
    orderByValue = orderByValue.split("ñ").join("n");
    orderByValue = orderByValue.split("ü").join("u");
    orderByValue = orderByValue.split("ö").join("o");
    orderByValue = orderByValue.split("ä").join("a");
    orderByValue = orderByValue.split("ç").join("c");
    
    orderByValue = orderByValue.toLowerCase();
    orderByValue = orderByValue.trim();

	return orderByValue;
}

function convertNumberToText (num) {

    var th = ['','thousand','million', 'billion','trillion'];
    var dg = ['zero','one','two','three','four', 'five','six','seven','eight','nine'];
    var tn = ['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen', 'seventeen','eighteen','nineteen'];
    var tw = ['twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    num = num.toString();
    num = num.replace(/[\, ]/g,'');
    if (num != parseFloat(num)) return num;
    var x = num.indexOf('.');
    
    if (x == -1)
        x = num.length;
    
    if (x > 15)
        return num;

    var n = num.split(''); 
    var str = '';
    var sk = 0;
    for (var i=0;   i < x;  i++) {
        if ((x-i)%3==2) { 
            if (n[i] == '1') {
                str += tn[Number(n[i+1])] + ' ';
                i++;
                sk=1;
            } else if (n[i]!=0) {
                str += tw[num[i]-2] + ' ';
                sk=1;
            }
        } else if (n[i]!=0) { // 0235
            str += dg[n[i]] +' ';
            if ((x-i)%3==0) str += 'hundred ';
            sk=1;
        }
        if ((x-i)%3==1) {
            if (sk)
                str += th[(x-i-1)/3] + ' ';
            sk=0;
        }
    }

    if (x != num.length) {
        var y = num.length;
        str += 'point ';
        for (var i=x+1; i<y; i++)
            str += dg[n[i]] +' ';
    }
    return str.replace(/\s+/g,' ');
}

function removeTags(str) {
    //tutorialspoint.com/how-to-remove-html-tags-from-a-string-in-javascript
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
}

function getStringValue(input){
    var output = '';

    //helper to check for mull values
    if (typeof input !== 'undefined'){
        output = input.trim();
    }
  
    return output;
}

function getPrimaryContributorString(refData, excludeRole, primaryOverride){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof excludeRole === 'undefined') {
        excludeRole = false;
    }

    if (typeof primaryOverride === 'undefined') {
        primaryOverride = '';
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        //determine the type of the first listed contributor
        //then grab all of that type

        let primaryType = getPrimaryContributorType(refData);

        if (primaryOverride.length > 0) {
            primaryType = primaryOverride;
        }

        let pluralLabel = false;
        let primaryContributors = getContributorsByType(primaryType, contributors);
        
        //1 - Last, First Middle Suffix.
        if (primaryContributors.length == 1) {
            contributorstring = getReferenceName(primaryContributors[0]);
        }    


        //2 - Last, First Middle Suffix., and First Middle Last Suffix.
        if (primaryContributors.length == 2) {

            //if the first entry is a group author a comma is not needed, otherwise it is
            if (primaryType == 'groupAuthor') {
                contributorstring = getReferenceName(primaryContributors[0]) + ' and ' + getSecondaryReferenceName(primaryContributors[1]);
            }
            else{
                contributorstring = getReferenceName(primaryContributors[0]) + ', and ' + getSecondaryReferenceName(primaryContributors[1]);
            }

            pluralLabel = true;
        }   
        
        //3 or more - Last, First Middle Suffix, et al. 
        if (primaryContributors.length > 2) {

            //if the first entry is a group author a comma is not needed, otherwise it is
            if (primaryType == 'groupAuthor') {
                contributorstring = getReferenceName(primaryContributors[0]) + ' et al.';
            }
            else{
                contributorstring = getReferenceName(primaryContributors[0]) + ', et al.';
            }

            pluralLabel = true;
        }

        let contributorLabel = '';

        //if our primary is something other than an author...label it
        if (primaryType != 'author') {
            switch (primaryType) {
                case "editor":
                    
                    if (pluralLabel) {
                        contributorLabel = 'editors';
                    }
                    else{
                        contributorLabel = 'editor';
                    }
                
                    break;
                case "translator":
                    
                    if (pluralLabel) {
                        contributorLabel = 'translators';
                    }
                    else{
                        contributorLabel = 'translator';
                    }

                    break;
                case "illustrator":
                    
                    if (pluralLabel) {
                        contributorLabel = 'illustrators';
                    }
                    else{
                        contributorLabel = 'illustrator';
                    }

                    break;
                case "narrator":

                    if (pluralLabel) {
                        contributorLabel = 'narrators';
                    }
                    else{
                        contributorLabel = 'narrator';
                    }
                    
                    break;
                case "performer":
                case "groupPerformer":
                    if (pluralLabel) {
                        contributorLabel = 'performers';
                    }
                    else{
                        contributorLabel = 'performer';
                    }
                    
                    break;
                case "host":
                    if (pluralLabel) {
                        contributorLabel = 'hosts';
                    }
                    else{
                        contributorLabel = 'host';
                    }
                    
                    break;
            }  
        }

        if (contributorLabel.length > 0 && !excludeRole) {
            contributorstring = contributorstring + ', ' + contributorLabel;
        }
    }

    //if the contributors are the same as the publisher, then MLA will add this in the secondary
    let publisherString = getPublisherString(refData.publishers);

    if ((contributorstring.length > 0 && publisherString == contributorstring) ||
        (contributorstring.length > 0 && publisherString + '.' == contributorstring)) {
            contributorstring = '';
    }

    return fixClosure(contributorstring);
}

function getPrimaryContributorSort(refData, excludeRole){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof excludeRole === 'undefined') {
        excludeRole = false;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        //determine the type of the first listed contributor
        //then grab all of that type

        let primaryType = getPrimaryContributorType(refData);
        let primaryContributors = getContributorsByType(primaryType, contributors);

        let counter = 0;
        _forEach(primaryContributors, (contrib) => {
            let contribString = '';

            // if (counter == 0) {
                contribString = getReferenceName(contrib).replace(',', '');
            // }
            // else{
            //     contribString = getSecondaryReferenceName(contrib);
            // }
            
            contributorstring = contributorstring + ' ';
            contributorstring = contributorstring + contribString;

            counter++;
        });
    }

    //if the contributors are the same as the publisher, then MLA will add this in the secondary
    let publisherString = getPublisherString(refData.publishers);

    if ((contributorstring.length > 0 && publisherString == contributorstring) ||
        (contributorstring.length > 0 && publisherString + '.' == contributorstring)) {
            contributorstring = '';
    }

    return fixClosure(contributorstring);
}

function getPrimaryCustomContributorString(refData){
    //Film and TV have some weird but shared logic so we'll re-use this for them 
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        //primary contribs are only custom types
        let primaryContributors = getContributorsByType('custom', contributors);

        if (primaryContributors.length > 0) {
            switch (primaryContributors.length) {
                case 1:

                    let label = '';
                    label = getCustomContributorLabel(primaryContributors[0]);

                    contributorstring = getReferenceName(primaryContributors[0]);

                    if (label.length > 0) {
                        contributorstring = contributorstring  + ', ' + label;
                    }
                    break;
    
                case 2:
                    
                    let labelOne = '';
                    let labelTwo = '';
                    
                    labelOne = getCustomContributorLabel(primaryContributors[0]);
                    labelTwo = getCustomContributorLabel(primaryContributors[1]);

                    contributorOnestring = getReferenceName(primaryContributors[0]);
                    contributorTwostring = getReferenceName(primaryContributors[1]);

                    if (labelOne.length > 0) {
                        contributorOnestring = contributorOnestring  + ', ' + labelOne;
                    }

                    if (labelTwo.length > 0) {
                        contributorTwostring = contributorTwostring  + ', ' + labelTwo;
                    }

                    contributorstring = contributorOnestring + ', and ' + contributorTwostring;
                    break;

                default:

                    let contribStringArray = [];

                    //throw the contrib strings in an array before we build
                    let counter = 1;
					_forEach(primaryContributors, (contrib) => {
                        let contribString = '';
                        
                        if (counter == 1) {
                            contribString = getReferenceName(contrib);
                        }
                        else{
                            contribString = getSecondaryReferenceName(contrib);
                        }

                        let label = getCustomContributorLabel(contrib);

                        if (label.length > 0) {
                            contribString = contribString + ', ' + label;
                        }

                        contribStringArray.push(contribString);
                        counter++;
                    });

                    counter = 1;
                    contribStringArray.forEach(contrib => {
                        if (counter > 1) {

                            if (counter == contribStringArray.length) {
                                contributorstring = contributorstring + ', and '
                            }
                            else{
                                contributorstring = contributorstring + ', ';
                            }
                        }

                        contributorstring = contributorstring + contrib;
                        counter++;
                    });

                    break;
            }
        }
    }
    return fixClosure(contributorstring);
}

function getCustomContributorLabel(contributor){
    let label = '';

    if (typeof contributor === 'undefined') {
        return label;
    }

    if (typeof contributor.name === 'undefined') {
        return label;
    }

    label = contributor.name.toLowerCase();

    return label;
}

function getPrimaryContributorType(refData){
    let primaryType = '';

    if (typeof refData.contributors === 'undefined') {
        return primaryType;
    }

    let contributors =[];

    _forEach(refData.contributors, (item) => {
        //add any types here that cannot be a primary type
        if (item.type != 'originalEditor') {
            contributors.push(item);
        }
    });

    if (contributors.length > 0) {
        primaryType = getStringValue(contributors[0].type);
    }

    return primaryType;
}

function getFirstSupplementalContributorString(refData, contributorType){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    let contributors = getContributorsByType(contributorType, refData.contributors);

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {
        contributorstring = getSecondaryContributorTypeString(contributors, contributorType);
    }

    return contributorstring;
}

function getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle, noTitleFormatting){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        refData.contributors = [];
    }

    //default this param to false....it's a rare case
    if (typeof noTitleFormatting === 'undefined') {
        noTitleFormatting = false;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (typeof titleProperty === 'undefined') {
        return '';
    }

    if (contributors.length > 0) {

        //determine the type of the first listed contributor
        //then grab all of that type

        let primaryType = getStringValue(contributors[0].type);
        let primaryContributors = getContributorsByType(primaryType, contributors);

        //1 - Last, First Middle Suffix.
        if (primaryContributors.length == 1) {
            contributorstring = getCitationName(primaryContributors[0]);
        }    

        //2 - Last, First Middle Suffix., and First Middle Last Suffix.
        if (primaryContributors.length == 2) {
            contributorstring = getCitationName(primaryContributors[0]) + ' and ' + getCitationName(primaryContributors[1]);
        }   
        
        //3 or more - Last, First Middle Suffix, et al. 
        if (primaryContributors.length > 2) {
            contributorstring = getCitationName(primaryContributors[0]) + ' et al.';
        }
    }

    //check to make sure that the publisher string is not the same as the primary contributor
    //if so, if we have a title property, we need ot use it instead
    let primaryRefString = getPrimaryContributorString(refData);

    if (primaryRefString.length == 0) {

        contributorstring = '';

        if (titleProperty.length > 0) {
            let title = getStringValue(refData[titleProperty]);

            if (title.length > 0) {
                //if this is a description, remove the brackets and don't italicize
                if (title.startsWith('[')) {
                    title = removeDescriptionFromTitle(title);

                    title = shortenGroupName(title);
                }
                else{
                    title = shortenGroupName(title);

                    if (!noTitleFormatting) {
                        title = title.toTitleCase();

                        if (!isSecondaryTitle) {
                            title = title.italicize();   
                        }
                        else{
                            title = title.doubleQuote();
                        }    
                    }
                }

                contributorstring = title.replaceAmpersand();
            }
        }
    }
  
    return contributorstring;
}

function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {
        let primaryType = getStringValue(contributors[0].type);

        let secondaryContributors = [];
        
		_forEach(contributors, (item) => {
            if (item.type != primaryType) {

                //for authors, groupAuthors and userNames need to be filtered as well
                if (primaryType == 'author' || primaryType == 'groupAuthor' || primaryType == 'userName') {
                    if (item.type != 'groupAuthor' && item.type != 'userName' && item.type != 'author') {
                        secondaryContributors.push(item);
                    }
                }
                else {
                    secondaryContributors.push(item);
                }
            }
        });

        if (secondaryContributors.length > 0) {
            //pull in this order: Authors, Editors, Translators, Illustrators, Narrators
            let typeStrings = [];

            let authors = getContributorsByType('author', secondaryContributors);
            let editors = getContributorsByType('editor', secondaryContributors);
            let translators = getContributorsByType('translator', secondaryContributors);
            let illustrators = getContributorsByType('illustrator', secondaryContributors);
            let narrators = getContributorsByType('narrator', secondaryContributors);
            let performers = getContributorsByType('performer', secondaryContributors);
            let groupPerformers = getContributorsByType('groupPerformer', secondaryContributors);
            let executiveProducers = getContributorsByType('executiveProducer', secondaryContributors);
            let editorTranslators = getEditorTranslators(secondaryContributors);
            let testimonies = getContributorsByType('testimony', secondaryContributors);
            let volumeEditors = getContributorsByType('volumeEditor', secondaryContributors);
            
            if (editorTranslators.length > 0) {
                editors = [];
                translators = [];
            }

            if (authors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(authors, 'author'));
            }

            if (editors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(editors, 'editor'));
            }

            if (translators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(translators, 'translator'));
            }

            if (illustrators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(illustrators, 'illustrator'));
            }

            if (narrators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(narrators, 'narrator'));
            }

            if (performers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(performers, 'performer'));
            }

            if (groupPerformers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(groupPerformers, 'groupPerformer'));
            }

            if (editorTranslators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(editorTranslators, 'editorTranslator'));
            }

            if (executiveProducers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(executiveProducers, 'executiveProducer'));
            }

            if (testimonies.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(testimonies, 'testimony'));
            }

            if (volumeEditors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(volumeEditors, 'volumeEditor'));
            }

            //now build out our strings
            if (typeStrings.length > 0) {
                
                let counter = 0;
                typeStrings.forEach(item => {

                    if (counter == 0) {
                        //item = item.charAt(0).toUpperCase() + item.slice(1)
                        
                        contributorstring = item;
                    }
                    else{
                        contributorstring = contributorstring + ', ' + item;
                    }
                    
                    counter++;
                });
            }
        }

    }

    return contributorstring;
}

function getEditorTranslators(contributors){

    let editorTranslatorString = '';
    let editorArray = [];

    if (typeof contributors === 'undefined') {
        return editorArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'editor') {
            editorArray.push(item);
        }
    });

    let translatorArray = [];

    _forEach(contributors, (item) => {
        if (item.type == 'translator') {
            translatorArray.push(item);
        }
    });

    if (editorArray.length > 0 &&
        (editorArray.length == translatorArray.length)) {
        
        let same = true;

        //gonna loop each array and see if we have a match
        editorArray.forEach(thisEditor =>{
            
            var found = false;

            translatorArray.forEach(thisTranslator =>{
                if (thisEditor.firstName == thisTranslator.firstName &&
                    thisEditor.middleName == thisTranslator.middleName &&
                    thisEditor.lastName == thisTranslator.lastName &&
                    thisEditor.prefix == thisTranslator.prefix &&
                    thisEditor.suffix == thisTranslator.suffix) {
                        found = true;
                }    
            });

            if (found == false) {
                same = false;
            }
        });

        if (same) {
            return translatorArray;
        }
    }

    return [];
}

function getSecondaryContributorTypeString(contributors, type){
    let contributorString = '';

    switch (contributors.length) {
        case 0:
            contributorString = ''
            break;
    
        case 1:
            contributorString = getSecondaryReferenceName(contributors[0]);
            break;

        case 2:
            contributorString = getSecondaryReferenceName(contributors[0]) + ' and ' + getSecondaryReferenceName(contributors[1]);
            break;

        default:
            contributorString = getSecondaryReferenceName(contributors[0]) + ' et al.';
            break;
    }

    if (contributorString.length > 0) {
        switch (type) {
            case "author":
            case "reviewAuthor":
            case "reviewArtist":
            case "reviewGroupArtist":
                contributorString = 'by ' + contributorString;
                break;
            case "editor":
            case "originalEditor":
            case "volumeEditor":
                contributorString = 'edited by ' + contributorString;
                break;
            case "translator":
            case "chapterTranslator":
                contributorString = 'translated by ' + contributorString;
                break;
            case "illustrator":
                contributorString = 'illustrated by ' + contributorString;
                break;
            case "narrator":
                contributorString = 'narrated by ' + contributorString;
                break;
            case "testimony":
                contributorString = 'testimony of ' + contributorString;
                break;
            case "performer":
            case "groupPerformer":
                contributorString = 'performance by ' + contributorString;
                break;
            case "director":
                contributorString = 'directed by ' + contributorString;
                break;
            case "writer":
                contributorString = 'written by ' + contributorString;
                break;
            case "writerDirector":
                contributorString = 'directed and written by ' + contributorString;
                break;
            case "editorTranslator":
                contributorString = 'edited and translated by ' + contributorString;
            break;
            case "executiveProducer":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive producer";
                }
                else{
                    contributorString = contributorString + ", executive producers";
                }
            break;
            case "chair":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", chair";
                }
                else{
                    contributorString = contributorString + ", chairs";
                }
            break;
            case "executiveDirector":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive director";
                }
                else{
                    contributorString = contributorString + ", executive directors";
                }
            break;
            case "executiveDirectorProducer":
                
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive director and executive producer";
                }
                else{
                    contributorString = contributorString + ", executive directors and executive producers";
                }

                break;
            case "reviewComposer":

                let name = getStringValue(contributors[0].name);

                if (name.length > 0) {
                    contributorString = contributorString + ", " + name.toLowerCase();;
                }
            break;
        }   
    }

    return contributorString;
}

function getReferenceName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
            // Last, First Middle Suffix.
        	if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = name + ', ' + contributor.firstName;
            }

			if (contributor.middleName && contributor.middleName.trim().length > 0) {
                // name = name + ' ' + contributor.middleName;
                contributor.middleName = contributor.middleName.trim();

                if (name.length > 0 && contributor.firstName.trim().length == 0) {
                    name = name + ",";
                }

                if (contributor.middleName.includes(" ")) {
                    let middleArray = contributor.middleName.split(" ");
                    let middleNameString = "";
                    
                    for (var i = 0; i < middleArray.length; i++) {
                        if (middleNameString.length > 0) {
                            middleNameString = middleNameString + " ";
                        }

                        middleNameString = middleNameString + middleArray[i];
                    }

                    name = name + ' ' + middleNameString;
                }
                else{
                    name = name + ' ' + contributor.middleName;
                }
            }

            if (contributor.suffix && contributor.suffix.trim().length > 0) {

                if (contributor.suffix.toLowerCase() == 'junior') {
                    contributor.suffix = 'Jr.';
                }

                if (contributor.suffix.toLowerCase() == 'senior') {
                    contributor.suffix = 'Sr.';
                }

                // if (contributor.suffix.toLowerCase().startsWith('jr') ||
                //     contributor.suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                // }

                name = name + ' ' + contributor.suffix.trim();
            }

            if (contributor.type == 'chapterTranslator') {
                name = name + ', translator'
            }

            break;
        case 'userName':

            if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = name + ', ' + contributor.firstName;
            }

			if (contributor.middleName && contributor.middleName.trim().length > 0) {
                name = name + ' ' + contributor.middleName;
            }

            if (contributor.suffix && contributor.suffix.trim().length > 0) {

                if (contributor.suffix.toLowerCase() == 'junior') {
                    contributor.suffix = 'Jr.';
                }

                if (contributor.suffix.toLowerCase() == 'senior') {
                    contributor.suffix = 'Sr.';
                }

                if (contributor.suffix.toLowerCase().startsWith('jr') ||
                    contributor.suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                }

                name = name + ' ' + contributor.suffix.trim();
            }

            let userName = '';
            
            if (contributor.name) {
                userName = contributor.name.trim();
            }

            if (userName.length > 0) {
                if (name.length > 0) {
                    name = name + ' [' + userName + ']';
                }
                else {
                    name = userName;
                }
            }
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
            if(contributor.groupName){
				name = contributor.groupName.trim();
            }
            
            let contributorname = getStringValue(contributor.name);
            
            if (contributorname.length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + '[' + contributorname + ']';
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
    }

    return name;
}

function getCitationName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':
        	if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            break;
        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
        
            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            // var suffix = '';
            // if (contributor.suffix && contributor.suffix.trim().length > 0) {
            //     suffix = contributor.suffix.trim();
            // }

            // if (suffix.length > 0) {
            //     name = name + ' ' + suffix;

            //     if (!name.endsWith(".")) {
            //         name = name + '.'
            //     }
            // }

            if (contributor.type == 'reviewEditor') {
                name = name + ', Ed.'
            }

            break;
        case 'userName':

            if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					name = '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}
            
            let userName = '';
            //if we dont have a last name, check user name
            if (contributor.name && name.length == 0) {
                return contributor.name.trim();
            }
            
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
        
            if(contributor.groupName){
				name = contributor.groupName.trim();
                
                //since this is a group author, shorten
                name = shortenGroupName(name);
            }
            
            // let contributorname = getStringValue(contributor.name);
            
            // if (contributorname.length > 0) {
            //     if (name.length > 0) {
            //         name = name + ' ';
            //     }

            //     name = name + '[' + contributorname + ']';
            // }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
    }

    return name;
}

function shortenGroupName(name){
    //if we contain preposition, cut before it
    //otherwise, 3 words...break

    let prepositions = ['aboard'
                            ,'about'
                            ,'above'
                            ,'across'
                            ,'after'
                            ,'against'
                            ,'along'
                            ,'amid'
                            ,'among'
                            ,'anti'
                            ,'around'
                            ,'as'
                            ,'at'
                            ,'before'
                            ,'behind'
                            ,'below'
                            ,'beneath'
                            ,'beside'
                            ,'besides'
                            ,'between'
                            ,'beyond'
                            ,'but'
                            ,'by'
                            ,'concerning'
                            ,'considering'
                            ,'despite'
                            ,'down'
                            ,'during'
                            ,'except'
                            ,'excepting'
                            ,'excluding'
                            ,'following'
                            ,'for'
                            ,'from'
                            ,'in'
                            ,'inside'
                            ,'into'
                            ,'like'
                            ,'minus'
                            ,'near'
                            ,'of'
                            ,'off'
                            ,'on'
                            ,'onto'
                            ,'opposite'
                            ,'outside'
                            ,'over'
                            ,'past'
                            ,'per'
                            ,'plus'
                            ,'regarding'
                            ,'round'
                            ,'save'
                            ,'since'
                            ,'than'
                            ,'through'
                            ,'to'
                            ,'toward'
                            ,'towards'
                            ,'under'
                            ,'underneath'
                            ,'unlike'
                            ,'until'
                            ,'up'
                            ,'upon'
                            ,'versus'
                            ,'via'
                            ,'with'
                            ,'within'
                            ,'without','and', 'or', 'but', '&', 'how', 'do', 'that', 'is'
                        ];

    //if this is a subtitle, only take the first entry before the :
    if (name.includes(':')) {
        let subtitleArray = name.split(':');
        name = subtitleArray[0].trim();
    }

    //same with: Depression (Major Depressive Disorder)
    if (name.includes('(')) {
        let subtitleArray = name.split('(');
        name = subtitleArray[0].trim();
    }
    
    let words = name.split(" ");

    let shortName = '';
    let foundPrep = false;
    let foundNonPrep = false;
    let counter = 0;

    //do not stop once we 
    words.forEach(word => {
        let isPrep = prepositions.includes(word.toLowerCase());
        let isAbbreviated = isAbbreviation(word);

        if (foundPrep) {
            if (!foundNonPrep) {
                //keep adding until we find a non-prep
                shortName = shortName.appendData(word, ' ');
            }
        }
        else{
            //have not found a prep and we are still less than 3, add it
            if (!foundNonPrep) {
                shortName = shortName.appendData(word, ' ');
            }
            else{
                if (counter < 3 && !isPrep) {
                    shortName = shortName.appendData(word, ' ');
                }
            }
        }

        if (isPrep || isAbbreviated) {
            foundPrep = true;
        }
        else{
            foundNonPrep = true;
        }

        counter++;
    });

    return shortName.trim();
}

function isAbbreviation(word){

    //TODO: adding this breaks another test....so leaving this in as hardcoded for now
    //easy switch once we decide
    return false;

    if (typeof word == 'undefined'){
        return false;
    }

    if (word.length == 0) {
        return false;
    }
    
    //logic going to be anything that is single letters followed by a .
    let isAbbreviatied = false;

    word = word.trim();

    if (word.endsWith('.')) {
        
        //now see if we split this, all they all single letters
        let array = word.split('.');

        if (array.length > 0) {
            let allSingles = true;

            array.forEach(item => {
                if (item.length > 1) {
                    allSingles = false;
                }
            });

            isAbbreviatied = allSingles;
        }
    }

    return isAbbreviatied;
}

function getSecondaryReferenceName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
        case 'volumeEditor':
        
            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = contributor.firstName;
            }
        
            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                name = name + ' ' + contributor.middleName;
            }

            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();

                if (suffix.toLowerCase() == 'junior') {
                    suffix = 'Jr.';
                }

                if (suffix.toLowerCase() == 'senior') {
                    suffix = 'Sr.';
                }

                if (suffix.toLowerCase().startsWith('jr') ||
                    suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                }
            }

            if (suffix.length > 0) {
                name = name + ' ' + suffix;

                if (!name.endsWith(".")) {
                    name = name + '.'
                }
            }

            break;
        case 'userName':

            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = contributor.firstName;
            }
        
            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                name = name + ' ' + contributor.middleName;
            }

            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();

                if (suffix.toLowerCase() == 'junior') {
                    suffix = 'Jr.';
                }

                if (suffix.toLowerCase() == 'senior') {
                    suffix = 'Sr.';
                }

                if (suffix.toLowerCase().startsWith('jr') ||
                    suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                }
            }

            if (suffix.length > 0) {
                name = name + ' ' + suffix;

                if (!name.endsWith(".")) {
                    name = name + '.'
                }
            }

            let userName = '';
            
            if (contributor.name) {
                userName = contributor.name.trim();
            }
            
            //see if we have username only
            if (name.length == 0) {
                
                name = userName;
            }
            else{
                if (userName.length > 0) {
                    name = name + ' [' + userName + ']';
                }
            }
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
            if(contributor.groupName){
				name = contributor.groupName.trim();
            }
            
            let contributorname = getStringValue(contributor.name);
            
            if (contributorname.length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + '[' + contributorname + ']';
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
    }

    return name;
}

function fixClosure(part){
    
    if (part.length > 0) {
        if (!part.endsWith('.') && !part.endsWith('?') && !part.endsWith('!')
            && !part.endsWith('.</em>') && !part.endsWith('?</em>') && !part.endsWith('!</em>')) {
            part = part + ".";
        }  

        //if this ends with the a title in double quotes, followed by a period, we need to move the period inside the quote
        if (part.endsWith('".')) {

            let title = part.slice(0, -2);
            let punctuation = '."';

            if (title.endsWith('!') || title.endsWith('?') || title.endsWith('.')) {
                //if this is an ellipsis, it still needs an extra period
                if (!title.endsWith('...')) {
                    punctuation = '"';
                }
            }

            part = title + punctuation;
        }
    }

    return part;
}

function getContributorsByType(type, contributors){
    let typeContribs = [];

    if (typeof contributors == 'undefined'){
        return typeContribs;
    }

    _forEach(contributors, (item) => {
        if (item.type == type) {
            typeContribs.push(item);
        }

        //for authors. we also need to group in Group Authors and Usernames
        if (type == 'author') {
            if (item.type == 'groupAuthor' || item.type == 'userName') {
                typeContribs.push(item);
            }
        }

        if (type == 'groupAuthor') {
            if (item.type == 'author' || item.type == 'userName') {
                typeContribs.push(item);
            }
        }

        if (type == 'userName') {
            if (item.type == 'groupAuthor' || item.type == 'author') {
                typeContribs.push(item);
            }
        }
    });

    return typeContribs;
}

function appendDOIURL(doi){
    let url = '';

    if (doi.length > 0) {
        if (!doi.startsWith('https://doi.org/') && !doi.startsWith('http://doi.org/') ) {

            //in case they enter a URL in the DOI
            if ((doi.startsWith('https:') || doi.startsWith('http:'))) {

                if (doi.startsWith('https://dx.doi.org/') || doi.startsWith('http://dx.doi.org/')) {
                    doi = doi.replace('https://dx.doi.org/', '');
                    doi = doi.replace('http://dx.doi.org/', '');

                    url = 'https://doi.org/' + doi;
                }else{
                    return formatURL(doi);
                }
            }
            else{
                //if they happen to start this with dx.doi.org, pull that out
                
                doi = doi.replace('dx.doi.org/', '');
                doi = doi.replace('doi.org/', '');

                url = 'https://doi.org/' + doi;
            }
        }
        else{
            url = doi;
        }

        url = formatURL(url);
    }

    return url;
}

function formatURL(url){
    let formatURL = '';

    let urlText = url;

    if (!urlText.toLowerCase().includes('doi.org')) {
        urlText = urlText.replace('https://', '');  
        urlText = urlText.replace('http://', '');
    }
   
    if (url.length > 0) {
        formatURL = '<a href="' + url + '">' + urlText + '</a>';
    }

    return formatURL;
}

function getYear(date){
    var year = '';

    if (typeof date == 'undefined'){
        return '';
    }

    date = fixPageDash(date);

    //2018-2020 need to be abbrivated as 2018-20
    if (date.includes('–')) {
        var array = date.split("–");

        if (array.length == 2) {

            //trim both entries
            array[0] = array[0].trim();
            array[1] = array[1].trim();

            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }
    }

    //see if the format is either 2019 or 2/2/2019 or 2018/2020
    if (date.includes("/")) {
        //split into an array and grab the last entry in that array
        var array = date.split("/");

        if (array.length == 2) {
            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }

        year = array[array.length-1]
    }
    else{
        if (date.length > 4) {

            //2018-2020
            if (date.includes("-")) {
                year = date;
            }
            else {
                try {
                    year = dateHelper.format(date, "yyyy");
                }
                catch(err) {
                    console.log('Failed to convert year of ' + date);
                    console.log(err);
                    year = date;
                }
            }
        }
        else {
            //assume this is only a year
            year = date;
        }
    }

    return year;
}

function getMonthYear(date){
    let monthYear = '';

    if (typeof date == 'undefined'){
        return '';
    }

    let year = getYear(date);

    //see if a month is included
    let month = '';

    if (date.length > 4) {
        //possible formats 3/2020 or March 2020

        let dashArray = date.split('/');
        let spaceArray = date.split(' ');

        if (dashArray.length == 2) {
            //for the format of May/June 2019
            if (dashArray[1].includes(" ")) {
                return date;
            } 

            month = getMonthFromDate(date);
        }

        if (spaceArray.length == 2) {
            month = getMonthFromDate(date);
        }
    }

    monthYear = month;

    if (monthYear.length > 0) {
        monthYear = monthYear + ' ';
    }

    monthYear = monthYear + year;

    return monthYear;
}

function getFullDate(date){

    if (typeof date == 'undefined'){
        return '';
    }

    if (date.length == 0) {
        date = '';
    } else {
        if (date.includes("/")) {
            var array = date.split("/");

            let month = '';
            let day = '';
            let year = '';

            switch (array.length) {
                case 3:
                    if (array[0].length > 0) {
                        month = dateHelper.format(array[0] + '/1/2020', 'mmm.');
                    }

                    if (array[1].length > 0) {
                        day = dateHelper.format('1/' + array[1] + '/2020', 'd');
                    }

                    if (array[2].length > 0) {
                        year = dateHelper.format('1/1/' + array[2], 'yyyy');
                    }

                    break;
                case 2:
                        //for the format of May/June 2019
                        if (array[1].includes(" ")) {
                            return date;
                        }  

                        if (array[0].length > 0) {
                            month = dateHelper.format(array[0] + '/1/2020', 'mmm.');
                        }

                        if (array[1].length > 0) {
                            year = dateHelper.format('1/1/' + array[1], 'yyyy');
                        }

                    break;
                case 1: 
                    if (array[0].length > 0) {
                        year = dateHelper.format('1/1/' + array[0], 'yyyy');
                    }

                    break;
            }

            date = '';

            if (day.length > 0) {
                date = day;
            }

            if (month.length > 0) {
                if (day.length > 0) {
                    date = date + ' '
                }

                date = date + month;
            }

            if (year.length > 0) {
                if (date.length > 0) {
                    date = date + ' ';
                }

                date = date + year;
            }
            
        } else {
            date = date;
        }
    }

    if (date == '//') {
        date = '';
    }
        
    //fix the weirdness with short months
    date = date.replace('May.', 'May');
    date = date.replace('Jul.', 'July');
    date = date.replace('Jun.', 'June');
    date = date.replace('Sep.', 'Sept.');

    //if this includes a dash, clean it up
    date = fixPageDash(date);

    //fix any weirdness with present and spacing
    date = date.replace('– present', '–present')

    return date;
}

function getMonthFromDate(date) {
    let month = '';
    
    try {
        month = dateHelper.format(date, "mmm.");
    }
    catch(err) {
        console.log('Failed to convert month of ' + date);
        console.log(err);
    }

    if (month.length > 0) {
        month = month.replace('May.', 'May');
        month = month.replace('Jul.', 'July');
        month = month.replace('Jun.', 'June');
        date = date.replace('Sep.', 'Sept.');
    }

    return month;
}

function getFullDateRange(startDate, endDate){

    //get the format for the start date, and we'll append the range to that
    let fullDate = getFullDate(startDate);

    let startYear = getYearFromDate(startDate);
    let startMonth = getMonthFromDate(startDate);
    let startDay = getDayFromDate(startDate);

    let endYear = getYearFromDate(endDate);
    let endMonth = getMonthFromDate(endDate);
    let endDay = getDayFromDate(endDate);

    let fullDateRange = ''; 

    //same year and month and day
    if (startYear == endYear && startMonth == endMonth && startDay == endDay) {
        fullDateRange = fullDate;
    }

    //same year and month
    if (fullDateRange.length == 0) {
        if (startYear == endYear && startMonth == endMonth) {
            fullDateRange = startDay + '–' + endDay + ' ' + startMonth + ' ' + startYear;
        }
    }
    
    //same year
    if (fullDateRange.length == 0) {
        if (startYear == endYear) {
            fullDateRange = startDay + ' ' + startMonth +  '–' + endDay + ' ' + endMonth + ' ' + startYear;
        }
    }
    
    if (fullDateRange.length == 0) {
        fullDateRange = fullDate + '–' + getFullDate(endDate);
    }

    //fix the weirdness with short months
    fullDateRange = fullDateRange.replace('May.', 'May');
    fullDateRange = fullDateRange.replace('Jul.', 'July');
    fullDateRange = fullDateRange.replace('Jun.', 'June');
    fullDateRange = fullDateRange.replace('Sep.', 'Sept.');

    return fullDateRange;
}

function getYearFromDate(date){
    let year = '';
    var array = date.split("/");

    switch (array.length) {
        case 3:
            if (array[2].length > 0) {
                year = dateHelper.format('1/1/' + array[2], 'yyyy');
            }
            break;
        case 2:
                if (array[1].length > 0) {
                    year = dateHelper.format('1/1/' + array[1], 'yyyy');
                }
            break;
        case 1: 
            if (array[0].length > 0) {
                year = dateHelper.format('1/1/' + array[0], 'yyyy');
            }
            break;
    }

    return year;
}

function getDayFromDate(date){
    var array = date.split("/");
    let day = '';

    switch (array.length) {
        case 3:

            if (array[1].length > 0) {
                day = dateHelper.format('1/' + array[1] + '/2020', 'd');
            }
            break;
    }

    return day;
}

function formatOriginalPublicationDate(date) {
    // We have to convert 'ca.' or 'c.' to 'circa' in MLA. 
    //Also need to remove periods for B.C.E., C.E., A.D., B.C.if used in originalPublicationDate.

    date = date.replace('ca. ', 'circa ');
    date = date.replace('c. ', 'circa ');
    date = date.replace(' B.C.E.', ' BCE');
    date = date.replace(' C.E.', ' CE');
    date = date.replace(' A.D.', ' AD');
    date = date.replace(' B.C.', ' BC');

    return date;
}

function getPublisherString(publishers, type, italicize){
    let publisherString = '';

    if (typeof publishers == 'undefined'){
        return '';
    }
      
    //if not passed in, assume they are not
    if (typeof italicize == 'undefined'){
        italicize = false;
    }

    //if a type is not passed in default to reference
    if (typeof type == 'undefined'){
        type = 'reference';
    }
    
    //filter these for type: reference
    let refPublishers = [];

    publishers.forEach(item => {
        if (item.type == type && item.name.length > 0) {
            refPublishers.push(item);
        }
    });

    publishers = removeEmptyPublishers(publishers);

    //1 Publisher
      if (refPublishers.length == 1) {
        
          let name = refPublishers[0].name;

          if (italicize) {
              name = name.italicize();
          }
          
        return name;
    }

    //More than 1
    if (refPublishers.length > 1) {
    
        var counter = 0;
        refPublishers.forEach(item => {
            if (counter > 0) {
                publisherString = publisherString + ' / ';
            }

            let name = item.name;
            
            if (italicize) {
              name = name.italicize();
            }

            publisherString = publisherString + name;
            
            counter++;
        });

        // if (!publisherString.endsWith(".")) {
        //     publisherString = publisherString + '.'
        // }
    }
    
    publisherString = publisherString.replaceAmpersand();

    return publisherString;
}

function getCitations(citationData, primaryInfo, citationTitle, citationDate, shortenPageNumber){

    if (typeof citationTitle === 'undefined') {
        citationTitle = '';
    }

    if (typeof citationDate === 'undefined') {
        citationDate = '';
    }

    if (typeof shortenPageNumber === 'undefined') {
        shortenPageNumber = false;
    }

    let firstCitation = ''
    let subsequentCitation = ''; //don't think this is ever used right now, but saving it in here just in case we want to add to it later
    let location = getCitationLocationString(citationData, shortenPageNumber);

    //check to see if any data needs clearing out
    if (citationData.namePart == 'false') {
        primaryInfo = '';
    }
    
    if (primaryInfo.length > 0) {
        firstCitation = primaryInfo;
    }

    if (location.length > 0) {

        if (firstCitation.length > 0) {
            if (citationData.type != 'page'
                && citationData.type != 'time'
                && citationData.type != 'actSceneLine') {

                    //check our punctionation
                    if (firstCitation.endsWith('"')) {
                        firstCitation = firstCitation.slice(0, -1) + '," ';
                    }
                    else{
                        //if this has a punctuation, then a comma is not needed
                        if (firstCitation.endsWith('!') || firstCitation.endsWith('!</em>') ||
                            firstCitation.endsWith('?') || firstCitation.endsWith('?</em>')
                        ){
                            firstCitation = firstCitation + ' ';
                        }
                        else{
                            //nothing special, add the comma
                            firstCitation = firstCitation + ', ';
                        }
                    }                
            }
            else{
                if (!location.startsWith(',')) {
                    firstCitation = firstCitation + ' ';
                }
            }
        }

        firstCitation = firstCitation + location;
    }


    //trim these back
    firstCitation = firstCitation.trim();

    //make sure neither citation ends with a , or ,"
    if (firstCitation.endsWith(',')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -1);
    }

    if (firstCitation.endsWith(',"')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -2) + '"';
    }

    let titleCitation = '';

    if (citationTitle.length > 0) {
        titleCitation = primaryInfo + ", " + citationTitle.shortenName().toTitleCase().italicize();

        if (location.length > 0) {
            titleCitation = titleCitation + " " + location;
        }
    }

    let dateCitation = '';

    if (citationDate.length > 0) {
        dateCitation = primaryInfo + ", " + citationDate;

        if (location.length > 0) {
            dateCitation = dateCitation + ", " + location;
        }
    }

    let citation = {
        first : firstCitation,
        subsequent : subsequentCitation,
        withTitle : titleCitation,
        withDate : dateCitation
    }

    return citation;
}

function getCitationLocationString(citationData, shortenPageNumber){
    var location = '';

    if (typeof shortenPageNumber === 'undefined') {
        shortenPageNumber = false;
    }

    let citationVolume = getCitationVolume(citationData);
    if (citationData.value == '' && citationData.type != 'actSceneLine' && citationVolume.length == 0) {
        return '';
    }

    switch (citationData.type) {
        case 'notdirect':
            location = '';
            break;
        case 'page':
            location = formatPageNumbers(citationData.value, false, true, shortenPageNumber);

            if (citationVolume.length > 0) {
                if (citationData.value.length == 0) {
                    location = ', vol. ' + citationVolume;
                }else{
                    location = ', vol. ' + citationVolume + ', ' + location;
                }
            }

            break;
        case 'paragraph':
            var label = 'par.';

            if (usePluralLabel(citationData.value)) {
                label = 'pars.'
            }

            location = label + ' ' + citationData.value;
            break;
        case 'chapter':
            location = 'ch. ' + citationData.value;
            break;
        case 'track':
            location = 'tr. ' + citationData.value;
            break;
        case 'figure':
                var label = 'fig.';

                if (usePluralLabel(citationData.value)) {
                    label = 'figs.'
                }

                location = label + ' ' + citationData.value;
            break;
        case 'table':
            var label = 'tab.';

            if (usePluralLabel(citationData.value)) {
                label = 'tabs.'
            }

            location = label + ' ' + citationData.value;
            break;
        case 'time':

            let value = '';
            //we need to reformat strings like 1:14:30 as 01:14:30
            let timeArray = [];

            if (citationData.value.includes(':')) {
                timeArray = citationData.value.split(":");
            }

            if (citationData.value.includes('/')) {
                timeArray = citationData.value.split("/");
            }


            if (timeArray.length > 0) {
                let newTimeFormat = '';

                if (timeArray.length == 2) {
                    newTimeFormat = '00';
                }

                timeArray.forEach(item => {
                    
                    let newItem = item;

                    switch (item.length) {
                        case 0:
                            newItem = '00';
                            break;
                        case 1:
                            newItem = '0' + item;
                            break;
                    }

                    if (newTimeFormat.length > 0) {
                        newTimeFormat = newTimeFormat + ':';
                    }

                    newTimeFormat = newTimeFormat + newItem;
                });

                value = newTimeFormat;
            }
            else{
                value = citationData.value;
            }
            
            location = value;
            break;
        case 'section':
            var label = 'sec.';

                if (usePluralLabel(citationData.value)) {
                    label = 'secs.'
                }

                location = label + ' ' + citationData.value;
            break;
        case 'actSceneLine':
            location = fixPageDash(citationData.value);
            location = location.split("/").join(".");

            if (location.endsWith('.')) {
                location = location.substring(0, location.length - 1);
            }

            break;
        case 'other':
        case 'religious':

            location = citationData.label;

            if (citationData.label.length > 0) {
                location = location + ' ';
            }

            //: are replaced with . in MLA
            let citationValue = citationData.value;

            citationValue = citationValue.replace(':', '.');
            
            location = location + citationValue;
        break;
    }

    return location;
}

function fixPageDash(pages){
    
    let cleanPages = ''

    cleanPages = pages.replace('-', '–');
    cleanPages = cleanPages.replace(' - ', '–');
    cleanPages = cleanPages.replace(' – ', '–');

    return cleanPages;
}

function usePluralLabel(value){
    let use = false;

    if (value.includes('-') || 
        value.includes(':') ||
        value.includes('–') ||
        value.includes(',')) {
        
            use = true;
    }

    return use;
}

function fixEditionString(edition){

    if (edition.length > 0) {

        //if this is an integer, make it ordinal
        if (isInteger(edition)) {
            edition = getOrdinalValue(edition);
        }

        // //replace edition with ed.
        // if (edition.includes('edition')) {
        //     edition = edition.replace('edition', 'ed.');
        // }   

        // //replace edition with Ed.
        // if (edition.includes('Edition')) {
        //     edition = edition.replace('Edition', 'ed.');
        // }  

        //add ed if needed
        if (!edition.includes('ed.') && !edition.toLowerCase().includes('edition')) {
            edition = edition + ' ed.'
        }
        
        //if the string includes ed., is not at the end, and does not follow with a comma, add it
        if (edition.includes('ed.') &&
            !edition.endsWith('ed.') &&
            !edition.includes('ed.,')) {
            edition = edition.replace('ed.', 'ed.,');
        }
    }
    
    return edition;
}

function fixFormatString(format){

    format = fixEditionString(format);
    
    return format;
}

function removeDescriptionFromTitle(title)
{
    title = title.trim();

    if (title.startsWith('[')) {
        title = title.slice(1);

        //remove the ending bracket too
        if (title.endsWith(']')) {
            title = title.slice(0, -1);
        }
    }

    return title;
}

function isInteger(value) {
return !isNaN(value) && 
        parseInt(Number(value)) == value && 
        !isNaN(parseInt(value, 10));
}

function getOrdinalValue(value){
    //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    if (isInteger(value)) {
        var j = value % 10,
        k = value % 100;
        if (j == 1 && k != 11) {
            return value + "st";
        }
        if (j == 2 && k != 12) {
            return value + "nd";
        }
        if (j == 3 && k != 13) {
            return value + "rd";
        }
        return value + "th";
    }

    return value;
}

function formatPageNumbers(pages, addLabel, isCitation, shortenPageNumber){

    if (typeof addLabel === 'undefined') {
        addLabel = true;
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof shortenPageNumber === 'undefined') {
        shortenPageNumber = false;
    }
    
    pages = fixPageDash(pages);

    //for MLA, multiple pages are shortened and only add a + at the end
    //so "13–21, 27" becomes "13–21+""
    //this is only for references, citations do not break non-consecutive page numbers
    if (pages.includes(',') && (!isCitation || shortenPageNumber)) {
        let array = pages.split(",");

        pages = array[0] + "+";
    }

    //MLA also shortens page numbers in this way
    //1122–1128 becomes 1122–28
    //dupicate numbers have to have at least 2
    if (pages.includes('–')) {

        let hasPlus = false;
        if (pages.endsWith('+')) {
            hasPlus = true;
            pages = pages.slice(0, -1)
        }

        let array = pages.split("–");

        if (isInteger(array[0]) && isInteger(array[1])) {
            if (array[0].length == array[1].length) {
                if (array[0].length > 2) {
                    
                    //loop each number until we find the one that doesnt match
                    let match = -1;
                    for (var i = 0; i < array[0].length; i++) {
                        
                        if (array[0][i] != array[1][i]) {
    
                            if (match == -1) {
                                match = i;
                            }
                        }
                    }
    
                    if (match != -1) {
                        //if it's only the last number, we need to add one to it
                        if ((match + 1) == array[1].length) {
                            match = match - 1;
                        }
    
                        pages = array[0] + "–" + array[1].substring(match);
                    }
                }
            }
        }

        //re-add our plus
        if (hasPlus) {
            pages = pages + '+';
        }
    }

    if (pages.length > 0 && addLabel) {
        var label = 'p.';
        if (pages.includes('-') || pages.includes('–') || pages.includes(':') || pages.includes('+')) {
            label = 'pp.';
        }

        pages = label + ' ' + pages;
    }

    return pages;
}

function abbreviate(text) {
    
    //pull out any odd characters
    let trimmed = text.trim();

    if (trimmed == 0) {
        return trimmed;
    }

    var abbreviations = [
        // ['issue', 'no.'],
        ['appendix', 'app.'],
        ['chapter', 'ch.'],
        ['edition', 'ed.'],
        ['figure', 'fig.'],
        ['note', 'n'],
        ['notes', 'nn'],
        ['number', 'no.'],
        ['section', 'sec.'],
        ['supplement', 'supp.'],
        ['version', 'vers.'],
        ['volume', 'vol.'],
        ['publication', 'pub.']
    ];

    //parse every word in the text and see if it is a word we need to abbreviate
    let sentence = trimmed.split(" ");
    let newSentence = [];

    for (var i = 0; i < sentence.length; i++) {
        let thisWord = sentence[i];

        //check thisWord against every abbreviation
        let thisAbbreviation = '';
        for (let index = 0; index < abbreviations.length; index++) {
            let thisWordToAbbr = abbreviations[index][0];
            
            if (thisWord.toLowerCase() == thisWordToAbbr.toLowerCase()) {
                thisAbbreviation = abbreviations[index][1];
            }
        }

        //if we abbreviated, use it instead
        if (thisAbbreviation) {
            newSentence.push(thisAbbreviation);
        }
        else {
            newSentence.push(thisWord);
        }
    }

    return newSentence.join(" ");
}

function ordinalToWord(ordinal){
    //havent came up with a good way to do this yet
    //so for now, we'll just do a comparison in this array
    let word = '';

    var conversions = [
        ['1st', 'First'],
        ['1rst', 'First'],
        ['2nd', 'Second'],
        ['2d', 'Second'],
        ['3rd', 'Third'],
        ['3d', 'Third'],
        ['4th', 'Fourth'],
        ['5th', 'Fifth'],
        ['6th', 'Sixth'],
        ['7th', 'Seventh'],
        ['8th', 'Eighth'],
        ['9th', 'Ninth'],
        ['10th', 'Tenth'],
        ['11th', 'Eleventh'],
        ['12th', 'Twelfth'],
        ['13th', 'Thirteenth'],
        ['14th', 'Fourteenth'],
        ['15th', 'Fifteenth'],
        ['16th', 'Sixteenth'],
        ['17th', 'Seventeenth'],
        ['18th', 'Eighteenth'],
        ['19th', 'Nineteenth'],
        ['20th', 'Twentieth'],
    ];

    for (let index = 0; index < conversions.length; index++) {
        let thisConversion = conversions[index][0];

        if (thisConversion == ordinal.toLowerCase()) {
            word = conversions[index][1];
        }
    }    

    if (word == '') {
        //if we havent converted yet, just leave it as is
        word = ordinal;
    }

    return word;
}

function removeEmptyContributors(contributors){
    
    //remove any empty contributors from the array
    if (typeof contributors === 'undefined') {
        return [];
    }

    let cleanContributors = [];

    if (contributors.length > 0) {
        _forEach(contributors, (contributor) => {
        
            if (typeof contributor.type !== 'undefined') {

                switch (contributor.type) {
                    case 'author':
                    case 'chapterAuthor':
                    case 'custom':
                    case 'executiveProducer':
                    case 'executiveDirector':
                    case 'executiveDirectorProducer':
                    case 'director':
                    case 'writer':
                    case 'writerDirector':
                    case 'host':
                    case 'interview':
                       
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'editor':
                    case 'chapterTranslator':
                    case 'translator':
                    case 'narrator':
                    case 'illustrator':
                    case 'originalEditor':
                    case 'performer':
                    case 'chair':
                    case 'reviewAuthor':
                    case 'reviewDirector':
                    case 'reviewWriter':
                    case 'reviewWriterDirector':
                    case 'reviewArtist':
                    case 'reviewComposer':
                    case 'reviewEditor':
                    case 'volumeEditor':

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }
            
                        break;
                    case 'userName':
            
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0 ||
                            contributor.name.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupAuthor':
                    case 'reviewGroupArtist':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupPerformer':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if (typeof contributor.abbreviation === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0 ||
                            contributor.abbreviation.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'testimony':

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0){
                                cleanContributors.push(contributor);
                        }
                       
                        break;
                }
            }
        
        });
    }

    return cleanContributors;
}

function getCitationVolume(citationData) {
    let volume = getStringValue(citationData.volume);

    return volume;
}

function removeEmptyPublishers(publishers) {
    //remove any empty publishers from the array
    if (typeof publishers === 'undefined') {
        return [];
    }

    let cleanPublishers = [];

    if (publishers.length > 0) {

        _forEach(publishers, (publisher) => {
            let foundSomething = false;
            
            if (typeof publisher.type !== 'undefined') {
                if (publisher.type.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.city !== 'undefined') {
                if (publisher.city.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.name !== 'undefined') {
                if (publisher.name.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.state !== 'undefined') {
                if (publisher.state.length > 0) {
                    foundSomething = true;
                }
            }

            if (foundSomething) {
                cleanPublishers.push(publisher);
            }

        });

    }

    return cleanPublishers;
}