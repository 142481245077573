const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    //Executive Order [orderNumber]. \”[title].\”
    titlePart = titlePart.appendData('Executive Order');

    let orderNumber = shared.getStringValue(refData.orderNumber);
    titlePart = titlePart.appendData(orderNumber);
    titlePart = titlePart.addClosure();

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase().doubleQuote();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';
    // <em>Code of Federal Regulations</em>, title 3 ([publicationDate] comp.): [pageNumber].
    publicationPart = publicationPart.appendData("<em>Code of Federal Regulations</em>, title 3");

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getYear(publicationDate); 
    
    if (publicationDate.length > 0) {
        publicationPart = publicationPart.appendData(publicationDate, ' (', '', 'comp.)');
    }
    
    let pageNumber = shared.getStringValue(refData.pageNumber);
    publicationPart = publicationPart.appendData(pageNumber, ': ');

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // doi/url
    let url = shared.getStringValue(refData.url);
    
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = 'Executive Order';

    let orderNumber = shared.getStringValue(refData.orderNumber);

    if (orderNumber.length > 0) {
        citationTitle = citationTitle + ' ' + orderNumber;
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'orderNumber';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    firstInfo = firstInfo.appendData('Executive Order', ", ");

    let orderNumber = shared.getStringValue(refData.orderNumber);
    firstInfo = firstInfo.appendData(orderNumber);

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase().doubleQuote();    
    }

    firstInfo = firstInfo.appendData(title, ', ');
    firstInfo = firstInfo.appendData("<em>Code of Federal Regulations</em>, title 3", ", ");

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getYear(publicationDate); 
    
    if (publicationDate.length > 0) {
        firstInfo = firstInfo.appendData(publicationDate, ' (', '', 'comp.)');
    }

    let pageNumber = shared.getStringValue(refData.pageNumber);
    let citationLocation = shared.getCitationLocationString(citationData, false);

    if (citationLocation.length > 0) {
        firstInfo = firstInfo.appendData(citationLocation, ', ');
    }
    else{
        if (pageNumber.length > 0) {
            firstInfo = firstInfo.appendData(pageNumber, ', ');
        }
    }
    
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    subsequentInfo = subsequentInfo.appendData('Executive Order', ", ");
    subsequentInfo = subsequentInfo.appendData(orderNumber);

    if (citationLocation.length > 0) {
        subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');
    }
    else{
        if (pageNumber.length > 0) {
            subsequentInfo = subsequentInfo.appendData(pageNumber, ', ');
        }
    }

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function getAuthorPart(refData){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    let contributors = refData.contributors;
    contributors = shared.removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        let primaryContributors = shared.getContributorsByType('author', contributors);

        //this is for president, so can only be one
        //just grab the first one can move on

        if (primaryContributors.length > 0) {
            
            contributor = primaryContributors[0];

            if(contributor.firstName){
                contributorstring = contributor.firstName.trim();
            }

            if (contributor.middleName) {
                if (contributor.middleName.trim().length > 0) {
                    if (contributor.middleName.includes(" ")) {
                        let middleArray = contributor.middleName.split(" ");
                        let middleNameString = "";
                        
                        for (var i = 0; i < middleArray.length; i++) {
                            if (middleNameString.length > 0) {
                                middleNameString = middleNameString + " ";
                            }
    
                            middleNameString = middleNameString + middleArray[i].getInitial();
                        }
    
                        contributorstring = contributorstring + ' ' + middleNameString;
                    }
                    else{
                        contributorstring = contributorstring + ' ' + contributor.middleName.getInitial();
                    }
                }
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (contributorstring.length > 0) {
                        contributorstring = contributorstring + ' ';
                    }
    
                    contributorstring = contributorstring + contributor.lastName.trim();    
                }
            }
        } 

    }

    return contributorstring.addClosure();
}