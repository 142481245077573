const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let titlePart = getTitlePart(refData);
    let authorPart = shared.getAuthorPart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(titlePart);
    // referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.bookTitle);

    if(workTitle == ''){
        // if bookTitle is blank then use the translated title 
        workTitle = shared.getStringValue(refData.translatedTitle);
    }

    if (workTitle.length > 0) {
        // if this is a description, remove the brackets and don't italicize
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
               
        }
    }
    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();


    let allContribs = getSecondaryContributorString(refData.contributors);
    titlePart = titlePart.appendData(allContribs);
    titlePart = titlePart.addClosure();

    let version = shared.getStringValue(refData.version);
    version = shared.replaceWithAbbreviations(version);
    titlePart = titlePart.appendData(version);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false); 

    if (publisherString.length == 0) {
        publisherString = 'N.p.';
    }

    publicationPart = publicationPart.appendData(publisherString);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    if(publicationDate === ''){
        // console.log('publicationDate blank');
        publicationPart = publicationPart.appendData('n.d.', ', ');
    } else {
        publicationDate = shared.getFullDate(publicationDate);
        publicationDate = formatOriginalPublicationDate(publicationDate);
        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.addClosure();
    }

    return publicationPart;
}


function getLocationPart(refData){
    let locationPart = '';

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.bookTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    // let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    // let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    // firstInfo = firstInfo.appendData(authorPart);

    // let workTitle = shared.getStringValue(refData.bookTitle);
    // workTitle = workTitle.toTitleCase().italicize();
    // firstInfo = firstInfo.appendData(workTitle, ", ");

    // let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    // firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    // let seriesTitle = shared.getStringValue(refData.seriesTitle);
    // workTitle = workTitle.toTitleCase();
    // firstInfo = firstInfo.appendData(seriesTitle, ", ");

    // let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    // let publicationDate = shared.getFullDate(refData.publicationDate);
    // let publisherPart = '';

    //     publisherPart = publisherPart.appendData(publisherLocationString);


    // publisherPart = publisherPart.appendData(publicationDate, ', ');

    // publisherPart = publisherPart.parenthesis();
    // firstInfo = firstInfo.appendData(publisherPart, ', ');

    // let format = shared.getStringValue(refData.format);
    // format = shared.fixFormatStringForCitation(format);
    // firstInfo = firstInfo.appendData(format, ', ');

    // get version now to use later
    let version = shared.getStringValue(refData.version);
    version = shared.replaceWithAbbreviations(version);

    let citationLocation = shared.getCitationLocationString(citationData, false);
    if(citationLocation === ''){
        if(version === ''){
            // If there isn't citation data or version, then we list the title
            let workTitle = shared.getStringValue(refData.bookTitle);
            if(workTitle == ''){
                // if bookTitle is blank then use the translated title 
                workTitle = shared.getStringValue(refData.translatedTitle);
            }
            
            if (workTitle.length > 0) {
                if (workTitle.startsWith('[')) {
                    workTitle = shared.removeDescriptionFromTitle(workTitle);
                    firstInfo = firstInfo.appendData(workTitle);
                    subsequentInfo = subsequentInfo.appendData(workTitle.shortenName(), ', ');
                } else{
                    workTitle = workTitle.replaceAmpersand().toTitleCase();
                    firstInfo = firstInfo.appendData(workTitle.italicize());
                    subsequentInfo = subsequentInfo.appendData(workTitle.shortenName().italicize(), ', ');
                }
            }
            

        } else {
            // use version
            firstInfo = firstInfo.appendData(version);
            firstInfo = firstInfo.addClosure();            
        }
        
    } else {
        citationLocation = citationLocation.upperFirstLetter();

        firstInfo = firstInfo.appendData(citationLocation, ', ');
        version= version.parenthesis();
        firstInfo = firstInfo.appendData(version);
        firstInfo = firstInfo.addClosure();

        subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');
    }
    
    // subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    // subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');
    
    // let version = shared.getStringValue(refData.version);
    // version = shared.replaceWithAbbreviations(version).parenthesis();
    subsequentInfo = subsequentInfo.appendData(version);
    subsequentInfo = subsequentInfo.addClosure();

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){
    let contributorString = '';

    if (typeof contributors === 'undefined') {
        return contributorString;
    }
    
    if (contributors.length > 0) {
        // for these contributors, get them all - which is editors and tranlators
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {
            let typeStrings = [];

            let editors = shared.getContributorsByType('editor', secondaryContributors);
            let translators = shared.getContributorsByType('translator', secondaryContributors);
            
            if (editors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(editors, 'editor'));
            }            
            if (translators.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(translators, 'translator'));
            }

            typeStrings.forEach((typeString) => {
                if (contributorString.length > 0) {
                    contributorString = contributorString + ". ";   // this adds a closure . inbetween the two types of contributors
                }

                contributorString = contributorString + typeString;
            });

        }//e:if:secondaryContributors.length

    }//e:if:contributors.length

    return contributorString;


}//e:getSecondaryContributorString


// this might be Shared later (modified from MLA9-Shared)
function formatOriginalPublicationDate(date) {
    // Also need to remove periods for B.C.E., C.E., A.D., B.C.if used in originalPublicationDate.
    date = date.replace(' B.C.E.', ' BCE');
    date = date.replace(' C.E.', ' CE');
    date = date.replace(' A.D.', ' AD');
    date = date.replace(' B.C.', ' BC');

    return date;
}
