const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authors = shared.getContributorsByType('author', refData.contributors);
    let authorPart = "";

    if (authors.length > 0) {
        authorPart = shared.getAuthorPart(refData);
    }

    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let workTitle = shared.getStringValue(refData.reviewedTitle);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase();
        if(kind === 'song' || kind === 'tvEpisode') {
            workTitle = workTitle.doubleQuote();
        } else {
            workTitle = workTitle.replaceAll('"', '');
            workTitle = workTitle.italicize();
        }
    }
    
    titlePart = titlePart.appendData(workTitle, '', 'Review of');
    
    // let reviewArtists = shared.getContributorsByType('reviewArtist', refData.contributors);
    // let reviewAuthors = shared.getContributorsByType('reviewAuthor', refData.contributors);
    // let reviewComposers = shared.getContributorsByType('reviewComposer', refData.contributors);
    // let reviewDirectors = shared.getContributorsByType('reviewDirector', refData.contributors);
    // let reviewWriters = shared.getContributorsByType('reviewWriter', refData.contributors);
    // let reviewWriterDirectors = shared.getContributorsByType('reviewWriterDirector', refData.contributors);
    
    // let typeStrings = [];
    // if (reviewArtists.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewArtists, 'reviewArtist'));
    // }
    // if (reviewAuthors.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewAuthors, 'reviewAuthor'));
    // }
    // if (reviewComposers.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewComposers, 'reviewComposer'));
    // }
    // if (reviewDirectors.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewDirectors, 'reviewDirector'));
    // }
    // if (reviewWriters.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewWriters, 'reviewWriter'));
    // }
    // if (reviewWriterDirectors.length > 0) {
    //     typeStrings.push(shared.getSecondaryContributorTypeString(reviewWriters, 'reviewWriterDirector'));
    // }

    // let contributorString = '';
    // typeStrings.forEach((typeString) => {
    //     if (contributorString.length > 0) {
    //         contributorString = contributorString + ". ";   // this adds a closure . inbetween the two types of contributors
    //     }
    //     contributorString = contributorString + typeString;
    // });
    
    let contributorString = getSecondaryContributorString(refData.contributors);

    titlePart = titlePart.appendData(contributorString, ', ');
    titlePart = titlePart.addClosure();

    switch(type){
        case 'blog':
            let blog = shared.getStringValue(refData.blog);

            if (blog.startsWith('[')) {
                blog = shared.removeDescriptionFromTitle(blog);
            } else{
                blog = blog.replaceAmpersand().toTitleCase().italicize();
            }
    
            titlePart = titlePart.appendData(blog, ' ', '', '(blog)');
            titlePart = titlePart.addClosure();
            break;

        case 'periodical':
            let periodical = shared.getStringValue(refData.periodical);

            if (periodical.startsWith('[')) {
                periodical = shared.removeDescriptionFromTitle(periodical);
            } else{
                periodical = periodical.replaceAmpersand().toTitleCase().italicize();
            }

            titlePart = titlePart.appendData(periodical);

            let volume = shared.getStringValue(refData.volume);
            titlePart = titlePart.appendData(volume);

            let issue = shared.getStringValue(refData.issue);
            titlePart = titlePart.appendData(issue, ', ', 'no.');
            break;

        case 'website':
            let website = shared.getStringValue(refData.website);

            if (website.startsWith('[')) {
                website = shared.removeDescriptionFromTitle(website);
            }

            titlePart = titlePart.appendData(website);
            titlePart = titlePart.addClosure();
            break;
    }

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let type = shared.getStringValue(refData.type);

    let publicationDate = shared.getStringValue(refData.publicationDate);

    switch(type){
        case 'periodical':
            publicationDate = shared.getYear(publicationDate); 
            
            if (publicationDate.length > 0) {
                publicationDate = publicationDate.parenthesis();
                publicationPart = publicationPart.appendData(publicationDate);
            }

            let pageNumbers = shared.getStringValue(refData.pageNumbers);
            pageNumbers = shared.formatPageNumbers(pageNumbers, false);
            publicationPart = publicationPart.appendData(pageNumbers, ': ').addClosure();

            break;
        case 'blog':
        case 'website':
            publicationDate = shared.getFullDate(publicationDate); 

            if(publicationDate === 'n.d.'){
                publicationDate = publicationDate.upperFirstLetter();
            }
            
            publicationPart = publicationPart.appendData(publicationDate);
            publicationPart = publicationPart.addClosure();
            break;
    }

    
    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let authors = shared.getContributorsByType('author', refData.contributors);
    let authorPart = "";

    if (authors.length > 0) {
        authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    }

    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.reviewedTitle);
    
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase();

        if(kind === 'song' || kind === 'tvEpisode') {
            workTitle = workTitle.doubleQuote();
        } else {
            workTitle = workTitle.replaceAll('"', '');
            workTitle = workTitle.italicize();
        }
    }

    firstInfo = firstInfo.appendData(workTitle, ', ', 'review of');

    if (authorPart.length == 0) {
        firstInfo = firstInfo.upperFirstLetter();
    }

    let contributorString = getSecondaryContributorString(refData.contributors);
    firstInfo = firstInfo.appendData(contributorString, ', ');

    switch(type){
        case 'blog':
            let blog = shared.getStringValue(refData.blog);

            if (blog.startsWith('[')) {
                blog = shared.removeDescriptionFromTitle(blog);
            } else{
                blog = blog.replaceAmpersand().toTitleCase().italicize();
            }    

            firstInfo = firstInfo.appendData(blog, ', ', '', '(blog)');
            break;

        case 'periodical':
            let periodical = shared.getStringValue(refData.periodical);

            if (periodical.startsWith('[')) {
                periodical = shared.removeDescriptionFromTitle(blog);
            } else{
                periodical = periodical.replaceAmpersand().toTitleCase().italicize();
            }    
            
            firstInfo = firstInfo.appendData(periodical, ', ');

            let volume = shared.getStringValue(refData.volume);
            firstInfo = firstInfo.appendData(volume);

            let issue = shared.getStringValue(refData.issue);
            firstInfo = firstInfo.appendData(issue, ', ', 'no.');
            break;

        case 'website':
            let website = shared.getStringValue(refData.website);

            if (website.startsWith('[')) {
                website = shared.removeDescriptionFromTitle(website);
            }

            firstInfo = firstInfo.appendData(website, ', ');
            break;
    }
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    switch(type){
        case 'periodical':
            publicationDate = shared.getYear(publicationDate); 
            publicationDate = publicationDate.parenthesis();
            firstInfo = firstInfo.appendData(publicationDate);

            let pageNumbers = shared.getStringValue(refData.pageNumbers);
            pageNumbers = shared.formatPageNumbers(pageNumbers, false);
            firstInfo = firstInfo.appendData(pageNumbers, ': ')

            break;
        case 'blog':
        case 'website':
            publicationDate = shared.getFullDate(publicationDate); 
            firstInfo = firstInfo.appendData(publicationDate, ', ');
            break;
    }


    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    let subsequentAuthorPart = "";
    if (authors.length > 0) {
        subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    }

    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let subsequetWorkTitle = shared.getStringValue(refData.reviewedTitle);

    if (subsequetWorkTitle.startsWith('[')) {
        subsequetWorkTitle = shared.removeDescriptionFromTitle(subsequetWorkTitle).shortenName();
    } else{
        subsequetWorkTitle = subsequetWorkTitle.replaceAmpersand().shortenName().toTitleCase();
    }


    if (subsequetWorkTitle.startsWith('"')) {
        subsequetWorkTitle = subsequetWorkTitle.substring(1);
    } 
    if (subsequetWorkTitle.endsWith('"')) {
        subsequetWorkTitle = subsequetWorkTitle.slice(0, -1);
    } 
    // console.log('subsequetWorkTitle');
    // console.log(subsequetWorkTitle);

    if (!subsequetWorkTitle.endsWith('"')) {
        if(kind === 'song' || kind === 'tvEpisode'){
            subsequetWorkTitle = subsequetWorkTitle.doubleQuote();
        } else {
            subsequetWorkTitle = subsequetWorkTitle.italicize();
        }
    }


    subsequentInfo = subsequentInfo.appendData(subsequetWorkTitle, ', ', 'review of');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    if (authors.length == 0) {
        subsequentInfo = subsequentInfo.upperFirstLetter();
    }

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - which is editors and tranlators
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let reviewAuthors = shared.getContributorsByType('reviewAuthor', secondaryContributors);
            let reviewEditors = shared.getContributorsByType('reviewEditor', secondaryContributors);
            let directors = shared.getContributorsByType('reviewDirector', contributors);
            let writers = shared.getContributorsByType('reviewWriter', contributors);
            let writerDirectors = shared.getContributorsByType('reviewWriterDirector', contributors);
            let artists = shared.getContributorsByType('reviewArtist', contributors);
            let reviewComposers = shared.getContributorsByType('reviewComposer', contributors);
            let reviewGroupArtists = shared.getContributorsByType('reviewGroupArtist', contributors);

            if (writerDirectors.length > 0) {
                if (directors.length > 0 || writers.length > 0) {
               
                    //we need to get order they were entered correctly, so let's recreate these arrays as listed
                    directors = [];
                    writers = [];
                    writerDirectors = [];

					_forEach(contributors, (contributor) => {
                        switch (contributor.type) {
                            case 'reviewDirector':
                                directors.push(contributor);
                                break;
                            case 'reviewWriter':
                                writers.push(contributor);
                                break;
                            case 'reviewWriterDirector':
                                directors.push(contributor);
                                writers.push(contributor);
                            break;
                        }
                    });
                }
            }

            if (reviewAuthors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewAuthors, 'reviewAuthor'));
            }

            if (reviewEditors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewEditors, 'editor'));
            }

            //directors
            if (directors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(directors, 'director'));
            }

            //writers
            if (writers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writers, 'writer'));
            }

            //writerDirectors
            if (writerDirectors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(writerDirectors, 'writerDirector'));
            }

            //artists
            if (artists.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(artists, 'reviewArtist'));
            }

            //reviewGroupArtists
            if (reviewGroupArtists.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewGroupArtists, 'reviewGroupArtist'));
            }

            //reviewComposers
            if (reviewComposers.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(reviewComposers, 'reviewComposer'));
            }

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString.lowerFirstLetter();
            });
        }

    }

    return contributorstring;
}