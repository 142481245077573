const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let authorPart = '';

    if (kind === 'cover') {
        let groupPerformers = getGroupPerformerString(refData.contributors);
        let performers = getPerformerString(refData.contributors);

        if (groupPerformers.length > 0) {
            authorPart = groupPerformers;
        }

        if (performers.length > 0) {
            authorPart = performers;
        }

        authorPart = authorPart.addClosure();
    }
    else{
        authorPart = shared.getAuthorPart(refData);
    }
    
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    // let groupPerformerString = shared.getGroupPerformerString(refData.contributors);
    // console.log('groupPerformerString');
    // console.log(groupPerformerString);

    if(type === 'album'){
		let albumTitle = shared.getStringValue(refData.albumTitle);
        albumTitle = albumTitle.replaceAmpersand();
        albumTitle = albumTitle.toTitleCase().italicize();
        titlePart = titlePart.appendData(albumTitle);
        titlePart = titlePart.addClosure();
	} else if(type === 'song'){
        let songTitle = shared.getStringValue(refData.songTitle);
        if (songTitle.startsWith('[')) {
            songTitle = shared.removeDescriptionFromTitle(songTitle);
        } else{
            songTitle = songTitle.replaceAmpersand();
            songTitle = songTitle.toTitleCase().doubleQuote();
        }
        
        titlePart = titlePart.appendData(songTitle);
        titlePart = titlePart.addClosure();
	}

    let secondaryContributors = '';

    if (kind === 'cover') {
        let authorArray = shared.getContributorsByType('author', refData.contributors);
        let authorString = shared.getSecondaryContributorTypeString(authorArray, 'author');

        secondaryContributors = authorString.upperFirstLetter();
    }
    else{
        secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    }

    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let recordDate = shared.getStringValue(refData.recordDate);
    publicationPart = publicationPart.appendData(recordDate, '', 'Recorded');
    publicationPart = publicationPart.addClosure();
    
    let type = shared.getStringValue(refData.type);
    if(type === 'song'){
        let albumTitle = shared.getStringValue(refData.albumTitle);
        if (albumTitle.startsWith('[')) {
            albumTitle = shared.removeDescriptionFromTitle(albumTitle);
            albumTitle = albumTitle.toLowerCase();
        } else{
            albumTitle = albumTitle.replaceAmpersand();
            albumTitle = albumTitle.toTitleCase().italicize();
        }
        
        publicationPart = publicationPart.appendData(albumTitle, ' ', 'On');
        publicationPart = publicationPart.addClosure();
    }

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);
    if (publisherString.length == 0) {
        publisherString = 'N.p.';
    }
    publicationPart = publicationPart.appendData(publisherString);

        
    let publicationDate = shared.getStringValue(refData.publicationDate);
    if(publicationDate === ''){
        publicationPart = publicationPart.appendData('n.d.', ', ');
    } else {
        publicationDate = shared.getFullDate(publicationDate);   
        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.addClosure();
    }
    

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();
    
    //medium
    let medium = shared.getStringValue(refData.medium);
    medium = shared.fixFormatString(medium);
    locationPart = locationPart.appendData(medium).addClosure();

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'albumTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let authorPart = '';

    if (kind === 'cover') {
        authorPart = getGroupPerformerString(refData.contributors);

        if (authorPart.length == 0) {
            authorPart = getPerformerString(refData.contributors, true);
        }
    }
    else{
        authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    }

    firstInfo = firstInfo.appendData(authorPart);
    
    if(type === 'album'){
		let albumTitle = shared.getStringValue(refData.albumTitle);
        albumTitle = albumTitle.replaceAmpersand();
        albumTitle = albumTitle.toTitleCase().italicize();
        firstInfo = firstInfo.appendData(albumTitle, ', ');
	} else if(type === 'song'){
        let songTitle = shared.getStringValue(refData.songTitle);
        if (songTitle.startsWith('[')) {
            songTitle = shared.removeDescriptionFromTitle(songTitle);
        } else{
            songTitle = songTitle.replaceAmpersand();
            songTitle = songTitle.toTitleCase().doubleQuote();
        }

        firstInfo = firstInfo.appendData(songTitle, ', ');
	}

    if (kind === 'cover') {
        let authorArray = shared.getContributorsByType('author', refData.contributors);
        let authorString = shared.getSecondaryContributorTypeString(authorArray, 'author');

        firstInfo = firstInfo.appendData(authorString, ", ");
    }

    let recordDate = shared.getStringValue(refData.recordDate);
    firstInfo = firstInfo.appendData(recordDate, ', ', 'recorded');
    
    if(type === 'song'){
        let albumTitle = shared.getStringValue(refData.albumTitle);

        if (albumTitle.startsWith('[')) {
            albumTitle = shared.removeDescriptionFromTitle(albumTitle);
            albumTitle = albumTitle.toLowerCase();
        } else{
            albumTitle = albumTitle.replaceAmpersand();
            albumTitle = albumTitle.toTitleCase().italicize();
        }
        
        firstInfo = firstInfo.appendData(albumTitle, ', ', 'on');
    }

    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false);
    if (publisherLocationString.length == 0) {
        publisherLocationString = 'n.p.';
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';
    publisherPart = publisherPart.appendData(publisherLocationString);
    publisherPart = publisherPart.appendData(publicationDate, ', ');
    
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = format.lowerFirstLetter();
    firstInfo = firstInfo.appendData(format, ', ');

    let medium = shared.getStringValue(refData.medium);
    firstInfo = firstInfo.appendData(medium, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    citationLocation = shared.parseTimeStampString(citationLocation);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = '';

    if (kind === 'cover') {
        subsequentAuthorPart = getGroupPerformerString(refData.contributors);

        if (subsequentAuthorPart.length == 0) {
            subsequentAuthorPart = getPerformerString(refData.contributors, true, true);
        }
    }
    else{
        subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    }

    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    if(type === 'album'){
		let albumTitle = shared.getStringValue(refData.albumTitle);
        albumTitle = albumTitle.replaceAmpersand();
        albumTitle = albumTitle.shortenName().toTitleCase().italicize();
        subsequentInfo = subsequentInfo.appendData(albumTitle, ', ');
	} else if(type === 'song'){
        let songTitle = shared.getStringValue(refData.songTitle);

        if (songTitle.startsWith('[')) {
            songTitle = shared.removeDescriptionFromTitle(songTitle);
            // albumTitle = albumTitle.toLowerCase();
        } else{
            songTitle = songTitle.replaceAmpersand();
            songTitle = songTitle.shortenName().toTitleCase().doubleQuote();
        }
        
        subsequentInfo = subsequentInfo.appendData(songTitle, ', ');
	}

    
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function getGroupPerformerString(contributors){
    let performerString = '';
    let performersArray = [];

    contributors = shared.removeEmptyContributors(contributors);
    
    _forEach(contributors, (item) => {
        if (item.type == 'groupPerformer') {
            performersArray.push(item);
        }
    });
    
    //1 Performer
    if (performersArray.length == 1) {
        return performersArray[0].groupName;
    }

    //More than 1
    if (performersArray.length > 1) {
    
        var counter = 0;
        performersArray.forEach(item => {
            if (counter > 0) {

                if (counter == performersArray.length - 1) {
                    performerString = performerString + ', and ';
                }
                else{
                    performerString = performerString + ', ';
                }
            }

            performerString = performerString + item.groupName;
            
            counter++;
        });
    }
    
    return performerString;
}

function getPerformerString(contributors, isCitation, isSubsequent) {

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof isCitation === 'undefined') {
        isSubsequent = false;
    }

    let performerArray = shared.getContributorsByType('performer', contributors);
    let performerString = '';

    //1 - Last, First Middle Suffix.
    if (performerArray.length == 1) {

        if (isCitation) {
            performerString = shared.getCitationName(performerArray[0], isSubsequent);
        }
        else{
            performerString = shared.getReferenceName(performerArray[0]);
        }
    }    


    //2 - Last, First Middle Suffix., and First Middle Last Suffix.
    if (performerArray.length == 2) {
        if (isCitation) {
            performerString = shared.getCitationName(performerArray[0], isSubsequent) + ' and ' + shared.getCitationName(performerArray[1], isSubsequent);
        }
        else{
            performerString = shared.getReferenceName(performerArray[0]) + ', and ' + shared.getSecondaryReferenceName(performerArray[1]);
        }
    }   
    
    //3 or more - Last, First Middle Suffix - list them all
    if (performerArray.length > 2) {

        let contribStringArray = [];

        //throw the contrib strings in an array before we build
        let counter = 1;
        _forEach(performerArray, (contrib) => {
            let contribString = '';
            
            if (counter == 1) {

                if (isCitation) {
                    contribString = shared.getCitationName(contrib, isSubsequent);
                }
                else{
                    contribString = shared.getReferenceName(contrib);
                }
            }
            else{
                contribString = shared.getSecondaryReferenceName(contrib);
            }

            contribStringArray.push(contribString);
            counter++;
        });

            counter = 1;
            contribStringArray.forEach(contrib => {
            if (counter > 1) {

                if (counter == contribStringArray.length) {
                    performerString = performerString + ', and '
                }
                else{
                    performerString = performerString + ', ';
                }
            }

            performerString = performerString + contrib;
            counter++;
        });
    }

    return performerString;
}