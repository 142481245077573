const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.workTitle);

    if (workTitle) {
        // work title has content
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
        }
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            workTitle = workTitle.addClosure();
        }
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let publisherString = shared.getPublisherStringInverted(refData.publishers);

    if (publisherString != 'N.p.') {
        let publicationDate = shared.getStringValue(refData.publicationDate);
        publicationDate = shared.getFullDate(publicationDate);
        publicationDate = publicationDate.upperFirstLetter();
        titlePart = titlePart.appendData(publicationDate);
        titlePart = titlePart.addClosure();
    }

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    // publishers here are different for informal works than other places
    titlePart = titlePart.appendData(publisherString);
    titlePart = titlePart.addClosure();

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    seriesTitle = seriesTitle.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(seriesTitle);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.workTitle);
    
    if (workTitle) {
        // work title has content
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);

            if (firstInfo.length > 0) {
                workTitle = workTitle.lowerFirstLetter();
            }

        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
            
        }
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            workTitle = workTitle.upperFirstLetter();
            workTitle = workTitle.addClosure();
        }
    }
    firstInfo = firstInfo.appendData(workTitle, ", ");
    

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let publisherLocationString = shared.getPublisherStringInverted(refData.publishers, true);

    if (publisherLocationString != 'n.p.') {
        let publicationDate = shared.getFullDate(refData.publicationDate);
        firstInfo = firstInfo.appendData(publicationDate, ', ');
    }

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    firstInfo = firstInfo.appendData(description, ', ');

    firstInfo = firstInfo.appendData(publisherLocationString, ', ');
    
    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    workTitle = workTitle.replaceAmpersand().toTitleCase();
    firstInfo = firstInfo.appendData(seriesTitle, ", ");

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, " ", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.workTitle);

    if (workTitle) {
        // work title has content
        // if this is a description, remove the brackets
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
            // .toTitleCase().doubleQuote(); // double quotes for Test 7 and 8 
            workTitle = workTitle.addClosure();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
            
        }
    } else {
        // work title is blank, try to grab the description
        workTitle = shared.getStringValue(refData.description);
        if (workTitle) {
            workTitle = workTitle.shortenName().addClosure();
        }
    }


    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}