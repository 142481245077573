const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getAuthorPart(refData){
    let authorPart = '';

    if (refData.type == 'regulation') {
        return authorPart;
    }
    else{
        switch (refData.kind) {
            case 'senate':
                authorPart = 'US Congress, Senate.';
                break;
            case 'house':
                authorPart = 'US Congress, House.';
                break;
        }
    }

    return authorPart;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (refData.type == 'report') {
        //[session] Cong., [sessionPart] sess., [publicationDate].
        let session = shared.getStringValue(refData.session);

        if (session.length > 0) {
            session = shared.getOrdinalValue(session);
        }

        publicationPart = publicationPart.appendData(session);
        publicationPart = publicationPart.appendData('Cong.');

        let sessionPart = shared.getStringValue(refData.sessionPart);

        if (sessionPart.length > 0) {
            publicationPart = publicationPart.appendData(sessionPart, ', ');
            publicationPart = publicationPart.appendData('sess.');   
        }

        publicationDate = shared.getFullDate(publicationDate);   
        
        if(publicationDate === 'n.d.' && publicationPart.length == 0){
            publicationDate = publicationDate.upperFirstLetter();
        }

        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.addClosure();

        switch (refData.kind) {
            case 'senate':
                publicationPart = publicationPart.appendData('S. Rep.');
                break;
            case 'house':
                publicationPart = publicationPart.appendData('H. Rep.');
                break;
        }
    }

    if (refData.type == 'regulation') {

        if (refData.kind == 'codified') {
            //[volume] C.F.R. § [section]. [publicationDate].
            let volume = shared.getStringValue(refData.volume);
            publicationPart = publicationPart.appendData(volume);
            publicationPart = publicationPart.appendData('C.F.R.');

            let section = shared.getStringValue(refData.section);
            section = formatSectionString(section);
            publicationPart = publicationPart.appendData(section, ' ');
            publicationPart = publicationPart.addClosure();

            publicationDate = shared.getFullDate(publicationDate);   

            if(publicationDate === 'n.d.'){
                publicationDate = publicationDate.upperFirstLetter();
            }

            publicationPart = publicationPart.appendData(publicationDate);
            publicationPart = publicationPart.addClosure();
        }

        if (refData.kind == 'uncodified') {
            //[proposedVolume] F.R. [pageNumber]. Proposed [proposedDate].
            let proposedVolume = shared.getStringValue(refData.proposedVolume);
            publicationPart = publicationPart.appendData(proposedVolume, ', ');
            publicationPart = publicationPart.appendData('F.R.');

            let pageNumber = shared.getStringValue(refData.pageNumber);
            publicationPart = publicationPart.appendData(pageNumber);
            publicationPart = publicationPart.addClosure();

            let proposedDate = shared.getStringValue(refData.proposedDate);

            if (proposedDate.length > 0) {
                proposedDate = shared.getFullDate(proposedDate);   
                publicationPart = publicationPart.appendData(proposedDate, ' ', 'Proposed');
                publicationPart = publicationPart.addClosure();
            }
        }

    }

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //reportNumber
    let reportNumber = shared.getStringValue(refData.reportNumber);
    locationPart = locationPart.appendData(reportNumber).addClosure();

    // url
    let url = shared.getStringValue(refData.url);
    
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }
    
    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    //Report
    //[contributor.groupAuthor], [title], [session] Cong., [sessionPart] sess., [publicationDate], S. Rep. [number], [location], [url].

    if (refData.type == 'report') {
        let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
        firstInfo = firstInfo.appendData(authorPart);
    }

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    firstInfo = firstInfo.appendData(title, ", ");
    let publicationDate = shared.getFullDate(refData.publicationDate);

    if (refData.type == 'report') {

        let session = shared.getStringValue(refData.session);

        if (session.length > 0) {
            session = shared.getOrdinalValue(session);
        }

        firstInfo = firstInfo.appendData(session, ', ');
        firstInfo = firstInfo.appendData('Cong.');

        let sessionPart = shared.getStringValue(refData.sessionPart);

        if (sessionPart.length > 0) {
            firstInfo = firstInfo.appendData(sessionPart, ', ');
            firstInfo = firstInfo.appendData('sess.');
        }

        firstInfo = firstInfo.appendData(publicationDate, ', ');

        switch (refData.kind) {
            case 'senate':
                firstInfo = firstInfo.appendData('S. Rep.', ', ');
                break;
            case 'house':
                firstInfo = firstInfo.appendData('H. Rep.', ', ');
                break;
        }

        let reportNumber = shared.getStringValue(refData.reportNumber);
        firstInfo = firstInfo.appendData(reportNumber);
    }

    if (refData.type == 'regulation') {
    
        if (refData.kind == 'codified') {
            //[volume] C.F.R. § [section] ([publicationDate])
            let volume = shared.getStringValue(refData.volume);
            firstInfo = firstInfo.appendData(volume, ', ');
            firstInfo = firstInfo.appendData('C.F.R.');

            let section = shared.getStringValue(refData.section);
            section = formatSectionString(section);
            firstInfo = firstInfo.appendData(section, ' ');

            publicationDate = shared.getFullDate(publicationDate);   

            if (publicationDate.length > 0) {
                publicationDate = publicationDate.parenthesis();
                firstInfo = firstInfo.appendData(publicationDate, '');   
            }
        }

        if (refData.kind == 'uncodified') {
            //[proposedVolume] F.R. [pageNumber], proposed [proposedDate]
            let proposedVolume = shared.getStringValue(refData.proposedVolume);
            firstInfo = firstInfo.appendData(proposedVolume, ', ');
            firstInfo = firstInfo.appendData('F.R.');

            let pageNumber = shared.getStringValue(refData.pageNumber);
            firstInfo = firstInfo.appendData(pageNumber);

            let proposedDate = shared.getStringValue(refData.proposedDate);

            if (proposedDate.length > 0) {
                proposedDate = shared.getFullDate(proposedDate);   
                firstInfo = firstInfo.appendData(proposedDate, ', ', 'proposed');
            }
        }
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    if (refData.type == 'report') {
        let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);

        if (subsequentAuthorPart.length > 0) {
            subsequentAuthorPart = subsequentAuthorPart.shortenName();
        }

        subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    }

    let shortTitle = shared.getStringValue(refData.shortTitle);

    if (shortTitle.length > 0) {
        subsequentInfo = subsequentInfo.appendData(shortTitle.replaceAmpersand().toTitleCase(), ', ');
    }
    else{
        title = shared.getStringValue(refData.title);
        title = title.replaceAmpersand().shortenName().toTitleCase();

        subsequentInfo = subsequentInfo.appendData(title, ', ');
    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function formatSectionString(section){
    let prefix = '§';

    if (section.length == 0) {
        return '';
    }

    if (section.includes('-') || section.includes('–') || section.includes('—')) {
        prefix = '§§';
    }

    section = prefix + ' ' + section; 
    
    return section;
}