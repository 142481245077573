const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');
const _has = require('lodash/has');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);

    let useTitle = true;

    let workTitle = shared.getStringValue(refData.title);
    if(workTitle.length > 0){
        // there is a title, go normal
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
    } else {
        // there is no title, check the content
        useTitle = false;

        let content = shared.getStringValue(refData.content);
        workTitle = workTitle.appendData(content);    
    }

    if(type === 'forum'){
        if(useTitle){
            titlePart = titlePart.appendData(workTitle, ' ', 'Reply to');
        } else {
            titlePart = titlePart.appendData(workTitle);    
        }
        titlePart = titlePart.addClosure();

        let platform = shared.getStringValue(refData.platform);
        titlePart = titlePart.appendData(platform);
        titlePart = titlePart.addClosure();

        let publicationDate = shared.getStringValue(refData.publicationDate);
        publicationDate = shared.getFullDate(publicationDate);   

        if(publicationDate === 'n.d.'){
            titlePart = titlePart.appendData('N.d.');
        } else {
            titlePart = titlePart.appendData(publicationDate);
        }
            
    } else if(type === 'comment'){
        let publicationDate = shared.getStringValue(refData.publicationDate);
        publicationDate = shared.getFullDate(publicationDate);   

        let hasContribs = false;

        if (typeof refData.contributors !== 'undefined') {
            if (refData.contributors.length > 0) {
                hasContribs = true;
            }
        }
        
        if(!hasContribs){
            // If no author - put the “Comment on…” before the date.
            if(useTitle){
                titlePart = titlePart.appendData(workTitle, ' ', 'Comment on');
            } else {
                titlePart = titlePart.appendData(workTitle);    
            }
            
            titlePart = titlePart.addClosure();
            
            if(publicationDate === 'n.d.'){
                titlePart = titlePart.appendData('N.d.');
            } else {
                titlePart = titlePart.appendData(publicationDate);
            }

        } else {
            // there is some contributor, so act normal
            let originalAuthorContribs = shared.getContributorsByType('originalAuthor', refData.contributors);
            if(publicationDate === 'n.d.'){
                titlePart = titlePart.appendData('N.d.');
            } else {
                titlePart = titlePart.appendData(publicationDate);
            }
            titlePart = titlePart.addClosure();

            if(originalAuthorContribs[originalAuthorContribs.length - 1] && _has(originalAuthorContribs[originalAuthorContribs.length - 1], 'lastName')){
                titlePart = titlePart.appendData(originalAuthorContribs[originalAuthorContribs.length - 1].lastName, ' ', 'Comment on');    
                titlePart = titlePart.appendData(workTitle, ', ');
            } else {
                titlePart = titlePart.appendData(workTitle, ' ', 'Comment on');
            }
        }
    }
    
    titlePart = titlePart.addClosure();

    return titlePart;
}


function getLocationPart(refData){
    let locationPart = '';

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let type = shared.getStringValue(refData.type);

    let workTitle = shared.getStringValue(refData.title);

    let useTitle = true;

    if(workTitle.length > 0){
        // there is a title, go normal
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
    } else {
        // there is no title, check the content
        useTitle = false;

        let content = shared.getStringValue(refData.content);
        workTitle = workTitle.appendData(content);    
    }

    if(type === 'forum'){
        if(useTitle){
            if(authorPart.length > 0){
                firstInfo = firstInfo.appendData(workTitle, ', ', 'reply to');    
            } else {
                firstInfo = firstInfo.appendData(workTitle, ', ', 'Reply to');
            }
            
        } else {
            firstInfo = firstInfo.appendData(workTitle);    
        }

        let platform = shared.getStringValue(refData.platform);
        firstInfo = firstInfo.appendData(platform, ', ');


        let publicationDate = shared.getFullDate(refData.publicationDate);
        firstInfo = firstInfo.appendData(publicationDate, ', ');

    } else if(type === 'comment'){
        let publicationDate = shared.getStringValue(refData.publicationDate);
        publicationDate = shared.getFullDate(publicationDate);   

        if(refData.contributors.length === 0){
            // If no author - put the “Comment on…” before the date.
            if(useTitle){
                firstInfo = firstInfo.appendData(workTitle, ', ', 'Comment on');    
                
            } else {
                firstInfo = firstInfo.appendData(workTitle);    
            }

            firstInfo = firstInfo.appendData(publicationDate, ', ');
            
        } else {
            // there is a contributor so act normal
            let originalAuthorContribs = shared.getContributorsByType('originalAuthor', refData.contributors);

            if(originalAuthorContribs[originalAuthorContribs.length - 1] && _has(originalAuthorContribs[originalAuthorContribs.length - 1], 'lastName')){
                
                firstInfo = firstInfo.appendData(publicationDate, ', ');

                firstInfo = firstInfo.appendData(originalAuthorContribs[originalAuthorContribs.length - 1].lastName, ', ', 'comment on');    
                firstInfo = firstInfo.appendData(workTitle, ', ');

            } else {
                firstInfo = firstInfo.appendData(publicationDate, ', ');

                firstInfo = firstInfo.appendData(workTitle, ', ', 'comment on');

                
            }
        }

        

    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let content = '';

    if(useTitle){
        workTitle = shared.getStringValue(refData.title);
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();    
    } else {
        content = shared.getStringValue(refData.content);
        content = content.shortenName();
        // subsequentInfo = subsequentInfo.appendData(content);    
    }
    

    if(type === 'forum'){
        if(useTitle){
            if(subsequentAuthorPart.length > 0){
                subsequentInfo = subsequentInfo.appendData(workTitle, ', ', 'reply to');
            } else {
                subsequentInfo = subsequentInfo.appendData(workTitle, ', ', 'Reply to');
            }
        } else {
            subsequentInfo = subsequentInfo.appendData(content);
        }
        
    } else if(type === 'comment'){
        if(refData.contributors.length === 0){
            // If no author - put the “Comment on…” before the date.
            if(useTitle){
                subsequentInfo = subsequentInfo.appendData(workTitle, ', ', 'Comment on');
            } else {
                subsequentInfo = subsequentInfo.appendData(content);
            }
            
            
        } else {

            let originalAuthorContribs = shared.getContributorsByType('originalAuthor', refData.contributors);

            if(originalAuthorContribs[originalAuthorContribs.length - 1] && _has(originalAuthorContribs[originalAuthorContribs.length - 1], 'lastName')){
                subsequentInfo = subsequentInfo.appendData(originalAuthorContribs[originalAuthorContribs.length - 1].lastName, ', ', 'comment on');    
                subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
            } else {
                subsequentInfo = subsequentInfo.appendData(workTitle, ', ', 'comment on');
            }
        }

    }

    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}