const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    let workTitle = '';
    let translatedWorkTitle = '';

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedSectionTitle);
    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedIssueTitle);    
    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedArticleTitle);    
    }
    
    if (translatedWorkTitle.length > 0 && workTitle.length == 0) {
        workTitle = translatedWorkTitle;
        translatedWorkTitle = '';
    }

    if (workTitle.length > 0) {
        titlePart = workTitle;
    }

    if (translatedWorkTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedWorkTitle + ']';
    }
    

    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    let magazineTitle = shared.getStringValue(refData.magazineTitle);

    if (magazineTitle.startsWith('[')) {
        magazineTitle = shared.removeDescriptionFromTitle(magazineTitle);
    } else{
        magazineTitle = magazineTitle.replaceAmpersand().toTitleCase().italicize();    
    }
    
    if(type === 'special') {
        if(kind === 'section'){
            titlePart = titlePart.appendData('Special section');
            titlePart += ',';
            titlePart = titlePart.appendData(magazineTitle);
            titlePart += ',';
        } else if(kind === 'issue'){
            titlePart = titlePart.appendData('Special issue');
            titlePart += ',';
            titlePart = titlePart.appendData(magazineTitle);
            titlePart += ','; 
        }
    } else {
        if(kind === 'editorial'){
            titlePart = titlePart.appendData('Editorial.');
        } else if(kind === 'retracted'){
            titlePart = titlePart.appendData('Retracted article.');
        }

        magazineTitle += ',';
        titlePart = titlePart.appendData(magazineTitle);
    }
    
    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationPart = publicationPart.appendData(publicationDate, ', ');

    let referencePages = shared.getStringValue(refData.referencePages);
    publicationPart = publicationPart.appendData(referencePages, ", ").addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.articleTitle).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);
    
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let workTitle = '';
    let translatedWorkTitle = '';

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedSectionTitle);    
    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();   
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedIssueTitle);    
    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();   
        }

        translatedWorkTitle = shared.getStringValue(refData.translatedArticleTitle);
    }
    

    if (translatedWorkTitle.length > 0 && workTitle.length == 0) {
        workTitle = translatedWorkTitle;
        translatedWorkTitle = '';
    }

    if (translatedWorkTitle.length > 0) {
        workTitle = workTitle + ' [' + translatedWorkTitle + ']';
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");


    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.toLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');

    let magazineTitle = shared.getStringValue(refData.magazineTitle);

    if (magazineTitle.startsWith('[')) {
        magazineTitle = shared.removeDescriptionFromTitle(magazineTitle);
    } else{
        magazineTitle = magazineTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    if(type === 'special'){
        if(kind === 'section'){
            firstInfo = firstInfo.appendData('special section', ', ');
        } else if(kind === 'issue') {
            firstInfo = firstInfo.appendData('special issue', ', ');
        }
    } else {
        if(kind === 'editorial'){
            firstInfo = firstInfo.appendData('editorial', ', ');
        } else if(kind === 'retracted'){
            firstInfo = firstInfo.appendData('retracted article', ', ');
        }
    }

    firstInfo = firstInfo.appendData(magazineTitle, ", ");

    let publisherPart = '';
    let publicationDate = shared.getFullDate(refData.publicationDate);
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();    
        }
    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();    
        }
    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();    
        }
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}