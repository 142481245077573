const _cloneDeep = require('lodash/cloneDeep');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    specialRules
};

function specialRules(references){
	let _references = [];

    //iterarate each reference to update it's value
    references.forEach((thisReference) => {
		// clone thisReference so i can manipulate it
		let _thisReference = _cloneDeep(thisReference);

		let typeName = getTypeShortName(_thisReference.referenceTypeID);
        
        let typeEngine = getTypeEngine(typeName);
        let engineRef;
        let refDataObject; 
        
        if (typeof _thisReference.data === 'string') {
          refDataObject = JSON.parse((_thisReference.data));
        }
        else{
          refDataObject = _thisReference.data;
        }

        engineRef = typeEngine.getReference(refDataObject);
        
        _thisReference.authorPart = engineRef.authorPart;
        _thisReference.datePart = engineRef.datePart;
        _thisReference.sameAuthorDatePartID = 0;
        _thisReference.newDatePart = '';
        _thisReference.orderByValue = engineRef.orderByValue;
        _thisReference.displayValue = engineRef.value;
        _thisReference.citationEtAlOverwrite = '';

        let citationData = new Object;
        citationData.namePart = true;
        citationData.datePart = true;
        citationData.label = '';
        citationData.value = '';
        citationData.type = 'notdirect';
        
        _thisReference.indirectCitation = typeEngine.getCitation(refDataObject, citationData, references);

        //so group authors with Abbreviations do not cause an issue with this logic, replace any abbreviations in that citation
        //Group Author [GA], 2021, p.2
        let startBracket = _thisReference.indirectCitation.first.indexOf('[');
        let endBracket = _thisReference.indirectCitation.first.indexOf(']');
        
        if (startBracket != -1 && endBracket != -1) {
          _thisReference.indirectCitation.first = _thisReference.indirectCitation.first.substring(0, startBracket - 1) + _thisReference.indirectCitation.first.substring(endBracket + 1);
        }

        //update the citations for this reference
        if (_thisReference.citations != undefined) {

          //see if this is a simgle object, or an array
          if (Array.isArray(_thisReference.citations)) {

            let counter = 1;

            _thisReference.citations.forEach(thisCitation => {

              thisCitation.apa7 = typeEngine.getCitation(refDataObject, thisCitation.citationData, references);

              if (counter > 1 && thisCitation.apa7.subsequent != '') {
                thisCitation.displayValue = '(' + thisCitation.apa7.subsequent + ')';
              }
              else{
                thisCitation.displayValue = '(' + thisCitation.apa7.first + ')';
              }
    
              counter++;
            });
          }
          else{
            if (typeof _thisReference.citations === 'object' && _thisReference.citations !== null) {
              _thisReference.citations.apa7 = typeEngine.getCitation(refDataObject, _thisReference.citations.citationData, references);
              _thisReference.citations.displayValue = '(' + _thisReference.citations.apa7.first + ')'; 
            }
          }
        }
		_references.push(_thisReference);
		
    });//e:forEach

    //initial sort
    orderRefs(_references);

    //Same author date parts
    sameAuthorDateParts(_references);
    
    //Grouped Citations
    groupedCitations(_references);

    return _references;
}

function getTypeShortName(id){
    //swap the ID to the short name from types.json
    var typeData = require('../types.json');
    let typeShortName = '';
  
    typeData.refTypes.forEach(thisType => {
      if (thisType.id == id) {
        typeShortName = thisType.shortname;
      }
    });
  
    return typeShortName;
  }

  function getTypeEngine(type){
    var typeEngine = null;
  
      typeEngine = require('./APA7-' + type +'.js');
  
      return typeEngine;
  }

  function groupedCitations(references){

    //iterate and see if we have any grouped citations in the paper
    let groupCitationIds = [];

    references.forEach(thisRef => {

      if (thisRef.citations != undefined) {

        if (Array.isArray(thisRef.citations)) {
          for (var i = 0; i < thisRef.citations.length; i++) {
            let thisCitation = thisRef.citations[i];
  
            if (thisCitation.groupUniqueID != undefined) {
              if (thisCitation.groupUniqueID.length > 0) {
  
                //make sure this group id is not already in the list
                let found = false;
                groupCitationIds.forEach(thisGroupCitationID => {
                  if (thisGroupCitationID.toUpperCase() == thisCitation.groupUniqueID.toUpperCase()) {
                    found = true;
                  }
                });
  
                if (!found) {
                  groupCitationIds.push(thisCitation.groupUniqueID.toUpperCase());
                }
  
              }
            }
          }//e:for:citations
        }
        else{
          if (thisRef.citations.groupUniqueID != undefined) {
              if (thisRef.citations.groupUniqueID.length > 0) {
                //make sure this group id is not already in the list
                let found = false;
                groupCitationIds.forEach(thisGroupCitationID => {
                  if (thisGroupCitationID.toUpperCase() == thisRef.citations.groupUniqueID.toUpperCase()) {
                    found = true;
                  }
                });
  
                if (!found) {
                  groupCitationIds.push(thisRef.citations.groupUniqueID.toUpperCase());
                }
              }
          }
        }
      }

    });//e:for:references

    //process each group ID now
    groupCitationIds.forEach(thisGroupID => {

      let groupCitations = [];

      references.forEach(thisRef => {
        if (thisRef.citations != undefined) {

          if (Array.isArray(thisRef.citations)) {
            thisRef.citations.forEach(thisCitation =>{

              if (thisCitation.groupUniqueID != undefined) {
                if (thisCitation.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                  
                  //append some ref data that we may need in the next step building the output
                  thisCitation.refAuthorPart = thisRef.authorPart;
  
                  groupCitations.push(thisCitation);
                }
              }
  
            });//e:for:citations
          }
          else{
            if (thisRef.citations.groupUniqueID != undefined) {
              if (thisRef.citations.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                
                //append some ref data that we may need in the next step building the output
                thisRef.citations.refAuthorPart = thisRef.authorPart;

                groupCitations.push(thisRef.citations);
              }
            }
          }
        }
      });//e:for:references

      //make a pass through now to get our new value
      let groupValue = '';
      let lastRefAuthorPart = '';
      let lastCitationUsed = ''

      groupCitations.forEach(thisCitation => {

        let thisDisplay = thisCitation.displayValue;

        if (thisDisplay.startsWith('(')) {
          thisDisplay = thisDisplay.substring(1, thisDisplay.length);
        }

        if (thisDisplay.endsWith(')')) {
          thisDisplay = thisDisplay.substring(0, thisDisplay.length -1);
        }

        let displayToUse = thisDisplay;

        if (lastRefAuthorPart.length > 0) {
          if (thisCitation.refAuthorPart == lastRefAuthorPart) {

            //if this citation has a subsequent (Abbreviation) then we are going to just trim out the non author part
            if (thisCitation.apa7.subsequent.length > 0) {
              //remove to the first comma
              let commaSplit = thisDisplay.split(',');

              displayToUse = thisDisplay.substring(commaSplit[0].length + 2);
            }
            else{
              //loop through until we find the part of this citation that start to differ from the last one we used
              let found = false;
              for (let i = 0; i < displayToUse.length; i++) {

                if (!found) {

                  let thisChar = displayToUse.charAt(i);

                  if (shared.isInteger(thisChar)) {
                    displayToUse = displayToUse.substring(i, displayToUse.length);
                    found = true;
                  }
                  else{
                    if (displayToUse.charAt(i) != lastCitationUsed.charAt(i)) {
                      displayToUse = displayToUse.substring(i, displayToUse.length);
                      found = true;
                    } 
                  } 
                }
              }
            }
          } 
        }

        lastCitationUsed = thisDisplay;
        lastRefAuthorPart = thisCitation.refAuthorPart; 
        
        if (displayToUse.length > 0) {
          if (groupValue.length > 0) {
            groupValue = groupValue + '; ';
          }

          groupValue = groupValue + displayToUse;
        }

      });//e:for:group citations

      if (groupValue.length > 0) {
        groupValue = '(' + groupValue + ')';
      }

      //now go back and set the display value back
      groupCitations.forEach(thisCitation => {
        thisCitation.displayValue = groupValue;
      });//e:for:group citations

    });

  }

  function sameAuthorDateParts(references){
      //see which references have the same author date parts
    let sameAuthorDatePartID = 1; 
    let sameAuthorDatePartIDs = [];

    references.forEach(thisReference => {

      if (thisReference.authorPart != '' && thisReference.datePart != '' && thisReference.sameAuthorDatePartID == 0) {
          
        //inner loop for this reference
          let foundMatches = false;
          references.forEach(innerReference => {
            if (innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
                innerReference.indirectCitation.first == thisReference.indirectCitation.first
                ) 
            {
              //we found a matching author and date part
              foundMatches = true;
              thisReference.sameAuthorDatePartID = sameAuthorDatePartID;
              innerReference.sameAuthorDatePartID = sameAuthorDatePartID;
            }
          });

          //if we found a match, increment our id
          if (foundMatches) {
            sameAuthorDatePartIDs.push(sameAuthorDatePartID);
            sameAuthorDatePartID++;
          }
      }
        
    });

    //now that we have our matches, go back and see if we have any that contain et al
    //if we do, they need to have their citation author part "extended" instead of appended
    sameAuthorDatePartIDs.forEach(thisSameID =>{
    
        let matchingRefs = [];

        references.forEach(thisReference => {
          if (thisReference.sameAuthorDatePartID == thisSameID) {
            matchingRefs.push(thisReference);
          }
        });

        let refsToOverwrite = [];

        matchingRefs.forEach(thisReference => {

          //Short circuit this for refs with the exact same Author Part as another ref
          // all we can do is append a date later on
          let hasAnExactMatch = false;
          references.forEach(innerReference => {
            if (thisReference.referenceUniqueID != innerReference.referenceUniqueID &&
                thisReference.authorPart == innerReference.authorPart) {
                  hasAnExactMatch = true;
            }
          });          


          if (thisReference.indirectCitation.first.includes('et al')){

              let alreadyFound = false;
              //pull out the refs we can overwrite
              matchingRefs.forEach(innerReference => {
                  if (thisReference.referenceUniqueID != innerReference.referenceUniqueID && !alreadyFound && !hasAnExactMatch) {
                    if (innerReference.authorPart != thisReference.authorPart) {
                      refsToOverwrite.push(thisReference);
                      alreadyFound = true;
                    }
                  }
              });

          }
        });

        //if we get here and this only has one reference, all others had an exact match, that one is good
        if (refsToOverwrite.length == 1) {
          refsToOverwrite[0].sameAuthorDatePartID = 0;
          refsToOverwrite = [];
        }

        refsToOverwrite.forEach(thisReference => {
          matchingRefs.forEach(innerReference => {

            if (thisReference.referenceUniqueID != innerReference.referenceUniqueID) {

              let refDataObject; 
              
              if (typeof thisReference.data === 'string') {
                refDataObject = JSON.parse((thisReference.data));
              }
              else{
                refDataObject = thisReference.data;
              }

              let smallContribCount = refDataObject.contributors.length;
              let totalContribCount = smallContribCount;
              let addEtAl = false;

              let innerRefDataObject; 

              if (typeof innerReference.data === 'string') {
                innerRefDataObject = JSON.parse((innerReference.data));
              }
              else{
                innerRefDataObject = innerReference.data;
              }

              if (innerRefDataObject.contributors.length < smallContribCount) {
                  smallContribCount = innerRefDataObject.contributors.length;
                  addEtAl = true;
              }
              
              let contributors = [];
              //loop authors until we find the difference
              let endMatches = false;
              for (var i = 0; i < smallContribCount; i++) {

                let firstInitial = refDataObject.contributors[i].firstName.getInitial();
                let innerFirstInitial = refDataObject.contributors[i].firstName.getInitial();

                if (!endMatches) {
                  if (refDataObject.contributors[i].lastName == innerRefDataObject.contributors[i].lastName
                    && refDataObject.contributors[i].middleName == innerRefDataObject.contributors[i].middleName
                    && firstInitial == innerFirstInitial ) {
                      contributors.push(refDataObject.contributors[i]);
                  }
                  else{

                    if (i < smallContribCount - 1) {
                      addEtAl = true;
                    }

                    contributors.push(refDataObject.contributors[i]);
                    endMatches = true;
                  }  
                }
              }//e:contributors

              //if this is only 3 and the first 2 don't match, add them all
              if (contributors.length == 2 && totalContribCount == 3) {
                  contributors.push(refDataObject.contributors[2]);  
                  addEtAl = false;
              }

              //as an addtional check, if this built out all of the references, then all we need to do is the normal et al
              if ((contributors.length + 1) == refDataObject.contributors.length && addEtAl) {
                contributors = [];
                contributors.push(refDataObject.contributors[0]);
              }

              let includeAmpersand = false;

              if (contributors.length == refDataObject.contributors.length) {
                includeAmpersand = true;
              }
      
              let overwrite = getCitationEtAlOverwrite(contributors, addEtAl, includeAmpersand);
      
              thisReference.citationEtAlOverwrite = overwrite;
              thisReference.sameAuthorDatePartID = 0;

              //this code may need to be moved depending on how grouped citations come into play
              //iterate every citation in this reference, find the author part from the indirect, and replace that part with the overwrite
              if (thisReference.citations != undefined) {
                let splitArray = thisReference.indirectCitation.first.split(',');
                splitArray.pop();

                let existingAuthorPart = splitArray.join(',');

                //see if this is a simgle object, or an array
                if (Array.isArray(thisReference.citations)) {

                  if (thisReference.citations.length > 0) {
                    let counter = 1;
  
                    thisReference.citations.forEach(thisCitation =>{
                      thisCitation.apa7.first = thisCitation.apa7.first.replace(existingAuthorPart, overwrite);
                      thisCitation.apa7.subsequent = thisCitation.apa7.subsequent.replace(existingAuthorPart, overwrite);
    
                      if (counter > 1 && thisCitation.apa7.subsequent.length > 0) {
                        thisCitation.displayValue = '(' + thisCitation.apa7.subsequent + ')';
                      }
                      else{
                        thisCitation.displayValue = '(' + thisCitation.apa7.first + ')';
                      }
    
                      counter++;
                    });
                  }
                }
                else{
                  if (typeof thisReference.citations === 'object' && thisReference.citations !== null) {
                    thisReference.citations.apa7.first = thisReference.citations.apa7.first.replace(existingAuthorPart, overwrite);
                    thisReference.citations.apa7.subsequent = thisReference.citations.apa7.subsequent.replace(existingAuthorPart, overwrite);
                    thisReference.citations.displayValue = '(' + thisReference.citations.apa7.first + ')';
                  }
                }
              }            
            }
          });
        });

    });


    //loop back through each match found and update it's date
    sameAuthorDatePartIDs.forEach(thisSameID =>{

      let counter = 0;
      references.forEach(thisReference => {

        if (thisReference.sameAuthorDatePartID == thisSameID) {
          let suffixToAdd = String.fromCharCode(97 + counter); //https://stackoverflow.com/questions/3145030/convert-integer-into-its-character-equivalent-where-0-a-1-b-etc
          
          let refsDate = thisReference.datePart.replace('(', '');
          refsDate = refsDate.replace(').', '');

          let newDatePart;

          if (refsDate != 'n.d.' && refsDate != 'in press') {
              //we need to see if this date contains more than just the year
              if (refsDate.length > 4) {
                newDatePart = '(' + refsDate.substring(0, 4) + suffixToAdd + ',' + refsDate.substring(5, refsDate.length) + ').';
              }
              else{

                newDatePart = '(' + refsDate + suffixToAdd + ').';
              }

              thisReference.displayValue = thisReference.displayValue.replace(thisReference.datePart, newDatePart);
              thisReference.orderByValue = shared.getOrderByValue(thisReference.displayValue);

              if (thisReference.citations != undefined) {

                  let citationDate = refsDate;

                  //only grab the year, since that is what is in the citation
                  if (citationDate.length > 0) {
                    citationDate = refsDate.substring(0, 4);
                  }

                  //see if this is a simgle object, or an array
                  if (Array.isArray(thisReference.citations)) {

                    if (thisReference.citations.length > 0) {
                      thisReference.citations.forEach(thisCitation =>{
                        thisCitation.displayValue = thisCitation.displayValue.replace(citationDate, citationDate + suffixToAdd);
                      });
                    }
                  }
                  else{
                    if (thisReference.citations.displayValue != undefined) {
                      thisReference.citations.displayValue = thisReference.citations.displayValue.replace(citationDate, citationDate + suffixToAdd);
                    }
                  }
              }
              
              counter++;  
          }
          else{

            newDatePart = '(' + refsDate + '-' + suffixToAdd + ').';

            thisReference.displayValue = thisReference.displayValue.replace('(' + refsDate + ').', newDatePart);
            thisReference.orderByValue = shared.getOrderByValue(thisReference.displayValue);

            if (thisReference.citations != undefined) {

              //see if this is a simgle object, or an array
              if (Array.isArray(thisReference.citations)) {
                if (thisReference.citations.length > 0) {
                  thisReference.citations.forEach(thisCitation =>{
                    thisCitation.displayValue = thisCitation.displayValue.replace(refsDate, refsDate + '-' + suffixToAdd);
                  });
                }
              }
              else{
                if (thisReference.citations.displayValue != undefined) {
                  thisReference.citations.displayValue = thisReference.citations.displayValue.replace(refsDate, refsDate + '-' + suffixToAdd);
                }
              }
            }

            counter++;
          }
        }
      });

    });

  }

  function getCitationEtAlOverwrite(authors, addEtAl, includeAmpersand){
    var authorString = '';
    var counter = 0;

    authors.forEach(item => {
        if (counter > 0) {

          authorString = authorString + ', '

          if (includeAmpersand && counter == authors.length - 1) {
            authorString = authorString + '& '
          }

            // if (counter == authors.length - 1) {
              
            //   if (!addEtAl) {
            //     authorString = authorString + ',';  
            //   }

            //   //authorString = authorString + ' & '
            // }
            // else{
              // authorString = authorString + ', '
            // }
        }

        authorString = authorString + shared.getCitationName(item, false);

        counter++;
    });

    if (addEtAl) {

      if (authors.length > 1) {
        authorString = authorString + ',';
      }

      authorString = authorString + ' et al.';
    }
  
    return authorString;
}

  function orderRefs(references){
    //order the results
    references.sort((a, b) => {
      let textA = '';
      let textB = '';

      if (a.orderByValue !== null) {
          textA = a.orderByValue;
      }

      if (b.orderByValue !== null) {
        textB = b.orderByValue;
      }

      if (textA.length > 0) {
        textA = textA.toUpperCase();
      }

      if (textB.length > 0) {
        textB = textB.toUpperCase();
      }

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });//e:sort
  }