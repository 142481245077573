const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'bookChapter') {
        citationTitle = shared.getStringValue(refData.chapterTitle).replaceAmpersand();
    }
    else{
        citationTitle = shared.getStringValue(refData.bookTitle).replaceAmpersand();   
    }

    if ((refData.type == 'book' && refData.kind == 'multivolume') || refData.type == 'series' && refData.kind == 'series') {
        citationTitle = shared.getStringValue(refData.seriesTitle).replaceAmpersand();
    }

    if (refData.type == 'series' && refData.kind == 'volume') {
        citationTitle = shared.getStringValue(refData.volumeTitle).replaceAmpersand();
    }

    if (citationTitle.startsWith('[')) {
        citationTitle = citationTitle.removeBrackets();
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    // Book: Whole
    //First Middle Last Suffix, <em>Title of the Book</em>, con. First Middle Last Suffix, Edition (City, ST: Publisher, 2020), Format, pp., <a href=”https://doi.org/10.12345/”>https://doi.org/10.12345/</a>.
    //define our citation logic for this type here
    let titleProperty = 'pageTitle';
    let firstInfo = '';
    let subsequentInfo = '';
    let isTrans = isTranslated(refData);
    
    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let bookTitle = shared.getStringValue(refData.bookTitle);
    let chapterTitle = shared.getStringValue(refData.chapterTitle);
    let volumeTitle = shared.getStringValue(refData.volumeTitle);

    if (refData.type == 'bookChapter') {

        if (isGenericChapterTitle(chapterTitle)) {
            chapterTitle = chapterTitle.toLowerCase();
        }
        else{

            if (chapterTitle.startsWith('[')) {
                chapterTitle = shared.removeDescriptionFromTitle(chapterTitle);
            } else{
                if (!isTrans) {
                    chapterTitle = chapterTitle.replaceAmpersand().toTitleCase();    
                }
                
                chapterTitle = chapterTitle.doubleQuote();    
            }
        }

        let translatedChapterTitle = shared.getStringValue(refData.translatedChapterTitle);

        if (translatedChapterTitle.length > 0) {

            translatedChapterTitle = translatedChapterTitle.replaceAmpersand();

            if (chapterTitle.length > 0) {
                translatedChapterTitle = translatedChapterTitle.bracket();
            }
            else{
                translatedChapterTitle = translatedChapterTitle.toTitleCase();
            }

            // translatedChapterTitle = translatedChapterTitle.toTitleCase().bracket();
            chapterTitle = chapterTitle.appendData(translatedChapterTitle);
        }
        
        if (refData.kind == 'multivolume') {
            if (volumeTitle.length > 0) {
                volumeTitle = volumeTitle.replaceAmpersand();
                volumeTitle = volumeTitle.toTitleCase().italicize();
                chapterTitle = chapterTitle.appendData(volumeTitle, ' in ');
            }
        }
    }

    if (bookTitle.startsWith('[')) {
        bookTitle = bookTitle.removeBrackets();
    }
    else{
        // if (refData.kind == 'translated') {
        bookTitle = bookTitle.replaceAmpersand();

        if ((isTrans && refData.type == 'bookChapter') || refData.kind == 'translated') {
            bookTitle = bookTitle.italicize();
        }
        else{
            bookTitle = bookTitle.toTitleCase().italicize();
        }
    }
    //translatedTitle
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (bookTitle.length == 0) {
        translatedTitle = translatedTitle.replaceAmpersand();
        translatedTitle = translatedTitle.toTitleCase();
    }
    else{
        translatedTitle = translatedTitle.bracket();
    }

    if (chapterTitle.length > 0) {

        firstInfo = firstInfo.appendData(chapterTitle, ", ");

        let translatorString  = '';
        translatorString = shared.getChapterTranslatorString(refData.contributors);

        if (translatorString.length > 0) {
            let primaryType = shared.getPrimaryContributorType(refData);

            if (primaryType != 'chapterTranslator') {
                translatorString = translatorString.replace('Translated by', 'trans.');
                firstInfo = firstInfo.appendData(translatorString, ', ');          
            }   
        }
        
        if (isGenericChapterTitle(chapterTitle)) {
            firstInfo = firstInfo.appendData("to", " ");
        }
        else{
            if (refData.kind != 'multivolume') {
                firstInfo = firstInfo.appendData("in", ", ", '', '', true);
            }
        }

        firstInfo = firstInfo.appendData(bookTitle.appendData(translatedTitle));
    }
    else{
        firstInfo = firstInfo.appendData(bookTitle.appendData(translatedTitle), ", ");
    }
    
    let seriesTitle = shared.getStringValue(refData.seriesTitle);

    if (refData.type == 'series' && refData.kind == 'volume') {
        volumeTitle = shared.getStringValue(refData.volumeTitle);

        if (volumeTitle.length > 0) {
            volumeTitle = volumeTitle.replaceAmpersand();
            volumeTitle = volumeTitle.toTitleCase().italicize();    
            firstInfo = firstInfo.appendData(volumeTitle, ", ");
        }

        let volumeEditors = getEditorTypeString(refData.contributors, 'volume', true);

        if (volumeEditors.length > 0) {
            firstInfo = firstInfo.appendData(volumeEditors, ", ");
        }

        if (seriesTitle.length > 0) {

            seriesTitle = seriesTitle.replaceAmpersand();
            seriesTitle = seriesTitle.toTitleCase();

            let seriesEditors = getEditorTypeString(refData.contributors, 'series');

            if (seriesEditors.length == 0) {
                let volume = shared.getStringValue(refData.volume);

                seriesTitle = seriesTitle.appendData(volume);
            }

            firstInfo = firstInfo.appendData(seriesTitle, ", ");
        }

    }

    if ((refData.type == 'book' && refData.kind == 'multivolume') || refData.type == 'series' && refData.kind == 'series') {
        seriesTitle = seriesTitle.replaceAmpersand();
        seriesTitle = seriesTitle.toTitleCase().italicize();
        
        volumeTitle = shared.getStringValue(refData.volumeTitle);
        volumeTitle = volumeTitle.replaceAmpersand();
        volumeTitle = volumeTitle.toTitleCase().italicize();

        if (seriesTitle.length > 0) {
            firstInfo = firstInfo.appendData(seriesTitle, ", ");

            let volume = shared.getStringValue(refData.volume);

            if (volumeTitle.length > 0 && volume.length > 0) {
                volume = 'vol. ' + volume;
                firstInfo = firstInfo.appendData(volume, ', ');
            }
        }
        else{
            firstInfo = firstInfo.appendData(volumeTitle, ", ");
        }

        if (seriesTitle.length > 0 && volumeTitle.length > 0) {            
            firstInfo = firstInfo.appendData(volumeTitle, ', ');
        }
    }

    let volume = shared.getStringValue(refData.volume);

    if (refData.type == 'series' && refData.kind == 'volume') {
        let seriesEditors = getEditorTypeString(refData.contributors, 'series', true);
        firstInfo = firstInfo.appendData(seriesEditors, ", ");

        if (seriesEditors.length > 0 && volume.length > 0) {
            firstInfo = firstInfo.appendData('vol. ' + volume, ', ');
        }
    }
    else{
        let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
        firstInfo = firstInfo.appendData(secondaryContributors, ", ");
    }

    let referencePages = shared.getStringValue(refData.referencePages);

    if (refData.kind == 'multivolume' || refData.type == 'series') {
        seriesTitle = shared.getStringValue(refData.seriesTitle);
       
        if (seriesTitle.length == 0) {
            if (volume.length > 0) {
                volume = 'vol. ' + volume;
                firstInfo = firstInfo.appendData(volume, ', ');
            }
        }
        else{
            if (refData.type == 'bookChapter') {

                if (referencePages.length > 0) {
                    referencePages = shared.formatPageNumbers(referencePages, false);
                    firstInfo = firstInfo.appendData(referencePages, ', ');
                }

                if (volume.length > 0) {
                    volume = 'vol. ' + volume;
                    firstInfo = firstInfo.appendData(volume, ', ');
                }

                if (seriesTitle.length > 0) {
                    seriesTitle = seriesTitle.replaceAmpersand();
                    seriesTitle = seriesTitle.toTitleCase().italicize();

                    if (volume.length > 0) {
                        firstInfo = firstInfo.appendData(seriesTitle, ' of ');
                    }
                    else{
                        firstInfo = firstInfo.appendData(seriesTitle, ', ');
                    }
                }
            }
        }
    }
    // else{
    //     if (refData.type == 'bookChapter') {

    //         if (referencePages.length > 0) {
    //             referencePages = shared.formatPageNumbers(referencePages, false);
    //             firstInfo = firstInfo.appendData(referencePages, ', ');
    //         }
    //     }
    // }

    let edition = shared.getStringValue(refData.edition);
    edition = shared.fixEditionString(edition).toLowerCase().addClosure();
    firstInfo = firstInfo.appendData(edition, ", ");

    let citationVolume = shared.getCitationVolume(citationData);
    if (refData.type == 'series' && citationVolume.length > 0 && volume.length > 0) {
        citationVolume = 'vol. ' + citationVolume;
        firstInfo = firstInfo.appendData(citationVolume, ', ');
    }

    let citationValue = shared.getStringValue(citationData.value);
    //if this is a series, with a volume in the citation, but not the ref, and they don't have a page number
    //put the citation volume where volume should go
    if (refData.type == 'series' && citationVolume.length > 0 && volume.length == 0 && citationValue.length == 0) {
        citationVolume = 'vol. ' + citationVolume;
        firstInfo = firstInfo.appendData(citationVolume, ', ');
    }

    let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
    let originalPublicationDateYear = originalPublicationDate.getYear(originalPublicationDate);

    let publicationPart = '';
    let publisherString = '';

    if (shared.isSelfPublished(refData)) {

        // if (originalPublicationDateYear.length > 0 && refData.kind != 'translated') {
        if (originalPublicationDateYear.length > 0 && !isTrans) {
            publisherString = originalPublicationDateYear + '; repr., ';
        }

        //if we have a location, we need to add that in
        let publishers = [];
        let publisher = new Object();

        publisher.city = refData.publishers[0].city;
        publisher.state = refData.publishers[0].state;
        publisher.type = refData.publishers[0].type;
        publisher.name = '';

        publishers.push(publisher);

        let publisherLocationString = shared.getPublisherString(publishers, 'reference', true);

        if (publisherLocationString != 'n.p.') {
            publisherString = publisherString +  publisherLocationString + ': self-pub.';
        }
        else{
            publisherString = publisherString + 'self-pub.';
        }
    }
    else{
        publisherString = shared.getPublisherString(refData.publishers, 'reference', true);

        if (originalPublicationDateYear.length > 0 && !isTrans) {
            publisherString = originalPublicationDateYear + '; repr., ' + publisherString;
        }
    }

    if (firstInfo.length == 0 && publisherString.startsWith('n.p.')) {
        publisherString = publisherString.upperFirstLetter();
    }

    publicationPart = publicationPart.appendData(publisherString, ", ");

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);   
        
        publicationPart = publicationPart.appendData(publicationDate, ', ');
    }
    else{
        if (originalPublicationDateYear.length > 0) {
            publicationPart = publicationPart.appendData(originalPublicationDateYear, ', ');
        }
        else{
            publicationPart = publicationPart.appendData('n.d.', ', ');
        }
    }

    if (firstInfo.endsWith('.')) {
        firstInfo = firstInfo.appendData(publicationPart.parenthesis());
    }
    else{
        firstInfo = firstInfo.appendData(publicationPart.parenthesis(), ', ');    
    }

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);

    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    bookTitle = shared.getStringValue(refData.bookTitle);
    chapterTitle = shared.getStringValue(refData.chapterTitle);

    if (refData.type == 'bookChapter') {

        if (isGenericChapterTitle(chapterTitle)) {
            chapterTitle = chapterTitle.toLowerCase();
            chapterTitle = chapterTitle.appendData("to");

            bookTitle = bookTitle.replaceAmpersand();
            bookTitle = bookTitle.shortenName(isTrans).toTitleCase().italicize();

            chapterTitle = chapterTitle.appendData(bookTitle);
        }
        else{

            if (chapterTitle.startsWith('[')) {
                chapterTitle = shared.removeDescriptionFromTitle(chapterTitle);
            } else{
                chapterTitle = chapterTitle.shortenName(isTrans);

                if (!isTrans) {
                    chapterTitle = chapterTitle.replaceAmpersand();
                    chapterTitle = chapterTitle.toTitleCase();    
                }

                chapterTitle = chapterTitle.doubleQuote();   
            }
        }

        subsequentInfo = subsequentInfo.appendData(chapterTitle, ", ");
    }
    else{
        bookTitle = bookTitle.replaceAmpersand();

        if (bookTitle.startsWith('[')) {
            bookTitle = bookTitle.removeBrackets().shortenName(isTrans);
        }
        else{
            if (refData.kind == 'translated') {
                bookTitle = bookTitle.shortenName(isTrans).italicize();
            }
            else{
                bookTitle = bookTitle.shortenName(isTrans).toTitleCase().italicize();
            }
        }
    
        if (bookTitle.length > 0) {
            subsequentInfo = subsequentInfo.appendData(bookTitle, ", ");
        }
        else{
            //see if we have a translated title
            translatedTitle = shared.getStringValue(refData.translatedTitle);
            
            if (translatedTitle.length > 0) {
                translatedTitle = translatedTitle.shortenName().bracket();
                subsequentInfo = subsequentInfo.appendData(translatedTitle, ", ");
            }
        }
    }
    
    if ((refData.type == 'book' && refData.kind == 'multivolume') || refData.type == 'series' && refData.kind == 'series') {
        seriesTitle = shared.getStringValue(refData.seriesTitle);
        seriesTitle = seriesTitle.replaceAmpersand();
        seriesTitle = seriesTitle.shortenName(isTrans).toTitleCase().italicize();

        if (seriesTitle.length > 0) {
            subsequentInfo = subsequentInfo.appendData(seriesTitle, ", ");   
        }
        else{
            volumeTitle = shared.getStringValue(refData.volumeTitle);

            if (volumeTitle.length > 0) {
                volumeTitle = volumeTitle.replaceAmpersand();
                volumeTitle = volumeTitle.shortenName(isTrans).toTitleCase().italicize();
                subsequentInfo = subsequentInfo.appendData(volumeTitle, ", "); 
            }
        }
    }

    if (refData.type == 'series' && refData.kind == 'volume') {
        volumeTitle = shared.getStringValue(refData.volumeTitle);

        if (volumeTitle.length > 0) {
            volumeTitle = volumeTitle.shortenName(isTrans).toTitleCase().italicize();
            subsequentInfo = subsequentInfo.appendData(volumeTitle, ", ");
        }
    }

    //weird, location is not at the end of the string
    let citation = shared.getCitations(citationData, firstInfo, subsequentInfo, refData);

    if (citation.first.endsWith('.')) {
        citation.first = citation.first.slice(0, -1);
    }

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);

    citation.first = citation.first.appendData(format, ', ');

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        citation.first = citation.first.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            citation.first = citation.first.appendData(url, ', ');
        }
    }

    if (!citation.first.endsWith('.')) {
        citation.first = citation.first + '.';
    }

    return citation;
}

function getTitlePart(refData){
    let titlePart = '';
    let isTrans = isTranslated(refData);

    //<em>Title of the Book: Subtitle of the Book</em>. [edition]. Edited by First Middle Last Suffix. Translated by First Middle Last Suffix. Illustrated by First Middle Last Suffix. Narrated by First Middle Last Suffix.

    let bookTitle = shared.getStringValue(refData.bookTitle);
    let chapterTitle = shared.getStringValue(refData.chapterTitle);
    let volumeTitle = shared.getStringValue(refData.volumeTitle);

    if (refData.type == 'bookChapter') {

        if (chapterTitle.startsWith('[')) {
            chapterTitle = shared.removeDescriptionFromTitle(chapterTitle);
        } else{

            chapterTitle = chapterTitle.replaceAmpersand();

            if (!isTrans) {
                chapterTitle = chapterTitle.toTitleCase();
            }
    
            if (!isGenericChapterTitle(chapterTitle)) {
                chapterTitle = chapterTitle.doubleQuote();
            }    
        }

        let translatedChapterTitle = shared.getStringValue(refData.translatedChapterTitle);

        if (translatedChapterTitle.length > 0) {
            
            translatedChapterTitle = translatedChapterTitle.replaceAmpersand();

            if (chapterTitle.length > 0) {
                translatedChapterTitle = translatedChapterTitle.bracket();
            }
            else{
                translatedChapterTitle = translatedChapterTitle.toTitleCase();
            }

            chapterTitle = chapterTitle.appendData(translatedChapterTitle);
        }
    }

    if (bookTitle.startsWith('[')) {
        bookTitle = bookTitle.removeBrackets();
    }
    else{

        bookTitle = bookTitle.replaceAmpersand();
        if ((isTrans && refData.type == 'bookChapter') || refData.kind == 'translated') {
            bookTitle = bookTitle.italicize();
        }
        else{
            bookTitle = bookTitle.toTitleCase().italicize();
        }
    }

    if (chapterTitle.length > 0) {

        if (isGenericChapterTitle(chapterTitle)) {
            titlePart = titlePart.appendData(chapterTitle);
            titlePart = titlePart.appendData(bookTitle, ' to ');
        }
        else{
            titlePart = titlePart.appendData(chapterTitle.addClosure());

            let translatorString  = '';
            translatorString = shared.getChapterTranslatorString(refData.contributors);

            if (translatorString.length > 0) {

                let primaryType = shared.getPrimaryContributorType(refData);

                if (primaryType != 'chapterTranslator') {
                    titlePart = titlePart.appendData(translatorString);
                    titlePart = titlePart.addClosure();
                }   
            }

            if (refData.type == 'bookChapter' && refData.kind == 'multivolume') {
                if (volumeTitle.length > 0) {
                    volumeTitle = volumeTitle.replaceAmpersand();
                    volumeTitle = volumeTitle.toTitleCase().italicize();
                    titlePart = titlePart.appendData(volumeTitle, ' In ');
                }
            }

            titlePart = titlePart.appendData(bookTitle, ' In ');
        }
    }
    else{
        titlePart = titlePart.appendData(bookTitle);
    }

    //translatedTitle
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        translatedTitle = translatedTitle.replaceAmpersand();
        // let isTranslated = isTranslated(refData);
        if (bookTitle.length == 0) {
            translatedTitle = translatedTitle.toTitleCase();
        }
        else{
            translatedTitle = translatedTitle.bracket();
        }
        
        titlePart = titlePart.appendData(translatedTitle);
    }

    titlePart = titlePart.addClosure();

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    
    if ((refData.kind == 'multivolume' && refData.type == 'book') || (refData.type == 'series' && refData.kind == 'series')) {
        
        seriesTitle = seriesTitle.replaceAmpersand();
        volumeTitle = volumeTitle.replaceAmpersand();

        seriesTitle = seriesTitle.toTitleCase().italicize();
        volumeTitle = volumeTitle.toTitleCase().italicize();

        if (seriesTitle.length > 0) {
            titlePart = titlePart.appendData(seriesTitle);
        }
        else{
            titlePart = titlePart.appendData(volumeTitle);
        }
        
        let volume = shared.getStringValue(refData.volume);
        let volumeSeperator = ', ';

        if (seriesTitle.length > 0) {
            if (volume.length > 0 && refData.type != 'series') {
                volume = 'Vol. ' + volume;
                titlePart = titlePart.appendData(volume, '. ');
            }
            else{
                volumeSeperator = '. ';
            }
        }

        if (seriesTitle.length > 0 && volumeTitle.length > 0) {
            titlePart = titlePart.appendData(volumeTitle, volumeSeperator);
        }
        else{
            titlePart = titlePart + '.';
        }
    }

    if (refData.type == 'series' && refData.kind == 'volume') {
        volumeTitle = volumeTitle.replaceAmpersand();
        volumeTitle = volumeTitle.toTitleCase().italicize();

        if (volumeTitle.length > 0) {
            titlePart = titlePart.appendData(volumeTitle);
            titlePart = titlePart + '.';
        }
    }

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();

    if (secondaryContributors.length > 0) {

        if ((refData.type == 'bookChapter')) {
            if (titlePart.endsWith(".")) {
                //replace period with comma
                titlePart = titlePart.slice(0, -1) + ",";
            }
            else{
                titlePart = titlePart + ',';
            }

            //lower the first letter in contribs
            secondaryContributors = secondaryContributors.charAt(0).toLowerCase() + secondaryContributors.slice(1);
        }
        else{
            if (titlePart.length > 0 && !titlePart.endsWith('.')) {

                if (titlePart.endsWith('</em>')) {
                    titlePart = titlePart + ',';
    
                    //lowercase the first letter of the contribs since this will have a comma
                    secondaryContributors = secondaryContributors.charAt(0).toLowerCase() + secondaryContributors.slice(1);
                }
                else{
                    titlePart = titlePart + '.';
                }
            }
        }

        titlePart = titlePart.appendData(secondaryContributors);
    }

    if (refData.type == 'book' && refData.kind == 'multivolume') {
        seriesTitle = shared.getStringValue(refData.seriesTitle);

        if (seriesTitle.length == 0) {
            let volume = shared.getStringValue(refData.volume);

            if (volume.length > 0) {
                volume = 'Vol. ' + volume;
                titlePart = titlePart.appendData(volume);
            }
        }
    }

    if (refData.type == 'bookChapter') {
        let referencePages = shared.getStringValue(refData.referencePages);

        if (referencePages.length > 0) {
            referencePages = shared.formatPageNumbers(referencePages, false);

            if (titlePart.endsWith('.')) {
                titlePart = titlePart.slice(0, -1);
            }

            titlePart = titlePart.appendData(referencePages, ', ', '', '', true).addClosure();
        }

        let volume = shared.getStringValue(refData.volume);

        if (refData.kind == 'multivolume') {
            
            if (volume.length > 0) {
                volume = 'Vol. ' + volume;
                titlePart = titlePart.appendData(volume);
            }

            seriesTitle = shared.getStringValue(refData.seriesTitle);

            if (seriesTitle.length > 0) {
                seriesTitle = seriesTitle.replaceAmpersand();
                seriesTitle = seriesTitle.toTitleCase().italicize();

                if (volume.length > 0) {
                    titlePart = titlePart.appendData(seriesTitle, ' of ');
                }
                else{
                    titlePart = titlePart.appendData(seriesTitle);
                }
            }
            else{
                if (volume.length > 0) {
                    titlePart = titlePart.addClosure();
                }
            }
        }
    }

    if (refData.type == 'series' && refData.kind == 'volume') {
        let seriesVolumeText = '';

        seriesTitle = shared.getStringValue(refData.seriesTitle);

        if (seriesTitle.length > 0) {
            seriesTitle = seriesTitle.replaceAmpersand();
            seriesTitle = seriesTitle.toTitleCase();
            seriesVolumeText = seriesTitle;
        }

        let seriesEditors = getEditorTypeString(refData.contributors, 'series');

        if (seriesEditors.length > 0) {
            if (seriesVolumeText.length > 0) {
                seriesVolumeText = seriesVolumeText + ',';
                seriesEditors = seriesEditors.lowerFirstLetter();
            }

            seriesVolumeText = seriesVolumeText.appendData(seriesEditors);
        }
        else{
            let volume = shared.getStringValue(refData.volume);

            if (volume.length > 0) {

                if (seriesVolumeText.length == 0) {
                    seriesVolumeText = seriesVolumeText.appendData('Vol.');
                }

                seriesVolumeText = seriesVolumeText.appendData(volume);
                seriesVolumeText = seriesVolumeText + '.';
            }
        }

        titlePart = titlePart.appendData(seriesVolumeText);
    }

    let edition = shared.getStringValue(refData.edition);
    edition = shared.fixEditionString(edition).addClosure();
    titlePart = titlePart.appendData(edition);

    if (refData.type == 'series') {

        volume = shared.getStringValue(refData.volume);

        if (volume.length > 0) {

            if (refData.kind == 'volume') {

                let seriesEditors = getEditorTypeString(refData.contributors, 'series');

                if (seriesEditors.length > 0) {
                    titlePart = titlePart.addClosure();
                    volume = 'Vol. ' + volume;
                    titlePart = titlePart.appendData(volume);
                }
            }
            else{
                volume = volume + ' vols.'
                titlePart = titlePart.appendData(volume);
            }
        }
    }

    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';
    let isTrans = isTranslated(refData);

    let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
    let originalPublicationDateYear = originalPublicationDate.getYear(originalPublicationDate);

    // if (refData.kind != 'translated') {
    if (!isTrans) {
        publicationPart = publicationPart.appendData(originalPublicationDateYear).addClosure();
    }

    let publisherString = '';

    if (shared.isSelfPublished(refData)) {

        //if we have a location, we need to add that in
        let publishers = [];
        let publisher = new Object();

        publisher.city = refData.publishers[0].city;
        publisher.state = refData.publishers[0].state;
        publisher.type = refData.publishers[0].type;
        publisher.name = '';

        publishers.push(publisher);

        publisherString = shared.getPublisherString(publishers);

        if (publisherString != 'N.p.') {
            publisherString = publisherString + ': self-published';
        }
        else{
            publisherString = 'Self-published';
        }
    }
    else{
        //City, State: Publisher 1; Publisher 2; Publisher 3, publicationDate.
        publisherString = shared.getPublisherString(refData.publishers);
    }


    // if (refData.kind != 'translated') {
    //if ((!isTrans && refData.type == 'bookChapter') || (refData.kind != 'translated' && refData.type != 'bookChapter')) {
    if (!isTrans) {
        if (originalPublicationDateYear.length > 0) {

            //lower case when following a comma
            if (publisherString == 'Self-published') {
                publisherString = 'self-published';
            }

            publisherString = 'Reprint, ' + publisherString;
        }
    }
    
    publicationPart = publicationPart.appendData(publisherString);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);   
        
        publicationPart = publicationPart.appendData(publicationDate, ', ');
    }
    else{
        if (originalPublicationDateYear.length > 0) {
            publicationPart = publicationPart.appendData(originalPublicationDateYear, ', ');
        }
        else{
            publicationPart = publicationPart.appendData('n.d.', ', ');
        }
    }

    publicationPart = publicationPart.addClosure();


    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

     // doi/url
     let url = shared.getStringValue(refData.url);
     let doi = shared.getStringValue(refData.doi);
 
     if (doi.length > 0) {
         doi = shared.appendDOIURL(doi).addClosure();
         locationPart = locationPart.appendData(doi);
     }
     else{
         if (url.length > 0) {
             url = shared.formatURL(url).addClosure();
             locationPart = locationPart.appendData(url);
         }
     }

    return locationPart;
}

// function isSelfPublished(refData){
//     let isSelf = false;

//     let primaryType = shared.getPrimaryContributorType(refData);
//     let primaryContributors = shared.getContributorsByType(primaryType, refData.contributors);

//     if (primaryContributors.length == 1 && refData.publishers.length == 1) {
//         if (primaryContributors[0].type == 'groupAuthor') {
//             if (primaryContributors[0].groupName == refData.publishers[0].name) {
//                 isSelf = true;
//             }
//         }
//     }

//     return isSelf;
// }

function isGenericChapterTitle(title){
    let isGeneric = false;
    let genericTitles = ['introduction', 'foreword', 'preface', 'afterword', 'prologue', 'epilogue', 'dedication', 'appendix'];

    isGeneric = genericTitles.includes(title.toLowerCase());

    return isGeneric;
}

function isTranslated(refData){

    let isTrans = false;

    //this keys off 2 things foreign as the kind, or the presence of a translator
    if (refData.kind == 'foreign' || refData.kind == 'translated') {
        isTrans = true;
    }

    let translators = shared.getContributorsByType('translator', refData.contributors);
    let chapterTranslators = shared.getContributorsByType('chapterTranslator', refData.contributors);

    if (translators.length > 0 || chapterTranslators.length > 0) {
        isTrans = true;
    }

    return isTrans;
}

function getVolumeEditors(contributors){
    let editorArray = [];

    contributors = shared.removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'volumeEditor') {
            editorArray.push(item);
        }
    });

    return editorArray;
}

function getSeriesEditors(contributors){
    let editorArray = [];

    contributors = shared.removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'editor') {
            editorArray.push(item);
        }
    });

    return editorArray;
}

function getEditorTypeString(contributors, type, isCitation){
    let editorString = '';

    if (typeof contributors === 'undefined') {
        return editorString;
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    contributors = shared.removeEmptyContributors(contributors);

    let editors = [];

    switch (type) {
        case 'series':
            editors = getSeriesEditors(contributors);
            break;
        case 'volume':
            editors = getVolumeEditors(contributors);
            break;
    }

    switch (editors.length) {
        case 0:
            return '';
            break;
        case 1:
            editorString = shared.getSecondaryReferenceName(editors[0]);
            break;
        case 2:
            editorString = shared.getSecondaryReferenceName(editors[0]) + ' and ' + shared.getSecondaryReferenceName(editors[1]);
            break;
        default:

            let contribStringArray = [];

            //throw the contrib strings in an array before we build
            let counter = 1;
            _forEach(editors, (contrib) => {
                let contribString = '';
                
                contribString = shared.getSecondaryReferenceName(contrib);

                contribStringArray.push(contribString);
                counter++;
            });

            counter = 1;
            contribStringArray.forEach(contrib => {
                if (counter > 1) {

                    if (counter == contribStringArray.length) {
                        editorString = editorString + ', and '
                    }
                    else{
                        editorString = editorString + ', ';
                    }
                }

                editorString = editorString + contrib;
                counter++;
            });

            break;
    }

    if (isCitation) {
        return 'ed. ' + editorString;
    }
    else{
        return 'Edited by ' + editorString;
    }
}