/*
*****AncientClassicWork*****
 
Documentation: TODO: Include a link to the Google Document for this type here
GOOGLE FORM: https://drive.google.com/open?id=1TJxlOLwx3NyKX9THrJWBzw5zEHwvh2ERmPx8yNT6_3A
WEBFLOW FORM: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-ancient-classic-work

-There isn't a type/kind for Ancient & Classic Works
[AuthorPart] [DatePart] [TitlePart] [SourcePart]

-AuthorPart
    - Author, Editors w/out Authors
- DatePart
    - Either a year or n.d.
-TitlePart
    - Title of the work, Editors, Translators, Edition
- SourcePart
    - Publishers, DOI/URL, Original Publication Date

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs){
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

	_forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            authorString = refData.bookTitle.toTitleCase().italicize();
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0 && (refData.type == 'reprinted')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/'+ year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    // - Title of the work, Editors, Translators, Edition
    let titlePart = '';

    let title = shared.getStringValue(refData.bookTitle);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = title.italicize();    
    }
    
    //translated title
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedTitle + ']';
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length >0) {
        editorString = '';
        translatorString = '';
    }

    //edition
    let edition = shared.getStringValue(refData.edition);

    let authorArray = shared.getAuthors(refData.contributors);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0||
        edition.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorTranslatorString.length > 0) {
            titlePart = titlePart + editorTranslatorString;
            isFirstNames = false;
        }

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + translatorString;    
            }
            else{
                titlePart = titlePart + '; ' + translatorString; 
            }
            isFirstNames = false;
        }

        if ((edition.length > 0) && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (edition.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            edition = shared.fixEditionString(edition);

            titlePart = titlePart + edition;
        }

        titlePart = titlePart + ')';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getDatePart(refData) {

    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Publishers, DOI/URL, Original Publication Date
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        doi = shared.appendDOIURL(doi);

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    //publication Date Original
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);

    if (publicationDateOriginal.length > 0) {
        sourcePart = sourcePart + ' (Original work published ' + publicationDateOriginal + ')';
    }

    return sourcePart;
}