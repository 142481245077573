const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

//Notes from breakdown with Graysen
/*
Type Question
Kind Question

-Type: Anthology (anthology)
    - Kind: (whole) All or part of an anthology
    - Kind: (foreign) A foreign or translated anthology
    - Kind: (republished) A republished anthology
    [Whole Author Part] [Date Part] [Title Part] [Source Part]

- Type: Entry  in an Anthology (entry)
    - Kind: (entry) An entry in an anthology
    - Kind: (foreign) An entry in a foreign or translated anthology 
    [Author Part] [Date Part] [Title Part] [Entry Source Part]

- Whole Author Part
    - Editors
- AuthorPart
    - Authors, Group Authors, Editors
- DatePart
    - Publication year
- TitlePart
    - Title of Book, Translated Title, Editors, Translators, Edition
- SourcePart
    - Publishers, DOI/URL, Original Publication Date
- Entry Source Part
    - "In ", Editors, Book Title, Page Numbers, Publishers, DOI/URL, Original Publication Date

*/


function getReference (refData){

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let refAuthorPart = '';

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let referenceValue = '';
    let datePart = getDatePart(refData);
    
    switch (type) {
        case 'anthology':
            // [Whole Author Part] [Date Part] [Title Part] [Source Part]
            let wholeAuthorPart = getWholeAuthorPart(refData);
            let sourcePart = getSourcePart(refData);
            let titlePart = getTitlePart(refData);

            refAuthorPart = wholeAuthorPart;
            
            if (wholeAuthorPart.length == 0) {
                //no contributors
                referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
            }
            else{
                referenceValue = wholeAuthorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
            }

            break;
        case 'entry':
            // [Author Part] [Date Part] [Entry Title Part] [Entry Source Part]
            let authorPart = getAuthorPart(refData);
            let entrySourcePart = getEntrySourcePart(refData);
            let entryTitlePart = getEntryTitlePart(refData);

            refAuthorPart = authorPart;

            if (authorPart.length == 0) {

                entryTitlePart = shared.fixTitlePartClosure(entryTitlePart);

                //no contributors
                referenceValue = entryTitlePart + ' ' + datePart + ' ' + entrySourcePart;  
            }
            else{
                referenceValue = authorPart + ' ' + datePart + ' ' + entryTitlePart + ' ' + entrySourcePart;
            }

            break;
    }

    let reference = {
        value : referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: refAuthorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs){
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        if (refData.type == 'entry') {
            authorString = '"' + refData.entryTitle.toTitleCase() + '," ';
        }
        else{
            let editorArray = shared.getEditors(refData.contributors);

            if (editorArray.length > 0) {
                authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
            }
            else{
                authorString = refData.anthologyTitle.toTitleCase().italicize();
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0 && (refData.type == 'reprinted')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/'+ year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getDatePart(refData) {

    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getEntryTitlePart(refData){
    //Entry title [Translated entry title] (A. A. Translatora, Trans.).
    let entryTitle = shared.getStringValue(refData.entryTitle);
    let translatedEntryTitle = shared.getStringValue(refData.translatedEntryTitle);
    let translatorString = shared.getTranslatorString(refData.contributors);

    let titlePart = entryTitle;

        if (translatedEntryTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedEntryTitle + ']';
    }

    if (translatorString.length > 0) {
        titlePart = titlePart + ' (' + translatorString + ').';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getEntrySourcePart(refData){
    //In A. A. Editora (Ed.), <em>Anthology title</em> [Translated anthology title] (2nd ed., pp. 7–13). Publisher 1. https://doi.org.10.123456789 (Original work published 1945)"
    let entrySourcePart = 'In';

    let title = shared.getStringValue(refData.anthologyTitle);
    let translatedTitle = shared.getStringValue(refData.translatedAnthologyTitle);
    let edition = shared.getStringValue(refData.edition);
    let pageNumbers = shared.getStringValue(refData.pageNumbers);
    let normalSourcePart = getSourcePart(refData);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ' (Ed.)';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ' (Eds.)';
        }
    }

    if (editorString.length > 0) {
        entrySourcePart = entrySourcePart + ' ' + editorString + ',';
    }

        if (title.length > 0) {
        entrySourcePart = entrySourcePart + ' ' + title.italicize();
    }

    if (translatedTitle.length > 0) {
        entrySourcePart = entrySourcePart + ' [' + translatedTitle + ']';
    }

    if (edition.length > 0 || pageNumbers.length > 0) {
        entrySourcePart = entrySourcePart + ' (';

        if (edition.length > 0) {

            edition = shared.fixEditionString(edition);

            entrySourcePart = entrySourcePart + edition;
        }

        if (edition.length > 0 && pageNumbers.length > 0) {
            entrySourcePart = entrySourcePart + ', ';  
        }

        if (pageNumbers.length > 0) {
            let pageNumbersFormatted = shared.formatPageNumbers(pageNumbers);

            entrySourcePart = entrySourcePart + pageNumbersFormatted;
        }
    
        entrySourcePart = entrySourcePart + ')'
    }

    entrySourcePart = entrySourcePart + '.'

    //append our normal source part on here
    entrySourcePart = entrySourcePart + ' ' + normalSourcePart;

    return entrySourcePart;
}

function getTitlePart(refData){
    // - Title of Book, Translated Title, Editors, Translators, Edition
    let titlePart = '';

    let title = shared.getStringValue(refData.anthologyTitle);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = title.italicize();    
    }
    
    //translated title
    let translatedTitle = shared.getStringValue(refData.translatedAnthologyTitle);

    if (translatedTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedTitle + ']';
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length >0) {
        editorString = '';
        translatorString = '';
    }

    //edition
    let edition = shared.getStringValue(refData.edition);

    let authorArray = shared.getAuthors(refData.contributors);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0||
        edition.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorTranslatorString.length > 0) {
            titlePart = titlePart + editorTranslatorString;
            isFirstNames = false;
        }

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                titlePart = titlePart + translatorString;    
            }
            else{
                titlePart = titlePart + '; ' + translatorString; 
            }
            isFirstNames = false;
        }

        if ((edition.length > 0) && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (edition.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            edition = shared.fixEditionString(edition);

            titlePart = titlePart + edition;
        }

        titlePart = titlePart + ')';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getWholeAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors
        if (editorArray.length > 0) {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getSourcePart(refData){
    //Publishers, DOI/URL, Original Publication Date
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        doi = shared.appendDOIURL(doi);

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    //publication Date Original
    let publicationDateOriginal = shared.getStringValue(refData.originalPublicationDate);

    if (publicationDateOriginal.length > 0) {
        sourcePart = sourcePart + ' (Original work published ' + publicationDateOriginal + ')';
    }

    return sourcePart;
}
