/*
*****BillsResolutions*****
 
Documentation: https://docs.google.com/spreadsheets/d/16R6dnWoehlWQXXDOq4QLM8YNp_lqvYm2qtCRj2Vh8tM/edit#gid=2071428744
Mockup: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-bills-resolutions

Type & Kind Questions
- Types: 
    enactedFed
        - this is for enacted federal bills & resolutions
    unenactedFed
        - this is for unenacted federal bills & resolutions
- Kinds:
    senate
        - this is for enactedFed & unenactedFed.
    house
        - this is for enactedFed & unenactedFed types.
PARTS
    - title: the title of an unenacted bill
    - shortTitle: an optional shortened title to be used for the citation
    - billNumber: the number of the bill
    - volume: the volume of the Congressional Record
    - pageNumber: the starting page number for the record
    - session: the session of congress when it came up.  The user is prompted to input the ordinal number for the session.  If the session doesn't end with a letter (-th, -st, -rd), then we should add it for them.
    - publicationDate: the year the bill/resolution was acted on
    - url: url for the bill/resolution

enactedFed senate
    - S. Res. [billNumber], [session] Cong., [volume] Cong. Rec. [pageNumber] ([publicationDate]) (enacted). [url]

enactedFed house
    - H.R. Res. [billNumber], [session] Cong., [volume] Cong. Rec. [pageNumber] ([publicationDate]) (enacted). [url]

uneancted senate
    - [title], S. [billNumber], [session] Cong. ([publicationDate]). [url]

unenacted house
    - [title], H.R. [billNumber], [session] Cong. ([publicationDate]). [url]

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    //legal types are their own beasts.....we won't break them into pieces
    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    switch (type) {
        case 'enactedFed':

            if (kind == 'senate' || kind == 'senateJoint') {
                referenceValue = getEnactedSenate(refData);
            }

            if (kind == 'house' || kind == 'houseJoint') {
                referenceValue = getEnactedHouse(refData);
            }
            
            break;
        case 'unenactedFed':

            if (kind == 'senate') {
                referenceValue = getUnenactedSenate(refData);
            }

            if (kind == 'house') {
                referenceValue = getUnenactedHouse(refData);
            }

            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);
    let authorString = '';

    let title = shared.getStringValue(refData.title);
    let shortTitle = shared.getStringValue(refData.shortTitle);
    let year = shared.getYear(refData.publicationDate);

    if (type == 'enactedFed') {
        title = '';
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (shortTitle.length > 0) {
        title = shortTitle;
    }

    if (title.length > 0) {
        authorString = title;
    }
    else{
        let billNumber = shared.getStringValue(refData.billNumber);

        switch (type) {
            case 'enactedFed':
    
                if (kind == 'senate' || kind == 'senateJoint') {
                    authorString = 'S. Resolution';

                    if (billNumber.length > 0) {
                        authorString = authorString + ' ' + billNumber;
                    }
                }
    
                if (kind == 'house' || kind == 'houseJoint') {
                    authorString = 'H.R. Resolution';

                    if (billNumber.length > 0) {
                        authorString = authorString + ' ' + billNumber;
                    }
                }
    
                break;
            case 'unenactedFed':
    
                if (kind == 'senate') {
                    authorString = 'S.';

                    if (billNumber.length > 0) {
                        authorString = authorString + ' ' + billNumber;
                    }
                }
    
                if (kind == 'house') {
                    authorString = 'H.R.';

                    if (billNumber.length > 0) {
                        authorString = authorString + ' ' + billNumber;
                    }
                }
    
                break;
        }

    }

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}

function getEnactedSenate(refData){
    //- S. Res. [billNumber], [session] Cong., [volume] Cong. Rec. [pageNumber] ([publicationDate]) (enacted). [url]
    //S. Res. 123, 100th Cong., 456 Cong. Rec. 789 (2018) (enacted). https://www.perrla.com/resolution
    let value = '';
    let billNumber = shared.getStringValue(refData.billNumber);
    let session = shared.getStringValue(refData.session);
    let volume = shared.getStringValue(refData.volume);
    let pageNumber = shared.getStringValue(refData.pageNumber);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    let label = 'S. Res.';

    if (refData.kind == 'senateJoint') {
        label = 'S.J. Res.';
    }

    if (billNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ', ';
        }

        value = value + label + ' ' + billNumber;
    }
    
    if (session.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }

        session = shared.getOrdinalValue(session);

        value = value + ' ' + session;
        value = value + ' Cong.'
    }

    if (volume.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }
        value = value + ' ' + volume;
    }

    if (pageNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ' ';
        }

        value = value + 'Cong. Rec. ' + pageNumber;
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
        value = value + '(' + publicationDate + ')';
    }
    else{
        value = value + '(n.d.)';
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    value = value + '(enacted).'

    if (url.length > 0) {
        url = shared.formatURL(url);
        value = value + ' ' + url;
    }

    return value;
}

function getUnenactedSenate(refData){
    // Title of the Resolution Act, S. 123, 100th Cong. (2018). https://www.perrla.com/resolution
    // - [title], S. [billNumber], [session] Cong. ([publicationDate]). [url]
    let value = '';
    let title = shared.getStringValue(refData.title);
    let billNumber = shared.getStringValue(refData.billNumber);
    let session = shared.getStringValue(refData.session);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        value = title;
    }

    if (billNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ', ';
        }

        value = value + 'S. ' + billNumber;
    }

    if (session.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }

        session = shared.getOrdinalValue(session);

        value = value + ' ' + session;
        value = value + ' Cong.'
    }else{
        if (value.length > 0) {
            value = value +  '.';
        } 
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
        value = value + '(' + publicationDate + ').';
    }
    else{
        value = value + '(n.d.).';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        value = value + ' ' + url;
    }

    return value;
}

function getEnactedHouse(refData){
    //- H.R. Res. [billNumber], [session] Cong., [volume] Cong. Rec. [pageNumber] ([publicationDate]) (enacted). [url]
    //H.R. Res. 123, 100th Cong., 456 Cong. Rec. 789 (2018) (enacted). https://www.perrla.com/resolution"
    let value = '';
    let billNumber = shared.getStringValue(refData.billNumber);
    let session = shared.getStringValue(refData.session);
    let volume = shared.getStringValue(refData.volume);
    let pageNumber = shared.getStringValue(refData.pageNumber);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    let label = 'H.R. Res.';

    if (refData.kind == 'houseJoint') {
        label = 'H.R.J. Res.';
    }

    if (billNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ', ';
        }

        value = value + label + ' ' + billNumber;
    }
    
    if (session.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }

        session = shared.getOrdinalValue(session);

        value = value + ' ' + session;
        value = value + ' Cong.'
    }

    if (volume.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }
        value = value + ' ' + volume;
    }

    if (pageNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ' ';
        }

        value = value + 'Cong. Rec. ' + pageNumber;
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
        value = value + '(' + publicationDate + ')';
    }
    else{
        value = value + '(n.d.)';
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    value = value + '(enacted).'

    if (url.length > 0) {
        url = shared.formatURL(url);
        value = value + ' ' + url;
    }

    return value;
}

function getUnenactedHouse(refData){
    //- [title], H.R. [billNumber], [session] Cong. ([publicationDate]). [url]
    //Title of the Resolution Act Which is Really Long, H.R. 123, 100th Cong. (2018). https://www.perrla.com/resolution
    let value = '';
    let title = shared.getStringValue(refData.title);
    let billNumber = shared.getStringValue(refData.billNumber);
    let session = shared.getStringValue(refData.session);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        value = title;
    }

    if (billNumber.length > 0) {
        if (value.length > 0) {
            value = value +  ', ';
        }

        value = value + 'H.R. ' + billNumber;
    }
    
    if (session.length > 0) {
        if (value.length > 0) {
            value = value +  ',';
        }

        session = shared.getOrdinalValue(session);

        value = value + ' ' + session;
        value = value + ' Cong.'
    }else{
        if (value.length > 0) {
            value = value +  '.';
        } 
    }

    if (value.length > 0) {
        value = value +  ' ';
    }

    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
        value = value + '(' + publicationDate + ').';
    }
    else{
        value = value + '(n.d.).';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        value = value + ' ' + url;
    }

    return value;
}