const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    
    let type = shared.getStringValue(refData.type);    

    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);

    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);

    let authorPart = '';    
    let getAuthorPart = shared.getAuthorPart(refData);    

    if(getAuthorPart.endsWith('.')) {
        getAuthorPart = getAuthorPart.substring(0, getAuthorPart.length - 1);
    }
    if(type === 'testimony'){
        authorPart = authorPart.appendData(getAuthorPart, '', 'statement of');
        authorPart = authorPart.parenthesis();
        authorPart = authorPart.addClosure();

    } else {
        referenceValue = referenceValue.addClosure();

    }
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';
    
    let type = shared.getStringValue(refData.type);

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();


    let committeeName = shared.getStringValue(refData.committeeName);
    committeeName = committeeName.italicize();    
    if(type === 'hearing'){
        titlePart = titlePart.appendData(committeeName, ' ', 'Hearing before the');
    } else {
        titlePart = titlePart.appendData(committeeName);
    }

    // session
    let session = shared.getStringValue(refData.session);
    session = shared.getOrdinalValue(session);
    
    if(committeeName.length > 0){
        titlePart = titlePart.appendData(session, ', ', '', 'Cong.');

    } else {
        titlePart = titlePart.appendData(session, ' ', '', 'Cong.');

    }
    
    // sessionPart
    let sessionPart = shared.getStringValue(refData.sessionPart);
    sessionPart = shared.getOrdinalValue(sessionPart);
    titlePart = titlePart.appendData(sessionPart, ', ', '', 'sess.');

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let kind = shared.getStringValue(refData.kind);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationDate = publicationDate.parenthesis();
    publicationPart = publicationPart.appendData(publicationDate, ', ');

    if(kind.length === 0){
        publicationPart = publicationPart.addClosure();
    }

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // url
    let url = shared.getStringValue(refData.url);
    
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
        locationPart = locationPart.addClosure();
    }
    

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);

    let workTitle = shared.getStringValue(refData.title);
    
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    let committeeName = shared.getStringValue(refData.committeeName);
    committeeName = committeeName.italicize();    

    if(type === 'hearing'){
        firstInfo = firstInfo.appendData(committeeName, ', ', 'hearing before the');
    } else {
        firstInfo = firstInfo.appendData(committeeName, ', ');
    }

    // session
    let session = shared.getStringValue(refData.session);
    session = shared.getOrdinalValue(session);
    firstInfo = firstInfo.appendData(session, ', ', '', 'Cong.');

    // sessionPart
    let sessionPart = shared.getStringValue(refData.sessionPart);
    sessionPart = shared.getOrdinalValue(sessionPart);
    firstInfo = firstInfo.appendData(sessionPart, ', ', '', 'sess.');

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(refData.publicationDate);
    publicationDate = publicationDate.parenthesis();
    firstInfo = firstInfo.appendData(publicationDate, ', ');

    let authorPart = '';

    let getAuthorPart = shared.getAuthorPart(refData);    

    if(getAuthorPart.endsWith('.')) {
        getAuthorPart = getAuthorPart.substring(0, getAuthorPart.length - 1);
    }
    
    if(type === 'testimony'){
        authorPart = authorPart.appendData(getAuthorPart, '', 'statement of');
        authorPart = authorPart.parenthesis();
    }
    
    firstInfo = firstInfo.appendData(authorPart);

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }


    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation

    let shortWorkTitle = shared.getStringValue(refData.shortTitle);
    if(shortWorkTitle.length > 0){
        shortWorkTitle = shortWorkTitle.replaceAmpersand().toTitleCase().italicize();

    } else {
        // there isn't a shortTitle found to use title
        shortWorkTitle = shared.getStringValue(refData.title);

        if (shortWorkTitle.startsWith('[')) {
            shortWorkTitle = shared.removeDescriptionFromTitle(shortWorkTitle);
        } else{
            shortWorkTitle = shortWorkTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
        }
    }

    subsequentInfo = subsequentInfo.appendData(shortWorkTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}