const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.court);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    let titleProperty = 'court';
    let isSecondaryTitle = false;
    let noFormatting = true;

    //court
    let court = shared.getStringValue(refData.court);
    court = courtAbbreviationToFull(court);

    var array = court.split(",");

    if (array.length > 1) {
        court = array[0].trim();
    }

    let title= shared.getStringValue(refData.title);
    
    if (court.length == 0) {

        if (title.length > 0) {
            array = title.split("v.");

            if (array.length > 1) {
                title  = array[0].trim();
            }
            
            titleProperty = 'title';
            isSecondaryTitle = false;
            noFormatting = false;
        }
    }

    let fakeRefData = new Object();
    fakeRefData.court = court;
    fakeRefData.title = title;
    fakeRefData.contributors = []; //contribs arent listed in the citation here

    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle, noFormatting);
    let citation = shared.getCitations(citationData, primaryContributor);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    //court
    let court = shared.getStringValue(refData.court);
    court = courtAbbreviationToFull(court);
    primaryPart = primaryPart.appendData(court, ', ', '', '');

    let title= shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.italicize();
        }

        primaryPart = primaryPart.appendData(title, '. ', '', '');
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let citations = getCitationString(refData.citation);
    container2 = container2.appendData(citations, ', ');

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container2 = container2.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container2 = container2.appendData(url, ', ');
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

function getCitationString(citations){
    var citationString = '';

    if (typeof citations === 'undefined') {
        return citationString;
    }

    if (citations.length > 0) {

        //MLA only uses the first one
        //<em>[citation.series]</em>, vol. [citation.volume], p. [citation.pageNumber]

        let citation = citations[0];

        let volume = shared.getStringValue(citation.volume);
        let series = shared.getStringValue(citation.series);
        let pageNumber = shared.getStringValue(citation.pageNumber);

        if (series.length > 0) {
            series = seriesAbbreviationToFull(series);
            series = series.italicize();
            citationString = citationString.appendData(series, ', ');
        }

        if (volume.length > 0) {
            citationString = citationString.appendData(volume, ', ', 'vol.');
        }

        if (pageNumber.length > 0) {
            pageNumber = shared.formatPageNumbers(pageNumber);
            citationString = citationString.appendData(pageNumber, ', ');
        }

    }

    return citationString;
}

function courtAbbreviationToFull(abbreviation){
    //APA is entered in abbreviations...if they entered and abbreviation...convert it

    let word = '';

    var conversions = [
        ["Alabama State, Supreme Court", "Ala."],
        ["Alabama State, Court of Civil Appeals", "Ala. Civ. App."],
        ["Alabama State, Court of Criminal Appeals", "Ala. Crim. App."],
        ["Alaska State, Supreme Cout", "Alaska"],
        ["Alaska State, Court of Appeals", "Alaska Ct. App."],
        ["Arizona State, Surpreme Courts", "Ariz."],
        ["Arizona State, Court of Appeals", "Ariz. Ct. App."],
        ["Arkansas State, Supreme Court", "Ark."],
        ["Arkansas State, Court of Appeals", "Ark. Ct. App."],
        ["California State, Supreme Court", "Cal."],
        ["California State, Court of Appeal", "Cal. Ct. App."],
        ["Colorado State, Supreme Court", "Colo."],
        ["Colorado State, Court of Appeals", "Colo. App."],
        ["Connecticut State, Supreme Court", "Conn."],
        ["Connecticut State, Appellate Court", "Conn. App. Ct."],
        ["Delaware State, Supreme Court", "Del."],
        ["Delaware State, Court of Chancery", "Del. Ch."],
        ["District of Columbia Court of Appeals", "D.C."],
        ["Florida State, Supreme Court", "Fla."],
        ["Florida State, District Court of Appeal", "Fla. Dist. Ct. App."],
        ["Georgia State, Supreme Court", "Ga."],
        ["Georgia State, Court of Appeals", "Ga. Ct. App."],
        ["Hawaii State, Supreme Court", "Haw."],
        ["Hawaii State, Court of Appeals", "Haw. Ct. App."],
        ["Idaho State, Supreme Court", "Idaho"],
        ["Idaho State, Court of Appeals", "Idaho Ct. App."],
        ["Illinois State, Supreme Court", "Ill."],
        ["Illinois State, Appellate Court", "Ill. App. Ct."],
        ["Indiana State, Supreme Court", "Ind."],
        ["Indiana State, Court of Appeals", "Ind. Ct. App."],
        ["Iowa State, Supreme Court", "Iowa"],
        ["Iowa State, Court of Appeals", "Iowa Ct. App."],
        ["Kansas State, Supreme Court", "Kan."],
        ["Kansas State, Court of Appeals", "Kan. Ct. App."],
        ["Kentucky State, Supreme Court", "Ky."],
        ["Kentucky State, Court of Appeals", "Ky. Ct. App."],
        ["Louisiana State, Supreme Court", "La."],
        ["Louisiana State, Court of Appeal", "La. Ct. App."],
        ["Maine State, Supreme Court", "Me."],
        ["Maryland State, Supreme Court", "Md."],
        ["Maryland State, Court of Special Appeals", "Md. Ct. Spec. App."],
        ["Massachusetts State, Supreme Judicial Court", "Mass."],
        ["Massachusetts State, Appeals Court", "Mass. App. Ct."],
        ["Michigan State, Supreme Court", "Mich."],
        ["Michigan State, Court of Appeals", "Mich. Ct. App."],
        ["Minnesota State, Supreme Court", "Minn."],
        ["Minnesota State, Court of Appeals", "Minn. Ct. App."],
        ["Mississippi State, Supreme Court", "Miss."],
        ["Mississippi State, Court of Appeals", "Miss. Ct. App."],
        ["Missouri State, Supreme Court", "Mo."],
        ["Missouri State, Court of Appeals", "Mo. Ct. App."],
        ["Montana State, Supreme Court", "Mont."],
        ["Nebraska State, Supreme Court", "Neb."],
        ["Nebraska State, Court of Appeals", "Neb. Ct. App."],
        ["Nevada State, Supreme Court", "Nev,"],
        ["New Hampshire State, Supreme Court", "N.H."],
        ["New Jersey State, Supreme Court", "N.J."],
        ["New Jersey State, Superior Court, Appellate Divsion", "N.J. Super. Ct. App. Div."],
        ["New Mexico State, Supreme Court", "N.M."],
        ["New Mexico State, Court of Appeals", "N.M. Ct. App."],
        ["New York State, Court of Appeals", "N.Y."],
        ["New York State, Supreme Court, Appellate Division", "N.Y. App. Div."],
        ["North Carolina State, Supreme Court", "N.C."],
        ["North Carolina State, Court of Appeals", "N.C. Ct. App."],
        ["North Dakota State, Supreme Court", "N.D."],
        ["Court of Appeals of North Dakota", "N.D. Ct. App."],
        ["Ohio State, Supreme Court", "Ohio"],
        ["Ohio State, Court of Appeals", "Ohio Ct. App."],
        ["Oklahoma State, Supreme Court", "Okla."],
        ["Oklahoma State, Court of Criminal Appeals", "Okla. Crim. App."],
        ["Oklahoma State, Court of Civil Appeals", "Okla. Civ. App."],
        ["Oregon State, Supreme Court", "Or."],
        ["Oregon State, Court of Appeals", "Or. Ct. App."],
        ["Pennsylvania State, Supreme Court", "Pa."],
        ["Pennsylvania State, Superior Court", "Pa. Super. Ct."],
        ["Rhode Island State, Supreme Court", "R.I."],
        ["South Carolina State, Supreme Court", "S.C."],
        ["South Carolina State, Court of Appeals", "S.C. Ct. App."],
        ["South Dakota State, Supreme Court", "S.D."],
        ["Tennessee State, Supreme Court", "Tenn."],
        ["Tennessee State, Court of Appeals", "Tenn. Ct. App."],
        ["Texas State, Supreme Court", "Tex."],
        ["Texas State, Court of Criminal Appeals", "Tex. Crim. App."],
        ["Texas State, Court of Appeals", "Tex. App."],
        ["Texas State, Court of Appeals", "Tex. Ct. App."],
        ["Utah State, Supreme Court", "Utah"],
        ["Utah State, Court of Appeals", "Utah Ct. App."],
        ["Vermont State, Supreme Court", "Vt."],
        ["Virginia State, Supreme Court", "Va."],
        ["Virginia State, Court of Appeals", "Va. Ct. App."],
        ["Washington State, Supreme Court", "Wash."],
        ["Washington State, Court of Appeals", "Wash. Ct. App."],
        ["West Virginia State, Supreme Court of Appeals", "W. Va."],
        ["Wisconsin State, Supreme Court", "Wis."],
        ["Wisconsin State, Court of Appeals", "Wis. Ct. App."],
        ["Wyoming State, Supreme Court", "Wyo."]
    ];

    for (let index = 0; index < conversions.length; index++) {
        let thisConversion = conversions[index][1];

        if (thisConversion.toLowerCase() == abbreviation.toLowerCase()) {
            word = conversions[index][0];
        }
    }    

    if (word == '') {
        //if we havent converted yet, just leave it as is
        word = abbreviation;
    }

    return word;
}

function seriesAbbreviationToFull(abbreviation){
    //APA is entered in abbreviations...if they entered and abbreviation...convert it

    let word = '';

    var conversions = [
        ['A.', 'Atlantic Reporter'],
        ['A.2d', 'Atlantic Reporter, Second Series'],
        ['Cal. Rptr.', 'California Reporter'],
        ['Cal. Rptr. 2d', 'California Reporter, Second Series'],
        ['N.Y.S.', 'New York Supplement'],
        ['N.Y.S.2d', 'New York Supplement, Second Series'],
        ['N.E.', 'North Eastern Reporter'],
        ['N.E.2d', 'North Eastern Reporter, Second Series'],
        ['N.W.', 'North Western Reporter'],
        ['N.W.2d', 'North Western Reporter, Second Series'],
        ['P.', 'Pacific Reporter'],
        ['P.2d', 'Pacific Reporter, Second Series'],
        ['P.3d', 'Pacific Reporter, Third Series'],
        ['S.E.', 'South Eastern Reporter'],
        ['S.E.2d', 'South Eastern Reporter, Second Series'],
        ['So.', 'Southern Reporter'],
        ['So.2d', 'Southern Reporter, Second Series'],
        ['S.W.', 'South Western Reporter'],
        ['S.W.2d', 'South Western Reporter, Second Series'],
        ['S.W. 3d', 'South Western Reporter, Third Series']
    ];

    for (let index = 0; index < conversions.length; index++) {
        let thisConversion = conversions[index][0];

        if (thisConversion.toLowerCase() == abbreviation.toLowerCase()) {
            word = conversions[index][1];
        }
    }    

    if (word == '') {
        //if we havent converted yet, just leave it as is
        word = abbreviation;
    }

    return word;
}