const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let isEntryDesctiption = false;

    let entry = shared.getStringValue(refData.entry);
    if (entry.startsWith('[')) {
        isEntryDesctiption = true;
        entry = shared.removeDescriptionFromTitle(entry);
    } else{
        entry = entry.replaceAmpersand().toTitleCase().doubleQuote();
    }
    
    titlePart = titlePart.appendData(entry, ", ", '', 'in');

    let workTitle = shared.getStringValue(refData.title);
    
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        if(isEntryDesctiption){
            workTitle = workTitle.toLowerCase();
        }
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }

    
    
    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let version = shared.getStringValue(refData.version);
    titlePart = titlePart.appendData(version, ' ', 'V.');
    titlePart = titlePart.addClosure();


    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);
    if (publisherString.length == 0) {
        publisherString = 'N.p.';
    }

    publicationPart = publicationPart.appendData(publisherString);

    let publicationDate = shared.getStringValue(refData.publicationDate);

    if(publicationDate === ''){
        publicationPart = publicationPart.appendData('n.d.', ', ');
    } else {
        publicationDate = shared.getFullDate(publicationDate);   
        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.addClosure();
    }

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //platform
    let platform = shared.getStringValue(refData.platform);
    platform = shared.fixFormatString(platform);
    locationPart = locationPart.appendData(platform).addClosure();

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let isEntryDesctiption = false;
    
    let entry = shared.getStringValue(refData.entry);
    if (entry.startsWith('[')) {
        isEntryDesctiption = true;
        entry = shared.removeDescriptionFromTitle(entry);
        if(authorPart.length > 0){
            entry = entry.toLowerCase();
        }
    } else{
        entry = entry.replaceAmpersand().toTitleCase().doubleQuote();
    }
    firstInfo = firstInfo.appendData(entry, ", ", '', 'in');

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        if(isEntryDesctiption){
            workTitle = workTitle.toLowerCase();
        }
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }

    if(entry.length === 0){
        firstInfo = firstInfo.appendData(workTitle, ", ");
    } else {
        firstInfo = firstInfo.appendData(workTitle);        
    }
    
    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let version = shared.getStringValue(refData.version);
    version = version.toLowerCase();
    firstInfo = firstInfo.appendData(version, ', ', 'v.');

    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false);
    if (publisherLocationString.length == 0) {
        publisherLocationString = 'n.p.';
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';

    publisherPart = publisherPart.appendData(publisherLocationString);
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    publisherPart = publisherPart.parenthesis();
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let platform = shared.getStringValue(refData.platform);
    firstInfo = firstInfo.appendData(platform, ', ');

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);
    
    if(entry.length === 0){
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase();
        } else{
            workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();

        }

        
    } else {
        workTitle = entry;
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}