/*
*****Interview*****
 This was added after Turabian, and is based off of Personal Communications logic
*/

const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.description);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'description';
    let isSecondaryTitle = false;

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let primaryContributor = '';

    if (refData.type == 'archive') {
        let fakeRefData = new Object();
        fakeRefData.contributors = shared.getContributorsByType('editor', refData.contributors);
        fakeRefData.description = refData.description;

        primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle, true);
    }
    else{
        primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle, true);
    }
    
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    if (citation.first.length == 0) {
        citation.first = 'Interview';
    }

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    if (refData.type == 'archive') {
        let editors = shared.getContributorsByType('editor', refData.contributors);
        primaryPart = shared.getPrimaryContributorString(refData, true, 'editor');
    }
    else{
        let primaryContributor = shared.getPrimaryContributorString(refData);
        primaryPart = primaryContributor;
    }

    let title = shared.getStringValue(refData.description);

	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		}
	}

    if (title.length > 0) {

        title = title.replaceAmpersand();
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = 'Interview';

    if (refData.type == 'archive') {
        let authors = shared.getContributorsByType('author', refData.contributors);

        if (authors.length > 0) {
            let authorString = shared.getSecondaryContributorTypeString(authors, 'author');

            if (authorString.length > 0) {
                firstSupplemental = firstSupplemental + ' with ' + authorString.replace('by ', '');
            }
        }
    }
    
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    //publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    container2 = container2.appendData(publisherString);

	container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}