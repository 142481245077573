const _forEach = require('lodash/forEach');
const dateHelper = require('../dateHelper');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReferenceNameString,
    getCitationAuthorString,
    getReferenceName,
    getCitationName,
    getReverseCitationName,
    getStringValue,
    getYear,
    getCitationLocationString,
    getPublisherString,
    getGroupPerformerString,
    getEditorsAtStartofRefString,
    getEditors,
    getChairs,
    getAuthors,
    getPerformers,
    getChapterTranslatorString,
    getTranslatorString,
    getNarratorString,
    getIllustratorString,
    getTranslators,
    getChapterTranslators,
    getEditorTranslatorString,
    getOriginalEditorString,
    getOriginalPublisherString,
    appendDOIURL,
    fixPageDash,
    getOrderByValue,
    getFullDate,
    getFullDateRange,
    formatRetrievalDate,
    getCitations,
    formatPageNumbers,
    stripSubtitle,
    getReverseCitationAuthorString,
    getReverseFullDate,
    fixEditionString,
    fixTitlePartClosure,
    isInteger,
    removingLeadingZeros,
    fixTimeCitationData,
    getOrdinalValue,
    addCommasToNumber,
    formatURL,
    limitWordsInString,
    removeEmptyContributors,
    getContributorsByType
}

function getContributorsByType(type, contributors, matchSimilarTypes){
    let typeContribs = [];

    if (typeof contributors == 'undefined'){
        return typeContribs;
    }

    //sometimes we will want to grab all authors, users, group authors, etc as the same, sometimes we won't
    if (typeof matchSimilarTypes == 'undefined'){
        matchSimilarTypes = true;
    }

    _forEach(contributors, (item) => {
        if (item.type == type) {
            typeContribs.push(item);
        }

        if (matchSimilarTypes) {
            //for authors. we also need to group in Group Authors and Usernames
            if (type == 'author') {
                if (item.type == 'groupAuthor' || item.type == 'userName' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'groupAuthor') {
                if (item.type == 'author' || item.type == 'userName' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'userName') {
                if (item.type == 'groupAuthor' || item.type == 'author' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'chapterAuthor') {
                if (item.type == 'groupAuthor' || item.type == 'author' || item.type == 'userName') {
                    typeContribs.push(item);
                }
            }
        }

    });

    return typeContribs;
}

function getOrderByValue(referenceValue){

    if (referenceValue == '') {
        return '';
    }

    let orderByValue = referenceValue;
    
    //before we remove any tags, replace anything within italics and is a number with text
    //<em>22 searches for Sasquatch</em>
    //<em>twenty two searches for Sasquatch</em>

    var words = orderByValue.split(" ");
    let inItalics = false;
    for (var i = 0; i < words.length; i += 1) {
        if (words[i].startsWith('<em>')) {
            inItalics = true;
            words[i] = words[i].replace('<em>', '');
        }

        if (inItalics) {
            words[i] = words[i].replace(':', '');
            words[i] = convertNumberToText(words[i]);
        }

        if (words[i].endsWith('</em>')) {
            inItalics = false;
            words[i] = words[i].replace('</em>', '');
        }
    }

    orderByValue = words.join(' ');

    //now split back out and remove anything in brackets (like user names)
    words = orderByValue.split(" ");
    let bracketlessArray = [];
    let inBrackets = false;

    for (var i = 0; i < words.length; i += 1) {

        if (i > 0) {
            if (words[i].startsWith('[')) {
                inBrackets = true;
            }    
        }
        
        if (inBrackets === false) {
            bracketlessArray.push(words[i]);
        }

        if (i > 0) {
            if (words[i].endsWith(']') || words[i].endsWith('].')) {
                inBrackets = false;
            }
        }
    }

    orderByValue = bracketlessArray.join(' ');

    orderByValue = removeTags(orderByValue);

    //we need to parse the date from the value and convert it to sortable
    //IE: 2020, March 15 to 2020 03 15
    var array = orderByValue.split(".");

    for (i = 0; i < array.length; i++) {

        if (array[i].startsWith(' (') && array[i].endsWith(')')) {
            array[i] = array[i].split("(").join("");
            array[i] = array[i].split(")").join("");

            //if the array is just a year, then append 00 00
            if (array[i].length == 5) {
                if (isInteger(array[i])) {
                    array[i] = array[i] + ' 00 00';
                }
            }

            //if this section has  a month only, we need to add a 00 for the sort after ut
            if (array[i].endsWith('January') || array[i].endsWith('February') || array[i].endsWith('March') ||
                array[i].endsWith('April') || array[i].endsWith('May') || array[i].endsWith('June') || 
                array[i].endsWith('July') || array[i].endsWith('August') || array[i].endsWith('September') || 
                array[i].endsWith('October') || array[i].endsWith('November') || array[i].endsWith('December')) {
                    array[i] = array[i] + ' 00';
            }

            //replace any months with digits for sorting
            array[i] = array[i].replace('January', '01');
            array[i] = array[i].replace('February', '02');
            array[i] = array[i].replace('March', '03');
            array[i] = array[i].replace('April', '04');
            array[i] = array[i].replace('May', '05');
            array[i] = array[i].replace('June', '06');
            array[i] = array[i].replace('July', '07');
            array[i] = array[i].replace('August', '08');
            array[i] = array[i].replace('September', '09');
            array[i] = array[i].replace('October', '10');
            array[i] = array[i].replace('November', '11');
            array[i] = array[i].replace('December', '12');

            let dateArray = array[i].split(' ');

            //if we have a date, make sure to pad it with a 0
            //March 6 1978 should be 1978 03 06
            if (dateArray.length > 3) {
                if (dateArray[3].length == 1) {
                    dateArray[3] = '0' + dateArray[3];
                    array[i] = dateArray.join(' ');
                }
            }
        }

    }

    orderByValue = array.join('.');
    orderByValue = orderByValue.split(". ").join(" ");

    if (orderByValue.endsWith('.')) {
        orderByValue = orderByValue.substring(0, orderByValue.length - 1);
    }

    orderByValue = orderByValue.split("(enacted)").join("");
    orderByValue = orderByValue.split("(Reprinted from").join("");
    orderByValue = orderByValue.split("(Director)").join("");
    orderByValue = orderByValue.split("(Original work published ").join("");
    orderByValue = orderByValue.split("(Retraction published ").join("");
    orderByValue = orderByValue.split("(Host)").join("");
    orderByValue = orderByValue.split("(Hosts)").join("");
    orderByValue = orderByValue.split("(Executive Producer)").join("");
    orderByValue = orderByValue.split("(Executive Producers)").join("");
    orderByValue = orderByValue.split("(Ed.)").join("");
    orderByValue = orderByValue.split("(Eds.)").join("");
    orderByValue = orderByValue.split("Ed. & Trans.").join("");
    orderByValue = orderByValue.split(", Trans.").join(" ");

    orderByValue = orderByValue.split(".,").join("");
    orderByValue = orderByValue.split(",").join("");
    orderByValue = orderByValue.split("[").join("");
    orderByValue = orderByValue.split("]").join("");
    orderByValue = orderByValue.split("(").join(" ");
    orderByValue = orderByValue.split(")").join(" ");
    orderByValue = orderByValue.split(String.fromCharCode(34)).join(""); //double quotes
    orderByValue = orderByValue.split("“").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split(": ").join(" ");
    orderByValue = orderByValue.split(" n.d. ").join(" 0000 ");
    orderByValue = orderByValue.split(" n.d.-").join(" 0000");
    orderByValue = orderByValue.split(" in press ").join(" 9999 ");
    orderByValue = orderByValue.split(" in press-").join(" 9999");
    orderByValue = orderByValue.split("  ").join(" ");
    orderByValue = orderByValue.split(". & ").join(" ");
    orderByValue = orderByValue.split(" & ").join(" ");

    //replace special characters with their equivalent values
    orderByValue = orderByValue.split("é").join("e");
    orderByValue = orderByValue.split("á").join("a");
    orderByValue = orderByValue.split("ú").join("u");
    orderByValue = orderByValue.split("í").join("i");
    orderByValue = orderByValue.split("ó").join("o");
    orderByValue = orderByValue.split("ñ").join("n");
    orderByValue = orderByValue.split("ü").join("u");
    orderByValue = orderByValue.split("ö").join("o");
    orderByValue = orderByValue.split("ä").join("a");
    orderByValue = orderByValue.split("ç").join("c");
    
    orderByValue = orderByValue.trim();
    
	return orderByValue.toLowerCase();
}

function getReferenceNameString(contributors, isSecondary){
    var contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        //Single
        if (contributors.length == 1) {
            contributorstring = getReferenceName(contributors[0]);
        }

        //2-20
        if (contributors.length > 1 && contributors.length < 21) {
            var counter = 0;

			_forEach(contributors, (item) => {
                if (counter > 0) {

                    if (counter == contributors.length - 1) {

                        if (contributors.length == 2 && contributors[0].type == 'groupAuthor' && !isSecondary) {
                            contributorstring = contributorstring + ' & '
                        }
                        else{
                            contributorstring = contributorstring + ', & '
                        }
                    }
                    else{
                        contributorstring = contributorstring + ', '
                    }
                }

                contributorstring = contributorstring + getReferenceName(item);

                counter++;
            });
        }

        //More Than Twenty
        if (contributors.length > 20) {
            var counter = 0;

            _forEach(contributors, (item) => {

                if (counter < 19) {
                    if (counter > 0) {
                        contributorstring = contributorstring + ', '    
                    }
                    contributorstring = contributorstring + getReferenceName(item);
                }
                else{
                    if (counter == contributors.length - 1) {
                        contributorstring = contributorstring + ',...'
                        contributorstring = contributorstring + getReferenceName(item);
                    }
                }
                counter++;
            });
        }
    }

    if (isSecondary && contributors.length == 2) {
        contributorstring = contributorstring.replace(', &', ' &');
    }
  
    return contributorstring;
}

function getEditorsAtStartofRefString(editors){
    var editorString = '';

    editors = removeEmptyContributors(editors);

    if (editors.length > 0) {

        //Single
        if (editors.length == 1) {
            editorString = getEditorAtStartString(editors[0]);
        }

        //2-20
        if (editors.length > 1 && editors.length < 21) {
            var counter = 0;

            editors.forEach(item => {

                if (counter > 0) {

                    if (counter == editors.length - 1) {
                        editorString = editorString + ', & '
                    }
                    else{
                        editorString = editorString + ', '
                    }
                }

                editorString = editorString + getEditorAtStartString(item);

                counter++;
            });
        }

        //More Than Twenty
        if (editors.length > 20) {
            var counter = 0;

            editors.forEach(item => {

                if (counter < 19) {
                    if (counter > 0) {
                        editorString = editorString + ', '    
                    }
                    editorString = editorString + getEditorAtStartString(item);
                }
                else{
                    if (counter == editors.length - 1) {
                        editorString = editorString + ',...'
                        editorString = editorString + getEditorAtStartString(item);
                    }
                }
                counter++;
            });
        }
    }
  
    return editorString;
}

function getEditors(contributors){
    let editorArray = [];

    if (typeof contributors === 'undefined') {
        return editorArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'editor') {
            editorArray.push(item);
        }
    });

    return editorArray;
}

function getChairs(contributors){
    let chairArray = [];

    if (typeof contributors === 'undefined') {
        return chairArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'chair') {
            chairArray.push(item);
        }
    });

    return chairArray;
}

function getTranslators(contributors){
    let translatorArray = [];

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'translator') {
            translatorArray.push(item);
        }
    });

    return translatorArray;
}

function getChapterTranslators(contributors){
    let translatorArray = [];

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'chapterTranslator') {
            translatorArray.push(item);
        }
    });

    return translatorArray;
}

function getAuthors(contributors){
    let authorArray = [];

    if (typeof contributors === 'undefined') {
        return authorArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'author' || item.type == 'userName' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'director') {
            authorArray.push(item);
        }
    });

    return authorArray;
}

function getPerformers(contributors){
    let performerArray = [];

    if (typeof contributors === 'undefined') {
        return performerArray;
    }
    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'performer') {
            performerArray.push(item);
        }
    });

    return performerArray;
}

function getEditorAtStartString(contributor){
    var name = '';

    if (contributor.lastName && contributor.lastName.trim().length > 0) {
        name = contributor.lastName.trim();
    }

    if (contributor.firstName && contributor.firstName.trim().length > 0) {
        if (name.length > 0) {
            name = name + ", ";
        }

        name = name + contributor.firstName.getInitial()
    }

    if (contributor.middleName && contributor.middleName.trim().length > 0) {

        if (name.length > 0) {
            if (contributor.firstName.trim().length == 0) {
                name = name + ", ";
            }
            else{
                name = name + " ";
            }
        }

        name = name + contributor.middleName.getInitial();
    }

    if (contributor.suffix && contributor.suffix.trim().length > 0) {
        name = name + ', ' + contributor.suffix.trim();
    }

    return name;
}

function getCitationAuthorString(authors, isSubsequent, paperRefs){
    var authorString = '';

    authors = removeEmptyContributors(authors);

    //if there are multiple references with this author last name in the paper, we need to include the 

    if (authors.length > 0) {
        //1: LastName
        if (authors.length == 1) {
            authorString = getCitationName(authors[0], isSubsequent, paperRefs);
        }

        //2: LastName & LastName
        if (authors.length == 2) {
            authorString = getCitationName(authors[0], isSubsequent, paperRefs) + ' & ' + getCitationName(authors[1], isSubsequent, paperRefs);
        }

        //More than 4 = LastName, et al.
        if (authors.length > 2) {
            authorString = getCitationName(authors[0], isSubsequent, paperRefs) + ' et al.';
        }
    }
  
    return authorString;
}

function getReverseCitationAuthorString(authors){
    var authorString = '';

    authors = removeEmptyContributors(authors);

    if (authors.length > 0) {
        //1: A. A. Authora
        if (authors.length == 1) {
            authorString = getReverseCitationName(authors[0]);
        }

        //2: LastName & LastName = A. A. Authora & B. B. Authorb Jr.
        if (authors.length == 2) {
            authorString = getReverseCitationName(authors[0]) + ' & ' + getReverseCitationName(authors[1]);
        }

        //More than 2 = A. A. Authora et al.
        if (authors.length > 2) {
            authorString = getReverseCitationName(authors[0]) + ' et al.';
        }
    }
  
    return authorString;
}

function getPublisherString(publishers){
    let publisherString = '';

    if (typeof publishers == 'undefined'){
        return '';
    }
    
    //filter these for type: reference
    let refPublishers = [];

    publishers.forEach(item => {
        if (item.type == 'reference' && item.name.length > 0) {
            refPublishers.push(item);
        }
    });

    publishers = removeEmptyPublishers(publishers);

    //1 Publisher
    if (refPublishers.length == 1) {

        if (refPublishers[0].name.endsWith('.')) {
            return refPublishers[0].name;
        }
        else{
            return refPublishers[0].name + '.';
        }
    }

    //More than 1
    if (refPublishers.length > 1) {
    
        var counter = 0;
        refPublishers.forEach(item => {
            if (counter > 0) {
                publisherString = publisherString + '; ';
            }

            publisherString = publisherString + item.name;
            
            counter++;
        });

        if (!publisherString.endsWith(".")) {
            publisherString = publisherString + '.'
        }
    }
    
    return publisherString;
}

function getGroupPerformerString(contributors){
    let performerString = '';
    let performersArray = [];

    contributors = removeEmptyContributors(contributors);
    
    _forEach(contributors, (item) => {
        if (item.type == 'groupPerformer') {
            performersArray.push(item);
        }
    });
    
    //1 Performer
    if (performersArray.length == 1) {
        return performersArray[0].groupName;
    }

    //More than 1
    if (performersArray.length > 1) {
    
        var counter = 0;
        performersArray.forEach(item => {
            if (counter > 0) {

                if (counter == performersArray.length - 1) {
                    performerString = performerString + ' & ';
                }
                else{
                    performerString = performerString + ', ';
                }
            }

            performerString = performerString + item.groupName;
            
            counter++;
        });
    }
    
    return performerString;
}

function getOriginalPublisherString(publishers){
    let publisherString = '';

    let originalPublisherArray = [];
    
    publishers.forEach(item => {
        if (item.type == 'original') {
            originalPublisherArray.push(item);
        }
    });
    
    //1 Publisher
    if (originalPublisherArray.length == 1) {
        return originalPublisherArray[0].name;
    }

    //More than 1
    if (originalPublisherArray.length > 1) {
    
        var counter = 0;
        originalPublisherArray.forEach(item => {
            if (counter > 0) {
                publisherString = publisherString + '; ';
            }

            publisherString = publisherString + item.name;
            
            counter++;
        });
    }
    
    return publisherString;
}

function getTranslatorString(contributors){
    let translatorArray = [];
    let translatorString = '';

    if (typeof contributors === 'undefined') {
        return translatorArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'translator') {
            translatorArray.push(item);
        }
    });

    if (translatorArray.length > 0) {
        translatorString = getReferenceNameString(translatorArray, true);
        translatorString = translatorString + ', Trans.';
    }

    return translatorString;
}

function getChapterTranslatorString(contributors){
    let translatorArray = [];
    let translatorString = '';

    if (typeof contributors === 'undefined') {
        return translatorArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'chapterTranslator') {
            translatorArray.push(item);
        }
    });

    if (translatorArray.length > 0) {
        translatorString = getReferenceNameString(translatorArray, true);
        translatorString = translatorString + ', Trans.';
    }

    return translatorString;
}

function getOriginalEditorString(contributors){
    let originalEditorArray = [];
    let originalEditorString = '';

    if (typeof contributors === 'undefined') {
        return originalEditorString;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'originalEditor') {
            originalEditorArray.push(item);
        }
    });

    if (originalEditorArray.length > 0) {
        originalEditorString = getReferenceNameString(originalEditorArray, true);


        if (originalEditorArray.length == 1) {
            originalEditorString = originalEditorString + ', Ed.';
        }
        else{
            originalEditorString = originalEditorString + ', Eds.';
        }
        
    }

    return originalEditorString;
}

function getNarratorString(contributors){
    let narratorArray = [];
    let narratorString = '';

    if (typeof contributors === 'undefined') {
        return narratorString;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'narrator') {
            narratorArray.push(item);
        }
    });

    if (narratorArray.length > 0) {
        narratorString = getReferenceNameString(narratorArray, true);
        narratorString = narratorString + ', ';

        if (narratorArray.length == 1) {
            narratorString = narratorString + 'Narr.';
        }
        else{
            narratorString = narratorString + 'Narrs.';
        }
    }

    return narratorString;
}

function getIllustratorString(contributors){
    let illustratorArray = [];
    let illustratorString = '';

    if (typeof contributors === 'undefined') {
        return illustratorString;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'illustrator') {
            illustratorArray.push(item);
        }
    });

    if (illustratorArray.length > 0) {
        illustratorString = getReferenceNameString(illustratorArray, true);
        illustratorString = illustratorString + ', ';

        if (illustratorArray.length == 1) {
            illustratorString = illustratorString + 'Ill.';
        }
        else{
            illustratorString = illustratorString + 'Ills.';
        }
    }

    return illustratorString;
}

function getReferenceName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':
        	if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = name + ', ' + contributor.firstName.getInitial();
            }

			if (contributor.middleName && contributor.middleName.trim().length > 0) {

                if (isSpecialMiddleName(contributor.middleName)) {
                    name = name + ' ' + contributor.middleName;
                }
                else{
                    contributor.middleName = contributor.middleName.trim();

                    if (contributor.middleName.includes(" ")) {
                        let middleArray = contributor.middleName.split(" ");
                        let middleInitial = "";
                        
                        for (var i = 0; i < middleArray.length; i++) {
                            if (middleInitial.length > 0) {
                                middleInitial = middleInitial + " ";
                            }

                            middleInitial = middleInitial + middleArray[i].getInitial();
                        }

                        name = name + ' ' + middleInitial;
                    }
                    else{
                        name = name + ' ' + contributor.middleName.getInitial();
                    }
                }
            }

            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                name = name + ', ' + contributor.suffix.trim();
            }

            break;
        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
        case 'volumeEditor':
        
            if (contributor.firstName && contributor.firstName.trim().length > 0) {
                name = contributor.firstName.getInitial();
            }
        
            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                name = name + ' ' + contributor.middleName.getInitial();
            }

            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();
            }

            if (suffix.length > 0) {
                name = name + ' ' + suffix;

                if (!name.endsWith(".")) {
                    name = name + '.'
                }
            }

            if (contributor.type == 'reviewEditor') {
                name = name + ', Ed.'
            }

            break;
        case 'userName':

            let firstInitial ='';
            if (contributor.firstName.trim().length > 0) {
                firstInitial = contributor.firstName.getInitial();
            }

            let middleInitial = '';
            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                middleInitial = contributor.middleName.getInitial();
            }

            let lastName = '';
            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                lastName = contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();
            }

            let userName = '';
            
            if (contributor.name) {
                userName = contributor.name.trim();
            }
            
            //see if we have username only
            if (firstInitial.length == 0 &&
                middleInitial.length == 0 &&
                lastName.length == 0) {
                
                name = userName;
            }
            else{
                name = lastName;

                if (firstInitial.length > 0) {
                    name = name + ', ' + firstInitial;
                }

                if (middleInitial.length > 0) {
                    name = name + ' ' + middleInitial;
                }

                if (suffix.length > 0) {
                    name = name + ', ' + suffix
                }

                if (userName.length > 0) {
                    name = name + ' [' + userName + ']';
                }
            }
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
            if(contributor.groupName){
				name = contributor.groupName.trim();
            }
            
            let contributorname = getStringValue(contributor.name);
            
            if (contributorname.length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + '[' + contributorname + ']';
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
    }

    return name;
}

function getCitationName(contributor, isSubsequent, paperRefs){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'editor':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

            //see if we need to check for other references with this author's surname
            if (paperRefs != null) {
                let contribCount = 0;

                let firstInitial ='';
                if (contributor.firstName.trim().length > 0) {
                    firstInitial = contributor.firstName.getInitial();
                }

                //check each reference
                paperRefs.forEach(thisReference => {
                    if (typeof thisReference.data.contributors != 'undefined') {

                        let foundForThisRef = false;
                        
						//check each contrib on the ref until we find a matching
						_forEach(thisReference.data.contributors, (thisContributor) => {
                            let thisContribFirstInitial ='';
                            if (thisContributor.firstName.trim().length > 0) {
                                thisContribFirstInitial = thisContributor.firstName.getInitial();
                            }

                            if (thisContributor.lastName == contributor.lastName && thisContribFirstInitial != firstInitial) {

                                if (!foundForThisRef) {
                                    contribCount++;
                                }

                                foundForThisRef = true;
                            }
                        });
                    }
                });

                if (contribCount > 0) {
                    //we found multiple contribs with the same last name, add first initial
                    if (firstInitial != '') {
                        name = firstInitial + ' ';
                    }

                    name = name + contributor.lastName.trim();
                }
                else{
                    name = contributor.lastName.trim();
                }
            }
            else{
                name = contributor.lastName.trim();
            }
            break;
        case 'userName':

            if (contributor.lastName.trim().length > 0) {
                name = contributor.lastName.trim();
            }
            else{
                name = contributor.name.trim();
            }
            break;
        case 'groupAuthor':

            var abbreviation = getStringValue(contributor.abbreviation);

            if (isSubsequent) {
                if (abbreviation.length > 0) {
                    name = abbreviation;
                }
                else{
                    name = getStringValue(contributor.groupName).trim()
                }
            }
            else{
                name = getStringValue(contributor.groupName).trim();

                if (abbreviation.length > 0) {
                    name = name + ' [' + abbreviation + ']';
                }
            }

            break;
        case 'translator':
            name = contributor.lastName.trim();
            break;
    }

    return name;
}

function getReverseCitationName(contributor, isSubsequent){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'editor':
        case 'chapterAuthor':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
            // name = contributor.lastName.trim();
            //B. B. Authorb Jr.
            
            let firstInitial ='';
            if (contributor.firstName.trim().length > 0) {
                firstInitial = contributor.firstName.getInitial();
            }

            let middleInitial = '';
            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                middleInitial = contributor.middleName.getInitial();
            }

            let lastName = '';
            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                lastName = contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();
            }

            name = '';

            if (firstInitial.length > 0) {
                name = firstInitial;
            }

            if (middleInitial.length > 0) {

                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + middleInitial;
            }

            if (lastName.length > 0) {

                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + lastName;
            }

            if (suffix.length > 0) {

                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + suffix
            }

            break;
        case 'userName':
			if(contributor.lastName){
				if (contributor.lastName.trim().length > 0) {
					name = contributor.lastName.trim();
				}
				else{
					name = contributor.name.trim();
				}
			}
            break;
        case 'groupAuthor':

            var abbreviation = getStringValue(contributor.abbreviation);

            if (isSubsequent) {
                if (abbreviation.length > 0) {
                    name = abbreviation;
                }
                else{
                    name = contributor.groupName.trim()
                }
            }
            else{
                name = contributor.groupName.trim();

                if (abbreviation.length > 0) {
                    name = name + ' [' + abbreviation + ']';
                }
            }

            break;
        case 'translator':
            name = contributor.lastName.trim();
            break;
    }

    return name;
}

function getYear(date){
    var year = '';

    if (typeof date == 'undefined'){
        return '';
    }

    if (date == '') {
        return year;
    }

    //see if there are any valid prefixes or suffixes on this date
    let prefix = '';
    let suffix = '';
    let errorConverting = false;

    if (date.toLowerCase().startsWith('ca.')) {
        prefix = 'ca.';
    }

    if (date.toLowerCase().startsWith('c.')) {
        prefix = 'c.';
    }

    //see if the format is either 2019 or 2/2/2019
    if (date.includes("/")) {
        //split into an array and grab the last entry in that array
        var array = date.split("/");

        // 2018/2020 needs to return as 2018-2020
        if (array.length == 2) {
            if (array[0].length == 4 && array[1].length == 4) {
                return array[0] + '–' + array[1];
            }
            else{
                //handle case of May/June 2019
                if (array[1].includes(" ")) {
                    var yearArray = array[1].split(" ");

                    return yearArray[yearArray.length - 1];
                }
            }
        }

        year = array[array.length-1]
    }
    else{
        if (date.length > 4) {

            //2018-2020
            if (date.includes("-") || date.includes("–")) {
                year = date;
            }
            else {
                try {
                    year = dateHelper.format(date, "yyyy");
                }
                catch(err) {
                    errorConverting = true;
                    console.log('Failed to convert year of ' + date);
                    // console.log(err);
                    year = date;
                }
            }
        }
        else {
            //assume this is only a year
            year = date;
        }
    }

    if (prefix.length > 0 && !errorConverting) {

        if (prefix.length > 0 && year.length == 0) {
            year = date;
        }else{
            year = prefix + ' ' + year;
        }
    }

    //if this is a span, then run page dash logic
    year = fixPageDash(year);
    
    return year;
  }

function getStringValue(input){
    var output = '';

    //helper to check for mull values
    if (typeof input !== 'undefined'){
        output = input.trim();
    }
  
    return output;
}

function getCitationLocationString(citationData){
    var location = '';

    let citationVolume = getCitationVolume(citationData);

    if (citationData.value == '' && citationData.type != 'actSceneLine' && citationVolume.length == 0) {
        return '';
    }

    switch (citationData.type) {
        case 'notdirect':
            location = '';
            break;
        case 'page':

            var label = 'p.';

            if (usePluralLabel(citationData.value) && !citationData.value.includes(':')) {
                label = 'pp.'
            }

            location = label + ' ' + fixPageDash(citationData.value);

            if (citationVolume.length > 0) {

                if (citationData.value.length == 0) {
                    location = 'vol. ' + citationVolume;
                }else{
                    location = 'vol. ' + citationVolume + ', ' + location;
                }
            }

            break;
        case 'paragraph':
            var label = 'para.';

            if (usePluralLabel(citationData.value)) {
                label = 'paras.'
            }

            location = label + ' ' + citationData.value;
            break;
        case 'chapter':
            location = 'Chapter ' + citationData.value;
            break;
        case 'track':
            location = 'Track ' + citationData.value;
            break;
        case 'figure':
                var label = 'Figure';
                
                if (usePluralLabel(citationData.value)) {
                    label = 'Figures'
                }

                location = label + ' ' + citationData.value;
            break;
        case 'table':
            var label = 'Table';

            if (usePluralLabel(citationData.value)) {
                label = 'Tables'
            }

            location = label + ' ' + citationData.value;
            break;
        case 'time':

            if (citationData.value.startsWith('/')) {
                citationData.value = citationData.value.substring(1, citationData.value.length);
            }

            location = citationData.value;
            break;
        case 'section':

            if (isInteger(citationData.value)) {
                location = 'section ' + citationData.value;
            }
            else{
                location = citationData.value + ' section';
            }
            break;
        case 'actSceneLine':
            location = fixPageDash(citationData.value);
            location = location.split("/").join(".");

            if (location.endsWith('.')) {
                location = location.substring(0, location.length - 1);
            }

            break;
        case 'other':
        case 'religious':
            
            location = citationData.label;

            if (citationData.label.length > 0) {
                location = location + ' ';
            }
            location = location + citationData.value;
        
        break;
    }

    return location;
}

function getEditorTranslatorString(contributors){

    let editorTranslatorString = '';
    let editorArray = [];

    if (typeof contributors === 'undefined') {
        return editorArray;
    }

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'editor') {
            editorArray.push(item);
        }
    });

    let translatorArray = [];

    _forEach(contributors, (item) => {
        if (item.type == 'translator') {
            translatorArray.push(item);
        }
    });

    if (editorArray.length > 0 &&
        (editorArray.length == translatorArray.length)) {
        
        let same = true;

        //gonna loop each array and see if we have a match
        editorArray.forEach(thisEditor =>{
            
            var found = false;

            translatorArray.forEach(thisTranslator =>{
                if (thisEditor.firstName == thisTranslator.firstName &&
                    thisEditor.middleName == thisTranslator.middleName &&
                    thisEditor.lastName == thisTranslator.lastName &&
                    thisEditor.prefix == thisTranslator.prefix &&
                    thisEditor.suffix == thisTranslator.suffix) {
                        found = true;
                }    
            });

            if (found == false) {
                same = false;
            }
        });

        if (same) {
            //they match, build the combined string
            editorTranslatorString = getReferenceNameString(translatorArray, true);
            editorTranslatorString = editorTranslatorString + ', Ed. & Trans.';
        }
    }

    return editorTranslatorString;
}

function appendDOIURL(doi){
    let url = '';

    if (doi.length > 0) {
        if (!doi.startsWith('https://doi.org/') && !doi.startsWith('http://doi.org/') ) {

            //in case they enter a URL in the DOI
            if ((doi.startsWith('https:') || doi.startsWith('http:'))) {

                if (doi.startsWith('https://dx.doi.org/') || doi.startsWith('http://dx.doi.org/')) {
                    doi = doi.replace('https://dx.doi.org/', '');
                    doi = doi.replace('http://dx.doi.org/', '');

                    url = 'https://doi.org/' + doi;
                }else{
                    return formatURL(doi);
                }
            }
            else{
                //if they happen to start this with dx.doi.org, pull that out
                
                doi = doi.replace('dx.doi.org/', '');
                doi = doi.replace('doi.org/', '');

                url = 'https://doi.org/' + doi;
            }
        }
        else{
            url = doi;
        }

        url = formatURL(url);
    }

    return url;
}

function fixPageDash(pages){
    
    let cleanPages = ''

    cleanPages = pages.replace('-', '–');
    cleanPages = cleanPages.replace(' - ', '–');
    cleanPages = cleanPages.replace(' – ', '–');

    return cleanPages;
}

function usePluralLabel(value){
    let use = false;

    if (value.includes('-') || 
        value.includes(':') ||
        value.includes('–') ||
        value.includes(',')) {
        
            use = true;
    }

    return use;
}

function formatPageNumbers(pages){
    if (pages.length > 0) {
        var label = 'p.';
        if (pages.includes('-') || pages.includes('–')) {
            label = 'pp.';
        }

        pages = label + ' ' + fixPageDash(pages);
    }

    return pages;
}

function isInteger(value) {
return !isNaN(value) && 
        parseInt(Number(value)) == value && 
        !isNaN(parseInt(value, 10));
}
function convertNumberToText (num) {

    var th = ['','thousand','million', 'billion','trillion'];
    var dg = ['zero','one','two','three','four', 'five','six','seven','eight','nine'];
    var tn = ['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen', 'seventeen','eighteen','nineteen'];
    var tw = ['twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    num = num.toString();
    num = num.replace(/[\, ]/g,'');
    if (num != parseFloat(num)) return num;
    var x = num.indexOf('.');
    
    if (x == -1)
        x = num.length;
    
    if (x > 15)
        return num;

    var n = num.split(''); 
    var str = '';
    var sk = 0;
    for (var i=0;   i < x;  i++) {
        if ((x-i)%3==2) { 
            if (n[i] == '1') {
                str += tn[Number(n[i+1])] + ' ';
                i++;
                sk=1;
            } else if (n[i]!=0) {
                str += tw[num[i]-2] + ' ';
                sk=1;
            }
        } else if (n[i]!=0) { // 0235
            str += dg[n[i]] +' ';
            if ((x-i)%3==0) str += 'hundred ';
            sk=1;
        }
        if ((x-i)%3==1) {
            if (sk)
                str += th[(x-i-1)/3] + ' ';
            sk=0;
        }
    }

    if (x != num.length) {
        var y = num.length;
        str += 'point ';
        for (var i=x+1; i<y; i++)
            str += dg[n[i]] +' ';
    }
    return str.replace(/\s+/g,' ');
}

function getOrdinalValue(value){
    //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    if (isInteger(value)) {
        var j = value % 10,
        k = value % 100;
        if (j == 1 && k != 11) {
            return value + "st";
        }
        if (j == 2 && k != 12) {
            return value + "nd";
        }
        if (j == 3 && k != 13) {
            return value + "rd";
        }
        return value + "th";
    }

    return value;
}

function addCommasToNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removingLeadingZeros(value){
    if (value.length > 0) {
        value = value.replace(/^0+/, '')
    }

    return value;
}

function fixTimeCitationData(value){
    if (value.length > 0) {
        let dataArray = value.split('/');

        switch (dataArray.length) {
            case 2:
                if (dataArray[1].length == 1) {
                    dataArray[1] = '0' + dataArray[1]
                }
                break;
        
            case 3:
                if (dataArray[1].length == 1) {
                    dataArray[1] = '0' + dataArray[1]
                }

                if (dataArray[2].length == 1) {
                    dataArray[2] = '0' + dataArray[2]
                }
                break;
        }

        value = dataArray.join('/');

        value = removingLeadingZeros(value);

        if (value.startsWith('/0')) {
            value = value.substring(2, value.length);
        }

        if (value.startsWith('/')) {
            value = value.substring(1, value.length);
        }

        //twice for //
        if (value.startsWith('/')) {
            value = value.substring(1, value.length);
        }

        //last check is if this data is seconds only, append a 0 back to the begining so it ends up as 0:45 instead of 45
        if (!value.includes('/') && !value.includes(':')) {
            value = '0/' + value;
        }
    }

    return value;
}

function formatRetrievalDate(date){
    let formattedDate = '';

    try {
        if (date.includes("/")) {

            date = date.replace('//', '/');

            var array = date.split("/");

            switch (array.length) {
                case 3:
                    if (date.endsWith("/")) {
                        formattedDate = dateHelper.format(date, 'mmmm d');
                    }
                    else{
                        formattedDate = dateHelper.format(date, 'mmmm d, yyyy');
                    }
                    break;
                case 2:
                    formattedDate = dateHelper.format(array[0] + '/1/' + array[1], 'mmmm, yyyy');
                    break;
                case 1:
                    formattedDate = dateHelper.format('1/1/' + array[0], 'yyyy');
                    break;
            }
        }
        else{
            return date;
        }
    }
    catch(err) {
        console.log('Failed to convert retrieval date of ' + date);
        console.log(err);
        return date;
    }

    return formattedDate;
}

function getFullDate(date){

    if (date.length == 0) {
        date = 'n.d.';
    } else {
        if (date.includes("/")) {
            var array = date.split("/");

            let month = '';
            let day = '';
            let year = '';

            switch (array.length) {
                case 3:
                    if (array[0].length > 0) {
                        month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                    }

                    if (array[1].length > 0) {
                        day = dateHelper.format('1/' + array[1] + '/2020', 'd');
                    }

                    if (array[2].length > 0) {
                        year = dateHelper.format('1/1/' + array[2], 'yyyy');
                    }

                    break;
                case 2:

                        //this is for the format of 2021/2022
                        if (array[0].length == 4 && array[1].length == 4) {
                            return date;
                        }

                        //for the format of May/June 2019
                        if (array[1].includes(" ")) {
                            return date;
                        }                        

                        if (array[0].length > 0) {
                            month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                        }
    
                        if (array[1].length > 0) {
                            year = dateHelper.format('1/1/' + array[1], 'yyyy');
                        }

                    break;
                case 1: 
                    if (array[0].length > 0) {
                        year = dateHelper.format('1/1/' + array[0], 'yyyy');
                    }

                    break;
            }

            if (year.length > 0) {
                date = year;
            }

            if (month.length > 0) {
                if (year.length > 0) {
                    date = date + ', '
                }
                else{
                    date = '';
                }

                date = date + month;
            }

            if (day.length > 0) {
                if (year.length > 0 && month.length == 0) {
                    date = date + ', '
                } 

                if (month.length > 0) {
                    date = date + ' '
                } 

                date = date + day;
            }
            
        } else {
            date = date;
        }
    }

    if (date == '//') {
        date = 'n.d.';
    }

    return date;
}

function getFullDateRange(startDate, endDate){

    //get the format for the start date, and we'll append the range to that
    let fullDate = getFullDate(startDate);

    let startYear = getYearFromDate(startDate);
    let startMonth = getMonthFromDate(startDate);
    let startDay = getDayFromDate(startDate);

    let endYear = getYearFromDate(endDate);
    let endMonth = getMonthFromDate(endDate);
    let endDay = getDayFromDate(endDate);

    //same year and month and day
    if (startYear == endYear && startMonth == endMonth && startDay == endDay) {
        return fullDate;
    }

    //same year and month
    if (startYear == endYear && startMonth == endMonth) {
        return fullDate + '–' + endDay;
    }

    //same year
    if (startYear == endYear) {
        return fullDate + '–' + endMonth + ' ' + endDay;
    }

    return fullDate + '–' + getFullDate(endDate);
}

function getYearFromDate(date){
    let year = '';
    var array = date.split("/");

    switch (array.length) {
        case 3:
            if (array[2].length > 0) {
                year = dateHelper.format('1/1/' + array[2], 'yyyy');
            }
            break;
        case 2:
                if (array[1].length > 0) {
                    year = dateHelper.format('1/1/' + array[1], 'yyyy');
                }
            break;
        case 1: 
            if (array[0].length > 0) {
                year = dateHelper.format('1/1/' + array[0], 'yyyy');
            }
            break;
    }

    return year;
}

function getMonthFromDate(date){
    var array = date.split("/");
    let month = '';

    switch (array.length) {
        case 3:
            if (array[0].length > 0) {
                month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
            }

            break;
        case 2:
            if (array[0].length > 0) {
                month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
            }

            break;
    }

    return month;
}

function getDayFromDate(date){
    var array = date.split("/");
    let day = '';

    switch (array.length) {
        case 3:

            if (array[1].length > 0) {
                day = dateHelper.format('1/' + array[1] + '/2020', 'd');
            }
            break;
    }

    return day;
}

function getReverseFullDate(date){

    if (date.length == 0) {
        date = 'n.d.';
    } else {
        if (date.includes("/")) {
            var array = date.split("/");

            let month = '';
            let day = '';
            let year = '';

            switch (array.length) {
                case 3:
                    if (array[0].length > 0) {
                        month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                    }

                    if (array[1].length > 0) {
                        day = dateHelper.format('1/' + array[1] + '/2020', 'd');
                    }

                    if (array[2].length > 0) {
                        year = dateHelper.format('1/1/' + array[2], 'yyyy');
                    }

                    break;
                case 2:
                        if (array[0].length > 0) {
                            month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                        }
    
                        if (array[1].length > 0) {
                            year = dateHelper.format('1/1/' + array[1], 'yyyy');
                        }

                    break;
                case 1: 
                    if (array[0].length > 0) {
                        year = dateHelper.format('1/1/' + array[0], 'yyyy');
                    }

                    break;
            }

            date = '';

            if (month.length > 0) {
                date = month;
            }

            if (day.length > 0) {
                if (month.length > 0) {
                    date = date + ' ';
                }

                date = date + day;
            }

            if (year.length > 0) {
                if (day.length > 0) {
                    date = date + ', ';
                }
                else{
                    if (month.length > 0) {
                        date = date + ' ';
                    }
                }

                date = date + year;
            }
            
        } else {
            date = date;
        }
    }

    return date;
}

function getCitations(citationData, authorString, year, authorArray){

    let firstCitation = ''
    let subsequentCitation = '';
    let location = getCitationLocationString(citationData);

    //check to see if any data needs clearing out
    if (citationData.namePart == 'false') {
        authorString = '';
    }

    if (citationData.datePart == 'false') {
        year = '';
    }
    
    if (authorString.length > 0) {
        firstCitation = authorString;
    }

    if (year.length > 0) {
        if (firstCitation.length > 0 && !firstCitation.endsWith('" ')) {
            firstCitation = firstCitation + ', ';
        }
        firstCitation = firstCitation + year;
    }

    if (location.length > 0) {

        if (firstCitation.length > 0 && (!firstCitation.endsWith('" ') || firstCitation.endsWith(', '))) {
            firstCitation = firstCitation + ', ';
        }

        firstCitation = firstCitation + location;
    }

    //if there is a group author, and it's in the first two contributors....and that group auther has an abbreviation, we have a different subsequent
    var contributorCounter = 0;
    var hasSubsequent = false;

    authorArray.forEach(item => {
        if (item.type == 'groupAuthor') {
            if (contributorCounter < 2) {
                var abbreviation = getStringValue(item.abbreviation);

            if (abbreviation.length > 0) {
                hasSubsequent = true;
            }
            }
        }

        contributorCounter++;
    });

    if (hasSubsequent) {
        let subsequentAuthorString = getCitationAuthorString(authorArray, true);
        
        if (citationData.namePart == 'false') {
            subsequentCitation = '';
        }
        else{
            if (subsequentAuthorString.length > 0) {
                subsequentCitation = subsequentAuthorString;
            }
        }

        if (year.length > 0) {
            if (subsequentCitation.length > 0 && !subsequentCitation.endsWith('" ')) {
                subsequentCitation = subsequentCitation + ', ';
            }
            subsequentCitation = subsequentCitation + year;
        }
    
        if (location.length > 0) {
    
            if (subsequentCitation.length > 0 && (!subsequentCitation.endsWith('" ') || subsequentCitation.endsWith(', '))) {
                subsequentCitation = subsequentCitation + ', ';
            }
    
            subsequentCitation = subsequentCitation + location;
        }
    }

    //trim these back
    firstCitation = firstCitation.trim();

    //make sure neither citation ends with a , or ,"
    if (firstCitation.endsWith(',')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -1);
    }

    if (firstCitation.endsWith(',"')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -2) + '"';
    }

    let citation = {
        first : firstCitation,
        subsequent : subsequentCitation
    }

    return citation;
}

function stripSubtitle(title){
    
    if (title.includes(':')) {
        var array = title.split(":");
        title = array[0];
    }

    return title;
}

function fixEditionString(edition){

    if (edition.length > 0) {

        //if this is an integer, make it ordinal
        if (isInteger(edition)) {
            edition = getOrdinalValue(edition);
        }

        //replace edition with ed.
        if (edition.includes('edition')) {
            edition = edition.replace('edition', 'ed.');
        }   

        //replace edition with Ed.
        if (edition.includes('Edition')) {
            edition = edition.replace('Edition', 'ed.');
        }  

        //add ed if needed
        if (!edition.includes('ed.')) {
            edition = edition + ' ed.'
        }
        
        //if the string includes ed., is not at the end, and does not follow with a comma, add it
        if (edition.includes('ed.') &&
            !edition.endsWith('ed.') &&
            !edition.includes('ed.,')) {
            edition = edition.replace('ed.', 'ed.,');
        }
    }
    
    return edition;
}

function fixTitlePartClosure(titlePart){
    
    if (titlePart.length > 0) {
        if (!titlePart.endsWith('.') && !titlePart.endsWith('?') && !titlePart.endsWith('!')
            && !titlePart.endsWith('.</em>') && !titlePart.endsWith('?</em>') && !titlePart.endsWith('!</em>')) {
            titlePart = titlePart + ".";
        }        
    }

    return titlePart;
}

function isSpecialMiddleName(middleName){
    //APA 10.92 Example 1
    //all I know of now is van, but if others are considered special, we can handle then here

    let isSpecial = false;

    if (middleName.length > 0) {
        if (middleName.toUpperCase() == 'VAN') {
            isSpecial = true;
        }
    }

    return isSpecial;
}

function formatURL(url){
    let formatURL = '';

    if (url.length > 0) {
        formatURL = '<a href="' + url + '">' + url + '</a>';
    }

    return formatURL;
}

function removeTags(str) {
    //tutorialspoint.com/how-to-remove-html-tags-from-a-string-in-javascript
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
}

function limitWordsInString(value, wordCount){
    let limitedValue = '';

    if (value.length == 0) {
        return value;
    }

    let valueArray = value.split(' ');

    if(valueArray.length > 0){
        if (valueArray.length > wordCount) {
            let i = 0;

            while (i < wordCount) {
                if (limitedValue.length > 0) {
                    limitedValue = limitedValue + ' ';
                }

                limitedValue = limitedValue + valueArray[i];
                i++;
              }
        }
        else{
            limitedValue = value;
        }
    }
    else{
        limitedValue = value;
    }


    return limitedValue;
}

function removeEmptyContributors(contributors){
    
    //remove any empty contributors from the array
    if (typeof contributors === 'undefined') {
        return [];
    }

    let cleanContributors = [];

    if (contributors.length > 0) {
        _forEach(contributors, (contributor) => {
        
            if (typeof contributor.type !== 'undefined') {

                switch (contributor.type) {
                    case 'author':
                    case 'chapterAuthor':
                    case 'custom':
                    case 'executiveProducer':
                    case 'executiveDirector':
                    case 'executiveDirectorProducer':
                    case 'director':
                    case 'writer':
                    case 'writerDirector':
                    case 'host':
                    case 'interview':
                       
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'editor':
                    case 'chapterTranslator':
                    case 'translator':
                    case 'narrator':
                    case 'illustrator':
                    case 'originalEditor':
                    case 'performer':
                    case 'chair':
                    case 'reviewAuthor':
                    case 'reviewDirector':
                    case 'reviewWriter':
                    case 'reviewWriterDirector':
                    case 'reviewArtist':
                    case 'reviewComposer':
                    case 'reviewEditor':
                    case 'volumeEditor':    

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }
            
                        break;
                    case 'userName':
            
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0 ||
                            contributor.name.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupPerformer':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if (typeof contributor.abbreviation === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0 ||
                            contributor.abbreviation.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupAuthor':
                    case 'reviewGroupArtist':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'testimony':

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0){
                                cleanContributors.push(contributor);
                        }
                       
                        break;
                }
            }
        
        });
    }

    return cleanContributors;
}

function getCitationVolume(citationData) {
    let volume = getStringValue(citationData.volume);

    return volume;
}

function removeEmptyPublishers(publishers) {
    //remove any empty publishers from the array
    if (typeof publishers === 'undefined') {
        return [];
    }

    let cleanPublishers = [];

    if (publishers.length > 0) {

        _forEach(publishers, (publisher) => {
            let foundSomething = false;
            
            if (typeof publisher.type !== 'undefined') {
                if (publisher.type.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.city !== 'undefined') {
                if (publisher.city.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.name !== 'undefined') {
                if (publisher.name.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.state !== 'undefined') {
                if (publisher.state.length > 0) {
                    foundSomething = true;
                }
            }

            if (foundSomething) {
                cleanPublishers.push(publisher);
            }

        });

    }

    return cleanPublishers;
}