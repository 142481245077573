const _forEach = require('lodash/forEach');
const _has = require('lodash/has');
const dateHelper = require('../dateHelper');

module.exports = {
    getOrderByValue,
    getAuthorPart,
    getAuthorPartSort,
    getStringValue,
    getContributorsByType,
    getSecondaryContributorString,
    getSecondaryContributorCitationString,
    getFullDate,
    getYear,
    formatURL,
    appendDOIURL,
    getCitationAuthorPart,
    getCitations,
    getFullDateRange,
    getGroupAuthorCitationString,
    getPublisherString,
    getPublisherStringInverted,
    fixFormatString,
    fixFormatStringForCitation,
    fixEditionString,
    formatPageNumbers,
    getPrimaryContributorType,
    getChapterTranslators,
    getChapterTranslatorString,
    getCitationVolume,
    removeEmptyContributors,
    getSecondaryReferenceName,
    getCitationLocationString,
    fixPageDash,
    replaceWithAbbreviations,
    isSelfPublished,
    removeDescriptionFromTitle,
    parseTimeStampString,
    isPublisherAndAuthorSame,
    getSecondaryContributorTypeString,
    getSecondaryContributorCitationTypeString,
    getReferenceName,
    getCitationName,
    getOrdinalValue
}

function getOrderByValue(reference){

    let referenceValue = reference.displayValue;

    if (referenceValue == '') {
        return '';
    }

    let orderByValue = referenceValue;

    orderByValue = orderByValue.replace(reference.authorPart, reference.authorPartSort);

    //check to see if the author part includes contributor labels, if they do, remove them
    // if (reference.authorPart != reference.authorPartNoLabel) {
    //     orderByValue = orderByValue.replace(reference.authorPart, reference.authorPartNoLabel);
    // }
    
    //before we remove any tags, replace anything within italics and is a number with text
    //<em>22 searches for Sasquatch</em>
    //<em>twenty two searches for Sasquatch</em>

    var words = orderByValue.split(" ");
    let inItalics = false;
    for (var i = 0; i < words.length; i += 1) {
        if (words[i].startsWith('<em>')) {
            inItalics = true;
            words[i] = words[i].replace('<em>', '');
        }

        if (inItalics) {
            words[i] = words[i].replace(':', '');
            words[i] = convertNumberToText(words[i]);
        }

        if (words[i].endsWith('</em>')) {
            inItalics = false;
            words[i] = words[i].replace('</em>', '');
        }
    }

    orderByValue = words.join(' ');

    //now split back out and remove anything in brackets (like user names)
    words = orderByValue.split(" ");
    let bracketlessArray = [];
    let inBrackets = false;

    for (var i = 0; i < words.length; i += 1) {

        if (i > 0) {
            if (words[i].startsWith('[')) {
                inBrackets = true;
            }    
        }
        
        if (inBrackets === false) {
            bracketlessArray.push(words[i]);
        }

        if (i > 0) {
            if (words[i].endsWith(']') || words[i].endsWith('].')) {
                inBrackets = false;
            }
        }
    }

    orderByValue = bracketlessArray.join(' ');

    orderByValue = removeTags(orderByValue);

    //we need to parse the date from the value and convert it to sortable
    //IE: 2020, March 15 to 2020 03 15
    var array = orderByValue.split(".");

    for (i = 0; i < array.length; i++) {

        if (array[i].startsWith(' (') && array[i].endsWith(')')) {
            array[i] = array[i].split("(").join("");
            array[i] = array[i].split(")").join("");

            //if the array is just a year, then append 00 00
            if (array[i].length == 5) {
                if (isInteger(array[i])) {
                    array[i] = array[i] + ' 00 00';
                }
            }

            //if this section has  a month only, we need to add a 00 for the sort after ut
            if (array[i].endsWith('January') || array[i].endsWith('February') || array[i].endsWith('March') ||
                array[i].endsWith('April') || array[i].endsWith('May') || array[i].endsWith('June') || 
                array[i].endsWith('July') || array[i].endsWith('August') || array[i].endsWith('September') || 
                array[i].endsWith('October') || array[i].endsWith('November') || array[i].endsWith('December')) {
                    array[i] = array[i] + ' 00';
            }

            //replace any months with digits for sorting
            array[i] = array[i].replace('January', '01');
            array[i] = array[i].replace('February', '02');
            array[i] = array[i].replace('March', '03');
            array[i] = array[i].replace('April', '04');
            array[i] = array[i].replace('May', '05');
            array[i] = array[i].replace('June', '06');
            array[i] = array[i].replace('July', '07');
            array[i] = array[i].replace('August', '08');
            array[i] = array[i].replace('September', '09');
            array[i] = array[i].replace('October', '10');
            array[i] = array[i].replace('November', '11');
            array[i] = array[i].replace('December', '12');
        }

    }

    orderByValue = array.join('.');
    orderByValue = orderByValue.split(". ").join(" ");

    if (orderByValue.endsWith('.')) {
        orderByValue = orderByValue.substring(0, orderByValue.length - 1);
    }

    orderByValue = orderByValue.split("(enacted)").join("");
    orderByValue = orderByValue.split("(Reprinted from").join("");
    orderByValue = orderByValue.split("(Director)").join("");
    orderByValue = orderByValue.split("(Original work published ").join("");
    orderByValue = orderByValue.split("(Retraction published ").join("");
    orderByValue = orderByValue.split("(Host)").join("");
    orderByValue = orderByValue.split("(Hosts)").join("");
    orderByValue = orderByValue.split("(Executive Producer)").join("");
    orderByValue = orderByValue.split("(Executive Producers)").join("");
    orderByValue = orderByValue.split("(Ed.)").join("");
    orderByValue = orderByValue.split("(Eds.)").join("");
    orderByValue = orderByValue.split("Ed. & Trans.").join("");
    orderByValue = orderByValue.split(", Trans.").join(" ");

    orderByValue = orderByValue.split(".,").join("");
    orderByValue = orderByValue.split(",").join("");
    orderByValue = orderByValue.split("[").join("");
    orderByValue = orderByValue.split("]").join("");
    orderByValue = orderByValue.split("(").join(" ");
    orderByValue = orderByValue.split(")").join(" ");
    orderByValue = orderByValue.split(String.fromCharCode(34)).join(""); //double quotes
    orderByValue = orderByValue.split("“").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split("”").join("");
    orderByValue = orderByValue.split(": ").join(" ");
    orderByValue = orderByValue.split(" n.d. ").join(" 0000 ");
    orderByValue = orderByValue.split(" n.d.-").join(" 0000");
    orderByValue = orderByValue.split(" in press ").join(" 9999 ");
    orderByValue = orderByValue.split(" in press-").join(" 9999");
    orderByValue = orderByValue.split("  ").join(" ");
    orderByValue = orderByValue.split(". & ").join(" ");
    orderByValue = orderByValue.split(" & ").join(" ");

    //replace special characters with their equivalent values
    orderByValue = orderByValue.split("é").join("e");
    orderByValue = orderByValue.split("á").join("a");
    orderByValue = orderByValue.split("ú").join("u");
    orderByValue = orderByValue.split("í").join("i");
    orderByValue = orderByValue.split("ó").join("o");
    orderByValue = orderByValue.split("ñ").join("n");
    orderByValue = orderByValue.split("ü").join("u");
    orderByValue = orderByValue.split("ö").join("o");
    orderByValue = orderByValue.split("ä").join("a");
    orderByValue = orderByValue.split("ç").join("c");
    
    orderByValue = orderByValue.toLowerCase();
    orderByValue = orderByValue.trim();

	return orderByValue;
}

function getStringValue(input){
    var output = '';

    //helper to check for mull values
    if (typeof input !== 'undefined'){
        output = input.trim();
    }
  
    return output;
}

function getFullDate(date){

    if (typeof date == 'undefined'){
        return '';
    }

    if (date.length == 0) {
        date = 'n.d.';
    } else {
        if (date.includes("/")) {
            var array = date.split("/");

            let month = '';
            let day = '';
            let year = '';

            switch (array.length) {
                case 3:
                    if (array[0].length > 0) {
                        month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                    }

                    if (array[1].length > 0) {
                        day = dateHelper.format('1/' + array[1] + '/2020', 'd');
                    }

                    if (array[2].length > 0) {
                        year = dateHelper.format('1/1/' + array[2], 'yyyy');
                    }

                    break;
                case 2:

                        //this is for the format of 2021/2022
                        if (array[0].length == 4 && array[1].length == 4) {
                            // return array[0] + '–' + array[1];
                            return getYear(date);
                        }

                        //for the format of May/June 2019
                        if (array[1].includes(" ")) {
                            return date;
                        }                        

                        if (array[0].length > 0) {
                            month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
                        }
    
                        if (array[1].length > 0) {
                            year = dateHelper.format('1/1/' + array[1], 'yyyy');
                        }

                    break;
                case 1: 
                    if (array[0].length > 0) {
                        year = dateHelper.format('1/1/' + array[0], 'yyyy');
                    }

                    break;
            }


            if (year.length > 0) {
                date = year;

                if (month.length > 0 && day.length > 0) {
                    date = month + ' ' + day + ', ' + year;
                }
                else{
                    if (month.length > 0) {
                        date = month + ' ' + year;
                    }
                }
            }
            else{
                if (month.length > 0) {
                    date = month;

                    if (day.length > 0) {
                        date = month + ' ' + day;
                    }
                }
            }
            
        } else {
            date = date;

            // if (date.includes('–') && !date.includes('present')) {
            //     //assume this is a year, so get its format
            //     date = getYear(date);
            // }

        }
    }

    if (date == '//') {
        date = 'n.d.';
    }

    return date;
}

function getYear(date){
    var year = '';

    if (typeof date == 'undefined'){
        return '';
    }

    date = fixPageDash(date);

    //2018-2020 need to be abbrivated as 2018-20
    if (date.includes('–')) {
        var array = date.split("–");

        if (array.length == 2) {

            //trim both entries
            array[0] = array[0].trim();
            array[1] = array[1].trim();

            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }
    }

    //see if the format is either 2019 or 2/2/2019 or 2018/2020
    if (date.includes("/")) {
        //split into an array and grab the last entry in that array
        var array = date.split("/");

        if (array.length == 2) {
            //both 4 chars
            if (array[0].length == 4 && array[1].length == 4) {
                if (array[0].substring(0, 2) == array[1].substring(0, 2)) {
                    year = array[0] + '-' + array[1].substring(2);
                    return fixPageDash(year);
                }
            }
        }

        year = array[array.length-1]
    }
    else{
        if (date.length > 4) {

            //2018-2020
            if (date.includes("-")) {
                year = date;
            }
            else {
                try {
                    year = dateHelper.format(date, "yyyy");
                }
                catch(err) {
                    console.log('Failed to convert year of ' + date);
                    console.log(err);
                    year = date;
                }
            }
        }
        else {
            //assume this is only a year
            year = date;
        }
    }

    if (year == '') {
        year = 'n.d.';
    }

    return year;
}

function formatURL(url){
    let formatURL = '';

    if (url.length > 0) {
        formatURL = '<a href="' + url + '">' + url + '</a>';
    }

    return formatURL;
}

function appendDOIURL(doi){
    let url = '';

    if (doi.length > 0) {
        if (!doi.startsWith('https://doi.org/') && !doi.startsWith('http://doi.org/') ) {

            //in case they enter a URL in the DOI
            if ((doi.startsWith('https:') || doi.startsWith('http:'))) {

                if (doi.startsWith('https://dx.doi.org/') || doi.startsWith('http://dx.doi.org/')) {
                    doi = doi.replace('https://dx.doi.org/', '');
                    doi = doi.replace('http://dx.doi.org/', '');

                    url = 'https://doi.org/' + doi;
                }else{
                    return formatURL(doi);
                }
            }
            else{
                //if they happen to start this with dx.doi.org, pull that out
                
                doi = doi.replace('dx.doi.org/', '');
                doi = doi.replace('doi.org/', '');

                url = 'https://doi.org/' + doi;
            }
        }
        else{
            url = doi;
        }

        url = formatURL(url);
    }

    return url;
}

function fixPageDash(pages){
    
    let cleanPages = ''

    cleanPages = pages.replace('-', '–');
    cleanPages = cleanPages.replace(' - ', '–');
    cleanPages = cleanPages.replace(' – ', '–');

    return cleanPages;
}

function getAuthorPart(refData, groupAuthorsAsAuthors, excludeRole){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof groupAuthorsAsAuthors === 'undefined') {
        groupAuthorsAsAuthors = true;
    }

    if (typeof excludeRole === 'undefined') {
        excludeRole = false;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        let primaryType = getPrimaryContributorType(refData);

        // //if the primary type is not an author, verify that we don't have any authors
        // if (primaryType !== 'author' && primaryType !== 'groupAuthor') {
        //     let authorContribs = getContributorsByType('author', contributors);

        //     //we have authors, they just arent added first, make them primary anyways
        //     if (authorContribs.length > 0) {
        //         primaryType = 'author';
        //     }
        // }

        let pluralLabel = false;
        let primaryContributors = getContributorsByType(primaryType, contributors);

        let index = 0;

        if (!groupAuthorsAsAuthors) {
            if (primaryType == 'author') {
                _forEach(primaryContributors, (contrib) => {
                    if (typeof contrib !== 'undefined') {
                        if (contrib.type == 'groupAuthor') {
                            primaryContributors.splice(index, 1);
                        }
                    }
    
                    index++;
                });
            }
        }

        //1 - Last, First Middle Suffix.
        if (primaryContributors.length == 1) {
            contributorstring = getReferenceName(primaryContributors[0]);

            // if (primaryType == ) {
                
            // }
        }    


        //2 - Last, First Middle Suffix., and First Middle Last Suffix.
        if (primaryContributors.length == 2) {

            //if the first entry is a group author a comma is not needed, otherwise it is
            if (primaryType == 'groupAuthor') {
                contributorstring = getReferenceName(primaryContributors[0]) + ' and ' + getSecondaryReferenceName(primaryContributors[1]);
            }
            else{
                contributorstring = getReferenceName(primaryContributors[0]) + ', and ' + getSecondaryReferenceName(primaryContributors[1]);
            }

            pluralLabel = true;
        }   
        
        //3 or more - Last, First Middle Suffix - list them all
        if (primaryContributors.length > 2) {

            let contribStringArray = [];

            //throw the contrib strings in an array before we build
            let counter = 1;
            _forEach(primaryContributors, (contrib) => {
                let contribString = '';
                
                if (counter == 1) {
                    contribString = getReferenceName(contrib);
                }
                else{
                    contribString = getSecondaryReferenceName(contrib);
                }

                contribStringArray.push(contribString);
                counter++;
            });

            counter = 1;
            contribStringArray.forEach(contrib => {
                if (counter > 1) {

                    if (counter == contribStringArray.length) {
                        contributorstring = contributorstring + ', and '
                    }
                    else{
                        contributorstring = contributorstring + ', ';
                    }
                }

                contributorstring = contributorstring + contrib;
                counter++;
            });

            // //if the first entry is a group author a comma is not needed, otherwise it is
            // if (primaryType == 'groupAuthor') {
            //     contributorstring = getReferenceName(primaryContributors[0]) + ' et al.';
            // }
            // else{
            //     contributorstring = getReferenceName(primaryContributors[0]) + ', et al.';
            // }

            pluralLabel = true;
        }

        //if our primary is something other than an author...label it
        let contributorLabel = getContributorLabel(primaryType, pluralLabel);

        if (contributorLabel.length > 0 && !excludeRole) {
            contributorstring = contributorstring + ', ' + contributorLabel;
        }
    }

    return contributorstring.addClosure();
}

function getAuthorPartSort(refData, groupAuthorsAsAuthors){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof groupAuthorsAsAuthors === 'undefined') {
        groupAuthorsAsAuthors = true;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        let primaryType = getPrimaryContributorType(refData);
        let primaryContributors = getContributorsByType(primaryType, contributors);

        let index = 0;

        if (!groupAuthorsAsAuthors) {
            if (primaryType == 'author') {
                _forEach(primaryContributors, (contrib) => {
                    if (typeof contrib !== 'undefined') {
                        if (contrib.type == 'groupAuthor') {
                            primaryContributors.splice(index, 1);
                        }
                    }
    
                    index++;
                });
            }
        }

        //throw the contrib strings in an array before we build
        _forEach(primaryContributors, (contrib) => {
            let contribString = getReferenceName(contrib);;
            
            contributorstring = contributorstring + ' ';
            contributorstring = contributorstring + contribString;
        });
    }

    return contributorstring;
}

function getContributorLabel(primaryType, pluralLabel){
    let contributorLabel = '';

    if (typeof primaryType === 'undefined') {
        return '';
    }

    if (typeof pluralLabel === 'undefined') {
        pluralLabel = false;
    }

    //if our primary is something other than an author...label it
    if (primaryType != 'author') {
        switch (primaryType) {
            case "editor":
                
                if (pluralLabel) {
                    contributorLabel = 'eds.';
                }
                else{
                    contributorLabel = 'ed.';
                }
            
                break;
            case "translator":
            case "chapterTranslator":
                
                if (pluralLabel) {
                    contributorLabel = 'trans.';
                }
                else{
                    contributorLabel = 'trans.';
                }

                break;
            case "illustrator":
                
                if (pluralLabel) {
                    contributorLabel = 'ills.';
                }
                else{
                    contributorLabel = 'ill.';
                }

                break;
            case "narrator":

                if (pluralLabel) {
                    contributorLabel = 'narrs.';
                }
                else{
                    contributorLabel = 'narr.';
                }
                
                break;
            case "performer":
            case "groupPerformer":
                if (pluralLabel) {
                    contributorLabel = 'performers';
                }
                else{
                    contributorLabel = 'performer';
                }
                
                break;
            case "host":
                if (pluralLabel) {
                    contributorLabel = 'hosts';
                }
                else{
                    contributorLabel = 'host';
                }
                
                break;
        }  
    }

    return contributorLabel;
}

function getCitationAuthorPart(refData, titleProperty, isSubsequent, omitGroupAuthors){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        refData.contributors = [];
    }

    if (typeof isSubsequent === 'undefined') {
        isSubsequent = false;
    }

    if (typeof omitGroupAuthors === 'undefined') {
        omitGroupAuthors = false;
    }

    let contributors = refData.contributors;

    contributors = removeEmptyContributors(contributors);

    if (typeof titleProperty === 'undefined') {
        return '';
    }

    if (contributors.length > 0) {

        //determine the type of the first listed contributor
        //then grab all of that type

        // let primaryType = getStringValue(contributors[0].type);
        let primaryType = getPrimaryType(refData);
        let primaryContributors = getContributorsByType(primaryType, contributors);
        let pluralLabel = false;

        //group authors are omitted in the author portion of the first citation..not matching the ref
        //note this for websites
        if (omitGroupAuthors) {
            let index = 0;
            _forEach(primaryContributors, (contrib) => {

                if (contrib) {
                    if (contrib.type == 'groupAuthor') {
                        primaryContributors.splice(index, 1);
                    }
                }
                index++;
            });
        }
        
        //1 - Last, First Middle Suffix.
        if (primaryContributors.length == 1) {
            contributorstring = getCitationName(primaryContributors[0], isSubsequent);
        }    

        //2 - Last, First Middle Suffix., and First Middle Last Suffix.
        if (primaryContributors.length == 2) {
            // let commaChar = ',';
            // if (isSubsequent) {
            //     commaChar = '';
            // }

            // contributorstring = getCitationName(primaryContributors[0], isSubsequent) + commaChar + ' and ' + getCitationName(primaryContributors[1], isSubsequent);
            contributorstring = getCitationName(primaryContributors[0], isSubsequent) + ' and ' + getCitationName(primaryContributors[1], isSubsequent);
            pluralLabel = true;
        }
        
        //3 - Last, First Middle Suffix., Last, First Middle Suffix., and First Middle Last Suffix.
        if (primaryContributors.length == 3) {
            contributorstring = getCitationName(primaryContributors[0], isSubsequent) + ', ' + getCitationName(primaryContributors[1], isSubsequent) +  ', and ' + getCitationName(primaryContributors[2], isSubsequent);
            pluralLabel = true;
        }
        
        //3 or more - Last, First Middle Suffix, et al. 
        if (primaryContributors.length > 3) {
            contributorstring = getCitationName(primaryContributors[0], isSubsequent) + ' et al.';
            pluralLabel = true;
        }

        if (!isSubsequent) {
            //if our primary is something other than an author...label it
            let contributorLabel = getContributorLabel(primaryType, pluralLabel);

            if (contributorLabel.length > 0) {
                contributorstring = contributorstring + ', ' + contributorLabel;
            }
        }
    }

    //check to make sure that the publisher string is not the same as the primary contributor
    //if so, if we have a title property, we need ot use it instead
    // let primaryRefString = getAuthorPart(refData);

    // if (primaryRefString.length == 0) {

    //     contributorstring = '';

    //     if (titleProperty.length > 0) {
    //         let title = getStringValue(refData[titleProperty]);

    //         if (title.length > 0) {
    //             //if this is a description, remove the brackets and don't italicize
    //             if (title.startsWith('[')) {
    //                 title = removeDescriptionFromTitle(title);

    //                 // title = shortenGroupName(title);
    //             }
    //             else{
    //                 // title = shortenGroupName(title);
    //                 title = title.toTitleCase();
    //             }

    //             contributorstring = title;
    //         }
    //     }
    // }
  
    return contributorstring;
}

function getCitationName(contributor, isSubsequent){
    var name = '';

    if (typeof isSubsequent === 'undefined') {
        isSubsequent = false;
    }

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

            if (isSubsequent) {
                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }
            }
            else{
                if(contributor.firstName){
                    if (contributor.firstName.trim().length > 0) {
                        let firstName = contributor.firstName;

                        //gonna assume this is an abbreviations
                        if (firstName.length == 1 ) {
                            firstName = firstName + '.';
                        }

                        name = firstName;
                    }
                }
    
                if(contributor.middleName){
                    if (contributor.middleName.trim().length > 0) {

                        if (contributor.middleName.length == 1) {
                            name = name + ' ' + contributor.middleName.toUpperCase() + ".";
                        }
                        else
                        {
                            name = name.appendData(contributor.middleName); 
                        }
                        
                    }
                }
    
                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }

                if(contributor.suffix){
                    if (contributor.suffix.trim().length > 0) {

                        let suffix = contributor.suffix.trim();

                        if (suffix.toLowerCase() == 'jr' 
                            || suffix.toLowerCase() == 'sr') {
                            suffix = suffix + '.';
                        }

                        name = name.appendData(suffix);
                    }
                }
            }
            
            break;
        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':

            if (isSubsequent) {
                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }
            }
            else{
                if(contributor.firstName){
                    if (contributor.firstName.trim().length > 0) {
                        let firstName = contributor.firstName.trim();

                        //gonna assume this is an abbreviations
                        if (firstName.length == 1 ) {
                            firstName = firstName + '.';
                        }

                        name = firstName;
                    }
                }
    
                if(contributor.middleName){
                    if (contributor.middleName.trim().length > 0) {
                        name = name.appendData(contributor.middleName.getInitial()); 
                    }
                }
    
                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }

                if(contributor.suffix){
                    if (contributor.suffix.trim().length > 0) {
                        name = name.appendData(contributor.suffix.trim())
                    }
                }

                if (contributor.type == 'chair') {
                    name = name + ', chair'
                }
            }

            break;
        case 'userName':

            if (isSubsequent) {
                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }

                // if(contributor.name){
                //     if (contributor.name.trim().length > 0) {

                //         if (name.length == 0) {
                //             name = name.appendData(contributor.name.trim());
                //         }
                //         else{
                //             name = name.appendData(contributor.name.trim().parenthesis());
                //         }
                //     }
                // }

                if (name.length == 0) {
                    if(contributor.name){
                        if (contributor.name.trim().length > 0) {
                            name = contributor.name.trim();    
                        }
                    }
                }
            }
            else{
                if(contributor.firstName){
                    if (contributor.firstName.trim().length > 0) {
                        name = contributor.firstName.trim(); 
                    }

                    if (name.length == 1) {
                        name  = name + '.';
                    }
                }

                if(contributor.middleName){
                    if (contributor.middleName.trim().length > 0) {
                        name = name.appendData(contributor.middleName.getInitial()); 
                    }
                }

                if(contributor.lastName){
                    if (contributor.lastName.trim().length > 0) {
                        name = name.appendData(contributor.lastName.trim())
                    }
                }

                if(contributor.suffix){
                    if (contributor.suffix.trim().length > 0) {
                        name = name.appendData(contributor.suffix.trim())
                    }
                }

                if(contributor.name){
                    if (contributor.name.trim().length > 0) {

                        if (name.length == 0) {
                            name = name.appendData(contributor.name.trim());
                        }
                        else{
                            name = name.appendData(contributor.name.trim().parenthesis());
                        }
                    }
                }
            }

            // Albert A. Authora (username1)
            // if (isSubsequent) {
            //     if(contributor.lastName){
            //         if (contributor.lastName.trim().length > 0) {
            //             name = name.appendData(contributor.lastName.trim())
            //         }
            //     }
            // }
            // else{
            //     if(contributor.firstName){
            //         if (contributor.firstName.trim().length > 0) {
            //             name = contributor.firstName.trim(); 
            //         }
            //     }
    
            //     if(contributor.middleName){
            //         if (contributor.middleName.trim().length > 0) {
            //             name = name.appendData(contributor.middleName.getInitial()); 
            //         }
            //     }
    
            //     if(contributor.lastName){
            //         if (contributor.lastName.trim().length > 0) {
            //             name = name.appendData(contributor.lastName.trim())
            //         }
            //     }

                
            // }

            // if(contributor.lastName){
			// 	if (contributor.lastName.trim().length == 0) {
			// 		name = '';
			// 	}
			// 	else{
			// 		name = contributor.lastName.trim();                
			// 	}
			// }
            
            // let userName = '';
            // //if we dont have a last name, check user name
            // if (contributor.name && name.length == 0) {
            //     return contributor.name.trim();
            // }
            
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
        
            if(contributor.groupName){
				name = contributor.groupName.trim();
                
                //since this is a group author, shorten
                // name = shortenGroupName(name);
            }
            
            if (!isSubsequent) {
                let contributorname = getStringValue(contributor.name);
            
                if (contributorname.length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }

                    name = name + '(' + contributorname + ')';
                }
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
        case 'anonymous':
            if(contributor.name){
                name = contributor.name.trim();
            }
            
        break;
    }

    return name;
}

function getCitations(citationData, firstInfo, subsequentInfo, refData){

    let firstCitation = ''
    let subsequentCitation = '';
    let firstLocation = getCitationLocationString(citationData, false);
    let subsequentLocation = getCitationLocationString(citationData, true);

    //check to see if any data needs clearing out
    // if (citationData.namePart == 'false') {
    //     firstInfo = '';
    // }
    
    if (firstInfo.length > 0) {
        firstCitation = firstInfo;
    }

    if (subsequentInfo.length > 0) {
        subsequentCitation = subsequentInfo;
    }

    let citationVolume = getCitationVolume(citationData);

    if (firstLocation.length > 0) {

        firstCitation = citationLocationPunctuation(firstCitation, citationData);

        //for multivolume books without at volume title, volume is included
        if ((refData.type == 'book' && refData.kind == 'multivolume') || refData.type == 'series') {
            let volume = getStringValue(refData.volume);
            let volumeTitle = getStringValue(refData.volumeTitle);

            if (volume.length == 0 && citationVolume.length > 0) {
                volume = citationVolume;
                citationVolume = '';
            }

            if (volume.length > 0 && volumeTitle.length == 0) {

                //if there is no volume title and there are multiple volumes listed in 'volume', 
                //the volume number is omitted from the note and the user should put the specific volume number before the page number
                if (volume.includes('and') || volume.includes('&') || volume.includes(',')) {
                    firstCitation = firstCitation + firstLocation;
                }
                else{

                    if (citationVolume.length == 0) {
                        volume = volume + ':';
                        firstCitation = firstCitation + volume + firstLocation;
                    }
                    else{
                        firstCitation = firstCitation + firstLocation;
                    }
                }
            }
            else{
                firstCitation = firstCitation + firstLocation;
            }
        }
        else{
            firstCitation = firstCitation + firstLocation;
        }
    }

    if (subsequentLocation.length > 0) {
        
        subsequentCitation = citationLocationPunctuation(subsequentCitation, citationData);

        //for multivolume books, volume is included
        if ((refData.type == 'book' && refData.kind == 'multivolume') || refData.type == 'series') {

            let volume = getStringValue(refData.volume);
            let volumeTitle = getStringValue(refData.volumeTitle);
            let seriesTitle = getStringValue(refData.seriesTitle);

            if (volume.length > 0 && (seriesTitle.length > 0 || volumeTitle.length > 0)) {

                //if there is no volume title and there are multiple volumes listed in 'volume', 
                //the volume number is omitted from the note and the user should put the specific volume number before the page number
                if (volume.includes('and') || volume.includes('&') || volume.includes(',')) {
                    subsequentCitation = subsequentCitation + subsequentLocation;
                }
                else{
                    if (citationVolume.length == 0) {
                        volume = volume + ':';
                        subsequentCitation = subsequentCitation + volume + subsequentLocation;
                    }
                    else{
                        subsequentCitation = subsequentCitation + subsequentLocation;
                    }
                }
            }
            else{
                subsequentCitation = subsequentCitation + subsequentLocation;
            }
        }
        else{
            subsequentCitation = subsequentCitation + subsequentLocation;
        }
    }

    //trim these back
    firstCitation = firstCitation.trim();

    //make sure neither citation ends with a , or ,"
    if (firstCitation.endsWith(',')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -1);
    }

    if (firstCitation.endsWith(',"')) {
        firstCitation = firstCitation.substr(0, firstCitation.length -2) + '"';
    }

    let citation = {
        first : firstCitation.addClosure().toString(),
        subsequent : subsequentCitation.addClosure().toString()
    }

    return citation;
}

function citationLocationPunctuation(citation, citationData){
    
    if (citation.length > 0) {
        //check our punctionation

        if (citation.endsWith('\'"')) {
            citation = citation.slice(0, -2) + ',\'" ';
            return citation;
        }

        if (citation.endsWith('"')) {
            citation = citation.slice(0, -1) + '," ';
            return citation;
        }

        //if this has a punctuation, then a comma is not needed
        if (citation.endsWith('!') || citation.endsWith('!</em>') ||
        citation.endsWith('?') || citation.endsWith('?</em>')
        ){
            citation = citation + ' ';
        }
        else{
            //nothing special, add the comma
            citation = citation + ', ';
        }
    }

    return citation;
}

function getCitationLocationString(citationData, isSubsequent){
    var location = '';

    if (typeof isSubsequent === 'undefined') {
        isSubsequent = false;
    }

    let citationVolume = getCitationVolume(citationData);

    if (citationData.value == '' && citationData.type != 'actSceneLine' && citationVolume.length == 0) {
        return '';
    }

    switch (citationData.type) {
        case 'notdirect':
            location = '';
            break;
        case 'page':
            location = formatPageNumbers(citationData.value, false);

            if (isSubsequent) {
                //check for volume citation data
                if (citationVolume.length > 0) {
                    if (location.length > 0) {
                        location =  citationVolume + ':' + location;
                    }
                    else{
                        location = 'vol. ' + citationVolume;
                    }
                }
            }

            break;
        case 'paragraph':
            var label = 'para.';

            // if (usePluralLabel(citationData.value)) {
            //     label = 'para.'
            // }

            location = label + ' ' + citationData.value;
            break;
        case 'chapter':
            location = 'chap. ' + citationData.value;
            break;
        case 'track':
            location = 'track ' + citationData.value;
            break;
        case 'figure':
                var label = 'fig.';

                // if (usePluralLabel(citationData.value)) {
                //     label = 'figs.'
                // }

                location = label + ' ' + citationData.value;
            break;
        case 'table':
            var label = 'table';

            // if (usePluralLabel(citationData.value)) {
            //     label = 'tabs.'
            // }

            location = label + ' ' + citationData.value;
            break;
        case 'time':

            
            //this version is for the way film is formatted
            let value = '';
            //we need to reformat strings like 1:14:30 as 01:14:30
            let timeArray = [];

            if (citationData.value.includes(':')) {
                timeArray = citationData.value.split(":");
            }

            if (citationData.value.includes('/')) {
                timeArray = citationData.value.split("/");
            }


            if (timeArray.length > 0) {
                let newTimeFormat = '';

                if (timeArray.length == 2) {
                    newTimeFormat = '00';
                }

                timeArray.forEach(item => {
                    
                    let newItem = item;

                    switch (item.length) {
                        case 0:
                            newItem = '00';
                            break;
                        case 1:
                            newItem = '0' + item;
                            break;
                    }

                    if (newTimeFormat.length > 0) {
                        newTimeFormat = newTimeFormat + ':';
                    }

                    newTimeFormat = newTimeFormat + newItem;
                });

                value = newTimeFormat;
            }
            else{
                value = citationData.value;
            }
            
            location = value;
            
            break;
        case 'section':
            var label = 'sec.';

                if (usePluralLabel(citationData.value)) {
                    label = 'secs.'
                }

                location = label + ' ' + citationData.value.ordinalToNumber();
            break;
        case 'actSceneLine':
            location = fixPageDash(citationData.value);
            location = location.split("/").join(".");

            if (location.endsWith('.')) {
                location = location.substring(0, location.length - 1);
            }

            break;
        case 'other':
        case 'religious':

            location = citationData.label;
            //appendix is not a listed section type, but is listed as app in Tirabian
            location = location.replace('Appendix', 'app.');
            location = location.toLowerCase();

            if (citationData.label.length > 0) {
                location = location + ' ';
            }

            let citationValue = citationData.value;
                        
            location = location + citationValue;
        break;
    }

    return location;
}

function usePluralLabel(value){
    let use = false;

    if (value.includes('-') || 
        value.includes(':') ||
        value.includes('–')) {
        
            use = true;
    }

    return use;
}

function formatPageNumbers(pages, addLabel){

    if (typeof addLabel === 'undefined') {
        addLabel = true;
    }
    
    pages = fixPageDash(pages);

    // //for MLA, multiple pages are shortened and only add a + at the end
    // //so "13–21, 27" becomes "13–21+""
    // if (pages.includes(',')) {
    //     let array = pages.split(",");

    //     pages = array[0] + "+";
    // }

    //MLA also shortens page numbers in this way
    //1122–1128 becomes 1122–28
    //dupicate numbers have to have at least 2
    if (pages.includes('–')) {

        let hasPlus = false;
        if (pages.endsWith('+')) {
            hasPlus = true;
            pages = pages.slice(0, -1)
        }

        let array = pages.split("–");

        if (isInteger(array[0]) && isInteger(array[1])) {
            if (array[0].length == array[1].length) {
                if (array[0].length > 2) {
                    
                    //loop each number until we find the one that doesnt match
                    let match = -1;
                    for (var i = 0; i < array[0].length; i++) {
                        
                        if (array[0][i] != array[1][i]) {
    
                            if (match == -1) {
                                match = i;
                            }
                        }
                    }
    
                    if (match != -1) {
                        //if it's only the last number, we need to add one to it
                        if ((match + 1) == array[1].length) {
                            match = match - 1;
                        }
    
                        pages = array[0] + "–" + array[1].substring(match);
                    }
                }
            }
        }

        //re-add our plus
        if (hasPlus) {
            pages = pages + '+';
        }
    }

    if (pages.length > 0 && addLabel) {
        var label = 'p.';
        if (pages.includes('-') || pages.includes('–') || pages.includes(':') || pages.includes('+')) {
            label = 'pp.';
        }

        pages = label + ' ' + pages;
    }

    return pages;
}

function isInteger(value) {
    return !isNaN(value) && 
            parseInt(Number(value)) == value && 
            !isNaN(parseInt(value, 10));
    }

function getReferenceName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
            // Last, First Middle Suffix.
        	if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            if (contributor.firstName && contributor.firstName.trim().length > 0) {

                let firstName = contributor.firstName;

                //gonna assume this is an abbreviations
                if (firstName.length == 1 ) {
                    firstName = firstName + '.';
                }

                name = name + ', ' + firstName;
            }

			if (contributor.middleName && contributor.middleName.trim().length > 0) {

                contributor.middleName = contributor.middleName.trim()

                if (contributor.firstName.trim().length == 0) {
                    name = name + ',';
                }

                if (contributor.middleName.length == 1) {
                    name = name + ' ' + contributor.middleName.toUpperCase() + ".";
                }
                else
                {
                    name = name + ' ' + contributor.middleName;
                }

                // contributor.middleName = contributor.middleName.trim();

                // if (name.length > 0 && contributor.firstName.trim().length == 0) {
                //     name = name + ",";
                // }

                // if (contributor.middleName.includes(" ")) {
                //     let middleArray = contributor.middleName.split(" ");
                //     let middleNameString = "";
                    
                //     for (var i = 0; i < middleArray.length; i++) {
                //         if (middleNameString.length > 0) {
                //             middleNameString = middleNameString + " ";
                //         }

                //         middleNameString = middleNameString + middleArray[i].getInitial();
                //     }

                //     name = name + ' ' + middleNameString;
                // }
                // else{
                //     name = name + ' ' + contributor.middleName.getInitial();
                // }
            }

            if (contributor.suffix && contributor.suffix.trim().length > 0) {

                if (contributor.suffix.toLowerCase() == 'junior') {
                    contributor.suffix = 'Jr.';
                }

                if (contributor.suffix.toLowerCase() == 'senior') {
                    contributor.suffix = 'Sr.';
                }

                // if (contributor.suffix.toLowerCase().startsWith('jr') ||
                //     contributor.suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                // }

                name = name + ' ' + contributor.suffix.trim();
            }

            if (contributor.type == 'chair') {
                name = name + ', chair'
            }

            break;
        case 'userName':

            // if(contributor.firstName){
            //     if (contributor.firstName.trim().length > 0) {
            //         name = contributor.firstName.trim(); 
            //     }
            // }

            // if(contributor.middleName){
            //     if (contributor.middleName.trim().length > 0) {
            //         name = name.appendData(contributor.middleName.getInitial()); 
            //     }
            // }

            // if(contributor.lastName){
            //     if (contributor.lastName.trim().length > 0) {
            //         name = name.appendData(contributor.lastName.trim())
            //     }
            // }

            // if (contributor.suffix && contributor.suffix.trim().length > 0) {

            //     if (contributor.suffix.toLowerCase() == 'junior') {
            //         contributor.suffix = 'Jr.';
            //     }

            //     if (contributor.suffix.toLowerCase() == 'senior') {
            //         contributor.suffix = 'Sr.';
            //     }

            //     if (contributor.suffix.toLowerCase().startsWith('jr') ||
            //         contributor.suffix.toLowerCase().startsWith('sr')) {
                        
            //         name = name + ',';
            //     }

            //     name = name + ' ' + contributor.suffix.trim();
            // }

                        // Last, First Middle Suffix.
        	if(contributor.lastName){
				if (contributor.lastName.trim().length == 0) {
					return '';
				}
				else{
					name = contributor.lastName.trim();                
				}
			}

            if (contributor.firstName && contributor.firstName.trim().length > 0) {

                let firstName = contributor.firstName;

                if (firstName.length == 1) {
                    firstName = firstName + '.';
                }

                name = name + ', ' + firstName;
            }

			if (contributor.middleName && contributor.middleName.trim().length > 0) {
                // name = name + ' ' + contributor.middleName;
                contributor.middleName = contributor.middleName.trim();

                if (name.length > 0 && contributor.firstName.trim().length == 0) {
                    name = name + ",";
                }

                if (contributor.middleName.includes(" ")) {
                    let middleArray = contributor.middleName.split(" ");
                    let middleNameString = "";
                    
                    for (var i = 0; i < middleArray.length; i++) {
                        if (middleNameString.length > 0) {
                            middleNameString = middleNameString + " ";
                        }

                        middleNameString = middleNameString + middleArray[i].getInitial();
                    }

                    name = name + ' ' + middleNameString;
                }
                else{
                    name = name + ' ' + contributor.middleName.getInitial();
                }
            }

            if (contributor.suffix && contributor.suffix.trim().length > 0) {

                if (contributor.suffix.toLowerCase() == 'junior') {
                    contributor.suffix = 'Jr.';
                }

                if (contributor.suffix.toLowerCase() == 'senior') {
                    contributor.suffix = 'Sr.';
                }

                // if (contributor.suffix.toLowerCase().startsWith('jr') ||
                //     contributor.suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                // }

                name = name + ' ' + contributor.suffix.trim();
            }

            let userName = '';
            
            if (contributor.name) {
                userName = contributor.name.trim();
            }

            if (userName.length > 0) {
                if (name.length > 0) {
                    name = name + ' ' + userName.parenthesis();
                }
                else {
                    name = userName;
                }
            }
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
            if(contributor.groupName){
				name = contributor.groupName.trim();
            }
            
            let contributorname = getStringValue(contributor.name);
            
            if (contributorname.length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + '(' + contributorname + ')';
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
            case 'anonymous':
                if(contributor.name){
                    name = contributor.name.trim();
                }
                
            break;
    }

    return name;
}

function getSecondaryReferenceName(contributor){
    var name = '';

    switch (contributor.type) {
        case 'author':
        case 'chapterAuthor':
        case 'custom':
        case 'executiveProducer':
        case 'executiveDirector':
        case 'executiveDirectorProducer':
        case 'director':
        case 'writer':
        case 'writerDirector':
        case 'host':
        case 'interview':

        case 'editor':
        case 'chapterTranslator':
        case 'translator':
        case 'narrator':
        case 'illustrator':
        case 'originalEditor':
        case 'performer':
        case 'chair':
        case 'reviewAuthor':
        case 'reviewDirector':
        case 'reviewWriter':
        case 'reviewWriterDirector':
        case 'reviewArtist':
        case 'reviewComposer':
        case 'reviewEditor':
        case 'volumeEditor':
        
            if (contributor.firstName && contributor.firstName.trim().length > 0) {

                let firstName = contributor.firstName;

                //gonna assume this is an abbreviations
                if (firstName.length == 1 ) {
                    firstName = firstName + '.';
                }

                name = firstName;
            }

            if (contributor.middleName && contributor.middleName.trim().length > 0) {
                contributor.middleName = contributor.middleName.trim()

                if (contributor.middleName.length == 1) {
                    name = name + ' ' + contributor.middleName.toUpperCase() + ".";
                }
                else
                {
                    name = name + ' ' + contributor.middleName;
                }
            }

            // if (contributor.middleName && contributor.middleName.trim().length > 0) {
            //     name = name + ' ' + contributor.middleName.getInitial();
            // }

            if (contributor.lastName && contributor.lastName.trim().length > 0) {

                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();

                if (suffix.toLowerCase() == 'junior') {
                    suffix = 'Jr.';
                }

                if (suffix.toLowerCase() == 'senior') {
                    suffix = 'Sr.';
                }

                if (suffix.toLowerCase().startsWith('jr') ||
                    suffix.toLowerCase().startsWith('sr')) {
                        
                    // name = name + ',';
                }
            }

            if (suffix.length > 0) {
                name = name + ' ' + suffix;

                if (!name.endsWith(".")) {
                    name = name + '.'
                }
            }

            break;
        case 'userName':

            if (contributor.firstName && contributor.firstName.trim().length > 0) {

                //gonna assume this is an abbreviations
                if (contributor.firstName.length == 1 ) {
                    name = contributor.firstName + '.';
                }
                else{
                    name = contributor.firstName;
                }
            }
        
            if (contributor.middleName.length == 1) {
                name = name + ' ' + contributor.middleName.toUpperCase() + ".";
            }
            else
            {
                name = name + ' ' + contributor.middleName;
            }

            if (contributor.lastName && contributor.lastName.trim().length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }
                
                name = name + contributor.lastName.trim();
            }

            var suffix = '';
            if (contributor.suffix && contributor.suffix.trim().length > 0) {
                suffix = contributor.suffix.trim();

                if (suffix.toLowerCase() == 'junior') {
                    suffix = 'Jr.';
                }

                if (suffix.toLowerCase() == 'senior') {
                    suffix = 'Sr.';
                }

                if (suffix.toLowerCase().startsWith('jr') ||
                    suffix.toLowerCase().startsWith('sr')) {
                        
                    name = name + ',';
                }
            }

            if (suffix.length > 0) {
                name = name + ' ' + suffix;

                if (!name.endsWith(".")) {
                    name = name + '.'
                }
            }

            let userName = '';
            
            if (contributor.name) {
                userName = contributor.name.trim();
            }
            
            //see if we have username only
            if (name.trim().length == 0) {
                
                name = userName;
            }
            else{
                if (userName.length > 0) {
                    // name = name + ' [' + userName + ']';
                    name = name + ' ' + userName.parenthesis();
                }
            }
            break;
        case 'groupAuthor':
        case 'reviewGroupArtist':
        case 'groupPerformer':
            if(contributor.groupName){
				name = contributor.groupName.trim();
            }
            
            let contributorname = getStringValue(contributor.name);
            
            if (contributorname.length > 0) {
                if (name.length > 0) {
                    name = name + ' ';
                }

                name = name + '[' + contributorname + ']';
            }

            break;
        case 'testimony':
           
            if(contributor.firstName){
                name = contributor.firstName.trim();
            }

            if(contributor.lastName){

                if (contributor.lastName.trim().length > 0) {
                    if (name.length > 0) {
                        name = name + ' ';
                    }
    
                    name = name + contributor.lastName.trim();    
                }
            }

            break;
    }

    return name;
}

function getPrimaryContributorType(refData){
    let primaryType = '';

    if (typeof refData.contributors === 'undefined') {
        return primaryType;
    }

    let contributors =[];

    _forEach(refData.contributors, (item) => {
        let canBePrimary = true;

        //add any types here that cannot be a primary type
        if (item.type == 'originalEditor') {
            canBePrimary = false;
        }

        //translators can't be the primary on a translated book
        if (refData.type == 'book' && refData.kind == 'translated' && item.type == 'translator') {
            canBePrimary = false;
        }

        if (canBePrimary) {
            contributors.push(item);
        } 
    });

    if (contributors.length > 0) {
        primaryType = getStringValue(contributors[0].type);
    }

    //if the primary type is not an author, verify that we don't have any authors
    if (primaryType !== 'author' && primaryType !== 'groupAuthor' && primaryType !== 'chapterAuthor') {
        let authorContribs = getContributorsByType('author', contributors);

        //we have authors, they just arent added first, make them primary anyways
        if (authorContribs.length > 0) {
            primaryType = 'author';
        }
    }

    return primaryType;
}

function getContributorsByType(type, contributors, matchSimilarTypes){
    let typeContribs = [];

    if (typeof contributors == 'undefined'){
        return typeContribs;
    }

    //sometimes we will want to grab all authors, users, group authors, etc as the same, sometimes we won't
    if (typeof matchSimilarTypes == 'undefined'){
        matchSimilarTypes = true;
    }

    _forEach(contributors, (item) => {
        if (item.type == type) {
            typeContribs.push(item);
        }

        if (matchSimilarTypes) {
            //for authors. we also need to group in Group Authors and Usernames
            if (type == 'author') {
                if (item.type == 'groupAuthor' || item.type == 'userName' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'groupAuthor') {
                if (item.type == 'author' || item.type == 'userName' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'userName') {
                if (item.type == 'groupAuthor' || item.type == 'author' || item.type == 'chapterAuthor') {
                    typeContribs.push(item);
                }
            }

            if (type == 'chapterAuthor') {
                if (item.type == 'groupAuthor' || item.type == 'author' || item.type == 'userName') {
                    typeContribs.push(item);
                }
            }
        }

    });

    return typeContribs;
}

function getSecondaryContributorString(refData){

    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    refData.contributors = removeEmptyContributors(refData.contributors);

    if (refData.contributors.length > 0) {
        let primaryType = getPrimaryType(refData);

        // //if the primary type is not an author, verify that we don't have any authors
        // if (primaryType !== 'author' && primaryType !== 'groupAuthor') {
        //     let authorContribs = getContributorsByType('author', contributors);

        //     //we have authors, they just arent added first, make them primary anyways
        //     if (authorContribs.length > 0) {
        //         primaryType = 'author';
        //     }
        // }

        let secondaryContributors = [];

        if (refData.type == 'series' && refData.kind == 'volume') {
            //for this type, we need to get volume editors
            secondaryContributors = getContributorsByType('volumeEditor', refData.contributors);
        }
        else{
            _forEach(refData.contributors, (item) => {
                if (item.type != primaryType) {
    
                    //for authors, groupAuthors and userNames need to be filtered as well
                    if (primaryType == 'author' || primaryType == 'groupAuthor' || primaryType == 'userName' || primaryType == 'chapterAuthor') {
                        if (item.type != 'groupAuthor' && item.type != 'userName' && item.type != 'author' && item.type != 'chapterAuthor') {
                            secondaryContributors.push(item);
                        }
                    }
                    else {
                        secondaryContributors.push(item);
                    }
                }
            });
        }
        
        let seperateWithComma = false;

        //for book chapters, seperate with a comma instead of periods
        if (refData.type == 'bookChapter') {
            seperateWithComma = true;
        }

        if (secondaryContributors.length > 0) {
            //pull in this order: Authors, Editors, Translators, Illustrators, Narrators
            let typeStrings = [];

            let authors = getContributorsByType('author', secondaryContributors);
            let editors = getContributorsByType('editor', secondaryContributors);
            let translators = getContributorsByType('translator', secondaryContributors);
            let narrators = getContributorsByType('narrator', secondaryContributors);
            let illustrators = getContributorsByType('illustrator', secondaryContributors);
            let performers = getContributorsByType('performer', secondaryContributors);
            let groupPerformers = getContributorsByType('groupPerformer', secondaryContributors);
            let executiveProducers = getContributorsByType('executiveProducer', secondaryContributors);
            let editorTranslators = getEditorTranslators(secondaryContributors);
            let testimonies = getContributorsByType('testimony', secondaryContributors);
            let volumeEditors = getContributorsByType('volumeEditor', secondaryContributors);
            
            if (editorTranslators.length > 0) {
                editors = [];
                translators = [];
            }

            if (authors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(authors, 'author'));
            }

            if (editors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(editors, 'editor'));
            }

            if (translators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(translators, 'translator'));
            }

            if (narrators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(narrators, 'narrator'));
            }

            if (illustrators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(illustrators, 'illustrator'));
            }

            if (performers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(performers, 'performer'));
            }

            if (groupPerformers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(groupPerformers, 'groupPerformer'));
            }

            if (editorTranslators.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(editorTranslators, 'editorTranslator'));
            }

            if (executiveProducers.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(executiveProducers, 'executiveProducer'));
            }

            if (testimonies.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(testimonies, 'testimony'));
            }

            if (volumeEditors.length > 0) {
                typeStrings.push(getSecondaryContributorTypeString(volumeEditors, 'volumeEditor'));
            }

            //now build out our strings
            if (typeStrings.length > 0) {
                
                let counter = 0;
                typeStrings.forEach(item => {

                    if (seperateWithComma) {
                        item = item.lowerFirstLetter();
                    }

                    if (counter == 0) {
                        contributorstring = item;
                    }
                    else{
                        if (seperateWithComma) {
                            contributorstring = contributorstring + ', ' + item;
                        }
                        else{
                            contributorstring = contributorstring + '. ' + item;
                        }
                    }
                    
                    counter++;
                });
            }
        }

    }

    return contributorstring;
}

function getSecondaryContributorTypeString(contributors, type, useLeadingCustomName){
    let contributorString = '';

    if (typeof useLeadingCustomName == 'undefined'){
        useLeadingCustomName = false;
    }

    switch (contributors.length) {
        case 0:
            contributorString = ''
            break;
    
        case 1:
            contributorString = getSecondaryReferenceName(contributors[0]);
            break;

        case 2:
            contributorString = getSecondaryReferenceName(contributors[0]) + ' and ' + getSecondaryReferenceName(contributors[1]);
            break;

        default:
            // contributorString = getSecondaryReferenceName(contributors[0]) + ' et al.';

            let counter = 1;
            let contribStringArray = [];
            _forEach(contributors, (contrib) => {
                let contribString = '';
                
                // if (counter == 1) {
                //     contribString = getReferenceName(contrib);
                // }
                // else{
                    contribString = getSecondaryReferenceName(contrib);
                // }

                contribStringArray.push(contribString);
                counter++;
            });

            counter = 1;
            contribStringArray.forEach(contrib => {
                if (counter > 1) {

                    if (counter == contribStringArray.length) {
                        contributorString = contributorString + ', and '
                    }
                    else{
                        contributorString = contributorString + ', ';
                    }
                }

                contributorString = contributorString + contrib;
                counter++;
            });

            break;
    }

    let name = '';

    if (contributorString.length > 0) {
        switch (type) {
            case "author":
            case "reviewAuthor":
            case "reviewArtist":
            case "reviewGroupArtist":
                contributorString = 'by ' + contributorString;
                break;
            case "editor":
            case "originalEditor":
            case "volumeEditor":
                contributorString = 'Edited by ' + contributorString;
                break;
            case "translator":
            case "chapterTranslator":
                contributorString = 'Translated by ' + contributorString;
                break;
            case "illustrator":
                contributorString = 'Illustrated by ' + contributorString;
                break;
            case "narrator":
                contributorString = 'Narrated by ' + contributorString;
                break;
            case "testimony":
                contributorString = 'Testimony of ' + contributorString;
                break;
            case "actor":
                contributorString = 'Featuring ' + contributorString;
                break;
            case "performer":
            case "groupPerformer":
                contributorString = 'Performance by ' + contributorString;
                break;
            case "director":
                contributorString = 'Directed by ' + contributorString;
                break;
            case "host":
                contributorString = 'Hosted by ' + contributorString;
                break;
            case "writer":
                contributorString = 'Written by ' + contributorString;
                break;
            case "writerDirector":
                contributorString = 'Directed and written by ' + contributorString;
                break;
            case "editorTranslator":
                contributorString = 'Edited and translated by ' + contributorString;
            break;
            case "executiveProducer":
            case "producer":
                contributorString = 'Produced by ' + contributorString;
            break;
            case "chair":
                contributorString = 'Chaired by ' + contributorString;
            break;
            case "executiveDirector":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive director";
                }
                else{
                    contributorString = contributorString + ", executive directors";
                }
            break;
            case "executiveDirectorProducer":
                
                contributorString = 'Produced and directed by ' + contributorString;

                break;
            case "reviewComposer":

                //name = getStringValue(contributors[0].name);

                if (name.length > 0) {
                    contributorString = contributorString + ", " + name.toLowerCase();;
                }

                contributorString = 'by ' + contributorString;
            break;
            case "custom":

                name = getStringValue(contributors[0].name);

                if (name.length > 0) {

                    if (useLeadingCustomName) {
                        contributorString = name.toLowerCase() + ', ' + contributorString;
                        contributorString = String(contributorString).upperFirstLetter();
                    }
                    else{
                        contributorString = contributorString + ", " + name.toLowerCase();
                    }
                    
                }

            break;
        }   
    }

    return contributorString;
}

function getSecondaryContributorCitationString(refData){

    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (refData.contributors.length > 0) {
        // let primaryType = getStringValue(contributors[0].type);
        let primaryType = getPrimaryType(refData);

        let secondaryContributors = [];
        
		_forEach(refData.contributors, (item) => {
            if (item.type != primaryType) {

                //for authors, groupAuthors and userNames need to be filtered as well
                if (primaryType == 'author' || primaryType == 'groupAuthor' || primaryType == 'userName' || primaryType == 'chapterAuthor') {
                    if (item.type != 'groupAuthor' && item.type != 'userName' && item.type != 'author' && item.type != 'chapterAuthor') {
                        secondaryContributors.push(item);
                    }
                }
                else {
                    secondaryContributors.push(item);
                }
            }
        });

        if (secondaryContributors.length > 0) {
            //pull in this order: Authors, Editors, Translators, Illustrators, Narrators
            let typeStrings = [];

            let authors = getContributorsByType('author', secondaryContributors);
            let editors = getContributorsByType('editor', secondaryContributors);
            let translators = getContributorsByType('translator', secondaryContributors);
            let illustrators = getContributorsByType('illustrator', secondaryContributors);
            let narrators = getContributorsByType('narrator', secondaryContributors);
            let performers = getContributorsByType('performer', secondaryContributors);
            let groupPerformers = getContributorsByType('groupPerformer', secondaryContributors);
            let executiveProducers = getContributorsByType('executiveProducer', secondaryContributors);
            let editorTranslators = getEditorTranslators(secondaryContributors);
            let testimonies = getContributorsByType('testimony', secondaryContributors);
            
            if (editorTranslators.length > 0) {
                editors = [];
                translators = [];
            }

            if (authors.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(authors, 'author'));
            }

            if (editors.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(editors, 'editor'));
            }

            if (translators.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(translators, 'translator'));
            }

            if (narrators.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(narrators, 'narrator'));
            }

            if (illustrators.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(illustrators, 'illustrator'));
            }

            if (performers.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(performers, 'performer'));
            }

            if (groupPerformers.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(groupPerformers, 'groupPerformer'));
            }

            if (editorTranslators.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(editorTranslators, 'editorTranslator'));
            }

            if (executiveProducers.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(executiveProducers, 'executiveProducer'));
            }

            if (testimonies.length > 0) {
                typeStrings.push(getSecondaryContributorCitationTypeString(testimonies, 'testimony'));
            }

            //now build out our strings
            if (typeStrings.length > 0) {
                
                let counter = 0;
                typeStrings.forEach(item => {

                    if (counter == 0) {
                        //item = item.charAt(0).toUpperCase() + item.slice(1)
                        
                        contributorstring = item;
                    }
                    else{
                        contributorstring = contributorstring + ', ' + item;
                    }
                    
                    counter++;
                });
            }
        }

    }

    return contributorstring;
}

function getSecondaryContributorCitationTypeString(contributors, type, useEtAl){
    let contributorString = '';

    if (typeof useEtAl === 'undefined') {
        useEtAl = false;
    }

    switch (contributors.length) {
        case 0:
            contributorString = ''
            break;
    
        case 1:
            contributorString = getSecondaryReferenceName(contributors[0]);
            break;

        case 2:
            contributorString = getSecondaryReferenceName(contributors[0]) + ' and ' + getSecondaryReferenceName(contributors[1]);
            break;

        default:
            if (useEtAl && contributors.length > 3) {
                contributorString = getSecondaryReferenceName(contributors[0]) + ' et al.';
            }
            else{
                let counter = 1;
                let contribStringArray = [];
                _forEach(contributors, (contrib) => {
                    let contribString = '';
                    
                    contribString = getSecondaryReferenceName(contrib);

                    contribStringArray.push(contribString);
                    counter++;
                });

                counter = 1;
                contribStringArray.forEach(contrib => {
                    if (counter > 1) {

                        if (counter == contribStringArray.length) {
                            contributorString = contributorString + ', and '
                        }
                        else{
                            contributorString = contributorString + ', ';
                        }
                    }

                    contributorString = contributorString + contrib;
                    counter++;
                });
            }

            break;
    }

    if (contributorString.length > 0) {
        switch (type) {
            case "author":
            case "reviewAuthor":
            case "reviewArtist":
            case "reviewGroupArtist":
                contributorString = 'by ' + contributorString;
                break;
            case "editor":
            case "originalEditor":
                // if (contributors.length == 1) {
                    contributorString = 'ed. ' + contributorString;
                // }
                // else{
                //     contributorString = 'eds. ' + contributorString;
                // }

                break;
            case "translator":
            case "chapterTranslator":
                contributorString = 'trans. ' + contributorString;
                break;
            case "illustrator":
                contributorString = 'ill. ' + contributorString;
                break;
            case "narrator":
                contributorString = 'narr. ' + contributorString;
                break;
            case "testimony":
                contributorString = 'Testimony of ' + contributorString;
                break;
            case "performer":
            case "groupPerformer":
                contributorString = 'Performance by ' + contributorString;
                break;
            case "director":
                contributorString = 'Directed by ' + contributorString;
                break;
            case "writer":
                contributorString = 'Written by ' + contributorString;
                break;
            case "writerDirector":
                contributorString = 'Directed and Written by ' + contributorString;
                break;
            case "editorTranslator":
                contributorString = 'ed. and trans. ' + contributorString;
            break;
            case "producer":
                contributorString = 'Produced by ' + contributorString;
            break;
            case "executiveProducer":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive producer";
                }
                else{
                    contributorString = contributorString + ", executive producers";
                }
            break;
            case "chair":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", chair";
                }
                else{
                    contributorString = contributorString + ", chairs";
                }
            break;
            case "executiveDirector":
                if (contributors.length == 1) {
                    contributorString = contributorString + ", executive director";
                }
                else{
                    contributorString = contributorString + ", executive directors";
                }
            break;
            case "executiveDirectorProducer":
                
                contributorString = 'Produced and directed by ' + contributorString;

                break;
            case "reviewComposer":

                let name = getStringValue(contributors[0].name);

                if (name.length > 0) {
                    contributorString = contributorString + ", " + name.toLowerCase();;
                }
            break;
            case "custom":
                let customName = getStringValue(contributors[0].name);

                if (customName.length > 0) {
                    contributorString = customName.toLowerCase() + ', ' + contributorString;
                }
                break;
        }   
    }

    return contributorString;
}

function getEditorTranslators(contributors){

    let editorTranslatorString = '';
    let editorArray = [];

    if (typeof contributors === 'undefined') {
        return editorArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'editor') {
            editorArray.push(item);
        }
    });

    let translatorArray = [];

    _forEach(contributors, (item) => {
        if (item.type == 'translator') {
            translatorArray.push(item);
        }
    });

    if (editorArray.length > 0 &&
        (editorArray.length == translatorArray.length)) {
        
        let same = true;

        //gonna loop each array and see if we have a match
        editorArray.forEach(thisEditor =>{
            
            var found = false;

            translatorArray.forEach(thisTranslator =>{
                if (thisEditor.firstName == thisTranslator.firstName &&
                    thisEditor.middleName == thisTranslator.middleName &&
                    thisEditor.lastName == thisTranslator.lastName &&
                    thisEditor.prefix == thisTranslator.prefix &&
                    thisEditor.suffix == thisTranslator.suffix) {
                        found = true;
                }    
            });

            if (found == false) {
                same = false;
            }
        });

        if (same) {
            return translatorArray;
        }
    }

    return [];
}

function getGroupAuthorCitationString(contributors){
    let groupAuthorString = '';

    if (typeof contributors == 'undefined'){
        return '';
    }
      
    let groupAuthors = getContributorsByType('groupAuthor', contributors, false);

    if (groupAuthors.length > 0) {

        groupAuthors.forEach(item => {
            groupAuthorString = groupAuthorString.appendData(item.groupName, ' ,');
        });
    }
    
    return groupAuthorString;
}

function getChapterTranslators(contributors){
    let translatorArray = [];

    contributors = removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'chapterTranslator') {
            translatorArray.push(item);
        }
    });

    return translatorArray;
}

function getChapterTranslatorString(contributors){
    let translatorArray = [];
    let translatorString = '';

    if (typeof contributors === 'undefined') {
        return translatorArray;
    }

    contributors = removeEmptyContributors(contributors);

    let chapterTranslators = getChapterTranslators(contributors);

    switch (chapterTranslators.length) {
        case 0:
            return '';
            break;
        case 1:
            translatorString = getSecondaryReferenceName(chapterTranslators[0]);
            break;
        case 2:
            translatorString = getSecondaryReferenceName(chapterTranslators[0]) + ' and ' + getSecondaryReferenceName(chapterTranslators[1]);
            break;
        default:

            let contribStringArray = [];

            //throw the contrib strings in an array before we build
            let counter = 1;
            _forEach(chapterTranslators, (contrib) => {
                let contribString = '';
                
                contribString = getSecondaryReferenceName(contrib);

                contribStringArray.push(contribString);
                counter++;
            });

            counter = 1;
            contribStringArray.forEach(contrib => {
                if (counter > 1) {

                    if (counter == contribStringArray.length) {
                        translatorString = translatorString + ', and '
                    }
                    else{
                        translatorString = translatorString + ', ';
                    }
                }

                translatorString = translatorString + contrib;
                counter++;
            });

            break;
    }

    return 'Translated by ' + translatorString;
}

function getPublisherString(publishers, type, isCitation, verifyLocation, cleanNames){
    let publishersString = '';

    if (typeof publishers == 'undefined'){
        return '';
    }

    if (typeof verifyLocation == 'undefined'){
        verifyLocation = true;
    }

    //if a type is not passed in default to reference
    if (typeof type == 'undefined'){
        type = 'reference';
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof cleanNames === 'undefined') {
        cleanNames = true;
    }
    
    //filter these for type: reference
    let refPublishers = [];

    publishers.forEach(item => {
        if (item.type == type) {
            refPublishers.push(item);
        }
    });

    publishers = removeEmptyPublishers(publishers);

    // City, State: Publisher 1; Publisher 2; Publisher 3
    _forEach(refPublishers, (publisher) => {
        let publisherString = '';

        if (publisher.city) {
            if (publisher.city.length > 0) {
                publisherString = publisher.city;
            }
        }

        if (publisher.state) {
            if (publisher.state.length > 0) {

                if (publisherString.length > 0) {
                    publisherString = publisherString + ', ';
                }
                
                publisherString = publisherString + publisher.state;
            }
        }

        let noPubLocation = false;
        if (publisherString.length == 0) {
            noPubLocation = true;
        }

        if (publisher.name) {
            if (publisher.name.length > 0) {
                if (publisherString.length > 0) {
                    publisherString = publisherString + ': ';
                }

                let cleanName = publisher.name;

                if (cleanNames) {
                    cleanName = getCleanPublisherName(publisher.name);
                }
                
                publisherString = publisherString + cleanName;
            }
        }

        if (publisherString.length > 0) {

            if (noPubLocation && verifyLocation) {
                let prefix = 'N.p.';

                if (isCitation || publishersString.length > 0) {
                    prefix = prefix.toLowerCase();
                }

                publisherString = prefix + ': ' + publisherString;
            }
            
            if (publishersString.length > 0) {
                publishersString = publishersString + '; ';
            }

            publishersString = publishersString + publisherString;
        }

    });

    if (publishersString.length == 0 && verifyLocation) {
        if (isCitation) {
            publishersString = 'n.p.';   
        }
        else{
            publishersString = 'N.p.';
        }
    }
    
    return publishersString;
}

function getFullDateRange(startDate, endDate){

    //get the format for the start date, and we'll append the range to that
    let fullDate = getFullDate(startDate);

    let startYear = getYearFromDate(startDate);
    let startMonth = getMonthFromDate(startDate);
    let startDay = getDayFromDate(startDate);

    let endYear = getYearFromDate(endDate);
    let endMonth = getMonthFromDate(endDate);
    let endDay = getDayFromDate(endDate);

    //same year and month and day
    if (startYear == endYear && startMonth == endMonth && startDay == endDay) {
        return fullDate;
    }

    //same year and month
    if (startYear == endYear && startMonth == endMonth) {
        return endMonth + ' ' + startDay + '–' + endDay + ', ' + endYear 
    }

    //same year
    if (startYear == endYear) {
        return startMonth + ' ' + startDay + '–' + endMonth + ' ' + endDay  + ', ' + endYear 
    }

    return fullDate + '–' + getFullDate(endDate);
}


function getYearFromDate(date){
    let year = '';
    var array = date.split("/");

    switch (array.length) {
        case 3:
            if (array[2].length > 0) {
                year = dateHelper.format('1/1/' + array[2], 'yyyy');
            }
            break;
        case 2:
                if (array[1].length > 0) {
                    year = dateHelper.format('1/1/' + array[1], 'yyyy');
                }
            break;
        case 1: 
            if (array[0].length > 0) {
                year = dateHelper.format('1/1/' + array[0], 'yyyy');
            }
            break;
    }

    return year;
}

function getMonthFromDate(date){
    var array = date.split("/");
    let month = '';

    switch (array.length) {
        case 3:
            if (array[0].length > 0) {
                month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
            }

            break;
        case 2:
            if (array[0].length > 0) {
                month = dateHelper.format(array[0] + '/1/2020', 'mmmm');
            }

            break;
    }

    return month;
}

function getDayFromDate(date){
    var array = date.split("/");
    let day = '';

    switch (array.length) {
        case 3:

            if (array[1].length > 0) {
                day = dateHelper.format('1/' + array[1] + '/2020', 'd');
            }
            break;
    }

    return day;
}


// new one added for Informal Works, might be user in other places
function getPublisherStringInverted(publishers, isCitation, verifyLocation){
    let publishersString = '';

    if (typeof publishers == 'undefined'){
        return '';
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    if (typeof verifyLocation == 'undefined'){
        verifyLocation = true;
    }
    
    //filter these for type: reference
    let refPublishers = [];

    publishers.forEach(item => {
        if (item.type == 'reference') {
            refPublishers.push(item);
        }
    });

    publishers = removeEmptyPublishers(publishers);

    // Publisher 1, City, State; Publisher 2, City, State;
    _forEach(refPublishers, (publisher) => {
        let publisherString = '';

        if (publisher.name) {
            if (publisher.name.length > 0) {
                if (publisherString.length > 0) {
                    publisherString = publisherString + ': ';
                }

                let cleanName = getCleanPublisherName(publisher.name);
                
                publisherString = cleanName;
            }
        }

        if (publisher.city) {
            if (publisher.city.length > 0) {

                if (publisherString.length > 0) {
                    publisherString = publisherString + ', ';
                }

                publisherString = publisherString + publisher.city;
                
            }
        }

        if (publisher.state) {
            if (publisher.state.length > 0) {

                if (publisherString.length > 0) {
                    publisherString = publisherString + ', ';
                }
                
                publisherString = publisherString + publisher.state;
            }
        }

        let noPubLocation = false;
        if (publisherString.length == 0) {
            noPubLocation = true;
        }

       

        if (publisherString.length > 0) {

            if (noPubLocation) {
                let prefix = 'N.p.';

                if (isCitation || publishersString.length > 0) {
                    prefix = prefix.toLowerCase();
                }

                publisherString = prefix + ': ' + publisherString;
            }
            
            if (publishersString.length > 0) {
                publishersString = publishersString + '; ';
            }

            publishersString = publishersString + publisherString;
        }

    });

    if (publishersString.length == 0 && verifyLocation) {
        if (isCitation) {
            publishersString = 'n.p.';   
        }
        else{
            publishersString = 'N.p.';
        }
    }

    return publishersString;
}


function getCleanPublisherName(name){
    
    if (typeof name == 'undefined'){
        return '';
    }

    let cleanName = ' ' + name + ' ';

    // //"Publisher removes '& Co.', 'Co.', 'The' 'Inc.', 'Ltd.', 'S.A.', 'Publishing Co.', '& Company', 'Company', 'Incorporated', 'Limited', 'and Company', 'Publishing Company'.",
    let removalWords = ['& Co.', 'The', 'Inc.', 'Ltd.', 'S.A.', 'Publishing Co.', '& Company', 'Incorporated', 'Limited', 'and Company', 'Publishing Company', 'Co.', 'Company',];

    _forEach(removalWords, (removalWord) => {
        cleanName = cleanName.replace(' ' + removalWord + ' ', ' ');
    });

    cleanName = cleanName.trim();

    return cleanName;
}

function fixFormatString(format){

    if (typeof format == 'undefined'){
        return '';
    }

    if (format == 'e-book') {
        return 'E-book';
    }
    
    return format;
}

function fixFormatStringForCitation(format){

    //If the format is a generic format, then it is not capitalized in the first citation. Generics include: e-book, ebook, audiobook. These are still capitalized in the reference because they follow a period.
    if (typeof format == 'undefined'){
        return '';
    }

    if (format.toLowerCase() == 'e-book') {
        return 'e-book';
    }

    if (format.toLowerCase() == 'ebook') {
        return 'ebook';
    }

    if (format.toLowerCase() == 'audiobook') {
        return 'audiobook';
    }

    if (format.toLowerCase() == 'kindle') {
        return 'Kindle';
    }
    
    return format;
}

function fixEditionString(edition){

    if (typeof edition == 'undefined'){
        return '';
    }

    if (edition.length > 0) {

        //if this is an integer, make it ordinal
        if (edition.isInteger()) {
            edition = edition.getOrdinalValue();

            edition = edition + " ed.";
        }

        edition = edition.replace('Edition', 'ed');

        //add ed if needed
        if (!edition.includes(' ed.') &&
            !edition.toLowerCase().includes(' edition') &&
            !edition.includes(' ed')) {
            edition = edition + ' ed.'
        }
        
        // //if the string includes ed., is not at the end, and does not follow with a comma, add it
        // if (edition.includes('ed.') &&
        //     !edition.endsWith('ed.') &&
        //     !edition.includes('ed.,')) {
        //     edition = edition.replace('ed.', 'ed.,');
        // }
    }
    
    return edition;
}

function convertNumberToText (num) {

    var th = ['','thousand','million', 'billion','trillion'];
    var dg = ['zero','one','two','three','four', 'five','six','seven','eight','nine'];
    var tn = ['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen', 'seventeen','eighteen','nineteen'];
    var tw = ['twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    num = num.toString();
    num = num.replace(/[\, ]/g,'');
    if (num != parseFloat(num)) return num;
    var x = num.indexOf('.');

    if (x == -1)
        x = num.length;

    if (x > 15)
        return num;

    var n = num.split(''); 
    var str = '';
    var sk = 0;
    for (var i=0;   i < x;  i++) {
        if ((x-i)%3==2) { 
            if (n[i] == '1') {
                str += tn[Number(n[i+1])] + ' ';
                i++;
                sk=1;
            } else if (n[i]!=0) {
                str += tw[num[i]-2] + ' ';
                sk=1;
            }
        } else if (n[i]!=0) { // 0235
            str += dg[n[i]] +' ';
            if ((x-i)%3==0) str += 'hundred ';
            sk=1;
        }
        if ((x-i)%3==1) {
            if (sk)
                str += th[(x-i-1)/3] + ' ';
            sk=0;
        }
    }

    if (x != num.length) {
        var y = num.length;
        str += 'point ';
        for (var i=x+1; i<y; i++)
            str += dg[n[i]] +' ';
    }
    return str.replace(/\s+/g,' ');
}

function removeTags(str) {
    //tutorialspoint.com/how-to-remove-html-tags-from-a-string-in-javascript
    if ((str===null) || (str===''))
    return false;
    else
    str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
}

function getPrimaryType(refData){

    let primaryType = '';

    if (typeof refData.contributors === 'undefined') {
        return primaryType;
    }

    let contributors =[];

    _forEach(refData.contributors, (item) => {
        let canBePrimary = true;

        //add any types here that cannot be a primary type
        if (item.type == 'originalEditor') {
            canBePrimary = false;
        }

        //translators can't be the primary on a translated book
        if (refData.type == 'book' && refData.kind == 'translated' && item.type == 'translator') {
            canBePrimary = false;
        }

        if (canBePrimary) {
            contributors.push(item);
        } 
    });

    if (contributors.length == 0) {
        return '';
    }
    else{
        primaryType = getStringValue(contributors[0].type);
    }

    //if the primary type is not an author, verify that we don't have any authors
    if (primaryType !== 'author' && primaryType !== 'groupAuthor') {
        let authorContribs = getContributorsByType('author', contributors);

        //we have authors, they just arent added first, make them primary anyways
        if (authorContribs.length > 0) {
            primaryType = 'author';
        }
    }

    return primaryType;
}

function removeEmptyContributors(contributors){
    
    //remove any empty contributors from the array
    if (typeof contributors === 'undefined') {
        return [];
    }

    let cleanContributors = [];

    if (contributors.length > 0) {
        _forEach(contributors, (contributor) => {
        
            if (typeof contributor.type !== 'undefined') {

                switch (contributor.type) {
                    case 'author':
                    case 'chapterAuthor':
                    case 'custom':
                    case 'executiveProducer':
                    case 'executiveDirector':
                    case 'executiveDirectorProducer':
                    case 'director':
                    case 'writer':
                    case 'writerDirector':
                    case 'host':
                    case 'interview':
                       
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'editor':
                    case 'chapterTranslator':
                    case 'translator':
                    case 'narrator':
                    case 'illustrator':
                    case 'originalEditor':
                    case 'performer':
                    case 'chair':
                    case 'reviewAuthor':
                    case 'reviewDirector':
                    case 'reviewWriter':
                    case 'reviewWriterDirector':
                    case 'reviewArtist':
                    case 'reviewComposer':
                    case 'reviewEditor':
                    case 'volumeEditor':

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0){
                                cleanContributors.push(contributor);
                        }
            
                        break;
                    case 'userName':
            
                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0 ||
                            contributor.middleName.length > 0 ||
                            contributor.suffix.length > 0 ||
                            contributor.name.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupAuthor':
                    case 'reviewGroupArtist':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'groupPerformer':

                        if (typeof contributor.groupName === 'undefined') {
                            break;
                        }

                        if (typeof contributor.abbreviation === 'undefined') {
                            break;
                        }

                        if(contributor.groupName.length > 0 ||
                            contributor.abbreviation.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                    case 'testimony':

                        if(contributor.lastName.length > 0 ||
                            contributor.firstName.length > 0){
                                cleanContributors.push(contributor);
                        }
                       
                        break;
                    case 'anonymous':

                        if (typeof contributor.name === 'undefined') {
                            break;
                        }

                        if(contributor.name.length > 0){
                                cleanContributors.push(contributor);
                        }

                        break;
                }
            }
        
        });
    }

    return cleanContributors;
}

function removeEmptyPublishers(publishers) {
    //remove any empty publishers from the array
    if (typeof publishers === 'undefined') {
        return [];
    }

    let cleanPublishers = [];

    if (publishers.length > 0) {

        _forEach(publishers, (publisher) => {
            let foundSomething = false;
            
            if (typeof publisher.type !== 'undefined') {
                if (publisher.type.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.city !== 'undefined') {
                if (publisher.city.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.name !== 'undefined') {
                if (publisher.name.length > 0) {
                    foundSomething = true;
                }
            }

            if (typeof publisher.state !== 'undefined') {
                if (publisher.state.length > 0) {
                    foundSomething = true;
                }
            }

            if (foundSomething) {
                cleanPublishers.push(publisher);
            }

        });

    }

    return cleanPublishers;
}

function getCitationVolume(citationData) {
    let volume = getStringValue(citationData.volume);

    return volume;
}

function replaceWithAbbreviations(text){

    text = text.replaceAll('Appendix', 'App.');
    text = text.replaceAll('Book', 'Bk.');
    text = text.replaceAll('Chapter', 'Chap.');
    text = text.replaceAll('Figure', 'Fig.');
    text = text.replaceAll('Manuscript', 'MS');
    text = text.replaceAll('Page', 'P.');
    text = text.replaceAll('Paragraph', 'Para.');
    text = text.replaceAll('Part', 'Pt.');
    text = text.replaceAll('Section', 'Sec.');
    text = text.replaceAll('Supplement', 'Supp.');
    text = text.replaceAll('Series', 'Ser.');
    text = text.replaceAll('Volume', 'Vol.');

    return text;
}

function isSelfPublished(refData){
    let isSelf = false;

    let primaryType = getPrimaryContributorType(refData);
    let primaryContributors = getContributorsByType(primaryType, refData.contributors);

    if (typeof refData.publishers === 'undefined') {
        return false;
    }

    if (primaryContributors.length == 1 && refData.publishers.length == 1) {
        if (primaryContributors[0].type == 'groupAuthor') {
            if (primaryContributors[0].groupName == refData.publishers[0].name) {
                isSelf = true;
            }
        }
    }

    return isSelf;
}

function removeDescriptionFromTitle(title)
{
    title = title.trim();

    if (title.startsWith('[')) {
        title = title.slice(1);

        //remove the ending bracket too
        if (title.endsWith(']')) {
            title = title.slice(0, -1);
        }
    }

    return title;
}

// so far this is only used for runtime
// update this so it comes in as a string not a controlled array

function parseTimeStampString(timeStringIn){
    // 00:04:27 -> 4:27
    // 01:05:15 -> 1:05:27
    // 12:47 -> 12:47
   
    if(timeStringIn){
        if(timeStringIn.includes(':')){
            let timeArray = timeStringIn.split(':');

            // the time array can be different lengths based on data we get at this point
            switch(timeArray.length){
                case 1: // SS (assumed)
                    return parseInt(timeArray[0]);
    
                case 2: // MM:SS (assumed)
                    return parseInt(timeArray[0]) + ':' + timeArray[1];
    
                case 3: // HH:MM:SS
                    if(timeArray[0] === '00'){
                        // hour is blank, ignore it
                        return parseInt(timeArray[1]) + ':' + timeArray[2];
                    } else {
                        // there is actual data for the hour
                        return parseInt(timeArray[0]) + ':' + timeArray[1] + ':' + timeArray[2];
                    }
            }
        } else {
            return timeStringIn;
        }
        
    }
    
    return '';
}

function isPublisherAndAuthorSame(refData){
    let isPublisherAndAuthorSame = false;

    // loop through each contributor
    _forEach(refData.contributors, (contributorBlock)=>{
        if(_has(contributorBlock, 'groupName') && contributorBlock.groupName !== ''){
            // this contributor has a groupname that is NOT blank, compare it
            
            // loop through each publisher
            _forEach(refData.publishers, (publisherBlock)=>{
                // console.log(publisherBlock);
                if(_has(publisherBlock, 'name') && publisherBlock.name === contributorBlock.groupName){
                    // this publisher has a name that matches the contributor Group Name
                    isPublisherAndAuthorSame = true;
                    return false;   // stop looping when you find one
                }
            });//e:forEach
        }
    });//e:forEach

    return isPublisherAndAuthorSame;
}

function getOrdinalValue(value){
    //https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
    if (isInteger(value)) {
        var j = value % 10,
        k = value % 100;
        if (j == 1 && k != 11) {
            return value + "st";
        }
        if (j == 2 && k != 12) {
            return value + "nd";
        }
        if (j == 3 && k != 13) {
            return value + "rd";
        }
        return value + "th";
    }

    return value;
}