const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'reference') {
        citationTitle = shared.getStringValue(refData.entry);
    }
    else{
        citationTitle = shared.getStringValue(refData.title);   
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    let type = shared.getStringValue(refData.type);

    if (type == 'reference') {
        titleProperty = 'entry';
        isSecondaryTitle = true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = '';


    if (type == 'reference') {
        title = shared.getStringValue(refData.entry);
        
        if (title.length > 0) {

            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.doubleQuote();
            }
        }
    }
    else{
        title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.italicize();
            }
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);


    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let version = shared.getStringValue(refData.version);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    publicationDate = shared.formatOriginalPublicationDate(publicationDate);

    let publishers = shared.getPublisherString(refData.publishers, 'reference', true);

    switch (type) {
        case 'computer':
            
            container1 = container1.appendData(version, ', ', 'version');
            container1 = container1.appendData(publishers, ', ');

            container1 = container1.appendData(publicationDate, ', ');

            let url = shared.getStringValue(refData.url);

            if (url.length > 0) {
                url = shared.formatURL(url);
                container1 = container1.appendData(url, ', ');
            }

            break;
        case 'mobile':
        
            container1 = container1.appendData(version, ', ', 'version');
            container1 = container1.appendData(publicationDate, ', ');

            break;
        case 'reference':
        
            let title = shared.getStringValue(refData.title);

            if (title.length > 0) {
                //if this is a description, remove the brackets and don't italicize
                if (title.startsWith('[')) {
                    title = shared.removeDescriptionFromTitle(title);
                }
                else{
                    title = title.replaceAmpersand();
                    title = title.toTitleCase();
                    title = title.italicize();
                }
                container1 = container1.appendData(title, '');
            }

            container1 = container1.appendData(version, ', ', 'version');
            container1 = container1.appendData(publicationDate, ', ');

            break;
    }

    //do not upper the the first letter if we start with a publisher
    if (publishers == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publishers)) {
            container1 = container1.upperFirstLetter();
        }
    }
    
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type != 'computer') {
        let publishers = shared.getPublisherString(refData.publishers, 'reference', true);
        container2 = container2.appendData(publishers, ', ');

        let url = shared.getStringValue(refData.url);

        if (url.length > 0) {
            url = shared.formatURL(url);
            container2 = container2.appendData(url, ', ');
        }
    }
    
    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //if all they have given is a description, then we do not need to add the label
    let title = '';
    let descriptionOnly = false;

    if (type == 'reference') {
        title = shared.getStringValue(refData.entry);
    }
    else{
        title = shared.getStringValue(refData.title);
    }

    if (title.length > 0) {
        if (title.startsWith('[')) {
            descriptionOnly = true;
        }
    }

    if (!descriptionOnly) {
        if (type == 'computer') {
            secondSupplemental = secondSupplemental.appendData('Computer software.', '');
        }
        else{
            secondSupplemental = secondSupplemental.appendData('Mobile app.', '');
        }
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}