const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

//Breakdown Notes
/*
No type & kind

[AuthorPart] [DatePart] [TitlePart] [SourcePart]

AuthorPart
    - Principle investigators, group authors
    - Inverted names

DatePart
    - Project start & end date

TitlePart
    - Title of the grant, grant or project number, "[Grant]"

SourcePart
    - Funding Institute/Center, URL

*/


function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            authorString = refData.title.toTitleCase().italicize();
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    //<em>Title of the grant: Subtitle of the grant</em> (Grant description number 12345) [Grant].
    //- Title of the grant, grant or project number, "[Grant]"
    let titlePart = '';

    let title = shared.getStringValue(refData.title);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        if (title.startsWith('[')) {
            titlePart = title;
        }
        else{
            titlePart = title.italicize();   
        }    
    }

    let description = shared.getStringValue(refData.description);

    if (description.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(' + description + ')';
    }

    if (titlePart.length > 0) {
        titlePart = titlePart + ' [Grant].';
    }

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {

        if (!authorArray[authorArray.length - 1].type == 'groupAuthor') {
            authorPart = authorPart + '.'
        }
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length > 0) {

        if (authorArray.length == 1) {
            authorPart = authorPart + ' (Principal Investigator).';
        }
        else{
            authorPart = authorPart + ' (Principal Investigators).';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Funding Institute/Center, URL
    //Grant Institution; Grant Institution B; Grant Institution C. https://www.perrla.com/grant"
    let sourcePart = '';
    //format these like publishers
    let institutionString = shared.getPublisherString(refData.institution);

    if (institutionString != '.') {

        let authorArray = shared.getAuthors(refData.contributors);
        let authorString = shared.getReferenceNameString(authorArray, false);

        if (institutionString != authorString + '.') {
            sourcePart = institutionString;
        }
    }

    //url
    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}