/*
*****Apparatus*****
 
FORM: https://docs.google.com/spreadsheets/d/1_IuQwfTSRuBqUB4N2LQDl3BqT4knVgq97A_PaTEQAZ8/edit#gid=1084144617
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-apparatus-equipment

TYPES & KINDS:
    apparatus
        - This is for an apparatus or piece of equipment. 
        [contributors]. ([publicationDate]). <em>[title]</em> ([model]) [Apparatus]. [url]
    combo
        - This is for an apparatus or piece of equipment that also includes software with it under the same name. 
        [contributors]. ([publicationDate]). <em>[title]</em> ([model]) [Apparatus and software]. [url]

AuthorPart
    contributor.groupAuthor
        - The company responsible for the apparatus, equipment, & software.

DatePart
    publicationDate
        - The year the apparatus was published or created.
        - Follows YYYY

TitlePart
    title
        - The name of the apparatus & software
    model
        - The model number for the apparatus or equipment.  Only included if not already in the title.
        - Placed in parentheses after the title.

SourcePart
    url
        - A url for the webpage for the apparatus or equipment.

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
        //for authors, we need to limit this to Authors and usernames
        let authorArray = [];

        _forEach(refData.contributors, (item) => {
            if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
                authorArray.push(item);
            }
        });
    
        let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
        let year = shared.getYear(refData.publicationDate);
        let type = shared.getStringValue(refData.type);
        
        //if there is not author string, we need to use the title
        if (authorString.length == 0) {
    
            let editorArray = shared.getEditors(refData.contributors);
    
            if (editorArray.length > 0) {
                authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
            }
            else{
                let title = shared.getStringValue(refData.title);
    
                if (title.length > 0) {
                    authorString = title;
                }
            }
        }
    
        //if there is no year, use n.d.
        if (year.length == 0) {
            year = 'n.d.';
        }
        else{
            year = shared.fixPageDash(year);
        }
    
        let citation = shared.getCitations(citationData, authorString, year, authorArray);
    
        return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';
    let type = shared.getStringValue(refData.type);

    let title = shared.getStringValue(refData.title);
    let model = shared.getStringValue(refData.model);

    if (title.length > 0) {
        titlePart = title.italicize();
    }

    if (model.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(' + model + ')';
    }

    let label = '';

    switch (type) {
        case 'apparatus':
            label = '[Apparatus]';
            break;
        case 'combo':
            label = '[Apparatus and software]';
            break;
    }

    if (label.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + label;
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';
    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url);
        sourcePart = sourcePart + url;
    }

    return sourcePart;
}