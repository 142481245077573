module.exports = {
  getReference,
  getCitation
}
  
function getReference(type, refData){
    var typeEngine = getTypeEngine(type);
    var reference = typeEngine.getReference(refData);
      
    return reference;
}

function getCitation(type, refData, citationData, paperRefs){
  var typeEngine = getTypeEngine(type);
  var citation = typeEngine.getCitation(refData, citationData, paperRefs);
    
  //add the brackets around the citation
  // if (citation.first.length > 0) {
  //   citation.first = "(" + citation.first + ")";  
  // }
  
  // if (citation.subsequent.length > 0) {
  //   citation.subsequent = "(" + citation.subsequent + ")";  
  // }

  return citation;
}

function getTypeEngine(type){
  var typeEngine = null;

    typeEngine = require('./Turabian9-' + type +'.js');

    return typeEngine;
}