const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
	// let kind = shared.getStringValue(refData.kind);

    let workTitle;
    let translatedAnthologyTitle = shared.getStringValue(refData.translatedAnthologyTitle);;
    let translatedEntryTitle = shared.getStringValue(refData.translatedEntryTitle);

	if(type === 'anthology'){
		workTitle = shared.getStringValue(refData.anthologyTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        }
        else{
            if(translatedAnthologyTitle.length > 0){
                workTitle = workTitle.replaceAmpersand().italicize();
            } else {
                workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
            }
        }
        
	} else if(type === 'entry'){
        workTitle = shared.getStringValue(refData.entryTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        }
        else{
            if(translatedEntryTitle.length > 0){
                workTitle = workTitle.replaceAmpersand().doubleQuote();
            } else {
                workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
            }
        }
	}
    
    titlePart = titlePart.appendData(workTitle);
    

    if(type === 'anthology'){
		//translatedAnthologyTitle
        if(translatedAnthologyTitle.length > 0){
            titlePart = titlePart.appendData('[' + translatedAnthologyTitle.replaceAmpersand() + ']', ' ', '', '');  
        }
		
	} else if(type === 'entry'){
		//translatedEntryTitle
        if(translatedEntryTitle.length > 0){
            titlePart = titlePart.appendData('[' + translatedEntryTitle.replaceAmpersand() + ']', ' ', '', '');  
        }

	}
    titlePart = titlePart.addClosure();

    if(type === 'anthology'){
		//originalPublicationDate
		// let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		// originalPublicationDate = shared.getFullDate(originalPublicationDate);
		// titlePart = titlePart.appendData(originalPublicationDate, ', ');
	} else if(type === 'entry'){
		//originalPublicationDate
		let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
		originalPublicationDate = shared.getFullDate(originalPublicationDate);
        if(originalPublicationDate !== 'n.d.'){
		    titlePart = titlePart.appendData(originalPublicationDate);
            titlePart = titlePart.addClosure();
        }

        let anthologyTitle = shared.getStringValue(refData.anthologyTitle);
        if(translatedAnthologyTitle.length > 0){
            anthologyTitle = anthologyTitle.replaceAmpersand().italicize();
        } else {
            anthologyTitle = anthologyTitle.replaceAmpersand().toTitleCase().italicize();
        }

        titlePart = titlePart.appendData(anthologyTitle, ' ', 'In');
        
        if(translatedAnthologyTitle.length > 0){
            titlePart = titlePart.appendData('[' + translatedAnthologyTitle.replaceAmpersand() + ']', ' ', '', '');  
        }
        titlePart = titlePart.addClosure();
	}

    let secondaryContributors = shared.getSecondaryContributorString(refData);
    titlePart = titlePart.appendData(secondaryContributors);
    
    
    if(type === 'anthology'){
        titlePart = titlePart.addClosure();
    }

    let pageNumbers = shared.getStringValue(refData.pageNumbers);
    pageNumbers = shared.fixPageDash(pageNumbers);
    if(secondaryContributors.length > 0){
        titlePart = titlePart.appendData(pageNumbers, ', ');
    } else {
        titlePart = titlePart.appendData(pageNumbers);
    }
    
    titlePart = titlePart.addClosure();

    let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
    titlePart = titlePart.appendData(edition);

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    if (shared.isSelfPublished(refData)) {
        publicationPart = publicationPart.appendData('Self-published');
    } else{
        let publisherString = shared.getPublisherString(refData.publishers);
        publicationPart = publicationPart.appendData(publisherString);
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    publicationPart = publicationPart.appendData(publicationDate, ', ');
    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);
    
    let translatedAnthologyTitle = shared.getStringValue(refData.translatedAnthologyTitle);
    let translatedEntryTitle = shared.getStringValue(refData.translatedEntryTitle);

    let workTitle;
    if(type === 'anthology'){
		workTitle = shared.getStringValue(refData.anthologyTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        }
        else{
            if(translatedAnthologyTitle.length > 0){
                workTitle = workTitle.replaceAmpersand().italicize();
            } else {
                workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
            }
        }

	} else if(type === 'entry'){
        workTitle = shared.getStringValue(refData.entryTitle);

        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        }
        else{
            if(translatedEntryTitle.length > 0){
                workTitle = workTitle.replaceAmpersand().doubleQuote();
            } else {
                workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
            }
        }
        
	}
    
    firstInfo = firstInfo.appendData(workTitle, ", ");

    if(type === 'anthology'){
		//translatedAnthologyTitle
		
        if(translatedAnthologyTitle.length > 0){
            firstInfo = firstInfo.appendData('[' + translatedAnthologyTitle.replaceAmpersand() + ']', ' ', '', '');  
        }

	} else if(type === 'entry'){
		//translatedEntryTitle
		
        if(translatedEntryTitle.length > 0){
            firstInfo = firstInfo.appendData('[' + translatedEntryTitle.replaceAmpersand() + ']', ' ', '', '');  
        }

        //originalPublicationDate
        let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
        originalPublicationDate = shared.getFullDate(originalPublicationDate);
        if(originalPublicationDate !== 'n.d.'){
            firstInfo = firstInfo.appendData(originalPublicationDate.parenthesis(), ', ');
        }

        let anthologyTitle = shared.getStringValue(refData.anthologyTitle);
        if(translatedAnthologyTitle.length > 0){
            anthologyTitle = anthologyTitle.replaceAmpersand().italicize();
        } else {
            anthologyTitle = anthologyTitle.replaceAmpersand().toTitleCase().italicize();
        }
        firstInfo = firstInfo.appendData(anthologyTitle, ', ', 'in');

        
        if(translatedAnthologyTitle.length > 0){
            firstInfo = firstInfo.appendData('[' + translatedAnthologyTitle.replaceAmpersand() + ']', ' ', '', '');  
        }
	}

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let edition = shared.getStringValue(refData.edition);
	edition = shared.fixEditionString(edition);
    firstInfo = firstInfo.appendData(edition, ', ');

    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true);
    let publicationDate = shared.getFullDate(refData.publicationDate);
    let publisherPart = '';

    if (shared.isSelfPublished(refData)) {
        publisherPart = publisherPart.appendData('self-pub.');
    } else {
        publisherPart = publisherPart.appendData(publisherLocationString);
    }
    
    publisherPart = publisherPart.appendData(publicationDate, ', ');

    publisherPart = publisherPart.parenthesis();
    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    } else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    let workTitleSubsequent;
    if(type === 'anthology'){
		workTitleSubsequent = shared.getStringValue(refData.anthologyTitle);
        if(translatedAnthologyTitle.length > 0){
            workTitleSubsequent = workTitleSubsequent.replaceAmpersand().shortenName().italicize();
        } else {
            workTitleSubsequent = workTitleSubsequent.replaceAmpersand().toTitleCase().shortenName().italicize();
        }
	} else if(type === 'entry'){
        workTitleSubsequent = shared.getStringValue(refData.entryTitle);
        if(translatedAnthologyTitle.length > 0){
            workTitleSubsequent = workTitleSubsequent.replaceAmpersand().shortenName().doubleQuote();
        } else {
            workTitleSubsequent = workTitleSubsequent.replaceAmpersand().toTitleCase().shortenName().doubleQuote();
        }
        
	}
    
    subsequentInfo = subsequentInfo.appendData(workTitleSubsequent, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}