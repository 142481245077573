const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    let authors = shared.getContributorsByType('author', refData.contributors);
    let fakeRefData = new Object();
    fakeRefData.contributors = authors;
    fakeRefData.title = refData.title;

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    //for this type, only authors are primary, so filter those out
    let authors = shared.getContributorsByType('author', refData.contributors);
    let fakeRefData = new Object();
    fakeRefData.contributors = authors;

    let primaryContributor = shared.getPrimaryContributorString(fakeRefData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.doubleQuote();
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    switch (type) {
        case 'conference':

            let conference = shared.getStringValue(refData.conference);
            container1 = container1.appendData(conference, ', ');

            let formattedDate = getConferenceDateRange(refData);
            container1 = container1.appendData(formattedDate, ', ');

            //location
            let location = getLocation(refData);
            container1 = container1.appendData(location, ', ');

            //url/doi
            let url = shared.getStringValue(refData.url);
            let doi = shared.getStringValue(refData.doi);

            if (doi.length > 0) {
                doi = shared.appendDOIURL(doi);
                container1 = container1.appendData(doi, ', ');
            }
            else{
                if (url.length > 0) {
                    url = shared.formatURL(url);
                    container1 = container1.appendData(url, ', ');
                }
            }
            
            break;
        case 'symposium':
            let symposiumTitle = shared.getStringValue(refData.symposiumTitle);

            if (symposiumTitle.length > 0) {
                symposiumTitle = symposiumTitle.replaceAmpersand();
                symposiumTitle = symposiumTitle.toTitleCase();
                container1 = container1.appendData(symposiumTitle, ', ');
            }

            //Chairs
            let secondaryContributor = getSecondaryContributorString(refData.contributors);
            container1 = container1.appendData(secondaryContributor, ', ');

            break;
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type == 'symposium') {
        let conference = shared.getStringValue(refData.conference);
        container2 = container2.appendData(conference, ', ');

        let formattedDate = getConferenceDateRange(refData);
        container2 = container2.appendData(formattedDate, ', ');

        //location
        let location = getLocation(refData);
        container2 = container2.appendData(location, ', ');

        //url/doi
        let url = shared.getStringValue(refData.url);
        let doi = shared.getStringValue(refData.doi);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi);
            container2 = container2.appendData(doi, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url);
                container2 = container2.appendData(url, ', ');
            }
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    if (type == 'conference') {
        let description = shared.getStringValue(refData.description);
        secondSupplemental = secondSupplemental.appendData(description, ', ');
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//doing this as a one off since this is where all contributors go for this type
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {
        //for these contributors, get them all - authors (which includes group authors and user names)
        let secondaryContributors = contributors; 
        
        if (secondaryContributors.length > 0) {

            let typeStrings = [];

            let authors = shared.getContributorsByType('chair', secondaryContributors);

            if (authors.length > 0) {
                typeStrings.push(shared.getSecondaryContributorTypeString(authors, 'chair'));
            }

            typeStrings.forEach(typeString => {
                if (contributorstring.length > 0) {
                    contributorstring = contributorstring + ", "
                }

                contributorstring = contributorstring + typeString;
            });
        }

    }

    return contributorstring;
}

function getConferenceDateRange(refData){
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let endDate = shared.getStringValue(refData.endDate);
    let formattedDate = '';

    if (endDate.length > 0) {
        formattedDate = shared.getFullDateRange(publicationDate, endDate);
    }
    else{
        formattedDate = shared.getFullDate(publicationDate);
    }

    return formattedDate;
}

function getLocation(refData){
    let city = shared.getStringValue(refData.city);
    let state = shared.getStringValue(refData.state);
    let country = shared.getStringValue(refData.country);

    //If city and state, don't include country. If no State, include Country. If no city, state and country. Always include 2 if we have it.
    let location = '';
    let counter = 0;

    if (city.length > 0) {
        location = city;
        counter++;
    }

    if (state.length > 0) {
        
        if (counter == 0) {
            location = state;
        }
        else{
            location = location + ', ' + state;
        }

        counter++;
    }

    if (counter < 2) {
        if (country.length > 0) {
            
            if (counter == 0) {
                location = country;
            }
            else{
                location = location + ', ' + country;
            }

            counter++;
        }
    }

    return location;
}