/*
- Config > Enums 
-- this is for all platforms, so don't delete here unless you check all the all first
-- 2024.08.21
*/

export default {
	AccountRole:{
		0: 'Not Started',
		1: 'Free Trial',
		2: 'Full Access',
	},
	AddFromLibraryMode:{
		'COMMING_SOON': 6,	// for ref types that are building soon
		'DISABLED': 2,
		'FORMAT_DISABLED': 7,	// in MLA9 paper  but there isn't an MLA9 Reference Value 
		'GIMMICK': 5,		// fake one i made for testing 
		'NEVERMIND': 8,		// used for a duplicate reference the user is trying to create outside of Paper Edit (user can't add a ref to a paper)
		'NORMAL': 3,	
		'REVIEW': 4,		// for a ref type that is missing information
		'UPDATE': 1,		// older ref type
	},
	CartPaymentType:{
		'CARD': 1,
		'ECHECK': 2,
	},
	CitationTab: {		// for turabian9 onlye
		'CITATION': 1,
		'FOOTNOTE': 0,
	},
	CkEditorType:{
		'ABSTRACT': 0,
		'ANNOTATION': 1,
		'APPENDIX': 4,
		'AUTHORS_NOTE': 2,
		'BODY': 3,
		'FOOTNOTES': 6,
		'OUTLINE': 5,
	},
	ClassColors:{
		// https://magical-scratch.webflow.io/
		'NONE': {
			'block': 'adb5bd',	//$gray-500
			'darkText': 'e9ecef',	// $dark-body-color
			'text': '495057',	//$gray-700
			'map':	['bbbbbb', '666666', 'bb00bb', '660066'],
		},
		'RED': {
			'block': 'f28c79',
			'darkText': 'f28c79', //ff3f67
			'text': '9a122c',
			'map':	['bb0000', '660000', 'bb5500', '885500'],
		},
		'YELLOW': {
			'block': 'ffd266',
			'darkText': 'ffd266', //d37500
			'text': 'b76600',
			'map':	['bbbb00', '666600'],
		},
		'GREEN': {
			'block': '8ddb7a',
			'darkText': '8ddb7a', //12611d
			'text': '12611d',
			'map':	['00bb00', '006600'],				
		},
		'TEAL': {
			'block': '80deea',
			'darkText': '71d5e2', // 009dac
			'text': '00838f',
			'map':	['00bbbb', '006666'],
		},
		'BLUE': {
			'block': '62baf5',
			'darkText': '62baf5', // 008cff
			'text': '0040a1',
			'map':	['0000bb', '000066'],
		},
	},
	DocumentType:{
		'ANNOTATED_BIBLIOGRAPHY' : 1,
		'DISCUSSION_POST': 2,
		'REFERENCE_LIST': 3,
		'RESEARCH_PAPER': 4,
	},
	DownloadPaperContentType:{
		'WORD': 1,
		'WORD_SIMPLE': 2,
		'PDF': 3,
		'HTML': 4,
		'COMPRESSED': 5,
	},
	Entity:{
		'ASSIGNMENT': 3,
		'CLASS': 2,
		'PAPER': 1,
		'REFERENCE': 7,
		'REMINDER': 5,
		'TODO': 4,
	},
	Error:{
		'INSERT_IN_CONTENT_CONTROL': 1,
	},
	FontFamily:{
		'ARIAL': 1,
		'COURIER': 2,
		'TIMES_NEW_ROMAN': 3,
		'TREBUCHET': 4,
		'VERDANA': 5,
	},
	Format: {
		'APA6': 2,
		'APA7': 7,
		'MLA8': 6,
		'MLA9': 8,
		'Turabian9' : 9
	},
	Layout: {
		'CART': 3,
		'PO': 0,
		'PCOM': 1,
		'SIMPLE': 2,	// same as PCOM without a header or footer
	},
	Module:{
		'DASHBOARD': 0,
		'PAPER_EDIT': 1,
		'PAPER_LIST': 2,
		'REFERENCE_EDIT': 3,
		'REFERENCE_LIST': 4,
		'REMINDER_LIST': 5,
	},
	ModuleOrigin:{
		'REFERENCES': 0,
		'RESEARCH_NOTES': 1,
	},
	PaperType: {
		'APA_DiscussionPost': 2,
		'APA_ResearchPaper': 1,

		'APA7_AnnotatedBibliography' : 15,
		'APA7_DiscussionPost': 13,
		'APA7_ProfessionalPaper': 12,
		'APA7_ReferenceList' : 14,
		'APA7_StudentPaper': 11,

		'MLA_DiscussionPost': 102,
		'MLA_ResearchPaper': 101,
		
		'MLA9_AnnotatedBibliography' : 114,
		'MLA9_DiscussionPost': 112,
		'MLA9_ReferenceList' : 113,
		'MLA9_ResearchPaper': 111,

		'Turabian9_AnnotatedBibliography' : 214,
		'Turabian9_DiscussionPost': 212,
		'Turabian9_ReferenceList' : 213,
		'Turabian9_ResearchPaper': 211,
	},
	Platform:{
		'ADD_IN': 6,
		'ONLINE': 7,
		'CHROME_EXT': 8,
	},
	RefTypeTab: {
		'Common': 0,
		'All': 1,
		// 'Recent': 4,
	},
	ResearchNotesSortBy:{
		'CITATION_FIRST_TO_LAST': 2,
		'CITATION_LAST_TO_FIRST': 3,
		'CREATED_DATE_NEWEST_FIRST': 0,
		'CREATED_DATE_OLDEST_FIRST': 1,
	},
	Status: {
		'FREE_TRIAL': 1,
		'FULL_ACCESS': 2,
		'NOT_STARTED': 0
	},
	SubscriptionPeriod:{
		0: 'None',
		1: 'Monthly',
		2: 'Annual',
	},
	SubscriptionRenewalPeriod:{
		'ANNUAL': 2,
		// 'MONTH': 1,
		// 'NONE': 0,
		'SEMI': 3,
	},
	SubscriptionRenewalType:{
		'AUTO': 1,
		'MANUAL': 2,
	},
	Tab:{
		'_': 0,
		'CITATIONS': 1,
		'DETAILS': 2,
		'EDITOR': 3,
		'OUTLINE': 4,
		'PAPERS': 5,
		'RESEARCH': 6,
	},
	Theme:{
		'DARK': 2,
		'REGULAR': 1,
		'SERRENA': 3,
	},
	URL_TO_ENV:{
		'PERRLA': 1,
		'PERRLA_COMPLETE': 2,
	},
	Validation:{
		'NONE': 0,	// not used but keep here so i know 0 is the default none
		'DATE': 6,
		'EMAIL': 1,
		'EMAIL_REQUIRED': 2,
		'NUMERIC': 3,
		'NUMERIC_REQUIRED': 4,
		'REQUIRED': 5,
	},
	WorkType:{
		'ASSIGNMENT': 2,
		'TODO': 1,
	},
}