/*
*****Manuscript*****
 
Documentation: TODO: Include a link to the Google Document for this type here

Type & Kind Questions
- TODO: Document the type and kinds for thi type

TODO: Document the parts and their fields here
AuthorPart
    - TODO: Fields for this part here

DatePart
    - TODO: Fields for this part here

TitlePart
    - TODO: Fields for this part here

SourcePart
    - TODO: Fields for this part here

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.workTitle);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    //<em>Manuscript title: Subtitle</em> (A. A. Editora, B. B. Editorb Jr., & C. C. Editorc, Eds.) [Unpublished manuscript].
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.workTitle);

    if (workTitle.length > 0) {
        if (workTitle.startsWith('[')) {
            titlePart = workTitle;  
        }
        else{
            titlePart = workTitle.italicize();  
        }
    }

    let editorArray = shared.getEditors(refData.contributors);
    
    //if there is not author, we need to check editors, unless this is an entry
    if (editorArray.length > 0) {
        let editorString = shared.getReferenceNameString(editorArray, true);

        let editorLabel = 'Ed.';

        if (editorArray.length > 1) {
            editorLabel = 'Eds.';
        }


        editorString = '(' + editorString + ', ' + editorLabel + ')';

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + editorString;
    }

    let manuscriptLabel = '';
    switch (refData.type) {
        case 'unpublished':
            manuscriptLabel = '[Unpublished manuscript]';
            break;
        case 'preparation':
            manuscriptLabel = '[Manuscript in preparation]';
            break;
        case 'submitted':
            manuscriptLabel = '[Manuscript submitted for publication]';
            break;
    }

    if (titlePart.length > 0) {
        titlePart = titlePart + ' ';
    }

    titlePart = titlePart + manuscriptLabel;

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = shared.getStringValue(refData.entryTitle);

        if (title.length > 0) {

            if (!title.endsWith('.')) {
                title = title + '.';
            }
    
            authorPart = title;    
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    // Department of Cryptozoology, PERRLA University. https://doi.org/10.123456789
    let sourcePart = '';

    let publisherString = shared.getPublisherString(refData.publishers);
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;

    return sourcePart;
}