/*
*****Interview*****
 This was added after Turabian, and is based off of Personal Communications logic
*/

const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';

    let datePart = getDatePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let sourcePart = getSourcePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart ;  
    }
    else{

        if (titlePart.length > 0) {
            referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
        }
        else{
            referenceValue = authorPart + ' ' + datePart;
        }
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: false,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData) {
    //this is it's own beast
    //(A. A. Authora, personal communication, April 1, 2019)
    let authorArray = [];

    _forEach(refData.contributors, (item) => {

        if (refData.type == 'archive') {
            if (item.type == 'editor') {
                authorArray.push(item);
            }
        }
        else{
            if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
                authorArray.push(item);
            }
        }
    });

    let authorString = shared.getReverseCitationAuthorString(authorArray);
    let formattedDate = shared.getReverseFullDate(refData.publicationDate);

    //check to see if they have excluded the name or date part
    if (citationData.namePart == 'false') {
        authorString = '';
    }

    if (citationData.datePart == 'false') {
        formattedDate = '';
    }
    else{
        //if there is no year, use n.d.
        if (formattedDate.length == 0) {
            formattedDate = 'n.d.';
        }
    }

    let firstCitation = '';

    if (authorString.length > 0) {
        firstCitation = authorString + ', ';
    }

    firstCitation = firstCitation + 'personal communication';

    if (formattedDate.length > 0) {
        firstCitation = firstCitation + ', ' + formattedDate;
    }

    if (citationData.type == 'time') {
        let location = shared.getCitationLocationString(citationData);

        if (location.length > 0) {
            firstCitation = firstCitation + ', ' + location;
        }
    }

    let citation = {
        first : firstCitation,
        subsequent : ''
    }


    return citation;
}

function getTitlePart(refData){
    let titlePart = '';
    let title = 'Personal communication';

    if (refData.type == 'archive') {
        let number = shared.getStringValue(refData.number);

        if (number.length > 0) {
            title = 'Personal communication (' + number + ')' ;
        }
    }

    let authorArray = shared.getAuthors(refData.contributors);

    if (refData.type == 'archive') {

        if (refData.kind == 'anonymous') {
            title = title + ' [Interview]';
        }
        else{
            if (authorArray.length > 0) {
                let authorString = '';
    
                authorArray.forEach(contributor => {
    
                    let name = '';
    
                    if (contributor.firstName && contributor.firstName.trim().length > 0) {
                        name = contributor.firstName;
                    }
                
                    if (contributor.middleName && contributor.middleName.trim().length > 0) {
                        name = name + ' ' + contributor.middleName.getInitial();
                    }
        
                    if (contributor.lastName && contributor.lastName.trim().length > 0) {
                        if (name.length > 0) {
                            name = name + ' ';
                        }
                        
                        name = name + contributor.lastName.trim();
                    }
        
                    var suffix = '';
                    if (contributor.suffix && contributor.suffix.trim().length > 0) {
                        suffix = contributor.suffix.trim();
                    }
        
                    if (suffix.length > 0) {
                        name = name + ' ' + suffix;
        
                        if (!name.endsWith(".")) {
                            name = name + '.'
                        }
                    }
    
                    if (authorString.length > 0) {
                        authorString = authorString + ', '
                    }
    
                    authorString = authorString + name;
                });
    
                if (authorString.length > 0) {
                    title = title + ' [Interview with ' + authorString + ']'
                }
                else
                {
                    title = title + '[Interview]';
                }
            }
        }

        titlePart = title + '.'; 
    }
    else{
        if (title.length > 0 && (authorArray.length != 0)) {
            titlePart = title + ' [Interview].';   
        }
    }

    return titlePart;
}

function getAuthorPart(refData){
    
    let authorString = '';
    if (refData.type == 'archive') {
        let editorArray = shared.getContributorsByType('editor', refData.contributors);
        authorString = shared.getEditorsAtStartofRefString(editorArray);
    }
    else{
        let authorArray = shared.getAuthors(refData.contributors);
        authorString = shared.getReferenceNameString(authorArray, false);
    }

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = 'Personal communication [Interview].';

        if (title.length > 0) {
            authorPart = title;
        }
    }

    if (!authorPart.endsWith('.')) {
        authorPart = authorPart + '.';
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Publishers
    let sourcePart = '';
    
    let publisherString = shared.getPublisherString(refData.publishers);
    
    if (publisherString != '.') {
        sourcePart = publisherString;      
    }

    return sourcePart;
}