/*
*****Testimonies*****
 
*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';

    let title = shared.getStringValue(refData.title);
    let committeeName = shared.getStringValue(refData.committeeName);
    let session = shared.getStringValue(refData.session);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let contributorString = shared.getReferenceNameString(refData.contributors, true);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        if (title.startsWith('[')) {
            referenceValue = title;
        }
        else{

            if (refData.type == 'hearing' && committeeName.length > 0) {
                title = title + ': Hearing before the ' + committeeName;                
            }

            referenceValue = title.italicize();
        }
    }

    if (refData.type == 'testimony' && committeeName.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + committeeName.italicize();
    }

    if (session.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue +  ',';
        }

        session = shared.getOrdinalValue(session);

        referenceValue = referenceValue + ' ' + session;
        referenceValue = referenceValue + ' Cong.'
    }else{
        if (referenceValue.length > 0) {
            referenceValue = referenceValue +  '.';
        } 
    }

    if (referenceValue.length > 0) {
        referenceValue = referenceValue +  ' ';
    }

    if (publicationDate.length > 0) {
        publicationDate = shared.getYear(publicationDate);
        referenceValue = referenceValue + '(' + publicationDate + ')';
    }
    else{
        referenceValue = referenceValue + '(n.d.)';
    }

    if (contributorString.length > 0) {
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ' (testimony of ';
            referenceValue = referenceValue + contributorString;
            referenceValue = referenceValue + ')';
        }
    }

    if (referenceValue.length > 0) {
        referenceValue = referenceValue +  '.';
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        referenceValue = referenceValue + ' ' + url;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let authorString = '';

    let title = shared.getStringValue(refData.title);
    let shortTitle = shared.getStringValue(refData.shortTitle);
    let year = shared.getYear(refData.publicationDate);

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (shortTitle.length > 0) {
        title = shortTitle;
    }

    if (!title.startsWith('[')) {
        title = shared.stripSubtitle(title);
        title = title.toTitleCase().italicize();    
    }

    let citation = shared.getCitations(citationData, title, year, []);

    return citation;
}