/*
*****Regulations*****
 
Documentation: 
FORM: https://docs.google.com/spreadsheets/d/1Xh5m6m6Jz3eA_zQfsOwO58WlrXJ9ICW5WiWwhtD8c3M/edit#gid=2071428744
MOCKUP: http://magical-design-system.webflow.io/add-in/wizard/wizard-2-federal-reports-regulations

Type & Kind Questions
- Types:
    - report: This has two kinds (senate & house)
    - regulation: This has two kinds (codified & uncodified)

- Report Senate
    S. Rep. No. [reportNumber] ([publicationDate]). [url]
    *** publicationDate here is YYYY

- Report House
    H.R. Rep. No. [reportNumber] ([publicationDate]). [url]
    *** publicationDate here is YYYY

- Regulation Codified
    [title], [volume] C.F.R. § [section] ([publicationDate]). [url]
    *** publicationDate here is YYYY

- Regulation Uncodified
    [title], [proposedVolume] F.R. [pageNumber] (proposed [publicationDate]) (to be codified at [volume] C.F.R. § [section]). [url]
    *** publicationDate here is MM DD, YYYY

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    switch (type) {
        case 'report':
            // H.R. Rep. No. [reportNumber] ([publicationDate]). [url]
            // S. Rep. No. [reportNumber] ([publicationDate]). [url]
            let reportNumber = shared.getStringValue(refData.reportNumber);
            let label = '';

            if(kind == 'senate'){
                label = 'S. Rep.';
            }

            if(kind == 'house'){
                label = 'H.R. Rep.';
            }

            referenceValue = label;

            if(reportNumber.length > 0){
                referenceValue = referenceValue + ' No. ' + reportNumber;
            }

            if(publicationDate.length > 0){
                referenceValue = referenceValue + ' (' + publicationDate + ').';
            }

            if(url.length > 0){
                url = shared.formatURL(url);
                referenceValue =  referenceValue + ' ' + url;
            }

            break;
        case 'regulation':
            //codified
            //[title], [volume] C.F.R. § [section] ([publicationDate]). [url]
            
            //uncodified
            // [title], [proposedVolume] F.R. [pageNumber] (proposed [publicationDate]) (to be codified at [volume] C.F.R. § [section]). [url]

            let title = shared.getStringValue(refData.title);
            let volume = shared.getStringValue(refData.volume);
            let section = shared.getStringValue(refData.section);
            section = formatSectionString(section);

            if(title.length > 0){
                referenceValue = title;
            }

            switch (kind) {
                case 'codified':
                    
                    if(volume.length > 0)
                    {
                        if(referenceValue.length > 0){
                            referenceValue = referenceValue + ', ';
                        }

                        referenceValue = referenceValue + volume;
                    }

                    if (referenceValue.length > 0) {
                        referenceValue = referenceValue + ' ';
                    }

                    referenceValue = referenceValue + 'C.F.R.';

                    if(section.length > 0)
                    {
                        referenceValue = referenceValue + ' ' + section;
                    }

                    if(publicationDate.length > 0){
                        referenceValue = referenceValue + ' (' + publicationDate + ').';
                    }

                    break;
                case 'uncodified':
                    let proposedVolume = shared.getStringValue(refData.proposedVolume);
                    let pageNumber = shared.getStringValue(refData.pageNumber);
                    let proposedDate = shared.getStringValue(refData.proposedDate);

                    if(proposedVolume.length > 0)
                    {
                        if(referenceValue.length > 0){
                            referenceValue = referenceValue + ', ';
                        }

                        referenceValue = referenceValue + proposedVolume;
                    }

                    if (referenceValue.length > 0) {
                        referenceValue = referenceValue + ' ';
                    }

                    referenceValue = referenceValue + 'F.R.';

                    if(pageNumber.length > 0)
                    {
                        referenceValue = referenceValue + ' ' + pageNumber;
                    }

                    if(proposedDate.length > 0)
                    {
                        referenceValue = referenceValue + ' (proposed ' + shared.getReverseFullDate(proposedDate) + ')';
                    }

                    if(volume.length > 0 || section.length > 0)
                    {
                        referenceValue = referenceValue + ' (to be codified at ';

                        if (volume.length > 0) {
                            referenceValue = referenceValue + volume + ' C.F.R.'; 
                        }

                        if (section.length == 0) {
                            section = '§ ___';
                        }

                        if (section.length > 0) {

                            if (!referenceValue.endsWith(' ')) {
                                referenceValue = referenceValue + ' ';
                            }

                            referenceValue = referenceValue + section; 
                        }

                        referenceValue = referenceValue + ')';    
                    }

                    referenceValue = referenceValue + '.'

                    break;
            }

            if(url.length > 0){
                url = shared.formatURL(url);
                referenceValue =  referenceValue + ' ' + url;
            }

            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    let authorString = '';
    switch (type) {
        case 'report':
            let reportNumber = shared.getStringValue(refData.reportNumber);
            let label = '';

            if(kind == 'senate'){
                label = 'S. Rep.';
            }

            if(kind == 'house'){
                label = 'H.R. Rep.';
            }

            authorString = label;

            if(reportNumber.length > 0){
                authorString = authorString + ' No. ' + reportNumber;
            }
            
            break;
        case 'regulation':
            let shortTitle = shared.getStringValue(refData.shortTitle);
            let title = shared.getStringValue(refData.title);

            if (shortTitle.length > 0) {
                authorString = shortTitle;
            }
            else{
                authorString = title;
            }

            break;
    }

    let year = shared.getYear(refData.publicationDate);
    let proposedYear = shared.getYear(refData.proposedDate);

    //if there is no year, use n.d.
    if (year.length == 0) {

        if (proposedYear.length > 0) {
            year = proposedYear;    
        }
        else{
            year = 'n.d.';
        }
    }

    let citation = shared.getCitations(citationData, authorString, year, []);

    return citation;
}

function formatSectionString(section){
    let prefix = '§';

    if (section.length == 0) {
        return '';
    }

    if (section.includes('-') || section.includes('–') || section.includes('—')) {
        prefix = '§§';
    }

    section = prefix + ' ' + section; 
    
    return section;
}