const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    //US Patent [number], filed [fileDate], and issued [publicationDate]. 
    publicationPart = publicationPart.appendData("US Patent");

    //number
    let number = shared.getStringValue(refData.number);
    publicationPart = publicationPart.appendData(number);

    let filedDate = shared.getStringValue(refData.filedDate);

    if (filedDate.length > 0) {
        filedDate = shared.getFullDate(filedDate);

        publicationPart = publicationPart.appendData("filed", ", ");
        publicationPart = publicationPart.appendData(filedDate);
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (publicationDate.length > 0) {
        publicationDate = shared.getFullDate(publicationDate);   

        let seperator = ', ';
        if (filedDate.length > 0) {
            publicationPart = publicationPart.appendData("and", seperator);
            seperator = ' ';
        }

        publicationPart = publicationPart.appendData("issued", seperator);
        publicationPart = publicationPart.appendData(publicationDate);
    }

    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // doi/url
    let url = shared.getStringValue(refData.url);
    
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    //[contributors], [title], US Patent [number], filed [fileDate], and issued [publicationDate], [location], [url].
    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    firstInfo = firstInfo.appendData(title, ", ");

    firstInfo = firstInfo.appendData("US Patent", ", ");

    let number = shared.getStringValue(refData.number);

    if (number.length > 0) {
        firstInfo = firstInfo.appendData(number);
    }

    let filedDate = shared.getStringValue(refData.filedDate);

    if (filedDate.length > 0) {
        filedDate = shared.getFullDate(filedDate);

        firstInfo = firstInfo.appendData("filed", ", ");
        firstInfo = firstInfo.appendData(filedDate);
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (publicationDate.length > 0) {
        publicationDate = shared.getFullDate(publicationDate); 
        
        let seperator = ', ';
        if (filedDate.length > 0) {
            firstInfo = firstInfo.appendData("and", seperator);
            seperator = ' ';
        }

        firstInfo = firstInfo.appendData("issued", seperator);
        firstInfo = firstInfo.appendData(publicationDate);
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //[contributors], [shortenedTitle], [location].
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    }

    title = title.replaceAmpersand().shortenName().toTitleCase();

    subsequentInfo = subsequentInfo.appendData(title, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}