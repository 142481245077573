/*
*****Reviews*****
 
FORM: https://docs.google.com/spreadsheets/d/1aw9yq_RdsyVby8blbl-7OJ9FF5B85F_CqU1q39evYyk/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-reviews

TYPES:
    periodical
        - used for newspapers, magazines, & journals
        
    website
        - used for reviews found on website
    blog
        - reviews posted as part of a blog

KINDS:
    book
        - uses "[Review of the book...]"
        - [title] is italicized
    film
        - uses "[Review of the film...]"
        - [title] is italicized
    album
        - uses "[Review of the album...]"
        - [title] is italicized
    song
        - uses "[Review of the song...]"
        - [title] is put in quotation marks
    tvSeries
        - uses "[Review of the television series...]"
        - [title] is italicized
    tvEpisode
        -uses "[Review of the television series episode...]"
        - [title] is put in quotation marks
    other
        - uses "[Review of the [worktype]...]"
        - [title] is italicized

PARTS:

AuthorPart
    All
        -[contributor.author].

DatePart
    All
        - ([publicationDate]).

TitlePart
    Periodical
        "[Review of the " + [worktype text & formatting is dependent on Kind] + [secondary contributors with titles] + "]." 
    Website
        "[Review of the " + [worktype text & formatting is dependent on Kind] + [secondary contributors with titles] + "]."
    Blog
        "[Review of the " + [worktype text & formatting is dependent on Kind] + [secondary contributors with titles] + "]."

SourcePart
    Periodical
        <em>[periodical]</em>, <em>[volume]</em>([issue]), [pageNumbers]. <a href=\"[doi or url]\"[doi or url]</a>"
    Website
        [website]. <a href=\"[url]\"[url]</a>
    Blog
        <em>[blog]</em>. <a href=\"[url]\"[url]</a>

*/

const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';

    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        //referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;
        referenceValue = titlePart + ' ' + sourcePart;
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    let type = refData.type;
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                if (type == 'website') {
                    authorString = title.toTitleCase().italicize(); 
                }
                else{
                    authorString = '"' + title.toTitleCase() + '," ';
                }
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getTitlePart(refData){
    let titlePart = '';
    let kind = shared.getStringValue(refData.kind);
    
    switch (kind) {
        case 'book':
            titlePart = getBookTitlePart(refData);
            break;
        case 'film':
            titlePart = getFilmTitlePart(refData);
            break;
        case 'album':
            titlePart = getAlbumTitlePart(refData);
            break;
        case 'song':
            titlePart = getSongTitlePart(refData);
            break;
        case 'tvSeries':
            titlePart = getTVSeriesTitlePart(refData);
            break;
        case 'tvEpisode':
            titlePart = getTVEpisodeTitlePart(refData);
            break;
        case 'other':
            titlePart = getOtherTitlePart(refData);
            break;
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }
    
    return titlePart;
}

function getBookTitlePart(refData){
    let titlePart = '';
    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let reviewAuthors = getReviewAuthors(refData.contributors);
    let secondaryContribsString = shared.getReferenceNameString(reviewAuthors, true);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the book ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + reviewedTitle.italicize();

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ', ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getFilmTitlePart(refData){
    let titlePart = '';

    // film
    //     - uses "[Review of the film...]"
    //     - [title] is italicized

    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let reviewContribs = getReviewFilmContribs(refData.contributors);
    let secondaryContribsString = getFilmReferenceNameString(reviewContribs);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the film ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + reviewedTitle.italicize();

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ', ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getAlbumTitlePart(refData){
    // album
    //     - uses "[Review of the album...]"
    //     - [title] is italicized
    let titlePart = '';
    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let reviewContribs = getReviewArtists(refData.contributors);
    let secondaryContribsString = shared.getReferenceNameString(reviewContribs, true);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the album ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + reviewedTitle.italicize();

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ', ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getSongTitlePart(refData){
    // song
    //     - uses "[Review of the song...]"
    //     - [title] is put in quotation marks
    let titlePart = '';
    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let reviewContribs = getReviewArtists(refData.contributors);
    let secondaryContribsString = shared.getReferenceNameString(reviewContribs, true);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the song ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + '"' + reviewedTitle;

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ',';
            }

            titlePart = titlePart + '"'

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ' ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getTVSeriesTitlePart(refData){
    let titlePart = '';

    // tvSeries
    //     - uses "[Review of the television series...]"
    //     - [title] is italicized

    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let secondaryContribsString = getTVReferenceNameString(refData);
    let authorPart = getAuthorPart(refData);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (authorPart.length == 0) {
        let datePart = getDatePart(refData);

        if (titlePart.length > 0) {

            if (!titlePart.endsWith('.')) {
                titlePart = titlePart + '.';
            }

            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + datePart;
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the TV series ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + reviewedTitle.italicize();

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ', ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getTVEpisodeTitlePart(refData){
    let titlePart = '';

    // tvEpisode
    //     -uses "[Review of the television series episode...]"
    //     - [title] is put in quotation marks

    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let secondaryContribsString = getTVReferenceNameString(refData);
    let authorPart = getAuthorPart(refData);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {

        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (authorPart.length == 0) {
        let datePart = getDatePart(refData);

        if (titlePart.length > 0) {

            if (!titlePart.endsWith('.')) {
                titlePart = titlePart + '.';
            }

            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + datePart;
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            if (!titlePart.endsWith(' ')) {
                titlePart = titlePart + ' ';    
            }
        }

        titlePart = titlePart + '[Review of the TV series episode ';

        if (reviewedTitle.length > 0) {
            titlePart = titlePart + '"' + reviewedTitle;

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ',';
            }

            titlePart = titlePart + '"'

            if (secondaryContribsString.length > 0) {
                titlePart = titlePart + ' ';
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;
}

function getOtherTitlePart(refData){
    let titlePart = '';

    // other
    //     - uses "[Review of the [worktype]...]"
    //     - [title] is italicized

    let title = shared.getStringValue(refData.title);
    let reviewedTitle = shared.getStringValue(refData.reviewedTitle);
    let reviewContribs = getReviewComposers(refData.contributors);
    let secondaryContribsString = getFilmReferenceNameString(reviewContribs); //works the same as film so re-use
    let workType = shared.getStringValue(refData.workType);
    let type = shared.getStringValue(refData.type);

    if (title.length > 0) {
        if (type == 'website') {
            titlePart = title.italicize();
        }
        else{
            titlePart = title;
        }
    }

    if (reviewedTitle.length > 0 || secondaryContribsString.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '[Review of the ' + workType + ' ';

        if (reviewedTitle.length > 0) {

            if (reviewedTitle.startsWith('"')) {
                titlePart = titlePart + reviewedTitle;
            }
            else {
                titlePart = titlePart + reviewedTitle.italicize();
            }

            if (secondaryContribsString.length > 0) {

                if (reviewedTitle.endsWith('"')) {
                    titlePart = titlePart.slice(0, -1) + '," ';
                }
                else {
                    titlePart = titlePart + ', ';
                }
            }
        }

        if (secondaryContribsString.length > 0) {
            titlePart = titlePart + 'by ' + secondaryContribsString;
        }

        titlePart = titlePart + ']'; 
    }
    
    return titlePart;

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}


function getSourcePart(refData){
    let sourcePart = '';

    let type = shared.getStringValue(refData.type);

    switch (type) {
        case 'periodical':
            sourcePart = getPeriodicalSourcePart(refData);
            break;
        case 'website':
            sourcePart = getWebsiteSourcePart(refData);
            break;
        case 'blog':
            sourcePart = getBlogSourcePart(refData);
            break;
    }

    return sourcePart;
}

function getPeriodicalSourcePart(refData){
    let sourcePart = '';
    // Periodical
    //     <em>[periodical]</em>, <em>[volume]</em>([issue]), [pageNumbers]. <a href=\"[doi or url]\"[doi or url]</a>"
    //<em>Periodical Title</em>, <em>42</em>(11), 12–14. <a href=\"https://doi.org/10.12345\">https://doi.org/10.12345</a>

    let periodical = shared.getStringValue(refData.periodical);
    let volume = shared.getStringValue(refData.volume);
    let issue = shared.getStringValue(refData.issue);
    let pageNumbers = shared.getStringValue(refData.pageNumbers);
    let doi = shared.getStringValue(refData.doi);
    let url = shared.getStringValue(refData.url);

    if (periodical.length > 0) {
        sourcePart = periodical.italicize();
    }

    if (volume.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + volume.italicize();    
    }

    if (issue.length > 0) {
        if (volume.length == 0) {
            sourcePart = sourcePart + ', ';
        }

        sourcePart = sourcePart + '(' + issue + ')';
    }

    if (pageNumbers.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ', ';
        }

        pageNumbers = shared.fixPageDash(pageNumbers);

        sourcePart = sourcePart + pageNumbers;
    }

    if (sourcePart.length > 0) {
        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}

function getWebsiteSourcePart(refData){
    let sourcePart = '';
    // Website
    //     [website]. <a href=\"[url]\"[url]</a>
    //Reviews R Us. <a href=\"https://www.perrla.com/reviews\">https://www.perrla.com/reviews</a>
    let website = shared.getStringValue(refData.website);
    let url = shared.getStringValue(refData.url);

    if (website.length > 0) {
        sourcePart = website;

        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}

function getBlogSourcePart(refData){
    let sourcePart = '';
    // Blog
    //     <em>[blog]</em>. <a href=\"[url]\"[url]</a>
    //<em>Blog Name</em>. <a href=\"https://www.perrla.com/reviews\">https://www.perrla.com/reviews</a>
    let blog = shared.getStringValue(refData.blog);
    let url = shared.getStringValue(refData.url);

    if (blog.length > 0) {
        sourcePart = blog.italicize();

        if (!sourcePart.endsWith('.')) {
            sourcePart = sourcePart + '.';
        }
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}


function getReviewAuthors(contributors){
    let contribArray = [];

    if (typeof contributors === 'undefined') {
        return contribArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'reviewAuthor' || item.type == 'reviewEditor') {
            contribArray.push(item);
        }
    });

    return contribArray;
}

function getReviewArtists(contributors){
    let contribArray = [];

    if (typeof contributors === 'undefined') {
        return contribArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'reviewArtist' || item.type == 'reviewGroupArtist') {
            contribArray.push(item);
        }
    });

    return contribArray;
}

function getReviewFilmContribs(contributors){
    let contribArray = [];

    if (typeof contributors === 'undefined') {
        return contribArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'reviewDirector' || item.type == 'reviewWriter' || item.type == 'reviewWriterDirector') {
            contribArray.push(item);
        }
    });

    return contribArray;
}

function getReviewComposers(contributors){
    let contribArray = [];

    if (typeof contributors === 'undefined') {
        return contribArray;
    }

    _forEach(contributors, (item) => {
        if (item.type == 'reviewComposer') {
            contribArray.push(item);
        }
    });

    return contribArray;
}


function getFilmReferenceNameString(contributors){
    var contributorstring = '';

    if (contributors.length > 0) {

        //Single
        if (contributors.length == 1) {
            contributorstring = shared.getReferenceName(contributors[0]);

            let label = getLabelForContributorType(contributors[0]);

            if (label.length > 0) {
                contributorstring = contributorstring + ', ' + label;
            }
        }

        //2-20
        if (contributors.length > 1 && contributors.length < 21) {
            var counter = 0;

            _forEach(contributors, (item) => {

                if (counter > 0) {

                    if (counter == contributors.length - 1) {
                            contributorstring = contributorstring + ', & '
                    }
                    else{
                        contributorstring = contributorstring + ', '
                    }
                }

                contributorstring = contributorstring + shared.getReferenceName(item);

                let label = getLabelForContributorType(contributors[counter]);

                if (label.length > 0) {
                    contributorstring = contributorstring + ', ' + label;
                }

                counter++;
            });
        }

        //More Than Twenty
        if (contributors.length > 20) {
            var counter = 0;

            _forEach(contributors, (item) => {

                let label = getLabelForContributorType(item);

                if (counter < 19) {
                    if (counter > 0) {
                        contributorstring = contributorstring + ', '    
                    }
                    contributorstring = contributorstring + shared.getReferenceName(item);
                }
                else{
                    if (counter == contributors.length - 1) {
                        contributorstring = contributorstring + ',...'
                        contributorstring = contributorstring + shared.getReferenceName(item);
                    }
                }

                if (label.length > 0) {
                    contributorstring = contributorstring + ', ' + label;
                }

                counter++;
            });
        }
    }
  
    return contributorstring;
}

function getTVReferenceNameString(refData){
    //Authora, A. A. (Writer), Authorb, B. B. (Director), & Authorc, C. C. (Writer & Director).
    let authorPart = '';

    //order by: writer, director, then writer & director
    //then order by the way they are listed
    //custom at the end

    if (typeof refData.contributors === 'undefined') {
        return '';
    }

    if (refData.contributors.length == 0) {
        return '';
    }

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    let writerArray = [];
    let directorArray = [];
    let writerDirectorArray = [];
    let customArray = [];
    let authorCount = 0;
    let typeCount = 0;

    let typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'reviewWriter') {
            writerArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'reviewDirector') {
            directorArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'reviewWriterDirector') {
            writerDirectorArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'custom') {
            customArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    //not sure how this would be possible to have contribs not in this list, but whatever
    if (authorCount == 0) {
        return '';
    }

    //now based off the total number we have to process, loop through all arrays at once and build the strings
    if (authorCount == 1) {
        //only one in one of these arrays
        if (writerArray.length == 1) {
            let thisName = shared.getReferenceName(writerArray[0]);
            let thisLabel = getLabelForContributorType(writerArray[0], false);
            let thisFullName = thisName + ', ' + thisLabel;

            authorPart = thisFullName;
        }

        if (directorArray.length == 1) {
            let thisName = shared.getReferenceName(directorArray[0]);
            let thisLabel = getLabelForContributorType(directorArray[0], false);
            let thisFullName = thisName + ', ' + thisLabel;

            authorPart = thisFullName;
        }

        if (writerDirectorArray.length == 1) {
            let thisName = shared.getReferenceName(writerDirectorArray[0]);
            let thisLabel = getLabelForContributorType(writerDirectorArray[0], false);
            let thisFullName = thisName + ', ' + thisLabel;

            authorPart = thisFullName;
        }

        if (customArray.length == 1) {
            let thisName = shared.getReferenceName(customArray[0]);
            let thisLabel = getLabelForContributorType(customArray[0], false);
            let thisFullName = thisName + ', ' + thisLabel;

            authorPart = thisFullName;
        }
    }

    //2-20
    if (authorCount > 1 && authorCount < 21) {
        let namesUsed = 0;
        
        //Writer
        writerArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ', ' + thisLabel;
            }

            namesUsed++;
        });

        if (writerArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(writerArray[0], true);
            authorPart = authorPart + ', ' + thisLabel;
        }

        //Director
        directorArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ', ' + thisLabel;
            }

            namesUsed++;
        });

        if (directorArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(directorArray[0], true);
            authorPart = authorPart + ', ' + thisLabel;
        }

        //Writers and Directors
        writerDirectorArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ', ' + thisLabel;
            }

            namesUsed++;
        });

        if (writerDirectorArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(writerDirectorArray[0], true);
            authorPart = authorPart + ', ' + thisLabel;
        }

        //Customs
        customArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            let thisLabel = getLabelForContributorType(item, false);
            authorPart = authorPart + ', ' + thisLabel;

            namesUsed++;
        });
    }

    //More Than Twenty
    if (authorCount > 20) {
        let namesUsed = 0;

                //Writers
                writerArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ', ' + thisLabel;
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ', ' + thisLabel;
                            }
                        }
                    }
                });
        
                if (writerArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(writerArray[0], true);
                    authorPart = authorPart + ', ' + thisLabel;
                }
        
                //Directors
                directorArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ', ' + thisLabel;
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ', ' + thisLabel;
                            }
                        }
                    }
                });
        
                if (directorArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(directorArray[0], true);
                    authorPart = authorPart + ', ' + thisLabel;
                }
        
                //Executive Directors and Producers
                writerDirectorArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ', ' + thisLabel;
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ', ' + thisLabel;
                            }
                        }
                    }
                });
        
                if (writerDirectorArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(writerDirectorArray[0], true);
                    authorPart = authorPart + ', ' + thisLabel;
                }
        
                //Customs
                customArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ', ' + thisLabel;
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ', ' + thisLabel;
                            }
                        }
                    }
                });
    }
    
    return authorPart;
}

function getLabelForContributorType(contributor, isPlural){
    let label = '';
    let customLabel = '';

    switch (contributor.type) {
        case 'reviewEditor':
            if (isPlural) {
                label = 'Eds.';    
            }
            else{
                label = 'Ed.';
            }
            break;

        case 'reviewDirector':
            if (isPlural) {
                label = 'Dirs.';    
            }
            else{
                label = 'Dir.';
            }
            break;

        case 'reviewWriter':

            if (isPlural) {
                label = 'Writers';    
            }
            else{
                label = 'Writer';
            }
            break;

        case 'reviewWriterDirector':
            if (isPlural) {
                label = 'Writers & Dirs.';    
            }
            else{
                label = 'Writer & Dir.';
            }
            break;

        case 'reviewComposer':
            customLabel = shared.getStringValue(contributor.name);

            if (customLabel.length > 0) {
                label = customLabel;
            }
            break;
    
        default:
            //custom
            customLabel = shared.getStringValue(contributor.name);

            if (customLabel.length > 0) {
                label = customLabel;
            }
            break;
    }

    return label;
}

function appendNameSeperator(nameString, namesUsed, authorCount, isSecondary){

    isSecondary = true;
    
    //repetive logic I'm trying to re-use will all these different name types
    if (namesUsed == authorCount - 1) {

        //if three or more, add comma 
        //or if the names are primary, then, add comma before any
        if (isSecondary && authorCount > 2) {
            nameString = nameString + ','
        }

        if (!isSecondary && authorCount > 1) {
            nameString = nameString + ','
        }

        nameString = nameString + ' & '
    }
    // else{

    //     if (nameString.endsWith(',')) {
    //         nameString = nameString + ' '
    //     }
    //     else{
    //         nameString = nameString + ', '
    //     }
    // }

    return nameString;
}