/*
*****Television*****
 
Documentation: https://docs.google.com/spreadsheets/d/1BHPmBwwZNGbyJhpkeCR_6haUPWLYsnSy7jOOYoQ1GZk/edit#gid=2071428744

Type & Kind Questions
- series: An entire television series
- episode: A single episode or webisode from a television series

**SERIES PARTS**
AuthorPart
    - Contributors
        - Executive Directors (Authora, A. A. (Executive Director)) [contributor.executiveDirector]
        - Executive Producers (Authora, A. A. (Executive Producer)) [contributor.executiveProducer]
        - Executive Director & Producer (Authora, A. A. (Executive Director & Executive Producer)) [contributor.executiveDirectorProducer]
        - If there is only one kind of contributor with multiple people, list them togehter (Authora, A. A., & Authorb, B. B. (Executive Producers)).  We can't combine all roles into one if there are multiple contributor types.
        - Role possibilites:
            - Executive Director
            - Executive Directors (multiple Ex. Director contributors only)
            - Executive Producer
            - Executive Producers (multiple Ex. Producer contributors only)
            - Executive Director & Executive Producer
            - Executive Directors & Executive Producers (multiple Ex. Director/Ex. Producer contributors only)
    - Order of Contributor Roles
        - Executive Directors
        - Executive Producer
        - Executive Director & Producer
        - Custom

DatePart
    - Years the series aired
    - Uses an en dash ("–")
    - Can include text (e.g., 2015–present)
    - (2015–present)
    - Ends with a period

TitlePart
    - Title of the series in italics (<em>)The wire</em>), followed by a period
    - followed by "[TV series]"

SourcePart
    - Production Company Names, separated by semi-colons if multiple (publishers)
    - End with a period


**EPISODE PARTS**
AuthorPart
    - Contributors
        - Writer (Authora, A. A. (Writer)) [contributor.writer]
        - Director (Authora, A. A. (Director)) [contributor.director]
        - Writer & Director (Authora, A. A. (Writer & Director)) [contributor.writerDirector]
        - Role possibilites:
            - Writer
            - Writers (multiple Writer contributors only)
            - Director
            - Directors (multiple Director contributors only)
            - Writer & Director
            - Writers & Directors (multiple Writer/Director contributors only)
        - Order of Contributor Roles
            - Writers
            - Directors
            - Writers & Directors
            - Custom

DatePart
    - Date the episode first aired
    - Year, Month Date
    - (2015, April 1)
    - Ends with period

TitlePart
    - Title of the episode, not italicized (e.g., Who shot Mr. Burns? (Part one))
    - Season & episode number in parentheses (Season 4, Episode 12)
    - followed by "[TV series episode]"

SourcePart
    - Starts with "In "
    - Then list secondary contributors
        - Executive Directors (A. A. Authora, (Executive Director))
        - Executive Producers (A. A. Authora, (Executive Producer))
        - If there is only one kind of contributor with multiple people, list them togehter (A. A. Authora, & B. B. Authorb (Executive Producers)). We can't combine all roles into one if there are multiple contributor types.
        - Role possibilites:
            - Executive Director
            - Executive Directors (multiple Ex. Director contributors only)
            - Executive Producer
            - Executive Producers (multiple Ex. Producer contributors only)
            - Executive Director & Executive Producer
            - Executive Directors & Executive Producers (multiple Ex. Director/Ex. Producer contributors only)
        - Order of Contributor Roles
            - Executive Directors
            - Executive Producer
            - Executive Director & Producer
            - Custom
    - Follow with a comma
    - Title of the series in italics (<em>)The wire</em>), followed by a period
    - Production Company Names, separated by semi-colons if multiple (publishers)
    - End with a period
*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let refAuthorPart = '';
    let refDatePart = '';

    //this type breaks out differently based off series or episode
    switch (refData.type) {
        case 'series':
            
            let seriesDatePart = getSeriesDatePart(refData);
            let seriesSourcePart = getSeriesSourcePart(refData);
            let seriesAuthorPart = getSeriesAuthorPart(refData, false);
            let seriesTitlePart = getSeriesTitlePart(refData);

            refAuthorPart = seriesAuthorPart;
            refDatePart = seriesDatePart;
        
            if (seriesAuthorPart.length == 0) {
                //no contributors
                referenceValue = seriesTitlePart + ' ' + seriesDatePart + ' ' + seriesSourcePart;  
            }
            else{
                referenceValue = seriesAuthorPart + ' ' + seriesDatePart + ' ' + seriesTitlePart + ' ' + seriesSourcePart;
            }

            break;
        case 'episode':
            
            let episodeDatePart = getEpisodeDatePart(refData);
            let episodeSourcePart = getEpisodeSourcePart(refData);
            let episodeAuthorPart = getEpisodeAuthorPart(refData);
            let episodeTitlePart = getEpisodeTitlePart(refData);

            refAuthorPart = episodeAuthorPart;
            refDatePart = episodeDatePart;
        
            if (episodeAuthorPart.length == 0) {
                //no contributors
                referenceValue = episodeTitlePart + ' ' + episodeDatePart + ' ' + episodeSourcePart;  
            }
            else{
                referenceValue = episodeAuthorPart + ' ' + episodeDatePart + ' ' + episodeTitlePart + ' ' + episodeSourcePart;
            }

            break;
    }
    
    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: refAuthorPart,
        datePart: refDatePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    if (refData.type == 'episode') {
        //writers
        _forEach(refData.contributors, (item) => {
            if (item.type == 'writer'){
                authorArray.push(item);
            }
        });

        //directors
        _forEach(refData.contributors, (item) => {
            if (item.type == 'director'){
                authorArray.push(item);
            }
        });

        //writers and directors
        _forEach(refData.contributors, (item) => {
            if (item.type == 'writerDirector'){
                authorArray.push(item);
            }
        });

        //custom
        _forEach(refData.contributors, (item) => {
            if (item.type == 'custom'){
                authorArray.push(item);
            }
        });
    }

    if (refData.type == 'series') {
        //executiveDirector
        _forEach(refData.contributors, (item) => {
            if (item.type == 'executiveDirector'){
                authorArray.push(item);
            }
        });

        //executiveProducer
        _forEach(refData.contributors, (item) => {
            if (item.type == 'executiveProducer'){
                authorArray.push(item);
            }
        });

        //executiveDirectorProducer
        _forEach(refData.contributors, (item) => {
            if (item.type == 'executiveDirectorProducer'){
                authorArray.push(item);
            }
        });

        //custom
        _forEach(refData.contributors, (item) => {
            if (item.type == 'custom'){
                authorArray.push(item);
            }
        });
    }

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    year = shared.fixPageDash(year);

    if (year.length > 0) {
        year = year.split(' ').join('');
    }

    //if there is not author string, we need to use the title
    if (authorString.length == 0) {
        let title = shared.getStringValue(refData.title);
        let episodeTitle = shared.getStringValue(refData.episodeTitle);

        //if we have an episode title, use it instead
        if (episodeTitle.length > 0) {
            title = episodeTitle;
        }
        
        title = shared.stripSubtitle(title);

        if (title.startsWith('[')) {
            authorString = title;
        }
        else{
            if (episodeTitle.length > 0) {
                authorString = '"' + title.toTitleCase() + '," '
            }
            else{
                authorString = title.toTitleCase().italicize();
            }   
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (citationData.type == 'time') {
        citationData.value = shared.fixTimeCitationData(citationData.value);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    //since time is treated differently here than from other places, replace / with :
    if (citationData.type == 'time') {
        citation.first = citation.first.split("/").join(":");
        citation.subsequent = citation.subsequent.split("/").join(":");
    }

    return citation;
}

function getSeriesTitlePart(refData){
    //<em>Series title</em> [TV series].
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.length > 0) {

        if (title.startsWith('[')) {
            titlePart = title + ' [TV series].'
        }
        else{
            titlePart = title.italicize() + ' [TV series].'
        }
    }

    return titlePart;
}

function getSeriesAuthorPart(refData, isSecondary){
    //Authora, A. A., Authorb, B. B., Authorc, C. C., & Authord, D. D. (Executive Producers).
    //Authora, A. A. (Executive Director), Authorb, B. B. (Executive Producer), Authorc, C. C. (Executive Producer), & Authord, D. D. (Executive Producer).
    let authorPart = '';

    //order by: executive director, executive producer, exec dir + exec prod
    //then order within how listed
    //custom at the end

    if (typeof refData.contributors === 'undefined') {
        return '';
    }

    if (refData.contributors.length == 0) {
        return '';
    }

    let executiveProducerArray = [];
    let executiveDirectorArray = [];
    let executiveDirectorProducerArray = [];
    let customArray = [];
    let authorCount = 0;
    let typeCount = 0;

    let typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'executiveProducer') {
            executiveProducerArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'executiveDirector') {
            executiveDirectorArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'executiveDirectorProducer') {
            executiveDirectorProducerArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    if (!isSecondary) {
        typeAdded = false;
        _forEach(refData.contributors, (item) => {
            if (item.type == 'custom') {
                customArray.push(item);
                authorCount++;

                if (!typeAdded) {
                    typeCount++;
                    typeAdded = true;
                }
            }
        });    
    }
    
    //not sure how this would be possible to have contribs not in this list, but whatever
    if (authorCount == 0) {
        return '';
    }

    //now based off the total number we have to process, loop through all arrays at once and build the strings
    if (authorCount == 1) {
        //only one in one of these arrays
        if (executiveProducerArray.length == 1) {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(executiveProducerArray[0]);
            }
            else{
                thisName = shared.getReferenceName(executiveProducerArray[0]);
            }

            let thisLabel = getLabelForContributorType(executiveProducerArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (executiveDirectorArray.length == 1) {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(executiveDirectorArray[0]);
            }
            else{
                thisName = shared.getReferenceName(executiveDirectorArray[0]);
            }
            
            let thisLabel = getLabelForContributorType(executiveDirectorArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (executiveDirectorProducerArray.length == 1) {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(executiveDirectorProducerArray[0]);
            }
            else{
                thisName = shared.getReferenceName(executiveDirectorProducerArray[0]);
            }
            
            let thisLabel = getLabelForContributorType(executiveDirectorProducerArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (customArray.length == 1) {
            let thisName = shared.getReferenceName(customArray[0]);
            let thisLabel = getLabelForContributorType(customArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }
    }

    //2-20
    if (authorCount > 1 && authorCount < 21) {
        let namesUsed = 0;
        
        //Executive Directors
        executiveDirectorArray.forEach(item => {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(item);
            }
            else{
                thisName = shared.getReferenceName(item);
            }

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (executiveDirectorArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(executiveDirectorArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Executive Producers
        executiveProducerArray.forEach(item => {
            let thisName;

            if (isSecondary) {
                let x = shared.getReverseCitationName(item) ;
                thisName = shared.getReverseCitationName(item);
            }
            else{
                thisName = shared.getReferenceName(item);
            }

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);

                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (executiveProducerArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(executiveProducerArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Executive Directors and Producers
        executiveDirectorProducerArray.forEach(item => {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(item);
            }
            else{
                thisName = shared.getReferenceName(item);
            }

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);

                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (executiveDirectorProducerArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(executiveDirectorProducerArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Customs
        customArray.forEach(item => {
            let thisName;

            if (isSecondary) {
                thisName = shared.getReverseCitationName(item);
            }
            else{
                thisName = shared.getReferenceName(item);
            }

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
            }

            let thisLabel = getLabelForContributorType(item, false);

            authorPart = authorPart + ' (' + thisLabel + ')';

            namesUsed++;
        });
    }

    //More Than Twenty
    if (authorCount > 20) {
        let namesUsed = 0;

                //Executive Directors
                executiveDirectorArray.forEach(item => {
                    let thisName;

                    if (isSecondary) {
                        thisName = shared.getReverseCitationName(item);
                    }
                    else{
                        thisName = shared.getReferenceName(item);
                    }

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            if(isSecondary){
                                authorPart = authorPart + ',';
                            }

                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (executiveDirectorArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(executiveDirectorArray[0],true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Executive Producers
                executiveProducerArray.forEach(item => {
                    let thisName;

                    if (isSecondary) {
                        thisName = shared.getReverseCitationName(item);
                    }
                    else{
                        thisName = shared.getReferenceName(item);
                    }

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);

                            if(isSecondary){
                                authorPart = authorPart + ',';
                            }

                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (executiveProducerArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(executiveProducerArray[0], true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Executive Directors and Producers
                executiveDirectorProducerArray.forEach(item => {
                    let thisName;

                    if (isSecondary) {
                        thisName = shared.getReverseCitationName(item);
                    }
                    else{
                        thisName = shared.getReferenceName(item);
                    }

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            
                            if(isSecondary){
                                authorPart = authorPart + ',';
                            }

                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (executiveDirectorProducerArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(executiveDirectorProducerArray[0], true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Customs
                customArray.forEach(item => {
                    let thisName;

                    if (isSecondary) {
                        thisName = shared.getReverseCitationName(item);
                    }
                    else{
                        thisName = shared.getReferenceName(item);
                    }

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount, isSecondary) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            
                            if(isSecondary){
                                authorPart = authorPart + ',';
                            }

                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
    }
    
    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    return authorPart;
}

function getSeriesDatePart(refData){
    //(2015–2018).
    // - Years the series aired
    // - Uses an en dash ("–")
    // - Can include text (e.g., 2015–present)
    // - (2015–present)
    // - Ends with a period

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let cleanedDate = '';

    if (publicationDate.length == 0) {
        cleanedDate = 'n.d.';
    }
    else{
        cleanedDate = shared.fixPageDash(publicationDate);
        cleanedDate = cleanedDate.split(' ').join('');
    }

    let datePart = '(' + cleanedDate + ').';

    return datePart;
}

function getSeriesSourcePart(refData){
    //Production Company 1; Production Company 2; Production Company 3.
    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    
    if (publisherString != '.') {
        sourcePart = publisherString;     
    }

    return sourcePart;
}

function getEpisodeTitlePart(refData){
    // - Title of the episode, not italicized (e.g., Who shot Mr. Burns? (Part one))
    // - Season & episode number in parentheses (Season 4, Episode 12)
    // - followed by "[TV series episode]"
    //Ex: Episode title (Season 3, Episode 21) [TV series episode].
    let titlePart = '';

    let title = shared.getStringValue(refData.episodeTitle);
    let seasonNumber = shared.getStringValue(refData.seasonNumber);
    let episodeNumber = shared.getStringValue(refData.episodeNumber);

    if (title.length > 0) {
        titlePart = title;

        if (seasonNumber.length > 0 || episodeNumber.length > 0) {
            titlePart = titlePart + ' (';

            if (seasonNumber.length > 0) {
                titlePart = titlePart + 'Season ' + seasonNumber;
            }

            if (episodeNumber.length > 0) {
                if (seasonNumber.length > 0) {
                    titlePart = titlePart + ', ';
                }

                titlePart = titlePart + 'Episode ' + episodeNumber;
            }

            titlePart = titlePart + ')';
        }

        titlePart = titlePart + ' [TV series episode].'
    }

    return titlePart;
}

function getEpisodeAuthorPart(refData){
    //Authora, A. A. (Writer), Authorb, B. B. (Director), & Authorc, C. C. (Writer & Director).
    let authorPart = '';

    //order by: writer, director, then writer & director
    //then order by the way they are listed
    //custom at the end

    if (typeof refData.contributors === 'undefined') {
        return '';
    }

    if (refData.contributors.length == 0) {
        return '';
    }

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    let writerArray = [];
    let directorArray = [];
    let writerDirectorArray = [];
    let customArray = [];
    let authorCount = 0;
    let typeCount = 0;

    let typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'writer') {
            writerArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'director') {
            directorArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'writerDirector') {
            writerDirectorArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    typeAdded = false;
    _forEach(refData.contributors, (item) => {
        if (item.type == 'custom') {
            customArray.push(item);
            authorCount++;

            if (!typeAdded) {
                typeCount++;
                typeAdded = true;
            }
        }
    });

    //not sure how this would be possible to have contribs not in this list, but whatever
    if (authorCount == 0) {
        return '';
    }

    //now based off the total number we have to process, loop through all arrays at once and build the strings
    if (authorCount == 1) {
        //only one in one of these arrays
        if (writerArray.length == 1) {
            let thisName = shared.getReferenceName(writerArray[0]);
            let thisLabel = getLabelForContributorType(writerArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (directorArray.length == 1) {
            let thisName = shared.getReferenceName(directorArray[0]);
            let thisLabel = getLabelForContributorType(directorArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (writerDirectorArray.length == 1) {
            let thisName = shared.getReferenceName(writerDirectorArray[0]);
            let thisLabel = getLabelForContributorType(writerDirectorArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }

        if (customArray.length == 1) {
            let thisName = shared.getReferenceName(customArray[0]);
            let thisLabel = getLabelForContributorType(customArray[0], false);
            let thisFullName = thisName + ' (' + thisLabel + ')';

            authorPart = thisFullName;
        }
    }

    //2-20
    if (authorCount > 1 && authorCount < 21) {
        let namesUsed = 0;
        
        //Writer
        writerArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (writerArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(writerArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Director
        directorArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (directorArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(directorArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Writers and Directors
        writerDirectorArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            if (typeCount > 1) {
                let thisLabel = getLabelForContributorType(item, false);
                authorPart = authorPart + ' (' + thisLabel + ')';
            }

            namesUsed++;
        });

        if (writerDirectorArray.length > 0 && typeCount == 1) {
            let thisLabel = getLabelForContributorType(writerDirectorArray[0], true);
            authorPart = authorPart + ' (' + thisLabel + ')';
        }

        //Customs
        customArray.forEach(item => {
            let thisName = shared.getReferenceName(item);

            if (authorPart.length == 0) {
                authorPart = thisName;    
            }
            else{
                authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
            }

            let thisLabel = getLabelForContributorType(item, false);
            authorPart = authorPart + ' (' + thisLabel + ')';

            namesUsed++;
        });
    }

    //More Than Twenty
    if (authorCount > 20) {
        let namesUsed = 0;

                //Writers
                writerArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (writerArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(writerArray[0], true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Directors
                directorArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (directorArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(directorArray[0], true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Executive Directors and Producers
                writerDirectorArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
        
                if (writerDirectorArray.length > 0 && typeCount == 1 && (namesUsed < 19 || namesUsed == authorCount)) {
                    let thisLabel = getLabelForContributorType(writerDirectorArray[0], true);
                    authorPart = authorPart + ' (' + thisLabel + ')';
                }
        
                //Customs
                customArray.forEach(item => {
                    let thisName = shared.getReferenceName(item);

                    if (namesUsed < 19) {
                        if (authorPart.length == 0) {
                            authorPart = thisName;    
                        }
                        else{
                            authorPart = appendNameSeperator(authorPart, namesUsed, authorCount) + thisName;
                        }
            
                        if (typeCount > 1) {
                            let thisLabel = getLabelForContributorType(item, false);
                            authorPart = authorPart + ' (' + thisLabel + ')';
                        }
            
                        namesUsed++;
                    }
                    else{
                        if (namesUsed == authorCount - 1) {
                            authorPart = authorPart + ',...'
                            authorPart = authorPart + thisName;

                            if (typeCount > 1) {
                                let thisLabel = getLabelForContributorType(item, false);
                                authorPart = authorPart + ' (' + thisLabel + ')';
                            }
                        }
                    }
                });
    }
    
    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    return authorPart;
}

function getEpisodeDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getEpisodeSourcePart(refData){
    //In A. A. Authora, (Executive Director), D. D. Authord, (Executive Producer) & E. E. Authore, (Executive Producer), <em>Series title</em>. Production Copmany 1; Production Company 2; Production Company 3."
    // - Starts with "In "
    // - Then list secondary contributors
    //     - Executive Directors (A. A. Authora, (Executive Director))
    //     - Executive Producers (A. A. Authora, (Executive Producer))
    //     - If there is only one kind of contributor with multiple people, list them togehter (A. A. Authora, & B. B. Authorb (Executive Producers)). We can't combine all roles into one if there are multiple contributor types.
    //     - Role possibilites:
    //         - Executive Director
    //         - Executive Directors (multiple Ex. Director contributors only)
    //         - Executive Producer
    //         - Executive Producers (multiple Ex. Producer contributors only)
    //         - Executive Director & Executive Producer
    //         - Executive Directors & Executive Producers (multiple Ex. Director/Ex. Producer contributors only)
    //     - Order of Contributor Roles
    //         - Executive Directors
    //         - Executive Producer
    //         - Executive Director & Producer
    //         - Custom
    // - Follow with a comma
    // - Title of the series in italics (<em>)The wire</em>), followed by a period
    // - Production Company Names, separated by semi-colons if multiple (publishers)
    // - End with a period

    let sourcePart = '';

    //this is a different way of doing this, but this is a different type
    //we're going to re0use the author part for series with a few tweaks in that method
    let secondaryContributorString = getSeriesAuthorPart(refData, true);
    let title = shared.getStringValue(refData.title);
    let publisherString = shared.getPublisherString(refData.publishers);

    //remove the period if we have one
    if (secondaryContributorString.endsWith('.')) {
        secondaryContributorString = secondaryContributorString.substring(0, secondaryContributorString.length - 1);
    }
    
    if (secondaryContributorString.length > 0 || title.length > 0 || publisherString != '.') {
        sourcePart = 'In';     
    }

    if (secondaryContributorString.length > 0) {
        sourcePart = sourcePart + ' ' + secondaryContributorString;
    }

    if (title.length > 0) {
        if (secondaryContributorString.length > 0) {
            sourcePart = sourcePart + ',';
        }
        
        if (!title.startsWith('[')) {
            title = title.italicize();
        }
        
        sourcePart = sourcePart + ' ' + title + '.';
    }

    if (publisherString.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }
        
        sourcePart = sourcePart + publisherString;
    }

    if (!sourcePart.endsWith('.')) {
        sourcePart = sourcePart + '.';
    }

    return sourcePart;
}

function getLabelForContributorType(contributor, isPlural){

    let label = '';

    switch (contributor.type) {
        case 'executiveProducer':

            if (isPlural) {
                label = 'Executive Producers';
            }
            else{
                label = 'Executive Producer';
            }

            break;
        case 'executiveDirector':

            if (isPlural) {
                label = 'Executive Directors';
            }
            else{
                label = 'Executive Director';
            }

            break;
        case 'executiveDirectorProducer':
            
            if (isPlural) {
                label = 'Executive Directors & Executive Producers';
            }
            else{
                label = 'Executive Director & Executive Producer';
            }
;
            break;
        case 'director':

            if (isPlural) {
                label = 'Directors';
            }
            else{
                label = 'Director';
            }

            break;
        case 'writer':

            if (isPlural) {
                label = 'Writers';
            }
            else{
                label = 'Writer';
            }

            break;
        case 'writerDirector':

            if (isPlural) {
                label = 'Writers & Directors';
            }
            else{
                label = 'Writer & Director';
            }
            break;
        case 'custom':
            label = contributor.name;
            break;
    }

    return label;
}

function appendNameSeperator(nameString, namesUsed, authorCount, isSecondary){

    //repetive logic I'm trying to re-use will all these different name types
    if (namesUsed == authorCount - 1) {

        //if three or more, add comma 
        //or if the names are primary, then, add comma before any
        if (isSecondary && authorCount > 2) {
            nameString = nameString + ','
        }

        if (!isSecondary && authorCount > 1) {
            nameString = nameString + ','
        }

        nameString = nameString + ' & '
    }
    else{

        if (nameString.endsWith(',')) {
            nameString = nameString + ' '
        }
        else{
            nameString = nameString + ', '
        }
    }

    return nameString;
}