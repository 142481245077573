const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'charter') {
        citationTitle = shared.getStringValue(refData.title);
        citationTitle = citationTitle.replaceAmpersand();
    }
    else{
        citationTitle = "The Constitution";   
    }

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    return citationDate;
}

function getCitation(refData, citationData) {
    let type = shared.getStringValue(refData.type);
    let primaryContributor = '<em>The Constitution</em>';

    if (type == 'charter') {
        let title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            title = title.italicize();
            primaryContributor = title;
        }
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';
    let type = shared.getStringValue(refData.type);

    switch (type) {
        case 'us':
            primaryPart = '<em>The Constitution of the United States</em>';
            break;
        case 'state':

            let state = shared.getStringValue(refData.state);

            primaryPart = '<em>The Constitution';

            if (state.length > 0) {

                //TODO: de-abbreviate
                state = stateAbbreviationToFull(state);
                primaryPart = primaryPart.appendData(state, ' ', 'of');
            }

            primaryPart = primaryPart + '</em>'

            break;
        case 'charter':
    
            let title = shared.getStringValue(refData.title);

            if (title.length > 0) {
                title = title.replaceAmpersand();
                title = title.italicize();
                primaryPart = primaryPart.appendData(title, ' ');
            }

            break;
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type == 'charter') {
        let charterArticle = shared.getStringValue(refData.charterArticle);
        container1 = container1.appendData(charterArticle, ', ', 'Art.', );

        let paragraph = shared.getStringValue(refData.paragraph);

        var label = 'par.';

        if (shared.usePluralLabel(paragraph)) {
            label = 'pars.'
        }

        container1 = container1.appendData(paragraph, ', ', label, );
    }
    else{
        switch (kind) {
            case 'article':
                
                let article = shared.getStringValue(refData.article);
                container1 = container1.appendData(article, ', ', 'Art.', );

                let section = shared.getStringValue(refData.section);
                container1 = container1.appendData(section, ', ', 'Sec.', );

                break;
            case 'amendment':
            case 'repealed':

                let amendment = shared.getStringValue(refData.amendment);
                container1 = container1.appendData(amendment, ', ', 'Amend.', );
            
                break;
        }
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //TODO: add any container data needed

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    if (kind == 'repealed') {
        let repealDate = shared.getStringValue(refData.repealDate);
        repealDate = shared.getFullDate(repealDate);
        secondSupplemental = secondSupplemental.appendData(repealDate, ' ', 'Repealed');
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//https://www.law.cornell.edu/citation/4-500
function stateAbbreviationToFull(abbreviation){
    //APA is entered in abbreviations...if they entered and abbreviation...convert it

    let word = '';

    var conversions = [
        ['Ala.', 'Alabama'],
        ['Ariz.', 'Arizona'],
        ['Ark.', 'Arkansas'],
        ['Cal.', 'California'],
        ['Colo.', 'Colorado'],
        ['Conn.', 'Connecticut'],
        ['Del.', 'Delaware'],
        ['D.C.', 'District of Columbia'],
        ['Fla.', 'Florida'],
        ['Ga.', 'Georgia'],
        ['Haw.', 'Hawaii'],
        ['Ill.', 'Illinois'],
        ['Ind.', 'Indiana'],
        ['Kan.', 'Kansas'],
        ['Ky.', 'Kentucky'],
        ['La.', 'Louisiana'],
        ['Me.', 'Maine'],
        ['Md.', 'Maryland'],
        ['Mass.', 'Massachusetts'],
        ['Mich.', 'Michigan'],
        ['Minn.', 'Minnesota'],
        ['Miss.', 'Mississippi'],
        ['Mo.', 'Missouri'],
        ['Mont.', 'Montana'],
        ['Neb.', 'Nebraska'],
        ['Nev.', 'Nevada'],
        ['N.H.', 'New Hampshire'],
        ['N.J.', 'New Jersey'],
        ['N.M.', 'New Mexico'],
        ['N.Y.', 'New York'],
        ['N.C.', 'North Carolina'],
        ['N.D.', 'North Dakota'],
        ['Okla.', 'Oklahoma'],
        ['Or.', 'Oregon'],
        ['Pa.', 'Pennsylvania'],
        ['R.I.', 'Rhode Island'],
        ['S.C.', 'South Carolina'],
        ['S.D.', 'South Dakota'],
        ['Tenn.', 'Tennessee'],
        ['Tex.', 'Texas'],
        ['Vt.', 'Vermont'],
        ['Va.', 'Virginia'],
        ['Wash.', 'Washington'],
        ['W. Va.', 'West Virginia'],
        ['Wis.', 'Wisconsin'],
        ['Wyo.', 'Wyoming']
    ];

    for (let index = 0; index < conversions.length; index++) {
        let thisConversion = conversions[index][0];

        if (thisConversion.toLowerCase() == abbreviation.toLowerCase()) {
            word = conversions[index][1];
        }
    }    

    if (word == '') {
        //if we havent converted yet, just leave it as is
        word = abbreviation;
    }

    return word;
}