const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const dateHelper = require('../dateHelper');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference (refData){
    var referenceValue = '';

    //Basic Format
    //Author Part - authorString 
    //Example: Authora, A. A., & Authorb, B. B., Jr.

    //Date Part - (publicationDate).
    //Example: (2018, April 15).

    //Title Part - <em>pageTitle</em> [translatedTitle] [format] (translatorsString).
    //Example: <em>Mi bùsqueda de Sasquatch</em> [My search for Sasquatch] (A. A. Translatora, Trans.). 

    //Source Part - websiteTitle. Retrieved retrievalDate from url
    //Example: Cryptozoology Online Today. Retrieved May 15, 2019 from https://www.cryptozoologytoday.com/sasquatch/search_for_the_truth/

    //for authors, we need to limit this to Authors and usernames
    ///*****Author Part ******/
    let authorArray = [];

    if (typeof refData.contributors != 'undefined') {
        _forEach(refData.contributors, (item) => {
            if (item.type == 'author' || item.type == 'userName' || item.type == 'groupAuthor') {
                authorArray.push(item);
            }
        });
    }

    let authorPart = shared.getReferenceNameString(authorArray, false);
    let editorsUsedInAuthorPart = false;

    if (authorPart.length == 0) {
        //editors
        let editorArray = shared.getEditors(refData.contributors);
        let editorString = shared.getEditorsAtStartofRefString(editorArray);

        if (editorArray.length == 1) {
            editorString = editorString + ' (Ed.).';
        }
        else{
            if (editorArray.length > 1) {
                editorString = editorString + ' (Eds.).';
            }
        }

        if (editorString.length > 0) {
            authorPart = editorString;
            editorsUsedInAuthorPart = true;
        }
    }

    if (authorPart.length > 0 && !authorPart.endsWith('.')) {
        authorPart = authorPart + '.';
    }

    ///*****Date Part ******/
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (publicationDate.length == 0) {
        publicationDate = 'n.d.';
    }
    else{
        if (refData.publicationDate.includes("/")) {
            var array = refData.publicationDate.split("/");

            switch (array.length) {
                case 1:
                    publicationDate = array[0];
                    break;

                case 2:
					publicationDate = dateHelper.format(array[0] + '/1/' + array[1], 'yyyy, mmmm'); 
                    break;
            
                default:
                    if (refData.publicationDate.endsWith("/")) {
                        publicationDate = dateHelper.format(refData.publicationDate, 'mmmm d');
                    }
                    else{
                        publicationDate = dateHelper.format(refData.publicationDate, 'yyyy, mmmm d');
                    }
                    
                    break;
            }

        }
        else{
            publicationDate = refData.publicationDate;
        }
    }

    let datePart = '(' + publicationDate + ').';

    ///*****Title Part ******/
    let title = shared.getStringValue(refData.pageTitle);

    let titlePart = "";

    if (title.length > 0) {
        titlePart = title.italicize()
    }

    //translated title
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedTitle + ']';
    }

    //editors
    if (!editorsUsedInAuthorPart) {
        let editorArray = shared.getEditors(refData.contributors);
        let editorString = shared.getReferenceNameString(editorArray, true);

        if (editorArray.length == 1) {
            editorString = editorString + ', Ed.';
        }
        else{
            if (editorArray.length > 1) {
                editorString = editorString + ', Eds.';
            }
        }

        if (editorString.length > 0) {
            titlePart = titlePart + ' (' + editorString + ')';
        }        
    }

    //format
    let format = shared.getStringValue(refData.format);
    
    if (format.length > 0) {
        titlePart = titlePart + ' [' + format + ']';
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    if (translatorString.length > 0) {
        titlePart = titlePart + ' (' + translatorString + ')';
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);   
    }

    ///*****Source Part ******/
    let websiteTitle = shared.getStringValue(refData.websiteTitle);
    let url = shared.getStringValue(refData.url);
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let sourcePart = '';

    if (websiteTitle.length > 0) {
        if (websiteTitle.replaceAll("’", "'") + '.' != authorPart.replaceAll("’", "'")) {
            sourcePart = websiteTitle + '.'   
        }
    }

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';    
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);    
        sourcePart = sourcePart + ', from' ;
    }

    if (sourcePart.length > 0) {
        sourcePart = sourcePart + ' ';    
    }

    url = shared.formatURL(url);
    sourcePart = sourcePart + url ;

    //build the reference from the parts
    var referenceValue;

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;    
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value : referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs){

    // (Author, YYYY, location)
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'userName' || item.type == 'groupAuthor') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    // let location = shared.getCitationLocationString(citationData);

    if (authorString.length == 0) {
        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
    }

    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        if (refData.pageTitle.length > 0) {
            authorString = refData.pageTitle.toTitleCase().italicize();    
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}