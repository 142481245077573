const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);

    if (publicationPart.length == 0 && locationPart.length == 0) {
        referenceValue = referenceValue.addClosure();
    }
    else{
        referenceValue = referenceValue.appendData(publicationPart, ', ');
        referenceValue = referenceValue.appendData(locationPart);
    }

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';
    
    let kind = shared.getStringValue(refData.kind);

    let workTitle = '';
    let translatedWorkTitle = '';

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedSectionTitle); 
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
        }
        
    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedIssueTitle); 
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
        }

    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedArticleTitle);
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
        }
    }
    
    if (translatedWorkTitle.length > 0 && workTitle.length == 0) {
        workTitle = translatedWorkTitle;
        translatedWorkTitle = '';
    }

    if (workTitle.length > 0) {
        titlePart = workTitle;
    }

    if (translatedWorkTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedWorkTitle + ']';
    }

    if(kind === 'retracted'){
        titlePart = titlePart.appendData('(retracted)');
    }

    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    let newspaperTitle = shared.getStringValue(refData.newspaperTitle);
    newspaperTitle = newspaperTitle.replaceAmpersand().toTitleCase().italicize();
    switch(kind){
        case 'editorial':
            titlePart = titlePart.appendData(newspaperTitle);
            titlePart = titlePart.appendData('editorial', ', ');
            break;
        case 'issue':
            titlePart = titlePart.appendData('Special issue');
            titlePart += ',';
            titlePart = titlePart.appendData(newspaperTitle);
            break;
      
        default:
            titlePart = titlePart.appendData(newspaperTitle);
    }

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);
    
    // 21 Editorial: Minimal - Turabian 9 Reference - there isn't a pubDate but i can't get it to change the comma into a period. 
    // could be something from above in get title part where i add a comma if it's an editorial
    if (publicationDate.length > 0) {
        publicationDate = shared.getFullDate(publicationDate);   
        publicationPart = publicationPart.appendData(publicationDate);
        publicationPart = publicationPart.addClosure();
    }

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = '';
    let doi = shared.getStringValue(refData.doi);
    
    let kind = shared.getStringValue(refData.kind);

    if(kind === 'retracted'){
        doi = shared.getStringValue(refData.retractionDOI);
        url = shared.getStringValue(refData.retractionURL);
        
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        } else {
            if(doi === ''){
                // it's retracted and retractionDOI is still blank so fallback to original doi
                doi = shared.getStringValue(refData.doi);        
                doi = shared.appendDOIURL(doi).addClosure();
                locationPart= locationPart.appendData(doi);

            } else if (doi.length > 0) {
                doi = shared.appendDOIURL(doi).addClosure();
                locationPart= locationPart.appendData(doi);
              
            }
        }

    } else {
        url = shared.getStringValue(refData.url);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi).addClosure();
            locationPart = locationPart.appendData(doi);
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url).addClosure();
                locationPart = locationPart.appendData(url);
            }
        }
    }

    

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(articleTitle);
    } else{
        citationTitle = citationTitle.replaceAmpersand().toTitleCase();    
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let kind = shared.getStringValue(refData.kind);

    let workTitle = '';
    let translatedWorkTitle = '';

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedSectionTitle);    
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
        }

    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedIssueTitle);    
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();    
        }

    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);
        translatedWorkTitle = shared.getStringValue(refData.translatedArticleTitle);    
        
        if (workTitle.startsWith('[')) {
            workTitle = shared.removeDescriptionFromTitle(workTitle);
        } else{
            workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();    
        }
    }
    

    if (translatedWorkTitle.length > 0 && workTitle.length == 0) {
        workTitle = translatedWorkTitle;
        translatedWorkTitle = '';
    }

    if (translatedWorkTitle.length > 0) {
        workTitle = workTitle + ' [' + translatedWorkTitle + ']';
    }

    firstInfo = firstInfo.appendData(workTitle, ", ");

    if(kind === 'retracted'){
        firstInfo = firstInfo.appendData('(retracted)');
    }

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.toLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');

    let newspaperTitle = shared.getStringValue(refData.newspaperTitle);
    newspaperTitle = newspaperTitle.replaceAmpersand().toTitleCase().italicize();

    if(kind === 'issue'){
        firstInfo = firstInfo.appendData('special issue', ', ');
    }

    firstInfo = firstInfo.appendData(newspaperTitle, ", ");
    
    if(kind === 'editorial'){
        firstInfo = firstInfo.appendData('editorial', ', ');
    }

    let publicationDate = shared.getFullDate(refData.publicationDate);

    if (publicationDate != 'n.d.') {
        let publisherPart = '';
        publisherPart = publisherPart.appendData(publicationDate, ', ');
        firstInfo = firstInfo.appendData(publisherPart, ', ');
    }

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    // doi/url
    let url = '';
    let doi = '';
    
    if(kind === 'retracted'){
        doi = shared.getStringValue(refData.retractionDOI);
        url = shared.getStringValue(refData.retractionURL);

        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        } else {
            if(doi === ''){
                // it's retracted and retractionDOI is still blank so fallback to original doi
                doi = shared.getStringValue(refData.doi);        
                doi = shared.appendDOIURL(doi).addClosure();
                firstInfo= firstInfo.appendData(doi, ', ');

            } else if (doi.length > 0) {
                doi = shared.appendDOIURL(doi).addClosure();
                firstInfo= firstInfo.appendData(doi, ', ');
              
            }
            
        }

    } else {
        doi = shared.getStringValue(refData.doi);
        url = shared.getStringValue(refData.url);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi).addClosure();
            firstInfo= firstInfo.appendData(doi, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url).addClosure();
                firstInfo = firstInfo.appendData(url, ', ');
            }
        }
    }


    

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    if(kind === 'section' ){
        workTitle = shared.getStringValue(refData.sectionTitle);
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
    } else if(kind === 'issue' ){
        workTitle = shared.getStringValue(refData.issueTitle);
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
    } else { // kind === 'article' || kind === 'editorial'
        workTitle = shared.getStringValue(refData.articleTitle);
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
    }
   
    

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}