const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.articleTitle);

    if (refData.kind == 'section') {
        citationTitle = shared.getStringValue(refData.sectionTitle);
    }

    if (refData.kind == 'issue') {
        citationTitle = shared.getStringValue(refData.issueTitle);
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'articleTitle';
    let isSecondaryTitle = true;

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (kind == 'section') {
        titleProperty = 'sectionTitle';
        isSecondaryTitle = true;
    }

    if (kind == 'issue') {
        titleProperty = 'issueTitle';
        isSecondaryTitle = true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let title = '';
    
    if (type == 'special') {
        
        if (kind == 'section') {
            title = shared.getStringValue(refData.sectionTitle);
        }

        if (kind == 'issue') {
            title = shared.getStringValue(refData.issueTitle);
        }
    }
    else {
        title = shared.getStringValue(refData.articleTitle);
    }
    
    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();

            if (type == 'special' && kind == 'issue') {
                title = title.italicize();
            }
            else {
                title = title.doubleQuote();
            }
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    //translatedTitle
    let translatedTitle = '';

    if (type == 'special') {
        
        if (kind == 'section') {
            translatedTitle = shared.getStringValue(refData.translatedSectionTitle);
        }

        if (kind == 'issue') {
            translatedTitle = shared.getStringValue(refData.translatedIssueTitle);
        }
    }
    else {
        translatedTitle = shared.getStringValue(refData.translatedArticleTitle);
    }

    if (translatedTitle.length > 0) {
        translatedTitle = translatedTitle.replaceAmpersand();
        translatedTitle = translatedTitle.toTitleCase();

        if (type == 'special' && kind == 'issue') {
            translatedTitle = translatedTitle.italicize();
        }
        else {
            translatedTitle = translatedTitle.doubleQuote();
        }

        if (title.length > 0) {
            translatedTitle = '[' + translatedTitle + ']';
        }
        
        primaryPart = primaryPart.appendData(translatedTitle, ' ', '', '');        
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type != 'reprinted' || kind != 'article') {
        //originalPublicationDate 
        let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
        originalPublicationDate = shared.getFullDate(originalPublicationDate);
        originalPublicationDate = shared.formatOriginalPublicationDate(originalPublicationDate);
        firstSupplemental = firstSupplemental.appendData(originalPublicationDate, ', ');        
    }

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //journalTitle
    let journalTitle = shared.getStringValue(refData.journalTitle);

    if (journalTitle.length > 0) {
        journalTitle = journalTitle.replaceAmpersand();
        journalTitle = journalTitle.toTitleCase();
        journalTitle = journalTitle.italicize();

        let prefix = '';

        if (type == 'special') {

            if (kind == 'section') {
                prefix = 'Special section of';
            }

            if (kind == 'issue') {
                prefix = 'Special issue of';
            }
        }

        container1 = container1.appendData(journalTitle, ', ', prefix);
    }

    //Secondary Contribs
    let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    container1 = container1.appendData(secondaryContributor, ', ');

    //volume
    let volume = shared.getStringValue(refData.volume);
    container1 = container1.appendData(volume, ', ', 'vol.', '');

    //issue
    let issue = shared.getStringValue(refData.issue);
    container1 = container1.appendData(issue, ', ', 'no.', '');

    if (kind == 'advance' || kind == 'inPress') {
        container1 = container1.appendData('forthcoming', ', ');
    }
    
    //publicationDate
    let publicationDate = shared.getMonthYear(refData.publicationDate);
    container1 = container1.appendData(publicationDate, ', ');
    
    //referencePages
    let referencePages = shared.getStringValue(refData.referencePages);
    referencePages = shared.formatPageNumbers(referencePages);
    container1 = container1.appendData(referencePages, ', ');

    //articleNumber
    let articleNumber = shared.getStringValue(refData.articleNumber);
    container1 = container1.appendData(articleNumber, ', ', 'article', '');

    if (type != 'database') {
        // doi/url
        let url = shared.getStringValue(refData.url);
        let doi = shared.getStringValue(refData.doi);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi);
            container1 = container1.appendData(doi, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url);
                container1 = container1.appendData(url, ', ');
            }
        }
    }



    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type != 'database') {
        //retrievalDate
        let retrievalDate = shared.getStringValue(refData.retrievalDate);
        retrievalDate = shared.getFullDate(retrievalDate);
        container2 = container2.appendData(retrievalDate, ', ', 'accessed', '');
    }

    let originalInfo = '';

    if (type == 'reprinted' && kind == 'article') {
        // 'Originally published as', originalArticleTitle, originalJournaltitle, originalVolume, originalIssue, origianlPublicationDate, originalPages, originalURL/DOI."
        
        let hasTitle = false;

        //originalArticleTitle
        let originalArticleTitle = shared.getStringValue(refData.originalArticleTitle);
        let articleTitle = shared.getStringValue(refData.articleTitle);

        if (originalArticleTitle.length > 0 && originalArticleTitle != articleTitle) {
            //if this is a description, remove the brackets and don't italicize
            if (originalArticleTitle.startsWith('[')) {
                originalArticleTitle = shared.removeDescriptionFromTitle(originalArticleTitle);
            }
            else{
                originalArticleTitle = originalArticleTitle.replaceAmpersand();
                originalArticleTitle = originalArticleTitle.toTitleCase();
                originalArticleTitle = originalArticleTitle.doubleQuote();
            }
            
            originalInfo = originalInfo.appendData(originalArticleTitle, ', ', '', '');
            hasTitle = true;
        }

        //originalJournalTitle
        let originalJournalTitle = shared.getStringValue(refData.originalJournalTitle);
        let journalTitle = shared.getStringValue(refData.journalTitle);

        if (originalJournalTitle.length > 0 && originalJournalTitle != journalTitle) {
            originalJournalTitle = originalJournalTitle.replaceAmpersand();
            originalJournalTitle = originalJournalTitle.toTitleCase();
            originalJournalTitle = originalJournalTitle.italicize();
            originalInfo = originalInfo.appendData(originalJournalTitle, ', ');
            hasTitle = true;
        }

        //originalVolume
        let originalVolume = shared.getStringValue(refData.originalVolume);
        originalInfo = originalInfo.appendData(originalVolume, ', ', 'vol.', '');

        //originalIssue
        let originalIssue = shared.getStringValue(refData.originalIssue);
        originalInfo = originalInfo.appendData(originalIssue, ', ', 'no.', '');

        //originalPublicationDate
        let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
        originalPublicationDate = shared.getFullDate(originalPublicationDate);
        originalPublicationDate = shared.formatOriginalPublicationDate(originalPublicationDate);
        originalInfo = originalInfo.appendData(originalPublicationDate, ', ');        

        //originalPages
        let originalPages = shared.getStringValue(refData.originalPages);
        originalPages = shared.formatPageNumbers(originalPages);
        originalInfo = originalInfo.appendData(originalPages, ', ');

        // original doi/url
        let originalUrl = shared.getStringValue(refData.originalUrl);
        let originalDoi = shared.getStringValue(refData.originalDoi);

        if (originalDoi.length > 0) {
            originalDoi = shared.appendDOIURL(originalDoi);
            originalInfo = originalInfo.appendData(originalDoi, ', ');
        }
        else{
            if (originalUrl.length > 0) {
                originalUrl = shared.formatURL(originalUrl);
                originalInfo = originalInfo.appendData(originalUrl, ', ');
            }
        }
        
        if (originalInfo.length > 0) {

            if (hasTitle) {
                container2 = container2.appendData(originalInfo, ', ', 'originally published as', '');
            }
            else {
                container2 = container2.appendData(originalInfo, ', ', 'originally published in', '');
            }
        }
    }

    if (kind == 'editorial') {
        container2 = container2.appendData('editorial', ', ');
    }

    if (kind == 'retracted') {
        //Retraction volume, retraction issue, retraction date, retraction pages, retraction doi/url.

        let retractionInfo = '';

         //retractionVolume
        let retractionVolume = shared.getStringValue(refData.retractionVolume);
        retractionInfo = retractionInfo.appendData(retractionVolume, ', ', 'vol.', '');

        //retractionIssue
        let retractionIssue = shared.getStringValue(refData.retractionIssue);
        retractionInfo = retractionInfo.appendData(retractionIssue, ', ', 'no.', '');

        //retractionDate
        let retractionDate = shared.getStringValue(refData.retractionDate);
        retractionDate = shared.getFullDate(retractionDate);
        retractionInfo = retractionInfo.appendData(retractionDate, ', ');
        
        //retractionPages
        let retractionPages = shared.getStringValue(refData.retractionPages);
        retractionPages = shared.formatPageNumbers(retractionPages);
        retractionInfo = retractionInfo.appendData(retractionPages, ', ');

        //retractionArticleNumber
        let retractionArticleNumber = shared.getStringValue(refData.retractionArticleNumber);
        retractionInfo = retractionInfo.appendData(retractionArticleNumber, ', ', 'article', '');

        // doi/url
        let retractionURL = shared.getStringValue(refData.retractionURL);
        let retractionDOI = shared.getStringValue(refData.retractionDOI);

        if (retractionDOI.length > 0) {
            retractionDOI = shared.appendDOIURL(retractionDOI);
            retractionInfo = retractionInfo.appendData(retractionDOI, ', ');
        }
        else{
            if (retractionURL.length > 0) {
                retractionURL = shared.formatURL(retractionURL);
                retractionInfo = retractionInfo.appendData(retractionURL, ', ');
            }
        }

        if (retractionInfo.length > 0) {
            container2 = container2.appendData(retractionInfo, ', ', 'Retracted in');
        }
    }

    if (type == 'database') {

        //databaseTitle
        let databaseTitle = shared.getStringValue(refData.databaseTitle);

        if (databaseTitle.length > 0) {
            databaseTitle = databaseTitle.replaceAmpersand();
            databaseTitle = databaseTitle.toTitleCase();
            databaseTitle = databaseTitle.italicize();
            container2 = container2.appendData(databaseTitle, ', ', '', '');
        }

        // doi/url
        let url = shared.getStringValue(refData.url);
        let doi = shared.getStringValue(refData.doi);

        if (doi.length > 0) {
            doi = shared.appendDOIURL(doi);
            container2 = container2.appendData(doi, ', ');
        }
        else{
            if (url.length > 0) {
                url = shared.formatURL(url);
                container2 = container2.appendData(url, ', ');
            }
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);
    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    if (type == 'database') {

        //retrievalDate
        let retrievalDate = shared.getStringValue(refData.retrievalDate);
        retrievalDate = shared.getFullDate(retrievalDate);
        secondSupplemental = secondSupplemental.appendData(retrievalDate, ', ', 'accessed', '');
    }

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}