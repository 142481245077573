/*
*****Videos*****
 
Documentation: https://docs.google.com/spreadsheets/d/1ODFPbTjf6GS654md6Q30E3ReRSqBqM5kw56RMQ72re8/edit#gid=1084144617

Type & Kind Questions
- TYPES
    - [streaming]: A streaming (online) video
    - [webinar]: A recorded webinar

- KINDS
    - [posted]: for [streaming] - a video posted on a streaming site
    - [hosted]: for [streaming] - a video on its own website
    - No kinds for webinar

TODO: Document the parts and their fields here
AuthorPart
    - Contributors
        - [contributor.author] - Normal individual name
        - [contributor.groupAuthor] - Normal Group Author
        - [contributor.userName] - Normal username
    - Ends with a period

DatePart
    - [publicationDate]
    - This can be a year or a full date, but we're asking for a full date
    - ends with a period

TitlePart
    - [title]
    - title is italicized
    - followed by the format "[Video]" or "[Webinar]"
    - ends with a period

SourcePart
    - [publisher.reference]
    - There's only ever one
    - followed by a period
    - URL at the end

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'userName' || item.type == 'groupAuthor') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {
        let title = shared.getStringValue(refData.title);

        if (title.length > 0) {
            title = shared.stripSubtitle(title);

            if (title.startsWith('[')) {
                authorString = title;
            }
            else{
                authorString = title.toTitleCase().italicize();   
            }
        }
        else{
            let description = shared.getStringValue(refData.description);

            if (description.length > 0) {
                authorString = description.bracket();
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    if (citationData.type == 'time') {
        citationData.value = shared.fixTimeCitationData(citationData.value);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    //since time is treated differently here than from other places, replace / with :
    if (citationData.type == 'time') {
        citation.first = citation.first.split("/").join(":");
        citation.subsequent = citation.subsequent.split("/").join(":");
    }

    return citation;
}

function getTitlePart(refData){
    // - [title]
    // - title is italicized
    // - followed by the format "[Video]" or "[Webinar]"
    // - ends with a period
    //<em>Video title</em> [Video].

    let titlePart = '';
    let title = shared.getStringValue(refData.title);
    let format = '';

    if (title.length > 0) {

        if (title.startsWith('[')) {
            titlePart = title;  
        }
        else{
            titlePart = title.italicize();  
        }
    }
    else{
        let description = shared.getStringValue(refData.description);

        if (description.length > 0) {
            titlePart = description.bracket();
        }
    }

    switch (refData.type) {
        case 'streaming':
            format = '[Video]';
            break;
        case 'webinar':
            format = '[Webinar]';
            break;
    }

    if (format.length > 0) {
        titlePart = titlePart + ' ' + format;
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getSourcePart(refData){
    //Platform Title. https://www.perrla.com/video
    // - [publisher.reference]
    // - There's only ever one
    // - followed by a period
    // - URL at the end

    let sourcePart = '';
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorPart = getAuthorPart(refData);
    let url = shared.getStringValue(refData.url);

    if (publisherString != '.' && publisherString != authorPart) {
        sourcePart = publisherString;     
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}