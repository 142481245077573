const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';

    let authorPart = shared.getAuthorPart(refData, false);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, false, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData, false)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitation(refData, citationData) {

    //define our citation logic for this type here
    let titleProperty = 'pageTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty, false, true);
    let titlePart = getCitationTitlePart(refData);
    let publicationPart = getCitationPublicationPart(refData);
    let locationPart = getCitationLocationPart(refData);

    firstInfo = firstInfo.appendData(authorPart);
    firstInfo = firstInfo.appendData(titlePart, ", ");
    firstInfo = firstInfo.appendData(publicationPart, ", ");
    firstInfo = firstInfo.appendData(locationPart, ", ");

    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true, true);
    let subsequentTitlePart = getSubsequentCitationTitlePart(refData);

    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);
    subsequentInfo = subsequentInfo.appendData(subsequentTitlePart, ", ");

    let citation = shared.getCitations(citationData, firstInfo, subsequentInfo, refData);

    return citation;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.citationTitle).replaceAmpersand();
    
    return citationTitle;
}

function getTitlePart(refData){
    let titlePart = '';

    let pageTitle = shared.getStringValue(refData.pageTitle);
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (pageTitle.startsWith('[')) {
        pageTitle = shared.removeDescriptionFromTitle(pageTitle);
    }

    //If the work contains a pageTitle and a translatedTitle, then both titles are NOT title cased - they are left in sentence case.
    if (pageTitle.length > 0 && translatedTitle.length > 0) {
        // pagetitle = pageTitle.doubleQuote().addClosure();
        pageTitle = pageTitle.doubleQuote();

        translatedTitle = translatedTitle.bracket().addClosure();
    }
    else{
        pageTitle = pageTitle.replaceAmpersand();
        pageTitle = pageTitle.toTitleCase().doubleQuote().addClosure();
        translatedTitle = translatedTitle.bracket().addClosure();
    }


    titlePart = titlePart.appendData(pageTitle);
    titlePart = titlePart.appendData(translatedTitle);

    if (titlePart.length == 0) {
        let websiteTitle = shared.getStringValue(refData.websiteTitle);

        if (websiteTitle.length > 0) {
            titlePart = websiteTitle.doubleQuote().addClosure();
        }
    }

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let secondaryContributors = shared.getSecondaryContributorString(refData);
    secondaryContributors = secondaryContributors.addClosure();
    publicationPart = publicationPart.appendData(secondaryContributors);

    let websiteTitle = shared.getStringValue(refData.websiteTitle);
    websiteTitle = websiteTitle.addClosure();

    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.replaceAll("’", "'") != websiteTitle.replaceAll("’", "'") && titlePart != websiteTitle.doubleQuote()) {
        publicationPart = publicationPart.appendData(websiteTitle);
    }

    //group authors are treated as a publisher here...if they are not the only authors
    let groupAuthors = shared.getGroupAuthorCitationString(refData.contributors);

    if (groupAuthors.length > 0) {
        if (websiteTitle != groupAuthors && groupAuthors.addClosure() != authorPart) {
            groupAuthors = groupAuthors.addClosure();
            publicationPart = publicationPart.appendData(groupAuthors);
        }
    }

    // let publicationDate = shared.getStringValue(refData.publicationDate);
    // publicationDate = shared.getFullDate(publicationDate).addClosure();
    

    // if (publicationDate = 'n.d.') {
    //     let retrievalDate = shared.getStringValue(refData.retrievalDate);
    //    retrievalDate = shared.getFullDate(retrievalDate).addClosure();
    //    publicationPart = publicationPart.appendData(retrievalDate, ' ', 'Accessed');
    // }
    // else{
    //     publicationPart = publicationPart.appendData(publicationDate);
    // }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let retrievalDate = shared.getStringValue(refData.retrievalDate);

    if (publicationDate.length == 0 && retrievalDate.length > 0) {
        retrievalDate = shared.getFullDate(retrievalDate).addClosure();
        publicationPart = publicationPart.appendData(retrievalDate, ' ', 'Accessed', '');
    }
    else{
        publicationDate = shared.getFullDate(publicationDate).addClosure();
        publicationPart = publicationPart.appendData(publicationDate, " ");
    }

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    let url = shared.getStringValue(refData.url);
    url = shared.formatURL(url).addClosure();
    locationPart = locationPart.appendData(url);

    return locationPart;
}

function getCitationTitlePart(refData){
    let titlePart = '';

    let pageTitle = shared.getStringValue(refData.pageTitle);
    
    if (pageTitle.startsWith('[')) {
        pageTitle = shared.removeDescriptionFromTitle(pageTitle);
    }

    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    //If the work contains a pageTitle and a translatedTitle, then both titles are NOT title cased - they are left in sentence case.
    if (pageTitle.length > 0 && translatedTitle.length > 0) {
        pagetitle = pageTitle.doubleQuote();
        translatedTitle = translatedTitle.bracket();
    }
    else{
        pageTitle = pageTitle.replaceAmpersand();
        pagetitle = pageTitle.toTitleCase().doubleQuote();
        translatedTitle = translatedTitle.bracket();
    }
    
    titlePart = titlePart.appendData(pagetitle);
    titlePart = titlePart.appendData(translatedTitle);

    return titlePart;
}

function getCitationPublicationPart(refData){
    let publicationPart = '';

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    publicationPart = publicationPart.appendData(secondaryContributors, ", ");

    let titlePart = getCitationTitlePart(refData);

    let websiteTitle = shared.getStringValue(refData.websiteTitle);

    if (titlePart.length == 0 && publicationPart.length == 0) {
        websiteTitle = websiteTitle.doubleQuote();
    }

    publicationPart = publicationPart.appendData(websiteTitle.replaceAll("’", "'"), ", ");

    //group authors are treated as a publisher here
    let groupAuthors = shared.getGroupAuthorCitationString(refData.contributors);

    if (websiteTitle.replaceAll("’", "'") != groupAuthors.replaceAll("’", "'")) {
        publicationPart = publicationPart.appendData(groupAuthors, ", ");
    }

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let retrievalDate = shared.getStringValue(refData.retrievalDate);

    if (publicationDate.length == 0 && retrievalDate.length > 0) {
        retrievalDate = shared.getFullDate(retrievalDate);
        publicationPart = publicationPart.appendData(retrievalDate, ', ', 'accessed', '');
    }
    else{
        publicationDate = shared.getFullDate(publicationDate);
        publicationPart = publicationPart.appendData(publicationDate, ", ");
    }

    return publicationPart;
}

function getCitationLocationPart(refData){
    let locationPart = '';

    let url = shared.getStringValue(refData.url);
    url = shared.formatURL(url);
    locationPart = locationPart.appendData(url);

    return locationPart;
}

function getSubsequentCitationTitlePart(refData){
    let titlePart = '';

    let pageTitle = shared.getStringValue(refData.pageTitle);
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    //If the work contains a pageTitle and a translatedTitle, then both titles are NOT title cased - they are left in sentence case.
    if (pageTitle.length > 0 && translatedTitle.length > 0) {
        pagetitle = pageTitle.shortenName().doubleQuote();
    }
    else{
        pageTitle = pageTitle.replaceAmpersand();
        pagetitle = pageTitle.shortenName().toTitleCase().doubleQuote();
    }

    titlePart = titlePart.appendData(pagetitle);

    if (titlePart.length == 0) {
        let websiteTitle = shared.getStringValue(refData.websiteTitle);

        titlePart = websiteTitle.shortenName().doubleQuote();
    }

    return titlePart;
}