const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    // use title or description if title is empty
	let title = shared.getStringValue(refData.title);

    if (title.length == 0) {
        citationTitle = shared.getStringValue(refData.description);
    }
    else{
        citationTitle = title;   
    }

    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
	let isSecondaryTitle = false;

    let primaryContributor;
	let titleProperty;

	// use title or description if title is empty
	let title = shared.getStringValue(refData.title);

	if (title.length > 0) {
		titleProperty = 'title';
	} else  {
		titleProperty = 'description';
	}
	
	if(refData.contributors.length === 0){
		// no contributors exisit
		if (title.length === 0) {
			primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle, true);
		} else {
			primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
		}
		
	} else {
		// contributors exisit
		primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle, true);
	}

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let title = shared.getStringValue(refData.title);

	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		} else{
            title = title.replaceAmpersand();
			title = title.toTitleCase();
			title = title.italicize();
		}
	}

    if (title.length > 0) {
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    } else {
		// since there is not a title, only a description, the description becomes the title in Primary Info
		//description
		description = shared.getStringValue(refData.description);
		if (description.length > 0) {
			primaryPart = primaryPart.appendData(description, ' ');
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    //Publishers
    let publisherString = shared.getPublisherString(refData.publishers);
    container1 = container1.appendData(publisherString, ', ');

	//publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

	//url
	let url = shared.getStringValue(refData.url);
	if (url.length > 0) {
		url = shared.formatURL(url);
		container1 = container1.appendData(url, ', ');
	}

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

	container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';
	
	//retrievalDate
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    retrievalDate = shared.getFullDate(retrievalDate);
    secondSupplemental = secondSupplemental.appendData(retrievalDate, ', ', 'accessed', '');

	let title = shared.getStringValue(refData.title);

	//description
	if(title.length > 0){
	let description = shared.getStringValue(refData.description);
	if (description.length > 0) {
		description = description.toLowerCase();
		secondSupplemental = secondSupplemental.appendData(description, ', ');
	}
	}

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}