const shared = require('./Turabian9-Shared.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
        
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    
    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);

    let workTitle = shared.getStringValue(refData.title).replaceAmpersand();
    workTitle = workTitle.italicize();    
    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    if(type !== 'appealed'){
        let volume = shared.getStringValue(refData.volume);
        titlePart = titlePart.appendData(volume);
    }
    
    // get fields here to use in the switch below
    let pageNumber = shared.getStringValue(refData.pageNumber);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
    }

    let series = shared.getStringValue(refData.series);
    let seriesAbbreviation = getSeriesAbbreviation(series);

    let circuit = shared.getStringValue(refData.circuit);

    switch(type){
        case 'appealed':
            let appealVolume = shared.getStringValue(refData.appealVolume);
            let appealSeries = shared.getStringValue(refData.appealSeries);
            let appealAbbreviation = getSeriesAbbreviation(appealSeries);
            let appealPageNumber = shared.getStringValue(refData.appealPageNumber);
            let appealCircuit = shared.getStringValue(refData.appealCircuit);
            let appealDate = shared.getStringValue(refData.appealDate);
            appealDate = shared.getFullDate(appealDate); 

            let appealCircuitPart = '';
            appealCircuitPart = appealCircuitPart.appendData(appealCircuit, ' ', '', 'Cir.');
            appealCircuitPart = appealCircuitPart.appendData(appealDate);
            appealCircuitPart = appealCircuitPart.parenthesis();

            titlePart = titlePart.appendData(appealVolume);
            titlePart = titlePart.appendData(appealAbbreviation, ' ', 'F.');
            titlePart = titlePart.appendData(appealPageNumber);
            
            titlePart = titlePart.appendData(appealCircuitPart);
            break;

        case 'circuit':
            titlePart = titlePart.appendData(seriesAbbreviation, ' ', 'F.');
            titlePart = titlePart.appendData(pageNumber, ' ');

            let circuitPart = '';
            circuitPart = circuitPart.appendData();
            circuitPart = circuitPart.appendData(circuit, ' ', '', 'Cir.');
            circuitPart = circuitPart.appendData(publicationDate);

            circuitPart = circuitPart.parenthesis()
            titlePart = titlePart.appendData(circuitPart, ' ');
            break;

        case 'district':
            titlePart = titlePart.appendData(seriesAbbreviation, ' ', 'F. Supp.');
            titlePart = titlePart.appendData(pageNumber, ' ');

            let districtPart = '';
            let district = shared.getStringValue(refData.district);
            districtPart = districtPart.appendData(district, ' ');
            districtPart = districtPart.appendData(publicationDate);
            districtPart = districtPart.parenthesis();

            titlePart = titlePart.appendData(districtPart, ' ');
            break;

        case 'supreme':
            titlePart = titlePart.appendData('US');
            titlePart = titlePart.appendData(pageNumber);
            titlePart = titlePart.appendData(publicationDate.parenthesis());
            break;
    }

    titlePart = titlePart.appendData(url, ', ');
    titlePart = titlePart.addClosure();

    return titlePart;
}


function getCitationTitle(refData){
    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {
    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title).replaceAmpersand();
    workTitle = workTitle.italicize();    
    firstInfo = firstInfo.appendData(workTitle, ', ');
    
    if(type !== 'appealed'){
        let volume = shared.getStringValue(refData.volume);
        firstInfo = firstInfo.appendData(volume, ', ');
    }

    // get fields here to use in the switch below
    let pageNumber = shared.getStringValue(refData.pageNumber);

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url);
    }

    let series = shared.getStringValue(refData.series);
    let seriesAbbreviation = getSeriesAbbreviation(series);

    let circuit = shared.getStringValue(refData.circuit);

    switch(type){
        case 'appealed':
            let appealVolume = shared.getStringValue(refData.appealVolume);
            let appealSeries = shared.getStringValue(refData.appealSeries);
            let appealAbbreviation = getSeriesAbbreviation(appealSeries);
            let appealPageNumber = shared.getStringValue(refData.appealPageNumber);
            let appealCircuit = shared.getStringValue(refData.appealCircuit);
            let appealDate = shared.getStringValue(refData.appealDate);
            appealDate = shared.getFullDate(appealDate); 

            let appealCircuitPart = '';
            appealCircuitPart = appealCircuitPart.appendData(appealCircuit, ' ', '', 'Cir.');
            appealCircuitPart = appealCircuitPart.appendData(appealDate);
            appealCircuitPart = appealCircuitPart.parenthesis();

            firstInfo = firstInfo.appendData(appealVolume, ', ');
            firstInfo = firstInfo.appendData(appealAbbreviation, ' ', 'F.');
            firstInfo = firstInfo.appendData(appealPageNumber);
            
            firstInfo = firstInfo.appendData(appealCircuitPart);
            break;

        case 'circuit':
            firstInfo = firstInfo.appendData(seriesAbbreviation, ' ', 'F.');
            firstInfo = firstInfo.appendData(pageNumber, ' ');

            let circuitPart = '';
            circuitPart = circuitPart.appendData();
            circuitPart = circuitPart.appendData(circuit, ' ', '', 'Cir.');
            circuitPart = circuitPart.appendData(publicationDate);

            circuitPart = circuitPart.parenthesis()
            firstInfo = firstInfo.appendData(circuitPart, ' ');
            break;

        case 'district':
            firstInfo = firstInfo.appendData(seriesAbbreviation, ' ', 'F. Supp.');
            firstInfo = firstInfo.appendData(pageNumber, ' ');

            let districtPart = '';
            let district = shared.getStringValue(refData.district);
            districtPart = districtPart.appendData(district, ' ');
            districtPart = districtPart.appendData(publicationDate);
            districtPart = districtPart.parenthesis();

            firstInfo = firstInfo.appendData(districtPart, ' ');
            break;

        case 'supreme':
            firstInfo = firstInfo.appendData('US');
            firstInfo = firstInfo.appendData(pageNumber);

            firstInfo = firstInfo.appendData(publicationDate.parenthesis());
            break;
    }

    firstInfo = firstInfo.appendData(url, ', ');
    firstInfo = firstInfo.addClosure();

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}


function getSeriesAbbreviation(series){
    let abbreviation = '';

    switch (series) {
        case 'second':
            abbreviation = '2d'
            break;
        case 'third':
            abbreviation = '3d'
            break;
        case 'fourth':
            abbreviation = '4th'
            break;
    }

    return abbreviation;
}