var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "loader-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShown,
            expression: "isShown",
          },
        ],
        staticClass: "loader-mask",
      },
      [
        _c(
          "div",
          {
            staticClass: "spinner-border text-primary",
            attrs: { role: "status" },
          },
          [
            _c("span", { staticClass: "visually-hidden" }, [
              _vm._v("Loading..."),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.$platformId === _vm.$enums.Platform.ONLINE
          ? _c(
              "router-link",
              {
                staticClass: "btn-logout-spinner btn btn-link btn-sm",
                attrs: {
                  to: {
                    name: "Logout",
                  },
                },
              },
              [_vm._v("\n\t\t\tLogout\n\t\t")]
            )
          : _vm.$platformId === _vm.$enums.Platform.ADD_IN ||
            _vm.$platformId === _vm.$enums.Platform.CHROME_EXT
          ? _c(
              "button",
              {
                staticClass: "btn-logout-spinner btn btn-link btn-sm",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fullLogout.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tLog Out\n\t\t")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }