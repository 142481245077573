const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

	let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
		authorPart: primaryContributor,
		authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
		authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.entryTitle);
	citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
	let primaryContributor;

    //define our citation logic for this type here
	let isGroupAuthorMatchBlogTitle = getGroupAuthorMatchBlogTitle(refData);
	
    let titleProperty = 'entryTitle';
    let isSecondaryTitle = true;

	if(isGroupAuthorMatchBlogTitle){
		//make a copy of refdata to pass off to the shared code
		let fakeRefData = new Object();

		if (refData.type == 'entry') {
			fakeRefData.entryTitle = refData.entryTitle;			
		}
		else{
			fakeRefData.entryTitle = refData.blogTitle;
			isSecondaryTitle = false;
		}

		fakeRefData.contributors = []; //contribs arent listed in the citation here
		primaryContributor = shared.getPrimaryContributorCitationString(fakeRefData, titleProperty, isSecondaryTitle);
	} else {
		primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
	}

	let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}



function getPrimaryInformation(refData){
	let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

	let isGroupAuthorMatchBlogTitle = getGroupAuthorMatchBlogTitle(refData);

	let title = shared.getStringValue(refData.entryTitle);

	if (title.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (title.startsWith('[')) {
			title = shared.removeDescriptionFromTitle(title);
		}
		else{
			title = title.replaceAmpersand();
			title = title.toTitleCase();
			title = title.doubleQuote();
		}
	}

	if(isGroupAuthorMatchBlogTitle){
		let entryTitle = shared.getStringValue(refData.entryTitle);
		if (entryTitle.length > 0) {
			entryTitle = entryTitle.replaceAmpersand();
			entryTitle = entryTitle.toTitleCase();
			entryTitle = entryTitle.doubleQuote();
			primaryPart = entryTitle;
		}
	} else {
		primaryPart = primaryContributor;
		
		if (title.length > 0) {
			if (primaryPart.length > 0) {
				primaryPart = primaryPart + ' ' +  title;
			} else{
				primaryPart = title;
			}
		}
	}

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    // blogTitle
	let blogTitle = shared.getStringValue(refData.blogTitle);

	if (blogTitle.length > 0) {
		//if this is a description, remove the brackets and don't italicize
		if (blogTitle.startsWith('[')) {
			blogTitle = shared.removeDescriptionFromTitle(blogTitle);
		} else{
			blogTitle = blogTitle.replaceAmpersand();
			blogTitle = blogTitle.toTitleCase();
			blogTitle = blogTitle.italicize();
		}
		container1 = container1.appendData(blogTitle, ', ');
	}

	// publicationDate
	let publicationDate = shared.getStringValue(refData.publicationDate);
	publicationDate = shared.getFullDate(publicationDate);
	container1 = container1.appendData(publicationDate, ', ');

	//url
	let url = shared.getStringValue(refData.url);
	if (url.length > 0) {
		url = shared.formatURL(url);
		container1 = container1.appendData(url, ', ');
	}


    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    
	// retrievalDate
	let retrievalDate = shared.getStringValue(refData.retrievalDate);
	retrievalDate = shared.getFullDate(retrievalDate);
	secondSupplemental = secondSupplemental.appendData(retrievalDate, ', ', 'Accessed');

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

	if (refData.type == 'blog') {
		secondSupplemental = secondSupplemental.appendData('Blog series.');
	}

    return secondSupplemental;
}


// reference and citation output is different if the group author and the blog title are the same
function getGroupAuthorMatchBlogTitle(refData){
	let primaryContributor = shared.getPrimaryContributorString(refData);
	
	let primaryContributorMinusLastCharacter = '';
	
	let blogTitle = '';

	// remove the last character (period)
	if(primaryContributor.length > 0){
		primaryContributorMinusLastCharacter = primaryContributor.substring(0, primaryContributor.length - 1);
		
		//blogTitle (to compare with primaryContributorMinusLastCharacter)
		blogTitle = shared.getStringValue(refData.blogTitle);

		if (blogTitle.length > 0) {
			if(primaryContributorMinusLastCharacter === blogTitle){
				return true;
			}
		}
	}
	
	return false;

}