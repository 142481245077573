const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}
let isAuthorAndBlogTitleEqual = false;

function getReference(refData) {
    isAuthorAndBlogTitleEqual = false;

    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);

    // If the authors and the blog name are the exact same, then omit the Group Author
    if(authorPart.endsWith('.')){
        let checkAuthorPart = authorPart.slice(0, -1);
        let blogTitle = shared.getStringValue(refData.blogTitle);
        if(checkAuthorPart === blogTitle){
            isAuthorAndBlogTitleEqual = true;
            authorPart = '';
        }
    }


    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);

    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    if(!type){
        type = 'entry';    // sets the default to entry if there isn't one
    }

    if(type === 'entry'){
        let entryTitle = shared.getStringValue(refData.entryTitle);

        // if this is a description, remove the brackets
        if (entryTitle.startsWith('[')) {
            entryTitle = shared.removeDescriptionFromTitle(entryTitle);
        } else{
            entryTitle = entryTitle.replaceAmpersand().toTitleCase().doubleQuote();
        }

        titlePart = titlePart.appendData(entryTitle);
        titlePart = titlePart.addClosure();
    }

    let blogTitle = shared.getStringValue(refData.blogTitle);
    blogTitle = blogTitle.replaceAmpersand().toTitleCase().italicize();
    
    titlePart = titlePart.appendData(blogTitle, ' ', '', '(blog)');
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    titlePart = titlePart.addClosure();

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    titlePart = titlePart.appendData(description);
    titlePart = titlePart.addClosure();

    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    seriesTitle = seriesTitle.replaceAmpersand().toTitleCase();
    titlePart = titlePart.appendData(seriesTitle);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false);
    
    if (publisherString !== 'N.p.') {
        publisherString = publisherString.italicize();
        publicationPart = publicationPart.appendData(publisherString);
    }
    
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);

    if(refData.type == 'entry'){
        publicationPart = publicationPart.appendData(publicationDate, ', ');
        publicationPart = publicationPart.upperFirstLetter();
    }
    publicationPart = publicationPart.addClosure();    

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);
    locationPart = locationPart.appendData(format).addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.entryTitle).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {
    isAuthorAndBlogTitleEqual = false;

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let entryTitle = '';

    let type = shared.getStringValue(refData.type);
    if(!type){
        type = 'entry';    // sets the default to entry if there isn't one
    }

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    
    
    let checkBlogTitle = shared.getStringValue(refData.blogTitle);
    
    // If the authors and the blog name are the exact same, then omit the Group Author
    if(authorPart === checkBlogTitle){
        isAuthorAndBlogTitleEqual = true;
        authorPart = '';
    }
    firstInfo = firstInfo.appendData(authorPart);

    if(type === 'entry'){
        entryTitle = shared.getStringValue(refData.entryTitle);

        if (entryTitle.startsWith('[')) {
            entryTitle = shared.removeDescriptionFromTitle(entryTitle);
        } else{
            entryTitle = entryTitle.replaceAmpersand().toTitleCase();
            entryTitle = entryTitle.doubleQuote();    
        }
       
        firstInfo = firstInfo.appendData(String(entryTitle), ', ', '', '');
    }

    let blogTitle = shared.getStringValue(refData.blogTitle);

    blogTitle = blogTitle.replaceAmpersand().toTitleCase().italicize();
    
    // blogTitle = blogTitle.toTitleCase();
    // if(!isAuthorAndBlogTitleEqual){
    //     blogTitle = blogTitle.italicize();
    // }
    
    // Test: 10 - APA 10.17 Example & Missing Publisher
    // Test Type: Turabian 9 First Citation
    // i need EXP to read [***Will?,"***] but instead it reads [***Will?"***]
    firstInfo = firstInfo.appendData(blogTitle, ', ', '', '(blog)', true);

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let description = shared.getStringValue(refData.description);
    description = shared.replaceWithAbbreviations(description);
    description = description.toLowerCase();
    firstInfo = firstInfo.appendData(description, ', ');

    let publisherPart = '';
   
    let publisher = shared.getPublisherString(refData.publishers, 'reference', true, false);

    if (publisher !== 'N.p.') {
        publisher = publisher.italicize();
        publisherPart = publisherPart.appendData(publisher);
    }


    let publicationDate = shared.getFullDate(refData.publicationDate);
    if(type === 'entry'){
        publisherPart = publisherPart.appendData(publicationDate, ', ');
    }

    firstInfo = firstInfo.appendData(publisherPart, ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    if(isAuthorAndBlogTitleEqual){
        subsequentAuthorPart = '';
    }
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    if(type === 'entry'){
        entryTitle = shared.getStringValue(refData.entryTitle);

         // if this is a description, remove the brackets
         if (entryTitle.startsWith('[')) {
            entryTitle = shared.removeDescriptionFromTitle(entryTitle).shortenName();
            entryTitle = entryTitle.addClosure();
        } else{
            entryTitle = entryTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
            
        }
    } else if(type === 'blog'){
        entryTitle = blogTitle;
        
    }

    subsequentInfo = subsequentInfo.appendData(entryTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}