const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    // let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    // referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: titlePart,
        authorPartNoLabel: String(titlePart),
        authorPartSort: String(titlePart),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let parties = shared.getStringValue(refData.parties);
    publicationPart = publicationPart.appendData(parties);
    publicationPart = publicationPart.addClosure();

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    
    if (publicationDate == 'n.d.') {
        publicationDate = 'N.d.';
    }
    
    publicationPart = publicationPart.appendData(publicationDate);
    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    //source
    let source = shared.getStringValue(refData.source);

    if (source.length > 0) {
        source = source.italicize();
        locationPart = locationPart.appendData(source);   
    }

    //location
    let location = shared.getStringValue(refData.location);
    locationPart = locationPart.appendData(location);
    locationPart = locationPart.addClosure();

    // doi/url
    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    // [title], [parties], [publicationDate], <em>[source]</em> [location], [citationLocation], [url].

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase();    
    }

    firstInfo = firstInfo.appendData(title, ", ");

    let parties = shared.getStringValue(refData.parties);
    firstInfo = firstInfo.appendData(parties, ', ');

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   
    firstInfo = firstInfo.appendData(publicationDate, ', ');

    //source
    let source = shared.getStringValue(refData.source);

    if (source.length > 0) {
        source = source.italicize();
        firstInfo = firstInfo.appendData(source, ', ');   
    }

    //location
    let location = shared.getStringValue(refData.location);
    firstInfo = firstInfo.appendData(location);

    // url
    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    
    //[title], [citationLocation].
    subsequentInfo = subsequentInfo.appendData(title, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}