/*
*****PersonalCommunication*****

- This type is not printed on the reference page, so whatever we spit out here is just for "decoration"
 
*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';

    let datePart = getDatePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        referenceValue = titlePart + ' ' + datePart ;  
    }
    else{

        if (titlePart.length > 0) {
            referenceValue = authorPart + ' ' + datePart + ' ' + titlePart;
        }
        else{
            referenceValue = authorPart + ' ' + datePart;
        }
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: false,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData) {
    //this is it's own beast
    //(A. A. Authora, personal communication, April 1, 2019)
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getReverseCitationAuthorString(authorArray);
    let formattedDate = shared.getReverseFullDate(refData.publicationDate);

    //check to see if they have excluded the name or date part
    if (citationData.namePart == 'false') {
        authorString = '';
    }

    if (citationData.datePart == 'false') {
        formattedDate = '';
    }
    else{
        //if there is no year, use n.d.
        if (formattedDate.length == 0) {
            formattedDate = 'n.d.';
        }
    }

    let firstCitation = '';

    if (authorString.length > 0) {
        firstCitation = authorString + ', ';
    }

    firstCitation = firstCitation + 'personal communication';

    if (formattedDate.length > 0) {
        firstCitation = firstCitation + ', ' + formattedDate;
    }

    let citation = {
        first : firstCitation,
        subsequent : ''
    }


    return citation;
}

function getTitlePart(refData){
    let titlePart = '';
    let title = 'Personal communication.';
    let authorArray = shared.getAuthors(refData.contributors);

    if (title.length > 0 && (authorArray.length != 0)) {
        titlePart = title;   
    }

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {
        //use our title instead
        let title = 'Personal communication.';

        if (title.length > 0) {
            authorPart = title;
        }
    }

    if (!authorPart.endsWith('.')) {
        authorPart = authorPart + '.';
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}