/*
- DateHelper
-- inpired from: https://stevenlevithan.com/assets/misc/date.format.js
-- 2023.09.20
*/

function format(dateObj, formatRequested){
	const regEx = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g;
	let pad = function (val, len) {
		val = String(val);
		len = len || 2;
		while (val.length < len) val = "0" + val;
			return val;
	};
		
	// Passing date through Date applies Date.parse, if necessary
	dateObj = dateObj ? new Date(dateObj) : new Date;
	
	// if (isNaN(dateObj)) throw SyntaxError("invalid date");
	// rollbar logs these and doesn't need to - to replicate select a full date then manually delete the month entry
	if(isNaN(dateObj)){
		return '';
	}

	let i18n = {
		dayNames: [
			"Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
			"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
		],
		monthNames: [
			"Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
			"January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
		]
	};

	let formats = {
		d:    dateObj.getDate(),
		dd:   pad(dateObj.getDate()),
		ddd:  i18n.dayNames[dateObj.getDay()],
		dddd: i18n.dayNames[dateObj.getDay() + 7],
		m:    dateObj.getMonth() + 1,
		mm:   pad(dateObj.getMonth() + 1),
		mmm:  i18n.monthNames[dateObj.getMonth()],
		mmmm: i18n.monthNames[dateObj.getMonth() + 12],
		yy:   String(dateObj.getFullYear()).slice(2),
		yyyy: dateObj.getFullYear()
	};

	return formatRequested.replace(regEx, function ($0) {
		return $0 in formats ? formats[$0] : $0.slice(1, $0.length - 1);
	});
}

module.exports = {
    format
}