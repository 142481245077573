/*
*****Treaties*****
 
FORM: https://docs.google.com/spreadsheets/d/1XGB83pKU9cpLa3majkj1G4ViGsBIZZjIEu0OMNxkzVg/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-international-conventions-treaties

Type & Kind Questions
*** There are no types/kinds

[title], [publicationDate], [url]

**If there isn't a URL, end with a period.

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    
    let title = shared.getStringValue(refData.title);
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let url = shared.getStringValue(refData.url);

    if (title.length > 0) {
        referenceValue = title;
    }

    if (publicationDate.length > 0) {
        if (title.length > 0) {
            referenceValue = referenceValue + ', ';
        }
        let formattedDate = shared.getReverseFullDate(publicationDate);

        referenceValue = referenceValue + formattedDate;
    }
    else{
        if (title.length > 0) {
            referenceValue = referenceValue + ', n.d.';
        }
    }

    if (url.length > 0) {
        url = shared.formatURL(url);
        if (referenceValue.length > 0) {
            referenceValue = referenceValue + ', ';
        }

        referenceValue = referenceValue + url;
    }
    else{
        if (referenceValue.length > 0) {
            if (!referenceValue.endsWith('.')) {
                referenceValue = referenceValue + '.';
            }
        }
    }


    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: '',
        datePart: ''
    }

    return reference;
}

function getCitation(refData, citationData) {
    let title = shared.getStringValue(refData.title);
    let year = shared.getYear(refData.publicationDate);

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    let citation = shared.getCitations(citationData, title, year, []);

    return citation;
}

//TODO: Create private methods for each part