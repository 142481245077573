/*
*****Advertisement*****
 
Documentation: TODO: Include a link to the Google Document for this type here

Type & Kind Questions
- TODO: Document the type and kinds for thi type

TODO: Document the parts and their fields here
AuthorPart
    - TODO: Fields for this part here

DatePart
    - TODO: Fields for this part here

TitlePart
    - TODO: Fields for this part here

SourcePart
    - TODO: Fields for this part here

*/

const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    switch (type) {
        case 'TYPE-NAME-HERE':
            break;
    }

    //TODO: Build our parts here to form our reference

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitation(refData, citationData) {
    //TODO; define our citation logic for this type here
    let firstCitation = '';
    let subsequentCitation = '';
    var hasSubsequent = false;

    let citation = {
        first: firstCitation,
        subsequent: subsequentCitation
    }

    return citation;
}

//TODO: Create private methods for each part