/*
*****Software*****
 
FORM: https://docs.google.com/spreadsheets/d/1ebv0eYdsP_tlOwzR1uvEkHZFyn2P_HAlsYWpiRB_l3I/edit#gid=2071428744
MOCKUP: https://magical-design-system.webflow.io/add-in/wizard/wizard-2-software-mobile-apps

TYPES
    computer: software run on a computer
        [contributors]. ([publicationDate]). <em>[title]</em> (Version [version]) [Computer software]. [publisher.reference]. <a href=\"[url]\">[url]</a>
    mobile: mobile app
        [contributors]. ([publicationDate]). <em>[title]</em> (Version [version]) [Mobile app]. [publisher.reference]. <a href=\"[url]\">[url]</a>
    reference: reference entry in a mobile app
        [contributors]. ([publicationDate]). [entry]. In <em>[title]</em> (Version [version]) [Mobile app]. [publisher.reference]. <a href=\"[url]\">[url]</a>

AuthorPart
    contributors
        - Normal authors & group Authors
    title
        - for computer or mobile, if there isn't a contributor
    entry
        - for reference if there is no contributors

DatePart
    publicationDate
        - YYYY

TitlePart
    entry
        - not italicized
    title
        - italicized
    version
        - follows title
        - has static text of "(Version " & ")"
    Static Text
        - each type ends the title part with "[Comptuer software]" or "[Mobile app]"
        - Don't include if [title] is a description

SourcePart
    publisher.reference
        - normal
    url
        - normal

*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let referenceValue = '';
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);
    let authorPart = getAuthorPart(refData);
    let titlePart = getTitlePart(refData);

    if (authorPart.length == 0) {
        //no contributors
        //referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;
        referenceValue = titlePart + ' ' + sourcePart;  
    }
    else{
        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{
            let title = shared.getStringValue(refData.title);
            let entry = shared.getStringValue(refData.entry);

            if (entry.length > 0) {
                if (entry.startsWith('[')) {
                    authorString = entry;
                }
                else{
                    authorString = '"' + entry + '"'; 
                }
            }
            else{
                title = shared.stripSubtitle(title);

                if (title.startsWith('[')) {
                    authorString = title;
                }
                else{
                    authorString = title.toTitleCase().italicize();   
                }
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }
    else{
        year = shared.fixPageDash(year);
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0) {
        if (!authorPart.endsWith('.')) {
            authorPart = authorPart + '.';
        }
    }

    return authorPart;
}

function getDatePart(refData){
    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    let titlePart = '';

    let type = shared.getStringValue(refData.type);
    let entry = shared.getStringValue(refData.entry);
    let title = shared.getStringValue(refData.title);
    let version = shared.getStringValue(refData.version);
    let label = '';
    let ignoreLabel = false;

    let authorPart = getAuthorPart(refData);

    if (entry.length > 0) {
        titlePart = entry;

        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    //if we don't have author, move date to title part
    if (authorPart.length == 0 && type == 'reference') {
        let datePart = getDatePart(refData);

        if (datePart.length > 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + ' ';
            }

            titlePart = titlePart + datePart;
        }
    }

    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' In ';
        }

        if (!title.startsWith('[')) {
            title = title.italicize();
        }
        else{
            ignoreLabel = true;
        }

        titlePart = titlePart + title;
    }

    //if we don't have author, move date to title part
    if (authorPart.length == 0 && type != 'reference') {
        let datePart = getDatePart(refData);

        if (datePart.length > 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + '. ';
            }

            titlePart = titlePart + datePart;
        }
    }

    if (version.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + '(Version ' + version + ')';
    }

    if (type == 'computer') {
        label = '[Computer software]';
    }

    if (type == 'mobile' || type == 'reference') {
        label = '[Mobile app]';
    }

    if (label.length > 0 && !ignoreLabel) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = titlePart + label;
    }

    if (titlePart.length > 0) {
        titlePart = shared.fixTitlePartClosure(titlePart);
    }

    return titlePart;
}

function getSourcePart(refData){
    let sourcePart = '';

    let url = shared.getStringValue(refData.url);
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }
    
    if (url.length > 0) {
        url = shared.formatURL(url);
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + url;
    }

    return sourcePart;
}