/*
*****Book*****
 
Primary Information

    First Supplemental

Container 1

Container 2

    Second Supplemental



Authora, Albert Alfred, et al. <em>My Search for Sasquatch</em>. 

Edited by Albert Alfred Editora et al., translated by Albert Alfred Translatora et al., illustrated by Albert Alfred Illustratora et al., narrated by Albert Alfred Narratora et al., Critical Edition, e-book ed., vol. 1, Crypto Press / Bigfoot Times / Nessie & Co., 2018, <a href=\"https://doi.org/10.1109/5.771073\">https://doi.org/10.1109/5.771073</a>.

*****CITATION INFO*****

Page = no abbreviation with citation
Paragraph = para. // paras.
Section = sec. // secs.
Table = tab. // tabs.
Figure = fig. // figs.

Use n-dash (option + -) to separate spans with no spaces on either side.

*/

const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    if (refData.type == 'bookChapter') {
        citationTitle = shared.getStringValue(refData.chapterTitle);
    }
    else{
        citationTitle = shared.getStringValue(refData.bookTitle);   
    }

    return citationTitle.replaceAmpersand();
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here

    let titleProperty = 'bookTitle';
    let isSecondaryTitle = false;

    let type = shared.getStringValue(refData.type);

    if (type == 'bookChapter') {
        titleProperty = 'chapterTitle';
        isSecondaryTitle = true;
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(refData, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = '';

    //title will not be used in this section if this is a book chapter
    if (type == 'bookChapter') {
        title = shared.getStringValue(refData.chapterTitle);
        
        if (title.length > 0) {
            title = title.toTitleCase();
            title = title.doubleQuote();
        }
    }
    else{
        title = shared.getStringValue(refData.bookTitle);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.toTitleCase();
                title = title.italicize();
            }
        }
    }

    if (title.length > 0) {

        title = title.replaceAmpersand();
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    //check translated title based off chapter or book
    if (type == 'bookChapter') {
        //Translated Chapter Title
        let translatedChapterTitle = shared.getStringValue(refData.translatedChapterTitle);

        if (translatedChapterTitle.length > 0) {
            translatedChapterTitle = translatedChapterTitle.replaceAmpersand();
            translatedChapterTitle = translatedChapterTitle.toTitleCase();
            translatedChapterTitle = translatedChapterTitle.doubleQuote();

            primaryPart = primaryPart.appendData('[' + translatedChapterTitle + ']', ' ', '', '');        
        }
    }
    else{
        //Translated Title
        let translatedTitle = shared.getStringValue(refData.translatedTitle);
        
        if (translatedTitle.length > 0) {
            translatedTitle = translatedTitle.replaceAmpersand();
            translatedTitle = translatedTitle.toTitleCase();
            translatedTitle = translatedTitle.italicize();

            primaryPart = primaryPart.appendData('[' + translatedTitle + ']', ' ', '', '');        
        }
    }

    // primaryPart = shared.fixClosure(primaryPart);
    primaryPart = primaryPart.addClosure();
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (kind != 'book' && kind != 'journal') {
        let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);
        firstSupplemental = firstSupplemental.appendData(originalPublicationDate, '');     
    }

    if (type == 'bookChapter') {
        //Add ChapterTranslators, but only of chapter translators are not the primary contributor already and have been used as such

        if (typeof refData.contributors === 'undefined') {
            return contributorstring;
        }

        if (refData.contributors.length > 0) {
            let primaryType = shared.getStringValue(refData.contributors[0].type);

            if (primaryType != 'chapterTranslator') {
                //chapterTranslator
                let supplementalContributors = shared.getFirstSupplementalContributorString(refData, 'chapterTranslator');
                firstSupplemental = firstSupplemental.appendData(supplementalContributors, ', ');
            }   
        }
    }
    
    let volumeClosure = false;

    if ((kind == 'multivolume' && type == 'book') || type == 'series') {
        //Volume Title
        let volumeTitle = shared.getStringValue(refData.volumeTitle);

        if (volumeTitle.length > 0) { 
            volumeTitle = volumeTitle.replaceAmpersand();
            volumeTitle = volumeTitle.toTitleCase();
            volumeTitle = volumeTitle.italicize();

            firstSupplemental = firstSupplemental.appendData(volumeTitle, '');
        }
        else{
            //put series title here if we have one, and not the volume
            let seriesTitle = shared.getStringValue(refData.seriesTitle);

            if (seriesTitle.length > 0) {
                seriesTitle = seriesTitle.replaceAmpersand();
                seriesTitle = seriesTitle.toTitleCase();
                seriesTitle = seriesTitle.italicize();

                firstSupplemental = firstSupplemental.appendData(seriesTitle, '');

                let volume = shared.getStringValue(refData.volume);
        
                if (volume.length > 0) {

                    if (refData.type != 'series') {
                        firstSupplemental = firstSupplemental.appendData(volume, '. ', 'Vol.');
                    }

                    if (type == 'series') {
                        firstSupplemental = firstSupplemental + '.';
                    }
                    else{
                        firstSupplemental = firstSupplemental + ',';
                    }

                    volumeClosure = true;
                }
            }
        }
    }

    firstSupplemental = firstSupplemental.upperFirstLetter();

    if (!volumeClosure) {
        firstSupplemental = shared.fixClosure(firstSupplemental);
    }

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    //if bookChapter, then title will be used in this container
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type == 'bookChapter') {
        title = shared.getStringValue(refData.bookTitle);

        if (title.length > 0) {
            //if this is a description, remove the brackets and don't italicize
            if (title.startsWith('[')) {
                title = shared.removeDescriptionFromTitle(title);
            }
            else{
                title = title.replaceAmpersand();
                title = title.toTitleCase();
                title = title.italicize();
            }

            container1 = container1.appendData(title, '');
        }

        //translatedTitle
        let translatedTitle = shared.getStringValue(refData.translatedTitle);

        if (translatedTitle.length > 0) {
            translatedTitle = translatedTitle.replaceAmpersand();
            translatedTitle = translatedTitle.toTitleCase();
            translatedTitle = translatedTitle.italicize();

            container1 = container1.appendData('[' + translatedTitle + ']', ' ', '', '');        
        }

        if (kind == 'multivolume') {
            let volumeTitle = shared.getStringValue(refData.volumeTitle);

            if (volumeTitle.length > 0) {
                volumeTitle = volumeTitle.replaceAmpersand();
                volumeTitle = volumeTitle.toTitleCase();
                volumeTitle = volumeTitle.italicize();

                container1 = container1.appendData(volumeTitle, '');
            }
        }
    }

    //Secondary Contribs
    let secondaryContributor = '';

    if (type == 'series' && kind == 'volume') {
        //we need to pull volume editors specifically for this one
        let volumeEditors = shared.getContributorsByType('volumeEditor', refData.contributors);
        secondaryContributor = shared.getSecondaryContributorTypeString(volumeEditors,'volumeEditor');
    }
    else{
        secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    }

    container1 = container1.appendData(secondaryContributor, ', ');
    
    //edition
    let edition = shared.getStringValue(refData.edition);
    edition = shared.fixEditionString(edition);
    container1 = container1.appendData(edition, ', ');

    //format
    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatString(format);

    //lowercase first letter of format if the container already has data
    if (container1.length > 0 && format.length > 0) {
        format = format.charAt(0).toLowerCase() + format.slice(1)
    }

    container1 = container1.appendData(format, ', ', '', '');

    //do not add volume here if there is a series title...it will go in second supplemenmtal
    let seriesTitle = shared.getStringValue(refData.seriesTitle);

    if (seriesTitle.length == 0 && kind != 'journal') {
        //volume
        let volume = shared.getStringValue(refData.volume);
        container1 = container1.appendData(volume, ', ', 'vol.', '');    
    }
    
    //Publishers
    let publisherString = shared.getPublisherString(refData.publishers);
    container1 = container1.appendData(publisherString, ', ');

    //pub date
    //journals will use this in supplemental
    if (kind != 'journal') {
        let year = shared.getYear(refData.publicationDate);
        container1 = container1.appendData(year, ', ');    
    }
    
    //referencePages
    let referencePages = shared.getStringValue(refData.referencePages);
    referencePages = shared.formatPageNumbers(referencePages);
    container1 = container1.appendData(referencePages, ', ');

    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi);
        container1 = container1.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    if (refData.type == 'series' && refData.kind == 'series') {
        let volume = shared.getStringValue(refData.volume);

        if (volume.length > 0) {
            container1 = container1.appendData(volume, '. ', '', 'vols.');
        }
    }

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //for translated titles, original publication is moved from first to second supplemental
    if (type == 'bookChapter') {
        let title = '';
        let prefix = 'Originally published in ';
        
        if (kind == 'journal') {
            prefix = 'Originally published as ';
            //articleTitle
            let articleTitle = shared.getStringValue(refData.articleTitle);

            if (articleTitle.length > 0) {
                articleTitle = articleTitle.replaceAmpersand();
                articleTitle = articleTitle.toTitleCase();
                articleTitle = articleTitle.doubleQuote();
                title = title.appendData(articleTitle, ', ');
            }

            //journalTitle
            let journalTitle = shared.getStringValue(refData.journalTitle);

            if (journalTitle.length > 0) {
                journalTitle = journalTitle.replaceAmpersand();
                journalTitle = journalTitle.toTitleCase();
                journalTitle = journalTitle.italicize();
                title = title.appendData(journalTitle, ', ');
            }

            //volume
            let volume = shared.getStringValue(refData.volume);
            title = title.appendData(volume, ', ', 'vol.', ''); 

            //issue
            let issue = shared.getStringValue(refData.issue);
            title = title.appendData(issue, ', ', 'no.', ''); 
        }
        else{
            let originalBookTitle = shared.getStringValue(refData.originalBookTitle);

            if (originalBookTitle.length > 0) {
                title = title.replaceAmpersand();
                title = originalBookTitle.toTitleCase();
                title = title.italicize();
            }
        }

        let originalEditors = shared.getContributorsByType('originalEditor', refData.contributors);
        let originalEditorString = '';

        if (originalEditors.length > 0) {
            originalEditorString = shared.getSecondaryContributorTypeString(originalEditors, 'originalEditor')
        }

        let originalPublisherString = shared.getPublisherString(refData.publishers, 'original');

        let originalPublicationDate = shared.getYear(refData.originalPublicationDate);
        
        let originalPages = shared.getStringValue(refData.originalPages);

        if (originalPages.length > 0) {
            originalPages = shared.formatPageNumbers(originalPages);
        }

        let originalDoi = shared.getStringValue(refData.originalDoi);
        let originalUrl = shared.getStringValue(refData.originalUrl);

        if (originalDoi.length > 0) {
            originalUrl = shared.appendDOIURL(originalDoi);
        }
        else{
            if (originalUrl.length > 0) {
                originalUrl = shared.formatURL(originalUrl);
            }
        }

        if (title.length > 0 ||
            originalEditorString.length > 0 ||
            originalPublisherString.length > 0 ||
            originalUrl.length > 0 ||
            originalPages.length > 0) {
           
            secondSupplemental = secondSupplemental.appendData(title, '');
            secondSupplemental = secondSupplemental.appendData(originalEditorString, ', ');
            secondSupplemental = secondSupplemental.appendData(originalPublisherString, ', ');
            secondSupplemental = secondSupplemental.appendData(originalPublicationDate, ', ');
            secondSupplemental = secondSupplemental.appendData(originalPages, ', ');
            secondSupplemental = secondSupplemental.appendData(originalUrl, ', ');

            secondSupplemental = prefix + secondSupplemental;
        }
    }

    //seriesTitle
    let seriesTitle = shared.getStringValue(refData.seriesTitle);
    let volumeTitle = shared.getStringValue(refData.volumeTitle);
    
    if (seriesTitle.length > 0 && volumeTitle.length > 0) {
        let volume = shared.getStringValue(refData.volume);
        
        if (volume.length > 0) {
            secondSupplemental = secondSupplemental.appendData(volume, '', 'Vol.', 'of');
        }

        seriesTitle = seriesTitle.replaceAmpersand();
        seriesTitle = seriesTitle.toTitleCase();
        seriesTitle = seriesTitle.italicize();

        secondSupplemental = secondSupplemental.appendData(seriesTitle, ' ', '', '');
    }

    if (type == 'series' && kind == 'volume') {
        let seriesEditors = shared.getContributorsByType('editor', refData.contributors);
        let seriesEditorString = shared.getSecondaryContributorTypeString(seriesEditors,'seriesEditor');

        if (seriesEditorString.length > 0) {

            if (seriesEditors.length == 1) {
                seriesEditorString = seriesEditorString + ', series editor';
            }
            else{
                seriesEditorString = seriesEditorString + ', series editors';
            }

            secondSupplemental = secondSupplemental.appendData(seriesEditorString, ', ', '', '');
        }
    }

    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}