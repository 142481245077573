const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);


    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'title';
    let isSecondaryTitle = false;

    //filter out our contribs to only custom 
    let customContribs = [];
    customContribs = shared.getContributorsByType('custom', refData.contributors);

    //make a copy of refdata to pass off to the shared code
    let fakeRefDate = new Object();
    fakeRefDate.title = refData.title;
    fakeRefDate.contributors = customContribs;

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);
    
    let primaryContributor = shared.getPrimaryContributorCitationString(fakeRefDate, titleProperty, isSecondaryTitle);
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryCustomContributorString(refData);

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = '';
    title = shared.getStringValue(refData.title);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.italicize();
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    //Translated Title
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        translatedTitle = translatedTitle.replaceAmpersand();
        translatedTitle = translatedTitle.toTitleCase();
        translatedTitle = translatedTitle.italicize();

        primaryPart = primaryPart.appendData('[' + translatedTitle + ']', ' ', '', '');        
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //TODO: add any first supplemental data needed

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //Secondary Contribs
    let secondaryContributor = getSecondaryContributorString(refData.contributors, 'directed');
    container1 = container1.appendData(secondaryContributor, ', ');

    //Publishers
    let publisherString = shared.getPublisherString(refData.publishers);
    container1 = container1.appendData(publisherString, ', ');

    //publicationDate
    let publicationDate = shared.getMonthYear(refData.publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    //platform
    let platform = shared.getStringValue(refData.platform);

    if (platform.length == 0) {
        //url
        let url = shared.getStringValue(refData.url);
        if (url.length > 0) {
            url = shared.formatURL(url);
            container1 = container1.appendData(url, ', ');
        }
    }

    //do not upper the the first letter if we start with a publisher
    if (publisherString == '') {
        container1 = container1.upperFirstLetter();
    }
    else{
        if (!container1.startsWith(publisherString)) {
            container1 = container1.upperFirstLetter();
        }
    }

    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //platform
    let platform = shared.getStringValue(refData.platform);
    
    if (platform.length > 0) {
        platform = platform.italicize();
        container2 = container2.appendData(platform, ', ');

        //url
        let url = shared.getStringValue(refData.url);
        if (url.length > 0) {
            url = shared.formatURL(url);
            container2 = container2.appendData(url, ', ');
        }
    }

    container2 = container2.upperFirstLetter();
    container2 = shared.fixClosure(container2);


    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let kind = shared.getStringValue(refData.kind);
    let type = shared.getStringValue(refData.type);

    //format
    let format = shared.getStringValue(refData.format);
    secondSupplemental = secondSupplemental.appendData(format, '');


    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);

    return secondSupplemental;
}

//this is so one off, we are just going to use this locally
function getSecondaryContributorString(contributors){

    let contributorstring = '';

    if (typeof contributors === 'undefined') {
        return contributorstring;
    }

    if (contributors.length > 0) {

        //for custom contribs, only grab authors\directors
        let secondaryContributors = shared.getContributorsByType('author', contributors);
        let directorContributors = shared.getContributorsByType('director', contributors);

        if (directorContributors.length > 0) {
            secondaryContributors = directorContributors;
        }

        if (secondaryContributors.length > 0) {
            
            contributorstring = shared.getSecondaryContributorTypeString(secondaryContributors, 'author');

            if (contributorstring.length > 0) {
                contributorstring = 'directed ' + contributorstring;
            }
        }

    }

    return contributorstring;
}