const shared = require('./Turabian9-Shared.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    // let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    // titlePart = titlePart.appendData(secondaryContributors);
    // titlePart = titlePart.addClosure();

    return titlePart;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);   

    let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, true);

    if(publicationDate === 'n.d.'){
        publicationPart = publicationPart.appendData(publisherString);

    } else {
        publicationPart = publicationPart.appendData(publicationDate, ', ', 'Recorded');
        publicationPart = publicationPart.addClosure();

        publicationPart = publicationPart.appendData(publisherString);
    }
    
    let copyrightYear = shared.getStringValue(refData.copyrightYear);
    if (copyrightYear.length === 0) {
        publicationPart = publicationPart.appendData('n.d.', ', ');
        
    } else {
        publicationPart = publicationPart.appendData(copyrightYear, ', ');
        publicationPart = publicationPart.addClosure();
    }

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // url
    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){
    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.workTitle);

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().italicize();
    }
    
    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");
    
    let publisherPart = '';
    let publicationDate = shared.getFullDate(refData.publicationDate);
    
    let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, true);

    if(publicationDate === 'n.d.'){
        publisherPart = publisherPart.appendData(publisherLocationString);

    } else {
        publisherPart = publisherPart.appendData(publisherLocationString);
        firstInfo = firstInfo.appendData(publicationDate, ', ', 'recorded');

    }

    let copyrightYear = shared.getStringValue(refData.copyrightYear);
    if (copyrightYear.length === 0) {
        publisherPart = publisherPart.appendData('n.d.', ', ');
    } else {
        publisherPart = publisherPart.appendData(copyrightYear, ', ');
    }

    firstInfo = firstInfo.appendData(publisherPart.parenthesis(), ', ');

    let format = shared.getStringValue(refData.format);
    format = shared.fixFormatStringForCitation(format);
    firstInfo = firstInfo.appendData(format, ', ');

    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let url = shared.getStringValue(refData.url);
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        firstInfo = firstInfo.appendData(url, ', ');
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle).shortenName();
    } else{
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().italicize();
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}