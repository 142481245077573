const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let authorPart = shared.getAuthorPart(refData);
    let titlePart = getTitlePart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(authorPart);
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(authorPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let workTitle = shared.getStringValue(refData.title);

    // if this is a description, remove the brackets
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
    }

    titlePart = titlePart.appendData(workTitle);
    titlePart = titlePart.addClosure();

    let secondaryContributors = shared.getSecondaryContributorString(refData).addClosure();
    titlePart = titlePart.appendData(secondaryContributors);
    
    titlePart = titlePart.addClosure();

    return titlePart;
}


function getLocationPart(refData){
    let locationPart = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if(type === 'conference') {
        let description = shared.getStringValue(refData.description);
        description = shared.replaceWithAbbreviations(description);
        locationPart = locationPart.appendData(description);

        let conference = shared.getStringValue(refData.conference);
        locationPart = locationPart.appendData(conference, ', ');

    } else if(type === 'symposium') {

        let chairs = getChairs(refData.contributors);
        let hasNonOtherContribs = hasNonChairContributors(refData.contributors);

        if (chairs.length > 0 && hasNonOtherContribs) {
            let chairsString = shared.getSecondaryContributorTypeString(chairs, 'chair');
            chairsString = chairsString.addClosure();
            locationPart = locationPart.appendData(chairsString, ', ');
        }

        let symposiumTitle = shared.getStringValue(refData.symposiumTitle);
        symposiumTitle = symposiumTitle.replaceAmpersand().toTitleCase();

        if (chairs.length > 0) {
            locationPart = locationPart.appendData(symposiumTitle);
        }
        else{
            locationPart = locationPart.appendData(symposiumTitle, ', ');
        }

        locationPart = locationPart.addClosure();

        let conference = shared.getStringValue(refData.conference);
        locationPart = locationPart.appendData(conference, ' ', 'Symposium at');

    }

    let city = shared.getStringValue(refData.city);
    locationPart = locationPart.appendData(city, ', ');

    let state = shared.getStringValue(refData.state);
    locationPart = locationPart.appendData(state, ', ');
    
    let country = shared.getStringValue(refData.country);
    if (
        country.length > 0 &&
        (country !== 'US' && country !== 'USA' && country !== 'United States' && country !== 'United States of America')
    ) {
        locationPart = locationPart.appendData(country, ', ');
    }

    let formattedDate = getConferenceDateRange(refData);
    locationPart = locationPart.appendData(formattedDate, ', ');
    
    locationPart = locationPart.addClosure();


    // doi/url
    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        locationPart = locationPart.appendData(doi);
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            locationPart = locationPart.appendData(url);
        }
    }

    return locationPart;
}


function getConferenceDateRange(refData){
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let endDate = shared.getStringValue(refData.endDate);
    let formattedDate = '';

    if (endDate.length > 0) {
        formattedDate = shared.getFullDateRange(publicationDate, endDate);
    }
    else{
        formattedDate = shared.getFullDate(publicationDate);
    }

    return formattedDate;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'articleTitle';
    let firstInfo = '';
    let subsequentInfo = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    let authorPart = shared.getCitationAuthorPart(refData, titleProperty);
    firstInfo = firstInfo.appendData(authorPart);

    let workTitle = shared.getStringValue(refData.title);

    // if this is a description, remove the brackets
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
    } else{
        workTitle = workTitle.replaceAmpersand().toTitleCase().doubleQuote();
    }
    
    firstInfo = firstInfo.appendData(workTitle, ", ");

    let secondaryContributors = shared.getSecondaryContributorCitationString(refData);
    firstInfo = firstInfo.appendData(secondaryContributors, ", ");

    let parenthesisPart = '';

    if(type === 'conference') {
        let description = shared.getStringValue(refData.description);
        description = shared.replaceWithAbbreviations(description);
        description = description.toLowerCase();
        parenthesisPart = parenthesisPart.appendData(description, ', ');
        
        let conference = shared.getStringValue(refData.conference);
        parenthesisPart = parenthesisPart.appendData(conference, ', ');

    } else if(type === 'symposium') {

        let chairs = getChairs(refData.contributors);
        let hasNonOtherContribs = hasNonChairContributors(refData.contributors);

        if (chairs.length > 0 && hasNonOtherContribs) {
            let chairsString = shared.getSecondaryContributorTypeString(chairs, 'chair');

            if (chairs.length == 1) {
                chairsString = chairsString.replace("Chaired by", 'chair,');
            }
            else{
                chairsString = chairsString.replace("Chaired by", 'chairs,');
            }

            firstInfo = firstInfo.appendData(chairsString, ', ');
        }

        let symposiumTitle = shared.getStringValue(refData.symposiumTitle);
        symposiumTitle = symposiumTitle.replaceAmpersand().toTitleCase();
        firstInfo = firstInfo.appendData(symposiumTitle, ', ');

        let conference = shared.getStringValue(refData.conference);
        parenthesisPart = parenthesisPart.appendData(conference, ' ', 'symposium at');

    }
    
    let city = shared.getStringValue(refData.city);
    parenthesisPart = parenthesisPart.appendData(city, ', ');

    let state = shared.getStringValue(refData.state);
    parenthesisPart = parenthesisPart.appendData(state, ', ');

    let country = shared.getStringValue(refData.country);
    if (
        country.length > 0 &&
        (country !== 'US' && country !== 'USA' && country !== 'United States' && country !== 'United States of America')
    ) {
        parenthesisPart = parenthesisPart.appendData(country, ', ');
    }

    let formattedDate = getConferenceDateRange(refData);
    parenthesisPart = parenthesisPart.appendData(formattedDate, ', ');
    parenthesisPart = parenthesisPart.parenthesis();
    firstInfo = firstInfo.appendData(parenthesisPart, ', ');
    
    let citationLocation = shared.getCitationLocationString(citationData, false);
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    let articleNumber = shared.getStringValue(refData.articleNumber);
    firstInfo = firstInfo.appendData(articleNumber, ', ');

    if (firstInfo.endsWith(':')) {
        firstInfo = firstInfo.removeLastCharacter();
    }

    let url = shared.getStringValue(refData.url);
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {
        doi = shared.appendDOIURL(doi).addClosure();
        firstInfo= firstInfo.appendData(doi, ', ');
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url).addClosure();
            firstInfo = firstInfo.appendData(url, ', ');
        }
    }

    //Authora et al., \"Title,\" 4.
    //Contributors, "articleTitle", citationLocation
    let subsequentAuthorPart = shared.getCitationAuthorPart(refData, titleProperty, true);
    subsequentInfo = subsequentInfo.appendData(subsequentAuthorPart);

    workTitle = shared.getStringValue(refData.title);

    // if this is a description, remove the brackets
    if (workTitle.startsWith('[')) {
        workTitle = shared.removeDescriptionFromTitle(workTitle);
        workTitle = workTitle.shortenName();
    } else{
        workTitle = workTitle.replaceAmpersand().shortenName().toTitleCase().doubleQuote();
    }

    subsequentInfo = subsequentInfo.appendData(workTitle, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}

function getChairs(contributors){
    let editorArray = [];

    contributors = shared.removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type == 'chair') {
            editorArray.push(item);
        }
    });

    return editorArray;
}

function hasNonChairContributors(contributors){
    let has = false;

    contributors = shared.removeEmptyContributors(contributors);

    _forEach(contributors, (item) => {
        if (item.type !== 'chair') {
            has = true;
        }
    });

    return has;
}