//BREAKDOWN NOTES
/*
Type Question
Kind Quesstion

-Type: (entry) Entry in a Reference Work
    - Kind: (encyclopeddia) Entry in an encyclopedia
    - Kind: (dictionary) Entry in a dictionary or thesaurus
[AuthorPart][DatePart][EntryTitlePart][EntrySourcePart]
    - Kind: (wikipedia) Entry in Wikipedia
[EntryTitlePart] [DatePart] [WikiSourcePart]

- Type: (whole) Whole Reference Work
    - Kind: (encyclopedia) Encyclopedia
    - Kind: (dictionary) Dictionary or thesaurus
[AuthorPart] [DatePart] [TitlePart] [SourcePart]


- AuthorPart
    - Authors, Group Authors, Usernames, Editors without Authors
- DatePart
    - Either a year, year-month-day, or n.d.
- EntryTitlePart
    - Entry title
- EntrySourcePart
    - "In ", Editor, Reference Work Title, Publisher, Retrieval Date, URL/DOI
- WikiSourcePart
    - "In <em>Wikipedia</em>. ", Retrieval Date, Archived URL
    
- TitlePart
    - Reference Work Title
- SourcePart
    - Retrieval Date, URL/DOI
*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let datePart = getDatePart(refData);
    let authorPart = getAuthorPart(refData);

    switch (type) {
        case 'entry':

                let entryTitlePart = getEntryTitlePart(refData);

                switch (kind) {
                    case 'encyclopedia':
                    case 'dictionary':
                        // [AuthorPart][DatePart][EntryTitlePart][EntrySourcePart]
                        let entrySourcePart = getEntrySourcePart(refData);

                        if (authorPart.length == 0) {
                            //no contributors
                            referenceValue = entryTitlePart + ' ' + datePart + ' ' + entrySourcePart;  
                        }
                        else{
                            referenceValue = authorPart + ' ' + datePart + ' ' + entryTitlePart + ' ' + entrySourcePart;
                        }

                        break;
                    case 'wikipedia':
                        // [EntryTitlePart] [DatePart] [WikiSourcePart]
                        let wikiSourcePart = getWikiSourcePart(refData);

                        if (authorPart.length == 0) {
                            //no contributors
                            referenceValue = entryTitlePart + ' ' + datePart + ' ' + wikiSourcePart;  
                        }
                        else{
                            referenceValue = authorPart + ' ' + datePart + ' ' + entryTitlePart + ' ' + wikiSourcePart;
                        }

                        break;
                }

            break;
        case 'whole':
            //[AuthorPart] [DatePart] [TitlePart] [SourcePart]
            let titlePart = getTitlePart(refData);
            let sourcePart = getSourcePart(refData);

            if (authorPart.length == 0) {
                //no contributors
                referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart;  
            }
            else{
                referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
            }

            break;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {
    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);
    
    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        }
        else{

            let entryTitle = shared.getStringValue(refData.entryTitle);
            let referenceTitle = shared.getStringValue(refData.referenceTitle);

            if (refData.type == 'entry') {
                authorString = '"' + entryTitle.toTitleCase() + '," ';    
            }
            else{
                authorString = referenceTitle.toTitleCase().italicize();
            }
        }
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);
    
    if (publicationDateOriginal.length == 0 && (refData.type == 'reprinted')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/'+ year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getDatePart(refData) {

    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData){
    // - Title of Book, Translated Title, Editors, Translators, Edition
    let titlePart = '';

    let title = shared.getStringValue(refData.referenceTitle);
    
    if (title.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ' ';
        }

        titlePart = title.italicize();    
    }

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //edition
    let edition = shared.getStringValue(refData.edition);

    let authorArray = shared.getAuthors(refData.contributors);

    //build our secondary contributors portion
    if ((editorString.length > 0 && authorArray.length > 0) ||
        edition.length > 0) {
        
        var isFirstNames = true;

        titlePart = titlePart + ' (';

        if (editorString.length > 0 && authorArray.length > 0) {
            titlePart = titlePart + editorString;
            isFirstNames = false;
        }

        if ((edition.length > 0) && !isFirstNames) {
            titlePart = titlePart + ';';
        }

        if (edition.length > 0) {
            if (!isFirstNames) {
                titlePart = titlePart + ' ';
                isFirstNames = false;
            }

            edition = shared.fixEditionString(edition);

            titlePart = titlePart + edition;
        }

        titlePart = titlePart + ')';
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getAuthorPart(refData){
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, false);

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors, unless this is an entry
        if (editorArray.length > 0 && refData.type != 'entry') {
            authorPart  = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            }
            else{
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getSourcePart(refData){
    let sourcePart = '';

    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let publisherString = shared.getPublisherString(refData.publishers);
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray, true);

    if (publisherString != '.') {
        if (publisherString != authorString && (publisherString != authorString + '.')) {
            sourcePart = publisherString;    
        }        
    }

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);    
        sourcePart = sourcePart + ', from' ;
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}

function getEntryTitlePart(refData){
    let entryTitle = shared.getStringValue(refData.entryTitle);
    let titlePart = '';

    if (entryTitle.length > 0) {
        titlePart = entryTitle;

        titlePart = shared.fixTitlePartClosure(titlePart);  
    }
    
    return titlePart;
}

function getEntrySourcePart(refData){
    let entrySourcePart = 'In';

    let title = shared.getStringValue(refData.referenceTitle);
    let edition = shared.getStringValue(refData.edition);
    let normalSourcePart = getSourcePart(refData);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ' (Ed.)';
    }
    else{
        if (editorArray.length > 1) {
            editorString = editorString + ' (Eds.)';
        }
    }

    if (editorString.length > 0) {
        entrySourcePart = entrySourcePart + ' ' + editorString + ',';
    }

    if (title.length > 0) {
        entrySourcePart = entrySourcePart + ' ' + title.italicize();
    }

    if (edition.length > 0) {
        entrySourcePart = entrySourcePart + ' (';

        edition = shared.fixEditionString(edition);

        entrySourcePart = entrySourcePart + edition;
        entrySourcePart = entrySourcePart + ')'
    }

    if (entrySourcePart == 'In') {
        entrySourcePart = normalSourcePart;
    }
    else{
        entrySourcePart = entrySourcePart + '.'

        //append our normal source part on here
        entrySourcePart = entrySourcePart + ' ' + normalSourcePart;
    }

    return entrySourcePart;
}

function getWikiSourcePart(refData){
    //"In <em>Wikipedia</em>. ", Retrieval Date, Archived URL
    let sourcePart = 'In <em>Wikipedia</em>.';

    let retrievalDate = shared.getStringValue(refData.retrievalDate);

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);    
        sourcePart = sourcePart + ', from' ;
    }

    //url
    let url = shared.getStringValue(refData.url);

    //doi
    let doi = shared.getStringValue(refData.doi);

    if (doi.length > 0) {

        doi = shared.appendDOIURL(doi);

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + doi;
    }
    else{
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }
    
            sourcePart = sourcePart + url;
        }
    }

    return sourcePart;
}