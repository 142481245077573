const shared = require('./Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');
const _forEach = require('lodash/forEach');
const { first } = require('lodash');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
 
    let referenceValue = '';
    
    let titlePart = getTitlePart(refData);
    let contribPart = getCustomContributorString(refData);
    let publicationPart = getPublicationPart(refData);
    let locationPart = getLocationPart(refData);
    
    referenceValue = referenceValue.appendData(titlePart);
    referenceValue = referenceValue.appendData(contribPart);
    referenceValue = referenceValue.appendData(publicationPart);
    referenceValue = referenceValue.appendData(locationPart);

    let citationTitle = getCitationTitle(refData);
    let citationShortTitle = citationTitle.shortenName();

    let reference = {
        authorPart: String(contribPart),
        authorPartNoLabel: String(shared.getAuthorPart(refData, true, true)),
        authorPartSort: String(shared.getAuthorPartSort(refData)),
        citationTitle: getCitationTitle(refData),
        citationShortTitle: citationShortTitle,
        citationDefaultShortTitle: citationShortTitle,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getTitlePart(refData){
    let titlePart = '';

    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = shared.removeDescriptionFromTitle(title);
    } else{
        title = title.replaceAmpersand().toTitleCase().italicize();    
    }

    titlePart = titlePart.appendData(title);
    titlePart = titlePart.addClosure();

    if (refData.type == 'episode') {
        // Season [seasonNumber], episode [episodeNumber], \”[episodeTitle].\”
        let seasonNumber = shared.getStringValue(refData.seasonNumber);

        if (seasonNumber.length > 0) {
            titlePart = titlePart.appendData('Season');
            titlePart = titlePart.appendData(seasonNumber);
        }

        let episodeNumber = shared.getStringValue(refData.episodeNumber);

        if (episodeNumber.length > 0) {
            if (seasonNumber.length > 0) {
                titlePart = titlePart.appendData('episode', ', ');
            }
            else{
                titlePart = titlePart.appendData('Episode');
            }

            titlePart = titlePart.appendData(episodeNumber);
        }
        
        let episodeTitle = shared.getStringValue(refData.episodeTitle);

        if (episodeTitle.length > 0) {
            
            if (episodeTitle.startsWith('[')) {
                episodeTitle = shared.removeDescriptionFromTitle(episodeTitle);
            } else{
                episodeTitle = episodeTitle.replaceAmpersand().toTitleCase().doubleQuote();    
            }

            if (titlePart.endsWith('.')) {
                titlePart = titlePart.appendData(episodeTitle);
            }
            else{
                titlePart = titlePart.appendData(episodeTitle, ', ');
            }
        }
    }

    titlePart = titlePart.addClosure();

    return titlePart;
}

function getCustomContributorString(refData, isCitation){
    let contributorstring = '';

    if (typeof refData.contributors === 'undefined') {
        return contributorstring;
    }

    if (typeof isCitation === 'undefined') {
        isCitation = false;
    }

    let seperator = '. ';

    if (isCitation) {
        seperator = ', '
    }

    //https://docs.google.com/document/d/1SIlxeWd5XPEWXmxMiDaw5RVh-DfwzI7T82rD3m5tjL8/edit#heading=h.dsb2n2r5yilc
    /*
    The order of contributors is:
        Executive Producer & Producer: 	Produced by…		
        Executive Director & Director	Directed by…			
        Director & Writer			Directed and written by…	
        Writer					Written by…			
        Custom				Role, …			Role
    */
    
    let contributors = refData.contributors;

    contributors = shared.removeEmptyContributors(contributors);

    if (contributors.length > 0) {

        let producerContribs = [];
        let directorContribs = [];
        let writerContribs = [];
        let writerDirectorContribs = [];
        let directorProducerContribs = [];
        let customContribs = [];

        let useCombinedWriterDirector = false;
        let useCombinedDirectorProducer = false;

        let executiveDirectorArray = shared.getContributorsByType('executiveDirector', refData.contributors);
        let executiveProducerArray = shared.getContributorsByType('executiveProducer', refData.contributors);
        let directorArray = shared.getContributorsByType('director', refData.contributors);
        let writerDirectorArray = shared.getContributorsByType('writerDirector', refData.contributors);
        let writerArray = shared.getContributorsByType('writer', refData.contributors);
        let executiveDirectorProducerArray = shared.getContributorsByType('executiveDirectorProducer', refData.contributors);

        //this is weird one - this while type is
        //Only use writerDirector if there are no other directors, executievDirectors, or writers
        if (directorArray.length == 0 && executiveDirectorArray.length == 0 && writerArray.length == 0 && writerDirectorArray.length > 0) {
            useCombinedWriterDirector = true;
        }

        //Only use executiveDirectorProducer if there are no other executiveDirector, executiveProducer, director
        if (directorArray.length == 0 && executiveDirectorArray.length == 0 && executiveProducerArray.length == 0 && executiveDirectorProducerArray.length > 0) {
            useCombinedDirectorProducer = true;
        }

        _forEach(contributors, (item) => {
            switch (item.type) {
                case 'executiveDirector':
                    directorContribs.push(item);
                    break;
                case 'executiveProducer':
                    producerContribs.push(item);
                    break;
                case 'director':
                    directorContribs.push(item);
                    break;
                case 'writerDirector':

                    if (useCombinedWriterDirector) {
                        writerDirectorContribs.push(item);
                    }
                    else{
                        directorContribs.push(item);
                        writerContribs.push(item);
                    }
                    break;
                case 'writer':
                    writerContribs.push(item);
                    break;
                case 'executiveDirectorProducer':

                    if (useCombinedDirectorProducer) {
                        directorProducerContribs.push(item);
                    }
                    else{
                        directorContribs.push(item);
                        producerContribs.push(item);
                    }
                    break;
                case 'custom':
                    customContribs.push(item);
                    break;
            }
        });

        if (producerContribs.length > 0) {
            let producerString = '';
            
            if (isCitation) {
                producerString = shared.getSecondaryContributorCitationTypeString(producerContribs, 'producer', true);
            }
            else{
                producerString = shared.getSecondaryContributorTypeString(producerContribs, 'producer');
            }

            if (isCitation) {
                producerString = producerString.lowerFirstLetter();
            }

            contributorstring = contributorstring.appendData(producerString, seperator);
        }

        if (directorContribs.length > 0) {
            let directorString = '';
            
            if (isCitation) {
                directorString = shared.getSecondaryContributorCitationTypeString(directorContribs, 'director', true);
            }
            else{
                directorString = shared.getSecondaryContributorTypeString(directorContribs, 'director');
            }

            if (isCitation) {
                directorString = directorString.lowerFirstLetter();
            }

            contributorstring = contributorstring.appendData(directorString, seperator);
        }

        if (directorProducerContribs.length > 0) {
            let directorProducerString = '';
            
            if (isCitation) {
                directorProducerString = shared.getSecondaryContributorCitationTypeString(directorProducerContribs, 'executiveDirectorProducer', true);
            }
            else{
                directorProducerString = shared.getSecondaryContributorTypeString(directorProducerContribs, 'executiveDirectorProducer');
            }

            if (isCitation) {
                directorProducerString = directorProducerString.replace('Produced and Directed', 'produced and directed');
            }

            contributorstring = contributorstring.appendData(directorProducerString, seperator);
        }

        if (writerContribs.length > 0) {
            let writerString = '';
            
            if (isCitation) {
                writerString = shared.getSecondaryContributorCitationTypeString(writerContribs, 'writer', true);
            }
            else{
                writerString = shared.getSecondaryContributorTypeString(writerContribs, 'writer');
            }

            if (isCitation) {
                writerString = writerString.lowerFirstLetter();
            }

            contributorstring = contributorstring.appendData(writerString, seperator);
        }

        if (writerDirectorContribs.length > 0) {
            let writerDirectorString = '';
            
            if (isCitation) {
                writerDirectorString = shared.getSecondaryContributorCitationTypeString(writerDirectorContribs, 'writerDirector', true);
            }
            else{
                writerDirectorString = shared.getSecondaryContributorTypeString(writerDirectorContribs, 'writerDirector');
            }

            if (isCitation) {
                writerDirectorString = writerDirectorString.replace('Directed and Written', 'directed and written');
            }

            contributorstring = contributorstring.appendData(writerDirectorString, seperator);
        }

        if (customContribs.length > 0) {
            let customContribString = '';
            
            if (isCitation) {
                customContribString = shared.getSecondaryContributorCitationTypeString(customContribs, 'custom', true);
            }
            else{
                customContribString = shared.getSecondaryContributorTypeString(customContribs, 'custom', true);
            }

            if (isCitation) {
                customContribString = customContribString.lowerFirstLetter();
            }

            contributorstring = contributorstring.appendData(customContribString, seperator);
        }
        
    }

    if (!isCitation) {
        contributorstring = contributorstring.addClosure();
    }

    return contributorstring;
}

function getPublicationPart(refData){
    let publicationPart = '';

    let channel = shared.getStringValue(refData.channel);
    let publicationDate = shared.getStringValue(refData.publicationDate);

    if (refData.type == 'series') {
        //On [channel]. [publishers]. [publicationDate].
        if (channel.length > 0) {
            publicationPart = publicationPart.appendData('On');
            publicationPart = publicationPart.appendData(channel).addClosure();
        }

        let publisherString = shared.getPublisherString(refData.publishers, 'reference', false, false, false);
        publicationPart = publicationPart.appendData(publisherString);
        publicationPart = publicationPart.addClosure();

        publicationDate = shared.getFullDate(publicationDate);
        
        if (refData.type == 'series') {
            publicationDate = publicationDate.replace(' - ', '–');
            publicationDate = publicationDate.replace(' – ', '–');

            publicationDate = publicationDate.replace('- ', '–');
            publicationDate = publicationDate.replace('– ', '–');
        }

        if (publicationPart.length == 0 && publicationDate == 'n.d.') {
            publicationDate = 'N.d.';
        }
        
        publicationPart = publicationPart.appendData(publicationDate);
    }

    if (refData.type == 'episode') {
        //Aired [publicationDate], on [channel].
        publicationDate = shared.getFullDate(publicationDate);
        
        if (publicationDate.length > 0 || channel.length > 0) {
            publicationPart = publicationPart.appendData('Aired');

            publicationPart = publicationPart.appendData(publicationDate);

            if (channel.length > 0) {

                if (publicationDate.length > 0) {
                    publicationPart = publicationPart.appendData('on', ', ');
                }
                else{
                    publicationPart = publicationPart.appendData('on');
                }

                publicationPart = publicationPart.appendData(channel).addClosure();
            }
        }
    }
    
    publicationPart = publicationPart.addClosure();

    return publicationPart;
}

function getLocationPart(refData){
    let locationPart = '';

    // url
    let url = shared.getStringValue(refData.url);
    
    if (url.length > 0) {
        url = shared.formatURL(url).addClosure();
        locationPart = locationPart.appendData(url);
    }

    return locationPart;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.title).replaceAmpersand();

    if (citationTitle.startsWith('[')) {
        citationTitle = shared.removeDescriptionFromTitle(citationTitle);
    }

    return citationTitle;
}

function getCitation(refData, citationData) {

    let titleProperty = 'title';
    let firstInfo = '';
    let subsequentInfo = '';

    // Series
    // <em>[title]</em>, [contributors], on [channel] ([publishers], [publicationDate]), [location], [url].
    
    // Episode
    // <em>[title]</em>, season [seasonNumber], episode [episodeNumber], \”[episodeTitle],\” [contributors], aired [publicationDate], on [channel], [location], [url].
    let title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = String(shared.removeDescriptionFromTitle(title));
    } else{
        title = title.replaceAmpersand().toTitleCase().italicize();    
    }

    firstInfo = firstInfo.appendData(title, ", ");

    let publicationDate = shared.getStringValue(refData.publicationDate);
    let channel = shared.getStringValue(refData.channel);

    if (refData.type == 'series') {
        let contribPart = getCustomContributorString(refData, true);

        if (title.length > 0) {
            contribPart = contribPart.lowerFirstLetter();
        }

        firstInfo = firstInfo.appendData(contribPart, ", ");

        if (channel.length > 0) {
            firstInfo = firstInfo.appendData('on', ', ');
            firstInfo = firstInfo.appendData(channel)
        }

        let publisherLocationString = shared.getPublisherString(refData.publishers, 'reference', true, false, false);
        publicationDate = shared.getFullDate(publicationDate);
        publicationDate = publicationDate.replace(' - ', '–');
        publicationDate = publicationDate.replace(' – ', '–');

        publicationDate = publicationDate.replace('- ', '–');
        publicationDate = publicationDate.replace('– ', '–');

        let publisherPart = '';

        if (shared.isSelfPublished(refData)) {
            publisherPart = publisherPart.appendData('self-pub.');
        }
        else{
            publisherPart = publisherPart.appendData(publisherLocationString);
        }

        publisherPart = publisherPart.appendData(publicationDate, ', ');

        publisherPart = publisherPart.parenthesis();
        firstInfo = firstInfo.appendData(publisherPart, ', ');
    }

    if (refData.type == 'episode') {
        let seasonNumber = shared.getStringValue(refData.seasonNumber);

        if (seasonNumber.length > 0) {
            firstInfo = firstInfo.appendData('season', ', ');
            firstInfo = firstInfo.appendData(seasonNumber);
        }

        let episodeNumber = shared.getStringValue(refData.episodeNumber);

        if (episodeNumber.length > 0) {
            firstInfo = firstInfo.appendData('episode', ', ');
            firstInfo = firstInfo.appendData(episodeNumber);
        }
        
        let episodeTitle = shared.getStringValue(refData.episodeTitle);

        if (episodeTitle.length > 0) {
            
            if (episodeTitle.startsWith('[')) {
                episodeTitle = shared.removeDescriptionFromTitle(episodeTitle).toLowerCase();
            } else{
                episodeTitle = episodeTitle.replaceAmpersand().toTitleCase().doubleQuote();    
            }

            firstInfo = firstInfo.appendData(episodeTitle, ', ');
        }

        let contribPart = getCustomContributorString(refData, true);

        if (title.length > 0) {
            contribPart = contribPart.lowerFirstLetter();
        }

        firstInfo = firstInfo.appendData(contribPart, ", ");

        publicationDate = shared.getFullDate(publicationDate);
        if (publicationDate.length > 0 || channel.length > 0) {
            firstInfo = firstInfo.appendData('aired', ", ");

            firstInfo = firstInfo.appendData(publicationDate);

            if (channel.length > 0) {

                if (publicationDate.length > 0) {
                    firstInfo = firstInfo.appendData('on', ', ');
                }
                else{
                    firstInfo = firstInfo.appendData('on');
                }

                firstInfo = firstInfo.appendData(channel);
            }
        }
    }

    

    let citationLocation = shared.getCitationLocationString(citationData, false);
    //remove leading 00s
    citationLocation = citationLocation.replace('00:0', '');
    citationLocation = citationLocation.replace('00:', '');
    firstInfo = firstInfo.appendData(citationLocation, ', ');

    title = shared.getStringValue(refData.title);

    if (title.startsWith('[')) {
        title = String(shared.removeDescriptionFromTitle(title).shortenName());
    } else{
        title = title.replaceAmpersand().shortenName().toTitleCase().italicize();    
    }
    
    subsequentInfo = subsequentInfo.appendData(title, ', ');
    subsequentInfo = subsequentInfo.appendData(citationLocation, ', ');

    let citation = {
        first : firstInfo.addClosure().toString(),
        subsequent : subsequentInfo.addClosure().toString()
    }

    return citation;
}