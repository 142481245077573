const shared = require('./MLA9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);
    let containerOne = getContainerOne(refData);
    let containerTwo = getContainerTwo(refData);
    let secondSupplemental = getSecondSupplemental(refData);

    let referenceValue = primaryInfo;

    if (firstSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + firstSupplemental;
    }

    if (containerOne.length > 0) {
        referenceValue = referenceValue + ' ' + containerOne;
    }

    if (containerTwo.length > 0) {
        referenceValue = referenceValue + ' ' + containerTwo;
    }

    if (secondSupplemental.length > 0) {
        referenceValue = referenceValue + ' ' + secondSupplemental;
    }

    let primaryContributor = shared.getPrimaryContributorString(refData);
    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let reference = {
        authorPart: primaryContributor,
        authorPartNoLabel : shared.getPrimaryContributorString(refData, true),
        authorPartSort: shared.getPrimaryContributorSort(refData),
        citationTitle : citationTitle,
        citationDate : citationDate,
        value: referenceValue.trim(),
        isPrintedOnReferencePage: true
    }

    return reference;
}

function getCitationTitle(refData){

    let citationTitle = '';

    citationTitle = shared.getStringValue(refData.pageTitle);
    citationTitle = citationTitle.replaceAmpersand();

    return citationTitle;
}

function getCitationDate(refData){

    let citationDate = '';

    citationDate = shared.getStringValue(refData.publicationDate);

    return citationDate;
}

function getCitation(refData, citationData) {
    //define our citation logic for this type here
    let titleProperty = 'pageTitle';
    let isSecondaryTitle = false;

    let primaryReferenceContributor = shared.getPrimaryContributorString(refData);

    //if the website title is the same as the primaryContributor, then we will use this later in the ref instead
    let websiteTitle = shared.getStringValue(refData.websiteTitle.replaceAll("’", "'"));
    let citationRefData = JSON.parse(JSON.stringify(refData));
    if (websiteTitle.replaceAll("’", "'") + '.' == primaryReferenceContributor.replaceAll("’", "'")) {
        citationRefData.contributors = [];
    }

    let citationTitle = getCitationTitle(refData);
    let citationDate = getCitationDate(refData);

    let primaryContributor = shared.getPrimaryContributorCitationString(citationRefData, titleProperty, isSecondaryTitle);
    
    let citation = shared.getCitations(citationData, primaryContributor, citationTitle, citationDate);

    //Test 54...odd scenario
    //if we don't have any other information here, check the pub date
    if (citation.first.length == 0 && citationData.namePart == 'true') {
        let publicationDate = shared.getStringValue(refData.publicationDate);
        publicationDate = shared.getFullDate(publicationDate);

        citation.first = publicationDate;
    }

    return citation;
}

function getPrimaryInformation(refData){
    let primaryPart = '';

    let primaryContributor = shared.getPrimaryContributorString(refData);

    //if the website title is the same as the primaryContributor, then we will use this later in the ref instead
    let websiteTitle = shared.getStringValue(refData.websiteTitle);
    if (websiteTitle.replaceAll("’", "'") + '.' == primaryContributor.replaceAll("’", "'")) {
        primaryContributor = '';
    }

    primaryPart = primaryContributor;

    let type = shared.getStringValue(refData.type);

    let title = '';

    title = shared.getStringValue(refData.pageTitle);

    if (title.length > 0) {
        //if this is a description, remove the brackets and don't italicize
        if (title.startsWith('[')) {
            title = shared.removeDescriptionFromTitle(title);
        }
        else{
            title = title.replaceAmpersand();
            title = title.toTitleCase();
            title = title.italicize();
        }
    }

    if (title.length > 0) {
        
        if (primaryPart.length > 0) {
            primaryPart = primaryPart + ' ' +  title;
        }
        else{
            primaryPart = title;
        }
    }

    //translatedTitle
    let translatedTitle = shared.getStringValue(refData.translatedTitle);

    if (translatedTitle.length > 0) {
        translatedTitle = translatedTitle.replaceAmpersand();
        translatedTitle = translatedTitle.toTitleCase();
        translatedTitle = translatedTitle.italicize();

        primaryPart = primaryPart.appendData('[' + translatedTitle + ']', ' ', '', '');  
    }

    primaryPart = shared.fixClosure(primaryPart);
    
    return primaryPart;
}

function getFirstSupplemental(refData){
    let firstSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    firstSupplemental = firstSupplemental.upperFirstLetter();
    firstSupplemental = shared.fixClosure(firstSupplemental);

    return firstSupplemental;
}

function getContainerOne(refData){
    let container1 = '';   

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //Secondary Contribs
    let secondaryContributor = shared.getSecondaryContributorString(refData.contributors);
    container1 = container1.appendData(secondaryContributor, ', ');

    //websiteTitle
    let websiteTitle = shared.getStringValue(refData.websiteTitle);
    container1 = container1.appendData(websiteTitle.replaceAll("’", "'"), ', ');

    //publicationDate
    let publicationDate = shared.getStringValue(refData.publicationDate);
    publicationDate = shared.getFullDate(publicationDate);
    container1 = container1.appendData(publicationDate, ', ');

    //Test 54
    //odd odd scenario...if we get to here, and they have only given us 
    //a website title and publication date, swap their order
    let primaryInfo = getPrimaryInformation(refData);
    let firstSupplemental = getFirstSupplemental(refData);

    if (primaryInfo.length == 0 &&
        firstSupplemental.length == 0 &&
        websiteTitle.length > 0 &&
        publicationDate.length > 0) {
        
        container1 = publicationDate;
        container1 = container1.appendData(websiteTitle, ', ');
    }


    //url
    let url = shared.getStringValue(refData.url);

    if (url.length > 0) {
        url = shared.formatURL(url);
        container1 = container1.appendData(url, ', ');
    }

    container1 = container1.upperFirstLetter();
    container1 = shared.fixClosure(container1);

    return container1;
}

function getContainerTwo(refData){
    let container2 = '';

    return container2;
}

function getSecondSupplemental(refData){
    let secondSupplemental = '';

    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    //retrievalDate
    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    retrievalDate = shared.getFullDate(retrievalDate);
    secondSupplemental = secondSupplemental.appendData(retrievalDate, ', ', 'accessed', '');
    
    //format
    let format = shared.getStringValue(refData.format);
    secondSupplemental = secondSupplemental.appendData(format, ', ', '', 'download');

    secondSupplemental = secondSupplemental.upperFirstLetter();
    secondSupplemental = shared.fixClosure(secondSupplemental);
    
    return secondSupplemental;
}