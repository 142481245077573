const _cloneDeep = require('lodash/cloneDeep');
const shared = require('../Turabian9/Turabian9-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    specialRules
};

function specialRules(references){
	let _references = [];

    //iterarate each reference to update it's value
    references.forEach((thisReference) => {
		// clone thisReference so i can manipulate it
		let _thisReference = _cloneDeep(thisReference);
		
        let typeName = getTypeShortName(_thisReference.referenceTypeID);
        
        let typeEngine = getTypeEngine(typeName);
        let engineRef;
        let refDataObject; 
        
        if (typeof _thisReference.data === 'string') {
          refDataObject = JSON.parse((_thisReference.data));
        }
        else{
          refDataObject = _thisReference.data;
        }

        engineRef = typeEngine.getReference(refDataObject);
        
      _thisReference.authorPart = engineRef.authorPart;
      _thisReference.authorPartNoLabel = engineRef.authorPartNoLabel;
      _thisReference.authorPartSort = engineRef.authorPartSort;
      _thisReference.sameAuthorPartID = 0;
      _thisReference.citationTitle = engineRef.citationTitle;
      _thisReference.citationShortTitle = engineRef.citationShortTitle;
      _thisReference.citationDefaultShortTitle = engineRef.citationDefaultShortTitle;

      _thisReference.displayValue = engineRef.value;
      _thisReference.orderByValue = shared.getOrderByValue(_thisReference);

      let citationData = new Object;
      citationData.namePart = true;
      citationData.datePart = true;
      citationData.label = '';
      citationData.value = '';
      citationData.type = 'notdirect';
      
      _thisReference.indirectCitation = typeEngine.getCitation(refDataObject, citationData, references);

      //update the citations for this reference
      if (_thisReference.citations != undefined) {

        //see if this is a simgle object, or an array
        if (Array.isArray(_thisReference.citations)) {

          let counter = 1;

          _thisReference.citations.forEach(thisCitation => {

            thisCitation.turabian9 = typeEngine.getCitation(refDataObject, thisCitation.citationData, references);
            // thisCitation.displayValue = thisCitation.turabian9.first;

            if (counter > 1 && thisCitation.turabian9.subsequent != '') {
              thisCitation.displayValue = thisCitation.turabian9.subsequent;
            }
            else{
              thisCitation.displayValue = thisCitation.turabian9.first;
            }
  
            counter++;
          });
        }
        else{
          if (typeof _thisReference.citations === 'object' && _thisReference.citations !== null) {
            _thisReference.citations.turabian9 = typeEngine.getCitation(refDataObject, _thisReference.citations.citationData, references);
            _thisReference.citations.displayValue = _thisReference.citations.turabian9.first; 
          }
        }
      }
	  _references.push(_thisReference);

    });//e:forEach

    //initial sort
    orderRefs(_references);

    //Same author  parts
    sameAuthorParts(_references);
    
    //Grouped Citations
    groupedCitations(_references);

    // console.log(_references);

    return _references;
}

function getTypeShortName(id){

    //swap the ID to the short name from types.json
    var typeData = require('../types.json');
    let typeShortName = '';

    if (isInteger(id)) {
      id = id.toString();
    }

    typeData.refTypes.forEach(thisType => {
      if (thisType.id == id) {
        typeShortName = thisType.shortname;
      }
    });

    return typeShortName;
  }

function getTypeEngine(type){
  var typeEngine = null;

    typeEngine = require('./Turabian9-' + type +'.js');

    return typeEngine;
}

function groupedCitations(references){

  //iterate and see if we have any grouped citations in the paper
  let groupCitationIds = [];

  references.forEach(thisRef => {

    if (thisRef.citations != undefined) {

      if (Array.isArray(thisRef.citations)) {
        for (var i = 0; i < thisRef.citations.length; i++) {
          let thisCitation = thisRef.citations[i];

          if (thisCitation.groupUniqueID != undefined) {
            if (thisCitation.groupUniqueID.length > 0) {

              //make sure this group id is not already in the list
              let found = false;
              groupCitationIds.forEach(thisGroupCitationID => {
                if (thisGroupCitationID.toUpperCase() == thisCitation.groupUniqueID.toUpperCase()) {
                  found = true;
                }
              });

              if (!found) {
                groupCitationIds.push(thisCitation.groupUniqueID.toUpperCase());
              }

            }
          }
        }//e:for:citations
      }
      else{
        if (thisRef.citations.groupUniqueID != undefined) {
            if (thisRef.citations.groupUniqueID.length > 0) {
              //make sure this group id is not already in the list
              let found = false;
              groupCitationIds.forEach(thisGroupCitationID => {
                if (thisGroupCitationID.toUpperCase() == thisRef.citations.groupUniqueID.toUpperCase()) {
                  found = true;
                }
              });

              if (!found) {
                groupCitationIds.push(thisRef.citations.groupUniqueID.toUpperCase());
              }
            }
        }
      }
    }

  });//e:for:references

  //process each group ID now
  groupCitationIds.forEach(thisGroupID => {

    let groupCitations = [];

    references.forEach(thisRef => {
      if (thisRef.citations != undefined) {

        if (Array.isArray(thisRef.citations)) {
          thisRef.citations.forEach(thisCitation =>{

            if (thisCitation.groupUniqueID != undefined) {
              if (thisCitation.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                groupCitations.push(thisCitation);
              }
            }

          });//e:for:citations
        }
        else{
          if (thisRef.citations.groupUniqueID != undefined) {
            if (thisRef.citations.groupUniqueID.toUpperCase() == thisGroupID.toUpperCase()) {
                            groupCitations.push(thisRef.citations);
            }
          }
        }
      }
    });//e:for:references

    //sort our citations by createOrder
    groupCitations.sort((a, b) => {
      let numberA = Number(a.createOrder);
      let numberB = Number(b.createOrder);

      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    });//e:sort

    //make a pass through now to get our new value
    let groupValue = '';
    let lastCitationUsed = ''

    groupCitations.forEach(thisCitation => {

      let thisDisplay = thisCitation.displayValue;

      if (thisDisplay.startsWith('(')) {
        thisDisplay = thisDisplay.substring(1, thisDisplay.length);
      }

      if (thisDisplay.endsWith(')')) {
        thisDisplay = thisDisplay.substring(0, thisDisplay.length -1);
      }

      let displayToUse = thisDisplay;

      //loop through until we find the part of this citation that start to differ from the last one we used
      // let found = false;
      // for (let i = 0; i < displayToUse.length; i++) {

      //   if (!found) {

      //     let thisChar = displayToUse.charAt(i);

      //     if (isInteger(thisChar)) {
      //       displayToUse = displayToUse.substring(i, displayToUse.length);
      //       found = true;
      //     }
      //     else{
      //       if (displayToUse.charAt(i) != lastCitationUsed.charAt(i)) {
      //         displayToUse = displayToUse.substring(i, displayToUse.length);
      //         found = true;
      //       } 
      //     } 
      //   }
      // }

      lastCitationUsed = thisDisplay;

      if (displayToUse.length > 0) {
        if (groupValue.length > 0) {
          
          if (groupValue.endsWith('.')) {
            groupValue = groupValue.substring(0, groupValue.length -1);
          }

          groupValue = groupValue + '; ';
        }

        groupValue = groupValue + displayToUse;
      }

    });//e:for:group citations

    // if (groupValue.length > 0) {
    //   groupValue = '(' + groupValue + ')';
    // }

    //now go back and set the display value back
    groupCitations.forEach(thisCitation => {
      thisCitation.displayValue = groupValue;
    });//e:for:group citations

  });

}

function sameAuthorParts(references){
  //see which references have the same author date parts
  let sameAuthorPartID = 1; 
  let sameAuthorPartIDs = [];

  references.forEach(thisReference => {

    if (thisReference.authorPart != '' && thisReference.sameAuthorPartID == 0) {
        
      //inner loop for this reference
        let foundMatches = false;
        references.forEach(innerReference => {
          if (innerReference.referenceUniqueID != thisReference.referenceUniqueID &&
              innerReference.authorPartNoLabel == thisReference.authorPartNoLabel
              ) 
          {
            //we found a matching author and date part
            foundMatches = true;
            thisReference.sameAuthorPartID = sameAuthorPartID;
            innerReference.sameAuthorPartID = sameAuthorPartID;
          }
        });

        //if we found a match, increment our id
        if (foundMatches) {
          sameAuthorPartIDs.push(sameAuthorPartID);
          sameAuthorPartID++;
        }
    }
      
  });

  //change any authors after the initial entry to only be ---
  sameAuthorPartIDs.forEach(thisSameID =>{
    let matchingRefs = [];

        references.forEach(thisReference => {
          if (thisReference.sameAuthorPartID == thisSameID) {
            matchingRefs.push(thisReference);
          }
        });

        let counter = 0;
        matchingRefs.forEach(thisReference => {
          
          if (counter > 0) {

            let dashReplacement = '———';

            if (thisReference.authorPart == thisReference.authorPartNoLabel) {
                dashReplacement = dashReplacement + '.';
            }

            thisReference.displayValue = thisReference.displayValue.replace(thisReference.authorPartNoLabel, dashReplacement);
          }
          
          counter++;
        });
  });

  //now we need to make sure that any subsequent citations do not match and have enough to identify
  sameAuthorPartIDs.forEach(thisSameID =>{
    let matchingRefs = [];

        references.forEach(thisReference => {
          if (thisReference.sameAuthorPartID == thisSameID) {
            matchingRefs.push(thisReference);
          }
        });

        let matchingShortNames = [];
        //going to start by getting all the citation short names that match
        matchingRefs.forEach(thisReference => {
          
          if (thisReference.citationDefaultShortTitle != '' ) {
                matchingRefs.forEach(innerReference => {
                
                if (innerReference.citationDefaultShortTitle == thisReference.citationDefaultShortTitle){

                    let filteredArray = matchingShortNames.filter(obj => obj === innerReference.citationDefaultShortTitle);

                    if (filteredArray.length == 0) {
                      matchingShortNames.push(thisReference.citationDefaultShortTitle);
                    }
                }
                
              });
          }
          
        });

        //we have short names that match, start doing work to extend them
        if (matchingShortNames.length > 0) {

          matchingShortNames.forEach(matchingShortName => {
            let matchingShortNameRefs = matchingRefs.filter(obj => obj.citationDefaultShortTitle === matchingShortName);
          
            //expand each short name until this is cleared up
            let i = 0;
            while (matchingShortNameRefs.length > 1) {

              //expand each array
              matchingShortNameRefs.forEach(matchingShortNameRef => {
                matchingShortNameRef.citationShortTitle = expandShortName(matchingShortNameRef.citationShortTitle, matchingShortNameRef.citationTitle);
              });

              //lets look at them now and remove any that are not duplicated
              let index = 0;
              matchingShortNameRefs.forEach(matchingShortNameRef => {
                let updatedMatches = matchingRefs.filter(obj => obj.citationShortTitle === matchingShortNameRef.citationShortTitle);
              
                if (updatedMatches.length == 1) {
                  //if this only has one match, it is itself, so it is unique and we can clear this one from our to-do list
                  matchingShortNameRefs.splice(index, 1);
                }

                index++;
              });

              //just a while short circuit, worst case scenarios
              if (i==1000) {
                break;
              }

              i++;
            }
          });
        }

        //now go back thru each of the references and see if they have subsuquents, and update them
        matchingRefs.forEach(thisReference => {

          if (thisReference.citations != undefined) {
            if (thisReference.citations.length > 1) {

              let i = 0;
              thisReference.citations.forEach(thisCitation => {
                
                if (i > 0) {
                  thisCitation.displayValue = thisCitation.displayValue.replace(thisReference.citationDefaultShortTitle, thisReference.citationShortTitle)
                }
                
                i++;
              });
            }
          }
          
        });
  });

}

function orderRefs(references){
  //order the results
  references.sort((a, b) => {
    let textA = '';
    let textB = '';

    if (a.orderByValue !== null) {
        textA = a.orderByValue;
    }

    if (b.orderByValue !== null) {
      textB = b.orderByValue;
    }

    if (textA.length > 0) {
      textA = textA.toUpperCase();
    }

    if (textB.length > 0) {
      textB = textB.toUpperCase();
    }

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });//e:sort
}

function expandShortName(shortName, fullName){
  let newShortName = shortName;

  if (shortName == fullName) {
    return fullName;
  }
  else{
    let restOfShortName = fullName.replace(shortName, '');
    restOfShortName = restOfShortName.trimStart();

    let words = restOfShortName.split(" ");

    let done = false;
    words.forEach(word => {
    
      if (!done) {
        //only add one word, unless ...
        //"Do not stop on a conjunction (and, or, but) or article (a, an, the)"
        let continueWords = ['and', 'or', 'but', 'a', 'an', 'the'];
        let isContinue = continueWords.includes(word.toLowerCase());

        newShortName = newShortName + ' ' + word;

        if (!isContinue) {
          done = true;
        }  
      }
    });
  }

  return newShortName;
}

function isInteger(value) {
  return !isNaN(value) && 
          parseInt(Number(value)) == value && 
          !isNaN(parseInt(value, 10));
  }